import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate, Navigate } from 'react-router-dom';
import styled from "styled-components/macro";
import { mediaQueries } from '../../styles/media.js';
import PageHeader from "../../components/PageHeader";
import Tile from "../../components/Tile";
import Circle from "../../components/Circle";
import TileGrid from "../../layouts/TileGrid.js";

import { describeTransactionType } from "../../utils/textUtils";

import { HomeWork } from '@styled-icons/material-rounded'
import { Home, HomeAlt } from '@styled-icons/boxicons-regular'
import { Home as HomeSolid } from '@styled-icons/boxicons-solid'

const CaseListPageHeader = styled.header`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  background-color: ${props => props.theme.color.junoBrand};
  color: ${props => props.theme.color.junoHeader};
  padding-bottom: 1em;
  ${mediaQueries.md} {
    padding-bottom: 2.25em;
  }
  ${mediaQueries.md} {
    margin-top: -40px;
  }
`

const CaseListPageHeaderInner = styled.div`
  margin-left: 20px;
  margin-right: 20px;

  ${mediaQueries.md} {
    margin-left: 45px;
    margin-right: 45px;
  }

  ${mediaQueries.lg} {
    width: 930px;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    font-size: 18px;
    line-height: 1.5;
    ${mediaQueries.md} {
      padding-top: 0.67em;
      margin-bottom: -12px;
    }
    ${mediaQueries.md} {
      font-size: 24px;
    }
    color: white;
    margin-top: 0;
    margin-bottom: 0;

    span {
     font-weight: ${props => props.theme.fontWeight.regular};
    }
  }

`;

const CaseListFilterWrapper = styled.div`
  display: block;
  ${mediaQueries.md} {
    display: flex;
    justify-content: flex-start;
  }

  width: 100%;

  padding-left: 20px;
  margin-top: 0;
  ${mediaQueries.md} {
    margin-top: 12px;
    padding-left: 0;
  }
`

const CaseListFilterLayout = styled.div`
  border-bottom: 1px solid #ebebeb;
  margin-left: -20px;
  padding-left: 20px;

  ${mediaQueries.md} {
    margin-left: 0;
    padding-left: 0;
    border-bottom: 0;
  }
`

const FilterButton = styled.button`
  background: white;
  border: 0;
  border-bottom: 2px solid ${props => props.selected === true ? 'black' : 'transparent'};
  color:${props => props.selected === true ? 'black' : 'grey'};
  transition: color 200ms ease;
  transition: border-bottom-color 200ms ease;

  font-size: 13px;
  font-weight: ${props => props.theme.fontWeight.bold};
  &:hover {
    color: ${props => props.theme.color.black};
    border-bottom-color:  ${props => props.selected === true ? 'black' : 'lightGray'};
    cursor: ${props => props.selected === true ? 'default' : 'pointer'};
  }
  &:focus {
    outline: 0;
    border-bottom-color:  ${props => props.selected === true ? 'black' : 'lightGray'};
  }

  margin-top: 0;
  margin-right: 28px;
  padding: 16px 0 10px 0;

  ${mediaQueries.md} {
    margin-right: 36px;
  }

`

const FilterButtonInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FilterButtonText = styled.span`
  flex-shrink: 0;
  padding-top: 6px;
  ${mediaQueries.md} {
    padding-top: 9px;
  }
`

const CaseListSortOrder = styled.div`
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeight.bold};

  margin-top: 16px;
  ${mediaQueries.md} {
    margin-top: 39px;
    margin-left: auto;
  }

  select {
    font-weight: ${props => props.theme.fontWeight.bold};
    background: white;

    height: 36px;
    ${mediaQueries.md} {
      height: 41px;
    }

    transition: border-bottom-color 200ms ease;
    border-radius: 0;
    border-width: 0 0 2px 0;
    border-bottom-style: solid;
    border-bottom-color: black;
    :hover {
      border-bottom-color: lightGray;
    }
    :focus {
      outline: 0;
    }

    padding-left: 0;
    padding-right: 8px;

    margin-left: 8px;

  }

  option {
    padding-left: 0;
  }
`

const CaseListLayout = styled.div`
  margin-left: 20px;
  margin-right: 20px;

  ${mediaQueries.md} {
    margin-left: auto;
    margin-right: auto;
  }

  border-top: 1px solid #EBEBEB;

  ul {
    list-style: none;
    margin: 8px 0 0 0;
    ${mediaQueries.md} {
      margin-top: 48px;
    }
    padding: 0 0 24px 0;
    width: 100%;
  }

  li {
    padding: 12px 0;
    border-bottom: 1px solid #EBEBEB;
    &:last-child {
      border-bottom: none;
    }
  }
`

const CaseListItemLink = styled.a`
  display: flex;
  align-items: flex-start;

  &:hover {
    cursor: pointer;
    border-bottom: none;
  }
`

const CaseListItem = styled.span`
  :first-letter {
    text-transform: uppercase;
  }

  color: ${props => props.theme.color.junoText};

  font-size: 16px;
  ${mediaQueries.md} {
    font-size: 18px;
  }
  line-height: 24px;
  margin-right: 16px;
`

const CaseListItemType = styled.div`
 :first-letter {
    text-transform: uppercase;
  }
  color: ${props => props.theme.color.junoTextSecondary};
  font-size: 14px;
  ${mediaQueries.md} {
    font-size: 15px;
  }
`

const SecondaryText = styled.span`
  color: ${props => props.theme.color.junoTextSecondary};
`

const Count = styled(Circle)`
  margin-left: auto;
  margin-right: 0;

  ${CaseListItemLink}:hover & {
    color: ${props => props.theme.color.purple};
    background: ${props => props.theme.color.offWhite};
    border-color: ${props => props.theme.color.offWhite};
  }
`

const countThingsToDo = (cse) => {
  let unreadNotifications = [];
  if (cse.notifications) {
    unreadNotifications = cse.notifications.filter(n => n.status === 'unread');
  }

  let availableDoables = [];
  if (cse.doables) {
    availableDoables = cse.doables.filter(d => d.status !== 'complete');
  }

  return String(unreadNotifications.length + availableDoables.length)
}

const countEnquiries = (cse) => {
  const enquiryDoables = cse.doables.filter(d => (
    d.doable_type === 'enquiry' && (d.status === 'available' || d.status === 'available_to_read')
  ))
  return enquiryDoables.length
}

const CaseList = (props) => {
  const cases = useSelector(state => state.dashboardState.cases);
  const session = useSelector(state => state.dashboardState.session);
  const navigate = useNavigate();

  const [filter, setFilter] = useState('all');
  const [order, setOrder] = useState('thingsToDo');


  const [visibleCases, setVisibleCases] = useState(null);
  useEffect(() => {
    let newVisibleCases = [...cases];
    if (filter !== 'all') {
      newVisibleCases = cases.filter(c => c.case_type === filter);
    }

    if (order === "enquiries") {
      newVisibleCases.sort((a, b) => countEnquiries(b) - countEnquiries(a))
    } else if (order === "dateCreated") {
      newVisibleCases.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (order === "thingsToDo") {
      newVisibleCases.sort((a, b) => countThingsToDo(b) - countThingsToDo(a));
    }
    setVisibleCases(newVisibleCases);
  }, [filter, order, cases]);

  if (!cases || !visibleCases) {
    return null;
  }

  if (cases.length === 1) {
    return <Navigate to={`/dashboard/${cases[0].case_reference}`} />;
  }

  const navigateToCase = (cse) => {
    navigate(`/dashboard/${cse.case_reference}`);
  };

  if (cases.length <= 2) {
    return (
      <>
        <PageHeader>
          Hello {session.first_name}<br/>
          You have {cases.length} cases with us
        </PageHeader>
        <TileGrid>
          {visibleCases.map((c, index) => {
            return (
                <Tile key={c.id} title={`Your ${describeTransactionType(c.case_type)}`} count={countThingsToDo(c)} caseType={c.case_type} onClick={() => navigateToCase(c)}>
                    {c.address && (c.address)} ({c.case_reference})
                </Tile>
            );
          })}
        </TileGrid>
      </>
    )
  }

  const hasBuying = cases.some(c => c.case_type === "buying")
  const hasSelling = cases.some(c => c.case_type === "selling")
  const hasRemo = cases.some(c => c.case_type === "remortgaging")

  return (
    <>
      <CaseListPageHeader>
        <CaseListPageHeaderInner>
            <h1>
              Hello {session.first_name}<br />
              You have {cases.length} cases with us
            </h1>
        </CaseListPageHeaderInner>
      </CaseListPageHeader>

      <CaseListFilterWrapper>

        <CaseListFilterLayout>
          {hasBuying && (
            <FilterButton onClick={() => setFilter('buying')} selected={filter === 'buying'}>
              <FilterButtonInner>
                <HomeSolid size="28" />
                <FilterButtonText>Purchases</FilterButtonText>
              </FilterButtonInner>
            </FilterButton>
          )}

          {hasSelling && (
            <FilterButton onClick={() => setFilter('selling')} selected={filter === 'selling'}>
              <FilterButtonInner>
                <Home size="28" />
                <FilterButtonText>Sales</FilterButtonText>
              </FilterButtonInner>
            </FilterButton>
          )}

          {hasRemo && (
            <FilterButton onClick={() => setFilter('remortgaging')} selected={filter === 'remortgaging'}>
              <FilterButtonInner>
                <HomeAlt size="28" />
                <FilterButtonText>Remortgages</FilterButtonText>
              </FilterButtonInner>
            </FilterButton>
          )}

          <FilterButton onClick={() => setFilter('all')} selected={filter === 'all'}>
            <FilterButtonInner>
              <HomeWork size="28" />
              <FilterButtonText>Show all</FilterButtonText>
            </FilterButtonInner>
          </FilterButton>
        </CaseListFilterLayout>

        <CaseListSortOrder>
          Order by
          <select
            value={order}
            onChange={(e) => setOrder(e.target.value)}>
            <option value="thingsToDo">Things to do</option>
            <option value="enquiries">Enquiries waiting</option>
            <option value="dateCreated">Newest case</option>
          </select>
        </CaseListSortOrder>
      </CaseListFilterWrapper>

      <CaseListLayout>
        <ul>
          {visibleCases && visibleCases.map((c) => {
            return (
              <li key={c.id} data-testid="case-list-item">
                <CaseListItemLink onClick={() => navigateToCase(c)}>
                  <CaseListItem>
                    {c.address && (c.address)} <SecondaryText>({c.case_reference})</SecondaryText>
                    <CaseListItemType>
                      {describeTransactionType(c.case_type)}
                    </CaseListItemType>
                  </CaseListItem>

                  {order !== "enquiries" && (
                    <Count background="purple" color="white" dataTestId="things-to-do" title="Things to do">
                      {countThingsToDo(c)}
                    </Count>
                  )}

                  {order === "enquiries" && (
                    <Count background="purple" color="white" dataTestId="enquiries-waiting" title="Enquiries waiting">
                      {countEnquiries(c)}
                    </Count>
                  )}
                </CaseListItemLink>
              </li>)
          })}
        </ul>
      </CaseListLayout>
    </>
  )
};

export default CaseList
