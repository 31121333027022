import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { isMoneyValid, normaliseMoney } from '../../utils/moneyUtils';
import {isEmailValid} from "../../utils/textUtils";

class LinkedSale extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      estimateSellingAnotherPropertyAmount: 'Please fill this in',
      linkedSaleComplete: 'Please choose an option',
      linkedSaleWithJuno: 'Please choose an option',
      linkedSaleLawyerHandling: 'Please fill this in',
      linkedSaleLawFirm: 'Please fill this in',
      linkedSaleLawFirmEmailAddress: 'Please fill this in',
    };

    if (this.props.linkedSale) {
      const linked = { ...this.props.linkedSale };
      this.state = {
        estimateSellingAnotherPropertyAmount:
          linked.estimateSellingAnotherPropertyAmount,
        linkedSaleComplete: linked.linkedSaleComplete,
        linkedSaleWithJuno: linked.linkedSaleWithJuno,
        linkedSaleLawyerHandling: linked.linkedSaleLawyerHandling,
        linkedSaleLawFirm: linked.linkedSaleLawFirm,
        linkedSaleLawFirmEmailAddress: linked.linkedSaleLawFirmEmailAddress,
        linkedSaleLawFirmPhoneNumber: linked.linkedSaleLawFirmPhoneNumber,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        estimateSellingAnotherPropertyAmount: '',
        linkedSaleComplete: null,
        linkedSaleWithJuno: null,
        linkedSaleLawyerHandling: '',
        linkedSaleLawFirm: '',
        linkedSaleLawFirmEmailAddress: '',
        linkedSaleLawFirmPhoneNumber: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => val => {
    this.setState({ [field]: val });
  };

  selectLinkedSaleComplete = lsc => {
    this.setState(
      {
        linkedSaleComplete: lsc,
      },
      () => {
        this.resetLinkedSaleWithJuno();
      },
    );
  };

  resetLinkedSaleWithJuno = () => {
    if (this.state.linkedSaleComplete === false) {
      this.setState({
        linkedSaleWithJuno: null,
      });
    }
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    let formIsValid = true;

    if (!fields['estimateSellingAnotherPropertyAmount']) {
      errors['estimateSellingAnotherPropertyAmount'] = true;
      formIsValid = false;
    }

    if (
      fields['estimateSellingAnotherPropertyAmount'] !== '' &&
      !isMoneyValid(fields['estimateSellingAnotherPropertyAmount'])
    ) {
      errors['estimateSellingAnotherPropertyAmount'] = true;
      errorMessages['estimateSellingAnotherPropertyAmount'] =
        'Please enter a valid amount';
      formIsValid = false;
    }

    if (fields['linkedSaleComplete'] === null) {
      errors['linkedSaleComplete'] = true;
      formIsValid = false;
    }

    if (fields['linkedSaleComplete'] === false) {
      if (fields['linkedSaleWithJuno'] === null) {
        errors['linkedSaleWithJuno'] = true;
        formIsValid = false;
      }
    }

    if (fields['linkedSaleWithJuno'] === false) {
      if (!fields['linkedSaleLawyerHandling']) {
        errors['linkedSaleLawyerHandling'] = true;
        formIsValid = false;
      }

      if (!fields['linkedSaleLawFirm']) {
        errors['linkedSaleLawFirm'] = true;
        formIsValid = false;
      }

      if (!fields['linkedSaleLawFirmEmailAddress']) {
        errors['linkedSaleLawFirmEmailAddress'] = true;
        formIsValid = false;
      }

      if (
        fields['linkedSaleLawFirmEmailAddress'] !== '' &&
        !isEmailValid(fields['linkedSaleLawFirmEmailAddress'])
      ) {
        errors['linkedSaleLawFirmEmailAddress'] = true;
        errorMessages['linkedSaleLawFirmEmailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleEmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isEmailValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid email address';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleMoneyValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isMoneyValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid amount';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  saveLinkedSale = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        linkedSale: {
          estimateSellingAnotherPropertyAmount: normaliseMoney(
            this.state.estimateSellingAnotherPropertyAmount,
          ),
          linkedSaleComplete: this.state.linkedSaleComplete,
          linkedSaleWithJuno: this.state.linkedSaleWithJuno,
          linkedSaleLawyerHandling: this.state.linkedSaleLawyerHandling,
          linkedSaleLawFirm: this.state.linkedSaleLawFirm,
          linkedSaleLawFirmEmailAddress: this.state
            .linkedSaleLawFirmEmailAddress,
          linkedSaleLawFirmPhoneNumber: this.state.linkedSaleLawFirmPhoneNumber,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about your sale" removeTopPadding>
          <TextQuestion
            question="How much money from your sale will you contribute to your purchase?"
            hint="This amount is usually lower than the sale price"
            value={this.state.estimateSellingAnotherPropertyAmount}
            valueEntered={this.handleInputChange(
              'estimateSellingAnotherPropertyAmount',
            )}
            validate={this.handleMoneyValidation(
              'estimateSellingAnotherPropertyAmount',
            )}
            validationError={
              this.state.errors.estimateSellingAnotherPropertyAmount
                ? this.state.errorMessages.estimateSellingAnotherPropertyAmount
                : null
            }
          />

          <RadioQuestion
            question="Has your sale already completed?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="linkedSaleComplete"
            selected={this.state.linkedSaleComplete}
            optionSelected={this.selectLinkedSaleComplete}
            validate={this.handleFieldValidation('linkedSaleComplete')}
            validationError={
              this.state.errors.linkedSaleComplete
                ? this.state.errorMessages.linkedSaleComplete
                : null
            }
          />

          {this.state.linkedSaleComplete === false && (
            <RadioConditional>
              <RadioQuestion
                question="Is Juno handling your sale?"
                options={YES_NO_OPTIONS}
                inline={true}
                name="linkedSaleWithJuno"
                selected={this.state.linkedSaleWithJuno}
                optionSelected={this.handleInputChange('linkedSaleWithJuno')}
                validate={this.handleFieldValidation('linkedSaleWithJuno')}
                validationError={
                  this.state.errors.linkedSaleWithJuno
                    ? this.state.errorMessages.linkedSaleWithJuno
                    : null
                }
              />

              {this.state.linkedSaleWithJuno === false && (
                <React.Fragment>
                  <TextQuestion
                    question="What's the name of the lawyer handling your sale?"
                    value={this.state.linkedSaleLawyerHandling}
                    valueEntered={this.handleInputChange(
                      'linkedSaleLawyerHandling',
                    )}
                    validate={this.handleFieldValidation(
                      'linkedSaleLawyerHandling',
                    )}
                    validationError={
                      this.state.errors.linkedSaleLawyerHandling
                        ? this.state.errorMessages.linkedSaleLawyerHandling
                        : null
                    }
                  />

                  <TextQuestion
                    question="What's the name of the law firm they work for?"
                    value={this.state.linkedSaleLawFirm}
                    valueEntered={this.handleInputChange('linkedSaleLawFirm')}
                    validate={this.handleFieldValidation('linkedSaleLawFirm')}
                    validationError={
                      this.state.errors.linkedSaleLawFirm
                        ? this.state.errorMessages.linkedSaleLawFirm
                        : null
                    }
                  />

                  <TextQuestion
                    question="What's their email address?"
                    value={this.state.linkedSaleLawFirmEmailAddress}
                    valueEntered={this.handleInputChange(
                      'linkedSaleLawFirmEmailAddress',
                    )}
                    validate={this.handleEmailAddressValidation(
                      'linkedSaleLawFirmEmailAddress',
                    )}
                    validationError={
                      this.state.errors.linkedSaleLawFirmEmailAddress
                        ? this.state.errorMessages.linkedSaleLawFirmEmailAddress
                        : null
                    }
                  />

                  <TextQuestion
                    question="What's their phone number?"
                    value={this.state.linkedSaleLawFirmPhoneNumber}
                    valueEntered={this.handleInputChange(
                      'linkedSaleLawFirmPhoneNumber',
                    )}
                  />
                </React.Fragment>
              )}
            </RadioConditional>
          )}

          <Action onClick={this.saveLinkedSale}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default LinkedSale;
