import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class ClientAddress extends Component {
  constructor(props) {
    super(props);

    if (this.props.clientAddress) {
      this.state = {
        clientAddress: { ...this.props.clientAddress },
        errors: { ...this.props.clientAddress.errors },
      };
    } else {
      this.state = {
        clientAddress: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          addressTown: '',
          addressCounty: '',
          addressPostcode: '',
        },
        errors: {},
        errorMessages: {
          addressLine1: 'Enter the first line of your address',
          addressPostcode: 'Enter your postcode',
          addressTown: 'Enter your town',
        },
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state.clientAddress };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (!fields['addressLine1']) {
      const field = 'addressLine1';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields['addressTown']) {
      const field = 'addressTown';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields['addressPostcode']) {
      const field = 'addressPostcode';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      // Hide the error when you interact with the field
      errors[field] = null;
    } else {
      // If the state for this field hasn't been set, we need some input, show an error
      if (!this.state.clientAddress[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleAddressChange = field => event => {
    let clientAddress = { ...this.state.clientAddress };
    clientAddress[field] = event;
    this.setState({ clientAddress });
  };

  saveClientAddress = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        clientAddress: this.state.clientAddress,
        errors: this.state.errors,
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="What’s your current address?" removeTopPadding>
          <TextQuestion
            question="Address line 1"
            value={this.state.clientAddress.addressLine1 || ''}
            valueEntered={this.handleAddressChange('addressLine1')}
            validate={this.handleFieldValidation('addressLine1')}
            validationError={
              this.state.errors.addressLine1
                ? this.state.errors.addressLine1
                : null
            }
          />

          <TextQuestion
            question="Address line 2"
            optional={true}
            value={this.state.clientAddress.addressLine2 || ''}
            valueEntered={this.handleAddressChange('addressLine2')}
          />

          <TextQuestion
            question="Address line 3"
            optional={true}
            value={this.state.clientAddress.addressLine3 || ''}
            valueEntered={this.handleAddressChange('addressLine3')}
          />

          <TextQuestion
            question="Town"
            value={this.state.clientAddress.addressTown || ''}
            valueEntered={this.handleAddressChange('addressTown')}
            validationError={
              this.state.errors.addressTown
                ? this.state.errors.addressTown
                : null
            }
          />

          <TextQuestion
            question="County"
            optional={true}
            value={this.state.clientAddress.addressCounty || ''}
            valueEntered={this.handleAddressChange('addressCounty')}
          />

          <TextQuestion
            question="Postcode"
            size="small"
            value={this.state.clientAddress.addressPostcode || ''}
            valueEntered={this.handleAddressChange('addressPostcode')}
            validate={this.handleFieldValidation('addressPostcode')}
            validationError={
              this.state.errors.addressPostcode
                ? this.state.errors.addressPostcode
                : null
            }
          />

          <Action onClick={this.saveClientAddress}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default ClientAddress;
