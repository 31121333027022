// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-ProofOfAddress-PoAIntro-content{text-align:left;margin:0 3em auto}@media(max-width: 479px){.onfido-sdk-ui-ProofOfAddress-PoAIntro-content{margin:0 0 auto}}.onfido-sdk-ui-ProofOfAddress-PoAIntro-requirements{font-weight:600;color:var(--osdk-color-content-body);margin-bottom:1em}.onfido-sdk-ui-ProofOfAddress-PoAIntro-requirement{display:-webkit-box;display:-webkit-flex;display:flex;padding-bottom:1em;padding-left:1em;position:relative}.onfido-sdk-ui-ProofOfAddress-PoAIntro-requirement::before{content:\"\";background-color:var(--osdk-color-background-alert-info);border-radius:50%;display:inline-block;height:.375em;width:.375em;position:absolute;left:0;top:.375em}.onfido-sdk-ui-ProofOfAddress-PoAIntro-bolder{font-weight:600}", ""]);
// Exports
exports.locals = {
	"modal_animation_duration": "200ms",
	"content": "onfido-sdk-ui-ProofOfAddress-PoAIntro-content",
	"requirements": "onfido-sdk-ui-ProofOfAddress-PoAIntro-requirements",
	"requirement": "onfido-sdk-ui-ProofOfAddress-PoAIntro-requirement",
	"bolder": "onfido-sdk-ui-ProofOfAddress-PoAIntro-bolder"
};
module.exports = exports;
