import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { isPhoneNumberValid } from '../../utils/numberUtils';
import { isEmailValid } from '../../utils/textUtils';

class SharedOwnershipPurchaseHousingAssociation extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      associationName: 'Please fill this in',
      contactFirstName: 'Please fill this in',
      contactLastName: 'Please fill this in',
      addressLine1: 'Please fill this in',
      postcode: 'Please fill this in',
      email: 'Please fill this in',
      phoneNumber: 'Please fill this in',
    };

    if (this.props.housingAssociation) {
      const details = this.props.housingAssociation;
      this.state = {
        associationName: details.associationName,
        contactFirstName: details.contactFirstName,
        contactLastName: details.contactLastName,
        addressLine1: details.addressLine1,
        addressLine2: details.addressLine2,
        addressLine3: details.addressLine3,
        postcode: details.postcode,
        email: details.email,
        phoneNumber: details.phoneNumber,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        associationName: '',
        contactFirstName: '',
        contactLastName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        postcode: '',
        email: '',
        phoneNumber: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (!fields['associationName']) {
      errors['associationName'] = true;
      formIsValid = false;
    }

    if (!fields['contactFirstName']) {
      errors['contactFirstName'] = true;
      formIsValid = false;
    }

    if (!fields['contactLastName']) {
      errors['contactLastName'] = true;
      formIsValid = false;
    }

    if (!fields['addressLine1']) {
      errors['addressLine1'] = true;
      formIsValid = false;
    }

    if (!fields['postcode']) {
      errors['postcode'] = true;
      formIsValid = false;
    }

    if (!fields['email']) {
      errors['email'] = true;
      formIsValid = false;
    }

    if (!fields['phoneNumber']) {
      errors['phoneNumber'] = true;
      formIsValid = false;
    }

    if (fields['email'] !== '' && !isEmailValid(fields['email'])) {
      errors['email'] = true;
      errorMessages['email'] = 'Please enter a valid email address';
      formIsValid = false;
    }

    if (
      fields['phoneNumber'] !== '' &&
      !isPhoneNumberValid(fields['phoneNumber'])
    ) {
      errors['phoneNumber'] = true;
      errorMessages['phoneNumber'] = 'Please enter a valid phone number';
      formIsValid = false;
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleEmailValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isEmailValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid email address';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handlePhoneNumberValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isPhoneNumberValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid phone number';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  saveSharedOwnershipPurchaseHousingAssociation = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        housingAssociation: {
          associationName: this.state.associationName,
          contactFirstName: this.state.contactFirstName,
          contactLastName: this.state.contactLastName,
          addressLine1: this.state.addressLine1,
          addressLine2: this.state.addressLine2,
          addressLine3: this.state.addressLine3,
          postcode: this.state.postcode,
          email: this.state.email,
          phoneNumber: this.state.phoneNumber,
        },
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about the housing association" removeTopPadding>
          <TextQuestion
            question="What's the name of the housing association"
            value={this.state.associationName || ''}
            valueEntered={this.handleInputChange('associationName')}
            validate={this.handleFieldValidation('associationName')}
            validationError={
              this.state.errors.associationName
                ? this.state.errorMessages.associationName
                : null
            }
          />

          <TextQuestion
            question="What's the first name of your housing association contact?"
            value={this.state.contactFirstName || ''}
            valueEntered={this.handleInputChange('contactFirstName')}
            validate={this.handleFieldValidation('contactFirstName')}
            validationError={
              this.state.errors.contactFirstName
                ? this.state.errorMessages.contactFirstName
                : null
            }
          />
          <TextQuestion
            question="What's their second name?"
            value={this.state.contactLastName || ''}
            valueEntered={this.handleInputChange('contactLastName')}
            validate={this.handleFieldValidation('contactLastName')}
            validationError={
              this.state.errors.contactLastName
                ? this.state.errorMessages.contactLastName
                : null
            }
          />

          <TextQuestion
            question="Address line 1"
            value={this.state.addressLine1 || ''}
            valueEntered={this.handleInputChange('addressLine1')}
            validate={this.handleFieldValidation('addressLine1')}
            validationError={
              this.state.errors.addressLine1
                ? this.state.errorMessages.addressLine1
                : null
            }
          />
          <TextQuestion
            question="Address line 2"
            optional={true}
            value={this.state.addressLine2 || ''}
            valueEntered={this.handleInputChange('addressLine2')}
          />
          <TextQuestion
            question="Address line 3"
            optional={true}
            value={this.state.addressLine3 || ''}
            valueEntered={this.handleInputChange('addressLine3')}
          />
          <TextQuestion
            question="Postcode"
            size="small"
            value={this.state.postcode || ''}
            valueEntered={this.handleInputChange('postcode')}
            validate={this.handleFieldValidation('postcode')}
            validationError={
              this.state.errors.postcode ? this.state.errors.postcode : null
            }
          />

          <TextQuestion
            question="What’s their email address?"
            value={this.state.email}
            valueEntered={this.handleInputChange('email')}
            validate={this.handleEmailValidation('email')}
            validationError={
              this.state.errors.email ? this.state.errorMessages.email : null
            }
          />
          <TextQuestion
            question="And their phone number?"
            value={this.state.phoneNumber}
            valueEntered={this.handleInputChange('phoneNumber')}
            validate={this.handlePhoneNumberValidation('phoneNumber')}
            validationError={
              this.state.errors.phoneNumber
                ? this.state.errorMessages.phoneNumber
                : null
            }
          />

          <Action onClick={this.saveSharedOwnershipPurchaseHousingAssociation}>
            Continue
          </Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default SharedOwnershipPurchaseHousingAssociation;
