import React from "react";
import RadioQuestion from "../../../components/RadioQuestion";
import {YES_NO_OPTIONS} from "../../../constants";
import DynamicCheckboxQuestion from "../../../components/DynamicCheckboxQuestion";
import TextQuestion, {TextareaQuestion} from "../../../components/TextQuestion";
import IntegerQuestion from "../../../components/IntegerQuestion";
import PhoneNumberQuestion from "../../../components/PhoneNumberQuestion";
import EmailAddressQuestion from "../../../components/EmailAddressQuestion";
import MoneyQuestion from "../../../components/MoneyQuestion";
import { isFieldQuestionVisible } from "./RenderQuestion";
import DateQuestion from "../../../components/DateQuestion";
import YearQuestion from "../../../components/YearQuestion";

const setSelected = (selected) => {
  if (selected === "true") {
    return true
  }
  if (selected === "false") {
    return false
  }
}

const RenderCompletedQuestion = props => {
  const fieldConfig = props.fieldConfig
  const optional = fieldConfig.required ? false : true

  if (!isFieldQuestionVisible(props.doableConfig, fieldConfig, props.responses)) {
    return null
  }

  if (fieldConfig.field_type === 'boolean') {
    return (
      <RadioQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        options={YES_NO_OPTIONS}
        inline={true}
        name={fieldConfig.field_identifier}
        selected={setSelected(props.selected)}
        optional={optional}
        disabled={props.disabled}
      />
    );
  } else if (fieldConfig.field_type === 'radio') {
    return (
      <RadioQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        options={fieldConfig.choices}
        name={fieldConfig.field_identifier}
        selected={props.selected}
        optional={optional}
        disabled={props.disabled}
      />
    );
  } else if (fieldConfig.field_type === 'checkbox') {
    return (
      <DynamicCheckboxQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        options={fieldConfig.choices}
        fieldId={fieldConfig.field_identifier}
        selected={props.selected}
        optional={optional}
        submission={true}
        disabled={props.disabled}
      />
    );
  } else if (fieldConfig.field_type === 'confirmation') {
    return (
      <DynamicCheckboxQuestion
        question={null}
        hint={fieldConfig.hint}
        options={[{ label: fieldConfig.display_name, value: true }]}
        fieldId={fieldConfig.field_identifier}
        selected={props.selected}
        optional={optional}
        submission={true}
        disabled={props.disabled}
      />
    );
  } else if (fieldConfig.field_type === 'text') {
    return (
      <TextQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        optional={optional}
        disabled={props.disabled}
      />
    );
  } else if (fieldConfig.field_type === 'postcode') {
    return (
      <TextQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        optional={optional}
        size="small"
        disabled={props.disabled}
      />
    );
  } else if (fieldConfig.field_type === 'text_area') {
    return (
      <TextareaQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        optional={optional}
        disabled={props.disabled}
      />
    );
  } else if (fieldConfig.field_type === 'integer') {
    return (
      <IntegerQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        optional={optional}
        disabled={props.disabled}
      />
    );
  } else if (
    fieldConfig.field_type === 'phone_number' ||
    fieldConfig.field_type === 'mobile_number'
  ) {
    return (
      <PhoneNumberQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        optional={optional}
        disabled={props.disabled}
      />
    );
  } else if (fieldConfig.field_type === 'email_address') {
    return (
      <EmailAddressQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        optional={optional}
        disabled={props.disabled}
      />
    );
  } else if (fieldConfig.field_type === 'money') {
    return (
      <MoneyQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        optional={optional}
        disabled={props.disabled}
      />
    );
  } else if (
    fieldConfig.field_type === 'date' ||
    fieldConfig.field_type === 'must_happen_by_date' ||
    fieldConfig.field_type === 'date_in_the_past'
  ) {
    return (
      <DateQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        name={fieldConfig.field_identifier}
        value={props.value || ''}
        optional={optional}
        disabled={props.disabled}
      />
    );
  } else if (fieldConfig.field_type === 'year') {
    return (
      <YearQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        optional={optional}
        disabled={props.disabled}
        size="small"
      />
    )
  } else {
    return (
      <TextQuestion
        hint={fieldConfig.hint}
        question={fieldConfig.display_name}
        value={props.value}
        optional={optional}
        disabled={props.disabled}
      />
    )
  }
};

export default RenderCompletedQuestion;
