import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries, mediaQueriesUntil } from '../../styles/media.js';

function SectionReviewTable(props) {
  return (
    <StyledTable>
      {props.children}
    </StyledTable>
  )
}

const StyledTable = styled.table`
  border: 0;
  border-collapse: collapse;
  width: 100%;

  margin-bottom: 2em;

  ${mediaQueriesUntil.md} {
    thead {
      display: none;
    }
  }

  tr {
    border-bottom: 1px solid ${props => props.theme.color.paleGrey};
  }

  ${mediaQueriesUntil.md} {
    tr {
      display: flex;
      flex-direction: column;
      padding-top: 1em;
      padding-bottom: 0.75em;

      &:first-child {
        border-top: 0;
        padding-top: 0;
      }
    }
  }

  ${mediaQueries.md} {
    tbody tr:hover {
      background-color: rgba(0,0,0,.04);
    }
  }

  th,
  td {
    text-align: left;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }

  ${mediaQueries.md} {
    th,
    td {
      height: 56px;
      padding: 0.5em 1em;
    }

    th:first-child,
    td:first-child {
      padding-left: 0;
      width: 30%;
    }
  }

  ${mediaQueriesUntil.md} {
    td[data-title]:before {
      content: attr(data-title);
      padding-right: 0.5em;
    }

    td:first-child {
      font-weight: ${props => props.theme.fontWeight.bold};
    }

    td:empty {
      display: none;
    }
  }
`

export default SectionReviewTable;
