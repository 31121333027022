import React, { Component } from 'react';

import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media.js';

import Grid, { GridItem } from '../../components/Grid';
import FullWidthBackground from '../../components/FullWidthBackground';

class QuoteLandingPageCopy extends Component {
  constructor(props) {
    super(props);

    const defaultCopy = {
      bulletPoint1: 'Save hours with our online dashboard and proactive service.',
      bulletPoint2: 'Get peace of mind with guaranteed legal fees and no hidden extras.',
      bulletPoint3: 'Rated Excellent on TrustPilot and trusted by thousands every year.',
    };

    this.state = { copy: {} };

    if (this.props.campaign) {
      for (let identifier in defaultCopy) {
        fetch(
          `/copy?identifier=${identifier}&utm_campaign=${this.props.campaign}`,
        )
          .then(res => res.json())
          .then(res => {
            let copyNew = this.state.copy;
            copyNew[identifier] = res.Success
              ? res['Copy']
              : defaultCopy[identifier];
            this.setState({
              copy: copyNew,
            });
          })
          .catch(err => {
            this.setState({
              copy: defaultCopy[identifier],
            });
          });
      }
    } else {
      this.state.copy = defaultCopy;
    }
  }

  render() {
    return (
      <StyledFullWidthBackground pageWidth={this.props.pageWidth}>
        <StyledHeading>
          Simpler, clearer and faster conveyancing
        </StyledHeading>
        <Grid>
          <GridItem>
            <p>{this.state.copy.bulletPoint1}</p>
          </GridItem>
          <GridItem>
            <p>{this.state.copy.bulletPoint2}</p>
          </GridItem>
          <GridItem>
            <p>{this.state.copy.bulletPoint3}</p>
          </GridItem>
        </Grid>
        <h3>Get a quote in seconds</h3>
        <p>Answer a few simple questions for a quote that covers everything – including bank transfers, ID checks and searches.</p>
      </StyledFullWidthBackground>
    );
  }
}

export default QuoteLandingPageCopy;

const StyledFullWidthBackground = styled(FullWidthBackground)`
  ${mediaQueries.md} {
    top: -30px
  }
`

// TODO: Update theme to include 'display' heading styles
const StyledHeading = styled.h1`
  max-width: 38rem;
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: ${props => props.theme.fontWeight.light};
  margin-top: 1rem;
  margin-bottom: 0.75rem;

  ${mediaQueries.md} {
    font-size:3.25rem;
    line-height: 4rem;
    margin-bottom: 1rem;
  }
`
