import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { useSelectedCase } from "../utils/hooks";
import PageHeader from "../../../components/PageHeader";
import StatusUpdate from '../StatusUpdate';
import LastWorkedOn from '../LastWorkedOn';

import { loadDashboardData } from '../utils/api';

const BrokerCaseDetails = () => {
  const session = useSelector(state => state.dashboardState.session);
  const selectedCase = useSelectedCase({caseScreen: 'case'});
  const dispatch = useDispatch();

  useEffect(() => {
    loadDashboardData(dispatch);
  }, [dispatch]);

  const renderCaseDetails = (selectedCase) => {
    const clientNames = selectedCase.clients.map(cl => cl.last_name)
    let clientNameString
    if (clientNames.length === 1) {
      clientNameString = clientNames[0]
    } else if (clientNames.length === 2) {
      clientNameString = `${clientNames[0]} & ${clientNames[1]}`
    } else {
      const lastClient = clientNames.pop()
      clientNameString = clientNames.join(', ') + ` & ${lastClient}`
    }

    const cardTitle = `Here's where we've got to on ${clientNameString} ${selectedCase.case_type} ${selectedCase.address} (${selectedCase.case_reference})`

    return (
      <PageHeader>
        Hello {session.full_name}<br />
        {cardTitle}
      </PageHeader>
    )
  }

  if (!selectedCase) {
    return null;
  }

  return (
    <>
      {renderCaseDetails(selectedCase)}
      <StatusUpdate
        selectedCase={selectedCase} />
      <LastWorkedOn
        selectedCase={selectedCase} />
    </>
  )
}

export default BrokerCaseDetails
