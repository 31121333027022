import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import List, { ListItem } from '../../components/List';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { MORTGAGE_LENDER_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import { recordSignupTermination } from '../../utils/flowActivityUtils';

let mortgageLenders = [...MORTGAGE_LENDER_OPTIONS];
// Remove the last item 'Other' from the list
mortgageLenders.pop();

class SorryBuyingMortgageLender extends Component {
  componentDidMount() {
    recordSignupTermination(this.props.leadId, 'unhandled mortgage lender');
  }

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Sorry — we can’t take on your case" removeTopPadding>
          <p>We can only handle cases with the lenders listed below.</p>
          <List>
            {mortgageLenders.map((option, i) => (
              <ListItem option={option} key={i}>
                {option.title}
              </ListItem>
            ))}
          </List>

          <p>
            You’ll need to get a mortgage with one of these lenders to continue
            using Juno.
          </p>

          <Action onClick={this.props.showPrevious}>
            Go back to choose a different lender
          </Action>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default SorryBuyingMortgageLender;
