import React, { Component } from 'react';
import styled from 'styled-components/macro';

import Question from './Question';
import Fieldset from './Fieldset';
import { JunoLegend } from './Legend';
import Hint from './Hint';
import Input from './Input';
import Label from './Label';
import Error from './Error';

const DateOfBirthLegend = styled(JunoLegend)`
  padding-bottom: 0.5rem;
`;

/* stylelint-disable property-no-vendor-prefix */
const DateOfBirthField = styled.div`
  display: inline-block;
  margin-right: ${props => props.theme.spacingScale[3]};

  label {
    display: block;
  }

  input {
    max-width: 5.4ex;
    padding-right: 0.25em;
  }

  .dateOfBirthYear {
    max-width: 9ex;
  }
`;
/* stylelint-enable property-no-vendor-prefix */

class DateOfBirthQuestion extends Component {
  render() {
    const day = this.props.value.day;
    const month = this.props.value.month;
    const year = this.props.value.year;
    return (
      <Question>
        <Fieldset>
          <DateOfBirthLegend>
            {this.props.question
              ? this.props.question
              : 'What is your date of birth?'}
            {this.props.hint ? (
              this.props.hint
            ) : (
              <Hint>For example, 31 08 1981</Hint>
            )}
            {this.props.validationError ? (
              <Error>{this.props.validationError}</Error>
            ) : null}
          </DateOfBirthLegend>
          <DateOfBirthField>
            <Label htmlFor="dateOfBirthDay">Day</Label>
            <Input
              id="dateOfBirthDay"
              data-testid="date-of-birth-day"
              className="dateOfBirthDay"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              value={day}
              onChange={this.props.onChange('day')}
              onBlur={event => {
                if (this.props.validate) {
                  this.props.validate('day')(event.target.value);
                }
              }}
              error={!!this.props.validationError}
            />
          </DateOfBirthField>
          <DateOfBirthField>
            <Label htmlFor="dateOfBirthMonth">Month</Label>
            <Input
              id="dateOfBirthMonth"
              data-testid="date-of-birth-month"
              className="dateOfBirthMonth"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              value={month}
              onChange={this.props.onChange('month')}
              onBlur={event => {
                if (this.props.validate) {
                  this.props.validate('month')(event.target.value);
                }
              }}
              error={!!this.props.validationError}
            />
          </DateOfBirthField>
          <DateOfBirthField>
            <Label htmlFor="dateOfBirthYear">Year</Label>
            <Input
              id="dateOfBirthYear"
              data-testid="date-of-birth-year"
              className="dateOfBirthYear"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              value={year}
              onChange={this.props.onChange('year')}
              onBlur={event => {
                if (this.props.validate) {
                  this.props.validate('year')(event.target.value);
                }
              }}
              error={!!this.props.validationError}
            />
          </DateOfBirthField>
        </Fieldset>
      </Question>
    );
  }
}

export default DateOfBirthQuestion;
