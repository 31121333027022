import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { isMoneyValid, normaliseMoney } from '../../utils/moneyUtils';
import {
  isPercentageValid,
  normalisePercentage,
} from '../../utils/numberUtils';

class SharedOwnershipPurchaseShare extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      sharePercentage: 'Please fill this in',
      reservationPaymentMade: 'Please fill this in',
    };

    if (this.props.shareDetails) {
      const details = this.props.shareDetails;
      this.state = {
        sharePercentage: details.sharePercentage,
        reservationPaymentMade: details.reservationPaymentMade,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        sharePercentage: '',
        reservationPaymentMade: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (!fields['sharePercentage']) {
      errors['sharePercentage'] = true;
      formIsValid = false;
    }

    if (!fields['reservationPaymentMade']) {
      errors['reservationPaymentMade'] = true;
      formIsValid = false;
    }

    if (
      fields['reservationPaymentMade'] !== '' &&
      !isMoneyValid(fields['reservationPaymentMade'])
    ) {
      errors['reservationPaymentMade'] = true;
      errorMessages['reservationPaymentMade'] = 'Please enter a valid amount';
      formIsValid = false;
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handlePercentageValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isPercentageValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid percentage';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleMoneyValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isMoneyValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid amount';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  saveSharedOwnershipPurchaseShare = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        shareDetails: {
          sharePercentage: normalisePercentage(this.state.sharePercentage),
          reservationPaymentMade: normaliseMoney(
            this.state.reservationPaymentMade,
          ),
        },
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about your share of the property" removeTopPadding>
          <TextQuestion
            question="What percentage share of the property are you buying?"
            value={this.state.sharePercentage}
            valueEntered={this.handleInputChange('sharePercentage')}
            validate={this.handlePercentageValidation('sharePercentage')}
            validationError={
              this.state.errors.sharePercentage
                ? this.state.errorMessages.sharePercentage
                : null
            }
          />
          <TextQuestion
            question="How much is the reservation payment you've made to the housing association?"
            value={this.state.reservationPaymentMade}
            valueEntered={this.handleInputChange('reservationPaymentMade')}
            validate={this.handleMoneyValidation('reservationPaymentMade')}
            validationError={
              this.state.errors.reservationPaymentMade
                ? this.state.errorMessages.reservationPaymentMade
                : null
            }
          />

          <Action onClick={this.saveSharedOwnershipPurchaseShare}>
            Continue
          </Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default SharedOwnershipPurchaseShare;
