import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { isMoneyValid, normaliseMoney } from '../../utils/moneyUtils';

class BonusFromEmployer extends Component {
  constructor(props) {
    super(props);

    if (this.props.bonusFromEmployer) {
      const bonus = { ...this.props.bonusFromEmployer };
      this.state = {
        bonusFromEmployerAmount: bonus.bonusFromEmployerAmount,
        errors: {},
        errorMessages: {}
      };
    } else {
      this.state = {
        bonusFromEmployerAmount: '',
        errors: {},
        errorMessages: {}
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (!fields['bonusFromEmployerAmount']) {
      errors['bonusFromEmployerAmount'] = true;
      errorMessages['bonusFromEmployerAmount'] =
        'Please fill this in';
      formIsValid = false;
    }

    if (
      fields['bonusFromEmployerAmount'] !== '' &&
      !isMoneyValid(fields['bonusFromEmployerAmount'])
    ) {
      errors['bonusFromEmployerAmount'] = true;
      errorMessages['bonusFromEmployerAmount'] =
        'Please enter a valid amount';
      formIsValid = false;
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handleMoneyValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isMoneyValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid amount';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  saveBonusFromEmployer = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        bonusFromEmployer: {
          bonusFromEmployerAmount: normaliseMoney(
            this.state.bonusFromEmployerAmount,
          ),
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about the annual or one-off bonus from your employer" removeTopPadding>
          <p>
            We need to know how much you’re contributing towards the purchase from the bonus you’ve received from your employer.
          </p>

          <TextQuestion
            question="How much are you using towards the purchase?"
            value={this.state.bonusFromEmployerAmount}
            valueEntered={this.handleInputChange('bonusFromEmployerAmount')}
            validate={this.handleMoneyValidation('bonusFromEmployerAmount')}
            validationError={
              this.state.errors.bonusFromEmployerAmount
                ? this.state.errorMessages.bonusFromEmployerAmount
                : null
            }
          />

          <p>
            We’ll ask you to provide a payslip for the month you received the bonus.
          </p>
          <Action onClick={this.saveBonusFromEmployer}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default BonusFromEmployer;
