import styled, { css } from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const Input = styled.input`
  box-sizing: border-box;

  display: block;
  width: 100%;
  height: 3em;

  margin-top: 0;
  padding: 0.25em 0.75em 0.25em 0.25em;

  border: 2px solid #a7b1be;
  border-radius: 0;

  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: 1.5rem;
  font-weight: ${props => props.theme.fontWeight.light};
  appearance: none;

  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
    line-height: 35px;
  }

  &:focus {
    outline: 3px solid ${props => props.theme.color.junoFocus};
  }

  ${props =>
    props.error &&
    css`
      border-width: 4px;
      border-color: ${props => props.theme.color.junoError};
    `};

  ${props =>
    props.size === 'large' &&
    css`
      max-width: 15em;
    `};

  ${props =>
    props.size === 'medium' &&
    css`
      max-width: 11.25em;
    `};

  ${props =>
    props.size === 'small' &&
    css`
      max-width: 7.5em;
    `};

 ${props =>
    props.disabled &&
    css`
      font-weight: ${props => props.theme.fontWeight.bold};
      background: none;
    `};
`;

export default Input;
