import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ScrollToTop from '../../components/utils/ScrollToTop';
import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import Image from '../../components/Image';
import imageRelaxingOnSofa from '../../images/relaxing-on-sofa.png';

const InstructSavedQuote = (props) => {
  const { quoteId } = useParams()

  const [state, setState] = useState({
    loading: true,
    error: null,
    quoteDetails: null,
  });


  useEffect(() => {
    fetch('/api/instruct/' + quoteId, {
      method: 'POST',
    })
      .then(resp => resp.json())
      .then(
        quoteData => {
          if (!!quoteData.isValid) {
            setState({
              loading: false,
              error: null,
            });
          } else if (quoteData.error === 'expired') {
            setState({
              loading: false,
              error: 'This quote has expired',
            });
          } else if (quoteData.error === 'submitted') {
            setState({
              loading: false,
              error: "It looks like you've already instructed this case",
            });
          } else {
            setState({
              loading: false,
              error: 'Failed to instruct case',
            });
          }
        },
        () => {
            setState({
              error: 'Unable to generate your quote',
              loading: false,
            });

        },
      );
  })

    if (!!state.loading) {
      return null;
    }

    if (state.error) {
      return (
        <PageLayout>
          <ScrollToTop />
          <Card title="Sorry, that didn't work" removeTopPadding>
            {state.error}
          </Card>
        </PageLayout>
      );
    }

    return (
      <PageLayout>
        <Card title="We've instructed your case" removeTopPadding>
          <p>Thank you.</p>
          <p>We'll be in touch with your client to progress.</p>
        </Card>

        <Image src={imageRelaxingOnSofa} fullWidth/>
      </PageLayout>
    );

  }

export default InstructSavedQuote;
