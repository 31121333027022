import React, {Component} from 'react';
import styled, {css} from 'styled-components/macro';
import {createId, createDataTestId} from './utils/helpers';
import {mediaQueries} from '../styles/media.js';
import Question, {Optional} from './Question';
import Fieldset from './Fieldset';
import Legend from './Legend';
import Hint from './Hint';
import Error from './Error';

const Radio = styled.div`
  min-height: 32px;
  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: 1.5rem;

  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
    line-height: 1.75rem;
  }

  margin-bottom: 8px;
  padding-left: 2em;

  position: relative;

  ${props =>
    props.inline &&
    css`
      margin-right: 1em;
      float: left;
      clear: none;
    `};

  label {
    display: block;
    padding: 0.25em 0.75em;
    border: 2px solid transparent;
    cursor: pointer;

    touch-action: manipulation;

    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;

      width: 2em;
      height: 2em;

      border: 2px solid ${props => props.theme.color.junoBorder};
      border-radius: 50%;
      background: transparent;
    }

    &:after {
      content: '';

      position: absolute;
      top: 0.5em;
      left: 0.5em;

      width: 0;
      height: 0;

      border: 0.5em solid ${props => props.theme.color.junoBorder};
      border-radius: 50%;
      opacity: 0;
      background: #adb6c2;

    }
  }

  input {
    position: absolute;

    z-index: 1;
    top: 0;
    left: 0;

    width: 2em;
    height: 2em;

    cursor: pointer;
    margin: 0;
    opacity: 0;

    :checked + label:after {
      opacity: 1;
    }

    :focus + label:before {
      box-shadow: 0 0 0 4px ${props => props.theme.color.junoFocus};
    }

    :disabled,
    :disabled + label {
      cursor: auto;
    }

    :disabled:checked + label {
      font-weight: bold;
    }

    :disabled:checked + label::before,
    :disabled:checked + label::after {
      border-color: ${props => props.theme.color.junoText};
    }

  }
`;

class RadioQuestion extends Component {
  render() {
    return (
      <Question className={this.props.className}>
        <Fieldset>
          {this.props.hideLegend ? (
            <Legend>
              {this.props.validationError ? (
                <Error>{this.props.validationError}</Error>
              ) : null}
            </Legend>
          ) : (
            <Legend>
              {this.props.question}
              {this.props.optional ? <Optional /> : null}
              {this.props.hint ? <Hint>{this.props.hint}</Hint> : null}
              {this.props.validationError ? (
                <Error>{this.props.validationError}</Error>
              ) : null}
            </Legend>
          )}
          {this.props.options.map((option, i) => {
            const labelText = option.title || option.label;
            const componentId = createId('input', this.props.name, labelText);
            const dataTestId = createDataTestId('radio', this.props.name, labelText);

            return (
              <Radio option={option} key={i} inline={this.props.inline}>
                <input
                  type="radio"
                  name={this.props.name}
                  checked={this.props.selected === option.value}
                  onChange={event => {
                    this.props.optionSelected(option.value);
                  }}
                  onClick={this.props.validate}
                  value={option.value}
                  id={componentId}
                  data-testid={dataTestId}
                  disabled={this.props.disabled}
                />
                <label htmlFor={componentId}>{labelText}</label>
              </Radio>
            );
          })}
        </Fieldset>
      </Question>
    );
  }
}

export default RadioQuestion;
