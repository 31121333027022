import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';

class SellingLoan2 extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      securedLoanLender: 'Please enter your lender below',
      securedLoanAccountNumber: 'Enter your account number',
    };

    if (this.props.loan2) {
      const loan2 = { ...this.props.loan2 };
      this.state = {
        securedLoanLender: loan2.securedLoanLender,
        securedLoanAccountNumber: loan2.securedLoanAccountNumber,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        securedLoanLender: '',
        securedLoanAccountNumber: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};

    let formIsValid = true;

    if (!fields['securedLoanLender']) {
      const field = 'securedLoanLender';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields['securedLoanAccountNumber']) {
      const field = 'securedLoanAccountNumber';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  saveSellingLoan2 = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        loan2: {
          securedLoanLender: this.state.securedLoanLender,
          securedLoanAccountNumber: this.state.securedLoanAccountNumber,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Your second loan" removeTopPadding>
          <TextQuestion
            question="Tell us your loan lender"
            value={this.state.securedLoanLender || ''}
            valueEntered={this.handleInputChange('securedLoanLender')}
            validate={this.handleFieldValidation('securedLoanLender')}
            validationError={
              this.state.errors.securedLoanLender
                ? this.state.errors.securedLoanLender
                : null
            }
          />

          <TextQuestion
            question="What’s the account number?"
            value={this.state.securedLoanAccountNumber || ''}
            valueEntered={this.handleInputChange('securedLoanAccountNumber')}
            validate={this.handleFieldValidation('securedLoanAccountNumber')}
            validationError={
              this.state.errors.securedLoanAccountNumber
                ? this.state.errors.securedLoanAccountNumber
                : null
            }
          />

          <Action onClick={this.saveSellingLoan2}>Submit loan details</Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default SellingLoan2;
