import React, { Component } from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';

import { getReferringBranchConfig } from './utils/ReferrerQuoteUtils';

class ReferrerQuoteSubmission extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    let channel = this.props.defaultChannel;
    let referrer = this.props.defaultReferrer;
    if (this.props.referral && this.props.referral.referralSource) {
      channel = this.props.referral.referralSource;
      referrer = this.props.referral.referralCode;
    }
    this.state = {
      selectedTransactionType: this.props.transactionType,
      transactionType: this.props.transactionType,
      buyingDetails: this.props.buyingDetails,
      sellingDetails: this.props.sellingDetails,
      remortgagingDetails: this.props.remortgagingDetails,
      personalDetails: this.props.personalDetails,
      agentName: this.props.agentName,
      agentEmailAddress: this.props.agentEmailAddress,
      additionalInformation: this.props.additionalInformation,
      channel: channel,
      quoteDetails: null,
      loading: true,
      error: null,
      showReferralCodeBanner: !this.props.inBranchReferral,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    let channel = this.props.defaultChannel;
    let referrer = this.props.defaultReferrer;
    if (this.props.referral && this.props.referral.referralSource) {
      channel = this.props.referral.referralSource;
      referrer = this.props.referral.referralCode;
    }

    // Referrer here is the referring branch, e.g. Berkhamsted Brown and Merry
    if (this.props.inBranchReferral) {
      let branch = getReferringBranchConfig(this.props.defaultReferrer);
      referrer = branch.referrer;
    }

    fetch('/api/quote', {
      method: 'PUT',
      body: JSON.stringify({
        transactionType: this.props.transactionType,
        channel: channel,
        campaign: this.props.campaign,
        referrer: referrer,
        agentName: this.props.agentName,
        agentEmailAddress: this.props.agentEmailAddress,
        additionalInformation: this.props.additionalInformation,
        personalDetails: this.props.personalDetails,
        sellingDetails:
          this.props.transactionType === 'Selling' ||
          this.props.transactionType === 'BuyingAndSelling'
            ? this.props.sellingDetails
            : null,
        buyingDetails:
          this.props.transactionType === 'Buying' ||
          this.props.transactionType === 'BuyingAndSelling'
            ? this.props.buyingDetails
            : null,
        remortgagingDetails:
          this.props.transactionType === 'Remortgaging'
            ? this.props.remortgagingDetails
            : null,
        referralCode: this.props.referral ? this.props.referral.referralCode : null,
        googleAnalyticsId: this.props.googleAnalyticsId,
      }),
    })
      .then(resp => resp.json())
      .then(
        quoteData => {
          if (this._isMounted) {
            this.setState({
              quoteDetails: quoteData,
              loading: false,
              submitted: true,
            });
            this.props.onFlowComplete({
              quoteDetails: quoteData,
            });
          }
        },
        () => {
          if (this._isMounted) {
            this.setState({
              error: 'Unable to generate your quote',
              loading: false,
            });
          }
        },
      );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn't work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    if (this.state.submitted) {
      return null;
    }
  }
}

export default ReferrerQuoteSubmission;
