import React from 'react';
import styled from 'styled-components/macro';
import {mediaQueries} from '../styles/media.js';

const JunoQuestionDescription = styled.div`
  margin-bottom: 1rem;
  ${mediaQueries.md} {
    margin-bottom: 1.75rem;
  }
  max-width: 38rem;

  font-size: ${props => props.theme.fontSizes.p['sm']};

  p:first-child {
    margin-top: 0;
  }

  ul {
    display: block;
    color: ${props => props.theme.color.junoText};
    font-family: ${props => props.theme.junoFont};
    font-weight: ${props => props.theme.fontWeight.light};
    font-size: ${props => props.theme.fontSizes.p['sm']};
    line-height: 1.5;
    margin-bottom: 1em;

    ${mediaQueries.md} {
      font-size: ${props => props.theme.fontSizes.p['md']};
      margin-bottom: 1.5em;
      line-height: 1.75;
    }

    padding-left: 24px;
    list-style-type: disc;

   li {
     margin-bottom: 2px;
    ${mediaQueries.md} {
      margin-bottom: 4px;
    }
   }
  }
`;

function QuestionDescription(props) {
  return (
    <JunoQuestionDescription {...props}>
      {props.children}
    </JunoQuestionDescription>
  );
}

export default QuestionDescription;
