import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media';

const JunoHeading = styled.h1`
  font-family: ${props => props.theme.junoFont};
  font-style: normal;
  line-height: 1.25em;
  font-weight: ${props => props.theme.fontWeight.bold};

  margin-bottom: 0.5em;
  ${mediaQueries.md} {
    margin-bottom: 0.75em;
  }
  ${mediaQueries.lg} {
    margin-bottom: 1em;
  }

  &.h1 {
    margin-bottom: 1em;
    font-size: ${props => props.theme.fontSizes.h1['sm']};
    ${mediaQueries.md} {
      font-size: ${props => props.theme.fontSizes.h1['md']};
    }
  }

  &.h2 {
    font-size: ${props => props.theme.fontSizes.h2['sm']};
    margin-left: -0.02em;
    ${mediaQueries.md} {
      font-size: ${props => props.theme.fontSizes.h2['md']};
    }
  }

  &.h3 {
    font-size: ${props => props.theme.fontSizes.h3['sm']};
    ${mediaQueries.md} {
      font-size: ${props => props.theme.fontSizes.h3['md']};
    }
  }

  &.h4 {
    font-size: ${props => props.theme.fontSizes.h4['sm']};
    ${mediaQueries.md} {
      font-size: ${props => props.theme.fontSizes.h4['md']};
    }
  }
`;

const Heading = ({ level, size, ...props }) => {
  return (
    <JunoHeading
      as={`h${level}`}
      className={size ? `h${size}` : `h${level}`}
      {...props}
    />
  );
};

Heading.defaultProps = {
  level: 1,
  size: null,
};

export default Heading;
