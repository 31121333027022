import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import DateOfBirthQuestion from '../../components/DateOfBirthQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants';
import { isDayValid, isMonthValid, isYearValid } from "../../utils/textUtils";
class RemoClient1 extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      annualIncome: 'Enter your annual income',
      dateOfBirth: 'Enter your date of birth',
      nationality: 'Enter your nationality',
      occupation: 'Enter your occupation',
      hasMiddleNames: 'Let us know if you have any middle names',
      middleNames: 'Please fill this in',
    };

    if (this.props.client) {
      this.state = {
        client: { ...this.props.client },
        errors: {},
        errorMessages: errorMessages,
        dateErrors: {},
      };
    } else {
      this.state = {
        client: {
          annualIncome: '',
          dateOfBirth: {
            day: '',
            month: '',
            year: '',
          },
          nationality: '',
          occupation: '',
          hasMiddleNames: null,
          middleNames: '',
        },
        errors: {},
        errorMessages: errorMessages,
        dateErrors: {},
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state.client };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (!fields['annualIncome']) {
      errors['annualIncome'] = true;
      formIsValid = false;
    }

    if (
      fields['annualIncome'] !== '' &&
      !this.isMoneyValid(fields['annualIncome'])
    ) {
      errors['annualIncome'] = true;
      errorMessages['annualIncome'] = 'Please enter a valid amount';
      formIsValid = false;
    }

    if (
      !fields['dateOfBirth'].day ||
      !fields['dateOfBirth'].month ||
      !fields['dateOfBirth'].year
    ) {
      errors['dateOfBirth'] = true;
      formIsValid = false;
    }

    if (!fields['nationality']) {
      errors['nationality'] = true;
      formIsValid = false;
    }

    if (!fields['occupation']) {
      errors['occupation'] = true;
      formIsValid = false;
    }

    if (fields['hasMiddleNames'] === null) {
      errors['hasMiddleNames'] = true;
      formIsValid = false;
    }

    if (fields['hasMiddleNames'] === true) {
      if (!fields['middleNames']) {
        errors['middleNames'] = true;
        formIsValid = false;
      }
    }

    this.setState({ errors, errorMessages });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state.client[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleDateFieldValidation = field => datePart => value => {
    let dateErrors = { ...this.state.dateErrors };
    let errors = { ...this.state.errors };
    let dateField = dateErrors[field] || {};

    if (datePart === "day") {
      dateField["day"] = !isDayValid(value)
    }

    if (datePart === "month") {
      dateField["month"] = !isMonthValid(value)
    }

    if (datePart === "year") {
      dateField["year"] = !isYearValid(value)
    }

    dateErrors[field] = dateField

    const allValues = Object.values(dateErrors[field]);
    const dateFieldIsValid = allValues.every(item => item === false);
    errors[field] = !dateFieldIsValid;

    this.setState({ errors, dateErrors });
  };

  normaliseMoney = m => {
    const stripCharsRegex = /[£, ]/g;
    return m.replace(stripCharsRegex, '');
  };

  isMoneyValid = money => {
    const allowedCharsRegex = /^[0-9£,. ]+$/;
    if (allowedCharsRegex.test(String(money))) {
      const normalised = this.normaliseMoney(money);

      const decimalPlaceRegex = /^\d+\.?\d{0,2}$/;

      return decimalPlaceRegex.test(String(normalised));
    }
    return false;
  };

  handleMoneyValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.isMoneyValid(this.state.client[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid amount';
      }
      if (!this.state.client[field]) {
        errors[field] = true;
        errorMessages[field] = 'Enter your annual income';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleInputChange = field => event => {
    let client = { ...this.state.client };
    client[field] = event;
    this.setState({ client });
  };

  handleDateChange = field => event => {
    let dateOfBirth = { ...this.state.client.dateOfBirth };
    dateOfBirth[field] = event.target.value;
    const updatedClient = { ...this.state.client, dateOfBirth: dateOfBirth };
    this.setState({ client: updatedClient });
  };

  saveRemoClient1 = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        client: this.state.client,
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us a bit about you" removeTopPadding>
          <p>
            We need these details to start the legal work for your remortgage.
            Make sure everything you enter here is correct — the wrong
            information could hold things up.
          </p>

          <DateOfBirthQuestion
            value={this.state.client.dateOfBirth}
            onChange={this.handleDateChange}
            validate={this.handleDateFieldValidation('dateOfBirth')}
            validationError={
              this.state.errors.dateOfBirth
                ? this.state.errorMessages.dateOfBirth
                : null
            }
          />

          <RadioQuestion
            question="Do you have any middle names?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="hasMiddleNames"
            selected={this.state.client.hasMiddleNames}
            optionSelected={this.handleInputChange('hasMiddleNames')}
            validate={this.handleFieldValidation('hasMiddleNames')}
            validationError={
              this.state.errors.hasMiddleNames
                ? this.state.errorMessages.hasMiddleNames
                : null
            }
          />
          {this.state.client.hasMiddleNames && (
            <RadioConditional>
              <TextQuestion
                question="Middle names"
                value={this.state.client.middleNames || ''}
                valueEntered={this.handleInputChange('middleNames')}
                validate={this.handleFieldValidation('middleNames')}
                validationError={
                  this.state.errors.middleNames
                    ? this.state.errorMessages.middleNames
                    : null
                }
              />
            </RadioConditional>
          )}

          <TextQuestion
            question="Nationality"
            value={this.state.client.nationality || ''}
            valueEntered={this.handleInputChange('nationality')}
            validate={this.handleFieldValidation('nationality')}
            validationError={
              this.state.errors.nationality
                ? this.state.errorMessages.nationality
                : null
            }
          />

          <TextQuestion
            question="Your job"
            value={this.state.client.occupation || ''}
            valueEntered={this.handleInputChange('occupation')}
            validate={this.handleFieldValidation('occupation')}
            validationError={
              this.state.errors.occupation
                ? this.state.errorMessages.occupation
                : null
            }
          />

          <TextQuestion
            question="Annual income"
            hint="Enter the full amount before tax, including any bonuses"
            value={this.state.client.annualIncome || ''}
            valueEntered={this.handleInputChange('annualIncome')}
            validate={this.handleMoneyValidation('annualIncome')}
            validationError={
              this.state.errors.annualIncome
                ? this.state.errorMessages.annualIncome
                : null
            }
          />

          <Action onClick={this.saveRemoClient1}>Continue</Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default RemoClient1;
