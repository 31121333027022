import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';
import { ArrowBackIos } from '@styled-icons/material';

const JunoBackButtonWrapper = styled.div`
  background-color: white;
  position: relative;
  left: 0;
  top: 0;
  padding-top: 15px;
  padding-left: 20px;
  margin-bottom: -15px;
  ${mediaQueries.md} {
    position: absolute;
    padding-top: 30px;
    padding-left: 30px;
    background-color: transparent;
  }
`;

const JunoBackButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  text-align: left;
  padding: 0;

  color: ${props => props.theme.color.pink};

  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: normal;
  font-family: ${props => props.theme.junoFontSecondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
  }

  &:focus {
    outline: solid transparent;
  }

  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
`;

const BackButtonIcon = styled(ArrowBackIos)`
  width: ${props => props.theme.iconSize['sm']};
  ${mediaQueries.md} {
    width: ${props => props.theme.iconSize['md']};
  }
`

function BackButton(props) {
  return (
    <JunoBackButtonWrapper>
      <JunoBackButton {...props}>
        <BackButtonIcon />
        Back
      </JunoBackButton>
    </JunoBackButtonWrapper>
  );
}

export default BackButton;
