import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const JunoError = styled.div`
  margin: 0;
  padding: 2px 0;
  clear: both;
  color: ${props => props.theme.color.red};

  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: normal;
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
  }

  ${props => props.loginError && ('margin-bottom: 1rem; max-width: 38rem;')};

`;

function Error(props) {
  return <JunoError {...props}>{props.children}</JunoError>;
}

export default Error;
