import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

const yesNoCallMeOptions = [
  { title: 'Yes', value: true },
  { title: 'No, I prefer to be called...', value: false },
];

class ClientName extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      firstName: 'Enter your first name',
      lastName: 'Enter your last name',
      hasMiddleNames: 'Let us know if you have any middle names',
      middleNames: 'Please fill this in',
      hasPreviousName: 'Let us know if you have changed your last name',
      previousName: 'Please fill this in',
      firstNameIsGivenName: 'Let us know the name we should use',
      givenName: 'Please fill this in',
    };

    if (this.props.clientName) {
      const details = this.props.clientName;
      this.state = {
        client: { ...details.client },
        errors: { ...details.errors },
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        client: {
          firstName: '',
          lastName: '',
          hasMiddleNames: null,
          middleNames: '',
          hasPreviousName: null,
          previousName: '',
          firstNameIsGivenName: null,
          givenName: '',
        },
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state.client };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (!fields['firstName']) {
      const field = 'firstName';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields['lastName']) {
      const field = 'lastName';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['hasPreviousName'] === null) {
      const field = 'hasPreviousName';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['hasPreviousName'] === true) {
      if (!fields['previousName']) {
        const field = 'previousName';
        formIsValid = false;
        errors[field] = errorMessages[field];
      }
    }

    if (fields['hasMiddleNames'] === null) {
      const field = 'hasMiddleNames';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['hasMiddleNames'] === true) {
      if (!fields['middleNames']) {
        const field = 'middleNames';
        formIsValid = false;
        errors[field] = errorMessages[field];
      }
    }

    if (fields['firstNameIsGivenName'] === null) {
      const field = 'firstNameIsGivenName';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['firstNameIsGivenName'] === false) {
      if (!fields['givenName']) {
        const field = 'givenName';
        formIsValid = false;
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      // Hide the error when you interact with the field
      errors[field] = null;
    } else {
      // If the state for this field hasn't been set, we need some input, show an error
      if (!this.state.client[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleClientName = field => event => {
    let client = { ...this.state.client };
    client[field] = event;
    this.setState({ client });
  };

  canWeUseYourFirstName = () => {
    return 'Can we call you ‘' + this.state.client.firstName + '’?';
  };

  saveClientName = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        clientName: {
          client: this.state.client,
          errors: this.state.errors,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us a bit about you" removeTopPadding>
          <TextQuestion
            question="What’s your first name?"
            value={this.state.client.firstName || ''}
            valueEntered={this.handleClientName('firstName')}
            validate={this.handleFieldValidation('firstName')}
            validationError={
              this.state.errors.firstName ? this.state.errors.firstName : null
            }
          />

          <TextQuestion
            question="And your last name?"
            value={this.state.client.lastName || ''}
            valueEntered={this.handleClientName('lastName')}
            validate={this.handleFieldValidation('lastName')}
            validationError={
              this.state.errors.lastName ? this.state.errors.lastName : null
            }
          />

          <RadioQuestion
            question="Have you ever changed your last name?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="hasPreviousName"
            selected={this.state.client.hasPreviousName}
            optionSelected={this.handleClientName('hasPreviousName')}
            validate={this.handleFieldValidation('hasPreviousName')}
            validationError={
              this.state.errors.hasPreviousName
                ? this.state.errors.hasPreviousName
                : null
            }
          />

          {this.state.client.lastName && this.state.client.hasPreviousName && (
            <RadioConditional>
              <TextQuestion
                question="What was your last name?"
                value={this.state.client.previousName || ''}
                valueEntered={this.handleClientName('previousName')}
                validate={this.handleFieldValidation('previousName')}
                validationError={
                  this.state.errors.previousName
                    ? this.state.errors.previousName
                    : null
                }
              />
            </RadioConditional>
          )}

          <RadioQuestion
            question="Do you have any middle names?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="hasMiddleNames"
            selected={this.state.client.hasMiddleNames}
            optionSelected={this.handleClientName('hasMiddleNames')}
            validate={this.handleFieldValidation('hasMiddleNames')}
            validationError={
              this.state.errors.hasMiddleNames
                ? this.state.errors.hasMiddleNames
                : null
            }
          />

          {this.state.client.hasMiddleNames && (
            <RadioConditional>
              <TextQuestion
                question="Middle names"
                value={this.state.client.middleNames || ''}
                valueEntered={this.handleClientName('middleNames')}
                validate={this.handleFieldValidation('middleNames')}
                validationError={
                  this.state.errors.middleNames
                    ? this.state.errors.middleNames
                    : null
                }
              />
            </RadioConditional>
          )}

          {this.state.client.firstName &&
            this.state.client.lastName &&
            this.state.client.hasPreviousName !== undefined &&
            this.state.client.hasMiddleNames !== undefined && (
              <RadioQuestion
                question={this.canWeUseYourFirstName()}
                options={yesNoCallMeOptions}
                inline={true}
                name="firstNameIsGivenName"
                selected={this.state.client.firstNameIsGivenName}
                optionSelected={this.handleClientName('firstNameIsGivenName')}
                validate={this.handleFieldValidation('firstNameIsGivenName')}
                validationError={
                  this.state.errors.firstNameIsGivenName
                    ? this.state.errors.firstNameIsGivenName
                    : null
                }
              />
            )}
          {this.state.client.firstName &&
            this.state.client.lastName &&
            this.state.client.hasPreviousName !== undefined &&
            this.state.client.hasMiddleNames !== undefined &&
            this.state.client.firstNameIsGivenName === false && (
              <RadioConditional>
                <TextQuestion
                  question="What name would you like us to use?"
                  value={this.state.client.givenName || ''}
                  valueEntered={this.handleClientName('givenName')}
                  validate={this.handleFieldValidation('givenName')}
                  validationError={
                    this.state.errors.givenName
                      ? this.state.errors.givenName
                      : null
                  }
                />
              </RadioConditional>
            )}

          <Action onClick={this.saveClientName}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default ClientName;
