import React, {Component} from 'react';
import PageLayout from '../../components/PageLayout';
import TextQuestion from '../../components/TextQuestion';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';
import Error from '../../components/Error';
import { updateAnalyticsIdentity } from '../../utils/analyticsUtils';
import LinkSent from "./LinkSent";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      emailAddress: '',
      loginSent: false,
      errors: {},
      error: false,
    };
  }

  handleInputChange = field => event => {
    let state = this.state;
    state[field] = event;
    this.setState(state);
  };

  handleFieldValidation = field => event => {
    let value = this.state[field];
    let errors = this.state.errors;

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (value === '') {
        errors[field] = true;
      } else {
        errors[field] = false;
      }
    }
    this.setState({errors: errors});
  };

  validate = () => {
    const fields = {...this.state};
    let errors = {};
    let formIsValid = true;

    if (fields['emailAddress'] === '') {
      errors['emailAddress'] = true;
      formIsValid = false;
    }

    this.setState({errors});
    return formIsValid;
  };

  onSubmit = () => {
    if (this.validate()) {
      updateAnalyticsIdentity(this.state.emailAddress.trim())

      fetch(`/api/login`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email_address: this.state.emailAddress.trim(),
        }),
      })
        .then(res => res.json())
        .then(res => {
          if (res.status === 'success') {
            this.setState({loginSent: true});
          } else if (res.status === 'no_match') {
            this.setState({error: true});
          }
        });
    }
  };

  render() {

    if (!!this.state.loginSent) {
      return (
        <PageLayout pageWidth="narrow">
          <LinkSent emailAddress={this.state.emailAddress} />
        </PageLayout>
      )
    }

    let loginErrorText;
    const {error} = this.state;

    if (error) {
      loginErrorText = (
        <Error loginError={true}>
          The email address you supplied does not match our records. Please
          check you have typed it correctly.
        </Error>
      );
    }

    return (
      <PageLayout pageWidth="narrow">
        <ScrollToTop />
        <Card
          title="Sign in to your Juno dashboard"
          removeTopPadding
          headingSize="1"
        >
          {loginErrorText}

          <TextQuestion
            question="Email address"
            value={this.state.emailAddress}
            valueEntered={this.handleInputChange('emailAddress')}
            validate={this.handleFieldValidation('emailAddress')}
            validationError={
              this.state.errors.emailAddress
                ? 'Please enter an email address'
                : null
            }
          />

          <Action small onClick={this.onSubmit}>
            Email me a link to sign in instantly
          </Action>

        </Card>
      </PageLayout>
    );
  }
}

export default Login;
