import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';
import { StyledIconBase } from '@styled-icons/styled-icon';
import { Search as SearchIcon, Close as CloseIcon } from '@styled-icons/material-rounded';

function Search({ value, onSearch, onClearSearch }) {
  return (
    <SearchBar role="search">
      <Input type="text" placeholder="Search" onChange={(e) => onSearch(e.target.value)} value={value} />
      <Button onClick={() => onClearSearch()}>
        {value ? <Icon><CloseIcon title="clear search" /></Icon> : <Icon ><SearchIcon title="Search" /></Icon>}
      </Button>
    </SearchBar>
  );
}

export default Search;

Search.propTypes = {
  value: PropTypes.string,
  onSearch: PropTypes.func,
  onClearSearch: PropTypes.func,
};

const sharedStyles = `
  background: #ebecf0;
  color: ${props => props.theme.color.junoText};
`

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-items: space-between;
  margin-right: 1rem;
  ${sharedStyles}
  height: 2.5rem;
  border-radius: 9999px;
  padding: 4px 4px 4px 16px;
`

const Input = styled.input`
  display: inline-block;

  ${sharedStyles}

  height: 100%;
  outline: none;
  border-color: transparent;

  ${mediaQueries.lg} {
    width: 300px;
  }

  font-size: 1rem;
  font-weight: ${props => props.theme.fontWeight.regular};
  margin-right: 6px;
`

const Button = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;

  border: 0;
  background: ${props => props.theme.color.purple};
  position: relative;
`;

const Icon = styled.i`
  ${StyledIconBase} {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    color: white;
  }

  ${Button}:hover & {
    cursor: pointer;
  }
`;
