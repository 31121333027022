import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class SellingEstateAgent extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      hasEstateAgent: 'Let us know if you have an estate agent',
      estateAgent: {
        company: 'Enter the company name',
        name: 'Enter their full name',
        emailAddress: 'Enter the email address',
        phoneNumber: 'Enter the phone number',
      },
      buyerFullName: 'Enter their full name',
      buyerEmailAddress: 'Enter their email address',
      buyerPhoneNumber: 'Enter their phone number',
    };

    if (this.props.sellingEstateAgent) {
      const ea = this.props.sellingEstateAgent;

      this.state = {
        hasEstateAgent: ea.hasEstateAgent,
        estateAgent: { ...ea.estateAgent },
        buyerFullName: ea.buyerFullName,
        buyerEmailAddress: ea.buyerEmailAddress,
        buyerPhoneNumber: ea.buyerPhoneNumber,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        hasEstateAgent: null,
        estateAgent: {
          company: '',
          name: '',
          emailAddress: '',
          phoneNumber: '',
        },
        buyerFullName: '',
        buyerEmailAddress: '',
        buyerPhoneNumber: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const estateAgentFields = { ...this.state.estateAgent };
    const errorMessages = { ...this.state.errorMessages };
    const propertyOfferAccepted = this.props.propertyOfferAccepted;

    let errors = {};
    let formIsValid = true;

    if (fields['hasEstateAgent'] === null) {
      const field = 'hasEstateAgent';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['hasEstateAgent'] === true) {
      if (!estateAgentFields['name']) {
        const field = 'name';
        formIsValid = false;
        errors[field] = errorMessages.estateAgent[field];
      }
      if (!estateAgentFields['company']) {
        const field = 'company';
        formIsValid = false;
        errors[field] = errorMessages.estateAgent[field];
      }
      if (!estateAgentFields['emailAddress']) {
        const field = 'emailAddress';
        formIsValid = false;
        errors[field] = errorMessages.estateAgent[field];
      }
      if (!estateAgentFields['phoneNumber']) {
        const field = 'phoneNumber';
        formIsValid = false;
        errors[field] = errorMessages.estateAgent[field];
      }
    }

    if (propertyOfferAccepted && fields['hasEstateAgent'] === false) {
      if (!fields['buyerFullName']) {
        const field = 'buyerFullName';
        formIsValid = false;
        errors[field] = errorMessages[field];
      }
      if (!fields['buyerEmailAddress']) {
        const field = 'buyerEmailAddress';
        formIsValid = false;
        errors[field] = errorMessages[field];
      }
      if (!fields['buyerPhoneNumber']) {
        const field = 'buyerPhoneNumber';
        formIsValid = false;
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }
    this.setState({ errors });
  };

  handleEstateAgentFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = {
      ...this.state.errorMessages,
    };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state.estateAgent[field]) {
        errors[field] = errorMessages.estateAgent[field];
      }
    }
    this.setState({ errors });
  };

  selectHasEstateAgent = ea => {
    this.setState({ hasEstateAgent: ea });
  };

  handleEstateAgentChange = field => event => {
    let estateAgent = { ...this.state.estateAgent };
    estateAgent[field] = event;
    this.setState({ estateAgent });
  };

  buyerFullNameEntered = bfn => {
    this.setState({ buyerFullName: bfn });
  };

  buyerEmailAddressEntered = bea => {
    this.setState({ buyerEmailAddress: bea });
  };

  buyerPhoneNumberEntered = bpn => {
    this.setState({ buyerPhoneNumber: bpn });
  };

  saveSellingEstateAgent = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        sellingEstateAgent: {
          hasEstateAgent: this.state.hasEstateAgent,
          estateAgent: this.state.estateAgent,
          buyerFullName: this.state.buyerFullName,
          buyerEmailAddress: this.state.buyerEmailAddress,
          buyerPhoneNumber: this.state.buyerPhoneNumber,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about your estate agent" removeTopPadding>
          <RadioQuestion
            question="Are you using an estate agent to sell your property?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="estateAgent"
            selected={this.state.hasEstateAgent}
            optionSelected={this.selectHasEstateAgent}
            validate={this.handleFieldValidation('hasEstateAgent')}
            validationError={
              this.state.errors.hasEstateAgent
                ? this.state.errors.hasEstateAgent
                : null
            }
          />

          {this.state.hasEstateAgent && (
            <RadioConditional>
              <p>Great, we’ll get in touch with them to introduce ourselves.</p>

              <TextQuestion
                question="Who’s handling your sale?"
                hint="If there’s more than one person, who do you speak to the most?"
                value={this.state.estateAgent.name || ''}
                valueEntered={this.handleEstateAgentChange('name')}
                validate={this.handleEstateAgentFieldValidation('name')}
                validationError={
                  this.state.errors.name ? this.state.errors.name : null
                }
              />

              <TextQuestion
                question="What company do they work for?"
                value={this.state.estateAgent.company || ''}
                valueEntered={this.handleEstateAgentChange('company')}
                validate={this.handleEstateAgentFieldValidation('company')}
                validationError={
                  this.state.errors.company ? this.state.errors.company : null
                }
              />

              <TextQuestion
                question="What’s their email address?"
                value={this.state.estateAgent.emailAddress || ''}
                valueEntered={this.handleEstateAgentChange('emailAddress')}
                validate={this.handleEstateAgentFieldValidation('emailAddress')}
                validationError={
                  this.state.errors.emailAddress
                    ? this.state.errors.emailAddress
                    : null
                }
              />

              <TextQuestion
                question="What’s their phone number?"
                value={this.state.estateAgent.phoneNumber || ''}
                valueEntered={this.handleEstateAgentChange('phoneNumber')}
                validate={this.handleEstateAgentFieldValidation('phoneNumber')}
                validationError={
                  this.state.errors.phoneNumber
                    ? this.state.errors.phoneNumber
                    : null
                }
              />
            </RadioConditional>
          )}

          {this.state.hasEstateAgent === false &&
            this.props.propertyOfferAccepted && (
              <RadioConditional>
                <p>
                  In that case, we’ll get in touch with your buyer directly to
                  introduce ourselves
                </p>

                <TextQuestion
                  question="What’s the buyer’s name?"
                  value={this.state.buyerFullName}
                  valueEntered={this.buyerFullNameEntered}
                  validate={this.handleFieldValidation('buyerFullName')}
                  validationError={
                    this.state.errors.buyerFullName
                      ? this.state.errors.buyerFullName
                      : null
                  }
                />

                <TextQuestion
                  question="What’s their email address?"
                  value={this.state.buyerEmailAddress}
                  valueEntered={this.buyerEmailAddressEntered}
                  validate={this.handleFieldValidation('buyerEmailAddress')}
                  validationError={
                    this.state.errors.buyerEmailAddress
                      ? this.state.errors.buyerEmailAddress
                      : null
                  }
                />

                <TextQuestion
                  question="What’s their phone number?"
                  value={this.state.buyerPhoneNumber}
                  valueEntered={this.buyerPhoneNumberEntered}
                  validate={this.handleFieldValidation('buyerPhoneNumber')}
                  validationError={
                    this.state.errors.buyerPhoneNumber
                      ? this.state.errors.buyerPhoneNumber
                      : null
                  }
                />
              </RadioConditional>
            )}

          <Action onClick={this.saveSellingEstateAgent}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default SellingEstateAgent;
