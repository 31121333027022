import React from 'react';
import StatusUpdateTitle from './StatusUpdateTitle';
import DescriptionBlocks from './DescriptionBlocks';

import styled from 'styled-components/macro';

function StatusUpdateSection({ status, title, descriptions, description_blocks }) {
  return (
    <Section>
      <StatusUpdateTitle status={status}>{title}</StatusUpdateTitle>
      <DescriptionBlocks
        status={status}
        descriptions={descriptions ? descriptions : null}
        description_blocks={description_blocks ? description_blocks : null } />
    </Section>
  );
};

export default StatusUpdateSection;

const Section = styled.section`
  width: 100%;
  /* Prevent text breaking out of the flex container */
  a {
    word-break: break-word;
  }
`;
