import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';

const RedirectOldReferAClientRoute = props => {
  let location = useLocation();
  const newRoute = location.pathname.replace('refer-a-client', 'refer')
  return <Navigate to={`${newRoute}${location.search}`}/>
}

export default RedirectOldReferAClientRoute
