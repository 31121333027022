import React from 'react';
import PropTypes from 'prop-types';
import StyledTodoListItem, { TodoText } from './StyledTodoListItem'

function ListCompleteRequest(props) {

  if (props.completeRequests.length === 0) {
    return null;
  }

  return (
    <>
      {props.completeRequests.map(doable => (
        <StyledTodoListItem
          key={doable.id}
          clickable={false}
          complete={true}
        >
          <TodoText complete>{doable.title}</TodoText>
        </StyledTodoListItem>
      ))}
    </>
  );
}

ListCompleteRequest.propTypes = {
  completeRequests: PropTypes.array.isRequired
};

export default ListCompleteRequest;
