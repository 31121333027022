import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import List, {ListItem} from '../../components/List';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

class EsignatureRequestIntro extends Component {

  render() {
    const title = this.props.flowState.title
    const witnessedSignature = this.props.flowState.witnessedSignature

    if (!! witnessedSignature) {
      const witnessedThing = `${witnessedSignature.first_name} ${witnessedSignature.last_name}’s signature`
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card
            title={title}
            removeTopPadding
            headingSize="1"
          >
            <p>
              Thanks for witnessing {witnessedThing}.
            </p>
            {this.props.flowState.smsVerificationRequired && (<>
              <p>
                We’ll verify your identity by sending you a text message with a 6-digit code.
              </p>
              <p>
                Once you’ve entered the code, you can sign the document.
              </p>
            </>)}
            <Action onClick={() => this.props.onStepComplete({})}>
              Get started
            </Action>
          </Card>
          <Image src={imageOfficeWorking} fullWidth />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card
          title={title}
          removeTopPadding
          headingSize="1"
        >
          <p>
            We need you to:
          </p>
          <List bullet>
            {this.props.flowState.smsVerificationRequired && (
              <ListItem>verify your identity (we’ll send you a code by text message)</ListItem>
            )}
            <ListItem>check the document</ListItem>
            <ListItem>follow the on-screen instructions to sign it</ListItem>
          </List>
          <Action onClick={() => this.props.onStepComplete({})}>
            Get started
          </Action>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default EsignatureRequestIntro;
