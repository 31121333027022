import React from "react";
import { isFieldQuestionVisible } from "./RenderQuestion";
import Link from '../../../components/Link';
import styled from 'styled-components/macro';
import { CheckBox } from '@styled-icons/material-outlined';

const formatResponse = (config, response) => {
  const fieldType = config.field_type

  switch (fieldType) {
    case 'boolean':
      if (response === "false" || response === false) {
        return "No"
      } else {
        return "Yes"
      }
    case 'radio':
      const option = config.choices.filter(choice => choice.value === response);
      return option[0].label
    case 'checkbox':
      const checkedResponses = []
      if (response instanceof Object) {
        for (const [key, value] of Object.entries(response)) {
          if (value === true) {
            const option = config.choices.filter(choice => choice.value === key);
            checkedResponses.push(option[0].label)
          }
        }
      }
      return checkedResponses.map((item, index) => (
        <CheckBoxResponse key={index}>
          <CheckBoxResponseIcon /> {item}
        </CheckBoxResponse>
      ));
    case 'confirmation':
      if (response === true) {
        return (
          <CheckBoxResponse>
            <CheckBoxResponseIcon /> I confirm
          </CheckBoxResponse>
        )
      }
    case 'date':
    case 'must_happen_by_date':
    case 'date_in_the_past':
      if (response === null) {
        return null
      }
      return `${response.day}/${response.month}/${response.year}`
    default:
      // Objects are not valid as a react child
      if (response instanceof Object) {
        return null
      }
      if (typeof (response) === 'string') {
        return response
      }
  }
}


const ReviewQuestion = props => {

  if (!isFieldQuestionVisible(props.doableConfig, props.fieldConfig, props.responses)) {
    return null
  }

  const question = props.fieldConfig.display_name

  return (
    <tr>
      <td>
        {question}
      </td>
      <td>
        {formatResponse(props.fieldConfig, props.responseToQuestion)}
      </td>
      <td><Link onClick={props.showPrevious}>Change</Link></td>
    </tr>
  )
};

export const CheckBoxResponse = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
`

export const CheckBoxResponseIcon = styled(CheckBox)`
  color: ${props => props.theme.color.lightGrey};
  width: ${props => props.theme.iconSize['sm']};
  margin-right: 6px;
`;

export default ReviewQuestion;
