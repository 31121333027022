import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import {isEmailValid} from "../../utils/textUtils";

const numberOfOccupiersOptions = [
  { title: 'No, just me', value: 0 },
  { title: 'Yes, with 1 other person', value: 1 },
  { title: 'Yes, with 2 other people', value: 2 },
  { title: 'Yes, with 3 other people', value: 3 },
];

const numberOfTenantOptions = [
  { title: 'No, it’s untenanted', value: 0 },
  { title: '1 other person lives at the property', value: 1 },
  { title: '2 other people live at the property', value: 2 },
  { title: '3 other people live at the property', value: 3 },
];

class RemoOtherOccupiers extends Component {
  constructor(props) {
    super(props);

    const occupierErrorMessages = {
      firstName: 'Enter their first name',
      lastName: 'Enter their last name',
      emailAddress: 'Enter their email address',
    };

    const errorMessages = {
      numberOfOccupiers: 'Please choose an option',
      occupier1: occupierErrorMessages,
      occupier2: occupierErrorMessages,
      occupier3: occupierErrorMessages,
    };

    if (this.props.otherOccupiers) {
      const details = this.props.otherOccupiers;
      this.state = {
        numberOfOccupiers: details.numberOfOccupiers,
        occupier1: { ...details.occupier1 },
        occupier2: { ...details.occupier2 },
        occupier3: { ...details.occupier3 },
        errors: {
          occupier1: {},
          occupier2: {},
          occupier3: {},
        },
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        numberOfOccupiers: null,
        occupier1: { firstName: '', lastName: '', emailAddress: '' },
        occupier2: { firstName: '', lastName: '', emailAddress: '' },
        occupier3: { firstName: '', lastName: '', emailAddress: '' },
        errors: {
          occupier1: {},
          occupier2: {},
          occupier3: {},
        },
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (fields['numberOfOccupiers'] === null) {
      errors['numberOfOccupiers'] = true;
      formIsValid = false;
    }

    if (fields['numberOfOccupiers'] === 1) {
      if (!fields.occupier1['firstName']) {
        errors.occupier1['firstName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier1['lastName']) {
        errors.occupier1['lastName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier1['emailAddress']) {
        errors.occupier1['emailAddress'] = true;
        formIsValid = false;
      }
      if (
        fields.occupier1['emailAddress'] !== '' &&
        !isEmailValid(fields.occupier1['emailAddress'])
      ) {
        errors.occupier1['emailAddress'] = true;
        errorMessages.occupier1['emailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }
    }

    if (fields['numberOfOccupiers'] === 2) {
      if (!fields.occupier1['firstName']) {
        errors.occupier1['firstName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier1['lastName']) {
        errors.occupier1['lastName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier1['emailAddress']) {
        errors.occupier1['emailAddress'] = true;
        formIsValid = false;
      }
      if (
        fields.occupier1['emailAddress'] !== '' &&
        !isEmailValid(fields.occupier1['emailAddress'])
      ) {
        errors.occupier1['emailAddress'] = true;
        errorMessages.occupier1['emailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }

      if (!fields.occupier2['firstName']) {
        errors.occupier2['firstName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier2['lastName']) {
        errors.occupier2['lastName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier2['emailAddress']) {
        errors.occupier2['emailAddress'] = true;
        formIsValid = false;
      }
      if (
        fields.occupier2['emailAddress'] !== '' &&
        !isEmailValid(fields.occupier2['emailAddress'])
      ) {
        errors.occupier2['emailAddress'] = true;
        errorMessages.occupier2['emailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }
    }

    if (fields['numberOfOccupiers'] === 3) {
      if (!fields.occupier1['firstName']) {
        errors.occupier1['firstName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier1['lastName']) {
        errors.occupier1['lastName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier1['emailAddress']) {
        errors.occupier1['emailAddress'] = true;
        formIsValid = false;
      }

      if (!fields.occupier2['firstName']) {
        errors.occupier2['firstName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier2['lastName']) {
        errors.occupier2['lastName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier2['emailAddress']) {
        errors.occupier2['emailAddress'] = true;
        formIsValid = false;
      }
      if (
        fields.occupier2['emailAddress'] !== '' &&
        !isEmailValid(fields.occupier2['emailAddress'])
      ) {
        errors.occupier2['emailAddress'] = true;
        errorMessages.occupier2['emailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }

      if (!fields.occupier3['firstName']) {
        errors.occupier3['firstName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier3['lastName']) {
        errors.occupier3['lastName'] = true;
        formIsValid = false;
      }
      if (!fields.occupier3['emailAddress']) {
        errors.occupier3['emailAddress'] = true;
        formIsValid = false;
      }
      if (
        fields.occupier3['emailAddress'] !== '' &&
        !isEmailValid(fields.occupier3['emailAddress'])
      ) {
        errors.occupier3['emailAddress'] = true;
        errorMessages.occupier3['emailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleOccupier1EmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors.occupier1[field] = false;
    } else {
      if (!isEmailValid(this.state.occupier1[field])) {
        errors.occupier1[field] = true;
        errorMessages.occupier1[field] = 'Please enter a valid email address';
      }
      if (!this.state.occupier1[field]) {
        errors.occupier1[field] = true;
        errorMessages.occupier1[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleOccupier2EmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors.occupier2[field] = false;
    } else {
      if (!isEmailValid(this.state.occupier2[field])) {
        errors.occupier2[field] = true;
        errorMessages.occupier2[field] = 'Please enter a valid email address';
      }
      if (!this.state.occupier2[field]) {
        errors.occupier2[field] = true;
        errorMessages.occupier2[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleOccupier3EmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors.occupier3[field] = false;
    } else {
      if (!isEmailValid(this.state.occupier3[field])) {
        errors.occupier3[field] = true;
        errorMessages.occupier3[field] = 'Please enter a valid email address';
      }
      if (!this.state.occupier3[field]) {
        errors.occupier3[field] = true;
        errorMessages.occupier3[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleOccupier1FieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors.occupier1[field] = false;
    } else {
      if (!this.state.occupier1[field]) {
        errors.occupier1[field] = true;
      }
    }
    this.setState({ errors });
  };

  handleOccupier2FieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors.occupier2[field] = false;
    } else {
      if (!this.state.occupier2[field]) {
        errors.occupier2[field] = true;
      }
    }
    this.setState({ errors });
  };

  handleOccupier3FieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors.occupier3[field] = false;
    } else {
      if (!this.state.occupier3[field]) {
        errors.occupier3[field] = true;
      }
    }
    this.setState({ errors });
  };

  selectNumberOfOccupiers = noo => {
    this.setState({ numberOfOccupiers: noo });
  };

  handleOccupier1 = field => event => {
    let occupier1 = { ...this.state.occupier1 };
    occupier1[field] = event;
    this.setState({ occupier1 });
  };

  handleOccupier2 = field => event => {
    let occupier2 = { ...this.state.occupier2 };
    occupier2[field] = event;
    this.setState({ occupier2 });
  };

  handleOccupier3 = field => event => {
    let occupier3 = { ...this.state.occupier3 };
    occupier3[field] = event;
    this.setState({ occupier3 });
  };

  saveOtherOccupiers = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        otherOccupiers: {
          numberOfOccupiers: this.state.numberOfOccupiers,
          occupier1: this.state.occupier1,
          occupier2: this.state.occupier2,
          occupier3: this.state.occupier3,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about other people living at the property" removeTopPadding>
          <RadioQuestion
            question="Does anyone else over the age of 17 live there, who you haven't mentioned"
            hint="(adult children, for example)"
            options={this.props.clientIsCompany? numberOfTenantOptions : numberOfOccupiersOptions}
            inline={false}
            name="numberOfOccupiers"
            selected={this.state.numberOfOccupiers}
            optionSelected={this.selectNumberOfOccupiers}
            validate={this.handleFieldValidation('numberOfOccupiers')}
            validationError={
              this.state.errors.numberOfOccupiers
                ? this.state.errorMessages.numberOfOccupiers
                : null
            }
          />

          {this.state.numberOfOccupiers >= 1 && (
            <div data-testid="occupier-1">
              <h3>
                We’ll need to get in touch with them — please enter their
                details below.
              </h3>

              <h3>First person</h3>
              <TextQuestion
                question="What’s their first name?"
                value={this.state.occupier1.firstName}
                valueEntered={this.handleOccupier1('firstName')}
                validate={this.handleOccupier1FieldValidation('firstName')}
                validationError={
                  this.state.errors.occupier1.firstName
                    ? this.state.errorMessages.occupier1.firstName
                    : null
                }
              />
              <TextQuestion
                question="What’s their last name?"
                value={this.state.occupier1.lastName}
                valueEntered={this.handleOccupier1('lastName')}
                validate={this.handleOccupier1FieldValidation('lastName')}
                validationError={
                  this.state.errors.occupier1.lastName
                    ? this.state.errorMessages.occupier1.lastName
                    : null
                }
              />
              <TextQuestion
                question="What’s their email address?"
                value={this.state.occupier1.emailAddress}
                valueEntered={this.handleOccupier1('emailAddress')}
                validate={this.handleOccupier1EmailAddressValidation(
                  'emailAddress',
                )}
                validationError={
                  this.state.errors.occupier1.emailAddress
                    ? this.state.errorMessages.occupier1.emailAddress
                    : null
                }
              />
            </div>
          )}

          {this.state.numberOfOccupiers >= 2 && (
            <div data-testid="occupier-2">
              <h3>Second person</h3>
              <TextQuestion
                question="What’s their first name?"
                value={this.state.occupier2.firstName}
                valueEntered={this.handleOccupier2('firstName')}
                validate={this.handleOccupier2FieldValidation('firstName')}
                validationError={
                  this.state.errors.occupier2.firstName
                    ? this.state.errorMessages.occupier2.firstName
                    : null
                }
              />
              <TextQuestion
                question="What’s their last name?"
                value={this.state.occupier2.lastName}
                valueEntered={this.handleOccupier2('lastName')}
                validate={this.handleOccupier2FieldValidation('lastName')}
                validationError={
                  this.state.errors.occupier2.lastName
                    ? this.state.errorMessages.occupier2.lastName
                    : null
                }
              />
              <TextQuestion
                question="What’s their email address?"
                value={this.state.occupier2.emailAddress}
                valueEntered={this.handleOccupier2('emailAddress')}
                validate={this.handleOccupier2EmailAddressValidation(
                  'emailAddress',
                )}
                validationError={
                  this.state.errors.occupier2.emailAddress
                    ? this.state.errorMessages.occupier2.emailAddress
                    : null
                }
              />
            </div>
          )}

          {this.state.numberOfOccupiers === 3 && (
            <div data-testid="occupier-3">
              <h3>Third person</h3>
              <TextQuestion
                question="What’s their first name?"
                value={this.state.occupier3.firstName}
                valueEntered={this.handleOccupier3('firstName')}
                validate={this.handleOccupier3FieldValidation('firstName')}
                validationError={
                  this.state.errors.occupier3.firstName
                    ? this.state.errorMessages.occupier3.firstName
                    : null
                }
              />
              <TextQuestion
                question="What’s their last name?"
                value={this.state.occupier3.lastName}
                valueEntered={this.handleOccupier3('lastName')}
                validate={this.handleOccupier3FieldValidation('lastName')}
                validationError={
                  this.state.errors.occupier3.lastName
                    ? this.state.errorMessages.occupier3.lastName
                    : null
                }
              />
              <TextQuestion
                question="What’s their email address?"
                value={this.state.occupier3.emailAddress}
                valueEntered={this.handleOccupier3('emailAddress')}
                validate={this.handleOccupier3EmailAddressValidation(
                  'emailAddress',
                )}
                validationError={
                  this.state.errors.occupier3.emailAddress
                    ? this.state.errorMessages.occupier3.emailAddress
                    : null
                }
              />
            </div>
          )}

          <Action onClick={this.saveOtherOccupiers}>Continue</Action>
        </Card>

        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default RemoOtherOccupiers;
