import React from 'react';
import StepFlowNavigate from '../../components/StepFlowNavigate';

import TA10Section from "./TA10Section";
import TA10SectionRooms from "./TA10SectionRooms";
import TA10SectionReview from "./TA10SectionReview";
import TA10Submission from './TA10Submission';

import { TA10Config } from './TA10Config';
import { room_items as roomItemsConfig } from './TA10Config';

const TA10RoomFlow = (props) => {
  let steps = []
  steps.push(
    {
      name: '1. Basic fittings',
      slug: 'basic-fittings',
      component: flowState => (
        <TA10Section
          rooms={flowState.rooms}
          section={flowState.section1}
          sectionNumber={1}
          config={TA10Config[1]}
          isFirstPage={true}
        />
      ),
    },
    {
      name: '1. Basic fittings - review',
      slug: 'basic-fittings-review',
      component: flowState => (
        <TA10SectionReview
          section={flowState.section1}
          config={TA10Config[1]}
        />
      ),
    },
    {
      name: '2. Kitchen',
      slug: 'kitchen',
      component: flowState => (
        <TA10Section
          section={flowState.section2}
          sectionNumber={2}
          config={TA10Config[2]}
          kitchen={true}
        />
      ),
    },
    {
      name: '2. Kitchen - Room items',
      slug: 'kitchen-fittings',
      component: flowState => (
        <TA10SectionRooms
          sectionRooms={flowState.sectionRooms}
          sectionTitle="Kitchen"
          sectionNumber="Rooms"
          roomIndex={0}
          config={TA10Config[0]}
          roomItemsConfig={roomItemsConfig}
        />
      )
    },
    {
      name: '2. Kitchen - review',
      slug: 'kitchen-review',
      component: flowState => (
        <TA10SectionReview
          section={flowState.section2}
          sectionRooms={flowState.sectionRooms[0]}
          config={TA10Config[2]}
          kitchen={true}
        />
      ),
    },
    {
      name: '3. Bathroom',
      slug: 'bathroom',
      component: flowState => (
        <TA10Section
          section={flowState.section3}
          sectionNumber={3}
          config={TA10Config[3]}
        />
      ),
    },
    {
      name: '3. Bathroom - review',
      slug: 'bathroom-review',
      component: flowState => (
        <TA10SectionReview
          section={flowState.section3}
          config={TA10Config[3]}
        />
      ),
    }
  )

  const initialFlowState = props.initialFlowState
  const rooms = initialFlowState.rooms
  const maxRooms = rooms.length
  // i=1 as rooms[0] is Kitchen
  for (let i = 1; i < maxRooms; i++) {
    steps.push(
      {
        name: 'Room',
        slug: `room-${i}`,
        valid: flowState => maxRooms > i,
        component: flowState => (
          <TA10SectionRooms
            sectionRooms={flowState.sectionRooms}
            sectionTitle={rooms[i]}
            sectionNumber="Rooms"
            roomIndex={i}
            config={TA10Config[0]}
            roomItemsConfig={roomItemsConfig}
          />
        )
      },
      {
        name: 'Room review',
        slug: `room-${i}-review`,
        valid: flowState => maxRooms > i,
        component: flowState => (
          <TA10SectionReview
            section={flowState.sectionRooms[i]}
            sectionTitle={rooms[i]}
            config={TA10Config[0]}
          />
        )
      }
    );
  }

  steps.push(
    {
      name: '8. Outdoor area',
      slug: 'outdoor-area',
      component: flowState => (
        <TA10Section
          section={flowState.section9}
          sectionNumber={9}
          config={TA10Config[9]}
        />
      ),
    },
    {
      name: '8. Outdoor area - review',
      slug: 'outdoor-area-review',
      component: flowState => (
        <TA10SectionReview
          section={flowState.section9}
          config={TA10Config[9]}
        />
      ),
    },
    {
      name: '9. Television',
      slug: 'television',
      component: flowState => (
        <TA10Section
          section={flowState.section10}
          sectionNumber={10}
          config={TA10Config[10]}
        />
      ),
    },
    {
      name: '9. Television - review',
      slug: 'television-review',
      component: flowState => (
        <TA10SectionReview
          section={flowState.section10}
          config={TA10Config[10]}
        />
      ),
    },
    {
      name: '10. Stock of fuel',
      slug: 'stock-of-fuel',
      component: flowState => (
        <TA10Section
          section={flowState.section11}
          sectionNumber={11}
          config={TA10Config[11]}
        />
      ),
    },
    {
      name: '10. Stock of fuel - review',
      slug: 'stock-of-fuel-review',
      component: flowState => (
        <TA10SectionReview
          section={flowState.section11}
          config={TA10Config[11]}
        />
      ),
    },
    {
      name: '11. Other items',
      slug: 'other-items',
      component: flowState => (
        <TA10Section
          section={flowState.section12}
          sectionNumber={12}
          config={TA10Config[12]}
        />
      ),
    },
    {
      name: '11 . Other items - review',
      slug: 'other-items-review',
      component: flowState => (
        <TA10SectionReview
          section={flowState.section12}
          config={TA10Config[12]}
        />
      ),
    },
    {
      name: 'Submission',
      slug: 'submit',
      component: flowState => (
        <TA10Submission
          rooms={flowState.rooms}
          TA10FlowState={flowState}
          onFlowComplete={props.onFlowComplete}
        />
      )
    }
  )

  return (
    <StepFlowNavigate
      stepParamName="section"
      steps={steps}
      initialFlowState={initialFlowState}
    />
  )
}

export default TA10RoomFlow;
