import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import { TextareaQuestion } from '../../components/TextQuestion';
import { YES_NO_OPTIONS } from '../../constants';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class RemoPropertyInformation2 extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      hasClientReceivedNotices: 'Please choose an option',
      noticesInfo: 'Please fill this in',
      isPropertyConnectedToUtilities: 'Please choose an option',
      connectionToUtilitiesInfo: 'Please fill this in',
      whoInsuresProperty: 'Please choose an option',
      clientPaysMaintenanceFees: 'Please choose an option',
      maintenanceFeesInfo: 'Please fill this in',
      numberOfManagementCompanies: 'Please choose an option',
      hasBuildingWorks: 'Please choose an option',
      buildingWorksInfo: 'Please fill this in',
    };

    if (this.props.propertyInformation2) {
      const details = this.props.propertyInformation2;
      this.state = {
        hasClientReceivedNotices: details.hasClientReceivedNotices,
        noticesInfo: details.noticesInfo,
        isPropertyConnectedToUtilities: details.isPropertyConnectedToUtilities,
        connectionToUtilitiesInfo: details.connectionToUtilitiesInfo,
        numberOfManagementCompanies: details.numberOfManagementCompanies,
        whoInsuresProperty: details.whoInsuresProperty,
        clientPaysMaintenanceFees: details.clientPaysMaintenanceFees,
        maintenanceFeesInfo: details.maintenanceFeesInfo,
        hasBuildingWorks: details.hasBuildingWorks,
        buildingWorksInfo: details.buildingWorksInfo,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        hasClientReceivedNotices: null,
        noticesInfo: '',
        isPropertyConnectedToUtilities: null,
        connectionToUtilitiesInfo: '',
        whoInsuresProperty: null,
        clientPaysMaintenanceFees: null,
        maintenanceFeesInfo: '',
        numberOfManagementCompanies: null,
        hasBuildingWorks: null,
        buildingWorksInfo: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({[field]: event});
  };

  handleValidation() {
    const fields = {...this.state};
    let errors = {...this.state.errors};
    let formIsValid = true;

    if (fields['hasClientReceivedNotices'] === null) {
      errors['hasClientReceivedNotices'] = true;
      formIsValid = false;
    }

    if (fields['hasClientReceivedNotices'] === true) {
      if (!fields['noticesInfo']) {
        errors['noticesInfo'] = true;
        formIsValid = false;
      }
    }

    if (fields['isPropertyConnectedToUtilities'] === null) {
      errors['isPropertyConnectedToUtilities'] = true;
      formIsValid = false;
    }

    if (fields['isPropertyConnectedToUtilities'] === false) {
      if (!fields['connectionToUtilitiesInfo']) {
        errors['connectionToUtilitiesInfo'] = true;
        formIsValid = false;
      }
    }

    if (fields['numberOfManagementCompanies'] === null) {
      errors['numberOfManagementCompanies'] = true;
      formIsValid = false;
    }

    if (fields['whoInsuresProperty'] === null) {
      errors['whoInsuresProperty'] = true;
      formIsValid = false;
    }

    if (fields['clientPaysMaintenanceFees'] === null) {
      errors['clientPaysMaintenanceFees'] = true;
      formIsValid = false;
    }

    if (fields['clientPaysMaintenanceFees'] === true) {
      if (!fields['maintenanceFeesInfo']) {
        errors['maintenanceFeesInfo'] = true;
        formIsValid = false;
      }
    }

    if (fields['hasBuildingWorks'] === null) {
      errors['hasBuildingWorks'] = true;
      formIsValid = false;
    }

    if (fields['hasBuildingWorks'] === true) {
      if (!fields['buildingWorksInfo']) {
        errors['buildingWorksInfo'] = true;
        formIsValid = false;
      }
    }

    this.setState({errors});
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = {...this.state.errors};

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({errors});
  };

  savePropertyInformation2 = event => {

    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        propertyInformation2: {
          hasClientReceivedNotices: this.state.hasClientReceivedNotices,
          noticesInfo: this.state.noticesInfo,
          isPropertyConnectedToUtilities: this.state.isPropertyConnectedToUtilities,
          whoInsuresProperty: this.state.whoInsuresProperty,
          connectionToUtilitiesInfo: this.state.connectionToUtilitiesInfo,
          clientPaysMaintenanceFees: this.state.clientPaysMaintenanceFees,
          maintenanceFeesInfo: this.state.maintenanceFeesInfo,
          numberOfManagementCompanies: this.state.numberOfManagementCompanies,
          hasBuildingWorks: this.state.hasBuildingWorks,
          buildingWorksInfo: this.state.buildingWorksInfo,
        },
      });
      return;
    }
  };

  render() {

    return (
      <React.Fragment>
        <ScrollToTop/>
        <Card title={this.props.clientIsCompany ? `Tell us about the property your company is remortgaging` : `Tell us about the property you’re remortgaging`} removeTopPadding>
          <RadioQuestion
            question="Have you received any notices about the property?"
            hint="For example, from the council or a utility company"
            options={YES_NO_OPTIONS}
            inline={true}
            name="hasClientReceivedNotices"
            selected={this.state.hasClientReceivedNotices}
            optionSelected={this.handleInputChange('hasClientReceivedNotices')}
            validate={this.handleFieldValidation('hasClientReceivedNotices')}
            validationError={
              this.state.errors['hasClientReceivedNotices']
                ? this.state.errorMessages['hasClientReceivedNotices']
                : null
            }
          />

          {this.state.hasClientReceivedNotices === true && (
            <RadioConditional data-testid="notices-info">
              <TextareaQuestion
                question="Please give details"
                value={this.state.noticesInfo}
                valueEntered={this.handleInputChange('noticesInfo')}
                validate={this.handleFieldValidation('noticesInfo')}
                validationError={
                  this.state.errors['noticesInfo']
                    ? this.state.errorMessages['noticesInfo']
                    : null
                }
              />
            </RadioConditional>
          )}

          <RadioQuestion
            question="Is the property connected to standard utilities?"
            hint="(energy, water, drains, etc)"
            options={YES_NO_OPTIONS}
            inline={true}
            name="isPropertyConnectedToUtilities"
            selected={this.state.isPropertyConnectedToUtilities}
            optionSelected={this.handleInputChange(
              'isPropertyConnectedToUtilities',
            )}
            validate={this.handleFieldValidation(
              'isPropertyConnectedToUtilities',
            )}
            validationError={
              this.state.errors['isPropertyConnectedToUtilities']
                ? this.state.errorMessages['isPropertyConnectedToUtilities']
                : null
            }
          />

          {this.state.isPropertyConnectedToUtilities === false && (
            <RadioConditional data-testid="connected-to-utilities-info">
              <TextareaQuestion
                name="connectionToUtilitiesInfo"
                question="Please give details"
                value={this.state.connectionToUtilitiesInfo}
                valueEntered={this.handleInputChange(
                  'connectionToUtilitiesInfo',
                )}
                validate={this.handleFieldValidation(
                  'connectionToUtilitiesInfo',
                )}
                validationError={
                  this.state.errors['connectionToUtilitiesInfo']
                    ? this.state.errorMessages['connectionToUtilitiesInfo']
                    : null
                }
              />
            </RadioConditional>
          )}


          <RadioQuestion
            question={this.props.clientIsCompany? "Does your company pay any money to a management company" : "Do you pay any money to a management company?"}
            hint="If so, we’ll ask you for their details later"
            options={[
              {title: 'No', value: 0},
              {title: 'Yes, to one management company', value: 1},
              {title: 'Yes, to two management companies', value: 2},
            ]}
            name="numberOfManagementCompanies"
            selected={this.state.numberOfManagementCompanies}
            optionSelected={this.handleInputChange(
              'numberOfManagementCompanies',
            )}
            validate={this.handleFieldValidation('numberOfManagementCompanies')}
            validationError={
              this.state.errors['numberOfManagementCompanies']
                ? 'Please choose an option'
                : null
            }
          />

          <RadioQuestion
            question={this.props.clientIsCompany? "Does your company have buildings insurance for the property?" : "Do you have buildings insurance for the property?"}
            options={this.state.numberOfManagementCompanies > 0 ? [
              {
                title: 'Yes',
                value: 'client insures property',
              },
              {
                title: 'No, the management company insures the property',
                value: 'management company insures property',
              },
              {
                title: 'No, we don’t insure the property',
                value: 'property not insured',
              },
            ] : [
              {
                title: 'Yes',
                value: 'client insures property',
              },
              {
                title: 'No, we don’t insure the property',
                value: 'property not insured',
              },
            ]}
            name="whoInsuresProperty"
            selected={this.state.whoInsuresProperty}
            optionSelected={this.handleInputChange('whoInsuresProperty')}
            validate={this.handleFieldValidation('whoInsuresProperty')}
            validationError={
              this.state.errors['whoInsuresProperty']
                ? this.state.errorMessages['whoInsuresProperty']
                : null
            }
          />

          <RadioQuestion
            name="clientPaysMaintenanceFees"
            question={this.props.clientIsCompany? "Does your company make any other contribution toward paying towards the upkeep of any communal areas?" : "Do you make any other contribution toward paying towards the upkeep of any communal areas?"}
            hint="(for example, a shared driveway or path)?"
            options={YES_NO_OPTIONS}
            inline={true}
            selected={this.state.clientPaysMaintenanceFees}
            optionSelected={this.handleInputChange('clientPaysMaintenanceFees')}
            validate={this.handleFieldValidation('clientPaysMaintenanceFees')}
            validationError={
              this.state.errors['clientPaysMaintenanceFees']
                ? this.state.errorMessages['clientPaysMaintenanceFees']
                : null
            }
          />

          {this.state.clientPaysMaintenanceFees === true && (
            <RadioConditional data-testid="maintenance-fees-info">
              <TextareaQuestion
                name="maintenanceFeesInfo"
                question="Please give details"
                value={this.state.maintenanceFeesInfo}
                valueEntered={this.handleInputChange('maintenanceFeesInfo')}
                validate={this.handleFieldValidation('maintenanceFeesInfo')}
                validationError={
                  this.state.errors['maintenanceFeesInfo']
                    ? this.state.errorMessages['maintenanceFeesInfo']
                    : null
                }
              />
            </RadioConditional>
          )}

          <RadioQuestion
            name="hasBuildingWorks"
            question="Have any building works been carried out in the last four years?"
            options={YES_NO_OPTIONS}
            inline={true}
            selected={this.state.hasBuildingWorks}
            optionSelected={this.handleInputChange('hasBuildingWorks')}
            validate={this.handleFieldValidation('hasBuildingWorks')}
            validationError={
              this.state.errors['hasBuildingWorks']
                ? this.state.errorMessages['hasBuildingWorks']
                : null
            }
          />

          {this.state.hasBuildingWorks === true && (
            <RadioConditional data-testid="building-works-info">
              <TextareaQuestion
                name="buildingWorksInfo"
                question="Please give details"
                value={this.state.buildingWorksInfo}
                valueEntered={this.handleInputChange('buildingWorksInfo')}
                validate={this.handleFieldValidation('buildingWorksInfo')}
                validationError={
                  this.state.errors['buildingWorksInfo']
                    ? this.state.errorMessages['buildingWorksInfo']
                    : null
                }
              />
            </RadioConditional>
          )}

          <Action onClick={this.savePropertyInformation2}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant}/>
      </React.Fragment>
    );
  }
}

export default RemoPropertyInformation2;
