import React from 'react';
import styled from "styled-components/macro";
import { IconList } from '../../components/IconList';
import StatusUpdateRow from '../../components/StatusUpdateRow';

function CaseStatusUpdate({ statusUpdate }) {
  if (! statusUpdate) {
    return (
      <p>
        We haven’t created a live update yet - soon you’ll be able to view the progress of this case here.
      </p>
    );
  }

  const numRows = Math.ceil(statusUpdate.sections.length / 2);
  const gridRowStyle = {
    gridTemplateRows: `repeat(${numRows}, min-content)`,
  };

  const statusUpdateRows = statusUpdate.sections.map((section, index) => {
    return (
      <StatusUpdateRow
        key={index}
        title={section.title}
        descriptions={section.descriptions}
        description_blocks={section.description_blocks}
        status={section.status}
      />
    );
  });

  return (
    <>
      <CaseUpdateInfo>
        Most recent update {new Date(statusUpdate.last_updated).toLocaleString('en-GB')}
      </CaseUpdateInfo>
      <IconList cols={1} style={gridRowStyle}>
        {statusUpdateRows}
      </IconList>
    </>
  );
}

export default CaseStatusUpdate;

const CaseUpdateInfo = styled.div`
  color: ${props => props.theme.color.junoTextSecondary};
  font-size: ${props => props.theme.fontSizes.p['s']};
  line-height: 1.5;
  margin-bottom: ${props => props.theme.spacingScale[2]};
`;
