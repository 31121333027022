import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { gaClientIdLoaded } from './analyticsSlice';

const AnalyticsLoader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (! window.gtag || ! window.appConfig?.ga_measurement_id) {
      return;
    }

    window.gtag('get', window.appConfig.ga_measurement_id, 'client_id', (clientId) => {
      dispatch(gaClientIdLoaded(clientId));
    });
  }, [dispatch]);

  return null;
};

export default AnalyticsLoader
