import React from 'react';
import styled from 'styled-components/macro';

const JunoFieldset = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
`;

function Fieldset(props) {
  return <JunoFieldset>{props.children}</JunoFieldset>;
}

export default Fieldset;
