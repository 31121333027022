import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { TA10Config } from './TA10Config';

class TA10Submission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };

    const flowState = this.props.TA10FlowState;
    const submission = {
      rooms: this.props.rooms,
      section1: flowState.section1,
      section2: flowState.section2,
      section3: flowState.section3,
      section4: {
        title: TA10Config[4].sectionTitle,
        items: []
      },
      section5: {
        title: TA10Config[5].sectionTitle,
        items: []
      },
      section6: {
        title: TA10Config[6].sectionTitle,
        items: []
      },
      section7: {
        title: TA10Config[7].sectionTitle,
        items: []
      },
      section8: {
        title: TA10Config[8].sectionTitle,
        items: []
      },
      section9: flowState.section9,
      section10: flowState.section10,
      section11: flowState.section11,
      section12: flowState.section12,
      sectionRooms: flowState.sectionRooms
    }

    fetch('/api/propertyInformationFormTA10', {
      method: 'PUT',
      body: JSON.stringify({
        formRequestId: flowState.formRequestId,
        ta10Data: submission
      }),
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
            submitted: true,
          });
          props.onFlowComplete();
        } else {
          this.setState({
            error: 'Unable to process your details',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Unable to process your details',
          loading: false,
        });
      },
    );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.submitted) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn't work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }
  }
}

export default TA10Submission;
