import React, { Component } from 'react';
import Action from '../../components/Action';
import Card from '../../components/Card';
import Link from '../../components/Link';
import SectionReviewTable from './SectionReviewTable';
import ScrollToTop from '../../components/utils/ScrollToTop';

class TA10SectionReview extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={this.props.config.sectionTitle || this.props.sectionTitle} removeTopPadding>
          <p>
            We've listed the items you have told us about below, if there's anything missing - you can go back to <Link onClick={this.props.showPrevious}>add more items</Link>.
          </p>
          <SectionReviewTable>
            <thead>
              <tr>
                <th>Item</th>
                <th>Included</th>
                {this.props.kitchen && <th>Fitted</th>}
                <th>Price</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {this.props.section && this.props.section.items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.itemName}</td>
                    <td>{item.inclusion}</td>
                    {this.props.kitchen && <td>{item.inclusion !== 'None' && (item.fitted === 'FreeStanding' ? 'Free-standing' : item.fitted)}</td>}
                    <td data-title="Sale price: ">{item.price && item.price}</td>
                    <td data-title="Comment: ">{item.comment && item.comment}</td>
                  </tr>
                )
              })}
              {this.props.kitchen && this.props.sectionRooms.items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.itemName}</td>
                    <td>{item.inclusion}</td>
                    {this.props.kitchen && <td>{item.inclusion !== 'None' && (item.fitted === 'FreeStanding' ? 'Free-standing' : item.fitted)}</td>}
                    <td data-title="Sale price: ">{item.price && item.price}</td>
                    <td data-title="Comment: ">{item.comment && item.comment}</td>
                  </tr>
                )
              })}
            </tbody>
          </SectionReviewTable>
          <Action onClick={this.props.onStepComplete}>Continue</Action>
        </Card>
      </React.Fragment>
    )

  }
}

export default TA10SectionReview;
