import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useClientAppFormRequest from '../../hooks/useClientAppFormRequest';
import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import RemoLeaseholdIntro from './RemoLeaseholdIntro';
import RemoLeaseholdInformation from './RemoLeaseholdInformation1';
import RemoLeaseholdThanks from './RemoLeaseholdThanks';
import StepFlowNavigate from '../../components/StepFlowNavigate';
import RemoFirstManagementCompanyDetails from './RemoFirstManagementCompanyDetails';
import RemoSecondManagementCompanyDetails from './RemoSecondManagementCompanyDetails';
import RemoLeaseholdSubmission from './RemoLeaseholdSubmission';

const RemoLeaseholdFlow = (props) => {
  const location = useLocation();
  const { requestId } = useParams();

  const [fromDashboard, setFromDashboard] = useState(false);
  const [flowCompleted, setFlowCompleted] = useState(false);

  const { loading, error, requestDetails, submitted } = useClientAppFormRequest(`/api/remortgageLeaseholdInformation/${requestId}`);

  // Set fromDashboard state on initial load
  useEffect(() => {
    const { fromDashboard } = location.state || {};
    setFromDashboard(fromDashboard);
  }, []);

  if (loading) {
    return null;
  }

  if (submitted || flowCompleted) {
    return <RemoLeaseholdThanks fromDashboard={fromDashboard} />;
  }

  if (error) {
    return (
      <PageLayout>
        <ScrollToTop />
        <Card title="Sorry, that didn't work" removeTopPadding>
          {error}
        </Card>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <StepFlowNavigate
        initialFlowState={{
          formRequestId: requestDetails.formRequestId,
          transactionType: requestDetails.transactionType,
          numberOfManagementCompanies: requestDetails.numberOfManagementCompanies,
          fromDashboard: fromDashboard,
        }}
        steps={[
          {
            slug: 'intro',
            component: flowState => (
              <RemoLeaseholdIntro
                remoLeasehold={flowState.remortgageLeasehold}
              />
            ),
          },
          {
            slug: 'management-company-details',
            component: flowState => (
              <RemoFirstManagementCompanyDetails
                managementCompanyDetails={flowState.firstManagementCompanyDetails}
              />
            ),
          },
          {
            slug: 'second-management-company-details',
            component: flowState => (
              <RemoSecondManagementCompanyDetails
                managementCompanyDetails={flowState.secondManagementCompanyDetails}
              />
            ),
            valid: flowState => flowState.numberOfManagementCompanies > 1,
          },
          {
            slug: 'leasehold-information',
            component: flowState => (
              <RemoLeaseholdInformation
                leaseholdInformation={flowState.leaseholdInformation}
              />
            ),
          },
          {
            slug: 'submit',
            component: flowState => (
              <RemoLeaseholdSubmission
                remortgageLeaseholdInformationFlowState={flowState}
                onFlowComplete={() => setFlowCompleted(true)}
              />
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

export default RemoLeaseholdFlow;
