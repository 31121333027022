import React from 'react';
import styled, { css } from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

/* stylelint-disable property-no-vendor-prefix */
const JunoButton = styled.button`
  display: inline-flex;
  align-items: center;

  border-radius: 5px;
  border-width: 0 0 2px 0;
  border-style: solid;
  padding: 4px 16px;

  font-family: ${props => props.theme.junoFontSecondary};
  line-height: 1.75;
  font-weight: ${props => props.theme.fontWeight.bold};

  font-size: ${props => props.theme.fontSizes.h3['sm']};
  letter-spacing: 0.02em;

  color: ${props => props.theme.color.junoButtonText};
  background-color: ${props => props.theme.color.junoButton};
  border-color: ${props => props.theme.color.junoButtonShadow};

  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;

  &:-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &:focus {
    outline: 3px solid transparent;
    background-color: ${props => props.theme.color.junoButtonHover};
  }

  &:link,
  &:hover,
  &:focus,
  &:visited {
    color: ${props => props.theme.color.junoButtonText};
  }

  &:visited {
    background-color: ${props => props.theme.color.junoButton};
  }

  &:hover,
  &:focus {
    border-bottom: 2px solid ${props => props.theme.color.junoButtonShadow};
    background-color: ${props => props.theme.color.junoButtonHover};
  }

  &:hover {
    transition: background-color 0.3s;
  }

  &:active {
    top: 2px;
    box-shadow: 0 0 0 ${props => props.theme.color.junoButton};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      color: ${props => props.theme.color.junoButtonText};
      background-color: ${props => props.theme.color.junoButton};

      &:hover {
        background-color: ${props => props.theme.color.junoButton};
        cursor: default;
      }
    `}

  ${props =>
    props.small &&
    css`
      font-size: ${props => props.theme.fontSizes.p['sm']};

      ${mediaQueries.md} {
        font-size: ${props => props.theme.fontSizes.p['md']};
      }
    `}

  ${props =>
    props.large &&
    css`
      padding-left: 1em;
      padding-right: 1em;
    `}

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

const JunoButtonText = styled.span`
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
`;

export const SecondaryButton = styled(Button)`
  color: ${props => props.theme.color.junoSecondaryButtonText};
  background-color: ${props => props.theme.color.junoSecondaryButton};
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: ${props => props.theme.color.junoSecondaryButtonShadow};

  &:focus {
    outline: 3px solid transparent;
    background-color: ${props => props.theme.color.junoSecondaryButtonHover};
  }

  &:link,
  &:hover,
  &:focus,
  &:visited {
    color: ${props => props.theme.color.junoSecondaryButtonText};
  }

  &:visited {
    background-color: ${props => props.theme.color.junoSecondaryButton};
  }

  &:hover,
  &:focus {
    border-bottom: 2px solid
      ${props => props.theme.color.junoSecondaryButtonShadow};
    background-color: ${props => props.theme.color.junoSecondaryButtonHover};
  }

  &:hover {
    transition: background-color 0.3s;
  }

  &:active {
    top: 2px;
    box-shadow: 0 0 0 ${props => props.theme.color.junoSecondaryButton};
  }
`;

export const CTAButton = styled(JunoButton)`
  color: ${props => props.theme.color.text1};
  background-color: ${props => props.theme.color.junoCTAButton};
  border-color: ${props => props.theme.color.junoCTAButtonShadow};

  &:focus {
    outline: 3px solid transparent;
    background-color: ${props => props.theme.color.junoCTAButtonHover};
  }

  &:link,
  &:hover,
  &:focus,
  &:visited {
    color: ${props => props.theme.color.text1};
  }

  &:visited {
    background-color: ${props => props.theme.color.junoCTAButton};
  }

  &:hover,
  &:focus {
    border-bottom: 2px solid ${props => props.theme.color.junoCTAButtonShadow};
    background-color: ${props => props.theme.color.junoCTAButtonHover};
  }

  &:hover {
    transition: background-color 0.3s;
  }

  &:active {
    top: 2px;
    box-shadow: 0 0 0 ${props => props.theme.color.junoCTAButton};
  }
`;
/* stylelint-enable property-no-vendor-prefix */

function Button(props) {
  return (
    <JunoButton {...props}>
      <JunoButtonText>{props.children}</JunoButtonText>
    </JunoButton>
  );
}

export default Button;
