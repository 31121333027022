export const YES_NO_OPTIONS = [
  { title: 'Yes', value: true },
  { title: 'No', value: false },
];

export const SAVINGS_ACCOUNTS_NUMBER_USED = [
  { title: '1', value: 1 },
  { title: '2', value: 2 },
  { title: '3', value: 3 },
  { title: '4', value: 4 },
  { title: '5', value: 5 },
  { title: '6', value: 6 },
  { title: '7', value: 7 },
  { title: '8', value: 8 },
  { title: '9', value: 9 },
  { title: '10', value: 10 },
  { title: '11', value: 11 },
  { title: '12', value: 12 }
];

export const TENURE_OPTIONS = [
  { title: 'Freehold (most houses)', value: 'freehold' },
  { title: 'Leasehold (most flats)', value: 'leasehold' },
  {
    title: 'Leasehold with share of freehold',
    value: 'leasehold with share of freehold',
  },
];

export const TERMS_AND_CONDITIONS_OPTIONS = [
  {
    title: 'I’ve read the terms and conditions and I accept them',
    value: true,
    name: 'termsAndConditions',
  },
];

export const SELLING_REJECTION_OPTIONS = [
  {
    title: 'you’re selling a shared ownership property?',
    value: 'a shared ownership property',
    name: 'sharedOwnership',
  },
  {
    title: 'you’re selling unregistered land?',
    value: 'unregistered land',
    name: 'unregisteredLand',
  },
  {
    title: 'you’re selling a property at auction?',
    value: 'a property at auction',
    name: 'auctionSale',
  },
];

export const SELLING_REJECTION_MAP = new Map(
  SELLING_REJECTION_OPTIONS.map(i => [i.name, i.value]),
);

export const BUYING_REJECTION_OPTIONS = [
  {
    title: 'unregistered land?',
    value: 'unregistered land',
    name: 'unregisteredLand',
  },
  {
    title: 'a property at auction?',
    value: 'a property at auction',
    name: 'auction',
  },
];

export const BUYING_REJECTION_MAP = new Map(
  BUYING_REJECTION_OPTIONS.map(i => [i.name, i.value]),
);

// Note: We also have another list of lenders in app/constants/lender.go that you will need to modify
// if editing this list!!
// The values here have to match the values in the corresponding workflow field
// choices.
export const MORTGAGE_LENDER_OPTIONS = [
  {
    title: 'Accord',
    value: 'Accord',
  },
  {
    title: 'Aldermore',
    value: 'Aldermore',
  },
  {
    title: 'Atom Bank',
    value: 'Atom Bank',
  },
  {
    title: 'Bank of Ireland',
    value: 'Bank of Ireland',
  },
  {
    title: 'Bank of Scotland',
    value: 'Bank of Scotland',
  },
  {
    title: 'Barclays',
    value: 'Barclays',
  },
  {
    title: 'Better Homeownership',
    value: 'Better Homeownership'
  },
  {
    title: 'Birmingham Midshires',
    value: 'Birmingham Midshires',
  },
  {
    title: 'Britannia',
    value: 'Britannia',
  },
  {
    title: 'Buckinghamshire Building Society',
    value: 'Buckingham Building Society',
  },
  {
    title: 'Cambridge Building Society',
    value: 'Cambridge Building Society',
  },
  {
    title: 'Chelsea Building Society',
    value: 'Chelsea Building Society',
  },
  {
    title: 'Chorley Building Society',
    value: 'Chorley Building Society',
  },
  {
    title: 'Clydesdale Bank',
    value: 'Clydesdale Bank',
  },
  {
    title: 'Co-operative Bank',
    value: 'Co-operative Bank',
  },
  {
    title: 'Coutts',
    value: 'Coutts',
  },
  {
    title: 'Coventry Building Society',
    value: 'Coventry Building Society',
  },
  {
    title: 'Cumberland Building Society',
    value: 'Cumberland Building Society',
  },
  {
    title: 'Cynergy Bank',
    value: 'Cynergy Bank',
  },
  {
    title: 'Danske Bank',
    value: 'Danske Bank',
  },
  {
    title: 'Darlington Building Society',
    value: 'Darlington Building Society',
  },
  {
    title: 'Dudley Building Society',
    value: 'Dudley Building Society',
  },
  {
    title: 'Ecology Building Society',
    value: 'Ecology Building Society',
  },
  {
    title: 'Family Building Society',
    value: 'Family Building Society',
  },
  {
    title: 'First Direct',
    value: 'First Direct',
  },
  {
    title: 'Fleet Mortgages',
    value: 'Fleet Mortgages',
  },
  {
    title: 'Foundation Home Loans',
    value: 'Foundation Home Loans',
  },
  {
    title: 'Furness Building Society',
    value: 'Furness Building Society',
  },
  {
    title: 'Generation Home',
    value: 'Generation Home',
  },
  {
    title: 'Godiva Mortgages',
    value: 'Godiva Mortgages',
  },
  {
    title: 'Habito',
    value: 'Habito',
  },
  {
    title: 'Halifax',
    value: 'Halifax',
  },
  {
    title: 'Handelsbanken',
    value: 'Handelsbanken',
  },
  {
    title: 'Hanley Economic Building Society',
    value: 'Hanley Economic Building Society',
  },
  {
    title: 'Hinckley & Rugby Building Society',
    value: 'Hinckley & Rugby Building Society',
  },
  {
    title: 'Hodge Bank',
    value: 'Hodge Bank',
  },
  {
    title: 'HSBC',
    value: 'HSBC',
  },
  {
    title: 'Ipswich Building Society',
    value: 'Ipswich Building Society',
  },
  {
    title: 'Kent Reliance',
    value: 'Kent Reliance',
  },
  {
    title: 'Leeds Building Society',
    value: 'Leeds Building Society',
  },
  {
    title: 'Leek United Building Society',
    value: 'Leek United Building Society',
  },
  {
    title: 'LendInvest',
    value: 'LendInvest',
  },
  {
    title: 'LiveMore Capital',
    value: 'LiveMore Capital',
  },
  {
    title: 'Lloyds',
    value: 'Lloyds',
  },
  {
    title: 'Loughborough Building Society',
    value: 'Loughborough Building Society',
  },
  {
    title: 'M&S Bank',
    value: 'M&S Bank',
  },
  {
    title: 'Mansfield Building Society',
    value: 'Mansfield Building Society',
  },
  {
    title: 'Market Harborough Building Society',
    value: 'Market Harborough Building Society',
  },
  {
    title: 'Marsden Building Society',
    value: 'Marsden Building Society',
  },
  {
    title: 'Melton Mowbray Building Society',
    value: 'Melton Mowbray Building Society',
  },
  {
    title: 'Metro Bank',
    value: 'Metro Bank',
  },
  {
    title: 'Moda Mortgages',
    value: 'Moda Mortgages',
  },
  {
    title: 'Monmouthshire Building Society',
    value: 'Monmouthshire Building Society',
  },
  {
    title: 'The Mortgage Lender',
    value: 'The Mortgage Lender Limited',
  },
  {
    title: 'The Mortgage Works',
    value: 'The Mortgage Works',
  },
  {
    title: 'National Counties Building Society',
    value: 'National Counties Building Society',
  },
  {
    title: 'Nationwide',
    value: 'Nationwide',
  },
  {
    title: 'NatWest',
    value: 'Natwest',
  },
  {
    title: 'Newbury Building Society',
    value: 'Newbury Building Society',
  },
  {
    title: 'Newcastle Building Society',
    value: 'Newcastle Building Society',
  },
  {
    title: 'Nottingham Building Society',
    value: 'Nottingham Building Society',
  },
  {
    title: 'Paragon',
    value: 'Paragon',
  },
  {
    title: 'Pepper Money',
    value: 'Pepper Money',
  },
  {
    title: 'Perenna',
    value: 'Perenna',
  },
  {
    title: 'Platform',
    value: 'Platform',
  },
  {
    title: 'Post Office Money',
    value: 'Post Office Money',
  },
  {
    title: 'Precise Mortgages',
    value: 'Precise Mortgages',
  },
  {
    title: 'Principality Building Society',
    value: 'Principality Building Society',
  },
  {
    title: 'Reliance Bank',
    value: 'Reliance Bank',
  },
  {
    title: 'Royal Bank of Scotland',
    value: 'Royal Bank of Scotland',
  },
  {
    title: 'Stafford Railway Building Society',
    value: 'Stafford Railway Building Society',
  },
  {
    title: 'Saffron Building Society',
    value: 'Saffron Building Society',
  },
  {
    title: 'Santander',
    value: 'Santander',
  },
  {
    title: 'Scottish Building Society',
    value: 'Scottish Building Society',
  },
  {
    title: 'Scottish Widows',
    value: 'Scottish Widows',
  },
  {
    title: 'Selina Finance',
    value: 'Selina Finance',
  },
  {
    title: 'Skipton Building Society',
    value: 'Skipton Building Society',
  },
  {
    title: 'Suffolk Building Society',
    value: 'Suffolk Building Society',
  },
  {
    title: 'Teachers Building Society',
    value: 'Teachers Building Society',
  },
  {
    title: 'Tipton & Coseley Building Society',
    value: 'Tipton & Coseley Building Society',
  },
  {
    title: 'TSB Bank',
    value: 'TSB',
  },
  {
    title: 'Vernon Building Society',
    value: 'Vernon Building Society',
  },
  {
    title: 'Vida Homeloans',
    value: 'Vida Homeloans',
  },
  {
    title: 'Virgin Money',
    value: 'Virgin Money',
  },
  {
    title: 'West Bromwich Building Society',
    value: 'West Bromwich Building Society',
  },
  {
    title: 'Yorkshire Bank',
    value: 'Yorkshire Bank',
  },
  {
    title: 'Yorkshire Building Society',
    value: 'Yorkshire Building Society',
  },
  {
    title: 'Zephyr Homeloans',
    value: 'Zephyr Homeloans',
  },
  {
    title: 'Other',
    value: 'Other',
  },
];

export const BUYING_PROOF_OF_FUNDS_OPTIONS = [
  {
    title: 'Savings or investments',
    value: 'Savings or investments',
    name: 'cashAndSavings',
  },
  {
    title: 'Mortgage',
    value: 'Mortgage',
    name: 'mortgage',
  },
  {
    title: 'Sale of another property',
    value: 'Selling another property',
    name: 'sellingAnotherProperty',
  },
  {
    title: 'Remortgage of another property',
    value: 'Remortgaging another property',
    name: 'remortgagingAnotherProperty',
  },
  {
    title: 'Help to Buy ISA',
    value: 'Help to Buy ISA',
    name: 'helpToBuyIsa',
  },
  {
    title: 'Lifetime ISA',
    value: 'Lifetime ISA',
    name: 'lifetimeIsa',
  },
  {
    title: 'Inheritance (Gift of money from someone who has died)',
    value: 'Inheritance',
    name: 'inheritance',
  },
  {
    title: 'Gift of money from someone who is alive',
    value: 'Someone’s giving me money',
    name: 'someoneGivingMeMoney',
  },
  {
    title: 'Loan (not including a mortgage) from a person or bank',
    value: 'Someone’s lending me money',
    name: 'someoneLendingMeMoney',
  },
  {
    title: 'Annual or one-off bonus from your employer',
    value: 'Bonus from employer',
    name: 'bonusFromEmployer',
  },
  {
    title: 'Money from a Trust',
    value: 'Money from a Trust',
    name: 'trust',
  }
];

export const BUYING_PROOF_OF_FUNDS = BUYING_PROOF_OF_FUNDS_OPTIONS.reduce((obj, item) => (obj[item.name] = false, obj), {});

export const INSURANCE_LIMIT = 4000000
