import React, {Component} from 'react';
import Card from '../../components/DashboardCard';
import Confirmation from '../../components/Confirmation';
import QuestionDescription from '../../components/QuestionDescription';
import PageHeader from '../../components/PageHeader';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import ScrollToTop from '../../components/utils/ScrollToTop';

class DocumentUploadDoableSubmission extends Component {

  createContent(content) {
    return { __html: content };
  }

  render() {

    if (!this.props.doable) return null;

    const requestTitle = this.props.doable.title;
    const requestDescription = this.props.doable.description;

    return (
      <React.Fragment>
        <ScrollToTop />
        <PageHeader>
          Things you’ve done
        </PageHeader>
        <Confirmation title="We've got your documents" submission={true}>
          <p>
            Thanks for sending us your documents – we’ll be in touch if we have any questions.
          </p>
        </Confirmation>

        <Card title={requestTitle}>
          {requestDescription && (
            <QuestionDescription dangerouslySetInnerHTML={this.createContent(requestDescription)} />
          )}
        </Card>
        <Image src={imageOfficeWorking} fullWidth={true} />
      </React.Fragment>
    )
  }
}

export default DocumentUploadDoableSubmission;
