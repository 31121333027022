// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-DocumentVideo-Confirm-container{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}.onfido-sdk-ui-DocumentVideo-Confirm-container.onfido-sdk-ui-DocumentVideo-Confirm-center{-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center}.onfido-sdk-ui-DocumentVideo-Confirm-content{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center}.onfido-sdk-ui-DocumentVideo-Confirm-title{font-size:1.5em;font-weight:bold}.onfido-sdk-ui-DocumentVideo-Confirm-content>.onfido-sdk-ui-DocumentVideo-Confirm-icon{background-image:url(\"data:image/svg+xml,%3Csvg width%3D%2256%22 height%3D%2256%22 viewBox%3D%220 0 56 56%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E  %3Ctitle%3E    complete-tick  %3C%2Ftitle%3E  %3Cg fill%3D%22none%22 fill-rule%3D%22evenodd%22%3E    %3Ccircle fill%3D%22%23353FF4%22 cx%3D%2228%22 cy%3D%2228%22 r%3D%2228%22%2F%3E    %3Cpath d%3D%22M37.723 16.373l-13.69 14.53-3.423-3.633c-1.512-1.6-3.964-1.6-5.476 0-1.512 1.608-1.512 4.214 0 5.813l6.16 6.544c1.513 1.61 3.964 1.61 5.477 0l16.43-17.44c1.51-1.61 1.51-4.215 0-5.814-1.513-1.61-3.964-1.61-5.477 0z%22 fill%3D%22%23FFF%22%2F%3E  %3C%2Fg%3E%3C%2Fsvg%3E\");background-repeat:no-repeat;background-size:contain;height:2em;width:2em;margin-bottom:.5em}.onfido-sdk-ui-DocumentVideo-Confirm-content>.onfido-sdk-ui-DocumentVideo-Confirm-body{font-weight:normal}.onfido-sdk-ui-DocumentVideo-Confirm-preview .onfido-sdk-ui-DocumentVideo-Confirm-videoWrapper{width:66%;margin:1em auto 0}.onfido-sdk-ui-DocumentVideo-Confirm-buttonsContainer>button{margin-top:1em}", ""]);
// Exports
exports.locals = {
	"modal_animation_duration": "200ms",
	"container": "onfido-sdk-ui-DocumentVideo-Confirm-container",
	"center": "onfido-sdk-ui-DocumentVideo-Confirm-center",
	"content": "onfido-sdk-ui-DocumentVideo-Confirm-content",
	"title": "onfido-sdk-ui-DocumentVideo-Confirm-title",
	"icon": "onfido-sdk-ui-DocumentVideo-Confirm-icon",
	"body": "onfido-sdk-ui-DocumentVideo-Confirm-body",
	"preview": "onfido-sdk-ui-DocumentVideo-Confirm-preview",
	"videoWrapper": "onfido-sdk-ui-DocumentVideo-Confirm-videoWrapper",
	"buttonsContainer": "onfido-sdk-ui-DocumentVideo-Confirm-buttonsContainer"
};
module.exports = exports;
