import React from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';
import {Link} from 'react-router-dom';

const LinkSent = (props) => {
  return (
    <>
      <ScrollToTop />
      <Card title="Check your email" removeTopPadding headingSize="1">
        <p>
          We've sent an email to <strong>{props.emailAddress}</strong> with a link to sign in instantly to your Juno dashboard.
        </p>
        <p>
          Not received the email? <Link to="/login">Send me another link</Link>
        </p>
      </Card>
    </>
  );
};

export default LinkSent;
