import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';
import CallToAction from '../components/CallToAction.js';
import { LIFECYCLE_TAGS } from '../utils/constants';

function DashboardLayout({ children, showMenu, selectedNavItem, showNav }) {
  return (
    <DashboardGrid>
      <DashboardSidebar>
        <Aside showMenu={showMenu}>
          <CTAWrapper>
            <CallToAction>
              <h2>
                Let’s discuss referrals
              </h2>
              <p>
                <a href="mailto:partnerships@juno.legal">
                  partnerships@juno.legal
                </a>
              </p>
            </CallToAction>
          </CTAWrapper>
          <Nav role="navigation">
            <NavList>
            {Object.keys(LIFECYCLE_TAGS).map(key => (
              <NavListItem key={key}>
                <NavListLink
                  selected={selectedNavItem === key}
                  aria-current={selectedNavItem === key ? 'page' : undefined}
                  onClick={(e) => { e.preventDefault(); showNav(key); }}>
                  {LIFECYCLE_TAGS[key].name}
                </NavListLink>
              </NavListItem>
            ))}
            </NavList>
          </Nav>
        </Aside>
      </DashboardSidebar>
      <DashboardContent>
        {children}
      </DashboardContent>
    </DashboardGrid>
  )
}

export default DashboardLayout;

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showMenu: PropTypes.bool.isRequired,
  selectedNavItem: PropTypes.string.isRequired,
};

DashboardLayout.defaultProps = {
  showLogout: true
};

const DashboardContent = styled.main`
  height: 100%;
  ${mediaQueries.lg} {
    padding-top: 4rem;
    height: calc(100vh - 4rem);
  }
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  ${mediaQueries.lg} {
    grid-template-columns: 18rem auto;
  }
`;

const DashboardSidebar = styled.div`
  /* Becomes 18rem wide on large screens */
  padding-top: 4rem;
`;

const Aside = styled.aside`
  background-color: ${props => props.theme.color.purple};

  display: none;
  width: 100%;
  height: 100%;

  ${mediaQueries.lg} {
    display: block;
    position: fixed;
    width: 18rem;
  }

  ${props => (props.showMenu ? 'display: block;' : 'display: none;')}
  ${props => (props.showMenu && 'position: fixed;' )}
  z-index: 1;
`;

const CTAWrapper = styled.div`
  margin: 1.5rem;
`;

const Nav = styled.nav`
  width: 100%;
  transform: translateZ(0);
`;

const NavList = styled.ul`
  font-size: ${props => props.theme.fontSizes.p['md']};
  color: ${props => props.theme.color.white};
  list-style: none;

  ${mediaQueries.lg} {
    margin-right: 0.5rem;
    margin-bottom: 3rem;
  }
  padding-left: 0;
`;

const NavListItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;

  margin-top: 1rem;
  margin-bottom: 1rem;

  svg {
    margin-right: 1rem;
  }
`;

const NavListLink = styled.a`
  display: flex;
  width: calc(100% - 2rem);
  padding: 0.75rem 1rem 0.75rem 1.5rem;

  border: 0 !important;
  color: ${props => props.selected ? props.theme.color.white : props.theme.color.palePurple} !important;
  background: ${props => props.selected ? props.theme.color.purple : 'transparent'};
  font-weight: ${props => props.selected ? props.theme.fontWeight.bold : props.theme.fontWeight.regular};

  &:focus {
    outline: none;
    color: ${props => props.theme.color.palePurple} !important;
    background: ${props => props.theme.color.purple};
  }

  &:hover {
    background: ${props => props.selected ? props.theme.color.purple : props.theme.color.palePurple};
    border-start-end-radius: 1rem;
    border-end-end-radius: 1rem;

    color: ${props => props.selected ? props.theme.color.white : props.theme.color.purple} !important;
    cursor: ${props => props.selected ? 'default' : 'pointer'};
  }
`;
