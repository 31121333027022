import React from 'react';

import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const StyledBigNumberedList = styled.ol`

  color: inherit;
  counter-reset: item;
  list-style-type: none;

  font-size: 16px;
  line-height: 1.5;
  ${mediaQueries.md} {
    font-size: 18px;
  }

  margin-top: 0;
  margin-left: 0;
  margin-bottom: 2em;
  padding-left: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 20px;
  }

  li:before {

    content: counter(item);
    counter-increment: item;

    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;

    margin-right: 20px;
    padding: 8px 6px 6px 6px;

    background: #7db89d;
    color: white;
    font-weight: ${props => props.theme.fontWeight.bold};
    border-radius: 50%;
    overflow: hidden;

    width: 30px;
    height: 30px;
    ${mediaQueries.md} {
      width: 48px;
      height: 48px;
      font-size: 24px;
    }

  }
`

function BigNumberedList(props) {
  return <StyledBigNumberedList {...props}>
    {props.children}
  </StyledBigNumberedList>;
}

export default BigNumberedList;
