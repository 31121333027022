import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';
import { CheckCircleOutline } from '@styled-icons/material';

const JunoCheckList = styled.ul`
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.junoFont};
  font-weight: ${props => props.theme.fontWeight.light};
  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: 1.5;
  margin-bottom: 1em;
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
    margin-bottom: 1.5em;
    line-height: 1.75;
  }
  list-style-type: none;
  padding-left: 0;
`;

const JunoCheckListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1em;
  line-height: 1em;
  margin-bottom: 0.5em;
  svg {
    font-size: 1.5em;
    margin-right: 0.5em;
  }
`;

const JunoCheckListIcon = styled(CheckCircleOutline)`
  width: ${props => props.theme.iconSize['md']};
  ${mediaQueries.md} {
    width: ${props => props.theme.iconSize['lg']};
  }
`

function CheckList(props) {
  return <JunoCheckList {...props}>{props.children}</JunoCheckList>;
}

export function CheckListItem(props) {
  return (
    <JunoCheckListItem {...props}>
      <JunoCheckListIcon />
      {props.children}
    </JunoCheckListItem>
  );
}

export default CheckList;
