import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import TextQuestion from '../../components/TextQuestion';
import { parseTenure } from '../../utils/queryStringUtils';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { SELLING_REJECTION_OPTIONS, TENURE_OPTIONS, YES_NO_OPTIONS, } from '../../constants/index';
import ReferralCodeBanner from "../../components/ReferralCodeBanner";

class BrokerQuoteSellingProperty extends Component {
  constructor(props) {
    super(props);

    if (this.props.sellingDetails) {
      const sd = this.props.sellingDetails;
      this.state = {
        price: this.formatMoneyWithCurrency(sd.price),
        priceValid: this.isPriceValid(sd.price),
        tenure: sd.tenure,
        divorceOrSeparation: sd.divorceOrSeparation,
        retirementProperty: sd.retirementProperty,
        shouldReject: sd.shouldReject,
        errors: {},
      };
    } else {
      this.state = {
        price: '',
        priceValid: true,
        tenure: parseTenure(),
        divorceOrSeparation: null,
        retirementProperty: null,
        shouldReject: null,
        errors: {},
      };
    }
  }

  selectTenure = t => {
    this.setState({ tenure: t });
  };

  priceEntered = p => {
    this.setState({ price: p });
  };

  formatMoneyWithCurrency = p => {
    let formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    });

    let moneyWithCurrency = formatter.format(p);

    if (moneyWithCurrency.includes('.00')) {
      let i = moneyWithCurrency.indexOf('.');
      return moneyWithCurrency.substring(0, i);
    }

    return moneyWithCurrency;
  };

  normalisePrice = p => {
    const stripCharsRegex = /[£, ]/g;
    return p.replace(stripCharsRegex, '');
  };

  isPriceValid = price => {
    const allowedCharsRegex = /^[0-9£,. ]+$/;
    if (allowedCharsRegex.test(String(price))) {
      const normalised = this.normalisePrice(price);

      const decimalPlaceRegex = /^\d+\.?\d{0,2}$/;

      return decimalPlaceRegex.test(String(normalised));
    }
    return false;
  };

  selectShouldReject = rb => {
    this.setState({ shouldReject: rb });
  };

  selectDivorceOrSeparation = dos => {
    this.setState({ divorceOrSeparation: dos });
  };

  selectRetirementProperty = rp => {
    this.setState({ retirementProperty: rp });
  };

  validatePrice = price => {
    this.setState({ priceValid: this.isPriceValid(price) });
  };

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  handlePriceFieldValidation = event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors['price'] = false;
    } else {
      if (!this.state.price || !this.isPriceValid(this.state.price)) {
        errors['price'] = true;
      }
    }
    this.setState({ errors });
  };

  handleValidation = () => {
    const fields = { ...this.state };
    let errors = {};
    let formIsValid = true;

    if (fields['price'] == null || !this.isPriceValid(fields['price'])) {
      errors['price'] = true;
      formIsValid = false;
    }

    if (fields['tenure'] == null) {
      errors['tenure'] = true;
      formIsValid = false;
    }

    if (fields['shouldReject'] == null) {
      errors['shouldReject'] = true;
      formIsValid = false;
    }

    if (fields['divorceOrSeparation'] == null) {
      errors['divorceOrSeparation'] = true;
      formIsValid = false;
    }

    if (fields['retirementProperty'] == null) {
      errors['retirementProperty'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  };

  saveSelection = () => {
    if (this.handleValidation()) {
      this.props.onStepComplete({
        sellingDetails: {
          tenure: this.state.tenure,
          price: this.normalisePrice(this.state.price),
          divorceOrSeparation: this.state.divorceOrSeparation,
          retirementProperty: this.state.retirementProperty,
          shouldReject: this.state.shouldReject,
        }
      });
    }
  };

  render() {
    let cardTitle;
    let priceHint;
    let rejectionQuestion;
    let divorceRejectionQuestion;
    let retirementPropertyRejectionQuestion;
    let buttonText;

    let rejectionReason = [];
    for (let i = 0; i < SELLING_REJECTION_OPTIONS.length; i++) {
      rejectionReason.push(SELLING_REJECTION_OPTIONS[i].value);
    }
    rejectionReason = rejectionReason
      .join(', ')
      .replace(/, ([^,]*)$/, ' or $1');

    if (this.props.mode === 'direct') {
      cardTitle =
        this.props.transactionType === 'BuyingAndSelling'
          ? "Tell us about the property you’re selling"
          : 'Get your instant quote';

      priceHint =
        'If you’re not sure just put an estimate – you can change it later';

      rejectionQuestion = `Are you selling ${rejectionReason}?`;
      divorceRejectionQuestion = 'Are you selling because of a divorce or separation?'
      retirementPropertyRejectionQuestion = "Is the property you’re selling a home built specifically for people in later life?"

      buttonText =
        this.props.transactionType === 'BuyingAndSelling'
          ? 'Continue'
          : 'Get your quote';
    } else {
      cardTitle =
        this.props.transactionType === 'BuyingAndSelling'
          ? 'Tell us about the property they are selling'
          : 'Tell us about their property';

      rejectionQuestion = `Are they selling ${rejectionReason}?`;
      divorceRejectionQuestion = 'Are they selling because of a divorce or separation?'
      retirementPropertyRejectionQuestion = "Is the property they’re selling a home built specifically for people in later life?"

      buttonText = 'Continue';
    }

    return (
      <React.Fragment>
        <ScrollToTop />

        <Card
          title={cardTitle}
          removeTopPadding={true}
        >
          <TextQuestion
            question="Sale price"
            hint={priceHint}
            value={this.state.price}
            valueEntered={this.priceEntered}
            validate={this.handlePriceFieldValidation}
            validationError={
              this.state.errors.price ? 'Please enter a valid price' : null
            }
          />

          <RadioQuestion
            question="Is the property:"
            options={TENURE_OPTIONS}
            name="tenure"
            selected={this.state.tenure}
            optionSelected={this.selectTenure}
            validate={this.handleFieldValidation('tenure')}
            validationError={
              this.state.errors.tenure ? 'Choose an option' : null
            }
          />

          <RadioQuestion
            question={rejectionQuestion}
            options={YES_NO_OPTIONS}
            inline={true}
            name="shouldRejectSelling"
            selected={this.state.shouldReject}
            optionSelected={this.selectShouldReject}
            validate={this.handleFieldValidation('shouldReject')}
            validationError={
              this.state.errors['shouldReject']
                ? 'Please choose an option'
                : null
            }
          />

          <RadioQuestion
            question={divorceRejectionQuestion}
            options={YES_NO_OPTIONS}
            inline={true}
            name="divorceOrSeparation"
            selected={this.state.divorceOrSeparation}
            optionSelected={this.selectDivorceOrSeparation}
            validate={this.handleFieldValidation('divorceOrSeparation')}
            validationError={
              this.state.errors['divorceOrSeparation']
                ? 'Please choose an option'
                : null
            }
          />

          <RadioQuestion
            question={retirementPropertyRejectionQuestion}
            hint="A 'retirement home', sheltered accommodation or in an 'assisted living' development"
            options={YES_NO_OPTIONS}
            inline={true}
            name="retirementProperty"
            selected={this.state.retirementProperty}
            optionSelected={this.selectRetirementProperty}
            validate={this.handleFieldValidation('retirementProperty')}
            validationError={
              this.state.errors['retirementProperty']
                ? 'Please choose an option'
                : null
            }
          />

          {!!this.props.referral &&
            <ReferralCodeBanner text={this.props.referral.discountMessage} small={true} />
          }

          <Action onClick={this.saveSelection}>{buttonText}</Action>
        </Card>

        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default BrokerQuoteSellingProperty;
