import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useClientAppFormRequest from '../../hooks/useClientAppFormRequest';
import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import StepFlowNavigate from '../../components/StepFlowNavigate';
import TA10Intro from './TA10Intro';
import TA10RoomManager from "./TA10RoomManager";
import TA10RoomFlow from './TA10RoomFlow';
import TA10Thanks from './TA10Thanks';

const TA10Flow = (props) => {
  const location = useLocation()
  const { requestId } = useParams()
  const [fromDashboard, setFromDashboard] = useState(false);
  const [flowCompleted, setFlowCompleted] = useState(false);

  const { loading, error, requestDetails, submitted } = useClientAppFormRequest(`/api/propertyInformationFormTA10/${requestId}`);

  // Set fromDashboard state on initial load
  useEffect(() => {
    const { fromDashboard } = location.state || {};
    setFromDashboard(fromDashboard);
  }, []);

  if (loading) {
    return null;
  }

  if (submitted || flowCompleted) {
    return (
      <PageLayout>
        <TA10Thanks fromDashboard={fromDashboard} />
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <ScrollToTop />
        <Card title="Sorry, that didn't work" removeTopPadding>
          {error}
        </Card>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <StepFlowNavigate
        initialFlowState={{
          ...requestDetails.ta10Data,
          formRequestId: requestDetails.formRequestId,
        }}
        steps={
          [
            {
              name: 'Intro',
              slug: 'intro',
              component: flowState => (
                <TA10Intro/>
              ),
            },
            {
              name: 'Setup',
              slug: 'choose-rooms',
              component: flowState => (
                <TA10RoomManager
                  rooms={flowState.rooms}
                  sectionRooms={flowState.sectionRooms}
                />
              ),
            },
            {
              name: 'Room flow',
              slug: 'fixtures-and-fittings',
              component: flowState => (
                <TA10RoomFlow
                  rooms={flowState.rooms}
                  initialFlowState={flowState}
                  onFlowComplete={() => setFlowCompleted(true)}
                />
              )
            }
          ]}
      />
    </PageLayout>
  );
}

export default TA10Flow;
