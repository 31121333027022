import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const StyledImageWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  margin-bottom: -16px;
  ${mediaQueries.md} {
    margin-bottom: -33px;
  }

  img {
    max-width: 100%;
    width: ${props => props.imageType === "chair" ? '75%' : "100%"};
  }
`;

function DashboardPanelImage(props) {
  return (
    <StyledImageWrapper imageType={props.imageType} className={props.className}>
      <img src={props.src} alt={props.alt} />
    </StyledImageWrapper>
  );
}

export default DashboardPanelImage;
