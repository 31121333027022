import React from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Action from '../../components/Action';
import Card from '../../components/Card';
import CheckList, { CheckListItem } from '../../components/CheckList';
import List, { ListItem } from '../../components/List';
import { formatMoneyWithCurrency, moneyStrToNum } from '../../utils/moneyUtils';
import Image from '../../components/Image';
import imageRelaxingOnSofa from '../../images/relaxing-on-sofa.png';
import ReferralCodeBanner from '../../components/ReferralCodeBanner';
import { ListItemSuffix, QuoteBreakdown, QuotedPrice, QuotedPriceWrapper } from "../QuoteCommon/QuoteStyles";

import { isSequenceReferral } from '../ReferrerQuote/utils/ReferrerQuoteUtils';

function ReferrerQuoteSentPage(props) {

    const {
      buyingDetails,
      defaultChannel,
      defaultReferrer,
      googleAnalyticsId,
      inBranchReferral,
      recipient,
      referral,
      quoteDetails,
      transactionType,
      sellingDetails,
      remortgagingDetails
    } = props;

    const showReferralCodeBanner = !inBranchReferral;

    let channel = defaultChannel;
    let referrer = defaultReferrer;
    if (referral && referral.referralSource) {
      channel = referral.referralSource;
      referrer = referral.referralCode;
    }
    // Channel here is the lead source, e.g. home_conveyancing_seller
    const sequenceCase = isSequenceReferral(channel)

    let saleLegalFee = '';
    let salePropertyPrice = '';
    let purchaseLegalFee = '';
    let purchasePropertyPrice = '';
    let quotePrice = '';
    let quotePriceText = '';
    let typeOfPurchase = '';
    let stampDuty = '';
    let stampDutyDescription = 'Stamp Duty';
    let landRegistryFee = '';
    let totalFeesAndTaxes = '';

    let remortgageAmount = '';
    let remortgageLegalFee = '';
    let noSearchIndemnityFee = null;
    let searchesFee = null;

    let includeDecimal = landRegistryFee.includes('.') || stampDuty.includes('.');

    if (quoteDetails) {
      quotePrice = formatMoneyWithCurrency(
        moneyStrToNum(quoteDetails.totalPrice).toString(),
        includeDecimal,
      );

      saleLegalFee = formatMoneyWithCurrency(
        moneyStrToNum(quoteDetails.sellingQuotePrice).toString(),
        includeDecimal,
      );

      purchaseLegalFee = formatMoneyWithCurrency(
        moneyStrToNum(quoteDetails.buyingQuotePrice).toString(),
        includeDecimal,
      );

      remortgageLegalFee = remortgagingDetails && formatMoneyWithCurrency(
        moneyStrToNum(quoteDetails.remortgageQuotePrice),
        includeDecimal,
      );

      stampDuty = formatMoneyWithCurrency(
        moneyStrToNum(quoteDetails.stampDutyFee).toString(),
        includeDecimal,
      );

      if (
        buyingDetails &&
        (!!buyingDetails.rightToBuyScheme ||
          !!buyingDetails.sharedOwnership)
      ) {
        stampDutyDescription =
          'Stamp Duty (based on the full value of the property)';
      }

      landRegistryFee = formatMoneyWithCurrency(
        moneyStrToNum(quoteDetails.landRegistryFee).toString(),
        includeDecimal,
      );

      totalFeesAndTaxes = formatMoneyWithCurrency(
        moneyStrToNum(quoteDetails.totalFeesAndTaxes).toString(),
        includeDecimal,
      );

      purchasePropertyPrice =
        transactionType === 'Buying' ||
        transactionType === 'BuyingAndSelling'
          ? formatMoneyWithCurrency(
              moneyStrToNum(buyingDetails.price).toString(),
              includeDecimal,
            )
          : '';

      salePropertyPrice =
        transactionType === 'Selling' ||
        transactionType === 'BuyingAndSelling'
          ? formatMoneyWithCurrency(
              moneyStrToNum(sellingDetails.price).toString(),
              includeDecimal,
            )
          : '';

      if (transactionType === 'Remortgaging') {
        remortgageAmount = formatMoneyWithCurrency(
          moneyStrToNum(remortgagingDetails.mortgageAmount).toString(),
          includeDecimal,
        )

        if (!!quoteDetails.noSearchIndemnityFee) {
          noSearchIndemnityFee = formatMoneyWithCurrency(
            moneyStrToNum(quoteDetails.noSearchIndemnityFee).toString(),
            includeDecimal,
          );
        }

        if (!!quoteDetails.searchesFee) {
          searchesFee = formatMoneyWithCurrency(
            moneyStrToNum(quoteDetails.searchesFee).toString(),
            includeDecimal,
          );
        }
      }
    }

    function getTenure(details) {
      if (details?.tenure === 'leasehold with share of freehold') {
        return 'share of freehold';
      }
      return details.tenure;
    }

    let sellingTenure = '';
    let buyingTenure = '';
    let remortgageTenure = '';

    if (transactionType === 'Selling' || transactionType === 'BuyingAndSelling') {
      sellingTenure = getTenure(sellingDetails);
    }
    if (transactionType === 'Buying' ||transactionType === 'BuyingAndSelling') {
      buyingTenure = getTenure(buyingDetails);
    }
    if (transactionType === 'Remortgaging') {
      remortgageTenure = getTenure(remortgagingDetails);
    }

    switch (transactionType) {
      case 'Buying':
        quotePriceText = `That’s our quote, including VAT, for legal work on a ${buyingTenure} purchase costing ${purchasePropertyPrice}.`;
        break;
      case 'Selling':
        quotePriceText = `That’s our quote, including VAT, for legal work on a ${sellingTenure} sale costing ${salePropertyPrice}.`;
        break;
      case 'BuyingAndSelling':
        quotePriceText = `That’s our quote, including VAT, for legal work on a ${buyingTenure} purchase costing ${purchasePropertyPrice} and ${sellingTenure} sale costing ${salePropertyPrice}.`;
        break;
      case 'Remortgaging':
        quotePriceText = `That’s our quote, including VAT, for switching to a ${remortgageAmount} title-insured buy-to-let mortgage on a ${remortgageTenure} property.`;
        break;
    }

    let cardTitle, introText;
    let showQuote = true;
    const recipientData = {
      agent: {
        cardTitle: 'Thanks, now check your email',
        introText: 'In a couple of minutes you’ll get an email from Juno with a link to enter information about your client.',
        showQuote: false,
      },
      default: {
        cardTitle: 'Thanks, we’ve sent your client the following quote',
        showQuote: true,
      },
    };

    if (inBranchReferral) {
      cardTitle = 'Thanks, we’ve prepared a quote for your client';
      introText = 'One of our sales team will get in touch with your client to confirm the details and send the quote'
    } else {
      const data = recipientData[recipient] || recipientData.default;
      cardTitle = data.cardTitle;
      introText = data.introText;
      showQuote = data.showQuote;
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={cardTitle} removeTopPadding>
          <p>{introText}</p>

          {showQuote && (
            <React.Fragment>
            <QuotedPriceWrapper>
              <QuotedPrice>{quotePrice}</QuotedPrice>
              <p>{quotePriceText}</p>
              {!sequenceCase && (
              <CheckList>
                <CheckListItem>No completion, no legal fee</CheckListItem>
                <CheckListItem>No hidden fees</CheckListItem>
                {(transactionType === 'Buying' ||
                 transactionType === 'BuyingAndSelling') && (
                  <CheckListItem>Search guarantee</CheckListItem>
                )}
              </CheckList>
              )}
            </QuotedPriceWrapper>

            {!!referral && showReferralCodeBanner && (
              <ReferralCodeBanner text={referral.quoteMessage} withinQuote={true} />
            )}

            {transactionType === 'Buying' ||
            transactionType === 'BuyingAndSelling' ? (
              <QuoteBreakdown>
                <List>
                  {transactionType === 'BuyingAndSelling' ? (
                    <>
                      <ListItem divider>
                        Legal work on the {sellingTenure} sale
                        <ListItemSuffix>{saleLegalFee}</ListItemSuffix>
                      </ListItem>
                    </>
                  ) : (
                    <></>
                  )}

                  <ListItem divider>
                    Legal work on the {buyingTenure} purchase
                    <ListItemSuffix>{purchaseLegalFee}</ListItemSuffix>
                  </ListItem>
                  <ListItem divider>
                    Land Registry fee
                    <ListItemSuffix data-testid="land-registry-fee">
                      {landRegistryFee}
                    </ListItemSuffix>
                  </ListItem>
                  <ListItem divider>
                    {stampDutyDescription}
                    <ListItemSuffix data-testid="stamp-duty-fee">
                      {stampDuty}
                    </ListItemSuffix>
                  </ListItem>
                  <ListItem divider>
                    Total including government fees and taxes
                    <ListItemSuffix data-testid="total-fees-and-taxes">
                      {totalFeesAndTaxes}
                    </ListItemSuffix>
                  </ListItem>
                </List>
              </QuoteBreakdown>
            ) : null}

            {transactionType === 'Remortgaging' && (
              <QuoteBreakdown>
                <List>
                  <ListItem divider>
                    Our fee for legal work switching your mortgage
                    <ListItemSuffix data-testid="no-search-indemnity-fee">
                      {remortgageLegalFee}
                    </ListItemSuffix>
                  </ListItem>
                  <ListItem divider>
                    Land Registry fee
                    <ListItemSuffix data-testid="land-registry-fee">
                      {landRegistryFee}
                    </ListItemSuffix>
                  </ListItem>
                </List>
              </QuoteBreakdown>
            )}
            </React.Fragment>
          )}

          <Action onClick={() => props.restartFlow()}>
            Refer another client
          </Action>

        </Card>

        <Image src={imageRelaxingOnSofa} fullWidth />
      </React.Fragment>
    );

}

export default ReferrerQuoteSentPage;
