import React from 'react';
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components/macro';
import theme from './styles/theme.js';
import GlobalStyle from './styles/GlobalStyle';
import store from './app/store';
import AnalyticsLoader from "./features/analytics/AnalyticsLoader";
import PageNotFound from './pages/PageNotFound';
import QuoteFlow from './pages/Quote/QuoteFlow';
import SignupOtherClientsFlow from './pages/SignupOtherClients/SignupOtherClientsFlow';
import MoreInformationFlow from './pages/MoreInformation/MoreInformationFlow';
import SellingMortgagesAndLoansFlow from './pages/MortgagesAndLoans/SellingMortgagesAndLoansFlow';
import TermsAndConditionsPage from './pages/Quote/TermsAndConditionsPage';
import ReferrerTerms from './pages/ReferrerQuote/ReferrerTerms';
import RedirectToStripeCheckout from './pages/payments/RedirectToStripeCheckout';
import SourceOfFundsFlow from './pages/SourceOfFunds/SourceOfFundsFlow';
import RemoSignupFlow from './pages/RemortgageSignup/RemoSignupFlow';
import RemoLeaseholdFlow from './pages/RemortgageLeasehold/RemoLeaseholdFlow';
import Login from './pages/ClientDashboard/Login';
import Logout from './pages/ClientDashboard/Logout';
import LoginCode from './pages/ClientDashboard/LoginCode';
import LinkExpired from './pages/ClientDashboard/LinkExpired';
import SharedOwnershipPurchaseFlow from './pages/SharedOwnershipPurchase/SharedOwnershipPurchaseFlow';
import IdentityCheckFlow from './pages/IdentityCheck/IdentityCheckFlow';
import EsignatureRequestFlow from './pages/EsignatureRequest/EsignatureRequestFlow';
import RemoAccountNumbersFlow from './pages/RemortgageAccountNumbers/RemoAccountNumbersFlow';
import DashboardSelector from "./pages/ClientDashboard/DashboardSelector";
import SignupFlow from "./pages/Signup/SignupFlow";
import ReferrerQuoteFlow from "./pages/ReferrerQuote/ReferrerQuoteFlow";
import InstructSavedQuote from "./pages/ReferrerQuote/InstructSavedQuote";
import TA10Flow from './pages/PropertyInformationFormTA10/TA10Flow';
import BrokerQuoteFlow from "./pages/BrokerQuote/BrokerQuoteFlow";
import RedirectOldReferAClientRoute from "./pages/ReferrerQuote/RedirectOldReferAClientRoute";

const AppDiv = styled.div`
  width: 100%;
  height: 100%;
`;

function RedirectToJuno() {
  window.location.replace('https://www.juno.legal/');
  return null;
}

function App() {
  return (
    <Provider store={store}>
      <AnalyticsLoader />
      <Router>
        <ThemeProvider theme={theme}>
          <AppDiv>
            <GlobalStyle />
              <Routes>
                <Route
                  path="/"
                  element={<RedirectToJuno />} />
                <Route
                  path="/quote/:referralCode"
                  element={<QuoteFlow/> } />
                <Route
                  path="/quote"
                  element={<QuoteFlow/> } />
                <Route
                  path="/remortgage/:signupId"
                  element={<RemoSignupFlow />}
                />
                <Route
                  path="/refer-a-client/*"
                  element={<RedirectOldReferAClientRoute />}
                />
                <Route
                  path="/refer/terms"
                  element={<ReferrerTerms />}
                />
                <Route
                  path="/refer/broker/:referralCode"
                  element={<BrokerQuoteFlow />}
                />
                <Route
                  path="/refer/*"
                  element={<ReferrerQuoteFlow />}
                />
                <Route
                  path="/instruct/:quoteId"
                  element={<InstructSavedQuote />}
                />
                <Route
                  path="/signup/:signupId"
                  element={<SignupFlow /> } />
                {/*<Route path="/signup/:signupId" element={<SignupTemporarilySuspended/> } />*/}
                <Route
                  path="/client/:clientId"
                  element={<SignupOtherClientsFlow />}
                />
                <Route
                  path="/requests/more-information/:requestId"
                  element={<MoreInformationFlow />}
                />
                <Route
                  path="/requests/mortgages-and-loans/:requestId"
                  element={<SellingMortgagesAndLoansFlow />}
                />
                <Route
                  path="/requests/remortgage-leasehold-information/:requestId"
                  element={<RemoLeaseholdFlow />}
                />
                <Route
                  path="/requests/remortgage-account-numbers/:requestId"
                  element={<RemoAccountNumbersFlow/>}
                />
                <Route
                  path="/requests/source-of-funds/:requestId"
                  element={<SourceOfFundsFlow />}
                />
                <Route
                  path="/requests/shared-ownership-purchase/:requestId"
                  element={<SharedOwnershipPurchaseFlow />}
                />
                <Route
                  path="/requests/identity-check/:requestId"
                  element={<IdentityCheckFlow />}
                />
                <Route
                  path="/requests/esignature-signing-request/:requestId"
                  element={<EsignatureRequestFlow />}
                />
                <Route
                  path="/requests/property-information-form-TA10/:requestId"
                  element={<TA10Flow />}
                />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditionsPage />}
                />
                <Route
                  path="/payment/:success?"
                  element={<RedirectToStripeCheckout />}
                />
                <Route
                  path="/login/code/:loginId"
                  element={<LoginCode /> } />
                <Route
                  path="/login"
                  element={<Login />} />
                <Route
                  path="/logout"
                  element={<Logout /> } />
                <Route
                  path="/link-expired"
                  element={<LinkExpired /> } />
                <Route
                  path="/dashboard/*"
                  element={<DashboardSelector />} />
                <Route element={<PageNotFound /> } />
              </Routes>
          </AppDiv>
        </ThemeProvider>
      </Router>
    </Provider>
  );
}

export default App;
