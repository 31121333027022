import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const h1 = {
  sm: '20px',
  md: '26px',
};

const h2 = {
  sm: '18px',
  md: '20px',
};

const h3 = {
  sm: '16px',
  md: '16px',
};

const h4 = {
  sm: '16px',
  md: '18px',
};

export const JunoDashboardCard = styled.div`
  color: #0d0d0d;
  background-color: #ffffff;
  margin-bottom: 16px;
  ${mediaQueries.md} {
    box-shadow: 0 8px 16px 0 rgba(34, 50, 84, 0.06),
      inset 0 0 0 1px rgba(112, 123, 140, 0.15);
    border-radius: 8px;
  }
`;

export const JunoDashboardCardHeader = styled.div`
  padding: 8px 16px 8px 16px;
  ${mediaQueries.md} {
    padding: 32px 32px 16px 32px;
  }
  h1,
  h2,
  h3,
  h4 {
    max-width: 608px;
  }
`;

export const JunoDashboardCardContent = styled.div`
  font-size: 16px;
  padding: 0 16px 16px 16px;
  ${mediaQueries.md} {
    padding: 0 32px 32px 32px;
  }

  p.small {
    font-size: 16px;
    margin-top: -1em;
  }
`;

export const StyledDashboardCardContent = styled.div`
  padding: 0 20px 8px 20px;
  ${mediaQueries.md} {
    padding: 24px 32px;
  }
`;

const JunoHeading = styled.h1`
  font-family: ${props => props.theme.junoFont};
  font-style: normal;
  line-height: 1.25em;
  font-weight: ${props => props.theme.fontWeight.bold};

  margin: 0 0 0.5em 0;

  ${props => props.invisible && 'visibility: hidden; height: 1px; margin: 0; padding: 0;' };

  &.h1 {
    font-size: ${h1['sm']};
    ${mediaQueries.md} {
      font-size: ${h1['md']};
    }
  }

  &.h2 {
    font-size: ${h2['sm']};
    margin-left: -0.02em;
    ${mediaQueries.md} {
      font-size: ${h2['md']};
    }
  }

  &.h3 {
    font-size: ${h3['sm']};
    ${mediaQueries.md} {
      font-size: ${h3['md']};
    }
  }

  &.h4 {
    font-size: ${h4['sm']};
    ${mediaQueries.md} {
      font-size: ${h4['md']};
    }
  }
`;

export const DashboardHeading = ({ level, size, ...props }) => {
  return (
    <JunoHeading
      as={`h${level}`}
      className={size ? `h${size}` : `h${level}`}
      {...props}
    />
  );
};

function DashboardCard(props) {
  return (
    <JunoDashboardCard dashboard={props.dashboard} id={props.id} className={props.className}>
      {props.title && (
        <>
          <JunoDashboardCardHeader>
            <DashboardHeading
              level={props.headingLevel ? props.headingLevel : '1'}
              size={props.headingSize ? props.headingSize : '2'}
            >
              {props.title}
            </DashboardHeading>
          </JunoDashboardCardHeader>
          <JunoDashboardCardContent>{props.children}</JunoDashboardCardContent>
        </>
      )}

      {!props.title && (
        <StyledDashboardCardContent>{props.children}</StyledDashboardCardContent>
      )}
    </JunoDashboardCard>
  );
}

export default DashboardCard;
