import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';

class SourceOfFundsSubmitted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };

    const flowState = this.props.sourceOfFundsFlowState;

    fetch('/api/sourceOfFunds', {
      method: 'PUT',
      body: JSON.stringify({
        formRequestId: flowState.formRequestId,

        howIsBuyerPaying: flowState.howIsBuyerPaying,

        savings: flowState.savings,
        savingsAccount1: flowState.savingsAccount1,
        savingsAccount2: flowState.savingsAccount2,
        savingsAccount3: flowState.savingsAccount3,
        savingsAccount4: flowState.savingsAccount4,
        savingsAccount5: flowState.savingsAccount5,
        savingsAccount6: flowState.savingsAccount6,
        savingsAccount7: flowState.savingsAccount7,
        savingsAccount8: flowState.savingsAccount8,
        savingsAccount9: flowState.savingsAccount9,
        savingsAccount10: flowState.savingsAccount10,
        savingsAccount11: flowState.savingsAccount11,
        savingsAccount12: flowState.savingsAccount12,

        mortgage: flowState.mortgage,
        occupiers: flowState.occupiers,

        linkedSale: flowState.linkedSale,
        remortgage: flowState.remortgage,

        numberOfHelpToBuyIsaAccounts: flowState.helpToBuyIsa,
        helpToBuyIsa1: flowState.helpToBuyIsaAccount1,
        helpToBuyIsa2: flowState.helpToBuyIsaAccount2,
        helpToBuyIsa3: flowState.helpToBuyIsaAccount3,
        helpToBuyIsa4: flowState.helpToBuyIsaAccount4,

        numberOfLifetimeIsaAccounts: flowState.lifetimeIsa,
        lifetimeIsa1: flowState.lifetimeIsaAccount1,
        lifetimeIsa2: flowState.lifetimeIsaAccount2,
        lifetimeIsa3: flowState.lifetimeIsaAccount3,
        lifetimeIsa4: flowState.lifetimeIsaAccount4,

        numberOfGifts: flowState.giftingMoney,
        giftingMoney1: flowState.giftingMoney1,
        giftingMoney2: flowState.giftingMoney2,
        giftingMoney3: flowState.giftingMoney3,
        giftingMoney4: flowState.giftingMoney4,

        numberOfLoans: flowState.lendingMoney,
        lendingMoney1: flowState.lendingMoney1,
        lendingMoney2: flowState.lendingMoney2,

        bonusFromEmployer: flowState.bonusFromEmployer,

        trust: flowState.trust,
      }),
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
            submitted: true,
          });
          props.onFlowComplete();
        } else {
          this.setState({
            error: 'Unable to process your details',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Unable to process your details',
          loading: false,
        });
      },
    );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn't work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    if (this.state.submitted) {
      return null;
    }
  }
}

export default SourceOfFundsSubmitted;
