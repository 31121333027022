import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const JunoLogoWrapper = styled.div`
  margin-top: 2px;
  a {
    display: flex !important;
    align-items: center;
    border-bottom: none !important;
    transition: transform .25s, opacity .25s;
  }
  svg {
    fill: ${props => props.theme.color.white};
    margin-right: 11px;
    height: 30px;
    ${mediaQueries.lg} {
      height: 32px;
    }
  }
  &:hover {
    opacity: .72;
  }
`;

const JunoLogoText = styled.span`
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.junoLogoFont};
  font-size: 32px;
  font-weight: ${props => props.theme.fontWeight.bold};
`;

const JunoLogo = (props) => (
  <JunoLogoWrapper>
    <a href="https://www.juno.legal">
      <svg
        width="32"
        height="34"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 93 100"
        fontSize={props.fontSize}
      >
        <desc>
          The Juno logo shows an arrow pointing into a house. Juno makes it easy
          to buy or sell property, and to organise your move.
        </desc>
        <path
          fill={props.fill}
          d="M57.1 5.2 92.2 40v34.9H33.3l11.2-11.2h36.4V44.9L57.1 21.2 41.7 35.8 34 28.2zM40.3 51.9l-23.2 23H1l23.1-23L1 28.9h16.1z"
        />
        <title>Juno logo</title>
      </svg>
      <JunoLogoText>juno</JunoLogoText>
    </a>
  </JunoLogoWrapper>
);

export default JunoLogo;
