import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useClientAppSignupRequest from '../../hooks/useClientAppSignupRequest';
import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import SellingPropertyDetails from './SellingPropertyDetails';
import SellingAddressDetails from './SellingAddressDetails';
import SellingEstateAgent from './SellingEstateAgent';
import BuyingPropertyDetails from './BuyingPropertyDetails';
import BuyingAddressDetails from './BuyingAddressDetails';
import BuyingEstateAgent from './BuyingEstateAgent';
import SellingMortgagesAndLoans from './SellingMortgagesAndLoans';
import BuyingMortgage from './BuyingMortgage';
import BuyingMortgageLender from './BuyingMortgageLender';
import YourName from './YourName';
import ContactDetails from './ContactDetails';
import OtherOwnersBuyingOrSelling from './OtherOwnersBuyingOrSelling';
import AnythingElse from './AnythingElse';
import SignupSubmission from './SignupSubmission';
import Thanks from './Thanks';
import SorryBuyingMortgageLender from './SorryBuyingMortgageLender';
import BuyingSorryPage from './BuyingSorryPage';
import SellingSorryPage from './SellingSorryPage';
import TermsAndConditions from './TermsAndConditions';
import StepFlowNavigate from '../../components/StepFlowNavigate';
import SaleOwnersBuyingAndSelling from './SaleOwnersBuyingAndSelling';
import PurchaseOwnersBuyingAndSelling from './PurchaseOwnersBuyingAndSelling';
import BuyingMortgageBroker from "./BuyingMortgageBroker";
import SignupTemporarilySuspended from "./SignupTemporarilySuspended";

import { isSequenceReferral } from "../ReferrerQuote/utils/ReferrerQuoteUtils";

const SignupFlow = (props) => {

  const { signupId } = useParams()
  const [flowCompleted, setFlowCompleted] = useState(false);
  const [flowTransactionType, setFlowTransactionType] = useState('');

  const { loading, error, signupRequest, signupsEnabled, signupsDisabledReason, submitted } = useClientAppSignupRequest(`/api/signup/${signupId}`);

  // Set transactionType state when signupDetails is available
  useEffect(() => {
    if (signupRequest) {
      setFlowTransactionType(signupRequest.transactionType);
    }
  }, [signupRequest]);

  const isSelling = transactionType => {
    return (
      transactionType === 'Selling' || transactionType === 'BuyingAndSelling'
    );
  };

  const isBuying = transactionType => {
    return (
      transactionType === 'Buying' || transactionType === 'BuyingAndSelling'
    );
  };

  const isBuyingAndSelling = transactionType => {
    return transactionType === 'BuyingAndSelling';
  };

  const isBuyingWithOfferAccepted = flowState => {
    return (
      isBuying(flowState.transactionType) &&
      flowState.buyingPropertyOfferAccepted === true
    );
  };

  const shouldShowBuyingMortgageLender = flowState => {
    if (
      flowState.transactionType === 'Buying' ||
      flowState.transactionType === 'BuyingAndSelling'
    ) {
      return flowState.buyingMortgage.buyerHasMortgage === true;
    }
  };

  const shouldShowBuyingMortgageBroker = flowState => {
    if (
      flowState.transactionType === 'Buying' ||
      flowState.transactionType === 'BuyingAndSelling'
    ) {
      if (flowState.haveMortgageBrokerDetails) {
        return false
      } else {
        return (
          flowState.buyingMortgage.buyerHasMortgage === true
        )
      }
    }
  };

  const shouldShowSorryBuyingMortgageLender = flowState => {
    if (
      flowState.transactionType === 'Buying' ||
      flowState.transactionType === 'BuyingAndSelling'
    ) {
      return (
        flowState.buyingMortgageLender &&
        flowState.buyingMortgageLender.buyingMortgageLender === 'Other' &&
        signupRequest.isBuyToLet !== true
      );
    }
  };

  if (!!loading) {
    return null;
  }

  if (submitted || flowCompleted) {
    return (
      <PageLayout>
        <Thanks transactionType={flowTransactionType}/>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <ScrollToTop />
        <Card title="Sorry, that didn't work" removeTopPadding>
          {error}
        </Card>
      </PageLayout>
    );
  }

  if (signupsEnabled === false) {
    return <SignupTemporarilySuspended reason={signupsDisabledReason} />
  }
  const askAboutPrepaidSearches = isSequenceReferral(signupRequest.leadSource) && (
    signupRequest.transactionType === 'Buying' || signupRequest.transactionType === 'BuyingAndSelling'
  );

  return (
    <PageLayout>
      <StepFlowNavigate
        initialFlowState={{
          leadId: signupId,
          transactionType: signupRequest.transactionType,
          clientIsCompany: signupRequest.clientIsCompany,
          yourName: {
            client1: {
              companyName: '',
              firstName: signupRequest.firstName,
              lastName: signupRequest.lastName,
              emailAddress: signupRequest.email,
              hasPreviousName: null,
              previousName: '',
              firstNameIsGivenName: null,
              givenName: '',
            },
          },
          contactDetails: {
            emailAddress: signupRequest.email,
            phoneNumber: signupRequest.phoneNumber,
            clientAddressIsSaleAddress: null,
            clientAddress: {
              addressLine1: '',
              addressLine2: '',
              addressLine3: '',
              addressTown: '',
              addressCounty: '',
              addressPostcode: '',
            },
          },
          haveMortgageBrokerDetails: signupRequest.haveMortgageBrokerDetails,
          askAboutPrepaidSearches: askAboutPrepaidSearches,
        }}
        steps={[
          {
            name: 'Your name',
            slug: 'your-name',
            component: flowState => (
              <YourName
                transactionType={flowState.transactionType}
                yourName={flowState.yourName}
                clientIsCompany={flowState.clientIsCompany}
              />
            ),
          },
          {
            name: 'Contact details',
            slug: 'contact-details',
            component: flowState => (
              <ContactDetails
                transactionType={flowState.transactionType}
                clientIsCompany={flowState.clientIsCompany}
                sellingAddressDetails={flowState.sellingAddressDetails}
                contactDetails={flowState.contactDetails}
              />
            ),
          },
          {
            name: 'Other owners',
            slug: 'other-owners',
            valid: flowState =>
              !flowState.clientIsCompany &&
              (flowState.transactionType === 'Selling' ||
                flowState.transactionType === 'Buying'),
            component: flowState => (
              <OtherOwnersBuyingOrSelling
                transactionType={flowState.transactionType}
                otherOwners={flowState.otherOwners}
              />
            ),
          },
          {
            name: 'Sale details',
            slug: 'sale-details',
            valid: flowState => isSelling(flowState.transactionType),
            component: flowState => (
              <SellingPropertyDetails
                sellingPropertyOfferAccepted={
                  flowState.sellingPropertyOfferAccepted
                }
                sellingRejectionReason={flowState.sellingRejectionReason}
              />
            ),
          },
          {
            name: 'Selling sorry page',
            slug: 'selling-sorry-page',
            valid: flowState => flowState.shouldReject === true,
            component: flowState => (
              <SellingSorryPage
                transactionType={flowState.transactionType}
                sellingRejectionReason={flowState.sellingRejectionReason}
                leadId={flowState.leadId}
              />
            )
          },
          {
            name: 'Sale address',
            slug: 'sale-address',
            valid: flowState => isSelling(flowState.transactionType),
            component: flowState => (
              <SellingAddressDetails
                propertyOfferAccepted={flowState.sellingPropertyOfferAccepted}
                sellingAddressDetails={flowState.sellingAddressDetails}
              />
            ),
          },
          {
            name: "Buying and selling who's selling",
            slug: "sale-owners-buying-and-selling",
            valid: flowState =>
              isBuyingAndSelling(flowState.transactionType),
            component: flowState => (
              <SaleOwnersBuyingAndSelling
                transactionType={flowState.transactionType}
                otherOwnersSelling={flowState.otherOwnersSelling}
              />
            ),
          },
          {
            name: 'Sale estate agent',
            slug: 'sale-estate-agent',
            valid: flowState => isSelling(flowState.transactionType),
            component: flowState => (
              <SellingEstateAgent
                propertyOfferAccepted={flowState.sellingPropertyOfferAccepted}
                sellingEstateAgent={flowState.sellingEstateAgent}
              />
            ),
          },
          {
            name: 'Sale mortgage and loans',
            slug: 'sale-mortgages-and-loans',
            valid: flowState => isSelling(flowState.transactionType),
            component: flowState => (
              <SellingMortgagesAndLoans
                sellingMortgagesAndLoans={flowState.sellingMortgagesAndLoans}
              />
            ),
          },
          {
            name: "Buying and selling who's buying",
            slug: "purchase-owners-buying-and-selling",
            valid: flowState =>
              isBuyingAndSelling(flowState.transactionType),
            component: flowState => (
              <PurchaseOwnersBuyingAndSelling
                transactionType={flowState.transactionType}
                otherOwnersSelling={flowState.otherOwnersSelling}
                otherOwnersPurchasing={flowState.otherOwnersPurchasing}
              />
            ),
          },
          {
            name: 'Purchase details',
            slug: 'purchase-details',
            valid: flowState => isBuying(flowState.transactionType),
            component: flowState => (
              <BuyingPropertyDetails
                transactionType={flowState.transactionType}
                clientIsCompany={flowState.clientIsCompany}
                buyingPropertyOfferAccepted={
                  flowState.buyingPropertyOfferAccepted
                }
                buyingRejectionReason={flowState.buyingRejectionReason}
              />
            ),
          },
          {
            name: 'Purchase sorry page',
            slug: 'purchase-sorry-page',
            valid: flowState => flowState.shouldReject === true,
            component: flowState => (
              <BuyingSorryPage
                transactionType={flowState.transactionType}
                buyingRejectionReason={flowState.buyingRejectionReason}
                leadId={flowState.leadId}
              />
            )
          },
          {
            name: 'Purchase address',
            slug: 'purchase-address',
            valid: flowState => isBuyingWithOfferAccepted(flowState),
            component: flowState => (
              <BuyingAddressDetails
                clientIsCompany={flowState.clientIsCompany}
                buyingAddressDetails={flowState.buyingAddressDetails}
              />
            ),
          },
          {
            name: 'Purchase estate agent',
            slug: 'purchase-estate-agent',
            valid: flowState => isBuyingWithOfferAccepted(flowState),
            component: flowState => (
              <BuyingEstateAgent
                buyingEstateAgent={flowState.buyingEstateAgent}
                askAboutPrepaidSearches={flowState.askAboutPrepaidSearches}
              />
            ),
          },
          {
            name: 'Purchase mortgage',
            slug: 'purchase-mortgage',
            valid: flowState => isBuying(flowState.transactionType),
            component: flowState => (
              <BuyingMortgage
                clientIsCompany={flowState.clientIsCompany}
                buyingMortgage={flowState.buyingMortgage}
              />
            ),
          },
          {
            name: 'Purchase mortgage lender',
            slug: 'purchase-mortgage-lender',
            valid: flowState => shouldShowBuyingMortgageLender(flowState),
            component: flowState => (
              <BuyingMortgageLender
                buyingMortgageLender={flowState.buyingMortgageLender}
              />
            ),
          },
          {
            name: 'Sorry mortgage lender',
            slug: 'sorry-mortgage-lender',
            valid: flowState =>
              shouldShowSorryBuyingMortgageLender(flowState),
            component: flowState => (
              <SorryBuyingMortgageLender leadId={flowState.leadId} />
            )
          },
          {
            name: 'Purchase mortgage broker',
            slug: 'purchase-mortgage-broker',
            valid: flowState => shouldShowBuyingMortgageBroker(flowState),
            component: flowState => (
              <BuyingMortgageBroker mortgageBroker={flowState.mortgageBroker} />
            ),
          },
          {
            name: 'Anything else',
            slug: 'anything-else',
            component: flowState => (
              <AnythingElse
                transactionType={flowState.transactionType}
                anythingElse={flowState.anythingElse}
              />
            ),
          },
          {
            name: 'Terms and conditions',
            slug: 'terms-and-conditions',
            component: flowState => (
              <TermsAndConditions
                transactionType={flowState.transactionType}
                clientIsCompany={flowState.clientIsCompany}
                termsAndConditions={flowState.termsAndConditions}
              />
            ),
          },
          {
            name: 'Submit',
            slug: 'submit',
            component: flowState => (
              <SignupSubmission
                transactionType={flowState.transactionType}
                signupFlowState={flowState}
                onFlowComplete={() => setFlowCompleted(true)}
              />
            )
          },
        ]}
      />
    </PageLayout>
  );
}

export default SignupFlow;
