import React, { Component } from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';
import CheckList, { CheckListItem } from '../../components/CheckList';
import Action from '../../components/Action';
import List, { ListItem } from '../../components/List';
import ReferralCodeBanner from '../../components/ReferralCodeBanner';
import { formatMoneyWithCurrency, moneyStrToNum } from '../../utils/moneyUtils';
import Image from '../../components/Image';
import imageRelaxingOnSofa from '../../images/relaxing-on-sofa.png';
import {
  ActionWrapper,
  ListItemSuffix,
  QuoteBreakdown,
  QuotedPrice,
  QuotedPriceWrapper
} from "../QuoteCommon/QuoteStyles";


class BrokerQuoteDetailsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      quoteDetails: null,
      loading: true,
      error: null,
    };

    fetch('/api/partialQuote', {
      method: 'PUT',
      body: JSON.stringify({
        channel: this.props.channel,
        campaign: this.props.campaign,
        sellingDetails:
          this.props.transactionType === 'Selling' ||
            this.props.transactionType === 'BuyingAndSelling'
            ? this.props.sellingDetails
            : null,
        buyingDetails:
          this.props.transactionType === 'Buying' ||
            this.props.transactionType === 'BuyingAndSelling'
            ? this.props.buyingDetails
            : null,
        remortgagingDetails:
          this.props.transactionType === 'Remortgaging'
            ? this.props.remortgagingDetails
            : null,
        referralCode: this.props.referral ? this.props.referral.referralCode : null,
        googleAnalyticsId: this.props.googleAnalyticsId,
        agentName: this.props.broker.fullName,
        agentEmailAddress: this.props.broker.emailAddress,
        additionalInformation: this.props.additionalInformation,
      }),
    })
      .then(resp => resp.json())
      .then(
        quoteData => {
          this.setState({
            quoteDetails: quoteData,
            loading: false,
          });
        },
        () => {
          this.setState({
            error: 'Unable to generate your quote',
            loading: false,
          });
        },
      );
  }

  continueImmediately = () => {
    this.props.onStepComplete({
      progress: 'sendInstructLink',
    });
  };

  continueSendClientQuote = () => {
    this.props.onStepComplete({
      progress: 'sendClientQuote',
      quoteDetails: this.state.quoteDetails
    });
  };

  render() {

    if (!!this.state.loading) {
      return null;
    }

    const transactionType = this.props.transactionType;

    let saleLegalFee = '';
    let salePropertyPrice = '';

    let purchaseLegalFee = '';
    let purchasePropertyPrice = '';

    let quotePrice = '';

    let stampDuty = '';
    let stampDutyDescription = 'Stamp Duty';

    let landRegistryFee = '';
    let companiesHouseRegistrationFee = '';
    let totalFeesAndTaxes = '';

    let remortgageLegalFee = '';
    let remortgageAmount = '';
    let remortgageTitleInsured = '';
    let remortgageBuyToLet = ''

    const formatQuotedAmount = (value, showDecimal) => {
      if (!value) return '';
      return formatMoneyWithCurrency(
        moneyStrToNum(value).toString(),
        showDecimal,
      );
    };

    let includeDecimal = landRegistryFee.includes('.') || stampDuty.includes('.');

    const buyingDetails = this.props.buyingDetails;
    const sellingDetails = this.props.sellingDetails;
    const remortgagingDetails = this.props.remortgagingDetails;

    const { quoteDetails } = this.state;
    if (quoteDetails) {
      quotePrice = formatQuotedAmount(quoteDetails.totalPrice, includeDecimal);
      saleLegalFee = formatQuotedAmount(quoteDetails.sellingQuotePrice, includeDecimal);
      purchaseLegalFee = formatQuotedAmount(quoteDetails.buyingQuotePrice, includeDecimal);
      remortgageLegalFee = formatQuotedAmount(quoteDetails.remortgageQuotePrice, includeDecimal);
      stampDuty = formatQuotedAmount(quoteDetails.stampDutyFee, includeDecimal);
      landRegistryFee = formatQuotedAmount(quoteDetails.landRegistryFee, includeDecimal);
      companiesHouseRegistrationFee = formatQuotedAmount(quoteDetails.companiesHouseRegistrationFee, includeDecimal);
      totalFeesAndTaxes = formatQuotedAmount(quoteDetails.totalFeesAndTaxes, includeDecimal);

      if (buyingDetails && (!!buyingDetails.rightToBuyScheme || !!buyingDetails.sharedOwnership)) {
        stampDutyDescription = 'Stamp Duty (based on the full value of the property)';
      }

      purchasePropertyPrice = (transactionType === 'Buying' || transactionType === 'BuyingAndSelling')
        ? formatQuotedAmount(buyingDetails?.price, includeDecimal)
        : '';

      salePropertyPrice = (transactionType === 'Selling' || transactionType === 'BuyingAndSelling')
        ? formatQuotedAmount(sellingDetails?.price, includeDecimal)
        : '';

      remortgageAmount = (transactionType === 'Remortgaging')
        ? formatQuotedAmount(remortgagingDetails?.mortgageAmount, includeDecimal)
        : '';
    }

    function getTenure(details) {
      if (details?.tenure === 'leasehold with share of freehold') {
        return 'share of freehold';
      }
      return details.tenure;
    }

    let sellingTenure = '';
    let buyingTenure = '';
    let remortgageTenure = '';

    if (transactionType === 'Selling' ||transactionType === 'BuyingAndSelling') {
      sellingTenure = getTenure(sellingDetails);
    }

    if (transactionType === 'Buying' || transactionType === 'BuyingAndSelling') {
      buyingTenure = getTenure(buyingDetails);
    }

    if (transactionType === 'Remortgaging') {
      remortgageTenure = getTenure(remortgagingDetails);

      if (!!quoteDetails?.remoUsingTitleInsurance) {
        remortgageTitleInsured = 'title-insured';
      }

      if (remortgagingDetails?.isBuyToLet === true) {
        remortgageBuyToLet = 'buy-to-let';
      }
    }

    const transactionTypes = {
      'Buying': {
        typeOfPurchaseIncTenure: `${buyingTenure} purchase`,
        quotePriceText: `That’s our quote, including VAT, for legal work on a ${buyingTenure} purchase costing ${purchasePropertyPrice}.`
      },
      'BuyingAndSelling': {
        typeOfPurchaseIncTenure: `${buyingTenure} purchase and ${sellingTenure} sale`,
        quotePriceText: `That’s our quote, including VAT, for legal work on a ${buyingTenure} purchase costing ${purchasePropertyPrice} and ${sellingTenure} sale costing ${salePropertyPrice}.`
      },
      'Remortgaging': {
        typeOfPurchaseIncTenure: `${remortgageTenure} remortgage`,
        quotePriceText: `That’s our quote, including VAT, for legal work switching to a ${remortgageAmount} ${remortgageTitleInsured} ${remortgageBuyToLet} mortgage on a ${remortgageTenure} property.`
      }
    };

    const { typeOfPurchaseIncTenure, quotePriceText } = transactionTypes[transactionType] || {};

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={`Conveyancing quote for ${this.props.personalDetails.firstName}`} removeTopPadding>
          <QuotedPriceWrapper>
            <QuotedPrice>{quotePrice}</QuotedPrice>
            <p>{quotePriceText}</p>

            <CheckList>
              <CheckListItem>Simpler, clearer, faster</CheckListItem>
              {(transactionType === 'BuyingAndSelling') && (
                <CheckListItem>No sale, no fee</CheckListItem>
              )}
              {(transactionType === 'BuyingAndSelling' ||
                transactionType === 'Buying') && (
                  <CheckListItem>Second chance guarantee</CheckListItem>
                )}
              {(transactionType === 'Remortgaging') && (
                <CheckListItem>No completion, no fee</CheckListItem>
              )}
              <CheckListItem>No hidden fees</CheckListItem>
            </CheckList>

            <p>
              Our quote includes searches, ID checks and common disbursements.{' '}
              <a href="https://www.juno.legal/find-out-more/whats-included-in-our-fixed-fee/" target="_blank" rel="noreferrer">See what’s included in our fixed fee</a>.
            </p>
          </QuotedPriceWrapper>

          {this.props.referral && (
            <ReferralCodeBanner text={this.props.referral.quoteMessage} withinQuote={true} />
          )}

          {(transactionType === 'Buying' || transactionType === 'BuyingAndSelling') && (
              <QuoteBreakdown>
                <List>
                  {transactionType === 'BuyingAndSelling' && (
                    <>
                      <ListItem divider>
                        Legal work on the {sellingTenure} sale
                        <ListItemSuffix>{saleLegalFee}</ListItemSuffix>
                      </ListItem>

                      <ListItem divider>
                        Legal work on the {buyingTenure} purchase
                        <ListItemSuffix>{purchaseLegalFee}</ListItemSuffix>
                      </ListItem>
                    </>
                  )}

                  {transactionType === 'Buying' && (
                    <ListItem divider>
                      Legal fee on the {buyingTenure} purchase
                      <ListItemSuffix data-testid="purchase-legal-fee">
                        {purchaseLegalFee}
                      </ListItemSuffix>
                    </ListItem>
                  )}

                  <ListItem divider>
                    Land Registry fee
                    <ListItemSuffix data-testid="land-registry-fee">
                      {landRegistryFee}
                    </ListItemSuffix>
                  </ListItem>

                  <ListItem divider>
                    {stampDutyDescription}
                    <ListItemSuffix data-testid="stamp-duty-fee">
                      {stampDuty}
                    </ListItemSuffix>
                  </ListItem>

                  {(remortgagingDetails?.clientIsCompany || buyingDetails?.clientIsCompany) &&
                    <ListItem divider>
                      Companies house registration fee
                      <ListItemSuffix data-testid="companies-house-registration-fee">
                        {companiesHouseRegistrationFee}
                      </ListItemSuffix>
                    </ListItem>
                  }

                  <ListItem divider>
                    Total including government fees and taxes
                    <ListItemSuffix data-testid="total-fees-and-taxes">
                      {totalFeesAndTaxes}
                    </ListItemSuffix>
                  </ListItem>
                </List>
              </QuoteBreakdown>
            )}

          {transactionType === 'Remortgaging' && (
            <QuoteBreakdown>
              <List>

                <ListItem divider>
                  Legal fee for switching the mortgage
                  <ListItemSuffix data-testid="no-search-indemnity-fee">
                    {remortgageLegalFee}
                  </ListItemSuffix>
                </ListItem>

                <ListItem divider>
                  Land Registry fee
                  <ListItemSuffix data-testid="land-registry-fee">
                    {landRegistryFee}
                  </ListItemSuffix>
                </ListItem>

                {remortgagingDetails?.clientIsCompany &&
                  <ListItem divider>
                    Companies house registration fee
                    <ListItemSuffix data-testid="companies-house-registration-fee">
                      {companiesHouseRegistrationFee}
                    </ListItemSuffix>
                  </ListItem>
                }

                <ListItem divider>
                  Total including government fees and taxes
                  <ListItemSuffix data-testid="total-fees-and-taxes">
                    {totalFeesAndTaxes}
                  </ListItemSuffix>
                </ListItem>
              </List>
            </QuoteBreakdown>
          )}

          <ActionWrapper>
            <Action onClick={this.continueSendClientQuote}>
              Send your client a quote
            </Action>
          </ActionWrapper>

        </Card>

        {!!buyingDetails && (
          <Card title="Second chance guarantee" headingLevel="3" removeTopPadding>
            <p>
              If your client’s first purchase falls through – for any reason – they can try again for no extra charge.
              <a href="https://www.juno.legal/find-out-more/our-guarantees/#our-second-chance-guarantee-for-buyers" target="_blank" rel="noreferrer">
                {' '}Here’s more information
              </a>.</p>
          </Card>
        )}

        {!!quoteDetails?.remoUsingTitleInsurance &&
          <Card title="This quote is for a remortgage using title insurance" headingLevel="3" removeTopPadding>
            <p>We’ll do your client's remortgage using a title insurance policy – this is much quicker and more cost-effective than ordering a full set of search reports.</p>
            <p>The cost of this insurance policy is already covered – we don’t charge anything extra.</p>
            <p>If, when we receive your mortgage offer, the lender requires us to order a full set of searches, we’ll charge an extra £300 inc VAT to order and review these.</p>
          </Card>
        }

        {!!remortgagingDetails?.mortgageLender === "Other" &&
          <Card title="This quote is for a remortgage using no-search indemnity insurance" headingLevel="3" removeTopPadding>
            <p>We’ll do your client's remortgage using a no-search indemnity policy – this is much quicker and more cost-effective than ordering a full set of search reports.</p>
            <p>The cost of this insurance policy is already covered – we don’t charge anything extra.</p>
            <p>If the lender requires us to carry out full searches, we will charge an additional £300 (inc. VAT) to order and process these.</p>
          </Card>
        }

        {!!remortgagingDetails?.mortgageLender === "LendInvest" &&
          <Card title="Additional charges" headingLevel="3" removeTopPadding>
            <p>To prepare the new mortgage with LendInvest, we’ll charge the following additional fees:</p>
              <List bullet>
                <ListItem>Legal work on your client's {typeOfPurchaseIncTenure}</ListItem>
                <ListItem>ID checks – £18 per person</ListItem>
                {remortgagingDetails?.clientIsCompany &&
                  <ListItem>Company ID check – £30</ListItem>
                }
                <ListItem>Environmental reports – £29.60</ListItem>
                <ListItem>Law firm bank details check fee – £24</ListItem>
                <ListItem>Bank transfers – £42, two included in our fee</ListItem>
              </List>
          </Card>
        }

        {!!buyingDetails?.mortgageLender === "LendInvest" &&
          <Card title="No hidden fees" headingLevel="3" removeTopPadding>
            <p>To prepare your purchase with LendInvest, we’ll charge the following additional fees:</p>
              <List bullet>
                <ListItem>Legal work on your client's {typeOfPurchaseIncTenure}</ListItem>
                <ListItem>ID checks – £18 per person</ListItem>
                {buyingDetails?.clientIsCompany &&
                  <ListItem>Company ID check – £30</ListItem>
                }
                <ListItem>Searches – £177.40</ListItem>
                <ListItem>Law firm bank details check fee – £24</ListItem>
                <ListItem>Stamp Duty return fee – £114</ListItem>
                <ListItem>Bank transfers – £42, three included in our fee</ListItem>
              </List>
          </Card>
        }

        <Image src={imageRelaxingOnSofa} fullWidth />
      </React.Fragment>
    );
  }
}

export default BrokerQuoteDetailsPage;
