import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useOtherClientSignupRequest from './hooks/useOtherClientSignupRequest';
import Card from '../../components/Card';
import PageLayout from '../../components/PageLayout';
import ScrollToTop from '../../components/utils/ScrollToTop';
import ClientIntro from './ClientIntro';
import ClientName from './ClientName';
import ClientDetails from './ClientDetails';
import ClientAddress from './ClientAddress';
import ClientTermsAndConditions from './ClientTermsAndConditions';
import SignupOtherClientsSubmission from './SignupOtherClientsSubmission';
import Thanks from './Thanks';
import StepFlowNavigate from '../../components/StepFlowNavigate';

const SignupOtherClientsFlow = (props) => {
  const { clientId } = useParams()

  const [flowCompleted, setFlowCompleted] = useState(false);
  const { loading, error, signupRequest, submitted } = useOtherClientSignupRequest(`/api/clientSignup/${clientId}`);

  if (!!loading) {
    return null;
  }

  if (submitted || flowCompleted) {
    return (
      <PageLayout>
        <Thanks />
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <ScrollToTop />
        <Card title="Sorry, that didn't work" removeTopPadding>
          {error}
        </Card>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <StepFlowNavigate
        initialFlowState={{
          transactionType: signupRequest?.transactionType,
          clientId: clientId,
          clientName: {
            client: {
              firstName: signupRequest?.firstName,
              lastName: signupRequest?.lastName,
              hasMiddleNames: null,
              middleNames: '',
              hasPreviousName: null,
              previousName: '',
              firstNameIsGivenName: null,
              givenName: '',
            },
          },
        }}
        steps={[
          {
            name: 'Intro',
            slug: 'intro',
            component: flowState => (
              <ClientIntro transactionType={flowState.transactionType} />
            ),
          },
          {
            name: 'Your name',
            slug: 'your-name',
            component: flowState => (
              <ClientName clientName={flowState.clientName} />
            ),
          },
          {
            name: 'Your details',
            slug: 'your-details',
            component: flowState => (
              <ClientDetails
                clientDetails={flowState.clientDetails}
                transactionType={flowState.transactionType}
              />
            ),
          },
          {
            name: 'Your address',
            slug: 'your-address',
            component: flowState => (
              <ClientAddress clientAddress={flowState.clientAddress} />
            ),
          },
          {
            name: 'Terms and conditions',
            slug: 'terms-and-conditions',
            component: flowState => (
              <ClientTermsAndConditions
                clientTermsAndConditions={flowState.clientTermsAndConditions}
                transactionType={flowState.transactionType}
              />
            ),
          },
          {
            name: 'Submit',
            slug: 'thanks',
            component: flowState => (
              <SignupOtherClientsSubmission
                signupOtherClientsFlowState={flowState}
                onFlowComplete={() => setFlowCompleted(true)} />
            )
          },
        ]}
      />
    </PageLayout>
  );
}

export default SignupOtherClientsFlow;
