import React, { Component } from 'react';
import { connect } from "react-redux";
import DashboardCard, { DashboardHeading } from '../../components/DashboardCard';
import ListEnquiriesWaitingForClient from './ListEnquiriesWaitingForClient';
import ListEnquiriesWaitingForThirdParty from './ListEnquiriesWaitingForThirdParty';
import ListDoable from './ListDoable';
import StyledTodoList from './StyledTodoList'
import OtherClientTodos from './OtherClientTodos';

class TodoListComponent extends Component {
  render() {
    const selectedCase = this.props.selectedCase;

    const availableDoables = selectedCase.doables.filter(d =>
      d.status === 'available' && d.doable_type !== 'enquiry'
    );

    if (
      availableDoables.length === 0 &&
      selectedCase.enquiries_waiting_for_client === 0 &&
      selectedCase.enquiries_waiting_for_third_party === 0
    ) {
      return null;
    }

    const clientAppForms = availableDoables.filter(d =>
      d.doable_type !== 'information_request' &&
      d.doable_type !== 'emailed_request' &&
      d.doable_type !== 'document_request' &&
      d.doable_type !== 'enquiry'
    );
    const informationRequests = availableDoables.filter(d => d.doable_type === 'information_request');
    const numberOfFormsToFillIn = clientAppForms.length + informationRequests.length;

    const documentRequests = availableDoables.filter(d => d.doable_type === 'document_request');
    const emailedRequests = availableDoables.filter(d => d.doable_type === 'emailed_request');

    return (
      <DashboardCard title="Things we need" size="1">
        <StyledTodoList>
          {selectedCase.enquiries_waiting_for_client > 0 &&
            <>
              <DashboardHeading level="3" id="client-enquiries" invisible={true}>
                Enquiries
              </DashboardHeading>
              <ListEnquiriesWaitingForClient
                numberOfEnquiriesWaitingForClient={selectedCase.enquiries_waiting_for_client}
                propertyAddress={selectedCase.address}
              />
            </>
          }
          {selectedCase.enquiries_waiting_for_third_party > 0 &&
            <>
              <DashboardHeading level="3" id="third-party-enquiries" invisible={true}>
                Third party enquiries
              </DashboardHeading>
              <ListEnquiriesWaitingForThirdParty
                numberOfEnquiriesWaitingForClient={selectedCase.enquiries_waiting_for_client}
                numberOfEnquiriesWaitingForThirdParty={selectedCase.enquiries_waiting_for_third_party}
              />
            </>
          }
          {numberOfFormsToFillIn > 0 &&
            <>
              <DashboardHeading id="fill-in-forms" level="3" invisible={true}>
                Fill in {numberOfFormsToFillIn > 1 ? 'these forms' : 'this form'}
              </DashboardHeading>
              {clientAppForms.length > 0 &&
                <ListDoable
                  items={clientAppForms}
                  clickable={this.props.canCompleteDoables} />
              }
              {informationRequests.length > 0 &&
                <ListDoable items={informationRequests} />
              }
            </>
          }
          {documentRequests.length > 0 &&
            <>
              <DashboardHeading id="upload-documents" level="3" invisible={true}>
                Upload {documentRequests.length > 1 ? 'these documents' : 'a document'}
              </DashboardHeading>
              <ListDoable items={documentRequests} />
            </>
          }
          {emailedRequests.length > 0 &&
            <>
              <DashboardHeading level="3" id="other-things-we-need" invisible={true}>
                Things we've emailed you to ask about
              </DashboardHeading>
              <ListDoable items={emailedRequests} />
            </>
          }
        </StyledTodoList>

        {selectedCase.other_clients?.length > 0 && (
          <OtherClientTodos otherClients={selectedCase.other_clients} />
        )}

      </DashboardCard>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    canCompleteDoables: state.dashboardState.session.session_type === 'client',
  }
};

const TodoList = connect(
  mapStateToProps
)(TodoListComponent);

export default TodoList;
