import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

function Panel({ small, children }) {
  return (
    <StyledPanel small={small}>
      {children}
    </StyledPanel>
  );
}

export default Panel;

const StyledPanel = styled.div`
  width: 100%;
  max-width: 60rem;

  padding: 4rem;
  ${props => props.small && 'padding: 0.5rem;'};
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;

  margin-bottom: 1.5rem;

  ${mediaQueries.lg} {
    margin-left: auto;
    margin-right: auto;
  }

  border-radius: 8px;
  background: linear-gradient(149deg, rgba(195,255,228,1) 0%, rgba(118,255,197,1) 100%);


  h2 {
    color: ${props => props.theme.color.junoText};
    font-size: 1.2rem;
    font-weight: ${props => props.theme.fontWeight.regular};
    line-height: 1.2;
    ${props => props.small && 'margin-top: 2rem; margin-bottom: 0.25rem;'};
  }

  p {
    font-size: 1rem;
    font-weight: ${props => props.theme.fontWeight.regular};
    line-height: 1.5;
    color: ${props => props.theme.color.text3};

    a,
    span {
      color: ${props => props.theme.color.purple};
      font-weight: ${props => props.theme.fontWeight.bold};
    }
  }
`;
