import React, { Component } from 'react';
import { updateAnalyticsIdentity } from '../../utils/analyticsUtils';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';

class QuoteSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTransactionType: this.props.transactionType,
      transactionType: this.props.transactionType,
      buyingDetails: this.props.buyingDetails,
      sellingDetails: this.props.sellingDetails,
      remortgagingDetails: this.props.remortgagingDetails,
      channel: this.props.channel,
      campaign: this.props.campaign,
      quoteDetails: {},
      loading: true,
      error: null,
    };

    let channel = this.props.defaultChannel;
    let referrer = this.props.defaultReferrer;
    if (this.props.referral && this.props.referral.referralSource) {
      channel = this.props.referral.referralSource;
      referrer = this.props.referral.referralCode;
    }

    fetch('/api/quote', {
      method: 'PUT',
      body: JSON.stringify({
        transactionType: this.props.transactionType,
        channel: channel,
        referrer: referrer,
        campaign: this.props.campaign,
        personalDetails: this.props.personalDetails,
        sellingDetails:
          this.props.transactionType === 'Selling' ||
            this.props.transactionType === 'BuyingAndSelling'
            ? this.props.sellingDetails
            : null,
        buyingDetails:
          this.props.transactionType === 'Buying' ||
            this.props.transactionType === 'BuyingAndSelling'
            ? this.props.buyingDetails
            : null,
        remortgagingDetails:
          this.props.transactionType === 'Remortgaging'
            ? this.props.remortgagingDetails
            : null,
        referralCode: this.props.referral ? this.props.referral.referralCode : null,
        googleAnalyticsId: this.props.googleAnalyticsId,
      }),
    })
      .then(resp => resp.json())
      .then(
        quoteData => {
          this.setState({
            quoteDetails: quoteData,
            loading: false,
            submitted: true,
          });

          if (this.props.personalDetails?.emailAddress){
            updateAnalyticsIdentity(this.props.personalDetails.emailAddress.trim(), 'lead')
          }

          props.onFlowComplete({
            quoteDetails: quoteData,
          });
        },
        () => {
          this.setState({
            error: 'Unable to generate your quote',
            loading: false,
          });
        },
      );
  }

  render() {

    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn't work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    if (this.state.submitted) {
      return null;
    }

  }
}

export default QuoteSubmission;
