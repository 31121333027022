import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import { TextareaQuestion } from '../../components/TextQuestion';
import { YES_NO_OPTIONS } from '../../constants';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class RemoPropertyInformation1 extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      rejectHasTenants: 'Please choose an option',
      rejectSharedOwnership: 'Please choose an option',
      rejectMultipleTitles: 'Please choose an option',
      isTransferOfEquity: 'Please choose an option',
      hasHadDisputesWithNeighbours: 'Please choose an option',
      disputesWithNeighboursInfo: 'Please fill this in',
      hasHadPaymentDemandsForChurchRepairs: 'Please choose an option',
      churchRepairsInfo: 'Please fill this in',
    };

    if (this.props.propertyInformation1) {
      const details = this.props.propertyInformation1;
      this.state = {
        isBuyToLet: details.isBuyToLet,
        rejectHasTenants: details.rejectHasTenants,
        rejectSharedOwnership: details.rejectSharedOwnership,
        isTransferOfEquity: details.isTransferOfEquity,
        rejectMultipleTitles: details.rejectMultipleTitles,
        hasHadDisputesWithNeighbours: details.hasHadDisputesWithNeighbours,
        disputesWithNeighboursInfo: details.disputesWithNeighboursInfo,
        hasHadPaymentDemandsForChurchRepairs:
          details.hasHadPaymentDemandsForChurchRepairs,
        churchRepairsInfo: details.churchRepairsInfo,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        isBuyToLet: this.props.isBuyToLet ? this.props.isBuyToLet : null,
        rejectHasTenants: null,
        rejectSharedOwnership: null,
        rejectMultipleTitles: null,
        isTransferOfEquity: null,
        hasHadDisputesWithNeighbours: null,
        disputesWithNeighboursInfo: '',
        hasHadPaymentDemandsForChurchRepairs: null,
        churchRepairsInfo: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let formIsValid = true;

    if (fields['isBuyToLet'] === null) {
      errors['isBuyToLet'] = true;
      formIsValid = false;
    }

    if (fields['rejectHasTenants'] === null) {
      errors['rejectHasTenants'] = true;
      formIsValid = false;
    }

    if (fields['rejectSharedOwnership'] === null) {
      errors['rejectSharedOwnership'] = true;
      formIsValid = false;
    }

    if (fields['isTransferOfEquity'] === null) {
      errors['isTransferOfEquity'] = true;
      formIsValid = false;
    }

    if (fields['rejectMultipleTitles'] === null) {
      errors['rejectMultipleTitles'] = true;
      formIsValid = false;
    }

    if (fields['hasHadDisputesWithNeighbours'] === null) {
      errors['hasHadDisputesWithNeighbours'] = true;
      formIsValid = false;
    }

    if (fields['hasHadDisputesWithNeighbours'] === true) {
      if (!fields['disputesWithNeighboursInfo']) {
        errors['disputesWithNeighboursInfo'] = true;
        formIsValid = false;
      }
    }

    if (fields['hasHadPaymentDemandsForChurchRepairs'] === null) {
      errors['hasHadPaymentDemandsForChurchRepairs'] = true;
      formIsValid = false;
    }

    if (fields['hasHadPaymentDemandsForChurchRepairs'] === true) {
      if (!fields['churchRepairsInfo']) {
        errors['churchRepairsInfo'] = true;
        formIsValid = false;
      }
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  savePropertyInformation1 = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        propertyInformation1: {
          isBuyToLet: this.state.isBuyToLet,
          rejectHasTenants: this.state.rejectHasTenants,
          rejectSharedOwnership: this.state.rejectSharedOwnership,
          rejectMultipleTitles: this.state.rejectMultipleTitles,
          isTransferOfEquity: this.state.isTransferOfEquity,
          hasHadDisputesWithNeighbours: this.state.hasHadDisputesWithNeighbours,
          disputesWithNeighboursInfo: this.state.disputesWithNeighboursInfo,
          hasHadPaymentDemandsForChurchRepairs: this.state
            .hasHadPaymentDemandsForChurchRepairs,
          churchRepairsInfo: this.state.churchRepairsInfo,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={this.props.clientIsCompany ? "Tell us about the property your company is remortgaging" : "Tell us about the property you’re remortgaging"} removeTopPadding>

          <RadioQuestion
            question={this.props.clientIsCompany ? `Is the company remortgaging the property as a buy-to-let?` : `Is this a buy-to-let remortgage?`}
            options={YES_NO_OPTIONS}
            inline={true}
            name="isBuyToLet"
            selected={this.state.isBuyToLet}
            optionSelected={this.handleInputChange('isBuyToLet')}
            validate={this.handleFieldValidation('isBuyToLet')}
            validationError={
              this.state.errors['isBuyToLet']
                ? 'Please choose an option'
                : null
            }
          />

          <RadioQuestion
            question={this.props.clientIsCompany ? `Will there be tenants in the property on the day your company receives the money from the new mortgage?` : `Will there be tenants in the property on the day you receive the money from the new mortgage?`}
            options={YES_NO_OPTIONS}
            inline={true}
            name="rejectHasTenants"
            selected={this.state.rejectHasTenants}
            optionSelected={this.handleInputChange('rejectHasTenants')}
            validate={this.handleFieldValidation('rejectHasTenants')}
            validationError={
              this.state.errors['rejectHasTenants']
                ? this.state.errorMessages['rejectHasTenants']
                : null
            }
          />

          <RadioQuestion
            question={this.props.clientIsCompany ? "Does your company own a share of the property as part of a Shared Ownership scheme?" : "Do you own a share of the property as part of a Shared Ownership scheme?"}
            hint="For example, with housing association or property developer"
            options={YES_NO_OPTIONS}
            inline={true}
            name="rejectSharedOwnership"
            selected={this.state.rejectSharedOwnership}
            optionSelected={this.handleInputChange('rejectSharedOwnership')}
            validate={this.handleFieldValidation('rejectSharedOwnership')}
            validationError={
              this.state.errors['rejectSharedOwnership']
                ? this.state.errorMessages['rejectSharedOwnership']
                : null
            }
          />

          <RadioQuestion
            question={this.props.clientIsCompany ? "Has your company bought additional land that it wants included in the mortgage?" : `Have you bought additional land that you want included in the mortgage?`}
            hint="For example, a garage, garden or parking space"
            options={YES_NO_OPTIONS}
            inline={true}
            name="rejectMultipleTitles"
            selected={this.state.rejectMultipleTitles}
            optionSelected={this.handleInputChange('rejectMultipleTitles')}
            validate={this.handleFieldValidation('rejectMultipleTitles')}
            validationError={
              this.state.errors['rejectMultipleTitles']
                ? this.state.errorMessages['rejectMultipleTitles']
                : null
            }
          />

          <RadioQuestion
            question={this.props.clientIsCompany ? "Will you need to add or remove someone as a legal owner of the property?" : "Do you want to add or remove someone as a legal owner of the property?"}
            hint="For example, as part of matrimonial proceedings, or because someone has passed away?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="isTransferOfEquity"
            selected={this.state.isTransferOfEquity}
            optionSelected={this.handleInputChange('isTransferOfEquity')}
            validate={this.handleFieldValidation('isTransferOfEquity')}
            validationError={
              this.state.errors['isTransferOfEquity']
                ? this.state.errorMessages['isTransferOfEquity']
                : null
            }
          />

          <RadioQuestion
            question={this.props.clientIsCompany ? "Have there been any disputes since your company bought the property?" : "Have there been any disputes since you bought the property?"}
            hint={this.props.clientIsCompany ? "For example, a dispute with a neighbour, with the local council, or with a tenant" : "For example, a dispute with your neighbour, with the local council, or with a tenant"}
            options={YES_NO_OPTIONS}
            inline={true}
            name="hasHadDisputesWithNeighbours"
            selected={this.state.hasHadDisputesWithNeighbours}
            optionSelected={this.handleInputChange(
              'hasHadDisputesWithNeighbours',
            )}
            validate={this.handleFieldValidation(
              'hasHadDisputesWithNeighbours',
            )}
            validationError={
              this.state.errors['hasHadDisputesWithNeighbours']
                ? this.state.errorMessages['hasHadDisputesWithNeighbours']
                : null
            }
          />

          {this.state.hasHadDisputesWithNeighbours === true && (
            <RadioConditional data-testid="disputes-with-neighbours">
              <TextareaQuestion
                question="Please give details"
                value={this.state.disputesWithNeighboursInfo}
                valueEntered={this.handleInputChange(
                  'disputesWithNeighboursInfo',
                )}
                validate={this.handleFieldValidation(
                  'disputesWithNeighboursInfo',
                )}
                validationError={
                  this.state.errors['disputesWithNeighboursInfo']
                    ? this.state.errorMessages['disputesWithNeighboursInfo']
                    : null
                }
              />
            </RadioConditional>
          )}

          <RadioQuestion
            question={this.props.clientIsCompany ? "Has a local church approached your company to demand payment for repairs?" : "Has a local church approached you to demand payment for repairs?"}
            options={YES_NO_OPTIONS}
            inline={true}
            name="hasHadPaymentDemandsForChurchRepairs"
            selected={this.state.hasHadPaymentDemandsForChurchRepairs}
            optionSelected={this.handleInputChange(
              'hasHadPaymentDemandsForChurchRepairs',
            )}
            validate={this.handleFieldValidation(
              'hasHadPaymentDemandsForChurchRepairs',
            )}
            validationError={
              this.state.errors['hasHadPaymentDemandsForChurchRepairs']
                ? this.state.errorMessages[
                'hasHadPaymentDemandsForChurchRepairs'
                ]
                : null
            }
          />

          {this.state.hasHadPaymentDemandsForChurchRepairs === true && (
            <RadioConditional data-testid="church-repairs">
              <TextareaQuestion
                name="churchRepairsInfo"
                question="Please give details"
                value={this.state.churchRepairsInfo}
                valueEntered={this.handleInputChange('churchRepairsInfo')}
                validate={this.handleFieldValidation('churchRepairsInfo')}
                validationError={
                  this.state.errors['churchRepairsInfo']
                    ? this.state.errorMessages['churchRepairsInfo']
                    : null
                }
              />
            </RadioConditional>
          )}

          <Action onClick={this.savePropertyInformation1}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default RemoPropertyInformation1;
