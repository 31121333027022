import React from 'react';
import { Link } from 'react-router-dom';
import Action from '../../components/Action';
import Card from '../../components/Card';
import Image from '../../components/Image';
import ScrollToTop from '../../components/utils/ScrollToTop';
import imageOfficeWorking from '../../images/office-working.png';

const TA10Thanks = ({ fromDashboard }) => {
  return (
    <React.Fragment>
      <ScrollToTop />
      <Card title="That’s it, thanks!" removeTopPadding>
        <p>We’ve got everything we need for now — we’ll be in touch soon.</p>
        {fromDashboard && (
          <Action to="/dashboard" as={Link}>
            Take me back to my dashboard
          </Action>
        )}
      </Card>
      <Image src={imageOfficeWorking} fullWidth />
    </React.Fragment>
  );
}

export default TA10Thanks;
