import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

import Grid, { GridItem } from '../components/Grid';
import FullWidthBackground from '../components/FullWidthBackground';

function TrustpilotBanner(props) {
  return (
    <StyledBanner background="grey" pageWidth={props.pageWidth}>

      <Grid>
        <GridItem width="full">

          <StyledTrustPilotHeading>
            <StyledHeadingLink href="https://uk.trustpilot.com/review/www.juno.legal" target="_blank" rel="noopener noreferrer">Rated ‘Excellent’ on Trustpilot
            </StyledHeadingLink>
          </StyledTrustPilotHeading>

          <StyledTrustPilotLede>
            Here are a few of our great reviews…
          </StyledTrustPilotLede>
        </GridItem>
      </Grid>

      <Grid>
        <GridItem>
          <StyledReview>
            <h3>
              <StyledRating>⭐️ ⭐️ ⭐️ ⭐️ ⭐️</StyledRating>
              I am so glad I didn't spend more on traditional conveyancing
            </h3>
            <p>
              <i>
                "I can't recommend them highly enough. The updates are regular by email, the dashboard is thorough, the documentation is so easy to load and access and when you need to speak to them you can get them on the phone and they explain things properly. Unbelievable to me that the service is provided at such a low cost."
              </i>
            </p>
            <p>
                <a href="https://uk.trustpilot.com/reviews/634fff50377e522e3153557e" target="_blank" rel="noopener noreferrer">Alistair on TrustPilot</a>
            </p>
          </StyledReview>
        </GridItem>

        <GridItem>
          <StyledReview>
          <h3>
            <StyledRating>⭐️ ⭐️ ⭐️ ⭐️ ⭐️</StyledRating>
            Heaven for first time buyers
          </h3>
          <p>
            <i>
            "With Juno, it's very digital and your online dashboards are amazing but if you ever need to speak to someone you can always ring, and when you speak to your team it's a very normal conversation with a human and you can tell they care about their clients, you aren't just another case for them."
            </i>
          </p>
          <p>
              <a href="https://uk.trustpilot.com/reviews/6364c7c7b84cc27618e1caff" target="_blank" rel="noopener noreferrer">CK on TrustPilot</a>
          </p>
          </StyledReview>
        </GridItem>

        <GridItem>
          <StyledReview>
            <h3>
              <StyledRating>⭐️ ⭐️ ⭐️ ⭐️ ⭐️</StyledRating>
              Best customer service I have ever received
            </h3>
            <p>
              <i>
              "The conveyancing team knew what they were doing and the rest of the team always answered any question extremely fast.
                If the lines were busy someone would call back sometimes within minutes and we would recieve regular updates without needing to ask! Based on other quotes the price was competitive too!"
              </i>
            </p>
            <p>
                <a href="https://uk.trustpilot.com/reviews/62ff82b4142fce1f45febfa1" target="_blank" rel="noopener noreferrer">Rebecca on Trustpilot</a>
            </p>
          </StyledReview>
        </GridItem>

      </Grid>
    </StyledBanner>
  );
}

const StyledBanner = styled(FullWidthBackground)`
  ${mediaQueries.md} {
    top: 30px;
    margin-bottom: -50px;
  }
`

const StyledTrustPilotHeading = styled.h2`
  font-size: 28px;
  line-height: 36px;
  padding-top: 8px;

  ${mediaQueries.md} {
    font-size: 32px;
    line-height: 44px;
    padding-top: 12px;
  }
`

const StyledTrustPilotLede = styled.p`
  font-size: 18px;
  ${mediaQueries.md} {
    font-size: 20px;
  }
`

const StyledHeadingLink = styled.a`
  color: #0d0d0d !important;
`

const StyledRating = styled.span`
  display: block;
  margin-bottom: 16px;
`

const StyledReview = styled.div`
  h3 {
    font-size: 16px;
    line-height: 1.25;
    margin-top: 0;

    ${mediaQueries.md} {
      font-size: 20px;
    }

    ${mediaQueries.lg} {
      min-height: 120px;
    }
  }

  p {
    font-size: 16px;
    margin-right: 16px;
  }
`

export default TrustpilotBanner;
