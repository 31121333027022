// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-DocumentMultiFrame-controls{-webkit-box-flex:1;-webkit-flex:1;flex:1;margin:2em 1em 3em;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column}.onfido-sdk-ui-DocumentMultiFrame-btn{cursor:pointer;outline-offset:.375em;background-color:var(--color-background-button-camera);font-size:inherit;border-radius:50%;border:.1875em solid rgb(var(--ods-color-neutral-black));-webkit-box-shadow:0 0 0 .25em rgb(var(--ods-color-neutral-white));box-shadow:0 0 0 .25em rgb(var(--ods-color-neutral-white));height:3.5em;margin-left:auto;margin-right:auto;padding:0;width:3.5em}.onfido-sdk-ui-DocumentMultiFrame-btn:hover{background-color:var(--osdk-color-background-button-camera-hover)}.onfido-sdk-ui-DocumentMultiFrame-btn:active{background-color:var(--osdk-color-background-button-camera-active)}.onfido-sdk-ui-DocumentMultiFrame-btn:disabled{display:none}", ""]);
// Exports
exports.locals = {
	"modal_animation_duration": "200ms",
	"controls": "onfido-sdk-ui-DocumentMultiFrame-controls",
	"btn": "onfido-sdk-ui-DocumentMultiFrame-btn"
};
module.exports = exports;
