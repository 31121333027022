import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { createId, createDataTestId } from './utils/helpers';
import { mediaQueries } from '../styles/media.js';
import Question, { Optional } from './Question';
import Fieldset from './Fieldset';
import Legend from './Legend';
import Hint from './Hint';
import Error from './Error';

const Checkbox = styled.div`
  position: relative;
  margin-bottom: 8px;
  padding-left: 2em;
  min-height: 32px;

  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: normal;
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
  }

  input {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 0;
    opacity: 0;
  }

  label {
    display: block;
    padding: 0.25em 0.75em;
    border: 2px solid transparent;
    cursor: pointer;
    touch-action: manipulation;
  }

  label::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2em;
    height: 2em;
    border: 2px solid #a7b1be;
    background: transparent;
  }

  label::after {
    content: '';
    width: 1em;
    height: 0.55em;
    position: absolute;
    top: 0.6em;
    left: 0.5em;
    transform: rotate(-45deg);
    border: solid;
    border-width: 0 0 5px 5px;
    border-color: #a7b1be;
    border-top-color: transparent;
    opacity: 0;
    background: transparent;
  }
  input:focus + label::before {
    box-shadow: 0 0 0 3px #f6ce55;
  }

  input:checked + label::after {
    opacity: 1;
  }

  input:disabled,
  input:disabled + label {
    cursor: default;
  }

  input:disabled + label {
    opacity: 0.5;
  }

  input:disabled:checked + label:after,
  input:disabled:checked + label:before {
    display: none;
  }

`;

class CheckboxQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedItems: this.props.selected
        ? new Map(this.props.selected)
        : new Map(),
    };
  }

  handleChange = event => {
    const item = event.target.name;
    const isChecked = event.target.checked;
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));
  };

  render() {
    return (
      <Question>
        <Fieldset>
          <Legend>
            {this.props.question} {this.props.optional ? <Optional /> : null}
            {this.props.hint ? <Hint>{this.props.hint}</Hint> : null}
            {this.props.validationError ? (
              <Error>{this.props.validationError}</Error>
            ) : null}
          </Legend>
          {this.props.options.map((option, i) => {
            const componentId = createId('input', `${option.title}`);

            const dataTestId = createDataTestId('checkbox', `${option.title}`);

            return (
              <Checkbox option={option} key={i} {...this.props}>
                <input
                  type="checkbox"
                  name={this.props.name ? this.props.name : option.name}
                  title={option.title}
                  checked={
                    !!this.state.checkedItems.get(
                      this.props.name ? this.props.name : option.name,
                    )
                  }
                  onChange={this.handleChange}
                  onClick={this.props.validate}
                  id={componentId}
                  data-testid={dataTestId}
                />
                <label htmlFor={componentId}>{option.title}</label>
              </Checkbox>
            );
          })}
        </Fieldset>
      </Question>
    );
  }
}

export default CheckboxQuestion;
