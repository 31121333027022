import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { isMoneyValid, normaliseMoney } from '../../utils/moneyUtils';
import { getOrdinalString } from '../../utils/numberUtils';
import {isEmailValid} from "../../utils/textUtils";

class GiftingMoneyForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.initStateFromProps(props);
  }

  initStateFromProps(props) {
    const errorMessages = {
      giftingMoneyFullName: 'Please fill this in',
      giftingMoneyAmount: 'Please fill this in',
      giftingMoneyEmailAddress: 'Please fill this in',
      giftingMoneyJointGift: 'Please choose an option',
      giftingMoneyJointName: 'Please fill this in',
      giftingMoneyJointEmailAddress: 'Please fill this in',
    };

    if (props.giftingMoney) {
      const gift = { ...props.giftingMoney };
      return {
        giftingMoneyFullName: gift.giftingMoneyFullName,
        giftingMoneyAmount: gift.giftingMoneyAmount,
        giftingMoneyEmailAddress: gift.giftingMoneyEmailAddress,
        giftingMoneyJointGift: gift.giftingMoneyJointGift,
        giftingMoneyJointName: gift.giftingMoneyJointName,
        giftingMoneyJointEmailAddress: gift.giftingMoneyJointEmailAddress,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      return {
        giftingMoneyFullName: '',
        giftingMoneyAmount: '',
        giftingMoneyEmailAddress: '',
        giftingMoneyJointGift: null,
        giftingMoneyJointName: '',
        giftingMoneyJointEmailAddress: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.giftingMoney !== prevProps.giftingMoney ||
      this.props.giftNumber !== prevProps.giftNumber
    ) {
      this.setState(this.initStateFromProps(this.props));
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    let formIsValid = true;

    if (!fields['giftingMoneyFullName']) {
      errors['giftingMoneyFullName'] = true;
      formIsValid = false;
    }

    if (!fields['giftingMoneyAmount']) {
      errors['giftingMoneyAmount'] = true;
      formIsValid = false;
    }

    if (
      fields['giftingMoneyAmount'] !== '' &&
      !isMoneyValid(fields['giftingMoneyAmount'])
    ) {
      errors['giftingMoneyAmount'] = true;
      errorMessages['giftingMoneyAmount'] = 'Please enter a valid amount';
      formIsValid = false;
    }

    if (!fields['giftingMoneyEmailAddress']) {
      errors['giftingMoneyEmailAddress'] = true;
      formIsValid = false;
    }

    if (
      fields['giftingMoneyEmailAddress'] !== '' &&
      !isEmailValid(fields['giftingMoneyEmailAddress'])
    ) {
      errors['giftingMoneyEmailAddress'] = true;
      errorMessages['giftingMoneyEmailAddress'] =
        'Please enter a valid email address';
      formIsValid = false;
    }

    if (fields['giftingMoneyJointGift'] === null) {
      errors['giftingMoneyJointGift'] = true;
      formIsValid = false;
    }

    if (fields['giftingMoneyJointGift'] === true) {
      if (!fields['giftingMoneyJointName']) {
        errors['giftingMoneyJointName'] = true;
        formIsValid = false;
      }
      if (!fields['giftingMoneyJointEmailAddress']) {
        errors['giftingMoneyJointEmailAddress'] = true;
        formIsValid = false;
      }
      if (
        fields['giftingMoneyJointEmailAddress'] !== '' &&
        !isEmailValid(fields['giftingMoneyJointEmailAddress'])
      ) {
        errors['giftingMoneyJointEmailAddress'] = true;
        errorMessages['giftingMonegiftingMoneyJointEmailAddressyEmailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleRadioValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (this.state[field] === null) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleEmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isEmailValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid email address';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleMoneyValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isMoneyValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid amount';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  saveGiftingMoney = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      const outGift = {};
      outGift['giftingMoney' + this.props.giftNumber] = {
        giftingMoneyFullName: this.state.giftingMoneyFullName,
        giftingMoneyAmount: normaliseMoney(this.state.giftingMoneyAmount),
        giftingMoneyEmailAddress: this.state.giftingMoneyEmailAddress,
        giftingMoneyJointGift: this.state.giftingMoneyJointGift,
        giftingMoneyJointName: this.state.giftingMoneyJointName,
        giftingMoneyJointEmailAddress: this.state.giftingMoneyJointEmailAddress,
      };

      this.props.onStepComplete({
        ...outGift,
      });
      return;
    }
  };

  render() {
    const ordinalString = getOrdinalString(this.props.giftNumber);

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card
          title={
            'Tell us about the ' + ordinalString + ' gift of money'
          }
          removeTopPadding
        >
          <p>
            We need to know how much money you are receiving from someone who is still alive - as a gift towards your purchase.
          </p>
          <p>
            Tell us the name and email address of anyone giving you money - so we can contact them to verify their identify and complete our anti-money laundering checks.
          </p>
          <TextQuestion
            question={
              "What is the name of the " +
              ordinalString +
              ' person giving you money?'
            }
            value={this.state.giftingMoneyFullName}
            valueEntered={this.handleInputChange('giftingMoneyFullName')}
            validate={this.handleFieldValidation('giftingMoneyFullName')}
            validationError={
              this.state.errors.giftingMoneyFullName
                ? this.state.errorMessages.giftingMoneyFullName
                : null
            }
          />

          <TextQuestion
            question="How much will they be giving you?"
            value={this.state.giftingMoneyAmount}
            valueEntered={this.handleInputChange('giftingMoneyAmount')}
            validate={this.handleMoneyValidation('giftingMoneyAmount')}
            validationError={
              this.state.errors.giftingMoneyAmount
                ? this.state.errorMessages.giftingMoneyAmount
                : null
            }
          />

          <TextQuestion
            question="What’s their email address?"
            value={this.state.giftingMoneyEmailAddress}
            valueEntered={this.handleInputChange('giftingMoneyEmailAddress')}
            validate={this.handleEmailAddressValidation(
              'giftingMoneyEmailAddress',
            )}
            validationError={
              this.state.errors.giftingMoneyEmailAddress
                ? this.state.errorMessages.giftingMoneyEmailAddress
                : null
            }
          />

          <RadioQuestion
            question="Is this a gift from a joint account?"
            hint="Are there multiple people contributing to this gift?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="giftingMoneyJointGift"
            selected={this.state.giftingMoneyJointGift}
            optionSelected={this.handleInputChange(
              'giftingMoneyJointGift',
            )}
            validate={this.handleRadioValidation('giftingMoneyJointGift')}
            validationError={
              this.state.errors.giftingMoneyJointGift
                ? this.state.errorMessages.giftingMoneyJointGift
                : null
            }
          />

          {this.state.giftingMoneyJointGift === true && (
            <RadioConditional>
              <TextQuestion
                question="Enter the joint account holder's name"
                value={this.state.giftingMoneyJointName}
                valueEntered={this.handleInputChange('giftingMoneyJointName')}
                validate={this.handleFieldValidation('giftingMoneyJointName')}
                validationError={
                  this.state.errors.giftingMoneyJointName
                    ? this.state.errorMessages.giftingMoneyJointName
                    : null
                }
              />

              <TextQuestion
                question="What is the joint account holder's email address?"
                hint="This needs to be a different email to the one above"
                value={this.state.giftingMoneyJointEmailAddress}
                valueEntered={this.handleInputChange('giftingMoneyJointEmailAddress')}
                validate={this.handleEmailAddressValidation(
                  'giftingMoneyJointEmailAddress',
                )}
                validationError={
                  this.state.errors.giftingMoneyJointEmailAddress
                    ? this.state.errorMessages.giftingMoneyJointEmailAddress
                    : null
                }
              />
            </RadioConditional>
          )}

          <Action onClick={this.saveGiftingMoney}>Continue</Action>
        </Card>

        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default GiftingMoneyForm;
