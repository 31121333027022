import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media';
import FullWidthBackground from '../../components/FullWidthBackground';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import TrustpilotBanner from '../../components/TrustpilotBanner';
import QuoteLandingPageCopy from './QuoteLandingPageCopy';
import ReferralCodeBanner from '../../components/ReferralCodeBanner';

const transactionTypeOptions = [
  { title: 'Buying a property', value: 'Buying' },
  { title: 'Selling a property', value: 'Selling' },
  { title: 'Buying and selling at the same time', value: 'BuyingAndSelling' },
  //{ title: 'Remortgaging a buy-to-let property', value: 'Remortgaging' }
];

class TransactionTypePicker extends Component {
  constructor(props) {
    super(props);

    if (this.props.transactionType) {
      this.state = {
        selectedTransactionType: this.props.transactionType,
        errors: {},
      };
    } else {
      this.state = {
        selectedTransactionType: null,
        errors: {},
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let formIsValid = true;

    if (fields['selectedTransactionType'] === null) {
      errors['selectedTransactionType'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  }

  saveTransactionType = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        transactionType: this.state.selectedTransactionType,
        personalDetails: null,
        sellingDetails: null,
        buyingDetails: null,
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />

        <QuoteLandingPageCopy pageWidth={this.props.pageWidth} campaign={this.props.campaign} />

        {!!this.props.referral &&
          <ReferralCodeBanner pageWidth={this.props.pageWidth} text={this.props.referral.discountMessage} />
        }

        <StyledFullWidthBackground pageWidth={this.props.pageWidth} background="white">

          <StyledHeading>
            Are you:
          </StyledHeading>

          <RadioQuestion
            question="Are you:"
            hideLegend={true}
            options={transactionTypeOptions}
            name="quoteType"
            selected={this.state.selectedTransactionType}
            optionSelected={this.handleInputChange('selectedTransactionType')}
            validationError={
              this.state.errors.selectedTransactionType
                ? 'Please choose an option'
                : null
            }
          />

          <Action onClick={this.saveTransactionType}>Continue</Action>
        </StyledFullWidthBackground>
        <TrustpilotBanner pageWidth={this.props.pageWidth} />
      </React.Fragment>
    );
  }
}

export default TransactionTypePicker;

const StyledHeading = styled.h2`
  ${mediaQueries.md} {
    margin-top: 30px;
  }
`

const StyledFullWidthBackground = styled(FullWidthBackground)`
  ${mediaQueries.md} {
    top: -30px;
    margin-bottom: -60px;
  }
`
