import { dashboardLoaded, userNotLoggedIn } from "../../../actions/dashboardActions";
import { updateAnalyticsIdentity } from "../../../utils/analyticsUtils";
import { enableFrontChatForClient } from "../../../utils/frontChatUtils";

function redirectToLogin(dispatch) {
  dispatch(userNotLoggedIn())
}

export function loadDashboardData(dispatch) {
  fetch('/api/dashboard', {method: 'GET', credentials: 'same-origin'})
    .then(resp => resp.json())
    .then(resp => {
      if (resp.error) {
        redirectToLogin()
      } else {
        if (resp.session.session_type === 'client') {
          updateAnalyticsIdentity(resp.session.email_address, resp.session.session_type)
          enableFrontChatForClient(resp.session)
        }

        if (resp.session.session_type === 'broker') {
          updateAnalyticsIdentity(resp.session.email_address, resp.session.session_type)
        }

        dispatch(dashboardLoaded(resp))
      }
    })
    .catch(() => {
      redirectToLogin(dispatch)
    });
}
