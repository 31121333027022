import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import StepFlow from '../../components/StepFlow';
import IdentityCheckIntro from "./IdentityCheckIntro";
import PerformOnfidoIdentityCheck from "./PerformOnfidoIdentityCheck";
import IdentityCheckThanks from "./IdentityCheckThanks";
import IdentityCheckPassFirstTime from "./IdentityCheckPassFirstTime";

const IdentityCheckFlow = (props) => {

  const location = useLocation()
  const { fromDashboard } = location.state || {};
  const { requestId } = useParams()

  const [state, setState] = useState({
    loading: true,
    error: null,
    requestDetails: null,
  });

  useEffect(() => {
    fetch('/api/identityCheck/' + requestId)
      .then(resp => resp.json())
      .then(
        data => {
          if (!!data.isValid) {
            setState({
              loading: false,
              requestDetails: data.config,
            });
          } else if (data.error === 'expired') {
            setState({
              loading: false,
              error: 'This request has expired',
            });
          } else if (data.error === 'submitted') {
            setState({
              loading: false,
              error: "It looks like you've already submitted this data",
            });
          } else {
            setState({
              loading: false,
              error: 'Failed to load request details',
            });
          }
        },
        () => {
          setState({
            loading: false,
            error: 'Failed to load request details',
          });
        },
      );
  }, [requestId]);


    if (state.loading) {
      return null;
    }

    if (state.error) {
      return (
        <PageLayout>
          <ScrollToTop />
          <Card title="Sorry, that didn't work" removeTopPadding>
            {state.error}
          </Card>
        </PageLayout>
      );
    }

    return (
      <PageLayout>
      <StepFlow
        initialFlowState={{
          formRequestId: requestId,
          fromDashboard: fromDashboard,
        }}
        steps={[
          {
            name: 'Intro',
            component: flowState => <IdentityCheckIntro />,
          },
          {
            name: 'Pass fist time',
            component: flowState => <IdentityCheckPassFirstTime />,
          },
          {
            name: 'Perform identity check',
            component: flowState => (
              <PerformOnfidoIdentityCheck
                identityRequestId={flowState.formRequestId}
              />
            ),
          },
          {
            name: 'Thanks',
            component: flowState => (
              <IdentityCheckThanks
                identityCheckFlowState={flowState}
                fromDashboard={flowState.fromDashboard}
              />
            ),
            terminal: true,
          },
        ]}
      />
      </PageLayout>
    );
}

export default IdentityCheckFlow;
