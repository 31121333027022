import React from 'react';
import PageLayout from '../components/PageLayout';
import Card from '../components/Card';
import Image from '../components/Image';
import imageOfficeWorking from '../images/office-working.png';
import ScrollToTop from '../components/utils/ScrollToTop';

function PageNotFound() {
  return (
    <PageLayout>
      <ScrollToTop />
      <Card title="We can't find the page you're looking for" removeTopPadding>
        <p>
          <a href="https://www.juno.legal">Take me to www.juno.legal</a>
        </p>
      </Card>
      <Image src={imageOfficeWorking} fullWidth />
    </PageLayout>
  );
}

export default PageNotFound;
