import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media.js';

import { InfoCircle } from '@styled-icons/boxicons-regular';
import { LogInCircle } from '@styled-icons/boxicons-solid';

function TabNav({ caseReference, onShowTab, selectedTab, hasActionRequired }) {

  return (
    <StyledTabNav>
      <Tab
        selected={selectedTab === `${caseReference}_live_update`}
        onClick={() => onShowTab(`${caseReference}_live_update`)}
      >
        <InfoCircle size="24" /> Live update
      </Tab>
      {hasActionRequired && (
        <Tab
          selected={selectedTab === `${caseReference}_action_required`}
          onClick={() => onShowTab(`${caseReference}_action_required`)}
        >
          <LogInCircle size="24" /> Action required
        </Tab>
      )}
    </StyledTabNav>
  );
}

export default TabNav;


TabNav.propTypes = {
  caseReference: PropTypes.string.isRequired,
  onShowTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string,
  hasActionRequired: PropTypes.bool,
};

const StyledTabNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid ${props => props.theme.color.palePurple};
  ${mediaQueries.md} {
    align-items: center;
  }
`;

const Tab = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin-right: 24px;
  ${mediaQueries.md} {
    flex-direction: row;
    font-size: 16px;
    margin-right: 48px;
  }

  font-weight: ${props => props.theme.fontWeight.bold};
  background: white;
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
  border-bottom-color: ${props => (props.selected ? '#483ea6' : 'transparent')};
  color: ${props => props.theme.color.purple};
  transition: color 200ms ease, border-bottom-color 200ms ease;
  margin-top: 0;
  padding: 16px 0 10px 0;

  &:hover {
    color: #483ea6;
    border-bottom-color: ${props => (props.selected ? '#483ea6' : '#D5D1FF')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    svg {
      color: ${props => props.theme.color.purple};
    }
  }

  &:focus {
    border-bottom-color: ${props => (props.selected ? '#483ea6' : '#D5D1FF')};
  }

  svg {
    color: ${props => (props.selected ? '#483ea6' : '#D5D1FF')};
    margin-right: 8px;
    margin-bottom: 8px;
    ${mediaQueries.md} {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
`;

const TabLargerScreenContext = styled.span`
  display: none;
  ${mediaQueries.md} {
    display: inline;
  }
`;
