import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckCircle } from '@styled-icons/material';

import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media';

const JunoConfirmation = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  color: ${props => props.theme.color.junoHeader};
  background-color: ${props => props.theme.color.paleGreen};

  padding-top: 1em;
  padding-bottom: 1em;
  ${mediaQueries.md} {
    padding-top: ${props => props.submission ? '1.5em' : '2em'};
    padding-bottom: ${props => props.submission ? '1.5em' : '2em'};
  }

  margin-bottom: 1em;
  margin-top: ${props => props.submission ? '-1em' : '0'};

  ${mediaQueries.md} {
    margin-top: -2em;
    margin-bottom: 1em;
  }

  h3 {
    margin: ${props => props.submission ? '0' : '0 0 1em 0'};
  }

  p {
    ${mediaQueries.md} {
     font-size: ${props => props.submission ? props.theme.fontSizes.p['sm'] : props.theme.fontSizes.p['md']};
    }
  }

`;

const JunoConfirmationContent = styled.div`

  margin-left: 20px;
  margin-right: 20px;

  ${mediaQueries.md} {
    margin-left: 45px;
    margin-right: 45px;
  }

  ${mediaQueries.lg} {
    width: 930px;
    margin-left: auto;
    margin-right: auto;
  }

  p,
  ul,
  ul li,
  a {
    font-size: ${props => props.theme.fontSizes.p['sm']};
  }

  ul:last-child {
    margin-bottom: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: ${props => props.theme.color.black};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-decoration: underline;
    border: none;
    &:hover {
      border: none;
    }
  }
`;

const JunoConfirmationHeading = styled.h3`
  display: flex;
  align-items: center;

  font-size: ${props => props.theme.fontSizes.p['sm']};
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
  }
`;

const JunoConfirmationIcon = styled(CheckCircle)`
  width: ${props => props.theme.iconSize['xl']};
  margin-right: 10px;
  ${mediaQueries.md} {
    margin-right: 16px;
  }
`;


class Confirmation extends Component {
  render() {
    return (
      <JunoConfirmation {...this.props}>
        <JunoConfirmationContent>
          <JunoConfirmationHeading>
            <JunoConfirmationIcon />
            <span>{this.props.title}</span>
          </JunoConfirmationHeading>
          {this.props.children}
        </JunoConfirmationContent>
      </JunoConfirmation>
    )
  }
}

export default Confirmation;


Confirmation.propTypes = {
  title: PropTypes.string.isRequired,
};
