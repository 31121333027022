import { useState, useEffect } from 'react';

const useOtherClientSignupRequest = (url) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [signupRequest, setSignupRequest] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      try {
        const resp = await fetch(url);
        const data = await resp.json();

        if (!ignore) {
          if (!!data.signupRequestValid) {
            setSignupRequest(data.signupRequest);
          } else if (data.signupRequestError === 'expired') {
            setError('Your signup link has expired.');
          } else if (data.signupRequestError === 'submitted') {
            setError("It looks like you've already signed up.");
            setSubmitted(true);
          } else {
            setError('Failed to load signup details.');
          }
        }
      } catch (err) {
        if (!ignore) {
          setError('Failed to load request details');
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      ignore = true;
    };
  }, [url]);

  return { loading, error, signupRequest, submitted };
};

export default useOtherClientSignupRequest;
