import React, { Component } from 'react';

import { YES_NO_OPTIONS } from '../../constants/index';
import { getReferringBranchConfig } from './utils/ReferrerQuoteUtils';

import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media.js';

import FullWidthBackground from '../../components/FullWidthBackground';

import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';

import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

import LogoBarnfields from '../../images/sequence-logo-barnfields.png';
import LogoWilliamHBrown from '../../images/sequence-logo-william-h-brown.png';
import LogoBrownAndMerry from '../../images/sequence-logo-brown-and-merry.png';
import LogoRogerPlatt from '../../images/sequence-logo-roger-platt.png';
import LogoHurfords from '../../images/logo-hurfords.png';
import LogoKevinHenry from '../../images/logo-kevin-henry.png';
import LogoPattisonLane from '../../images/logo-pattison-lane.png';
import LogoSharmanQuinney from '../../images/logo-sharman-quinney.png';

class ReferrerQuoteSequence extends Component {
  constructor(props) {
    super(props);

    const branch = getReferringBranchConfig(this.props.defaultReferrer);

    this.state = {
      selectedTransactionType: null,
      prepaidSearches: null,
      referral: null,
      referralCode: null,
      branchName: branch.referrer,
      branchLogo: branch.logo,
      branchAltText: branch.alt,
      agentEmailAddress: branch.email,
      errors: {},
    };

  }

  selectTransactionType = t => {
    this.setState({ selectedTransactionType: t });
  };

  selectPrepaidSearches = ps => {
    this.setState({ prepaidSearches: ps });
  };

  handleValidation = () => {
    const fields = { ...this.state };
    let errors = {};
    let formIsValid = true;

    if (fields['selectedTransactionType'] == null) {
      errors['selectedTransactionType'] = true;
      formIsValid = false;
    }

    if (fields['selectedTransactionType'] === 'Buying' || fields['selectedTransactionType'] === 'BuyingAndSelling') {
      if (fields['prepaidSearches'] == null) {
        errors['prepaidSearches'] = true;
        formIsValid = false;
      }
    }

    this.setState({ errors });
    return formIsValid;
  };

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  saveSelection = () => {
    const { selectedTransactionType, prepaidSearches } = this.state;

    if (this.handleValidation()) {
      if (selectedTransactionType === "Selling") {
        this.setState({ referralCode: 'home_conveyancing_seller' }, this.applyReferralCode);
      }
      if (selectedTransactionType === "Buying" || selectedTransactionType === "BuyingAndSelling") {
        if (prepaidSearches === true) {
          this.setState({ referralCode: 'home_conveyancing_buyer_searches_prepaid' }, this.applyReferralCode);
        }
        else {
          this.setState({ referralCode: 'home_conveyancing_buyer' }, this.applyReferralCode);
        }
      }
    }
  }

  applyReferralCode = () => {
    const { referralCode } = this.state;
    if (referralCode) {
      fetch(`/api/referralCode/${referralCode}?audience=referrer`)
        .then(resp => resp.json())
        .then(
          referralData => {
            if (referralData.referralCodeValid) {
              const referral = {
                referralCode: referralCode,
                discountAmount: referralData.discountAmount,
                discountMessage: referralData.discountMessage,
                quoteMessage: referralData.quoteMessage,
                referralSource: referralData.referralSource,
              };
              this.setState({
                loading: false,
                referral: referral,
              }, this.saveAndContinue
              );
            } else {
              this.setState({
                loading: false
              });
            }
          },
          () => {
            this.setState({
              loading: false,
            });
          },
        );
    }
  }

  saveAndContinue = () => {
    const { referral, selectedTransactionType, agentEmailAddress } = this.state;
    if (this.handleValidation()) {
      this.props.onStepComplete({
        transactionType: selectedTransactionType,
        referral: referral,
        referralLocked: true,
        agentEmailAddress: agentEmailAddress,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />

        <StyledHeader pageWidth={this.props.pageWidth}>
          <StyledHeaderText>
            Home Conveyancing <br/>with Juno
          </StyledHeaderText>
          {!!this.state.branchName && (
            <h3>
              {this.state.branchName}
            </h3>
          )}
        </StyledHeader>

        <StyledBranchCard removeTopPadding>
          <StyledBranchLogoWrapper>
            {!!this.state.branchName && this.state.branchLogo === "barnfields" && (
              <StyledBranchLogo src={LogoBarnfields} alt={this.state.branchAltText} />
            )}

            {!!this.state.branchName && this.state.branchLogo === "brown-and-merry" && (
              <StyledBranchLogo src={LogoBrownAndMerry} alt={this.state.branchAltText} />
            )}

            {!!this.state.branchName && this.state.branchLogo === "roger-platt" && (
              <StyledBranchLogo src={LogoRogerPlatt} alt={this.state.branchAltText} />
            )}

            {!!this.state.branchName && this.state.branchLogo === "william-h-brown" && (
              <StyledBranchLogo src={LogoWilliamHBrown} alt={this.state.branchAltText} />
            )}

            {!!this.state.branchName && this.state.branchLogo === "hurfords" && (
              <StyledBranchLogo src={LogoHurfords} alt={this.state.branchAltText} />
            )}

            {!!this.state.branchName && this.state.branchLogo === "kevin-henry" && (
              <StyledBranchLogo src={LogoKevinHenry} alt={this.state.branchAltText} />
            )}

            {!!this.state.branchName && this.state.branchLogo === "pattison-lane" && (
              <StyledBranchLogo src={LogoPattisonLane} alt={this.state.branchAltText} />
            )}

            {!!this.state.branchName && this.state.branchLogo === "sharman-quinney" && (
              <StyledBranchLogo src={LogoSharmanQuinney} alt={this.state.branchAltText} />
            )}

          </StyledBranchLogoWrapper>

          <h2>Refer a client to Juno</h2>

          <RadioQuestion
            question="Is your client:"
            options={this.props.transactionTypeOptions}
            name="quoteType"
            selected={this.state.selectedTransactionType}
            optionSelected={this.selectTransactionType}
            validate={this.handleFieldValidation('selectedTransactionType')}
            validationError={
              this.state.errors['selectedTransactionType']
                ? 'Please choose an option'
                : null
            }
          />

          {(this.state.selectedTransactionType === 'Buying' || this.state.selectedTransactionType === 'BuyingAndSelling') && (
            <RadioQuestion
              question={'Has your client paid for searches upfront?'}
              options={YES_NO_OPTIONS}
              inline={true}
              name="prepaidSearches"
              selected={this.state.prepaidSearches}
              optionSelected={this.selectPrepaidSearches}
              validate={this.handleFieldValidation('prepaidSearches')}
              validationError={
                this.state.errors['prepaidSearches']
                  ? 'Please choose an option'
                  : null
              }
            />
          )}

          <Action onClick={this.saveSelection}>Continue</Action>
        </StyledBranchCard>

        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default ReferrerQuoteSequence;

const StyledHeader = styled(FullWidthBackground)`
  ${mediaQueries.md} {
    top: -30px
  }
`

const StyledHeaderText = styled.h1`
  max-width: 38rem;
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: ${props => props.theme.fontWeight.light};
  margin-top: 1rem;
  margin-bottom: 0.75rem;

  ${mediaQueries.md} {
    font-size:3.25rem;
    line-height: 4rem;
    margin-bottom: 1rem;
  }
`

const StyledBranchCard = styled(Card)`
  position: relative;
  h2 {
    margin-bottom: 1em;
  }
`

const StyledBranchLogoWrapper = styled.div`
  margin-top: -0.5em;
  ${mediaQueries.md} {
    margin-top: 0;

    position: absolute;
    right: 32px;
    top: 34px;
  }
`

const StyledBranchLogo = styled.img`
  object-fit: scale-down;
  height: 40px;
  ${mediaQueries.md} {
    height: 60px;
  }
`
