import React, { Component } from 'react';
import TextQuestion from '../../components/TextQuestion';
import Action from '../../components/Action';
import Card from '../../components/Card';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import ScrollToTop from '../../components/utils/ScrollToTop';
import {isEmailValid} from "../../utils/textUtils";

class BrokerQuoteClientDetails extends Component {
  constructor(props) {
    super(props);
    if (this.props.personalDetails) {
      const details = this.props.personalDetails;
      this.state = {
        firstName: details.firstName,
        lastName: details.lastName,
        emailAddress: details.emailAddress,
        phoneNumber: details.phoneNumber,
        errors: {},
      };
    } else {
      this.state = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        errors: {},
      };
    }
  }

  firstNameEntered = s => {
    this.setState({ firstName: s });
  };

  lastNameEntered = s => {
    this.setState({ lastName: s });
  };

  emailEntered = s => {
    this.setState({ emailAddress: s });
  };

  phoneEntered = s => {
    this.setState({ phoneNumber: s });
  };

  isPhoneNumberValid = phone => {
    const re = /[0-9+() -]+$/;
    return re.test(String(phone));
  };

  handleEmailAddressValidation = event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors.emailAddress = false;
    } else {
      if (
        !this.state.emailAddress ||
        !isEmailValid(this.state.emailAddress)
      ) {
        errors.emailAddress = true;
      }
    }
    this.setState({ errors });
  };

  handlePhoneNumberValidation = event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors.phoneNumber = false;
    } else {
      if (
        !this.state.phoneNumber ||
        !this.isPhoneNumberValid(this.state.phoneNumber)
      ) {
        errors.phoneNumber = true;
      }
    }
    this.setState({ errors });
  };

  handleNameFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (this.state[field] === '') {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  handleValidation = () => {
    const fields = { ...this.state };
    let errors = {};
    let formIsValid = true;

    if (fields['firstName'] === '') {
      errors['firstName'] = true;
      formIsValid = false;
    }

    if (fields['lastName'] === '') {
      errors['lastName'] = true;
      formIsValid = false;
    }

    if (
      fields['emailAddress'] === '' ||
      !isEmailValid(fields['emailAddress'])
    ) {
      errors['emailAddress'] = true;
      formIsValid = false;
    }

    if (
      fields['phoneNumber'] === '' ||
      !this.isPhoneNumberValid(fields['phoneNumber'])
    ) {
      errors['phoneNumber'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  };

  saveReferrerQuoteClientDetails = () => {
    if (this.handleValidation()) {
      this.props.onStepComplete({
        personalDetails: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          emailAddress: this.state.emailAddress,
          phoneNumber: this.state.phoneNumber,
        },
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us your client’s details" removeTopPadding>
          <TextQuestion
            question="First name"
            value={this.state.firstName}
            valueEntered={this.firstNameEntered}
            validate={this.handleNameFieldValidation('firstName')}
            validationError={
              this.state.errors['firstName']
                ? 'Please enter their first name'
                : null
            }
          />

          <TextQuestion
            question="Last name"
            value={this.state.lastName}
            valueEntered={this.lastNameEntered}
            validate={this.handleNameFieldValidation('lastName')}
            validationError={
              this.state.errors.lastName ? 'Please enter their last name' : null
            }
          />

          <TextQuestion
            question="Email address"
            value={this.state.emailAddress}
            valueEntered={this.emailEntered}
            validate={this.handleEmailAddressValidation}
            validationError={
              this.state.errors.emailAddress
                ? 'Please enter a valid email address'
                : null
            }
          />

          <TextQuestion
            question="Phone number"
            value={this.state.phoneNumber}
            valueEntered={this.phoneEntered}
            validate={this.handlePhoneNumberValidation}
            validationError={
              this.state.errors.phoneNumber
                ? 'Please enter a valid phone number'
                : null
            }
          />

          <p>
            By pressing continue, you confirm that you have your client’s
            permission to share their details with Juno.
          </p>

          <Action onClick={this.saveReferrerQuoteClientDetails}>
            Continue
          </Action>
        </Card>

        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default BrokerQuoteClientDetails;
