import {
  DASHBOARD_LOADED,
  USER_NOT_LOGGED_IN,
  SET_ACTIVE_CASE,
} from "../actions/dashboardActions";

const initialDashboardState = {
  session: null,
  cases: null,
  lastActiveCaseRef: null,
  lastActiveCaseScreen: null,
};

export function dashboardState(state = initialDashboardState, action) {
  switch (action.type) {
    case DASHBOARD_LOADED:
      return {
        ...state,
        session: action.dashboardData.session,
        cases: action.dashboardData.cases,
      };
    case SET_ACTIVE_CASE: {
      const newState = {
        ...state,
        lastActiveCaseRef: action.caseRef,
      };
      if (!! action.caseScreen) {
        newState.lastActiveCaseScreen = action.caseScreen;
      }
      return newState;
    }
    default:
      return state;
  }
}

const initialLoginState = {
  showLogin: false,
};

export function loginState(state = initialLoginState, action) {
  switch (action.type) {
    case USER_NOT_LOGGED_IN:
      return {
        ...state,
        showLogin: true,
      };
    default:
      return state;
  }
}
