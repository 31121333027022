import React from 'react';
import { useSelector } from "react-redux";
import { useNavigate, Navigate } from 'react-router-dom';
import PageHeader from "../../../components/PageHeader";
import Tile from "../../../components/Tile";
import TileGrid from "../../../layouts/TileGrid.js";

const BrokerCaseList = () => {
  const session = useSelector(state => state.dashboardState.session);
  const cases = useSelector(state => state.dashboardState.cases);
  const navigate = useNavigate();

  if (!cases) {
    return null;
  }

  if (cases.length === 1) {
    return <Navigate to={`/dashboard/${cases[0].case_reference}`} />;
  }

  const navigateToCase = (cse) => {
    navigate(`/dashboard/${cse.case_reference}`);
  };

  return (
    <>
      <PageHeader>
        Hello {session.full_name}<br/>
        You have {cases.length} cases with us
      </PageHeader>
      <TileGrid>
        {cases.map((c) => {
          const clientNames = c.clients.map(cl => cl.last_name)

          let tileTitle;
          if (clientNames.length === 1) {
            tileTitle = clientNames[0]
          } else if (clientNames.length === 2) {
            tileTitle = `${clientNames[0]} & ${clientNames[1]}`
          } else {
            const lastClient = clientNames.pop()
            tileTitle = clientNames.join(', ') + ` & ${lastClient}`
          }

          return (
            <Tile key={c.id} title={tileTitle} caseType={c.case_type} onClick={() => navigateToCase(c)}>
              {c.case_type} {c.address && (c.address)} ({c.case_reference})
            </Tile>
          );
        })}
      </TileGrid>
    </>
  );
};

export default BrokerCaseList
