import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import Heading from '../../components/Heading';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import {isEmailValid} from "../../utils/textUtils";

class RemoContactDetails extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      emailAddress: 'Enter your email address',
      phoneNumber: 'Enter your phone number',
      addressLine1: 'Enter the first line of your address',
      addressTown: 'Enter your town',
      addressPostcode: 'Enter your postcode',
      propertyAddressIsClientAddress: 'Please choose an option',
    };

    if (this.props.contactDetails) {
      const details = this.props.contactDetails;
      this.state = {
        emailAddress: details.emailAddress,
        phoneNumber: details.phoneNumber,
        propertyAddressIsClientAddress: details.propertyAddressIsClientAddress,
        clientAddress: { ...details.clientAddress },
        errors: { ...details.errors },
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        emailAddress: '',
        phoneNumber: '',
        propertyAddressIsClientAddress: null,
        clientAddress: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          addressTown: '',
          addressCounty: '',
          addressPostcode: '',
        },
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleEmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isEmailValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid email address';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleValidation() {
    const fields = { ...this.state };
    const clientAddressFields = { ...this.state.clientAddress };
    let errors = { ...this.state.errors };
    let formIsValid = true;

    if (!fields['emailAddress']) {
      errors['emailAddress'] = true;
      formIsValid = false;
    }

    if (
      fields['emailAddress'] !== '' &&
      !isEmailValid(fields['emailAddress'])
    ) {
      errors['emailAddress'] = true;
      formIsValid = false;
    }

    if (!fields['phoneNumber']) {
      errors['phoneNumber'] = true;
      formIsValid = false;
    }

    if (!clientAddressFields['addressLine1']) {
      errors['addressLine1'] = true;
      formIsValid = false;
    }

    if (!clientAddressFields['addressPostcode']) {
      errors['addressPostcode'] = true;
      formIsValid = false;
    }

    if (!clientAddressFields['addressTown']) {
      errors['addressTown'] = true;
      formIsValid = false;
    }

    if (fields['propertyAddressIsClientAddress'] == null) {
      errors['propertyAddressIsClientAddress'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleNestedFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state.clientAddress[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleAddressChange = field => event => {
    let clientAddress = { ...this.state.clientAddress };
    clientAddress[field] = event;
    this.setState({ clientAddress });
  };

  saveContactDetails = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        contactDetails: {
          emailAddress: this.state.emailAddress,
          phoneNumber: this.state.phoneNumber,
          propertyAddressIsClientAddress: this.state
            .propertyAddressIsClientAddress,
          clientAddress: this.state.clientAddress,
        },
      });
      return;
    }
  };

  render() {

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us your contact details" headingSize="2" removeTopPadding>
          <TextQuestion
            question="What’s your email address?"
            value={this.state.emailAddress || ''}
            valueEntered={this.handleInputChange('emailAddress')}
            validate={this.handleEmailAddressValidation('emailAddress')}
            validationError={
              this.state.errors.emailAddress
                ? this.state.errorMessages.emailAddress
                : null
            }
          />

          <TextQuestion
            question="What’s your phone number?"
            value={this.state.phoneNumber}
            valueEntered={this.handleInputChange('phoneNumber')}
            validate={this.handleFieldValidation('phoneNumber')}
            validationError={
              this.state.errors.phoneNumber
                ? this.state.errorMessages.phoneNumber
                : null
            }
          />

          <Heading level="3" size="3">
            What’s your postal address?
          </Heading>

          { this.props.clientIsCompany && (
            <Heading level="4" size="4">
              We’ll need this for our identity checks
            </Heading>
          )}

          <TextQuestion
            question="Address line 1"
            value={this.state.clientAddress.addressLine1 || ''}
            valueEntered={this.handleAddressChange('addressLine1')}
            validate={this.handleNestedFieldValidation('addressLine1')}
            validationError={
              this.state.errors.addressLine1
                ? this.state.errorMessages.addressLine1
                : null
            }
          />

          <TextQuestion
            question="Address line 2"
            optional={true}
            value={this.state.clientAddress.addressLine2 || ''}
            valueEntered={this.handleAddressChange('addressLine2')}
          />

          <TextQuestion
            question="Address line 3"
            optional={true}
            value={this.state.clientAddress.addressLine3 || ''}
            valueEntered={this.handleAddressChange('addressLine3')}
          />

          <TextQuestion
            question="Town"
            value={this.state.clientAddress.addressTown || ''}
            valueEntered={this.handleAddressChange('addressTown')}
            validationError={
              this.state.errors.addressTown
                ? this.state.errorMessages.addressTown
                : null
            }
          />

          <TextQuestion
            question="County"
            optional={true}
            value={this.state.clientAddress.addressCounty || ''}
            valueEntered={this.handleAddressChange('addressCounty')}
          />

          <TextQuestion
            question="Postcode"
            size="small"
            value={this.state.clientAddress.addressPostcode || ''}
            valueEntered={this.handleAddressChange('addressPostcode')}
            validate={this.handleNestedFieldValidation('addressPostcode')}
            validationError={
              this.state.errors.addressPostcode
                ? this.state.errorMessages.addressPostcode
                : null
            }
          />

          <RadioQuestion
            question={this.props.clientIsCompany ? `Is this the address of the property your company is remortgaging?` : `Is this the address of the property you’re remortgaging?`}
            options={YES_NO_OPTIONS}
            inline={true}
            name="propertyAddressIsClientAddress"
            selected={this.state.propertyAddressIsClientAddress}
            optionSelected={this.handleInputChange(
              'propertyAddressIsClientAddress',
            )}
            validate={this.handleFieldValidation(
              'propertyAddressIsClientAddress',
            )}
            validationError={
              this.state.errors.propertyAddressIsClientAddress
                ? this.state.errorMessages.propertyAddressIsClientAddress
                : null
            }
          />

          <Action onClick={this.saveContactDetails}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default RemoContactDetails;
