import React from 'react';
import StyledCaseUpdateInfo from './StyledCaseUpdateInfo'

function LastWorkedOn(props) {
  const lastWorkedOnDays = props.selectedCase.case_data?.last_worked_on_days;

  if (lastWorkedOnDays == null) {
    return null
  }

  let text = null;
  switch (lastWorkedOnDays) {
    case 0:
      text = "Your case was last worked on today"
      break;
    case 1:
      text = "Your case was last worked on yesterday"
      break;
    default:
      if (lastWorkedOnDays <= 4) {
        text = `Your case was last worked on ${lastWorkedOnDays} days ago`
      }
  }

  return (
    <StyledCaseUpdateInfo>
      {text}
    </StyledCaseUpdateInfo>
  )
}

export default LastWorkedOn;
