// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-Confirm-previewsContainer{width:100%;height:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-flex:1;-webkit-flex:1;flex:1;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}.onfido-sdk-ui-Confirm-previewsContainer.onfido-sdk-ui-Confirm-previewsContainerIsFullScreen{top:0}.onfido-sdk-ui-Confirm-title{-webkit-box-flex:0;-webkit-flex:0 0 auto;flex:0 0 auto}.onfido-sdk-ui-Confirm-message{margin:2em 0 1em}@media(max-width: 479px){.onfido-sdk-ui-Confirm-message{margin:1em 0}}.onfido-sdk-ui-Confirm-actions{padding:0;width:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin-right:-1em}@media(max-width: 479px){.onfido-sdk-ui-Confirm-actions{margin-right:-0.5em}}.onfido-sdk-ui-Confirm-actions.onfido-sdk-ui-Confirm-vertical{-webkit-box-orient:vertical;-webkit-box-direction:reverse;-webkit-flex-direction:column-reverse;flex-direction:column-reverse;-webkit-box-align:center;-webkit-align-items:center;align-items:center}.onfido-sdk-ui-Confirm-singleAction{display:block;margin-bottom:.5em}.onfido-sdk-ui-Confirm-retakeAction{margin-right:1em}@media(max-width: 479px){.onfido-sdk-ui-Confirm-retakeAction{margin-right:.5em}}.onfido-sdk-ui-Confirm-retakeAction.onfido-sdk-ui-Confirm-vertical{margin-right:0;margin-top:.5em;width:19.4285714286em}", ""]);
// Exports
exports.locals = {
	"modal_animation_duration": "200ms",
	"previewsContainer": "onfido-sdk-ui-Confirm-previewsContainer",
	"previewsContainerIsFullScreen": "onfido-sdk-ui-Confirm-previewsContainerIsFullScreen",
	"title": "onfido-sdk-ui-Confirm-title",
	"message": "onfido-sdk-ui-Confirm-message",
	"actions": "onfido-sdk-ui-Confirm-actions",
	"vertical": "onfido-sdk-ui-Confirm-vertical",
	"singleAction": "onfido-sdk-ui-Confirm-singleAction",
	"retakeAction": "onfido-sdk-ui-Confirm-retakeAction"
};
module.exports = exports;
