import PropTypes from 'prop-types';

import styled from 'styled-components/macro';
import { CheckCircle, RadioButtonUnchecked, Timelapse } from '@styled-icons/material';

function StatusUpdateIcon({ status, size }) {
    switch(status) {
        case 'complete':
            return <Complete size={size} />
        case 'started':
            return <Started size={size} />
        case 'not_started':
            return <NotStarted size={size} />
        default:
            console.log(`Status update icon status is ${status}`);
            return null;
    }
}

export default StatusUpdateIcon;

StatusUpdateIcon.propTypes = {
    status: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
};
StatusUpdateIcon.defaultProps = {
    size: "24",
};


const Complete = styled(CheckCircle)`
    flex: none;
    color: ${props => props.theme.statusColor.complete};
`

const Started = styled(Timelapse)`
    flex: none;
    color: ${props => props.theme.statusColor.started};
`

const NotStarted = styled(RadioButtonUnchecked)`
    flex: none;
    color: ${props => props.theme.statusColor.not_started};
`
