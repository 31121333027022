import React, { Component } from 'react';
import Action from '../../components/Action';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { trackRejectionPageView } from '../../utils/analyticsUtils';
import { BUYING_REJECTION_OPTIONS, INSURANCE_LIMIT, SELLING_REJECTION_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

class ReferrerSorryPage extends Component {
  componentDidMount() {
    const transactionType = this.props.transactionType?.toLowerCase() || '';
    const pageName = `referrer_quote_${transactionType}_rejection`;

    trackRejectionPageView("referrer_quote", pageName, this.buildAnalyticsRejectionList());
  }

  shouldShowPriceMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.price >= INSURANCE_LIMIT;
  };

  shouldShowCannotActMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.shouldReject && !this.shouldShowPriceMessage();
  };

  shouldShowCompanyBTLMessage = () => {
    const { transactionDetails } = this.props;
    return !!transactionDetails?.clientIsCompany && !!transactionDetails?.buyToLet;
  };

  shouldShowRightToBuyMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.rightToBuyScheme && !this.shouldShowPriceMessage();
  };

  shouldShowNewBuildMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.newBuild && !this.shouldShowPriceMessage();
  };

  shouldShowDivorceMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.divorceOrSeparation && !this.shouldShowPriceMessage();
  };

  shouldShowRetirementPropertyMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.retirementProperty && !this.shouldShowPriceMessage();
  };

  shouldShowBuyingLeaseholdMessage = () => {
    const { transactionDetails, transactionType } = this.props;
    return (
      (transactionType === 'Buying' || transactionType === 'BuyingAndSelling') &&
      (transactionDetails?.tenure === 'leasehold' || transactionDetails?.tenure === 'leasehold with share of freehold') &&
      !this.shouldShowPriceMessage()
    );
  };

  buildAnalyticsRejectionList = () => {
    let reasons = []
    if (this.shouldShowPriceMessage()){ reasons.push("price_too_high")}
    if (this.shouldShowCannotActMessage()){ reasons.push("cannot_act")}
    if (this.shouldShowRightToBuyMessage()){ reasons.push("right_to_buy")}
    if (this.shouldShowNewBuildMessage()){ reasons.push("new_build")}
    if (this.shouldShowDivorceMessage()) { reasons.push("divorce_or_separation")}
    if (this.shouldShowBuyingLeaseholdMessage()) { reasons.push("buying_leasehold") }

    return reasons.join(", ")
  }

  displayRejectionReason = () => {
    let rejectionReason = [];

    if (this.props.rejectQuote === 'buying') {
      for (let i = 0; i < BUYING_REJECTION_OPTIONS.length; i++) {
        rejectionReason.push(BUYING_REJECTION_OPTIONS[i].value);
      }
    }

    if (this.props.rejectQuote === 'selling') {
      for (let i = 0; i < SELLING_REJECTION_OPTIONS.length; i++) {
        rejectionReason.push(SELLING_REJECTION_OPTIONS[i].value);
      }
    }

    return (rejectionReason = rejectionReason
      .join(', ')
      .replace(/, ([^,]*)$/, ' or $1'));
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Sorry — we can’t take on your case" removeTopPadding>
          {this.shouldShowDivorceMessage() && (
            <p>
              We’re sorry, we’re not able to help with your planned sale.
            </p>
          )}

          {!this.shouldShowDivorceMessage() && (
            <p>
              We’re really sorry, but we can’t take on your case at the moment.
            </p>
          )}

          {this.shouldShowDivorceMessage() && (
            <React.Fragment>
              <p>
                We don’t currently act for separating couples selling a property together, because we often receive conflicting instructions which mean we have to stop acting mid-way through the sale. In our experience it’s better to get a divorce lawyer involved and use their in-house conveyancing specialists for the sale of property, who are better equipped to handle situations like this.
              </p>
              <p>
                We recognise this is a difficult time for everyone involved, and we’re sorry not to be able to help you resolve the situation and move on to the next phase of your life. We wish you all the best for the future.
              </p>
            </React.Fragment>
          )}

          {this.shouldShowPriceMessage() && (
            <React.Fragment>
              <p>
                As a regulated conveyancing firm, we’re insured to work on
                transactions up to a value of £{INSURANCE_LIMIT / 1000000} million. We can’t take on any
                cases that have a higher value – sorry about that.
              </p>
              <p>
                We’re continuing to expand the range of services we offer, so
                please do consider us in the future.
              </p>
            </React.Fragment>
          )}

          {this.shouldShowCannotActMessage() && (
            <React.Fragment>
              <p>
                At the moment, we aren’t taking on any more clients who are{' '}
                {this.props.rejectQuote} {this.displayRejectionReason()}.
              </p>
              <p>
                We’re always expanding the range of transactions we can help
                with, so please do consider us next time round.
              </p>
            </React.Fragment>
          )}

          {this.shouldShowCompanyBTLMessage() && (
            <>
              <p>
                At the moment, we're not representing companies who want to buy
                rental properties.
              </p>
              <p>
                We’re always expanding the range of transactions we can help
                with, so please do consider us next time round.
              </p>
            </>
          )}

          {this.shouldShowRightToBuyMessage() && (
            <React.Fragment>
              <p>
                At the moment, we aren’t taking on any more clients who are
                using the right to buy scheme.
              </p>
              <p>
                We’re always expanding the range of transactions we can help
                with, so please do consider us next time round.
              </p>
            </React.Fragment>
          )}

          {this.shouldShowNewBuildMessage() && (
            <React.Fragment>
              <p>
                At the moment, we aren’t taking on any more clients who are buying a ‘new build’ or ‘first registration’ property. (A home on a new development or a property recently converted into flats, with no previous owner).
              </p>
              <p>
                We’re always expanding the range of transactions we can help
                with, so please do consider us next time round.
              </p>
            </React.Fragment>
          )}

          {this.shouldShowRetirementPropertyMessage() && (
            <React.Fragment>
              <p>
                At the moment, we aren’t taking on any more clients who are buying or selling a home built specifically for people in later life. (A 'retirement home', sheltered accommodation or in an 'assisted living' development).
              </p>
            </React.Fragment>
          )}

          {this.shouldShowBuyingLeaseholdMessage() && (
            <React.Fragment>
              <p>
                At the moment, we aren’t taking on any more clients who are buying a leasehold property.
              </p>
            </React.Fragment>
          )}

          <Action onClick={() => this.props.showFirst(
            {
              agentName: this.props.agentName,
              agentEmailAddress: this.props.agentEmailAddress
            }
          )}>
            Refer another client
          </Action>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default ReferrerSorryPage;
