import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import Heading from '../../components/Heading';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants/index';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class ContactDetails extends Component {
  constructor(props) {
    super(props);
    if (this.props.contactDetails) {
      const details = this.props.contactDetails;
      this.state = {
        emailAddress: details.emailAddress,
        phoneNumber: details.phoneNumber,
        clientAddressIsSaleAddress: details.clientAddressIsSaleAddress,
        clientAddress: { ...details.clientAddress },
        errors: {},
      };
    } else {
      this.state = {
        emailAddress: '',
        phoneNumber: '',
        clientAddressIsSaleAddress: null,
        clientAddress: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          addressTown: '',
          addressCounty: '',
          addressPostcode: '',
        },
        errors: {},
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const clientAddressFields = { ...this.state.clientAddress };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (!fields['emailAddress']) {
      const field = 'emailAddress';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields['phoneNumber']) {
      const field = 'phoneNumber';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (this.props.transactionType === 'Selling' || this.props.transactionType === 'BuyingAndSelling') {
      if (fields['clientAddressIsSaleAddress'] == null) {
        const field = 'clientAddressIsSaleAddress';
        formIsValid = false;
        errors[field] = true;
      }
    }

    if (!clientAddressFields['addressLine1']) {
      const field = 'addressLine1';
      formIsValid = false;
      errors[field] = true;
    }

    if (!clientAddressFields['addressTown']) {
      const field = 'addressTown';
      formIsValid = false;
      errors[field] = true;
    }

    if (!clientAddressFields['addressPostcode']) {
      const field = 'addressPostcode';
      formIsValid = false;
      errors[field] = true;
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleNestedFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state.clientAddress[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  emailAddressEntered = ea => {
    this.setState({ emailAddress: ea });
  };

  phoneNumberEntered = pn => {
    this.setState({ phoneNumber: pn });
  };

  selectClientAddressIsSaleAddress = sa => {
    this.setState({ clientAddressIsSaleAddress: sa });
  };

  handleAddressChange = field => event => {
    let clientAddress = { ...this.state.clientAddress };
    clientAddress[field] = event;
    this.setState({ clientAddress });
  };

  saveContactDetails = event => {
    event.preventDefault();

    if (this.handleValidation()) {

      if (this.state.clientAddressIsSaleAddress === true) {
        this.props.onStepComplete({
          contactDetails: {
            emailAddress: this.state.emailAddress,
            phoneNumber: this.state.phoneNumber,
            clientAddress: this.state.clientAddress,
            clientAddressIsSaleAddress: this.state.clientAddressIsSaleAddress,
          },
          sellingAddressDetails: {
            propertyAddress: {
              ...this.state.clientAddress
            }
          }
        });
      } else {
        this.props.onStepComplete({
          contactDetails: {
            emailAddress: this.state.emailAddress,
            phoneNumber: this.state.phoneNumber,
            clientAddress: this.state.clientAddress,
            clientAddressIsSaleAddress: this.state.clientAddressIsSaleAddress,
          },
          sellingAddressDetails: null
        });
      }
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us your contact details" headingSize="2" removeTopPadding>

          <TextQuestion
            question="What’s your email address?"
            value={this.state.emailAddress}
            valueEntered={this.emailAddressEntered}
            validate={this.handleFieldValidation('emailAddress')}
            validationError={
              this.state.errors.emailAddress
                ? 'Enter your email address'
                : null
            }
          />

          <TextQuestion
            question="And your phone number?"
            value={this.state.phoneNumber}
            valueEntered={this.phoneNumberEntered}
            validate={this.handleFieldValidation('phoneNumber')}
            validationError={
              this.state.errors.phoneNumber
                ? 'Enter your phone number'
                : null
            }
          />

          <Heading level="3" size="3">
            What’s your postal address?
          </Heading>

          { this.props.clientIsCompany && (
            <Heading level="4" size="4">
              We’ll need this for our identity checks
            </Heading>
          )}

          <TextQuestion
            question="Address line 1"
            value={this.state.clientAddress.addressLine1 || ''}
            valueEntered={this.handleAddressChange('addressLine1')}
            validate={this.handleNestedFieldValidation('addressLine1')}
            validationError={
              this.state.errors.addressLine1
                ? 'Enter the first line of your address'
                : null
            }
          />

          <TextQuestion
            question="Address line 2"
            optional={true}
            value={this.state.clientAddress.addressLine2 || ''}
            valueEntered={this.handleAddressChange('addressLine2')}
          />

          <TextQuestion
            question="Address line 3"
            optional={true}
            value={this.state.clientAddress.addressLine3 || ''}
            valueEntered={this.handleAddressChange('addressLine3')}
          />

          <TextQuestion
            question="Town"
            value={this.state.clientAddress.addressTown || ''}
            valueEntered={this.handleAddressChange('addressTown')}
            validationError={
              this.state.errors.addressTown
                ? 'Enter your town'
                : null
            }
          />

          <TextQuestion
            question="County"
            optional={true}
            value={this.state.clientAddress.addressCounty || ''}
            valueEntered={this.handleAddressChange('addressCounty')}
          />

          <TextQuestion
            question="Postcode"
            size="small"
            value={this.state.clientAddress.addressPostcode || ''}
            valueEntered={this.handleAddressChange('addressPostcode')}
            validate={this.handleNestedFieldValidation('addressPostcode')}
            validationError={
              this.state.errors.addressPostcode
                ? 'Enter your postcode'
                : null
            }
          />

          {(this.props.transactionType === 'Selling' || this.props.transactionType === 'BuyingAndSelling') && (
            <RadioQuestion
              question='Is this the address of the property you are selling?'
              options={YES_NO_OPTIONS}
              inline={true}
              name="clientAddressIsSaleAddress"
              selected={this.state.clientAddressIsSaleAddress}
              optionSelected={this.selectClientAddressIsSaleAddress}
              validate={this.handleFieldValidation('clientAddressIsSaleAddress')}
              validationError={
                this.state.errors['clientAddressIsSaleAddress']
                  ? 'Please choose an option'
                  : null
              }
            />
          )}

          <Action onClick={this.saveContactDetails}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default ContactDetails;
