import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom';

import PageLayout from '../../../components/PageLayout';
import BrokerCaseList from "./BrokerCaseList";
import BrokerCaseDetails from "./BrokerCaseDetails";

const BrokerDashboard = () => {
  const location = useLocation();

  const isCaseList = location.pathname === "/dashboard";

  return (
    <PageLayout pageWidth="wide" showLogout={true} background={isCaseList ? 'white' : null}>
      <Routes>
        <Route
          path="/"
          element={<BrokerCaseList />} />
        <Route
          path="/:caseRef"
          element={<BrokerCaseDetails />} />
      </Routes>
    </PageLayout>
  )
};

export default BrokerDashboard
