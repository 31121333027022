import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';
import Circle from "./Circle";

import { Home, HomeAlt } from '@styled-icons/boxicons-regular'
import { Home as HomeSolid } from '@styled-icons/boxicons-solid'

const JunoTile = styled.button`
  width: 100%;

  position: relative;
  background-color: ${props => props.theme.color.offWhite};
  border-radius: 20px;
  border: 2px solid transparent;
  transition: border 0.2s ease-out 0s;
  text-align: left;

  padding: 24px;
  ${mediaQueries.md} {
    padding: 40px;
  }
  svg {
    color: ${props => props.theme.color.purple};

  }
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.purple};
    border-color: ${props => props.theme.color.purple};
  }
`;

const JunoTileContent = styled.div`
  h3 {
    color: ${props => props.theme.color.text1};
    font-size: 24px;
    margin-top: 32px;
    margin-bottom: 0;
    ${mediaQueries.md} {
      font-size: 28px;
      margin-top: 64px;
    }
  }
  p {
    color: ${props => props.theme.color.text3};
    font-size: 16px;
    ${mediaQueries.md} {
      font-size: 18px;
    }
  }
  p:first-letter {
    text-transform: uppercase;
  }
`

const JunoTileIcon = styled.div`
  color: ${props => props.theme.color.purple};
`

const JunoTileCount = styled(Circle)`
  position: absolute;
  top: 12px;
  right: 12px;
  ${mediaQueries.md} {
    top: 24px;
    right: 24px;
  }
`

function Tile(props) {
  return (
    <JunoTile onClick={props.onClick}>
      <JunoTileContent>
        <JunoTileIcon>
          {props.count && props.count > 0 && <JunoTileCount color="white" background="purple" dataTestId="things-to-do">{props.count}</JunoTileCount>}

          {props.caseType === "buying" && (
            <HomeSolid size="40" />
          )}

          {props.caseType === "selling" && (
            <Home size="40" />
          )}

          {props.caseType === "remortgaging" && (
            <HomeAlt size="40" />
          )}
        </JunoTileIcon>

        <h3>{props.title}</h3>
        <p>{props.children}</p>
      </JunoTileContent>
    </JunoTile>
  );
}

export default Tile;
