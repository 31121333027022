import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const JunoRadioConditional = styled.div`
  max-width: 37.1em;
  margin-top: -1.5em;
  margin-left: 0.9em;
  margin-bottom: 1em;
  padding-left: 1.75em;
  padding-top: 1em;
  border-left: 4px solid ${props => props.theme.color.junoBorder};

  ${mediaQueries.md} {
    max-width: 37em;
    margin-top: -32px;
    margin-left: 16px;
    margin-bottom: 1.5em;
    padding-left: 36px;
    padding-top: 1.5em;
    border-left: 4px solid #a7b1be;
  }
`;

function RadioConditional(props) {
  return (
    <JunoRadioConditional {...props}>{props.children}</JunoRadioConditional>
  );
}

export default RadioConditional;
