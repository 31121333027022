import React, { Component } from 'react';
import Card from '../../components/Card';
import { TextareaQuestion } from '../../components/TextQuestion';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';

class SellingClient1RegisteredOwner extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      registeredOwnerDetails: 'Please fill this in',
    };

    if (this.props.registeredOwnerDetails) {
      this.state = {
        registeredOwnerDetails: this.props.registeredOwnerDetails,
        errors: { ...this.props.errors },
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        registeredOwnerDetails: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (!fields['registeredOwnerDetails']) {
      const field = 'registeredOwnerDetails';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }
    this.setState({ errors });
  };

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  saveSellingClient1RegisteredOwner = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        registeredOwnerDetails: this.state.registeredOwnerDetails,
        errors: this.state.errors,
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Ok, we just need to ask one more thing" removeTopPadding>
          <p>
            You told us you don’t have grant of probate, or power of attorney -
            can you tell us a bit more about your relationship to the registered
            owner of the property?
          </p>

          <TextareaQuestion
            question="What’s your relationship to the registered owner?"
            value={this.state.registeredOwnerDetails || ''}
            valueEntered={this.handleInputChange('registeredOwnerDetails')}
            validate={this.handleFieldValidation('registeredOwnerDetails')}
            validationError={
              this.state.errors.registeredOwnerDetails
                ? this.state.errors.registeredOwnerDetails
                : null
            }
          />

          <Action onClick={this.saveSellingClient1RegisteredOwner}>
            Continue
          </Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default SellingClient1RegisteredOwner;
