import React from 'react';
import StyledTodoListItem, {TodoText, TodoTextSecondary} from './StyledTodoListItem'

function ListEnquiriesWaitingForClient(props) {

  if (props.numberOfEnquiriesWaitingForClient === 0) {
    return null
  }

  return (
    <StyledTodoListItem clickable={false} complete={false}>
      <TodoText>
        We're waiting for your reply to {props.numberOfEnquiriesWaitingForClient} {props.numberOfEnquiriesWaitingForClient > 1 ? 'questions' : 'question'}
        <TodoTextSecondary>Check your email for the subject "Questions about {props.propertyAddress}"</TodoTextSecondary>
      </TodoText>
    </StyledTodoListItem>
  )
}

export default ListEnquiriesWaitingForClient;
