export function recordSignupTermination(flowId, notes) {
  fetch('/api/recordFlowTermination', {
    method: 'PUT',
    body: JSON.stringify({
      flowId: flowId,
      flowType: 'signup',
      notes: notes,
    }),
  });
}
