import React from 'react';
import List, { ListItem } from './List';
import Details from './Details';

function JunoMoneyDetails() {
  return (
    <Details summary="We cannot accept money from China, Hong Kong, Turkey, the UAE, or crypto">
      <p>Every law firm is required to verify where the money used toward a purchase has come from.</p>

      <p>We cannot accept money from cryptocurrency, China, Hong Kong, Turkey, the United Arab Emirates (inc Abu Dhabi and Dubai) or other countries or sources we cannot fully investigate. This applies even if the money is now in a UK bank account. If you have money in another country and you’d like us to check whether we can accept it, please just ask.</p>
      
      <p>If you have money from a source we cannot accept, you have two options:</p>
      <List number>
        <ListItem>
          You can find a different UK-based source for the money – such as another savings account, or a relative who can give you an unconditional gift.
        </ListItem>
        <ListItem>
          You can use another law firm which is set up to verify money from this source, such as a bilingual law firm or a firm with a cryptocurrency specialisation, and we would refund any fees not yet transferred to our company account.
        </ListItem>
      </List>
    </Details>
  )
}

export default JunoMoneyDetails;
