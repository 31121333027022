// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-CustomFileInput-container{position:relative;cursor:pointer}.onfido-sdk-ui-CustomFileInput-input{bottom:0;left:0;display:none !important;position:absolute;right:0;top:0;width:100%;z-index:1}", ""]);
// Exports
exports.locals = {
	"container": "onfido-sdk-ui-CustomFileInput-container",
	"input": "onfido-sdk-ui-CustomFileInput-input"
};
module.exports = exports;
