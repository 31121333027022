import React, { Component } from 'react';
import Card from '../../components/Card';
import TextQuestion from "../../components/TextQuestion";
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

class EsignatureRequestSMSVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    fetch('/api/signature_request/' + this.props.flowState.formRequestId + '/send_sms_code', {
      method: 'POST',
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            error: 'Error sending SMS code',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Error sending SMS code',
          loading: false,
        });
      },
    );
  }

  smsCodeSet = event => {
    this.setState({smsCode: event});
  };

  handleValidation() {

    if (!this.state.smsCode) {
      this.setState({smsCodeError: 'Please enter the code'});
      return false;
    }
    if (!this.state.smsCode.match(/^\d{6}$/)) {
      this.setState({smsCodeError: 'Must be a 6 digit code'});
      return false;
    }

    return true
  };

  submitSMSCode = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        smsCode: this.state.smsCode,
      });
      return;
    }

  };

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn’t work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Enter your 6-digit code from your text message" removeTopPadding>

          <TextQuestion
            name="sms_code"
            question="SMS code"
            valueEntered={this.smsCodeSet}
            validationError={
              this.state.smsCodeError
                ? this.state.smsCodeError
                : null
            }
          />
          <Action onClick={this.submitSMSCode}>Submit code</Action>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default EsignatureRequestSMSVerification;
