import React, {Component} from 'react';
import Card from '../../components/DashboardCard';
import Confirmation from '../../components/Confirmation';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import PageHeader from '../../components/PageHeader';
import QuestionDescription from '../../components/QuestionDescription';
import ScrollToTop from "../../components/utils/ScrollToTop";

class EmailedDoable extends Component {

  createContent(content) {
    return { __html: content };
  }

  render() {

    if (!this.props.doable) return null;

    const requestTitle = this.props.doable.title;
    const requestDescription = this.props.doable.description;
    return (
      <React.Fragment>
        <ScrollToTop />
        <PageHeader>
          Other things we need you to do
        </PageHeader>
        {this.props.doable.status === 'complete' && (
          <Confirmation title="Thanks for doing this — we've marked it as done" submission={true}>
          </Confirmation>
        )}
        <Card title={requestTitle} removeTopPadding>
          {requestDescription && (
            <QuestionDescription dangerouslySetInnerHTML={this.createContent(requestDescription)} />
          )}
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default EmailedDoable
