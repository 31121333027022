import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";

import PageLayout from '../../components/PageLayout';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';

function Logout() {

  const [state, setState] = useState({
    loading: true,
    error: null,
    redirect: false,
  });

  useEffect(() => {
    const url = "/api/logout";

    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'same-origin'
        });
        const data = await response.json();
        if (data.status === 'success') {
          setState(prevState => ({
            ...prevState,
            loading: false,
          }));
        } else if (data.status === 'error') {
          setState(prevState => ({
            ...prevState,
            loading: false,
            redirect: true,
          }));
        }
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loading: false,
          redirect: true,
        }));
      }
    };

    fetchData();

  }, []);

  if (state.loading) {
    return null;
  }

  if (state.redirect) {
    return <Navigate to="/login" />;
  }

  return (
    <PageLayout pageWidth="narrow">
      <ScrollToTop />
      <Card title="We’ve signed you out" removeTopPadding headingSize="1">
        <p>If you signed out accidentally, get a new link to sign in below.</p>

        <p>
          Get a new link to <Link to="/login">sign in instantly</Link>.
        </p>
      </Card>
    </PageLayout>
  );

}

export default Logout;
