import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import reportWebVitals from './reportWebVitals';

import * as Sentry from "@sentry/react";

try {
  if (window.appConfig.sentry_dsn && window.appConfig.sentry_dsn.length > 0) {
    const release = `build${process.env.REACT_APP_BUILD_NUMBER || 'unknown'}`;
    console.log("Initialising Sentry with release:", release);
    Sentry.init({
      dsn: window.appConfig.sentry_dsn,
      integrations: [new Sentry.BrowserTracing()],
      release: release,
      environment: window.appConfig.sentry_environment,
    })
  }
} catch (e) {
  console.error("Failed to initialise Sentry", e)
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
