// Set up branch config - so we can default the agent email address
export const SEQUENCE_BRANCH_CONFIG = [
  {
    id: 'sequence-aylesbury',
    email: 'Aylesbury@brownandmerry.co.uk',
    referrer: 'Brown & Merry - Aylesbury',
    logo: 'brown-and-merry',
    alt: 'Brown and Merry logo'
  },
  {
    id: 'sequence-berkhamsted',
    email: 'Berkhamsted@brownandmerry.co.uk',
    referrer: 'Brown & Merry - Berkhamsted',
    logo: 'brown-and-merry',
    alt: 'Brown and Merry logo'
  },
  {
    id: 'sequence-chesham',
    email: 'Chesham@brownandmerry.co.uk',
    referrer: 'Brown & Merry - Chesham',
    logo: 'brown-and-merry',
    alt: 'Brown and Merry logo'
  },
  {
    id: 'sequence-hemel-hempstead',
    email: 'HemelHempstead@brownandmerry.co.uk',
    referrer: 'Brown & Merry - Hemel Hempstead',
    logo: 'brown-and-merry',
    alt: 'Brown and Merry logo'
  },
  {
    id: 'sequence-tring',
    email: 'Tring@brownandmerry.co.uk',
    referrer: 'Brown & Merry - Tring',
    logo: 'brown-and-merry',
    alt: 'Brown and Merry logo'
  },
  {
    id: 'sequence-watford',
    email: 'Watford@brownandmerry.co.uk',
    referrer: 'Brown & Merry - Watford',
    logo: 'brown-and-merry',
    alt: 'Brown and Merry logo'
  },
  {
    id: 'sequence-wendover',
    email: 'Wendover@brownandmerry.co.uk',
    referrer: 'Brown & Merry - Wendover',
    logo: 'brown-and-merry',
    alt: 'Brown and Merry logo'
  },
  {
    id: 'sequence-burnham',
    email: 'Burnham@rogerplatt.co.uk',
    referrer: 'Roger Platt - Burnham',
    logo: 'roger-platt',
    alt: 'Roger Platt logo'
  },
  {
    id: 'sequence-lower-earley',
    email: 'LowerEarley@rogerplatt.co.uk',
    referrer: 'Roger Platt - Lower Earley',
    logo: 'roger-platt',
    alt: 'Roger Platt logo'
  },
  {
    id: 'sequence-maidenhead',
    email: 'Maidenhead@rogerplatt.co.uk',
    referrer: 'Roger Platt - Maidenhead',
    logo: 'roger-platt',
    alt: 'Roger Platt logo'
  },
  {
    id: 'sequence-enfield',
    email: 'info@barnfields.com',
    referrer: 'Barnfields - Enfield',
    logo: 'barnfields',
    alt: 'Barnfields logo'
  },
  {
    id: 'sequence-broxbourne',
    email: 'Broxbourne@williamhbrown.co.uk',
    referrer: 'William H Brown - Broxbourne',
    logo: 'william-h-brown',
    alt: 'William H Brown logo'
  },
  {
    id: 'sequence-harlow',
    email: 'harlow@williamhbrown.co.uk',
    referrer: 'William H Brown - Harlow',
    logo: 'william-h-brown',
    alt: 'William H Brown logo'
  },
  {
    id: 'sequence-hertford',
    email: 'Hertford@williamhbrown.co.uk',
    referrer: 'William H Brown - Hertford',
    logo: 'william-h-brown',
    alt: 'William H Brown logo'
  },
  {
    id: 'sequence-hoddesdon',
    email: 'Hoddesdon@williamhbrown.co.uk',
    referrer: 'William H Brown - Hoddesdon',
    logo: 'william-h-brown',
    alt: 'William H Brown logo'
  },
  {
    id: 'sequence-st-albans',
    email: 'StAlbans@williamhbrown.co.uk',
    referrer: 'William H Brown - St Albans',
    logo: 'william-h-brown',
    alt: 'William H Brown logo'
  },
  {
    id: 'sequence-stevenage',
    email: 'stevenage@williamhbrown.co.uk',
    referrer: 'William H Brown - Stevenage',
    logo: 'william-h-brown',
    alt: 'William H Brown logo'
  },
  {
    id: 'sequence-ware',
    email: 'Ware@williamhbrown.co.uk',
    referrer: 'William H Brown - Ware',
    logo: 'william-h-brown',
    alt: 'William H Brown logo'
  },
  {
    id: 'sequence-welwyn-garden-city',
    email: 'WelwynGardenCity@williamhbrown.co.uk',
    referrer: 'William H Brown - Welwyn Garden City',
    logo: 'william-h-brown',
    alt: 'William H Brown logo'
  },
  {
    id: 'hurfords-castor',
    email: 'info@hurfords.co.uk',
    referrer: 'Hurfords - Castor',
    logo: 'hurfords',
    alt: 'Hurfords logo'
  },
  {
    id: 'hurfords-stamford',
    email: 'stamford@hurfords.co.uk',
    referrer: 'Hurfords - Stamford',
    logo: 'hurfords',
    alt: 'Hurfords logo'
  },
  {
    id: 'hurfords-uppingham',
    email: 'uppingham@hurfords.co.uk',
    referrer: 'Hurfords - Uppingham',
    logo: 'hurfords',
    alt: 'Hurfords logo'
  },
  {
    id: 'kevin-henry-saffron-walden',
    email: 'sales@kevinhenry.co.uk',
    referrer: 'Kevin Henry - Saffron Walden',
    logo: 'kevin-henry',
    alt: 'Kevin Henry logo'
  },
  {
    id: 'quentin-marks-bourne',
    email: 'sales@quentinmarks.co.uk',
    referrer: 'Quentin Marks - Bourne',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'pattison-lane-desborough',
    email: 'desborough@pattisonlane.co.uk',
    referrer: 'Pattison Lane - Desborough',
    logo: 'pattison-lane',
    alt: 'Pattison Lane logo'
  },
  {
    id: 'pattison-lane-kettering',
    email: 'kettering@pattisonlane.co.uk',
    referrer: 'Pattison Lane Estate Agents - Kettering',
    logo: 'pattison-lane',
    alt: 'Pattison Lane logo'
  },
  {
    id: 'sharman-quinney-chatteris',
    email: 'chatteris@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Chatteris',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-march',
    email: 'march@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - March',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-cambourne',
    email: 'cambourne@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Cambourne',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-godmanchester',
    email: 'godmanchester@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Godmanchester',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-hampton',
    email: 'hampton@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Hampton',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-market-deeping',
    email: 'marketdeeping@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Market Deeping',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-peterborough',
    email: 'peterborough@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Peterborough',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-stanground',
    email: 'stanground@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Stanground',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-werrington',
    email: 'werrington@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Werrington',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-whittlesey',
    email: 'whittlesey@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Whittlesey',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-great-shelford',
    email: 'greatshelford@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Great Shelford',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-orchard-park-cambridge',
    email: 'orchardpark@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Orchard Park',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-oundle',
    email: 'oundle@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Oundle',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-ramsey',
    email: 'ramsey@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Ramsey',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-thrapston',
    email: 'thrapston@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Thrapston',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  },
  {
    id: 'sharman-quinney-yaxley',
    email: 'yaxley@sharmanquinney.co.uk',
    referrer: 'Sharman Quinney - Yaxley',
    logo: 'sharman-quinney',
    alt: 'Sharman Quinney logo'
  }
]

// Use the list of branches, to see if we should show the Sequence Intro
export const SEQUENCE_BRANCHES = SEQUENCE_BRANCH_CONFIG.map(branch => branch.id)

export const getReferringBranchConfig = (branchId) => {
  return SEQUENCE_BRANCH_CONFIG.filter(branch => branch.id === branchId)[0]
}

// Define the referral sources for Sequence
export const SEQUENCE_REFERRAL_SOURCES = [
  'home_conveyancing_seller',
  'home_conveyancing_buyer',
  'home_conveyancing_buyer_searches_prepaid'
]

// Helper to hide content for all Sequence referrals
// - including our internal referral process
export const isSequenceReferral = (source)  => {
  return SEQUENCE_REFERRAL_SOURCES.includes(source)
}

// Helper to hide content for Sequence branches ONLY
// - but not change our internal referral process
export const isSequenceBranch = (id) => {
  return SEQUENCE_BRANCHES.includes(id)
}
