// lifecycle_tag_to_description = {
//   "case_setup": "1 – Setup",
//   "information_gathering": "2 – Gather information",
//   "investigation": "3 – Send contract pack, check the title and offer",
//   "enquiries_ongoing": "4 – Resolve enquiries",
//   "approaching_exchange": "5 – Resolve final enquiries",
//   "ready_to_exchange": "6 – Exchange (or submit CoT)",
//   "approaching_completion": "7 – Complete",
//   "post_completion": "8 – Apply to register",
//   "wrapping_up": "9 – Send the registered title to client and lender, close the case",
// }

// Phase 6 - all enquiries resolved
// Phase 5 - 1-3 enquiries remaining
// Phase 4 - 4+ enquiries remaining
// Phase 3 - [whatever we had before]
// Phase 2 - [whatever we had before]
// Phase 1 - case setup

export const LIFECYCLE_TAGS = {
  pre_memo: {
    tags: [
      'case_setup'
    ],
    name: 'Pre-MOS'
  },
  all_cases: {
    tags: [
      'case_setup', 'information_gathering', 'investigation', 'enquiries_ongoing', 'approaching_exchange', 'ready_to_exchange'
    ],
    name: 'All active cases'
  },
  exchanged: {
    tags: [
      'approaching_completion'
    ],
    name: 'Exchanged cases'
  },
  completed: {
    tags: ['post_completion', 'wrapping_up'
    ],
    name: 'Completed cases'
  }
};

export const LIFECYCLE_TAG_DESCRIPTIONS = {
  tags: [
    { tag: 'case_setup', description: 'Case setup' },
    { tag: 'information_gathering', description: 'Gather information' },
    { tag: 'investigation', description: 'Contract pack' },
    { tag: 'enquiries_ongoing', description: '4 + enquiries remaining' },
    { tag: 'approaching_exchange', description: '1-3 enquiries remaining' },
    { tag: 'ready_to_exchange', description: 'All enquiries resolved' },
    { tag: 'approaching_completion', description: 'Approaching completion' },
    { tag: 'post_completion', description: 'Completed' },
    { tag: 'wrapping_up', description: 'Completed' },
  ]
};

export const MILESTONE_DESCRIPTIONS = {
  memorandum_of_sale_received: 'Memorandum of Sale received',
  leasehold_management_pack_received: 'Leasehold management pack received',
  searches_ordered: 'Searches ordered',
  searches_expected: 'Searches expected',
  searches_received: 'Searches received',
  draft_contract_received: 'Draft contract received',
  draft_contract_sent: 'Draft contract sent',
  first_enquiry_sent_to_other_side: 'First enquiry sent',
  first_enquiry_received_from_other_side: 'First enquiry received',
  exchange: 'Exchange',
  completion: 'Completion',
};
