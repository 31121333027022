import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class SellingAddressDetails extends Component {
  constructor(props) {
    super(props);

    if (this.props.sellingAddressDetails) {
      const details = this.props.sellingAddressDetails;
      this.state = {
        price: details.price,
        priceValid: true,
        propertyAddress: { ...details.propertyAddress },
        errors: { ...details.errors },
      };
    } else {
      this.state = {
        propertyAddress: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          addressTown: '',
          addressCounty: '',
          addressPostcode: '',
        },
        price: '',
        priceValid: true,
        errors: {},
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };

    let errors = {};
    let formIsValid = true;

    if (!fields.propertyAddress['addressLine1']) {
      const field = 'addressLine1';
      formIsValid = false;
      errors[field] = true;
    }

    if (!fields.propertyAddress['addressTown']) {
      const field = 'addressTown';
      formIsValid = false;
      errors[field] = true;
    }

    if (!fields.propertyAddress['addressPostcode']) {
      const field = 'addressPostcode';
      formIsValid = false;
      errors[field] = true;
    }

    if (!fields['price']) {
      const field = 'price';
      formIsValid = false;
      errors[field] = true;
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleAddressFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type === 'blur') {
      if (!this.state.propertyAddress[field]) {
        errors[field] = errorMessages[field];
      }
    } else {
      errors[field] = null;
    }

    this.setState({ errors });
  };

  handlePriceFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type === 'blur') {
      this.setState(
        { priceValid: this.isPriceValid(event.target.value) },
        () => {
          if (this.state.priceValid === false) {
            errors[field] = 'Please enter a valid price';
            this.setState({ errors });
          }
        },
      );
    } else {
      errors[field] = null;
    }

    this.setState({ errors });
  };

  priceEntered = p => {
    this.setState({ price: p });
  };

  // TODO extract common price functions
  normalisePrice = p => {
    const stripCharsRegex = /[£, ]/g;
    return p.replace(stripCharsRegex, '');
  };

  isPriceValid = price => {
    const allowedCharsRegex = /^[0-9£,. ]+$/;
    if (allowedCharsRegex.test(String(price))) {
      const normalised = this.normalisePrice(price);

      const decimalPlaceRegex = /^\d+\.?\d{0,2}$/;

      return decimalPlaceRegex.test(String(normalised));
    }
    return false;
  };

  handleAddressChange = field => event => {
    let propertyAddress = { ...this.state.propertyAddress };
    propertyAddress[field] = event;
    this.setState({ propertyAddress });
  };

  saveSellingAddressDetails = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        sellingAddressDetails: {
          propertyAddress: this.state.propertyAddress,
          price: this.state.price,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="What's the property address?" removeTopPadding>
          <React.Fragment>
            <TextQuestion
              question="Address line 1"
              value={this.state.propertyAddress.addressLine1 || ''}
              valueEntered={this.handleAddressChange('addressLine1')}
              validate={this.handleAddressFieldValidation('addressLine1')}
              validationError={
                this.state.errors.addressLine1
                  ? 'Enter the first line of the address'
                  : null
              }
            />

            <TextQuestion
              question="Address line 2"
              optional={true}
              value={this.state.propertyAddress.addressLine2 || ''}
              valueEntered={this.handleAddressChange('addressLine2')}
            />

            <TextQuestion
              question="Address line 3"
              optional={true}
              value={this.state.propertyAddress.addressLine3 || ''}
              valueEntered={this.handleAddressChange('addressLine3')}
            />

            <TextQuestion
              question="Town"
              value={this.state.propertyAddress.addressTown || ''}
              valueEntered={this.handleAddressChange('addressTown')}
              validationError={
                this.state.errors.addressTown
                  ? 'Enter the town'
                  : null
              }
            />

            <TextQuestion
              question="County"
              optional={true}
              value={this.state.propertyAddress.addressCounty || ''}
              valueEntered={this.handleAddressChange('addressCounty')}
            />

            <TextQuestion
              question="Postcode"
              size="small"
              value={this.state.propertyAddress.addressPostcode || ''}
              valueEntered={this.handleAddressChange('addressPostcode')}
              validate={this.handleAddressFieldValidation('addressPostcode')}
              validationError={
                this.state.errors.addressPostcode
                  ? 'Enter the postcode'
                  : null
              }
            />
          </React.Fragment>

          <TextQuestion
            question={this.props.propertyOfferAccepted ? 'How much have you agreed to sell it for?' : 'What’s your asking price?'}
            size="large"
            value={this.state.price || ''}
            valueEntered={this.priceEntered}
            validate={this.handlePriceFieldValidation('price')}
            validationError={
              this.state.errors.price
                ? 'Enter the property price'
                : null
            }
          />

          <Action onClick={this.saveSellingAddressDetails}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default SellingAddressDetails;
