import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';
import { Link } from 'react-router-dom';

import JunoLogo from './JunoLogo.js';

/* stylelint-disable no-descending-specificity */

const JunoHeader = styled.header`
  background-color: ${props => props.theme.color.junoBrand};
  color: ${props => props.theme.color.junoHeader};
  padding-top: 1em;
  padding-bottom: 1em;
`;

const JunoHeaderInner = styled.div`
  display: flex;
  align-items: center;

  max-width: ${props => (props.theme.pageWidth[props.pageWidth]/16)}rem;

  margin-left: ${props => props.theme.spacingScale[6]};
  margin-right: ${props => props.theme.spacingScale[6]};

  ${mediaQueries.md} {
    margin-left: ${props => props.pageWidth === 'narrow' ? 'auto' : props.theme.spacingScale[12]};
    margin-right: ${props => props.pageWidth === 'narrow' ? 'auto' : props.theme.spacingScale[12]};
  }

  @media(min-width: ${props => (props.theme.pageWidth[props.pageWidth] + props.theme.gridSpacing.columnWidth * 2) / 16}rem) {
    margin-left: auto;
    margin-right: auto;
  }

  a {
    color: ${props => props.theme.color.white};
    border-bottom-style: solid;
    border-bottom-color: transparent;
    display: inline-block;
  }
  a:link {
    color: #f1f2f4;
  }
  a:visited {
    color: #f1f2f4;
  }
  a:hover {
    color: #fff;
    border-bottom-color: #fff;
  }
  a:active {
    color: #fff;
  }
`;

const JunoHeaderLink = styled(Link)`
  margin-left: auto;
  border-bottom-width: 1px;
`;
/* stylelint-enable no-descending-specificity */

class Header extends Component {
  render() {
    return (
      <JunoHeader>
        <JunoHeaderInner pageWidth={this.props.pageWidth}>
          <JunoLogo />
          {this.props.showLogout && <JunoHeaderLink to="/logout">Sign out</JunoHeaderLink>}
        </JunoHeaderInner>
      </JunoHeader>
    );
  }
}

export default Header;

Header.propTypes = {
  pageWidth: PropTypes.string.isRequired,
  showLogout: PropTypes.bool.isRequired
};
