import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import CheckboxQuestion from '../../components/CheckboxQuestion';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { BUYING_REJECTION_OPTIONS, YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class BuyingPropertyDetails extends Component {
  constructor(props) {
    super(props);

    if (this.props.buyingPropertyOfferAccepted !== undefined) {
      this.state = {
        propertyOfferAccepted: this.props.buyingPropertyOfferAccepted,
        buyingRejectionReason: this.props.buyingRejectionReason
          ? this.props.buyingRejectionReason
          : new Map(),
        errors: {},
      };
    } else {
      this.state = {
        propertyOfferAccepted: null,
        buyingRejectionReason: new Map(),
        errors: {},
        errorMessages: {
          propertyOfferAccepted: 'Have you had an offer accepted?',
        },
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (fields['propertyOfferAccepted'] === null) {
      const field = 'propertyOfferAccepted';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }
    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }
    this.setState({ errors });
  };

  selectPropertyOffer = po => {
    this.setState({ propertyOfferAccepted: po });
  };

  handleBuyingRejectionReason = event => {
    const item = event.target.name;
    const isChecked = event.target.checked;
    this.setState(prevState => ({
      buyingRejectionReason: prevState.buyingRejectionReason.set(
        item,
        isChecked,
      ),
    }));
  };

  shouldReject = () => {
    for (let checked of this.state.buyingRejectionReason.values()) {
      if (checked === true) {
        return true;
      }
    }
    return false;
  };

  saveBuyingPropertyDetails = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        buyingPropertyOfferAccepted: this.state.propertyOfferAccepted,
        buyingRejectionReason: this.state.buyingRejectionReason,
        shouldReject: this.shouldReject(),
      });
      return;
    }
  };

  render() {
    const cardTitle =
      this.props.transactionType === 'BuyingAndSelling'
        ? 'Tell us about the property you are buying'
        : 'Let’s start with the basics';
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={cardTitle} removeTopPadding>
          <RadioQuestion
            question={this.props.clientIsCompany? "Has your company had an offer accepted on a specific property yet?" : "Have you had an offer accepted?"}
            options={YES_NO_OPTIONS}
            inline={true}
            name="propertyOfferAccepted"
            selected={this.state.propertyOfferAccepted}
            optionSelected={this.selectPropertyOffer}
            validate={this.handleFieldValidation('propertyOfferAccepted')}
            validationError={
              this.state.errors.propertyOfferAccepted
                ? this.state.errors.propertyOfferAccepted
                : null
            }
          />

          {this.state.propertyOfferAccepted && (
            <React.Fragment>
              <CheckboxQuestion
                question={this.props.clientIsCompany? "Can you tell us if your company is buying..." : "Can you tell us if you're buying..."}
                options={BUYING_REJECTION_OPTIONS}
                inline={false}
                name={BUYING_REJECTION_OPTIONS.name}
                selected={this.state.buyingRejectionReason}
                onChange={this.handleBuyingRejectionReason}
              />
            </React.Fragment>
          )}

          <Action onClick={this.saveBuyingPropertyDetails}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default BuyingPropertyDetails;
