import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CheckCircle, RadioButtonUnchecked } from '@styled-icons/material';
import styled from 'styled-components/macro';

class StyledTodoListItem extends React.Component {
  render() {
    return (
      <TodoListItem
        onClick={this.props.onClick}
        clickable={this.props.clickable}
        complete={this.props.complete}>

        <TodoListItemPrefix clickable={this.props.clickable} complete={this.props.complete}>
          {this.props.complete ? <CheckCircle size="24" /> : <RadioButtonUnchecked size="24" />}
        </TodoListItemPrefix>

        {this.props.children}

      </TodoListItem>
    )
  }
}

export default StyledTodoListItem;

StyledTodoListItem.propTypes = {
  clickable: PropTypes.bool.isRequired,
  complete: PropTypes.bool,
  onClick: PropTypes.func
};


export const TodoListItem = styled.li`
  display: flex;
  padding: 10px 0;
  font-size: 16px;
  border-bottom: 1px solid ${props => props.theme.color.paleGrey};

  color: ${props => props.clickable && props.theme.color.junoLink};
  color: ${props => (props.clickable === false && props.complete) && props.theme.color.junoTextSecondary};
  color: ${props => props.complete && props.clickable && props.theme.color.junoLink};
`;

export const TodoListItemPrefix = styled.span`
  flex: 0 0 auto;
  text-align: left;
  margin-right: 16px;

  color: inherit;
  color: ${props => props.complete && props.theme.color.green};
  color: ${props => !props.clickable && props.theme.color.junoText};
  color: ${props => (props.clickable === false && props.complete) && props.theme.color.paleGreen};
`;

export const TodoLink = styled(Link)`
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom-color: ${props => props.clickable && props.theme.color.junoLinkHover};
  }
`

export const TodoText = styled.div`
  color: inherit;
  border-bottom: 1px solid transparent;
  &:hover {
    cursor: ${props => props.clickable && 'pointer'};
    color: ${props => props.clickable && props.theme.color.junoLinkHover};
    border-bottom-color: ${props => props.clickable && props.theme.color.junoLinkHover};
    border-bottom-color: ${props => (!props.clickable || props.complete) && 'transparent'};
    border-bottom-color: ${props => props.complete && props.clickable && props.theme.color.junoLinkHover};
  }
  /* stylelint-disable selector-type-no-unknown */
  ${TodoLink}:hover & {
    border-bottom-color: transparent;
  }
  /* stylelint-enable selector-type-no-unknown */
`;

export const TodoDate = styled.div`
  flex: 0 0 auto;
  margin-left: auto;
  padding-left: 1em;
  font-size: 16px;
  color: ${props => props.complete ? props.theme.color.grey : props.theme.color.junoText};
`;

export const TodoTextSecondary = styled.div`
  font-size: 14px;
  color: ${props => props.theme.color.junoTextSecondary};
`;
