// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-Capture-documentContainer{position:relative;overflow:hidden}.onfido-sdk-ui-Capture-faceContainer{position:static}", ""]);
// Exports
exports.locals = {
	"modal_animation_duration": "200ms",
	"documentContainer": "onfido-sdk-ui-Capture-documentContainer",
	"faceContainer": "onfido-sdk-ui-Capture-faceContainer"
};
module.exports = exports;
