import React, {Component} from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Action from '../../components/Action';
import Card from '../../components/DashboardCard';
import Confirmation from '../../components/Confirmation';
import QuestionDescription from '../../components/QuestionDescription';
import PageHeader from '../../components/PageHeader';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import FileUploadQuestion from '../../components/FileUploadQuestion';

class DocumentUploadDoable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: props.doable.configuration.documents.reduce((docs, doc) => {
        docs[doc.identifier] = [];
        return docs;
      }, {}),
      documentsUploading: {},
      errors: {},
      error: null,
      submitted: null,
      loading: null
    };
  }

  handleUploadsChanged = identifier => uploadIds => {
    this.setState(
      prevState => ({
        documents: {
          ...prevState.documents,
          [identifier]: uploadIds,
        },
        errors: {
          ...prevState.errors,
          [identifier]: false,
        },
      })
    );
  };

  handleUploadInProgress = identifier => inProgress => {
    this.setState(
      prevState => ({
        documentsUploading: {
          ...prevState.documentsUploading,
          [identifier]: inProgress,
        },
      })
    );
  };

  handleValidation = () => {
    const documents = {...this.state.documents};
    let errors = {...this.state.errors};
    let errorMessages = {...this.state.errorMessages};
    let formIsValid = true;

    Object.entries(documents).forEach(([documentId, uploadIds]) => {
      if (uploadIds.length === 0) {
        errors[documentId] = true;
        errorMessages[documentId] = 'No file selected. Click below to select a file.';
        formIsValid = false;
      }
    });
    this.setState({errors, errorMessages});
    return formIsValid;
  };

  submit = event => {
    event.preventDefault();

    if (! this.handleValidation()) {
      return;
    }

    let data = {
      uploaded_documents: [],
    }
    Object.entries(this.state.documents).forEach(([documentId, uploadIds]) => {
      uploadIds.forEach(uploadId => {
        data.uploaded_documents.push({
          identifier: documentId,
          upload_id: uploadId,
        });
      });
    });

    fetch(`/api/dashboard/doable/${this.props.doable.id}`, {
      method: 'PUT',
      credentials: 'same-origin',
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.ok) {
        this.setState({
          loading: false,
          submitted: true,
        });
      } else {
        this.setState({
          loading: false,
          error: 'Failed to submit documents'
        });
      }
    }).catch(res => {
      this.setState({
        loading: false,
        error: 'Failed to submit documents'
      });
    });
  };

  returnToDoable = () => {
    this.setState({error: null})
  }

  createContent(content) {
    return { __html: content };
  }

  render() {

    if (!this.props.doable) return null;

    const requestTitle = this.props.doable.title;
    const requestDescription = this.props.doable.description;
    const documents = this.props.doable.configuration.documents;

    const {loading, error, submitted} = this.state;

    const buttonDisabled = Object.values(this.state.documentsUploading).some(v => v);

    const multipleDocuments = documents.some(doc => doc.allow_multiple_files) || documents.length > 1;
    const documentsText = multipleDocuments ? 'documents' : 'document';

    if (error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card
            title="Sorry, that didn't work"
            removeTopPadding
            headingSize="2"
          >
            {!!this.state.error && (
              <p>{this.state.error}.</p>
            )}
            <Action small onClick={this.returnToDoable}>
              Take me back to try again
            </Action>
          </Card>
        </React.Fragment>
      );
    }

    if (loading) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title={`Thanks, uploading your ${documentsText}...`}>
            <p>Don’t refresh the page, or close this window, we’ll let you know once it’s done.</p>
          </Card>
          <Image src={imageOfficeWorking} fullWidth={true} />
        </React.Fragment>
      );
    }

    if (submitted) {
      return (
        <React.Fragment>
          {window.scrollTo(0, 0)}
          <Confirmation title={`Thanks! We’ve got your ${documentsText}`}>
            <p>
              As soon as we need anything else we’ll drop you an
              email with a link to your dashboard.
            </p>
            <Action small onClick={this.props.returnToDashboard}>
              Take me back to my dashboard
            </Action>
          </Confirmation>
          <Image src={imageOfficeWorking} fullWidth={true} />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <ScrollToTop />
          <PageHeader>
            Upload {multipleDocuments ? '' : ' a'} {documentsText}
          </PageHeader>
          <Card title={requestTitle}>
            {requestDescription && (
              <QuestionDescription dangerouslySetInnerHTML={this.createContent(requestDescription)} />
            )}
            {documents.map(doc =>
                <FileUploadQuestion
                  key={doc.identifier}
                  question={doc.display_name}
                  multiple={!! doc.allow_multiple_files}
                  onUploadsChanged={this.handleUploadsChanged(doc.identifier)}
                  onUploadInProgress={this.handleUploadInProgress(doc.identifier)}
                  validationError={
                    this.state.errors[doc.identifier]
                      ? this.state.errorMessages[doc.identifier]
                      : null
                  }
                />
            )}
            <Action small disabled={buttonDisabled} onClick={this.submit}>
              Submit your {documentsText}
            </Action>
          </Card>
        </React.Fragment>
      )
    }
  }
}

export default DocumentUploadDoable;
