import React from 'react';

import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import Image from "../../components/Image";
import imageOfficeWorking from "../../images/office-working.png";

const reasonToWords = {
  disabled: {
    title: "Sorry - we aren’t taking on new cases at the moment",
    limitMessage: "To guarantee an excellent service for all our clients, we limit the number of new cases we take on. We’re adding more capacity as fast as we can, but we’ve now reached this week’s limit.",
    tryAgainMessage: "More slots will be made available later this week",
  },
  daily_quota_exceeded: {
    title: "Sorry - we aren’t taking on more new cases until tomorrow",
    limitMessage: "To guarantee an excellent service for all our clients, we limit the number of new cases we take on each day. We’re adding more capacity as fast as we can, but we’ve now reached today’s limit.",
    tryAgainMessage: "Try again from 9am tomorrow when we’ll make more slots available.",
  },
  weekly_quota_exceeded: {
    title: "Sorry - we aren’t taking on more new cases until next week",
    limitMessage: "To guarantee an excellent service for all our clients, we limit the number of new cases we take on each week. We’re adding more capacity as fast as we can, but we’ve now reached this week’s limit.",
    tryAgainMessage: "Try again from 9am next Monday when we’ll make more slots available.",
  }
}

const SignupTemporarilySuspended = (props) => {
  let words;
  if (props.reason) {
    words =  reasonToWords[props.reason];
  }
  if (!words) {
    words = reasonToWords.disabled;
  }

  return (
    <PageLayout>
      <Card title={words.title} removeTopPadding>
        <p>
          Thanks for choosing Juno for your conveyancing!
        </p>
        <p>
          {words.limitMessage}
        </p>
        <p>
          <strong>That means we can’t take on your case at the moment.</strong> We’re really sorry about this.
        </p>
        <p>
          {words.tryAgainMessage}
        </p>
        <p>
          Thanks for your understanding - we look forward to serving you soon.
        </p>
      </Card>
      <Image src={imageOfficeWorking} fullWidth />
    </PageLayout>
  );
}

export default SignupTemporarilySuspended;
