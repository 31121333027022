import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import {isEmailValid} from "../../utils/textUtils";

const numberOfClientOptions = [
  { title: 'No, just me', value: 1 },
  { title: 'Yes, with 1 other person', value: 2 },
  {
    title: 'Yes, with 2 other people',
    value: 3,
  },
  {
    title: 'Yes, with 3 other people',
    value: 4,
  },
];

class RemoOtherOwners extends Component {
  constructor(props) {
    super(props);

    const clientErrorMessages = {
      firstName: 'Enter their first name',
      lastName: 'Enter their last name',
      emailAddress: 'Enter their email address',
    };

    const errorMessages = {
      numberOfOwners: 'Please choose an option',
      client2: clientErrorMessages,
      client3: clientErrorMessages,
      client4: clientErrorMessages,
    };

    if (this.props.otherOwners) {
      const details = this.props.otherOwners;
      this.state = {
        numberOfOwners: details.numberOfOwners,
        client2: { ...details.client2 },
        client3: { ...details.client3 },
        client4: { ...details.client4 },
        errors: {
          client2: {},
          client3: {},
          client4: {},
        },
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        numberOfOwners: null,
        client2: { firstName: '', lastName: '', emailAddress: '' },
        client3: { firstName: '', lastName: '', emailAddress: '' },
        client4: { firstName: '', lastName: '', emailAddress: '' },
        errors: {
          client2: {},
          client3: {},
          client4: {},
        },
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (!fields['numberOfOwners']) {
      errors['numberOfOwners'] = true;
      formIsValid = false;
    }

    if (fields['numberOfOwners'] === 2) {
      if (!fields.client2['firstName']) {
        errors.client2['firstName'] = true;
        formIsValid = false;
      }

      if (!fields.client2['lastName']) {
        errors.client2['lastName'] = true;
        formIsValid = false;
      }

      if (!fields.client2['emailAddress']) {
        errors.client2['emailAddress'] = true;
        formIsValid = false;
      }

      if (
        fields.client2['emailAddress'] !== '' &&
        !isEmailValid(fields.client2['emailAddress'])
      ) {
        errors.client2['emailAddress'] = true;
        errorMessages.client2['emailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }
    }

    if (fields['numberOfOwners'] === 3) {
      if (!fields.client2['firstName']) {
        const field = 'firstName';
        formIsValid = false;
        errors.client2[field] = true;
      }
      if (!fields.client2['lastName']) {
        const field = 'lastName';
        formIsValid = false;
        errors.client2[field] = true;
      }
      if (!fields.client2['emailAddress']) {
        const field = 'emailAddress';
        formIsValid = false;
        errors.client2[field] = true;
      }

      if (!fields.client3['firstName']) {
        const field = 'firstName';
        formIsValid = false;
        errors.client3[field] = true;
      }
      if (!fields.client3['lastName']) {
        const field = 'lastName';
        formIsValid = false;
        errors.client3[field] = true;
      }
      if (!fields.client3['emailAddress']) {
        const field = 'emailAddress';
        formIsValid = false;
        errors.client3[field] = true;
      }
      if (
        fields.client3['emailAddress'] !== '' &&
        !isEmailValid(fields.client3['emailAddress'])
      ) {
        errors.client3['emailAddress'] = true;
        errorMessages.client3['emailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }
    }

    if (fields['numberOfOwners'] === 4) {
      if (!fields.client2['firstName']) {
        const field = 'firstName';
        formIsValid = false;
        errors.client2[field] = true;
      }
      if (!fields.client2['lastName']) {
        const field = 'lastName';
        formIsValid = false;
        errors.client2[field] = true;
      }
      if (!fields.client2['emailAddress']) {
        const field = 'emailAddress';
        formIsValid = false;
        errors.client2[field] = true;
      }
      if (
        fields.client2['emailAddress'] !== '' &&
        !isEmailValid(fields.client2['emailAddress'])
      ) {
        errors.client2['emailAddress'] = true;
        errorMessages.client2['emailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }

      if (!fields.client3['firstName']) {
        const field = 'firstName';
        formIsValid = false;
        errors.client3[field] = true;
      }
      if (!fields.client3['lastName']) {
        const field = 'lastName';
        formIsValid = false;
        errors.client3[field] = true;
      }
      if (!fields.client3['emailAddress']) {
        const field = 'emailAddress';
        formIsValid = false;
        errors.client3[field] = true;
      }
      if (
        fields.client3['emailAddress'] !== '' &&
        !isEmailValid(fields.client3['emailAddress'])
      ) {
        errors.client3['emailAddress'] = true;
        errorMessages.client3['emailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }

      if (!fields.client4['firstName']) {
        const field = 'firstName';
        formIsValid = false;
        errors.client4[field] = true;
      }
      if (!fields.client4['lastName']) {
        const field = 'lastName';
        formIsValid = false;
        errors.client4[field] = true;
      }
      if (!fields.client4['emailAddress']) {
        const field = 'emailAddress';
        formIsValid = false;
        errors.client4[field] = true;
      }
      if (
        fields.client4['emailAddress'] !== '' &&
        !isEmailValid(fields.client4['emailAddress'])
      ) {
        errors.client4['emailAddress'] = true;
        errorMessages.client4['emailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleClient2FieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = {
      ...this.state.errorMessages,
    };

    if (event.type !== 'blur') {
      errors.client2[field] = false;
    } else {
      if (!this.state.client2[field]) {
        errors.client2[field] = errorMessages.client2[field];
      }
    }
    this.setState({ errors });
  };

  handleClient3FieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = {
      ...this.state.errorMessages,
    };

    if (event.type !== 'blur') {
      errors.client3[field] = null;
    } else {
      if (!this.state.client3[field]) {
        errors.client3[field] = errorMessages.client3[field];
      }
    }
    this.setState({ errors });
  };

  handleClient4FieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = {
      ...this.state.errorMessages,
    };

    if (event.type !== 'blur') {
      errors.client4[field] = null;
    } else {
      if (!this.state.client4[field]) {
        errors.client4[field] = errorMessages.client4[field];
      }
    }
    this.setState({ errors });
  };

  handleClient2EmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors.client2[field] = false;
    } else {
      if (!isEmailValid(this.state.client2[field])) {
        errors.client2[field] = true;
        errorMessages.client2[field] = 'Please enter a valid email address';
      }
      if (!this.state.client2[field]) {
        errors.client2[field] = true;
        errorMessages.client2[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleClient3EmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors.client3[field] = false;
    } else {
      if (!isEmailValid(this.state.client3[field])) {
        errors.client3[field] = true;
        errorMessages.client3[field] = 'Please enter a valid email address';
      }
      if (!this.state.client3[field]) {
        errors.client3[field] = true;
        errorMessages.client3[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleClient4EmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors.client4[field] = false;
    } else {
      if (!isEmailValid(this.state.client4[field])) {
        errors.client4[field] = true;
        errorMessages.client4[field] = 'Please enter a valid email address';
      }
      if (!this.state.client4[field]) {
        errors.client4[field] = true;
        errorMessages.client4[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  selectNumberOfOwners = noo => {
    this.setState({ numberOfOwners: noo });
  };

  handleClient2 = field => event => {
    let client2 = { ...this.state.client2 };
    client2[field] = event;
    this.setState({ client2 });
  };

  handleClient3 = field => event => {
    let client3 = { ...this.state.client3 };
    client3[field] = event;
    this.setState({ client3 });
  };

  handleClient4 = field => event => {
    let client4 = { ...this.state.client4 };
    client4[field] = event;
    this.setState({ client4 });
  };

  saveOtherOwners = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        otherOwners: {
          numberOfOwners: this.state.numberOfOwners,
          client2: this.state.client2,
          client3: this.state.client3,
          client4: this.state.client4,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={this.props.clientIsCompany ? "Tell us about other owners of the property" : "Tell us about other people at the property"} removeTopPadding>
          <RadioQuestion
            question="Do you own the property with anyone else?"
            options={numberOfClientOptions}
            inline={false}
            name="numberOfOwners"
            selected={this.state.numberOfOwners}
            optionSelected={this.selectNumberOfOwners}
            validate={this.handleFieldValidation('numberOfOwners')}
            validationError={
              this.state.errors.numberOfOwners
                ? this.state.errors.numberOfOwners
                : null
            }
          />

          {this.state.numberOfOwners >= 2 && (
            <React.Fragment>
              <h3>
                We’ll need to get in touch with them — please enter their
                details below.
              </h3>
              <div data-testid="client-2">
                <h3>First person</h3>
                <TextQuestion
                  question="What’s their first name?"
                  value={this.state.client2.firstName}
                  valueEntered={this.handleClient2('firstName')}
                  validate={this.handleClient2FieldValidation('firstName')}
                  validationError={
                    this.state.errors.client2.firstName
                      ? this.state.errorMessages.client2.firstName
                      : null
                  }
                />
                <TextQuestion
                  question="What’s their last name?"
                  value={this.state.client2.lastName}
                  valueEntered={this.handleClient2('lastName')}
                  validate={this.handleClient2FieldValidation('lastName')}
                  validationError={
                    this.state.errors.client2.lastName
                      ? this.state.errorMessages.client2.lastName
                      : null
                  }
                />
                <TextQuestion
                  question="What’s their email address?"
                  value={this.state.client2.emailAddress}
                  valueEntered={this.handleClient2('emailAddress')}
                  validate={this.handleClient2EmailAddressValidation(
                    'emailAddress',
                  )}
                  validationError={
                    this.state.errors.client2.emailAddress
                      ? this.state.errorMessages.client2.emailAddress
                      : null
                  }
                />
              </div>
            </React.Fragment>
          )}

          {this.state.numberOfOwners >= 3 && (
            <React.Fragment>
              <h3>Second person</h3>
              <div data-testid="client-3">
                <TextQuestion
                  question="What’s their first name?"
                  value={this.state.client3.firstName}
                  valueEntered={this.handleClient3('firstName')}
                  validate={this.handleClient3FieldValidation('firstName')}
                  validationError={
                    this.state.errors.client3.firstName
                      ? this.state.errorMessages.client3.firstName
                      : null
                  }
                />
                <TextQuestion
                  question="What’s their last name?"
                  value={this.state.client3.lastName}
                  valueEntered={this.handleClient3('lastName')}
                  validate={this.handleClient3FieldValidation('lastName')}
                  validationError={
                    this.state.errors.client3.lastName
                      ? this.state.errorMessages.client3.lastName
                      : null
                  }
                />
                <TextQuestion
                  question="What’s their email address?"
                  value={this.state.client3.emailAddress}
                  valueEntered={this.handleClient3('emailAddress')}
                  validate={this.handleClient3EmailAddressValidation(
                    'emailAddress',
                  )}
                  validationError={
                    this.state.errors.client3.emailAddress
                      ? this.state.errorMessages.client3.emailAddress
                      : null
                  }
                />
              </div>
            </React.Fragment>
          )}

          {this.state.numberOfOwners === 4 && (
            <React.Fragment>
              <h3>Third person</h3>
              <div data-testid="client-4">
                <TextQuestion
                  question="What’s their first name?"
                  value={this.state.client4.firstName}
                  valueEntered={this.handleClient4('firstName')}
                  validate={this.handleClient4FieldValidation('firstName')}
                  validationError={
                    this.state.errors.client4.firstName
                      ? this.state.errorMessages.client4.firstName
                      : null
                  }
                />
                <TextQuestion
                  question="What’s their last name?"
                  value={this.state.client4.lastName}
                  valueEntered={this.handleClient4('lastName')}
                  validate={this.handleClient4FieldValidation('lastName')}
                  validationError={
                    this.state.errors.client4.lastName
                      ? this.state.errorMessages.client4.lastName
                      : null
                  }
                />
                <TextQuestion
                  question="What’s their email address?"
                  value={this.state.client4.emailAddress}
                  valueEntered={this.handleClient4('emailAddress')}
                  validate={this.handleClient4EmailAddressValidation(
                    'emailAddress',
                  )}
                  validationError={
                    this.state.errors.client4.emailAddress
                      ? this.state.errorMessages.client4.emailAddress
                      : null
                  }
                />
              </div>
            </React.Fragment>
          )}

          <Action onClick={this.saveOtherOwners}>Continue</Action>
        </Card>

        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default RemoOtherOwners;
