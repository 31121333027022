import React from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';
import CheckList, { CheckListItem } from '../../components/CheckList';
import List, { ListItem } from '../../components/List';
import ReferralCodeBanner from '../../components/ReferralCodeBanner';
import TrustpilotBanner from '../../components/TrustpilotBanner';
import { formatMoneyWithCurrency, moneyStrToNum } from '../../utils/moneyUtils';
import { ListItemSuffix, QuoteBreakdown, QuotedPrice, QuotedPriceWrapper } from "../QuoteCommon/QuoteStyles";


function QuoteDetailsPage(props) {

    const {
      pageWidth,
      referral,
      quoteDetails,
      buyingDetails,
      sellingDetails,
      remortgagingDetails,
      transactionType,
    } = props;

    let saleLegalFee = '';
    let salePropertyPrice = '';
    let purchaseLegalFee = '';
    let purchasePropertyPrice = '';
    let quotePrice = '';
    let stampDuty = '';
    let stampDutyDescription = 'Stamp Duty';
    let landRegistryFee = '';
    let companiesHouseRegistrationFee = '';
    let totalFeesAndTaxes = '';
    let sellingTenure = '';
    let buyingTenure = '';
    let remortgageLegalFee = '';
    let remortgageTenure = '';
    let remortgageAmount = '';
    let remortgageTitleInsured = '';
    let remortgageBuyToLet = ''
    let showLendInvestInclusions = false

    const formatQuotedAmount = (value, showDecimal) => {
      if (!value) return '';
      return formatMoneyWithCurrency(
        moneyStrToNum(value).toString(),
        showDecimal,
      );
    };

    let includeDecimal = landRegistryFee.includes('.') || stampDuty.includes('.');

    if (quoteDetails) {
      quotePrice = formatQuotedAmount(quoteDetails.totalPrice, includeDecimal);
      saleLegalFee = formatQuotedAmount(quoteDetails.sellingQuotePrice, includeDecimal);
      purchaseLegalFee = formatQuotedAmount(quoteDetails.buyingQuotePrice, includeDecimal);
      remortgageLegalFee = formatQuotedAmount(quoteDetails.remortgageQuotePrice, includeDecimal);
      stampDuty = formatQuotedAmount(quoteDetails.stampDutyFee, includeDecimal);
      landRegistryFee = formatQuotedAmount(quoteDetails.landRegistryFee, includeDecimal);
      companiesHouseRegistrationFee = formatQuotedAmount(quoteDetails.companiesHouseRegistrationFee, includeDecimal);
      totalFeesAndTaxes = formatQuotedAmount(quoteDetails.totalFeesAndTaxes, includeDecimal);

      if (buyingDetails && (!!buyingDetails.rightToBuyScheme || !!buyingDetails.sharedOwnership)) {
        stampDutyDescription = 'Stamp Duty (based on the full value of the property)';
      }

      purchasePropertyPrice = (transactionType === 'Buying' || transactionType === 'BuyingAndSelling')
        ? formatQuotedAmount(buyingDetails?.price, includeDecimal)
        : '';

      salePropertyPrice = (transactionType === 'Selling' || transactionType === 'BuyingAndSelling')
        ? formatQuotedAmount(sellingDetails?.price, includeDecimal)
        : '';

      remortgageAmount = (transactionType === 'Remortgaging')
        ? formatQuotedAmount(remortgagingDetails?.price, includeDecimal)
        : '';
    }

    function getTenure(details) {
      if (details?.tenure === 'leasehold with share of freehold') {
        return 'share of freehold';
      }
      return details.tenure;
    }

    if (transactionType === 'Selling' || transactionType === 'BuyingAndSelling') {
      sellingTenure = getTenure(sellingDetails);
    }

    if (transactionType === 'Buying' || transactionType === 'BuyingAndSelling') {
      buyingTenure = getTenure(buyingDetails);
    }

    if (transactionType === 'Remortgaging') {
      remortgageTenure = getTenure(remortgagingDetails);

      if (remortgagingDetails && quoteDetails.remoUsingTitleInsurance) {
        remortgageTitleInsured = 'title-insured';
      }

      if (remortgagingDetails?.isBuyToLet === true) {
        remortgageBuyToLet = 'buy-to-let';
      }
    }

    const transactionTypes = {
      'Buying': {
        typeOfPurchaseIncTenure: `${buyingTenure} purchase`,
        quotePriceText: `That’s our quote, including VAT, for legal work on a ${buyingTenure} purchase costing ${purchasePropertyPrice}.`
      },
      'Selling': {
        typeOfPurchaseIncTenure: `${sellingTenure} sale`,
        quotePriceText: `That’s our quote, including VAT, for legal work on a ${sellingTenure} sale costing ${salePropertyPrice}.`
      },
      'BuyingAndSelling': {
        typeOfPurchaseIncTenure: `${buyingTenure} purchase and ${sellingTenure} sale`,
        quotePriceText: `That’s our quote, including VAT, for legal work on a ${buyingTenure} purchase costing ${purchasePropertyPrice} and ${sellingTenure} sale costing ${salePropertyPrice}.`
      },
      'Remortgaging': {
        typeOfPurchaseIncTenure: `${remortgageTenure} remortgage`,
        quotePriceText: `That’s our quote, including VAT, for legal work switching to a ${remortgageAmount} ${remortgageTitleInsured} ${remortgageBuyToLet} mortgage on a ${remortgageTenure} property.`
      }
    };

    const { typeOfPurchaseIncTenure, quotePriceText } = transactionTypes[transactionType] || {};

    if (
      (!!remortgagingDetails && remortgagingDetails.mortgageLender === "LendInvest") ||
      (!!buyingDetails && buyingDetails.mortgageLender === "LendInvest")
    ) {
      showLendInvestInclusions = true
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Here’s your conveyancing quote" removeTopPadding>
          <QuotedPriceWrapper>
            <QuotedPrice>{quotePrice}</QuotedPrice>
            <p>{quotePriceText}</p>

            <CheckList>
              <CheckListItem>Simpler, clearer, faster</CheckListItem>
              {(transactionType === 'BuyingAndSelling') &&
                <CheckListItem>No sale, no fee</CheckListItem>
              }
              {(transactionType === 'Selling' ||
                transactionType === 'BuyingAndSelling' ||
                transactionType === 'Buying') &&
                <CheckListItem>Second chance guarantee</CheckListItem>
              }
              {transactionType === 'Remortgaging' &&
                <CheckListItem>No completion, no fee</CheckListItem>
              }
              <CheckListItem>No hidden fees</CheckListItem>
            </CheckList>
          </QuotedPriceWrapper>

          {referral &&
            <ReferralCodeBanner text={referral.quoteMessage} withinQuote={true} />
          }

          {(transactionType === 'Buying' ||
            transactionType === 'BuyingAndSelling') &&
            <QuoteBreakdown>
              <List>
                {transactionType === 'BuyingAndSelling' ? (
                  <>
                    <ListItem divider>
                      Legal fee on your {sellingTenure} sale
                      <ListItemSuffix>{saleLegalFee}</ListItemSuffix>
                    </ListItem>
                    <ListItem divider>
                      Legal fee on your {buyingTenure} purchase
                      <ListItemSuffix>{purchaseLegalFee}</ListItemSuffix>
                    </ListItem>
                  </>
                ) : (
                  <ListItem divider>
                    Legal fee on your {buyingTenure} purchase
                    <ListItemSuffix data-testid="up-front-payment">
                      {purchaseLegalFee}
                    </ListItemSuffix>
                  </ListItem>
                )}

                <ListItem divider>
                  Land Registry fee
                  <ListItemSuffix data-testid="land-registry-fee">
                    {landRegistryFee}
                  </ListItemSuffix>
                </ListItem>
                <ListItem divider>
                  {stampDutyDescription}
                  <ListItemSuffix data-testid="stamp-duty-fee">
                    {stampDuty}
                  </ListItemSuffix>
                </ListItem>
                {buyingDetails.clientIsCompany &&
                  <ListItem divider>
                    Companies house registration fee
                    <ListItemSuffix data-testid="companies-house-registration-fee">
                      {companiesHouseRegistrationFee}
                    </ListItemSuffix>
                  </ListItem>
                }

                <ListItem divider>
                  Total including government fees and taxes
                  <ListItemSuffix data-testid="total-fees-and-taxes">
                    {totalFeesAndTaxes}
                  </ListItemSuffix>
                </ListItem>
              </List>
            </QuoteBreakdown>
          }

          {transactionType === 'Remortgaging' &&
            <QuoteBreakdown>
              <List>
                <ListItem divider>
                  Legal fee for switching your mortgage
                  <ListItemSuffix data-testid="no-search-indemnity-fee">
                    {remortgageLegalFee}
                  </ListItemSuffix>
                </ListItem>
                <ListItem divider>
                  Land Registry fee
                  <ListItemSuffix data-testid="land-registry-fee">
                    {landRegistryFee}
                  </ListItemSuffix>
                </ListItem>

                {remortgagingDetails.clientIsCompany &&
                  <ListItem divider>
                    Companies house registration fee
                    <ListItemSuffix data-testid="companies-house-registration-fee">
                      {companiesHouseRegistrationFee}
                    </ListItemSuffix>
                  </ListItem>
                }
                <ListItem divider>
                  Total including government fees and taxes
                  <ListItemSuffix data-testid="total-fees-and-taxes">
                    {totalFeesAndTaxes}
                  </ListItemSuffix>
                </ListItem>
              </List>
            </QuoteBreakdown>
          }

        </Card>


        {(transactionType === "Buying" ||
          transactionType === "Selling" ||
          transactionType === 'BuyingAndSelling') &&
          <Card
            title="Check your email for your full quote"
            removeTopPadding
            headingSize="3"
          >
            <p>We’ve sent you a full breakdown of your fees and what’s included.</p>

            <p>If you’re happy it’s simple to sign up – just follow the link in your email and you’ll have a legal team of your own in minutes.</p>

            <p>We’ve also shared full details about our Second Chance Guarantee, Mortgage Offer Protection and our Guaranteed Pricing.</p>

          </Card>
        }

        {transactionType === 'Remortgaging' && quoteDetails.remoUsingTitleInsurance &&
          <Card title="This quote is for a remortgage using title insurance" headingLevel="3" removeTopPadding>
            <p>We’ll do your remortgage using a title insurance policy – this is much quicker and more cost-effective than ordering a full set of search reports.</p>
            <p>The cost of this insurance policy is already covered – we don’t charge anything extra.</p>
            <p>If, when we receive your mortgage offer, your lender requires us to order a full set of searches, we’ll charge an extra £300 inc VAT to order and review these.</p>
          </Card>
        }

        {transactionType === 'Remortgaging' && remortgagingDetails.mortgageLender === "Other" &&
          <Card title="This quote is for a remortgage using no-search indemnity insurance" headingLevel="3" removeTopPadding>
            <p>We’ll do your remortgage using a no-search indemnity policy – this is much quicker and more cost-effective than ordering a full set of search reports.</p>
            <p>The cost of this insurance policy is already covered – we don’t charge anything extra.</p>
            <p>If the lender requires us to carry out full searches, we will charge an additional £300 (inc. VAT) to order and process these.</p>
          </Card>
        }

        {transactionType === 'Remortgaging' &&
          <Card
            title="Paying your legal fee"
            removeTopPadding
            headingSize="3"
          >
            <p>You don’t have to pay us anything until we receive your mortgage offer – we’ll get started on your ID checks and case setup right away.</p>
            <p>Once we receive your mortgage offer, we’ll ask you to pay £399 up front. We’ll then deduct the remainder from your mortgage on completion.</p>
          </Card>
        }

        {showLendInvestInclusions && transactionType === 'Remortgaging' &&
          <Card title="Additional charges" headingLevel="3" removeTopPadding>
            <p>To prepare your new mortgage with LendInvest, we’ll charge the following additional fees:</p>
            <List bullet>
              <ListItem>Legal work on your client's {typeOfPurchaseIncTenure}</ListItem>
              <ListItem>ID checks – £18 per person</ListItem>
              {remortgagingDetails.clientIsCompany &&
                <ListItem>Company ID check – £30</ListItem>
              }
              <ListItem>Environmental reports – £29.60</ListItem>
              <ListItem>Law firm bank details check fee – £24</ListItem>
              <ListItem>Bank transfers – £42, two included in our fee</ListItem>
            </List>
          </Card>
        }

        {showLendInvestInclusions && (
          transactionType === "Buying" ||
          transactionType === "BuyingAndSelling"
        ) &&
          <Card title="Additional charges" headingLevel="3" removeTopPadding>
            <p>To prepare your purchase with LendInvest, we’ll charge the following additional fees:</p>
            <List bullet>
              <ListItem>Legal work on your client's {typeOfPurchaseIncTenure}</ListItem>
              <ListItem>ID checks – £18 per person</ListItem>
              {buyingDetails.clientIsCompany &&
                <ListItem>Company ID check – £30</ListItem>
              }
              <ListItem>Searches – £177.40</ListItem>
              <ListItem>Law firm bank details check fee – £24</ListItem>
              <ListItem>Stamp Duty return fee – £114</ListItem>
              <ListItem>Bank transfers – £42, three included in our fee</ListItem>
            </List>
          </Card>
        }

        <TrustpilotBanner pageWidth={pageWidth} />
      </React.Fragment >
    );
}

export default QuoteDetailsPage;
