import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useClientAppFormRequest from '../../hooks/useClientAppFormRequest';

import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import StepFlowNavigate from '../../components/StepFlowNavigate';
import SharedOwnershipPurchaseIntro from './SharedOwnershipPurchaseIntro';
import SharedOwnershipPurchaseShare from './SharedOwnershipPurchaseShare';
import SharedOwnershipPurchaseHousingAssociation from './SharedOwnershipPurchaseHousingAssociation';
import SharedOwnershipPurchaseSubmission from './SharedOwnershipPurchaseSubmission';
import SharedOwnershipPurchaseThanks from './SharedOwnershipPurchaseThanks';

const SharedOwnershipPurchaseFlow = (props) => {
  const location = useLocation();
  const { requestId } = useParams();

  const [fromDashboard, setFromDashboard] = useState(false);
  const [flowCompleted, setFlowCompleted] = useState(false);

  const { loading, error, requestDetails, submitted } = useClientAppFormRequest(`/api/sharedOwnershipPurchase/${requestId}`);

  // Set fromDashboard state on initial load
  useEffect(() => {
    const { fromDashboard } = location.state || {};
    setFromDashboard(fromDashboard);
  }, []);

  if (!!loading) {
    return null;
  }

  if (submitted || flowCompleted) {
    return <SharedOwnershipPurchaseThanks fromDashboard={fromDashboard} />;
  }

  if (error) {
    return (
      <PageLayout>
        <ScrollToTop />
        <Card title="Sorry, that didn't work" removeTopPadding>
          {error}
        </Card>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <StepFlowNavigate
        initialFlowState={{
          formRequestId: requestDetails?.formRequestId,
        }}
        steps={[
          {
            slug: 'intro',
            component: flowState => <SharedOwnershipPurchaseIntro />,
          },
          {
            slug: 'property-share',
            component: flowState => (
              <SharedOwnershipPurchaseShare
                shareDetails={flowState.shareDetails}
              />
            ),
          },
          {
            slug: 'housing-association',
            component: flowState => (
              <SharedOwnershipPurchaseHousingAssociation
                housingAssociation={flowState.housingAssociation}
              />
            ),
          },
          {
            slug: 'submit',
            component: flowState => (
              <SharedOwnershipPurchaseSubmission
                sharedOwnershipPurchaseFlowState={flowState}
                onFlowComplete={() => setFlowCompleted(true)}
              />
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

export default SharedOwnershipPurchaseFlow;
