import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useSelectedCase } from "./utils/hooks";
import ScrollToTop from '../../components/utils/ScrollToTop';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/DashboardCard';
import {describeTransactionType} from "../../utils/textUtils";
import styled from 'styled-components/macro';
import {mediaQueries} from '../../styles/media';
import { Drafts } from '@styled-icons/material';
import {getFormattedDate} from "../../utils/textUtils";

const Notification = () => {
  const { notificationId } = useParams();

  const sessionType = useSelector(state => state.dashboardState.session.session_type);
  const selectedCase = useSelectedCase({caseScreen: 'notification'});

  const notification = selectedCase.notifications.find(n => n.id === notificationId);

  useEffect(() => {
    // Update client app db, set notification status to 'read'
    if (sessionType === "client" && notification.status === "unread") {
      fetch(`/api/dashboard/notification/${selectedCase.id}/${notification.id}`, {
        method: 'PUT',
        credentials: 'same-origin'
      }).then(resp => {
        if (resp.error) {
          console.log("error updating notification", resp.error)
        }
      }).catch(() => {
        console.error('error updating notification');
      });
    }
  }, [sessionType, selectedCase.id, notification]);

  const createContent = () => {
    const notificationContent = notification.content;
    return {__html: notificationContent};
  };

  let address = ''
  if (!!selectedCase.address) {
    address = 'of ' + selectedCase.address
  }

  const cardTitle = `Your ${describeTransactionType(selectedCase.case_type)} ${address} (${selectedCase.case_reference})`;

  return (
    <React.Fragment>
      <ScrollToTop />
      <PageHeader>
        {cardTitle}
      </PageHeader>
      <Card title={null}>

        <StyledNotificationHeader>

          <StyledSmallScreenHeader>

            <StyledSmallScreenHeaderInner>
              <StyledNotificationIcon>
                <Drafts size="20" />
              </StyledNotificationIcon>
              {getFormattedDate(notification.created_at)}
            </StyledSmallScreenHeaderInner>

            <h2>{notification.subject}</h2>

          </StyledSmallScreenHeader>


          <StyledLargeScreenHeader>

            <StyledNotificationIcon>
              <Drafts size="24" />
            </StyledNotificationIcon>

            <h2>{notification.subject}</h2>

            <StyledNotificationDate>
              {getFormattedDate(notification.created_at)}
            </StyledNotificationDate>

          </StyledLargeScreenHeader>

        </StyledNotificationHeader>

        <StyledNotificationContent dangerouslySetInnerHTML={createContent()} />

      </Card>
    </React.Fragment>
  )
};

export default Notification

const StyledSmallScreenHeader = styled.div`
  ${mediaQueries.md} {
    display: none;
  }
`

const StyledSmallScreenHeaderInner = styled.div`
  display: flex;
  align-items: center;

  margin-top: 16px;
  margin-bottom: 8px;
`

const StyledLargeScreenHeader = styled.div`
  display: none;

  ${mediaQueries.md} {
    display: flex;
    align-items: center;
  }

`

const StyledNotificationHeader = styled.div`
  padding: 0 0 10px 0;
  margin-bottom: 1.5em;

  color: ${props => props.theme.color.junoText};
  font-weight: ${props => props.theme.fontWeight.bold};
  border-bottom: 1px solid ${props => props.read ? props.theme.color.white : props.theme.color.junoBorder};

  h2 {
    margin: 0;
    font-size: 18px;
    ${mediaQueries.md} {
      font-size: 20px;
      max-width: 75%
    }
    line-height: 1.25;
  }
`;

export const StyledNotificationIcon = styled.i`
  flex-shrink: 0;
  color: ${props => props.read ? '#1e9952' : 'inherit'};

  /* stylelint-disable no-descending-specificity */
  padding-right: 8px;
  ${mediaQueries.md} {
    padding-right: 16px;
  }
  /* stylelint-enable no-descending-specificity */
`;

const StyledNotificationDate = styled.div`
  margin-left: auto;
  text-align: right;
`;

const StyledNotificationContent = styled.div`
  ul {
    font-size: ${props => props.theme.fontSizes.p['sm']};
    line-height: 1.5;
    margin-bottom: 1em;
    ${mediaQueries.md} {
      font-size: ${props => props.theme.fontSizes.p['md']};
    }
  }

  ${mediaQueries.md} {
    width: 75%;
  }
`;
