import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import StepFlow from '../../components/StepFlow';
import EsignatureRequestIntro from "./EsignatureRequestIntro";
import EsignatureRequestSMSVerification from "./EsignatureRequestSMSVerification";
import PerformHellosignSignature from "./PerformHellosignSignature";
import EsignatureRequestThanks from "./EsignatureRequestThanks";

const EsignatureRequestFlow = (props) => {
  const location = useLocation()
  const { fromDashboard } = location.state || {};
  const { requestId } = useParams()

  const [state, setState] = useState({
    loading: true,
    error: null,
    title: null,
    requestDetails: null,
  });

  useEffect(() => {
    fetch('/api/esignature/' + requestId)
      .then(resp => resp.json())
      .then(
        data => {
          if (!!data.isValid) {
            setState({
              loading: false,
              title: data.title || 'Please sign this document',
              requestDetails: data.config,
            });
          } else if (data.error === 'expired') {
            setState({
              loading: false,
              error: 'This request has expired',
            });
          } else if (data.error === 'submitted') {
            setState({
              loading: false,
              error: "It looks like you've already signed this document",
            });
          } else {
            setState({
              loading: false,
              error: 'Failed to load request details',
            });
          }
        },
        () => {
          setState({
            loading: false,
            error: 'Failed to load request details',
          });
        },
      );
  }, [requestId]);

    if (state.loading) {
      return null;
    }

    if (state.error) {
      return (
        <PageLayout>
          <ScrollToTop />
          <Card title="Sorry, that didn't work" removeTopPadding>
            {state.error}
          </Card>
        </PageLayout>
      );
    }

    return (
      <PageLayout>
      <StepFlow
        initialFlowState={{
          formRequestId: requestId,
          title: state.title,
          smsVerificationRequired: state.requestDetails.sms_verification_required,
          witnessSignature: state.requestDetails.witness_signature,
          witnessedSignature: state.requestDetails.witnessed_signature,
          fromDashboard: fromDashboard,
        }}
        steps={[
          {
            name: 'Intro',
            component: flowState => <EsignatureRequestIntro flowState={flowState} />,
          },
          {
            name: 'Verify SMS code',
            component: flowState => <EsignatureRequestSMSVerification flowState={flowState} />,
            valid: flowState => flowState.smsVerificationRequired,
          },
          {
            name: 'Perform identity check',
            component: flowState => <PerformHellosignSignature flowState={flowState} />,
          },
          {
            name: 'Thanks',
            component: flowState => <EsignatureRequestThanks flowState={flowState} fromDashboard={flowState.fromDashboard} />,
            terminal: true,
          },
        ]}
      />
      </PageLayout>
    );
}

export default EsignatureRequestFlow;
