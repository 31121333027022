import React, { Component } from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';
import RadioQuestion from '../../components/RadioQuestion';
import { YES_NO_OPTIONS } from '../../constants';
import RadioConditional from '../../components/RadioConditional';
import TextQuestion from '../../components/TextQuestion';
import Action from '../../components/Action';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class RemoMortgageBroker extends Component {
  constructor(props) {
    super(props);

    if (this.props.mortgageBroker) {
      this.state = {
        hasMortgageBroker: this.props.mortgageBroker.hasMortgageBroker,
        mortgageBroker: {
          name: this.props.mortgageBroker.name,
          emailAddress: this.props.mortgageBroker.emailAddress,
          phoneNumber: this.props.mortgageBroker.phoneNumber,
        },
        errors: {},
        errorMessages: {
          hasMortgageBroker: 'Please choose an option',
          mortgageBroker: {
            name: 'Enter their full name',
            emailAddress: 'Enter their email address',
            phoneNumber: 'Enter their phone number',
          },
        },
      };
    } else {
      this.state = {
        hasMortgageBroker: null,
        mortgageBroker: {
          name: '',
          emailAddress: '',
          phoneNumber: '',
        },
        errors: {},
        errorMessages: {
          hasMortgageBroker: 'Please choose an option',
          mortgageBroker: {
            name: 'Enter their full name',
            emailAddress: 'Enter their email address',
            phoneNumber: 'Enter their phone number',
          },
        },
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const mortgageBrokerFields = { ...this.state.mortgageBroker };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (fields['hasMortgageBroker'] === null) {
      const field = 'hasMortgageBroker';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['hasMortgageBroker'] === true) {
      if (!mortgageBrokerFields['name']) {
        formIsValid = false;
        errors['name'] = errorMessages.mortgageBroker['name'];
      }
      if (!mortgageBrokerFields['emailAddress']) {
        formIsValid = false;
        errors['emailAddress'] = errorMessages.mortgageBroker['emailAddress'];
      }
      if (!mortgageBrokerFields['phoneNumber']) {
        formIsValid = false;
        errors['phoneNumber'] = errorMessages.mortgageBroker['phoneNumber'];
      }
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }
    this.setState({ errors });
  };

  handleMortgageBrokerFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = {
      ...this.state.errorMessages,
    };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state.mortgageBroker[field]) {
        errors[field] = errorMessages.mortgageBroker[field];
      }
    }
    this.setState({ errors });
  };

  handleMortgageBrokerChange = field => event => {
    let mortgageBroker = { ...this.state.mortgageBroker };
    mortgageBroker[field] = event;
    this.setState({ mortgageBroker });
  };

  selectHasMortgageBroker = hmb => {
    this.setState({ hasMortgageBroker: hmb });
  };

  saveMortgageBroker = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      if (this.state.hasMortgageBroker) {
        this.props.onStepComplete({
          mortgageBroker: {
            hasMortgageBroker: this.state.hasMortgageBroker,
            name: this.state.mortgageBroker.name,
            phoneNumber: this.state.mortgageBroker.phoneNumber,
            emailAddress: this.state.mortgageBroker.emailAddress,
          },
        });
      } else {
        this.props.onStepComplete({
          mortgageBroker: {
            hasMortgageBroker: this.state.hasMortgageBroker,
          },
        });
      }
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us if you’re using a mortgage broker" removeTopPadding>
          <RadioQuestion
            question="Are you going through a mortgage broker?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="mortgageBroker"
            selected={this.state.hasMortgageBroker}
            optionSelected={this.selectHasMortgageBroker}
            validate={this.handleFieldValidation('hasMortgageBroker')}
            validationError={
              this.state.errors.hasMortgageBroker
                ? this.state.errors.hasMortgageBroker
                : null
            }
          />

          {this.state.hasMortgageBroker && (
            <RadioConditional>
              <p>Great, we'll get in touch with them to introduce ourselves.</p>

              <TextQuestion
                question="Who’s handling your remortgage?"
                hint="If there’s more than one person, who do you speak to the most?"
                value={this.state.mortgageBroker.name || ''}
                valueEntered={this.handleMortgageBrokerChange('name')}
                validate={this.handleMortgageBrokerFieldValidation('name')}
                validationError={
                  this.state.errors.name ? this.state.errors.name : null
                }
              />

              <TextQuestion
                question="What’s their email address?"
                value={this.state.mortgageBroker.emailAddress}
                valueEntered={this.handleMortgageBrokerChange('emailAddress')}
                validate={this.handleMortgageBrokerFieldValidation(
                  'emailAddress',
                )}
                validationError={
                  this.state.errors.emailAddress
                    ? this.state.errors.emailAddress
                    : null
                }
              />

              <TextQuestion
                question="What’s their phone number?"
                value={this.state.mortgageBroker.phoneNumber}
                valueEntered={this.handleMortgageBrokerChange('phoneNumber')}
                validate={this.handleMortgageBrokerFieldValidation(
                  'phoneNumber',
                )}
                validationError={
                  this.state.errors.phoneNumber
                    ? this.state.errors.phoneNumber
                    : null
                }
              />
            </RadioConditional>
          )}

          <Action onClick={this.saveMortgageBroker}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default RemoMortgageBroker;
