import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

class MoreInformationIntro extends Component {
  render() {
    let introText;
    if (this.props.transactionType === 'Remortgaging') {
      introText = 'finalise your mortgage';
    } else {
      introText = 'prepare any contracts';
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="We need a bit more information about you" removeTopPadding>
          <p>
            As we work on your case, we need to find out a bit more about you.
            This is so we can carry out routine checks, which we have to do
            before we {introText}.
          </p>
          <p>
            It should only take a couple of minutes. If we need anything else
            we’ll follow-up over email.
          </p>
          <Action onClick={() => this.props.onStepComplete({})}>
            Let’s get started
          </Action>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default MoreInformationIntro;
