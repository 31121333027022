import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Action from '../../components/Action';
import Card from '../../components/Card';
import {TextareaQuestion} from '../../components/TextQuestion';
import List, {ListItem} from '../../components/List';
import ScrollToTop from '../../components/utils/ScrollToTop';

class ReferrerAnythingElse extends Component {
  constructor(props) {
    super(props);

    if (this.props.additionalInformation) {
      this.state = {
        additionalInformation: this.props.additionalInformation,
      };
    } else {
      this.state = {
        additionalInformation: '',
      };
    }
  }

  additionalInformationEntered = ai => {
    this.setState({additionalInformation: ai});
  };

  saveAndSendQuote = () => {
    this.props.onStepComplete({
      additionalInformation: this.state.additionalInformation,
    });
  };

  render() {
    const transactionType = this.props.transactionType;
    let tt;

    if (transactionType === 'Buying') {
      tt = 'purchase';
    }
    if (transactionType === 'BuyingAndSelling') {
      tt = 'sale and purchase';
    }
    if (transactionType === 'Selling') {
      tt = 'sale';
    }
    if (transactionType === 'Remortgaging') {
      tt = 'remortgage';
    }
    if (transactionType === 'SecondCharge') {
      tt = 'second charge';
    }

    let cardTitle =
      'Is there anything else you’d like to tell us about the ' + tt + '?';

    return (
      <React.Fragment>
        <ScrollToTop/>
        <Card title={cardTitle} removeTopPadding>
          <React.Fragment>
            <p>For example,</p>
            <List bullet>
              <ListItem>
                Is there a second client we could also get in touch with?
              </ListItem>
              {transactionType === 'Selling' && (
                <ListItem>Have they accepted an offer?</ListItem>
              )}
              {transactionType === 'Buying' && (
                <ListItem>Have they had an offer accepted?</ListItem>
              )}
              {transactionType === 'BuyingAndSelling' && (
                <ListItem>
                  Have they accepted an offer and had an offer accepted?
                </ListItem>
              )}
              <ListItem>
                Is there any reason they need to move quickly or slow things
                down?
              </ListItem>
            </List>
          </React.Fragment>

          <TextareaQuestion
            question="Anything else?"
            optional={true}
            value={this.state.additionalInformation}
            valueEntered={this.additionalInformationEntered}
          />

          {!this.props.showTermsLink ? null : (
            <p>
              By clicking the button below, you agree to
              <Link to="/refer/terms" target="_blank">
                {' '}
                our terms for referring customers to Juno
              </Link>
              .
            </p>
          )}

          <Action onClick={this.saveAndSendQuote}>{this.props.buttonText}</Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default ReferrerAnythingElse;
