import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import CheckboxQuestion from '../../components/CheckboxQuestion';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { SELLING_REJECTION_OPTIONS, YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class SellingPropertyDetails extends Component {
  constructor(props) {
    super(props);

    if (this.props.sellingPropertyOfferAccepted !== undefined) {
      this.state = {
        propertyOfferAccepted: this.props.sellingPropertyOfferAccepted,
        sellingRejectionReason: this.props.sellingRejectionReason
          ? this.props.sellingRejectionReason
          : new Map(),
        errors: {},
      };
    } else {
      this.state = {
        propertyOfferAccepted: null,
        sellingRejectionReason: new Map(),
        errors: {},
        errorMessages: {
          propertyOfferAccepted: 'Let us know if you have accepted an offer',
        },
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (fields['propertyOfferAccepted'] === null) {
      const field = 'propertyOfferAccepted';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }
    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }
    this.setState({ errors });
  };

  selectPropertyOffer = po => {
    this.setState({ propertyOfferAccepted: po });
  };

  handleSellingRejectionReason = event => {
    const item = event.target.name;
    const isChecked = event.target.checked;
    this.setState(prevState => ({
      sellingRejectionReason: prevState.sellingRejectionReason.set(
        item,
        isChecked,
      ),
    }));
  };

  shouldReject = () => {
    for (let checked of this.state.sellingRejectionReason.values()) {
      if (checked === true) {
        return true;
      }
    }
    return false;
  };

  saveSellingPropertyDetails = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        sellingPropertyOfferAccepted: this.state.propertyOfferAccepted,
        sellingRejectionReason: this.state.sellingRejectionReason,
        shouldReject: this.shouldReject(),
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="To begin, tell us about the property you’re selling" removeTopPadding>
          <RadioQuestion
            question="Have you accepted an offer on it yet?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="propertyOfferAccepted"
            selected={this.state.propertyOfferAccepted}
            optionSelected={this.selectPropertyOffer}
            validate={this.handleFieldValidation('propertyOfferAccepted')}
            validationError={
              this.state.errors.propertyOfferAccepted
                ? this.state.errors.propertyOfferAccepted
                : null
            }
          />

          <CheckboxQuestion
            question="Can you tell us if..."
            options={SELLING_REJECTION_OPTIONS}
            inline={false}
            name={SELLING_REJECTION_OPTIONS.name}
            selected={this.state.sellingRejectionReason}
            onChange={this.handleSellingRejectionReason}
          />

          <Action onClick={this.saveSellingPropertyDetails}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default SellingPropertyDetails;
