import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import PageLayout from '../../components/PageLayout';
import TransactionTypePicker from './TransactionTypePicker';
import QuoteDetailsPage from './QuoteDetailsPage';
import QuoteSubmission from './QuoteSubmission';
import QuoteSorryPage from './QuoteSorryPage';
import QuoteBuyingProperty from '../QuoteCommon/QuoteBuyingProperty';
import { INSURANCE_LIMIT } from '../../constants';
import { selectGAClientId } from '../../features/analytics/analyticsSlice';

import { parseChannel, userSkippedTransactionTypePicker } from '../../utils/queryStringUtils';
import StepFlowNavigate from '../../components/StepFlowNavigate';
import SaveQuoteForm from './SaveQuoteForm';
import QuoteSellingProperty from '../QuoteCommon/QuoteSellingProperty';
import QuoteRemortgagingProperty from '../QuoteCommon/QuoteRemortgagingProperty';
import { BuyingMortgageLender, RemortgagingMortgageLender } from "../QuoteCommon/MortgageLender";
import QuoteBuyingPropertyMortgageAmount from "../QuoteCommon/QuoteBuyingPropertyMortgageAmount";

const QuoteFlow = (props) => {

  const { referralCode } = useParams()
  const location = useLocation()
  const [searchParams] = useSearchParams();
  const [flowCompleted, setFlowCompleted] = useState(false);
  const [submittedFlowState, setSubmittedFlowState] = useState(null);

  // FIXME: move this into components that use it once they've been converted
  // to functional components.
  const googleAnalyticsId = useSelector(selectGAClientId);

  const[state, setState] = useState({
    loading: false,
    error: null,
    referral: null,
    referralLocked: false,
    defaultChannel: parseChannel(location, "juno.legal"),
    defaultReferrer: null,
    campaign: searchParams.get('utm_campaign'),
    transactionType: searchParams.get('transaction'),
  });

  useEffect(() => {

    if (!flowCompleted && !!referralCode) {
      setState(prevState => ({ ...prevState, loading: true, referralLocked: true }));

      fetch('/api/referralCode/' + referralCode)
        .then(resp => resp.json())
        .then(
          referralData => {
            if (referralData.referralCodeValid) {
              const referral = {
                referralCode: referralCode,
                discountAmount: referralData.discountAmount,
                discountMessage: referralData.discountMessage,
                quoteMessage: referralData.quoteMessage,
                referralSource: referralData.referralSource,
              };

              setState(prevState => ({ ...prevState, loading: false, referral }));
            } else {
              setState(prevState => ({
                ...prevState,
                loading: false,
                referral: null,
                defaultChannel: 'estate_agent_referrals',
                defaultReferrer: referralCode,
              }));
            }
          },
          () => {
            setState(prevState => ({ ...prevState, loading: false }));
          },
        );
    }
  }, [referralCode, flowCompleted]);

  const shouldShowTransactionPicker = () => {
    return state.transactionType === null;
  };

  const shouldShowSellingQuoteDetailsForm = transactionType => {
    return (
      transactionType === 'Selling' || transactionType === 'BuyingAndSelling'
    );
  };

  const shouldShowBuyingQuoteDetailsForm = transactionType => {
    return (
      transactionType === 'Buying' || transactionType === 'BuyingAndSelling'
    );
  };

  const shouldShowRemortgageQuoteDetailsForm = transactionType => {
    return transactionType === 'Remortgaging';
  };

  const shouldReject = (details) => {
    if (details.price >= INSURANCE_LIMIT) {
      return true;
    }

    // if (details.clientIsCompany && details.buyToLet) {
    if (details.clientIsCompany) {
      return true;
    }

    return !!details.shouldReject || !!details.sharedOwnership || !!details.newBuild || !!details.divorceOrSeparation || !!details.retirementProperty;
  };

  const shouldShowRejectionPage = (transactionDetails) => {
    return shouldReject({ ...transactionDetails });
  };

  const shouldShowBuyingRejectionPage = (transactionType, transactionDetails) => {
    let details = { ...transactionDetails };

    if (
      (transactionType === 'Buying' || transactionType === 'BuyingAndSelling') &&
      (details.tenure === 'leasehold' || details.tenure === 'leasehold with share of freehold')
    ) {
      return true;
    }

    return shouldReject(details);
  };

  const shouldShowBuyingMortgageLender = transactionDetails => {
    return transactionDetails && !!transactionDetails.buyToLet
  };

  const shouldShowBuyingMortgageAmount = transactionDetails => {
    return transactionDetails && !!transactionDetails.buyToLet && transactionDetails.mortgageLender === 'LendInvest'
  };

  if (state.loading) {
    return null
  }

  const pageWidth = "wider"

  if (flowCompleted) {
    return (
      <PageLayout pageWidth={pageWidth}>
        <QuoteDetailsPage
          pageWidth={pageWidth}
          referral={submittedFlowState?.referral}
          buyingDetails={submittedFlowState?.buyingDetails}
          sellingDetails={submittedFlowState?.sellingDetails}
          remortgagingDetails={submittedFlowState?.remortgagingDetails}
          transactionType={submittedFlowState.transactionType}
          quoteDetails={submittedFlowState.quoteDetails} />
      </PageLayout>
    );
  }

  return (
    <PageLayout pageWidth={pageWidth}>
      <StepFlowNavigate
        initialFlowState={{
          transactionType: state.transactionType,
          personalDetails: null,
          sellingDetails: null,
          buyingDetails: null,
          referral: state.referral,
        }}
        steps={[
          {
            name: 'Transaction type',
            slug: 'get-a-quote',
            valid: () => shouldShowTransactionPicker(),
            component: flowState => (
              <TransactionTypePicker
                transactionType={flowState.transactionType}
                campaign={state.campaign}
                referral={flowState.referral}
                pageWidth={pageWidth}
              />
            ),
          },
          {
            name: 'Selling Quote details',
            slug: 'selling-quote',
            isFirstPage: flowState =>
              ['Selling'].includes(flowState.transactionType),
            valid: flowState => shouldShowSellingQuoteDetailsForm(flowState.transactionType),
            component: flowState => (
              <QuoteSellingProperty
                mode="direct"
                transactionType={flowState.transactionType}
                sellingDetails={flowState.sellingDetails}
                referral={flowState.referral}
                referralLocked={state.referralLocked}
                campaign={state.campaign}
                showLandingCopy={
                  userSkippedTransactionTypePicker(location) &&
                  ['Selling'].includes(flowState.transactionType)
                }
                pageWidth={pageWidth}
              />
            ),
          },
          {
            name: 'Selling sorry page',
            slug: 'selling-sorry',
            valid: flowState => shouldShowRejectionPage(flowState.sellingDetails),
            component: flowState => (
              <QuoteSorryPage
                transactionType={flowState.transactionType}
                transactionDetails={flowState.sellingDetails}
                referral={flowState.referral}
                rejectQuote="selling"
              />
            ),
          },
          {
            name: 'Buying Quote details',
            slug: 'buying-quote',
            valid: flowState => shouldShowBuyingQuoteDetailsForm(flowState.transactionType),
            isFirstPage: flowState =>
              ['Buying', 'BuyingAndSelling'].includes(
                flowState.transactionType,
              ),
            component: flowState => (
              <QuoteBuyingProperty
                mode="direct"
                transactionType={flowState.transactionType}
                buyingDetails={flowState.buyingDetails}
                referral={flowState.referral}
                referralLocked={state.referralLocked}
                campaign={state.campaign}
                showLandingCopy={
                  userSkippedTransactionTypePicker(location) &&
                  ['Buying', 'BuyingAndSelling'].includes(
                    flowState.transactionType,
                  )
                }
                pageWidth={pageWidth}
              />
            ),
          },
          {
            name: 'Buying sorry page',
            slug: 'buying-sorry',
            valid: flowState => shouldShowBuyingRejectionPage(flowState.transactionType, flowState.buyingDetails),
            component: flowState => (
              <QuoteSorryPage
                transactionType={flowState.transactionType}
                transactionDetails={flowState.buyingDetails}
                referral={flowState.referral}
                rejectQuote="buying"
              />
            ),
          },
          {
            name: 'Buying mortgage lender',
            slug: 'buying-mortgage-lender',
            valid: flowState => shouldShowBuyingMortgageLender(
              flowState.buyingDetails
            ),
            component: flowState => (
              <BuyingMortgageLender
                mode="direct"
                buyingDetails={flowState.buyingDetails}
              />
            )
          },
          {
            name: 'Buying mortgage amount',
            slug: 'buying-mortgage-amount',
            valid: flowState => shouldShowBuyingMortgageAmount(
              flowState.buyingDetails
            ),
            component: flowState => (
              <QuoteBuyingPropertyMortgageAmount
                mode="direct"
                buyingDetails={flowState.buyingDetails}
              />
            )
          },
          {
            name: 'Remortgaging details',
            slug: 'remortgaging-quote',
            valid: flowState =>
              shouldShowRemortgageQuoteDetailsForm(
                flowState.transactionType,
              ),
            isFirstPage: flowState => userSkippedTransactionTypePicker(location),
            component: flowState => (
              <QuoteRemortgagingProperty
                mode="direct"
                transactionType={flowState.transactionType}
                remortgagingDetails={flowState.remortgagingDetails}
                referral={flowState.referral}
                referralLocked={state.referralLocked}
                campaign={state.campaign}
                showLandingCopy={userSkippedTransactionTypePicker(location)}
                pageWidth={pageWidth}
              />
            ),
          },
          {
            name: 'Remortgaging sorry page',
            slug: 'remortgaging-sorry',
            valid: flowState =>
            shouldShowRejectionPage(flowState.remortgagingDetails),
            component: flowState => (
              <QuoteSorryPage
                transactionType={flowState.transactionType}
                transactionDetails={flowState.remortgagingDetails}
                referral={flowState.referral}
                rejectQuote="remortgaging"
              />
            ),
          },
          {
            name: 'Remortgaging mortgage lender',
            slug: 'remortgaging-mortgage-lender',
            valid: flowState => shouldShowRemortgageQuoteDetailsForm(
              flowState.transactionType,
            ),
            component: flowState => (
              <RemortgagingMortgageLender
                remortgagingDetails={flowState.remortgagingDetails}
              />
            )
          },
          {
            name: 'Personal details',
            slug: 'personal-details',
            component: flowState => (
              <SaveQuoteForm
                personalDetails={flowState.personalDetails}
                transactionType={flowState.transactionType}
                buyingDetails={flowState.buyingDetails || {}}
                sellingDetails={flowState.sellingDetails || {}}
                remortgagingDetails={flowState.remortgagingDetails || {}}
                referral={flowState.referral}
                referralLocked={state.referralLocked}
                defaultChannel={state.defaultChannel}
                defaultReferrer={state.defaultReferrer}
                campaign={state.campaign}
                pageWidth={pageWidth}
                googleAnalyticsId={googleAnalyticsId}
              />
            ),
          },
          {
            name: 'Quote details',
            slug: 'your-quote',
            component: flowState => (
              <QuoteSubmission
                personalDetails={flowState.personalDetails}
                transactionType={flowState.transactionType}
                buyingDetails={flowState.buyingDetails || {}}
                sellingDetails={flowState.sellingDetails || {}}
                remortgagingDetails={flowState.remortgagingDetails || {}}
                referral={flowState.referral}
                referralLocked={state.referralLocked}
                defaultChannel={state.defaultChannel}
                defaultReferrer={state.defaultReferrer}
                campaign={state.campaign}
                pageWidth={pageWidth}
                googleAnalyticsId={googleAnalyticsId}
                onFlowComplete={(quoteDetails) => {
                  setSubmittedFlowState({
                    ...flowState,
                    ...quoteDetails
                  });
                  setFlowCompleted(true);
                }}
              />
            ),
          }
        ]}
      />
    </PageLayout>
  );
}

export default QuoteFlow;
