import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

function CallToAction({ children }) {
  return (
    <StyledCallToAction>
      {children}
    </StyledCallToAction>
  );
}

export default CallToAction;

const StyledCallToAction = styled.div`
  width: 100%;
  max-width: 60rem;

  padding: 0.5rem;

  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;

  ${mediaQueries.lg} {
    margin-left: auto;
    margin-right: auto;
  }

  border-radius: 8px;
  background: linear-gradient(149deg, rgba(195,255,228,1) 0%, rgba(118,255,197,1) 100%);


  h2 {
    color: ${props => props.theme.color.junoText};
    font-size: 0.875rem;
    font-weight: ${props => props.theme.fontWeight.regular};
    line-height: 1.5;
    margin-bottom: 0.25rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: ${props => props.theme.fontWeight.regular};
    line-height: 1.5;
    color: ${props => props.theme.color.text3};
    margin: 0 0 1rem 0;

    a,
    span {
      color: ${props => props.theme.color.purple};
      font-weight: ${props => props.theme.fontWeight.bold};
    }
  }
`;
