import { useState, useEffect } from 'react';

const useClientAppFormRequest = (url) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [requestDetails, setRequestDetails] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      try {
        const resp = await fetch(url);
        const data = await resp.json();

        if (!ignore) {
          if (!!data.isValid) {
            setRequestDetails(data.config);
          } else if (data.error === 'expired') {
            setError('This request has expired');
          } else if (data.error === 'submitted') {
            setError("It looks like you've already submitted this data");
            setSubmitted(true);
          } else {
            setError('Failed to load request details');
          }
        }
      } catch (err) {
        if (!ignore) {
          setError('Failed to load request details');
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      ignore = true;
    };
  }, [url]);

  return { loading, error, requestDetails, submitted };
};

export default useClientAppFormRequest;
