import React from 'react';
import styled from 'styled-components/macro';
import Button, { SecondaryButton } from './Button';
import { mediaQueries } from '../styles/media.js';

const JunoAction = styled.div`
  margin-top: ${props => props.theme.spacingScale[7]};
  margin-bottom: ${props => props.theme.spacingScale[4]};

  ${mediaQueries.md} {
    margin-top: ${props => props.theme.spacingScale[8]};
    margin-bottom: ${props => props.theme.spacingScale[4]};
  }
`;

const SecondaryAction = styled.a`
  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: normal;
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
  }
  margin-bottom: 0.25em;
  color: ${props => props.theme.color.junoLink};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.color.junoLinkHover};
  }

  &:focus {
    outline: 3px solid ${props => props.theme.color.junoFocus};
  }
`;

function Action(props) {
  return (
    <JunoAction>
      {props.secondaryAction ? (
        <SecondaryAction {...props}>{props.children}</SecondaryAction>
      ) : null}
      {props.secondaryButton ? (
        <SecondaryButton {...props}>{props.children}</SecondaryButton>
      ) : null}
      {!props.secondaryAction && !props.secondaryButton ? (
        <Button {...props}>{props.children}</Button>
      ) : null}
    </JunoAction>
  );
}

export default Action;
