import React, {Component} from 'react';
import styled from 'styled-components/macro';

import {createDataTestId, createId} from './utils/helpers';
import Question, { Optional } from './Question';
import Fieldset from './Fieldset';
import {JunoLegend} from './Legend';
import Hint from './Hint';
import Input from './Input';
import Label from './Label';
import Error from './Error';

const DateLegend = styled(JunoLegend)`
  padding-bottom: 0.5rem;
`;

/* stylelint-disable property-no-vendor-prefix */
const DateField = styled.div`
  display: inline-block;
  margin-right: ${props => props.theme.spacingScale[3]};

  label {
    display: block;
  }

  input {
    max-width: 5.4ex;
    padding-right: 0.25em;
  }

  input:disabled {
    font-weight: bold;
  }

  .dateYear {
    max-width: 9ex;
  }

`;

/* stylelint-enable property-no-vendor-prefix */

class DateQuestion extends Component {
  render() {
    const date = { day: '', month: '', year: '' }

    const componentId = createId('input', this.props.name);
    const testId = createDataTestId('input', this.props.name);

    const day = this.props.value?.day || date.day;
    const month = this.props.value?.month || date.month;
    const year = this.props.value?.year || date.year;
    return (
      <Question>
        <Fieldset>
          <DateLegend>
            {this.props.question} {this.props.optional ? <Optional /> : null}
            {this.props.hint ? (
              this.props.hint
            ) : (
              <Hint>For example, 31 08 2024</Hint>
            )}
            {this.props.validationError ? (
              <Error>{this.props.validationError}</Error>
            ) : null}
          </DateLegend>
          <DateField>
            <Label htmlFor={componentId + '_day'}>Day</Label>
            <Input
              id={componentId + '_day'}
              data-testid={testId + '-day'}
              className="dateDay"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              value={day}
              onChange={this.props.onChange ? this.props.onChange('day') : null}
              onBlur={event => {
                if (this.props.validate) {
                  this.props.validate('day')(event.target.value);
                }
              }}
              error={!!this.props.validationError}
              disabled={this.props.disabled}
            />
          </DateField>
          <DateField>
            <Label htmlFor={componentId + '_month'}>Month</Label>
            <Input
              id={componentId + '_month'}
              data-testid={testId + '-month'}
              className="dateMonth"
              type="text"
              pattern="[0-9]*"
              value={month}
              onChange={this.props.onChange ? this.props.onChange('month') : null}
              onBlur={event => {
                if (this.props.validate) {
                  this.props.validate('month')(event.target.value);
                }
              }}
              error={!!this.props.validationError}
              disabled={this.props.disabled}
            />
          </DateField>
          <DateField>
            <Label htmlFor={componentId + '_year'}>Year</Label>
            <Input
              id={componentId + '_year'}
              data-testid={testId + '-year'}
              className="dateYear"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              value={year}
              onChange={this.props.onChange ? this.props.onChange('year') : null}
              onBlur={event => {
                if (this.props.validate) {
                  this.props.validate('year')(event.target.value);
                }
              }}
              error={!!this.props.validationError}
              disabled={this.props.disabled}
            />
          </DateField>
        </Fieldset>
      </Question>
    );
  }
}

export default DateQuestion;
