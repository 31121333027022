import styled, { css } from 'styled-components/macro';

function createListItem(item, index) {
  const hasHtmlTags = /<[^>]*>/.test(item);
  if (hasHtmlTags) {
    return <li key={index} dangerouslySetInnerHTML={{ __html: item }} />;
  } else {
    return <li key={index}>{item}</li>;
  }
}

function createBlock(db, idx, status) {
  if (db.type === 'heading') {
    return <StyledHeading3 status={status} key={idx}>{db.text}</StyledHeading3>;
  } else if (db.type === 'list') {
    const items = db.items.map(createListItem);
    return <StyledList status={status} key={idx}>{items}</StyledList>;
  } else if (db.type === 'paragraph') {
    return <StyledP status={status} key={idx}>{db.text}</StyledP>;
  }
}

function DescriptionBlocks({ status, descriptions, description_blocks }) {
  // Check if descriptions or description_blocks are null or empty arrays
  if ((!descriptions || descriptions.length === 0) && (!description_blocks || description_blocks.length === 0)) {
    return null;
  }

  if (description_blocks) {
    return description_blocks.map((db, idx) => createBlock(db, idx, status));
  } else if (descriptions) {
    return descriptions.length > 1
      ? <StyledList status={status}>{descriptions.map(createListItem)}</StyledList>
      : <StyledP status={status}>{descriptions[0]}</StyledP>;
  }
}

export default DescriptionBlocks;

const sharedStyles = css`
  color: ${({status, theme}) => theme.statusColor[status]};
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0;
`;

const StyledHeading3 = styled.h3`
  ${sharedStyles}
  font-weight: ${props => props.theme.fontWeight.bold};

  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
`;

const StyledList = styled.ul`
  ${sharedStyles}
  list-style: disc;
  padding-left: 1rem;
`;

const StyledP = styled.p`
  ${sharedStyles}
`;
