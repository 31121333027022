import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const JunoLabel = styled.label`
  display: block;
  color: ${props => props.theme.color.junoText};

  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: normal;
  margin-bottom: 0.5em;
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
  }
`;

const JunoBoldLabel = styled(Label)`
  font-weight: ${props => props.theme.fontWeight.bold};
`;

function Label(props) {
  return <JunoLabel {...props}>{props.children}</JunoLabel>;
}

export function BoldLabel(props) {
  return <JunoBoldLabel>{props.children}</JunoBoldLabel>;
}

export default Label;
