import { useState, useEffect } from 'react';

const useClientAppSignupRequest = (url) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [signupRequest, setSignupRequest] = useState(null);
  const [signupsEnabled, setSignupsEnabled] = useState(null);
  const [signupsDisabledReason, setSignupsDisabledReason] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      try {
        const resp = await fetch(url);
        const data = await resp.json();

        if (!ignore) {
          if (!!data.signupRequestValid && !!data.signupsEnabled) {
            setSignupRequest(data.signupRequest);
            setSignupsEnabled(true);
          } else if (data.signupsEnabled === false) {
            setSignupsEnabled(false);
            setSignupsDisabledReason(data.signupsDisabledReason);
          } else if (data.signupRequestError === 'expired') {
            setError('Your signup link has expired.');
          } else if (data.signupRequestError === 'submitted') {
            setError("It looks like you've already signed up.");
            setSubmitted(true);
          } else {
            setError('Failed to load signup details.');
          }
        }
      } catch (err) {
        if (!ignore) {
          setError('Failed to load signup details.');
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      ignore = true;
    };
  }, [url]);

  return { loading, error, signupRequest, signupsEnabled, signupsDisabledReason, submitted };
};

export default useClientAppSignupRequest;
