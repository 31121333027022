const ordinalStrings = new Map([
  [1, 'first'],
  [2, 'second'],
  [3, 'third'],
  [4, 'fourth'],
  [5, 'fifth'],
  [6, 'sixth'],
  [7, 'seventh'],
  [8, 'eighth'],
  [9, 'ninth'],
  [10, 'tenth'],
  [11, 'eleventh'],
  [12, 'twelfth'],
]);

export const getOrdinalString = i => {
  return ordinalStrings.get(i);
};

export const normalisePercentage = money => {
  const stripCharsRegex = /[%, ]/g;
  return money.replace(stripCharsRegex, '');
};

export const isPercentageValid = percent => {
  const allowedCharsRegex = /^[0-9%,. ]+$/;
  if (allowedCharsRegex.test(String(percent))) {
    const normalised = normalisePercentage(percent);

    const decimalPlaceRegex = /^\d+\.?\d{0,2}$/;

    if (!decimalPlaceRegex.test(String(normalised))) {
      return false;
    }

    return parseFloat(normalised) !== 0.0;
  }
  return false;
};

export const isPhoneNumberValid = phone => {
  const re = /[0-9+() -]+$/;
  return re.test(String(phone));
};
