import React from 'react';
import styled from 'styled-components/macro';

function ToggleButton({ value, onChange }) {
  return (
    <ToggleButtonGroup value={value} onChange={onChange}>
      <LeftButton
        value="oldest_case"
        selected={value === "oldest_case"}
        aria-pressed={value === "oldest_case"}
        onClick={() => onChange('oldest_case')}>
          Oldest
      </LeftButton>
      <RightButton
        value="newest_case"
        selected={value === "newest_case"}
        aria-pressed={value === "newest_case"}
        onClick={() => onChange('newest_case')}>
          Newest
      </RightButton>
    </ToggleButtonGroup>
  );
}

export default ToggleButton;

const ToggleButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 2.5rem;
  color: ${props => props.theme.color.junoText};
  min-width: fit-content;
`;

const Button = styled.button`
  background: ${props => props.selected ? props.theme.color.purple : '#ebecf0'};
  color: ${props => props.selected ? props.theme.color.white : props.theme.color.palePurple};
  transition: background color 100ms ease;

  font-weight: bold;
  font-size: 14px;
  border-style: solid; /* override outset */
  border-color: ${props => props.selected ? props.theme.color.purple : '#ebecf0'};

  cursor: ${props => props.selected ? 'default' : 'pointer'};
  height: 100%;
  padding: 0 16px;

  &:hover {
    background: ${props => props.selected ? props.theme.color.purple : props.theme.color.palePurple};
    color: ${props => props.selected ? props.theme.color.white : props.theme.color.purple};
    border-color: ${props => props.selected ? props.theme.color.purple : props.theme.color.palePurple};
    font-weight: ${props => props.selected ? 'bold' : 'regular'};
  }
`;

const LeftButton = styled(Button)`
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;

const RightButton = styled(Button)`
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
`;
