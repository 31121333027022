import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { MORTGAGE_LENDER_OPTIONS } from '../../constants';

class BuyingMortgageLender extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      hasMortgageLender: 'Let us know if you have a mortgage',
      buyingMortgageLender: 'Please choose a mortgage lender',
    };

    if (this.props.buyingMortgageLender) {
      const details = this.props.buyingMortgageLender;
      this.state = {
        buyingMortgageLender: details.buyingMortgageLender,
        hasMortgageLender: details.hasMortgageLender,
        errors: { ...details.errors },
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        buyingMortgageLender: null,
        hasMortgageLender: null,
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (fields['hasMortgageLender'] === null) {
      const field = 'hasMortgageLender';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['hasMortgageLender'] === true) {
      if (fields['buyingMortgageLender'] === null) {
        const field = 'buyingMortgageLender';
        formIsValid = false;
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  selectHasMortgageLender = hml => {
    this.setState(
      {
        hasMortgageLender: hml,
      },
      () => {
        this.resetBuyingMortgageLender();
      },
    );
  };

  selectBuyingMortgageLender = bml => {
    this.setState({ buyingMortgageLender: bml });
  };

  resetBuyingMortgageLender = () => {
    if (this.state.hasMortgageLender === false) {
      this.setState({ buyingMortgageLender: null });
    }
  };

  saveBuyingMortgageLender = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        buyingMortgageLender: {
          hasMortgageLender: this.state.hasMortgageLender,
          buyingMortgageLender: this.state.buyingMortgageLender,
          errors: { ...this.state.errors },
        },
      });
      return;
    }
  };

  render() {
    const yes_not_yet_options = [
      { title: 'Yes', value: true },
      { title: 'Not yet', value: false },
    ];

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Your mortgage" removeTopPadding>
          <RadioQuestion
            question="Have you chosen a mortgage lender?"
            options={yes_not_yet_options}
            inline={true}
            name="hasMortgageLender"
            selected={this.state.hasMortgageLender}
            optionSelected={this.selectHasMortgageLender}
            validate={this.handleFieldValidation('hasMortgageLender')}
            validationError={
              this.state.errors.hasMortgageLender
                ? this.state.errors.hasMortgageLender
                : null
            }
          />

          {this.state.hasMortgageLender && (
            <RadioQuestion
              question="Select your lender"
              options={MORTGAGE_LENDER_OPTIONS}
              inline={false}
              name="buyingMortageLender"
              selected={this.state.buyingMortgageLender}
              optionSelected={this.selectBuyingMortgageLender}
              validate={this.handleFieldValidation('buyingMortgageLender')}
              validationError={
                this.state.errors.buyingMortgageLender
                  ? this.state.errors.buyingMortgageLender
                  : null
              }
            />
          )}

          <Action onClick={this.saveBuyingMortgageLender}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default BuyingMortgageLender;
