import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useClientAppFormRequest from '../../hooks/useClientAppFormRequest';
import ScrollToTop from '../../components/utils/ScrollToTop';
import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import MoreInformationIntro from './MoreInformationIntro';
import BuyingClient1 from './BuyingClient1';
import RemoClient1 from './RemoClient1';
import SellingClient1 from './SellingClient1';
import SellingClient1Ownership from './SellingClient1Ownership';
import SellingClient1Probate from './SellingClient1Probate';
import SellingClient1RegisteredOwner from './SellingClient1RegisteredOwner';
import MoreInformationSubmission from './MoreInformationSubmission';
import MoreInformationThanks from './MoreInformationThanks';
import StepFlowNavigate from '../../components/StepFlowNavigate';

const MoreInformationFlow = props => {
  const location = useLocation();
  const { requestId } = useParams();

  const [fromDashboard, setFromDashboard] = useState(false);
  const [flowCompleted, setFlowCompleted] = useState(false);
  const [transactionType, setTransactionType] = useState('');

  const { loading, error, requestDetails, submitted } = useClientAppFormRequest(`/api/moreInformation/${requestId}`);

  // Set fromDashboard state on initial load
  useEffect(() => {
    const { fromDashboard } = location.state || {};
    setFromDashboard(fromDashboard);
  }, []);

  // Set transactionType state when requestDetails is available
  useEffect(() => {
    if (requestDetails) {
      setTransactionType(requestDetails.transactionType);
    }
  }, [requestDetails]);

  const isRemortgaging = transactionType => {
    return transactionType === 'Remortgaging';
  };

  const isSelling = transactionType => {
    return (
      transactionType === 'Selling' || transactionType === 'BuyingAndSelling'
    );
  };

  const isBuying = transactionType => {
    return (
      transactionType === 'Buying' || transactionType === 'BuyingAndSelling'
    );
  };

  const shouldShowSellingOwnership = flowState => {
    return (
      isSelling(flowState.transactionType) &&
      flowState.sellerIsRegisteredOwner === false
    );
  };

  const shouldShowSellingRegisteredOwnerDetails = flowState => {
    return (
      isSelling(flowState.transactionType) &&
      flowState.sellerIsRegisteredOwner === false &&
      flowState.registeredOwner.relationshipToRegisteredOwner === 'other'
    );
  };

  const shouldShowSellingProbate = flowState => {
    return (
      isSelling(flowState.transactionType) &&
      flowState.sellerIsRegisteredOwner === false &&
      flowState.registeredOwner.relationshipToRegisteredOwner ===
        'sellerHasGrantOfProbate'
    );
  };

  if (!!loading) {
    return null;
  }

  if (submitted || flowCompleted) {
    return (
      <PageLayout>
        <MoreInformationThanks
          fromDashboard={fromDashboard}
          transactionType={transactionType} />
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <ScrollToTop />
        <Card title="Sorry, that didn't work" removeTopPadding>
          {error}
        </Card>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <StepFlowNavigate
        initialFlowState={{
          formRequestId: requestDetails.formRequestId,
          transactionType: requestDetails.transactionType,
        }}
        steps={[
          {
            name: 'Intro',
            slug: 'intro',
            component: flowState => (
              <MoreInformationIntro
                transactionType={flowState.transactionType}
              />
            ),
          },
          {
            name: 'Remortgaging - Client 1',
            slug: 'about-you-remortgage',
            valid: flowState => isRemortgaging(flowState.transactionType),
            component: flowState => <RemoClient1 client={flowState.client} />,
          },
          {
            name: 'Buying - Client 1',
            slug: 'about-you-buying',
            valid: flowState => isBuying(flowState.transactionType),
            component: flowState => <BuyingClient1 client={flowState.client} />,
          },
          {
            name: 'Selling - Client 1',
            slug: 'about-you-selling',
            valid: flowState => isSelling(flowState.transactionType),
            component: flowState => (
              <SellingClient1
                client={flowState.client}
                sellerIsRegisteredOwner={flowState.sellerIsRegisteredOwner}
              />
            ),
          },
          {
            name: 'Selling - Client 1 - Ownership',
            slug: 'ownership-selling',
            valid: flowState => shouldShowSellingOwnership(flowState),
            component: flowState => (
              <SellingClient1Ownership
                registeredOwner={flowState.registeredOwner}
              />
            ),
          },
          {
            name: 'Selling - Client 1 - Registered owner details',
            slug: 'registered-owner-selling',
            valid: flowState =>
              shouldShowSellingRegisteredOwnerDetails(flowState),
            component: flowState => (
              <SellingClient1RegisteredOwner
                registeredOwnerDetails={flowState.registeredOwnerDetails}
              />
            ),
          },
          {
            name: 'Selling - Client 1 - Probate',
            slug: 'probate-selling',
            valid: flowState => shouldShowSellingProbate(flowState),
            component: flowState => (
              <SellingClient1Probate
                lawFirmHandlingProbate={flowState.lawFirmHandlingProbate}
              />
            ),
          },
          {
            name: 'Thanks',
            slug: 'thanks',
            component: flowState => (
              <MoreInformationSubmission
                transactionType={flowState.transactionType}
                moreInformationFlowState={flowState}
                onFlowComplete={() => setFlowCompleted(true)}
              />
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

export default MoreInformationFlow;
