import React, { Component } from 'react';

import Action from '../../components/Action';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';

import { BUYING_PROOF_OF_FUNDS_OPTIONS } from '../../constants';
import List, { ListItem } from '../../components/List';

class SourceOfFundsCheckYourAnswers extends Component {

  formatSourceOfFunds(howIsBuyerPaying) {
    let payingName = Object.keys(howIsBuyerPaying)
      .filter(key => howIsBuyerPaying[key] === true)

    const fundingSources = payingName.map((name) => {
      const option = BUYING_PROOF_OF_FUNDS_OPTIONS.find(option => option.name === name);
      return option.title
    })

    return fundingSources;
  }

  onlyMortgage(howIsBuyerPaying) {
    const howIsBuyerPayingKeys = Object.keys(howIsBuyerPaying)
      .filter(key => howIsBuyerPaying[key] === true)
    if (howIsBuyerPayingKeys.length === 1 && howIsBuyerPayingKeys[0] === 'mortgage') {
      return true
    }
    return false
  }

  saveCheckYourAnswers = () => {
    this.props.onStepComplete({
      sourceOfFundsFlowState: this.props.sourceOfFundsFlowState
    })
    return;
  };

  render() {
    const howIsBuyerPaying = this.formatSourceOfFunds(this.props.sourceOfFundsFlowState.howIsBuyerPaying);

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Almost done, you’ve told us..." removeTopPadding>

          <p id="funds-list">You (and anyone you are purchasing the property with) are using these funds to pay for your property:</p>
          <List bullet aria-labelledby="funds-list">
            {howIsBuyerPaying.map((fundingSource, idx) => {
              return (
                <ListItem key={idx}>{fundingSource}</ListItem>
              )
            })}
          </List>

          <p>
            If you’d like to change your answers, choose “I’d like to add more funds” below, we’ll take you back to the start.
          </p>

          <Action onClick={this.saveCheckYourAnswers}>I’m ready - submit my answers</Action>

          <Action onClick={() => this.props.showIndex(1)} secondaryButton={true}>
            I’d like to add more funds
          </Action>

        </Card>
      </React.Fragment>
    );
  }
}

export default SourceOfFundsCheckYourAnswers;
