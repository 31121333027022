import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { MORTGAGE_LENDER_OPTIONS } from '../../constants';

class MortgageLender extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      mortgageLender: 'Please choose a mortgage lender',
    };

    if (this.props.transactionDetails) {
      const details = this.props.transactionDetails;
      this.state = {
        mortgageLender: details.mortgageLender,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        mortgageLender: null,
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (fields['mortgageLender'] === null) {
      const field = 'mortgageLender';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  selectMortgageLender = bml => {
    this.setState({ mortgageLender: bml });
  };

  saveMortgageLender = event => {
    let transactionDetails = { ...this.props.transactionDetails }
    transactionDetails['mortgageLender'] = this.state.mortgageLender

    event.preventDefault();
    if (this.handleValidation()) {
      let updateObj = {}
      updateObj[this.props.updateObjectName] = transactionDetails
      this.props.onStepComplete(updateObj);
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Your mortgage" removeTopPadding>
          <RadioQuestion
            question="Select your lender"
            options={MORTGAGE_LENDER_OPTIONS}
            inline={false}
            name="mortgageLender"
            selected={this.state.mortgageLender}
            optionSelected={this.selectMortgageLender}
            validate={this.handleFieldValidation('mortgageLender')}
            validationError={
              this.state.errors.mortgageLender
                ? this.state.errors.mortgageLender
                : null
            }
          />

          <Action onClick={this.saveMortgageLender}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export class RemortgagingMortgageLender extends Component {
  render() {
    return (
      <MortgageLender transactionDetails={this.props.remortgagingDetails}
                      updateObjectName="remortgagingDetails"
                      onStepComplete={this.props.onStepComplete}
      />
    )
  }
}

export class BuyingMortgageLender extends Component {
  render() {
    return (
      <MortgageLender transactionDetails={this.props.buyingDetails}
                      updateObjectName="buyingDetails"
                      onStepComplete={this.props.onStepComplete}
      />
    )
  }
}
