export const moneyStrToNum = string => {
  const nullable = string || '0';
  return parseFloat(nullable.replace('£', ''));
};

export const formatMoney = (money, includePence) => {
  if (includePence) {
    return addPenceToMoneyString(money);
  }
  return removePenceFromMoneyString(money);
};

export const removePenceFromMoneyString = money => {
  if (money.includes('.')) {
    const i = money.indexOf('.');
    return money.substring(0, i);
  }
  return money;
};

export const addPenceToMoneyString = money => {
  if (money.includes('.')) {
    return money;
  } else {
    return money + '.00';
  }
};

export const formatMoneyWithCurrency = p => {
  let formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  let moneyWithCurrency = formatter.format(p);

  if (moneyWithCurrency.includes('.00')) {
    let i = moneyWithCurrency.indexOf('.');
    return moneyWithCurrency.substring(0, i);
  }

  return moneyWithCurrency;
};

export const formatPenceNumberString = penceString => {
  const penceValue = parseInt(penceString);
  const pounds = penceValue / 100;
  return formatMoneyWithCurrency(pounds);
};

export const normaliseMoney = money => {
  const stripCharsRegex = /[£, ]/g;
  return money.replace(stripCharsRegex, '');
};

export const isMoneyValid = money => {
  const allowedCharsRegex = /^[0-9£,. ]+$/;
  if (allowedCharsRegex.test(String(money))) {
    const normalised = normaliseMoney(money);

    const decimalPlaceRegex = /^\d+\.?\d{0,2}$/;

    if (!decimalPlaceRegex.test(String(normalised))) {
      return false;
    }

    return parseFloat(normalised) !== 0.0;
  }
  return false;
};

export const formatMoneyNum = price => {
  return price ? formatPenceNumberString(price.toString()) : null;
}
