import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { AddCircleOutline } from '@styled-icons/material';
import Action from '../../components/Action';

class ShowAddTA10Item extends Component {
  render() {
    return (
      <>
        <AddItem secondaryAction={true} onClick={this.props.onShowAdd}>
          <AddItemIcon />{this.props.addLinkText}
        </AddItem>
        <Action onClick={this.props.continue}>Continue</Action>
      </>
    )
  }
}

const AddItem = styled(Action)`
  display: flex;
  align-items: center;

  color: ${props => props.theme.color.junoLink};
  margin-bottom: 3em;

  &:hover {
    border: none;
    color: ${props => props.theme.color.junoLinkHover}
  }
`

const AddItemIcon = styled(AddCircleOutline)`
  width: ${props => props.theme.iconSize['xl']};
  color: inherit;
  margin-right: 0.5em;
  margin-top: -2px;
`

export default ShowAddTA10Item;
