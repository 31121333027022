import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';

class MoreInformationSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };

    const flowState = this.props.moreInformationFlowState;

    fetch('/api/moreInformation', {
      method: 'PUT',
      body: JSON.stringify({
        formRequestId: flowState.formRequestId,
        transactionType: flowState.transactionType,
        client: flowState.client,
        sellerIsRegisteredOwner: flowState.sellerIsRegisteredOwner,
        registeredOwner: flowState.registeredOwner,
        registeredOwnerDetails: flowState.registeredOwnerDetails,
        lawFirmHandlingProbate: flowState.lawFirmHandlingProbate,
      }),
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
            submitted: true,
          });
          props.onFlowComplete();
        } else {
          this.setState({
            error: 'Unable to process your details',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Unable to process your details',
          loading: false,
        });
      },
    );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn't work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    if (this.state.submitted) {
      return null;
    }
  }
}

export default MoreInformationSubmission;
