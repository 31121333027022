import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

import DashboardPanel from './DashboardPanel';
import DashboardPanelImage from './DashboardPanelImage';
import ReferralLink from './ReferralLink';
import SocialLinks from './SocialLinks';
import imageRelaxingOnChair from '../images/relaxing-on-chair.png';

class ReferralPanel extends React.Component {

  render() {
    if (!this.props.showReferral) {
      return null
    }

    const referralUrl = `https://client.juno.legal/quote/${this.props.referralCode}`

    return (
      <DashboardPanel title="Share £150 with a friend">
        <StyledReferralLayout>
          <StyledReferralContent>
            <p>
              If you’ve got a friend or neighbour who’s thinking of moving, we’d love to help them with their conveyancing.
            </p>

            <p>
              Sign them up with Juno and they’ll get £75 off of their fees – and we’ll give you a £75 voucher once their move is complete.
            </p>

            <p>Just share this link:</p>

            <ReferralLink>
              <p>{referralUrl}</p>
            </ReferralLink>

            <SocialLinks referralUrl={referralUrl} />

            {this.props.showFindOutMore &&
              <p>
                <Link to="/dashboard/referral">
                  Find out more
                </Link>
              </p>
            }

          </StyledReferralContent>
          <StyledReferralImage>
            <DashboardPanelImage imageType="chair" src={imageRelaxingOnChair} />
          </StyledReferralImage>

        </StyledReferralLayout>
      </DashboardPanel>
    );
  }
}

export default ReferralPanel

ReferralPanel.propTypes = {
  showReferral: PropTypes.bool.isRequired,
  showFindOutMore: PropTypes.bool.isRequired,
  referralCode: PropTypes.string
};


const StyledReferralLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  ${mediaQueries.md} {
    flex-direction: row;
    align-items: flex-end;
  }
`

const StyledReferralContent = styled.div`
  a {
    color: ${props => props.theme.color.white};
    border-bottom: 1px solid ${props => props.theme.color.white};
    &:hover {
      cursor: pointer
    }
  }
  ${mediaQueries.md} {
    width: 55%;
  }
`

const StyledReferralImage = styled.div`
  margin-top: 1em;
  margin-bottom: -1em;
  width: 100%;
  ${mediaQueries.md} {
    margin-top: 0;
    margin-bottom: 0;
  }
`
