import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useClientAppFormRequest from '../../hooks/useClientAppFormRequest';

import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import SellingMortgagesAndLoansIntro from './SellingMortgagesAndLoansIntro';
import SellingNumberOfMortgages from './SellingNumberOfMortgages';
import SellingMortgage1 from './SellingMortgage1';
import SellingMortgage2 from './SellingMortgage2';
import SellingMortgage3 from './SellingMortgage3';
import SellingNumberOfLoans from './SellingNumberOfLoans';
import SellingLoan1 from './SellingLoan1';
import SellingLoan2 from './SellingLoan2';
import SellingMortgagesAndLoansThanks from './SellingMortgagesAndLoansThanks';
import StepFlowNavigate from '../../components/StepFlowNavigate';
import SellingMortgagesAndLoansSubmission from './SellingMortgagesAndLoansSubmission';

const SellingMortgagesAndLoansFlow = (props) => {
  const location = useLocation();
  const { requestId } = useParams();

  const [fromDashboard, setFromDashboard] = useState(false);
  const [flowCompleted, setFlowCompleted] = useState(false);

  const { loading, error, requestDetails, submitted } = useClientAppFormRequest(`/api/sellingMortgagesAndLoans/${requestId}`);

  // Set fromDashboard state on initial load
  useEffect(() => {
    const { fromDashboard } = location.state || {};
    setFromDashboard(fromDashboard);
  }, []);

  const shouldShowSellingMortgages = flowState => {
    return flowState.sellingMortgagesAndLoans.sellerHasAMortgage === true;
  };

  const shouldShowSellingMortgage1 = flowState => {
    return flowState.numberOfMortgages <= 3;
  };

  const shouldShowSellingMortgage2 = flowState => {
    return flowState.numberOfMortgages >= 2;
  };

  const shouldShowSellingMortgage3 = flowState => {
    return flowState.numberOfMortgages === 3;
  };

  const shouldShowSellingLoans = flowState => {
    return flowState.sellingMortgagesAndLoans.sellerHasASecuredLoan === true;
  };

  const shouldShowSellingLoan1 = flowState => {
    return flowState.numberOfLoans <= 2;
  };

  const shouldShowSellingLoan2 = flowState => {
    return flowState.numberOfLoans === 2;
  };


  if (loading) {
    return null;
  }

  if (submitted || flowCompleted) {
    return (
      <PageLayout>
        <SellingMortgagesAndLoansThanks fromDashboard={fromDashboard} />
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <ScrollToTop />
        <Card title="Sorry, that didn't work" removeTopPadding>
          {error}
        </Card>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <StepFlowNavigate
        initialFlowState={{
          formRequestId: requestDetails.formRequestId,
          sellingMortgagesAndLoans: {
            sellerHasAMortgage: requestDetails.sellerHasAMortgage,
            sellerHasASecuredLoan: requestDetails.sellerHasASecuredLoan,
          },
          fromDashboard: fromDashboard
        }}
        steps={[
          {
            slug: 'intro',
            component: flowState => (
              <SellingMortgagesAndLoansIntro
                sellingMortgagesAndLoans={flowState.sellingMortgagesAndLoans}
              />
            ),
          },
          {
            slug: 'how-many-mortgages',
            valid: flowState => shouldShowSellingMortgages(flowState),
            component: flowState => (
              <SellingNumberOfMortgages
                numberOfMortgages={flowState.numberOfMortgages}
              />
            ),
          },
          {
            slug: 'mortgage-1',
            valid: flowState => shouldShowSellingMortgage1(flowState),
            component: flowState => (
              <SellingMortgage1
                numberOfMortgages={flowState.numberOfMortgages}
                mortgage1={flowState.mortgage1}
              />
            ),
          },
          {
            slug: 'mortgage-2',
            valid: flowState => shouldShowSellingMortgage2(flowState),
            component: flowState => (
              <SellingMortgage2
                numberOfMortgages={flowState.numberOfMortgages}
                mortgage2={flowState.mortgage2}
              />
            ),
          },
          {
            slug: 'mortgage-3',
            valid: flowState => shouldShowSellingMortgage3(flowState),
            component: flowState => (
              <SellingMortgage3
                numberOfMortgages={flowState.numberOfMortgages}
                mortgage3={flowState.mortgage3}
              />
            ),
          },
          {
            slug: 'how-many-loans',
            valid: flowState => shouldShowSellingLoans(flowState),
            component: flowState => (
              <SellingNumberOfLoans numberOfLoans={flowState.numberOfLoans} />
            ),
          },
          {
            slug: 'loan-1',
            valid: flowState => shouldShowSellingLoan1(flowState),
            component: flowState => (
              <SellingLoan1
                numberOfLoans={flowState.numberOfLoans}
                loan1={flowState.loan1}
              />
            ),
          },
          {
            slug: 'loan-2',
            valid: flowState => shouldShowSellingLoan2(flowState),
            component: flowState => (
              <SellingLoan2
                numberOfLoans={flowState.numberOfLoans}
                loan2={flowState.loan2}
              />
            ),
          },
          {
            slug: 'submit',
            component: flowState => (
              <SellingMortgagesAndLoansSubmission
                fromDashboard={flowState.fromDashboard}
                sellingMortgagesAndLoansFlowState={flowState}
                onFlowComplete={() => setFlowCompleted(true)}
              />
            )
          },
        ]}
      />
    </PageLayout>
  );
}

export default SellingMortgagesAndLoansFlow;
