import React, { Component } from 'react';
import Card from '../../components/DashboardCard';
import PageHeader from '../../components/PageHeader';
import Confirmation from '../../components/Confirmation';
import ReviewText from '../../components/ReviewText';
import QuestionDescription from '../../components/QuestionDescription';
import RenderCompletedQuestion from "./utils/RenderCompletedQuestion";
import ScrollToTop from "../../components/utils/ScrollToTop";
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

class DynamicFormSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responses: {},
    };
  }

  componentDidMount() {
    this.addResponsesToState();
  }

  addResponsesToState() {
    let submission = this.props.doable.submission;

    if (! submission) {
      return;
    }

    let responses = { ...this.state.responses };

    submission['fields'].map(field => {
      let fieldId = field.fieldName;
      let fieldType = this.getFieldTypeForField(fieldId)

      if (fieldType === "checkbox") {
        responses[fieldId] = {};
        let checkboxChoices = this.getChoicesForField(fieldId)

        checkboxChoices.map(choice =>
          Object.assign(responses[fieldId], { [choice.value]: false }),
        );

        if (field.fieldValues !== null) {
          field.fieldValues.map(value =>
            Object.assign(responses[fieldId], { [value]: true })
          )
        }
      }

      if (field.fieldValue !== null) {
        if (
          fieldType === 'date' ||
          fieldType === 'date_in_the_past' ||
          fieldType === 'must_happen_by_date'
        ) {
          let date;

          if (field.fieldValue.includes('/')) {
            // Old submission in dd/mm/yyyy format
            const parts = field.fieldValue.split('/');
            date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

          } else {
            date = new Date(field.fieldValue);
          }

          responses[fieldId] = {
            day: date.getDate().toString(),
            month: (date.getMonth() + 1).toString(), // getMonth returns 0-indexed number
            year: date.getFullYear().toString(),
          };

        } else if (fieldType === "confirmation") {
          responses[fieldId] = true;

        } else {
          responses[fieldId] = field.fieldValue;

        }
      }

    });

    this.setState({ responses });
  }

  getChoicesForField = (field) => {
    return this.props.doable.configuration.fields.filter(f => f.field_identifier === field)[0].choices
  };

  getFieldTypeForField = (field) => {
    return this.props.doable.configuration.fields.filter(f => f.field_identifier === field)[0].field_type
  };

  createContent(content) {
    return { __html: content };
  }

  renderResponses = () => {
    if (! this.props.doable.submission) {
      return <p>
        We’ll be in touch if we have any questions.
      </p>
    }

    return <>
      <ReviewText>
        We’ve highlighted your responses in bold below
      </ReviewText>
      {this.props.doable.configuration.fields.map((fieldConfig) => {
        const name = fieldConfig.field_identifier;
        return <RenderCompletedQuestion
          key={name}
          doableConfig={this.props.doable.configuration}
          fieldConfig={fieldConfig}
          responses={this.state.responses}
          selected={this.state.responses[name] || false}
          value={this.state.responses[name] || ''}
          disabled={true}
        />;
      })}
    </>
  }

  render() {

    if (!this.props.doable) return null;

    const requestTitle = this.props.doable.title;
    const requestDescription = this.props.doable.description;

    return (
      <React.Fragment>
        <ScrollToTop />
        <PageHeader>
          Things you’ve done
        </PageHeader>
        <Confirmation
          title="Thanks for filling in our form - we’ve got your answers"
          submission={true} />

        <Card title={requestTitle} removeTopPadding>
          {requestDescription && (
            <QuestionDescription dangerouslySetInnerHTML={this.createContent(requestDescription)} />
          )}
          {this.renderResponses()}
        </Card>
        <Image src={imageOfficeWorking} fullWidth={true} />
      </React.Fragment>
    )
  }
}

export default DynamicFormSubmission
