import React from 'react';
import List, { ListItem } from '../../components/List';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

const Thanks = () => {

  return (
    <React.Fragment>
      <ScrollToTop />
      <Card title="Thanks, all done" removeTopPadding>
          <p>You’re all signed up! Next, we’ll:</p>

          <List bullet>
            <ListItem>Email you the terms and conditions</ListItem>
            <ListItem>Carry out our ID checks</ListItem>
          </List>

          <p>
            We’ll need more information along the way, but this is enough for us
            to get started. As soon as we need anything else we’ll drop you an
            email.
          </p>
          <p>Thanks, and enjoy the rest of your day.</p>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
    </React.Fragment>
  );
}

export default Thanks;
