import queryString from 'query-string';

// TODO: We should be able to use React Router's useSearchParams hook
// https://reactrouter.com/en/main/hooks/use-search-params

export function userSkippedTransactionTypePicker(location) {
  return queryString.parse(location.search).transaction != null;
}

export function parseTransactionType(location) {
  const qs = queryString.parse(location.search);
  if (qs.transaction) {
    const lower = qs.transaction.toLowerCase();
    if (lower === 'selling') {
      return 'Selling';
    }
    if (lower === 'buying') {
      return 'Buying';
    }
    if (lower === 'buyingandselling') {
      return 'BuyingAndSelling';
    }
    if (lower === 'remortgaging') {
      return 'Remortgaging';
    }
  }
  return null;
}

// FIXME: update this to take a passed-in location from React router instead of
// directly accessing window.location
export function parseTenure() {
  const qs = queryString.parse(window.location.search);
  if (qs.tenure) {
    const lower = qs.tenure.toLowerCase();
    if (lower === 'freehold') {
      return 'freehold';
    }
    if (lower === 'leasehold') {
      return 'leasehold';
    }
    if (lower === 'leasehold with share of freehold') {
      return 'leasehold with share of freehold';
    }
  }
  return null;
}

export function parseChannel(location, fallback = 'juno.legal') {
  const qs = queryString.parse(location.search);
  if (qs.utm_source) {
    return qs.utm_source;
  }
  return fallback;
}

export function parseCampaign(location) {
  const qs = queryString.parse(location.search);
  if (qs.utm_campaign) {
    return qs.utm_campaign;
  }
  return null;
}
