import React from 'react';
import RadioQuestion from "../../../components/RadioQuestion";
import { YES_NO_OPTIONS } from "../../../constants";
import DynamicCheckboxQuestion from "../../../components/DynamicCheckboxQuestion";
import TextQuestion, { TextareaQuestion } from "../../../components/TextQuestion";
import IntegerQuestion from "../../../components/IntegerQuestion";
import PhoneNumberQuestion from "../../../components/PhoneNumberQuestion";
import EmailAddressQuestion from "../../../components/EmailAddressQuestion";
import MoneyQuestion from "../../../components/MoneyQuestion";
import DateQuestion from "../../../components/DateQuestion";
import YearQuestion from "../../../components/YearQuestion";

const checkEquals = (answer, shownWhenValue) => {
  if (typeof (answer) === "boolean") {
    let stringBoolean = `${answer}`;
    return (stringBoolean === shownWhenValue)
  } else {
    return (answer === shownWhenValue)
  }
}

const checkCheckboxEquals = (answer, shownWhenValue) => {
  for (const [key, value] of Object.entries(answer)) {
    if (value === true) {
      if (shownWhenValue.includes(key)) {
        return true
      } else {
        return false
      }
    }
  }
}

const checkEqualsPrecheck = (responses, shownWhenField, shownWhenValue) => {
  for (const [question, answer] of Object.entries(responses)) {
    if (answer !== null) {
      if (question === shownWhenField) {
        if (answer instanceof Object) {
          let checkboxEquals = checkCheckboxEquals(answer, shownWhenValue)
          return checkboxEquals
        }
        let fieldEquals = checkEquals(answer, shownWhenValue)
        return fieldEquals
      }
    }
  }
}

const checkAnyFieldEqualsPrecheck = (responses, shownWhenFieldNames, shownWhenValue) => {
  for (const [question, answer] of Object.entries(responses)) {
    if (answer !== null) {
      if (shownWhenFieldNames.includes(question)) {
        if (checkEquals(answer, shownWhenValue) === true) {
          return true
        }
      }
    }
  }
  return false
}

const checkAnyOfPrecheck = (responses, shownWhenField, shownWhenValue) => {
  const fieldHasResponse = responses.hasOwnProperty(shownWhenField);
  if (fieldHasResponse) {
    let answer = responses[shownWhenField];

    if (answer instanceof Object) {
      for (const [key, value] of Object.entries(answer)) {
        if (value === true) {
          if (shownWhenValue.includes(key)) {
            return true
          }
        }
      }
    }

    if (typeof (answer) === "string") {
      if (shownWhenValue.includes(answer)) {
        return true
      }
    }

  }
  return false
}

const checkShownWhenFields = (doableConfig, fieldConfig) => {
  const fieldIds = doableConfig.fields.map(f => f.field_identifier);

  for (const precheck of fieldConfig.shown_when) {
    if (precheck.operation === "equals" || precheck.operation === "any_of") {
      let shownWhenField = precheck.field
      if (fieldIds.includes(shownWhenField)) {
        return true
      }
    }

    if (precheck.operation === "any_field_equals") {
      let shownWhenFieldNames = precheck.field_names
      return shownWhenFieldNames.some(fieldName => fieldIds.includes(fieldName))
    }
  }
  return false
}

export const isFieldQuestionVisible = (doableConfig, fieldConfig, responses) => {
  if (fieldConfig.shown_when) {

    // If the shown_when field doesn't exist in the request, ensure we show the question
    const fieldExistsInRequest = checkShownWhenFields(doableConfig, fieldConfig)
    if (fieldExistsInRequest === false) {
      return true
    }

    for (const precheck of fieldConfig.shown_when) {
      let shownWhenValue = precheck.value

      if (precheck.operation === "equals") {
        let shownWhenField = precheck.field

        let result = checkEqualsPrecheck(responses, shownWhenField, shownWhenValue)
        if (result === false || result === undefined) {
          return false
        }
      }

      if (precheck.operation === "any_field_equals") {
        let shownWhenFieldNames = precheck.field_names

        let result = checkAnyFieldEqualsPrecheck(responses, shownWhenFieldNames, shownWhenValue)
        if (result === false || result === undefined) {
          return false
        }
      }

      if (precheck.operation === "any_of") {
        let shownWhenField = precheck.field

        let result = checkAnyOfPrecheck(responses, shownWhenField, shownWhenValue)
        if (result === false || result === undefined) {
          return false
        }
      }

    }

  }
  return true
}

const RenderQuestion = props => {

  const fieldConfig = props.fieldConfig
  const optional = fieldConfig.required ? false : true

  if (!isFieldQuestionVisible(props.doableConfig, fieldConfig, props.responses)) {
    return null
  }

  if (fieldConfig.field_type === 'boolean') {
    return (
      <RadioQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        options={YES_NO_OPTIONS}
        inline={true}
        name={fieldConfig.field_identifier}
        selected={props.selected}
        optionSelected={props.optionSelected}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please choose an option' : null
        }
      />
    );
  } else if (fieldConfig.field_type === 'radio') {
    return (
      <RadioQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        options={fieldConfig.choices}
        name={fieldConfig.field_identifier}
        selected={props.selected}
        optionSelected={props.optionSelected}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please choose an option' : null
        }
      />
    );
  } else if (fieldConfig.field_type === 'checkbox') {
    return (
      <DynamicCheckboxQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        options={fieldConfig.choices}
        fieldId={fieldConfig.field_identifier}
        selected={props.selected}
        onChange={props.onChange}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please select all that apply' : null
        }
      />
    );
  } else if (fieldConfig.field_type === 'confirmation') {
    return (
      <DynamicCheckboxQuestion
        question={null}
        hint={fieldConfig.hint}
        options={[{ label: fieldConfig.display_name, value: true }]}
        fieldId={fieldConfig.field_identifier}
        selected={props.selected}
        onChange={props.onChange}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please tick the box below' : null
        }
      />
    );
  } else if (fieldConfig.field_type === 'text') {
    return (
      <TextQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        valueEntered={props.optionSelected}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please fill this in' : null
        }
      />
    );
  } else if (fieldConfig.field_type === 'postcode') {
    return (
      <TextQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        valueEntered={props.optionSelected}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please fill this in' : null
        }
        size="small"
      />
    );
  } else if (fieldConfig.field_type === 'text_area') {
    return (
      <TextareaQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        valueEntered={props.optionSelected}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please fill this in' : null
        }
      />
    );
  } else if (fieldConfig.field_type === 'integer') {
    return (
      <IntegerQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        valueEntered={props.optionSelected}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please fill this in' : null
        }
      />
    );
  } else if (fieldConfig.field_type === 'phone_number' || fieldConfig.field_type === 'mobile_number') {
    return (
      <PhoneNumberQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        valueEntered={props.optionSelected}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please fill this in' : null
        }
      />
    );
  } else if (fieldConfig.field_type === 'email_address') {
    return (
      <EmailAddressQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        valueEntered={props.optionSelected}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please fill this in' : null
        }
      />
    );
  } else if (fieldConfig.field_type === 'money') {
    return (
      <MoneyQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        valueEntered={props.optionSelected}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please fill this in' : null
        }
      />
    );

  } else if (
    fieldConfig.field_type === 'date' ||
    fieldConfig.field_type === 'must_happen_by_date' ||
    fieldConfig.field_type === 'date_in_the_past'
  ) {
    return (
      <DateQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        name={fieldConfig.field_identifier}
        onChange={props.onDateChange}
        value={props.value}
        valueEntered={props.onDateChange}
        optional={optional}
        validate={props.validateDate}
        validationError={
          props.validationError ? 'Please enter a date' : null
        }
      />
    );
  } else if (fieldConfig.field_type === 'year') {
    return (
      <YearQuestion
        question={fieldConfig.display_name}
        hint={fieldConfig.hint}
        value={props.value}
        valueEntered={props.optionSelected}
        optional={optional}
        validate={props.validate}
        validationError={
          props.validationError ? 'Please enter a year' : null
        }
        size="small"
      />
    );
  } else {
    return <></>
  }


}

export default RenderQuestion;
