import React, { Component } from 'react';
import HelloSign from 'hellosign-embedded';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media';
import Card from "../../components/Card";
import { hideFrontChatWidget } from "../../utils/frontChatUtils";

const HellosignWrapper = styled.div`
  min-height: 500px;
`;

const ImageWrapper = styled.div`
  img {
    display: none;
  }
  ${mediaQueries.md} {
    img {
      display: block;
    }
  }
`;

class PerformHellosignSignature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      signingUrl: null,
      viewportContent: null
    };

  }

  setViewportForHellosign() {
    const mvp = document.getElementById('meta-viewport')
    const oldContent = mvp.getAttribute('content')
    this.setState({viewportContent: oldContent})
    mvp.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0')
  }

  resetViewportForHellosign() {
    const mvp = document.getElementById('meta-viewport')
    if (this.state.viewportContent) {
      mvp.setAttribute('content', this.state.viewportContent)
    }
  }

  componentDidMount() {
    let apiURL = '/api/signature_request/' + this.props.flowState.formRequestId + '/sign_url'
    if ( this.props.flowState.smsCode ) {
      apiURL = apiURL + '?sms_code=' + encodeURIComponent(this.props.flowState.smsCode)
    }

    fetch(apiURL)
      .then((resp) => {
        if (!resp.ok) {
          throw resp
        }
        return resp.json()
      })
      .then(
        (data) => {
          hideFrontChatWidget();
          this.setViewportForHellosign()

          this.setState({
            loading: false,
            signatureUrl: data.signatureUrl,
          });

          const client = new HelloSign();

          client.on('finish', () => {
            this.resetViewportForHellosign()
            this.props.onStepComplete()
          })

          client.open(data.signatureUrl, {
            clientId: window.appConfig.hellosign_client_id,
            allowCancel: false,
          })

        },
        (err) => {
          if (err.json) {
            err.json().then(
              (errorData) => {
                if (errorData.error === "invalid_sms_code") {
                  this.setState({
                    loading: false,
                    error: 'That code wasn’t right. To get a new code, go back to your email and use the link again.',
                  });
                } else {
                  this.setState({
                    loading: false,
                    error: 'Failed to load signature details',
                  });
                }
              }
            )
          } else {
            this.setState({
              loading: false,
              error: 'Failed to load signature details',
            });
          }
        }
      );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    let content = null
    if (this.state.loading === false && !!this.state.error) {
      content = (
        <Card title="Sorry, that didn’t work" removeTopPadding>
          {this.state.error}
        </Card>
      )
    } else {
      content = (
        <HellosignWrapper>
          <div id="hellosign-mount" data-testid="hellosign-mount" />
        </HellosignWrapper>
      )
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        {content}
        <ImageWrapper>
          <Image src={imageOfficeWorking} fullWidth />
        </ImageWrapper>
      </React.Fragment>
    );
  }
}

export default PerformHellosignSignature;
