import React, { Component } from 'react';
import Action from '../../components/Action';
import Card from '../../components/Card';
import RadioQuestion from '../../components/RadioQuestion';
import DateQuestion from '../../components/DateQuestion';
import { TextareaQuestion } from '../../components/TextQuestion';
import RadioConditional from '../../components/RadioConditional';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { isDayValid, isMonthValid, isYearValid } from "../../utils/textUtils";

const completionCallOptions = [
  { title: "Yes, I'd like you to give me a ring", value: true },
  { title: 'No, please go ahead', value: false },
];

class RemoCompletionDate extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      specificCompletionDateRequested: 'Please choose an option',
      completionDate: 'Please enter a date',
      completionCall: 'Please choose an option',
    };

    if (this.props.completionDate) {
      const details = this.props.completionDate;
      this.state = {
        specificCompletionDateRequested:
          details.specificCompletionDateRequested,
        completionDate: {
          day: details.completionDate.day,
          month: details.completionDate.month,
          year: details.completionDate.year,
        },
        completionDateRequestDetails: details.completionDateRequestDetails,
        completionCall: details.completionCall,
        errors: {},
        errorMessages: errorMessages,
        dateErrors: {}
      };
    } else {
      this.state = {
        specificCompletionDateRequested: null,
        completionDate: {
          day: '',
          month: '',
          year: '',
        },
        completionDateRequestDetails: '',
        completionCall: null,
        errors: {},
        errorMessages: errorMessages,
        dateErrors: {}
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let formIsValid = true;

    if (fields['specificCompletionDateRequested'] === null) {
      errors['specificCompletionDateRequested'] = true;
      formIsValid = false;
    }

    if (fields['specificCompletionDateRequested'] === true) {
      if (
        !fields['completionDate'].day ||
        !fields['completionDate'].month ||
        !fields['completionDate'].year
      ) {
        errors['completionDate'] = true;
        formIsValid = false;
      }
    }

    if (fields['completionCall'] === null) {
      errors['completionCall'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleDateChange = field => event => {
    let completionDate = {
      ...this.state.completionDate,
    };
    completionDate[field] = event.target.value;
    this.setState({
      completionDate: completionDate,
    });
  };

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleDateFieldValidation = field => datePart => value => {
    let dateErrors = { ...this.state.dateErrors };
    let errors = { ...this.state.errors };
    let dateField = dateErrors[field] || {};

    if (datePart === "day") {
      dateField["day"] = !isDayValid(value)
    }

    if (datePart === "month") {
      dateField["month"] = !isMonthValid(value)
    }

    if (datePart === "year") {
      dateField["year"] = !isYearValid(value)
    }

    dateErrors[field] = dateField

    const allValues = Object.values(dateErrors[field]);
    const dateFieldIsValid = allValues.every(item => item === false);
    errors[field] = !dateFieldIsValid;

    this.setState({ errors, dateErrors });
  };

  saveCompletionDate = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        completionDate: {
          specificCompletionDateRequested: this.state
            .specificCompletionDateRequested,
          completionDate: this.state.completionDate,
          completionDateRequestDetails: this.state.completionDateRequestDetails,
          completionCall: this.state.completionCall,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Do you have any specific dates we should be aware of?" removeTopPadding>
          <RadioQuestion
            question={this.props.clientIsCompany? "When would you like us to switch the mortgage?" : "When would you like us to switch your mortgage?"}
            options={[
              { title: 'As soon as possible', value: false },
              { title: 'On a specific date', value: true },
            ]}
            name="specificCompletionDateRequested"
            selected={this.state.specificCompletionDateRequested}
            optionSelected={this.handleInputChange(
              'specificCompletionDateRequested',
            )}
            validate={this.handleFieldValidation(
              'specificCompletionDateRequested',
            )}
            validationError={
              this.state.errors.specificCompletionDateRequested
                ? this.state.errorMessages
                  .specificCompletionDateRequested
                : null
            }
          />

          {this.state.specificCompletionDateRequested === true && (
            <RadioConditional>
              <DateQuestion
                question={this.props.clientIsCompany? "Enter the date you would like us to switch the mortgage on" : "Enter the date you would like us to switch your mortgage on"}
                name="completionDate"
                onChange={this.handleDateChange}
                value={this.state.completionDate}
                validate={this.handleDateFieldValidation(
                  'completionDate',
                )}
                validationError={
                  this.state.errors.completionDate
                    ? this.state.errorMessages.completionDate
                    : null
                }
              />
            </RadioConditional>
          )}

          <TextareaQuestion
            question={this.props.clientIsCompany? "Is there anything else you want to tell us about the date to switch the mortgage" : "Is there anything else you want to tell us about the date to switch your mortgage"}
            optional={true}
            value={this.state.completionDateRequestDetails}
            valueEntered={this.handleInputChange(
              'completionDateRequestDetails',
            )}
          />

          <RadioQuestion
            question={this.props.clientIsCompany? "When we’ve lined everything up, we’ll email you to let you know we’re switching the mortgage over" : "When we’ve lined everything up, we’ll email you to let you know we’re switching your mortgage over"}
            hint="Would you prefer us to give you a ring before we do that to confirm you’re happy to go ahead?"
            options={completionCallOptions}
            name="completionCall"
            selected={this.state.completionCall}
            optionSelected={this.handleInputChange('completionCall')}
            validate={this.handleFieldValidation('completionCall')}
            validationError={
              this.state.errors.completionCall
                ? this.state.errorMessages.completionCall
                : null
            }
          />

          <Action onClick={this.saveCompletionDate}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default RemoCompletionDate;
