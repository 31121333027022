import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useSelectedCase } from "./utils/hooks";
import {describeTransactionType} from "../../utils/textUtils";

import EnquiriesList from "./EnquiriesList";
import TodoSummary from './TodoSummary';
import TodoList from "./TodoList";
import ListCompleteRequest from "./ListCompleteRequest";
import PageHeader from "../../components/PageHeader";
import ReferralPanel from "../../components/ReferralPanel";
import StatusUpdate from './StatusUpdate';
import styled from "styled-components/macro";

import Card from '../../components/Card';
import DashboardCard from '../../components/DashboardCard';
import NotificationList from "./NotificationList";
import {loadDashboardData} from './utils/api';
import { formatMoneyNum } from '../../utils/moneyUtils';


const ViewAll = styled(Link)`
  display: inline-block;
  color: ${props => props.theme.color.junoLink};
  margin-top: 1.5em;
  font-size: 16px;
  border-bottom: 1px solid transparent;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 3px solid ${props => props.theme.color.junoFocus};
  }
`;

const CaseDetails = () => {
  const session = useSelector(state => state.dashboardState.session);
  const selectedCase = useSelectedCase({caseScreen: 'case'});
  const dispatch = useDispatch();

  useEffect(() => {
    loadDashboardData(dispatch);
  }, [dispatch]);

  if (!selectedCase) {
    return (
      <>
        <PageHeader>
          Hello {session.first_name}
        </PageHeader>

        <Card title="Case not found" removeTopPadding>
          <p>
            We couldn't find that case.
          </p>
          <p>
            If you can’t find the case you’re looking for, please get in touch. Email us at <a href="mailto:hello@juno.legal">hello@juno.legal</a> along with the case reference or property address.
          </p>
          <p>
            <Link to="/dashboard">Back to your cases</Link>
          </p>
        </Card>
      </>
    )
  }

  const recentCompleteRequests = selectedCase.doables.filter(d =>
    d.status === 'complete' && d.doable_type !== 'enquiry'
  ).slice(-5);

  let address = ''
  if (!!selectedCase.address) {
    address = 'of ' + selectedCase.address
  }

  const propertyPrice = selectedCase.case_data?.property_price;
  const formattedPropertyPrice = propertyPrice ? formatMoneyNum(propertyPrice) : '';

  const transactionType = describeTransactionType(selectedCase.case_type);
  const cardTitle = `Your ${formattedPropertyPrice} ${transactionType} ${address} (${selectedCase.case_reference})`;

  return (
    <>
      <PageHeader>
        Hello {session.first_name}<br />
        {cardTitle}
      </PageHeader>

      <TodoSummary transactionType={transactionType} selectedCase={selectedCase} />

      <TodoList selectedCase={selectedCase} />

      {selectedCase.notifications && selectedCase.notifications.length > 0 && (
        <NotificationList selectedCase={selectedCase} />
      )}

      <EnquiriesList selectedCase={selectedCase} />

      <StatusUpdate
        title={`Current status of your ${describeTransactionType(selectedCase.case_type)}`}
        selectedCase={selectedCase} />

      {recentCompleteRequests.length > 0 && (
        <DashboardCard title="Things you’ve done">
          <ListCompleteRequest completeRequests={recentCompleteRequests} />
          <ViewAll to={`/dashboard/${selectedCase.case_reference}/things-youve-done`}>
            View all things you’ve done
          </ViewAll>
        </DashboardCard>
      )}

      <ReferralPanel
        showReferral={!!session.referral_code}
        showFindOutMore={true}
        referralCode={session.referral_code && session.referral_code.code} />
    </>
  )
};

export default CaseDetails
