import { createSlice } from '@reduxjs/toolkit';

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    gaClientId: null,
  },
  reducers: {
    gaClientIdLoaded: (state, action) => {
      state.gaClientId = action.payload;
    }
  },
});

export const { gaClientIdLoaded } = analyticsSlice.actions;

export const selectGAClientId = (state) => state.analytics.gaClientId;

export default analyticsSlice.reducer;
