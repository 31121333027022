import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import Heading from '../../components/Heading';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

const yesNoCallMeOptions = [
  { title: 'Yes', value: true },
  { title: 'No, I prefer to be called...', value: false },
];

class YourName extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      firstName: 'Enter your first name',
      lastName: 'Enter your last name',
      hasPreviousName: 'Let us know if you have changed your last name',
      previousName: 'Please fill this in',
      firstNameIsGivenName: 'Let us know the name we should use',
      givenName: 'Please fill this in',
    };

    if (this.props.yourName) {
      const details = this.props.yourName;
      this.state = {
        client1: { ...details.client1 },
        errors: { ...details.errors },
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        client1: {
          companyName: '',
          firstName: '',
          lastName: '',
          hasPreviousName: null,
          previousName: '',
          firstNameIsGivenName: null,
          givenName: '',
        },
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state.client1 };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (!fields['firstName']) {
      const field = 'firstName';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields['lastName']) {
      const field = 'lastName';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['hasPreviousName'] === null) {
      const field = 'hasPreviousName';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['hasPreviousName'] === true) {
      if (!fields['previousName']) {
        const field = 'previousName';
        formIsValid = false;
        errors[field] = errorMessages[field];
      }
    }

    if (fields['firstNameIsGivenName'] === null) {
      const field = 'firstNameIsGivenName';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['firstNameIsGivenName'] === false) {
      if (!fields['givenName']) {
        const field = 'givenName';
        formIsValid = false;
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state.client1[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleClient1 = field => event => {
    let client1 = { ...this.state.client1 };
    client1[field] = event;
    this.setState({ client1 });
  };

  canWeUseYourFirstName = () => {
    return 'Can we call you ‘' + this.state.client1.firstName + '’?';
  };

  saveYourName = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        yourName: {
          client1: this.state.client1,
          errors: this.state.errors,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card
          title="Our promise: straightforward conveyancing that’s clear, convenient and
          reliable"
          removeTopPadding
        >
          <Heading level="2" size="4">
            Let’s get started. It’ll take about 5 minutes to sign up.
          </Heading>

          {this.props.clientIsCompany && (
            <TextQuestion
              question="What's the registered name of your company?"
              optional={true}
              hint="If you don't yet know your company name, please leave this blank."
              value={this.state.client1.companyName || ''}
              valueEntered={this.handleClient1('companyName')}
            />
          )}

          <TextQuestion
            question="What’s your first name?"
            value={this.state.client1.firstName || ''}
            valueEntered={this.handleClient1('firstName')}
            validate={this.handleFieldValidation('firstName')}
            validationError={
              this.state.errors.firstName ? this.state.errors.firstName : null
            }
          />

          <TextQuestion
            question="And your last name?"
            value={this.state.client1.lastName || ''}
            valueEntered={this.handleClient1('lastName')}
            validate={this.handleFieldValidation('lastName')}
            validationError={
              this.state.errors.lastName ? this.state.errors.lastName : null
            }
          />

          <RadioQuestion
            question="Have you ever changed your last name?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="hasPreviousName"
            selected={this.state.client1.hasPreviousName}
            optionSelected={this.handleClient1('hasPreviousName')}
            validate={this.handleFieldValidation('hasPreviousName')}
            validationError={
              this.state.errors.hasPreviousName
                ? this.state.errors.hasPreviousName
                : null
            }
          />

          {this.state.client1.lastName && this.state.client1.hasPreviousName && (
            <RadioConditional>
              <TextQuestion
                question="What was your last name?"
                value={this.state.client1.previousName || ''}
                valueEntered={this.handleClient1('previousName')}
                validate={this.handleFieldValidation('previousName')}
                validationError={
                  this.state.errors.previousName
                    ? this.state.errors.previousName
                    : null
                }
              />
            </RadioConditional>
          )}

          {this.state.client1.firstName &&
            this.state.client1.lastName &&
            this.state.client1.hasPreviousName !== undefined && (
              <RadioQuestion
                question={this.canWeUseYourFirstName()}
                options={yesNoCallMeOptions}
                inline={true}
                name="firstNameIsGivenName"
                selected={this.state.client1.firstNameIsGivenName}
                optionSelected={this.handleClient1('firstNameIsGivenName')}
                validate={this.handleFieldValidation('firstNameIsGivenName')}
                validationError={
                  this.state.errors.firstNameIsGivenName
                    ? this.state.errors.firstNameIsGivenName
                    : null
                }
              />
            )}
          {this.state.client1.firstName &&
            this.state.client1.lastName &&
            this.state.client1.hasPreviousName !== undefined &&
            this.state.client1.firstNameIsGivenName === false && (
              <RadioConditional>
                <TextQuestion
                  question="What name would you like us to use?"
                  value={this.state.client1.givenName || ''}
                  valueEntered={this.handleClient1('givenName')}
                  validate={this.handleFieldValidation('givenName')}
                  validationError={
                    this.state.errors.givenName
                      ? this.state.errors.givenName
                      : null
                  }
                />
              </RadioConditional>
            )}

          <Action onClick={this.saveYourName}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default YourName;
