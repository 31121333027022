import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageRelaxingOnSofa from '../../images/relaxing-on-sofa.png';

class ClientIntro extends Component {
  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Hello, and welcome to Juno!" removeTopPadding>
          <p>
            Thanks for choosing us to handle your conveyancing. As we start work
            on your case, we need you to tell us a few things about yourself.
          </p>
          <p>
            It'll take just a couple of minutes to answer all of our questions.
            Once you've signed up we'll have everything we need to get cracking.
          </p>
          <Action onClick={() => this.props.onStepComplete({})}>
            Let’s get started
          </Action>
        </Card>
        <Image src={imageRelaxingOnSofa} fullWidth />
      </React.Fragment>
    );
  }
}

export default ClientIntro;
