import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import TextQuestion from '../../components/TextQuestion';
import TrustpilotBanner from '../../components/TrustpilotBanner';
import { parseTenure } from '../../utils/queryStringUtils';
import { TENURE_OPTIONS, YES_NO_OPTIONS } from '../../constants/index';
import QuoteLandingPageCopy from '../Quote/QuoteLandingPageCopy';
import ReferralCodeControl from "./ReferralCodeControl";
import ReferralCodeBanner from '../../components/ReferralCodeBanner';

class QuoteRemortgagingProperty extends Component {
  constructor(props) {
    super(props);

    if (this.props.remortgagingDetails) {
      const rd = this.props.remortgagingDetails;
      this.state = {
        mortgageAmount: rd.mortgageAmount ? this.formatMoneyWithCurrency(rd.mortgageAmount) : '',
        tenure: rd.tenure,
        clientIsCompany: rd.clientIsCompany,
        isBuyToLet: rd.isBuyToLet,
        applyEnabled: false,
        referral: this.props.referral,
        errors: {},
      };
    } else {
      this.state = {
        mortgageAmount: '',
        tenure: parseTenure(),
        clientIsCompany: null,
        isBuyToLet: null,
        applyEnabled: false,
        referral: this.props.referral,
        errors: {},
      };
    }

    if (this.props.discountCode) {
      this.applyDiscount();
    }
  }

  selectTenure = t => {
    this.setState({ tenure: t });
  };

  mortgageAmountEntered = p => {
    this.setState({ mortgageAmount: p });
  };

  selectClientIsCompany = c => {
    this.setState({ clientIsCompany: c });
  };

  selectIsBuyToLet = btl => {
    this.setState({ isBuyToLet: btl });
  };

  formatMoneyWithCurrency = p => {
    let formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    });

    let moneyWithCurrency = formatter.format(p);

    if (moneyWithCurrency.includes('.00')) {
      let i = moneyWithCurrency.indexOf('.');
      return moneyWithCurrency.substring(0, i);
    }

    return moneyWithCurrency;
  };

  normalisePrice = p => {
    const stripCharsRegex = /[£, ]/g;
    return p.replace(stripCharsRegex, '');
  };

  isPriceValid = price => {
    const allowedCharsRegex = /^[0-9£,. ]+$/;
    if (allowedCharsRegex.test(String(price))) {
      const normalised = this.normalisePrice(price);

      const decimalPlaceRegex = /^\d+\.?\d{0,2}$/;

      return decimalPlaceRegex.test(String(normalised));
    }
    return false;
  };

  onReferralCodeApplied = referral => {
    this.setState({ referral: referral })
  }

  handleValidation = () => {
    const fields = { ...this.state };
    let errors = {};
    let formIsValid = true;

    if (fields['mortgageAmount'] == null || !this.isPriceValid(fields['mortgageAmount'])) {
      errors['mortgageAmount'] = true;
      formIsValid = false;
    }

    if (fields['tenure'] == null) {
      errors['tenure'] = true;
      formIsValid = false;
    }

    if (fields['clientIsCompany'] == null) {
      errors['clientIsCompany'] = true;
      formIsValid = false;
    }

    if (fields['isBuyToLet'] == null) {
      errors['isBuyToLet'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  };

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  handlePriceFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field] || !this.isPriceValid(this.state[field])) {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  saveSelection = () => {
    if (this.handleValidation()) {
      this.props.onStepComplete({
        remortgagingDetails: {
          tenure: this.state.tenure,
          price: this.normalisePrice(this.state.mortgageAmount), // price is used in the generic reject quote check
          // This has been replaced with mortgageAmount in the quote details page
          // propertyValue: this.normalisePrice(this.state.propertyValue),
          mortgageAmount: this.normalisePrice(this.state.mortgageAmount), // but we actually price based on the mortgage
          clientIsCompany: this.state.clientIsCompany,
          isBuyToLet: this.state.isBuyToLet,
        },
        referral: this.state.referral,
      });
    }
  };

  render() {
    let cardTitle;
    let priceHint;
    let buttonText;
    let yourOrTheir;

    if (this.props.mode === 'direct') {
      cardTitle = 'Get your instant quote';

      priceHint =
        'If you’re not sure just put an estimate – you can change it later';

      buttonText = 'Get your quote';
      yourOrTheir = 'your'
    } else {
      cardTitle = 'Tell us about their property';
      yourOrTheir = 'their'
      buttonText = 'Continue';
    }

    return (
      <React.Fragment>
        <ScrollToTop />

        {this.props.showLandingCopy && (
          <QuoteLandingPageCopy pageWidth={this.props.pageWidth} campaign={this.props.campaign} />
        )}

        <Card
          title={cardTitle}
          removeTopPadding={this.props.showLandingCopy ? true : null}
        >

          <TextQuestion
            question={this.props.mode === 'direct' ? 'Value of your new mortgage' : 'How much are they borrowing?'}
            hint={priceHint}
            value={this.state.mortgageAmount}
            valueEntered={this.mortgageAmountEntered}
            validate={this.handlePriceFieldValidation('mortgageAmount')}
            validationError={
              this.state.errors.mortgageAmount ? 'Please enter a valid price' : null
            }
          />

          <RadioQuestion
            question="Is the property:"
            options={TENURE_OPTIONS}
            name="tenure"
            selected={this.state.tenure}
            optionSelected={this.selectTenure}
            validate={this.handleFieldValidation('tenure')}
            validationError={
              this.state.errors.tenure ? 'Please choose an option' : null
            }
          />

          <RadioQuestion
            question={this.props.mode === 'direct' ? 'Do you own the property as a limited company?' : 'Do they own the property as a limited company?'}
            options={YES_NO_OPTIONS}
            inline={true}
            name="clientIsCompany"
            selected={this.state.clientIsCompany}
            optionSelected={this.selectClientIsCompany}
            validate={this.handleFieldValidation('clientIsCompany')}
            validationError={
              this.state.errors['clientIsCompany']
                ? 'Please choose an option'
                : null
            }
          />

          <RadioQuestion
            question='Is this a buy-to-let remortgage?'
            options={YES_NO_OPTIONS}
            inline={true}
            name="isBuyToLet"
            selected={this.state.isBuyToLet}
            optionSelected={this.selectIsBuyToLet}
            validate={this.handleFieldValidation('isBuyToLet')}
            validationError={
              this.state.errors['isBuyToLet']
                ? 'Please choose an option'
                : null
            }
          />

          {!!this.props.referral &&
            <ReferralCodeBanner text={this.props.referral.discountMessage} small={true} />
          }

          {!this.props.referralLocked &&
            <ReferralCodeControl
              audience={this.props.mode === "referrer" ? "referrer" : null}
              referral={this.state.referral}
              onReferralCodeApplied={this.onReferralCodeApplied}
              />
          }

          <Action onClick={this.saveSelection}>{buttonText}</Action>
        </Card>

        <TrustpilotBanner pageWidth={this.props.pageWidth} />
      </React.Fragment>
    );
  }
}

export default QuoteRemortgagingProperty;
