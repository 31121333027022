import React, { Component } from 'react';
import Card from '../../components/Card';
import TextQuestion from '../../components/TextQuestion';
import RadioQuestion from '../../components/RadioQuestion';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';

const relationshipToRegisteredOwnerOptions = [
  {
    title: 'I’m an executor for someone who has died',
    value: 'sellerHasGrantOfProbate',
  },
  {
    title: 'I have power of attorney for the registered owner',
    value: 'sellerHasPowerOfAttorney',
  },
  { title: 'Other', value: 'other' },
];

class SellingClient1Ownership extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      registeredOwnerFullName: 'Enter their full name',
      relationshipToRegisteredOwner: 'Please choose an option',
    };

    if (this.props.registeredOwner) {
      const details = this.props.registeredOwner;
      this.state = {
        registeredOwnerFullName: details.registeredOwnerFullName,
        relationshipToRegisteredOwner: details.relationshipToRegisteredOwner,
        errors: { ...this.props.errors },
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        registeredOwnerFullName: '',
        relationshipToRegisteredOwner: null,
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (!fields['registeredOwnerFullName']) {
      const field = 'registeredOwnerFullName';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['relationshipToRegisteredOwner'] === null) {
      const field = 'relationshipToRegisteredOwner';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }
    this.setState({ errors });
  };

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  saveSellingClient1Ownership = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        registeredOwner: {
          registeredOwnerFullName: this.state.registeredOwnerFullName,
          relationshipToRegisteredOwner: this.state
            .relationshipToRegisteredOwner,
        },
        errors: this.state.errors,
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Ok, we’ll need a bit more information" removeTopPadding>
          <TextQuestion
            question="What’s the registered owner’s full name?"
            value={this.state.registeredOwnerFullName || ''}
            valueEntered={this.handleInputChange('registeredOwnerFullName')}
            validate={this.handleFieldValidation('registeredOwnerFullName')}
            validationError={
              this.state.errors.registeredOwnerFullName
                ? this.state.errors.registeredOwnerFullName
                : null
            }
          />

          <RadioQuestion
            question="What’s your relationship with the registered owner?"
            options={relationshipToRegisteredOwnerOptions}
            inline={false}
            name="relationshipToRegisteredOwner"
            selected={this.state.relationshipToRegisteredOwner}
            optionSelected={this.handleInputChange(
              'relationshipToRegisteredOwner',
            )}
            validate={this.handleFieldValidation(
              'relationshipToRegisteredOwner',
            )}
            validationError={
              this.state.errors.relationshipToRegisteredOwner
                ? this.state.errors.relationshipToRegisteredOwner
                : null
            }
          />

          <Action onClick={this.saveSellingClient1Ownership}>Continue</Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default SellingClient1Ownership;
