import React from 'react';
import { createDataTestId, createId } from './utils/helpers';
import Question, { Optional } from './Question';
import Label from './Label';
import Hint from './Hint';
import Input from './Input';
import Error from './Error';


function YearQuestion(props) {
  const componentName = props.name ? props.name : null
  const componentId = createId('input', props.question, componentName);
  const dataTestId = createDataTestId('input', props.question, componentName);

  return (
    <Question>
      <Label htmlFor={componentId}>
        {props.question} {props.optional ? <Optional /> : null}
        {props.hint ? <Hint>{props.hint}</Hint> : null}
        {props.validationError ? <Error>{props.validationError}</Error> : null}
      </Label>
      <Input
        {...props}
        type={props.type ? props.type : 'text'}
        inputmode="numeric"
        pattern="[0-9]*"
        onChange={event => props.valueEntered(event.target.value)}
        onFocus={props.validate}
        onBlur={props.validate}
        id={componentId}
        data-testid={dataTestId}
        value={props.value}
        error={props.validationError ? props.validationError : null}
        size={props.size}
      />
    </Question>
  );
}

export default YearQuestion;
