import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class RemoPropertyAddress extends Component {
  constructor(props) {
    super(props);

    if (this.props.propertyAddress) {
      const details = { ...this.props.propertyAddress };
      this.state = {
        propertyAddress: {
          addressLine1: details.addressLine1,
          addressLine2: details.addressLine2,
          addressLine3: details.addressLine3,
          addressTown: details.addressTown,
          addressCounty: details.addressCounty,
          addressPostcode: details.addressPostcode,
        },
        errors: {},
      };
    } else {
      this.state = {
        propertyAddress: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          addressTown: '',
          addressCounty: '',
          addressPostcode: '',
        },
        errors: {},
        errorMessages: {
          addressLine1: 'Enter the first line of the property address',
          addressTown: 'Enter the town',
          addressPostcode: 'Enter a postcode',
        },
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (!fields.propertyAddress['addressLine1']) {
      const field = 'addressLine1';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields.propertyAddress['addressTown']) {
      const field = 'addressTown';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields.propertyAddress['addressPostcode']) {
      const field = 'addressPostcode';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleAddressFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type === 'blur') {
      if (!this.state.propertyAddress[field]) {
        errors[field] = errorMessages[field];
      }
    } else {
      errors[field] = null;
    }

    this.setState({ errors });
  };

  handleAddressChange = field => event => {
    let propertyAddress = { ...this.state.propertyAddress };
    propertyAddress[field] = event;
    this.setState({ propertyAddress });
  };

  savePropertyAddress = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        propertyAddress: this.state.propertyAddress,
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={this.props.clientIsCompany ? `Tell us the address of the property your company is remortgaging` : `Tell us the address of the property you’re remortgaging`} removeTopPadding>
          <TextQuestion
            question="Address line 1"
            value={this.state.propertyAddress.addressLine1 || ''}
            valueEntered={this.handleAddressChange('addressLine1')}
            validate={this.handleAddressFieldValidation('addressLine1')}
            validationError={
              this.state.errors.addressLine1
                ? this.state.errors.addressLine1
                : null
            }
          />
          <TextQuestion
            question="Address line 2"
            optional={true}
            value={this.state.propertyAddress.addressLine2 || ''}
            valueEntered={this.handleAddressChange('addressLine2')}
          />
          <TextQuestion
            question="Address line 3"
            optional={true}
            value={this.state.propertyAddress.addressLine3 || ''}
            valueEntered={this.handleAddressChange('addressLine3')}
          />
          <TextQuestion
            question="Town"
            value={this.state.propertyAddress.addressTown || ''}
            valueEntered={this.handleAddressChange('addressTown')}
            validationError={
              this.state.errors.addressTown
                ? this.state.errors.addressTown
                : null
            }
          />
          <TextQuestion
            question="County"
            optional={true}
            value={this.state.propertyAddress.addressCounty || ''}
            valueEntered={this.handleAddressChange('addressCounty')}
          />
          <TextQuestion
            question="Postcode"
            size="small"
            value={this.state.propertyAddress.addressPostcode || ''}
            valueEntered={this.handleAddressChange('addressPostcode')}
            validate={this.handleAddressFieldValidation('addressPostcode')}
            validationError={
              this.state.errors.addressPostcode
                ? this.state.errors.addressPostcode
                : null
            }
          />
          <Action onClick={this.savePropertyAddress}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default RemoPropertyAddress;
