import React, { useState, useEffect } from 'react';

import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';

const RemoAccountNumbersSubmission = (props) => {

  const [state, setState] = useState({
    loading: true,
    error: null,
    submitted: false,
  });

  const { remoAccountNumbersFlowState } = props;

  useEffect(() => {
    let isMounted = true;

    const response = {
      formRequestId: remoAccountNumbersFlowState.formRequestId,
      accountNumbers: remoAccountNumbersFlowState.securedLoans.map(loan => loan.accountNumber)
    };

    fetch('/api/remortgageAccountNumbers', {
        method: 'PUT',
        body: JSON.stringify(response),
      }).then(
        resp => {
          if (isMounted) {
            if (!!resp.ok) {
              setState(prevState => ({
                ...prevState,
                loading: false,
                submitted: true
              }));
              props.onFlowComplete();
            } else {
              setState(prevState => ({
                ...prevState,
                error: 'Unable to process your details',
                loading: false,
              }));
            }
          }
        },
        () => {
          if (isMounted) {
            setState(prevState => ({
              ...prevState,
              error: 'Unable to process your details',
              loading: false,
            }));
          }
        },
      );

    return () => {
      isMounted = false;
    };
  }, [remoAccountNumbersFlowState]);

  if (!!state.loading) {
    return null;
  }

  if (state.error) {
    return (
      <>
        <ScrollToTop />
        <Card title="Sorry, that didn't work" removeTopPadding>
          {state.error}
        </Card>
      </>
    );
  }

  if (state.submitted) {
    return null;
  }
}

export default RemoAccountNumbersSubmission;
