// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-Pannable-container{overflow:auto}", ""]);
// Exports
exports.locals = {
	"container": "onfido-sdk-ui-Pannable-container"
};
module.exports = exports;
