import React, { Component, StrictMode } from "react"
import { loadDashboardData } from "./utils/api";
import { connect } from "react-redux";
import { Navigate } from 'react-router-dom';

import { ThemeProvider } from "styled-components";
import dashboardTheme from "../../styles/dashboardTheme";
import GlobalStyle from "../../styles/GlobalStyle";

import AgentDashboard from "./AgentDashboard";
import ClientDashboard from "./ClientDashboard";
import BrokerDashboard from "./BrokerDashboard/BrokerDashboard";
import PageNotFound from "../PageNotFound";

class DashboardSelectorComponent extends Component {
  intervalId = null;

  componentDidMount() {
    if (!this.props.showLogin && !this.props.dashboardData.cases) {
      loadDashboardData(this.props.dispatch)
    }

    this.setRefreshInterval();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showLogin !== this.props.showLogin) {
      this.setRefreshInterval();
    }
  }

  componentWillUnmount() {
    this.clearRefresh();
  }

  setRefreshInterval = () => {
    this.clearRefresh()
    if (! this.props.showLogin) {
      // Refresh dashboard data every hour
      this.intervalId = setInterval(this.refreshDashboardData, 60 * 60 * 1000);
    }
  }

  clearRefresh = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  refreshDashboardData = () => {
    if (this.props.dashboardData?.session?.subject_type === 'estate_agent') {
      loadDashboardData(this.props.dispatch);
    }
  }

  render() {
    if (!this.props.showLogin && !this.props.dashboardData.cases) {
      return null
    }

    if (this.props.showLogin === true) {
      return <Navigate to="/login" />;
    }

    if (this.props.dashboardData) {
      switch (this.props.dashboardData.session.subject_type) {
        case "client":
          return <ClientDashboard />

        case "estate_agent":
          return (
            <StrictMode>
              <ThemeProvider theme={dashboardTheme}>
                <AgentDashboard />
                <GlobalStyle />
              </ThemeProvider>
            </StrictMode>
          )

        case "broker":
          return <BrokerDashboard />

        default:
          return <PageNotFound />

      }
    }
  }
}

const mapStateToProps = state => {
  return {
    dashboardData: state.dashboardState,
    showLogin: state.loginState.showLogin,
  }
};

const DashboardSelector = connect(
  mapStateToProps
)(DashboardSelectorComponent);

export default DashboardSelector
