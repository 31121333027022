import React from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media.js';

import Card from '../../components/DashboardCard';
import Details from '../../components/Details';
import NotificationListItem from './NotificationListItem';

const NotificationList = ({ selectedCase }) => {
  const notifications = selectedCase.notifications;
  const navigate = useNavigate();

  const selectNotification = (id) => {
    navigate(`/dashboard/${selectedCase.case_reference}/messages/${id}`);
  };

  let recentNotifications = notifications;
  let olderNotifications = [];

  // Only filter notifications if there five or more
  if (notifications.length >= 5) {

    // Check if there are read messages more than two weeks old
    const currentDate = new Date();
    const cutOffDate = new Date(currentDate.setDate(currentDate.getDate() - 14));

    olderNotifications = notifications.filter(n =>
      new Date(n.created_at) <= cutOffDate && n.status === 'read'
    )

    // Remove older ones from recent list
    if (olderNotifications.length > 0) {
      recentNotifications = notifications.filter(n =>
        !olderNotifications.includes(n)
      )
    }
  }

  return (
    <Card title="Messages" id="messages">
      <StyledNotificationList>
        <NotificationListItem
          notifications={recentNotifications}
          selectNotification={selectNotification} />
      </StyledNotificationList>

      {olderNotifications.length > 0 &&
      <StyledNotificationDetails summary="Show older messages">
        <StyledNotificationList>
          <NotificationListItem
            notifications={olderNotifications}
            selectNotification={selectNotification} />
        </StyledNotificationList>
      </StyledNotificationDetails>
      }
    </Card>
  )
}

export default NotificationList


export const StyledNotificationList = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding-left: 0;
`;

const StyledNotificationDetails = styled(Details)`
  max-width: 100%;
  margin: 0;
  padding-top: 16px;

  summary {
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid ${props => props.theme.color.junoLinkHover};
    }

    ${mediaQueries.md} {
      font-size: ${props => props.theme.fontSizes.p['sm']};
    }

    ::marker {
      font-size: ${props => props.theme.fontSizes.p['lg']};
    }
  }
  `
