import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { isMoneyValid, normaliseMoney } from '../../utils/moneyUtils';
import { isEmailValid } from "../../utils/textUtils";

class Remortgage extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      estimateRemortgagingAnotherPropertyAmount: 'Please fill this in',
      remortgageComplete: 'Please choose an option',
    };

    if (this.props.remortgage) {
      const remortgage = { ...this.props.remortgage };
      this.state = {
        estimateRemortgagingAnotherPropertyAmount:
          remortgage.estimateRemortgagingAnotherPropertyAmount,
        remortgageComplete: remortgage.remortgageComplete,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        estimateRemortgagingAnotherPropertyAmount: '',
        remortgageComplete: null,
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => val => {
    this.setState({ [field]: val });
  };

  selectRemortgageComplete = rc => {
    this.setState({ remortgageComplete: rc });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    let formIsValid = true;

    if (!fields['estimateRemortgagingAnotherPropertyAmount']) {
      errors['estimateRemortgagingAnotherPropertyAmount'] = true;
      formIsValid = false;
    }

    if (
      fields['estimateRemortgagingAnotherPropertyAmount'] !== '' &&
      !isMoneyValid(fields['estimateRemortgagingAnotherPropertyAmount'])
    ) {
      errors['estimateRemortgagingAnotherPropertyAmount'] = true;
      errorMessages['estimateRemortgagingAnotherPropertyAmount'] =
        'Please enter a valid amount';
      formIsValid = false;
    }

    if (fields['remortgageComplete'] === null) {
      errors['remortgageComplete'] = true;
      formIsValid = false;
    }
    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleEmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isEmailValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid email address';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleMoneyValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isMoneyValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid amount';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  saveRemortgage = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        remortgage: {
          estimateRemortgagingAnotherPropertyAmount: normaliseMoney(
            this.state.estimateRemortgagingAnotherPropertyAmount,
          ),
          remortgageComplete: this.state.remortgageComplete,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about your remortgage" removeTopPadding>
          <TextQuestion
            question="How much money from your remortgage will you contribute to your purchase?"
            value={this.state.estimateRemortgagingAnotherPropertyAmount}
            valueEntered={this.handleInputChange(
              'estimateRemortgagingAnotherPropertyAmount',
            )}
            validate={this.handleMoneyValidation(
              'estimateRemortgagingAnotherPropertyAmount',
            )}
            validationError={
              this.state.errors.estimateRemortgagingAnotherPropertyAmount
                ? this.state.errorMessages.estimateRemortgagingAnotherPropertyAmount
                : null
            }
          />

          <RadioQuestion
            question="Has your remortgage already completed?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="remortgageComplete"
            selected={this.state.remortgageComplete}
            optionSelected={this.selectRemortgageComplete}
            validate={this.handleFieldValidation('remortgageComplete')}
            validationError={
              this.state.errors.remortgageComplete
                ? this.state.errorMessages.remortgageComplete
                : null
            }
          />

          <Action onClick={this.saveRemortgage}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default Remortgage;
