import { configureStore } from '@reduxjs/toolkit';

import analyticsReducer from "../features/analytics/analyticsSlice";
import { dashboardState, loginState } from "../reducers/dashboardReducers";

export const buildStore = () => configureStore({
  reducer: {
    analytics: analyticsReducer,
    dashboardState,
    loginState,
  },
});

const store = buildStore();
export default store;
