import React from 'react';

import { getFormattedDate } from "../../utils/textUtils";

import styled from 'styled-components/macro';
import { Drafts, Markunread} from '@styled-icons/material';

function NotificationListItem(props) {
  return (
    props.notifications.map((n, index) => {
      const isRead = n.status === "read"
      return (
        <StyledNotificationListItem key={index} read={isRead}>
          <StyledNotificationIcon read={isRead}>
            {isRead ? <Drafts size="24" /> : <Markunread size="24" />}
          </StyledNotificationIcon>
          <StyledNotificationText onClick={() => props.selectNotification(n.id)} read={isRead}>
            {n.subject}
          </StyledNotificationText>
          <StyledNotificationDate read={isRead}>
            {getFormattedDate(n.created_at)}
          </StyledNotificationDate>
        </StyledNotificationListItem>
      )
    })
  )
}

export default NotificationListItem


export const StyledNotificationListItem = styled.li`
  display: flex;
  padding: 11px 0 10px 0;
  font-size: 16px;
  color: ${props => props.read ? props.theme.color.grey : props.theme.color.junoText};
  font-weight: ${props => props.read ? 'normal' : 'bold'};
  border-bottom: 1px solid ${props => props.theme.color.paleGrey};
`;

export const StyledNotificationText = styled.span`
  color: ${props => props.read ? props.theme.color.grey : props.theme.color.junoText};
  border-bottom: 1px solid transparent;
  &:hover {
    cursor: pointer;
    border-bottom-color: ${props => props.read ? props.theme.color.grey : props.theme.color.junoText};
  }
`;

export const StyledNotificationIcon = styled.div`
  flex-shrink: 0;
  text-align: left;
  margin-right: 16px;
  color: ${props => props.read ? props.theme.color.grey : props.theme.color.junoText};
`;

export const StyledNotificationDate = styled.div`
  flex-shrink: 0;
  font-size: 16px;
  margin-left: auto;
  padding-left: 16px;
`;
