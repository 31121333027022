import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const JunoImage = styled.div`
  position: relative;
  overflow: hidden;
  bottom: 0;

  width: 100%;

  display: flex;
  align-items: bottom;

  justify-content: flex-end;
  ${props => (props.fullWidth && 'justify-content: center;')};
  ${props => (props.confirmation && 'justify-content: center;')};

  ${mediaQueries.md} {
    bottom: -${props => props.theme.junoImageSpacing['md']};
  }

  img {
    max-width: 100%;
    
    width: 50%;
    ${props => (props.fullWidth && 'width: 100%')};
    ${props => (props.confirmation && 'width: 100%')};
    ${mediaQueries.md} {
      ${props => (props.confirmation && 'width: 75%')};
    }
  }

`;

function Image(props) {
  return (
    <JunoImage fullWidth={props.fullWidth} confirmation={props.confirmation}>
      <img src={props.src} alt={props.alt} />
    </JunoImage>
  );
}

export default Image;
