import React from 'react';
import styled from 'styled-components/macro';

class StyledTodoList extends React.Component {
  render() {
    return (
      <TodoList>
        {this.props.children}
      </TodoList>
    )
  }
}

export default StyledTodoList;

const TodoList = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding-left: 0;
`;
