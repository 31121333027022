import React, { Component } from 'react';

import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media.js';

import Heading from '../../components/Heading';
import List, { ListItem } from '../../components/List';

class OtherClientTodos extends Component {
  render() {

    if (!this.props.otherClients) {
      return null
    }

    return (
      <>
        {this.props.otherClients.map((client, index) => {

          if (! client.doables?.length > 0) {
            return null
          }

          return (
            <StyledOtherClientTodos key={index}>
              <Heading level="2" size="4">
                {client.first_name} has {client.doables.length} thing{client.doables.length > 1 ? 's' : ''} to do
                <StyledSecondaryText>Tell them to log into their dashboard to complete these:</StyledSecondaryText>
              </Heading>
              <StyledList bullet>
                {client.doables.map(doable => {
                  return (
                    <ListItem key={doable.id}>{doable.title}</ListItem>
                  )
                })}
              </StyledList>
            </StyledOtherClientTodos>
          )
        })}
      </>
    );
  }
}

export default OtherClientTodos;

const StyledOtherClientTodos = styled.div`
  margin-top: 2em;

  li {
    font-size: ${props => props.theme.fontSizes.p['sm']};
  }
`

const StyledSecondaryText = styled.div`
  font-weight: ${props => props.theme.fontWeight.regular};
  font-size: ${props => props.theme.fontSizes.p['sm']};
  color: ${props => props.theme.color.junoTextSecondary};
  padding-top: 0.5em;

  ${mediaQueries.md} {
    margin-bottom: -0.5em;
  }
`

const StyledList = styled(List)`
  margin-top: 0;
`
