import React from 'react';
import styled from "styled-components/macro";
import StatusUpdateSection from '../../components/StatusUpdateSection';

function GenericStatusUpdate({ statusUpdate }) {
  if (! statusUpdate) return null;

  const statusUpdateSections = statusUpdate.sections.map((section, index) => {
    return (
      <StatusUpdateSection
        key={index}
        title={section.title}
        descriptions={section.descriptions}
        description_blocks={section.description_blocks}
        status={section.status}
      />
    );
  });

  return (
    <>
      <StatusUpdateInfo>
        Most recent update {new Date(statusUpdate.last_updated).toLocaleString('en-GB')}
      </StatusUpdateInfo>
      {statusUpdateSections}
    </>
  );
}

export default GenericStatusUpdate;

const StatusUpdateInfo = styled.div`
  color: ${props => props.theme.color.junoTextSecondary};
  font-size: ${props => props.theme.fontSizes.p['s']};
  line-height: 1.5;
  margin-bottom: ${props => props.theme.spacingScale[4]};
`;
