import React from 'react';
import { useSelector } from "react-redux";
import { Routes, Route, useLocation } from 'react-router-dom';

import PageLayout from '../../components/PageLayout';
import CaseList from "./CaseList";
import CaseDetails from "./CaseDetails";
import CaseDoable from "./CaseDoable";
import Notification from "./Notification";
import CaseArchive from "./CaseArchive";
import ClientReferral from "./ClientReferral";

const ClientDashboard = (props) => {
  const session = useSelector(state => state.dashboardState.session);
  const location = useLocation();

  const isCaseList = location.pathname === "/dashboard";

  return (
    <PageLayout pageWidth="wide" showLogout={true} background={isCaseList ? 'white' : null}>
      <Routes>
        <Route
          path="/"
          element={<CaseList pageWidth="wide" />} />
        <Route
          path="/:caseRef"
          element={<CaseDetails />} />
        <Route
          path="/:caseRef/things-youve-done"
          element={<CaseArchive />} />
        <Route
          path="/:caseRef/messages/:notificationId"
          element={<Notification />} />
        <Route
          path="/doable/:doableId"
          element={<CaseDoable />} />
        <Route
          path="/referral"
          element={<ClientReferral client={session} />} />
      </Routes>
    </PageLayout>
  )
}

export default ClientDashboard
