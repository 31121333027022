import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media';

const JunoText = styled.div`
  p,
  dl,
  ol,
  ul {
    font-size: ${props => props.theme.fontSizes.p['sm']};
    line-height: normal;
    margin-bottom: 1em;
    ${mediaQueries.md} {
      font-size: ${props => props.theme.fontSizes.p['md']};
    }
  }
  table {
    max-width: 34em;
  }
`;

function Text(props) {
  return <JunoText>{props.children}</JunoText>;
}

export default Text;
