import React, {Component} from 'react';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import {mediaQueries} from '../styles/media.js';
import Question, {Optional} from './Question';
import Label from './Label';
import Hint from './Hint';
import Error from './Error';
import Dropzone from 'react-dropzone';
import { AttachFile, HighlightOff } from '@styled-icons/material'
import { Error as ErrorIcon } from '@styled-icons/material'


export const allowedFileTypes = '.pdf,.png,.tiff,.jpeg,.jpg,.txt';

const UndoText = styled.p`
  color: ${props => props.theme.color.junoTextSecondary};
  margin: 0;
  font-size: 1em;
`;

const DropzoneContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-color: ${props => props.theme.color.junoBorder};
  border-width: 2px;
  border-radius: 16px;
  border-style: dashed;
  background-color: #fafafa;
  color: ${props => props.theme.color.junoTextSecondary};
  outline: none;
  transition: border 0.24s ease-in-out;
  &:hover {
    color: #2196f3;
    border-color: #2196f3;
    cursor: pointer;
  }
`;

const InstructionText = styled.p`
  color: ${props => props.theme.color.junoTextSecondary};
  text-align: center;

  &:hover {
    color: ${props => props.theme.color.junoLinkHover};
  }
`;

const ProgressBar = styled.div`
  max-width: 38rem;
  background: ${props => props.theme.color.paleGrey};
  border-radius: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  margin-top: 0.5em;
`

const ProgressBarStatus = styled.div`
  @keyframes expand {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  background: ${props => props.theme.color.green};
  height: 2px;
  margin: 4px 10px;

  animation: expand 3s linear infinite;
`

const AttachedTitle = styled.h3`
  font-size: 16px;
  margin: 0.5em 0 0.25em 0;
  padding: 0;
  color: ${props => props.uploading ? props.theme.color.grey : props.theme.color.green};
`

const AttachedList = styled.ul`
  color: ${props => props.theme.color.junoText};
  font-family: ${props => props.theme.junoFont};
  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: 1.5;

  margin-top: 0;
  margin-bottom: 1em;
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
    line-height: 1.75;
  }
  list-style-type: none;
  padding-left: 0;
`;

export const AttachedListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: ${props => props.error ? 'normal' : 'bold'};
  color: ${props => props.error ? props.theme.color.red : props.theme.color.junoText};
`;

export const AttachIcon = styled.div`
  padding-top: 3px;
  font-size: 14px;
  transform: ${props => props.error ? 'none' : 'rotate(45deg)'};
  color: ${props => props.error ? props.theme.color.red : props.theme.color.green};
  margin-right: 8px;
`

export const RemoveIcon = styled.div`
  padding-top: 3px;
  margin-left: 12px;
  font-size: 14px;
  color: black;
  &:hover {
    color: red;
    cursor: pointer;
  }
`

class FileUploadQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploads: [],
      uploading: false,
      errorMessage: null,
    }
  }

  componentDidUpdate() {
    const uploading = this.state.uploads.some(u => u.uploading);

    if (uploading !== this.state.uploading) {
      this.setState({uploading: uploading});
      if (this.props.onUploadInProgress) {
        this.props.onUploadInProgress(uploading);
      }
    }
  };

  notifyUploadsChanged = () => {
    this.props.onUploadsChanged(
      this.state.uploads.map(u => u.upload_id).filter(upload_id => !!upload_id),
    );
  };

  updateUploadDetails = (file_id, newDetails) => {
    // Notify parent if an upload has been completed successfully (upload_id being set)
    const callback = !!newDetails.upload_id ? this.notifyUploadsChanged : null

    this.setState(state => {
      const newUploads = [...state.uploads];
      const index = newUploads.findIndex(u => u.file_id === file_id);
      if (index < 0) {
        // file has been removed since the upload was started
        return {};
      }
      Object.assign(newUploads[index], newDetails);
      return {
        uploads: newUploads,
      };
    }, callback);
  };

  uploadFile = (upload) => {
    const formData = new FormData();
    formData.append('file', upload.file, upload.file.name);

    fetch(`/api/dashboard/upload_file`, {
      method: 'POST',
      credentials: 'same-origin',
      body: formData,
    }).then(resp => {
      if (!resp.ok) {
        throw new Error("non-success response");
      }
      return resp.json()
    }).then(data => {
      this.updateUploadDetails(upload.file_id, {
        upload_id: data.upload_id,
        uploading: false,
      });
    }).catch(err => {
      this.updateUploadDetails(upload.file_id, {
        uploading: false,
        failed: true,
      });
    });
  };

  dropAccepted = (files) => {
    // Clear any existing error messages
    this.setState({ errorMessage: null });

    const validFiles = files.filter(file => file.size > 0);

    // Check if any files were rejected due to size
    if (validFiles.length < files.length) {
      // Set an error message for zero-byte files
      this.setState({
        errorMessage: "Zero byte files cannot be uploaded.",
      });
      // If no valid files, exit early
      if (validFiles.length === 0) {
        return;
      }
    }

    const addedUploads = validFiles.map(file => (
      {
        file_id: uuidv4(),
        file: file,
        uploading: true,
        failed: false,
      }
    ));

    let filesChanged = false;
    const notifyCallback = () => { if (filesChanged) { this.notifyUploadsChanged(); }; };

    this.setState((state, props) => {
      if (!props.multiple && state.uploads.length > 0) {
        // we're replacing an existing upload
        filesChanged = true;
      }
      const newUploads = props.multiple ? [...state.uploads] : [];
      newUploads.push(...addedUploads);
      return {
        uploads: newUploads,
        // Keep the existing error message if it's already set, otherwise null
        errorMessage: this.state.errorMessage || null,
      };
    }, notifyCallback);

    addedUploads.forEach(upload => this.uploadFile(upload));
  };

  dropRejected = (files) => {
    this.setState({
      errorMessage: `File format must be ${allowedFileTypes} and less than 20M`,
    });
  };

  removeFile = file_id => event => {
    // prevent the DropZone click handler opening the file dialog when remove
    // is clicked
    event.stopPropagation();

    this.setState(prevState => {
      let newUploads = [...prevState.uploads];
      const index = newUploads.findIndex(u => u.file_id === file_id);
      if (index < 0) {
        // Upload not present - should never happen...
        return {}
      }

      newUploads.splice(index, 1);
      return {
        uploads: newUploads,
      }
    }, this.notifyUploadsChanged);
  };

  render() {

    let uploadInfo = this.state.uploads.map(upload => {
      if (upload.uploading) {
        return (
          <ProgressBar
            key={`progress_${upload.file_id}`}
            data-testid={`${upload.file.name}-progressbar`}
          >
            <ProgressBarStatus />
          </ProgressBar>
        );
      }

      if (upload.failed) {
        return (
          <AttachedListItem key={`failed_${upload.file_id}`} error={true}>
            <AttachIcon error={true}><ErrorIcon size="24" onClick={this.removeFile(upload.file_id)} /></AttachIcon>
            <span error="true">failed, please try again — {upload.file.name}</span>
          </AttachedListItem>
        );
      }

      return (
        <AttachedListItem key={upload.file_id}>
          <AttachIcon><AttachFile size="24" /></AttachIcon>
          <span>{upload.file.name}</span>
          <RemoveIcon>
            <HighlightOff size="24"
              onClick={this.removeFile(upload.file_id)}
              data-testid={`${upload.file.name}-remove`}
            />
          </RemoveIcon>
        </AttachedListItem>
      );
    })

    const uploadedCount = this.state.uploads.filter(u => !!u.upload_id).length;
    return (
      <Question>
        <Label>
          {this.props.question} {this.props.optional && <Optional />}
          {this.props.hint && <Hint>{this.props.hint}</Hint>}
          {this.props.validationError && <Error>{this.props.validationError}</Error>}
          {this.state.errorMessage && <Error>{this.state.errorMessage}</Error>}
        </Label>

        {uploadInfo.length > 0 && (
          <>
            {this.state.uploading && (
              <AttachedTitle uploading={true}>Uploading...</AttachedTitle>
            )}
            {(!this.state.uploading && uploadedCount > 0) && (
              <AttachedTitle>
                Thanks, {uploadedCount > 1 ? "these are" : "that's"} now ready to submit:
              </AttachedTitle>
            )}
            <AttachedList>
              {uploadInfo}
            </AttachedList>
          </>
        )}

        <Dropzone
          onDropAccepted={this.dropAccepted}
          onDropRejected={this.dropRejected}
          multiple={!!this.props.multiple}
          accept={allowedFileTypes}
          maxSize={20971520}
        >
          {({getRootProps, getInputProps}) => (
            <>
              <DropzoneContent
                {...getRootProps({
                  className: 'dropzone',
                })}
              >
                <input {...getInputProps()} />

                {uploadInfo.length > 0 &&
                  <div>
                    {!this.props.multiple &&
                      <UndoText>
                        Not the right one? Click to select a new file
                      </UndoText>
                    }
                  </div>
                }

                {(uploadInfo.length === 0 || this.props.multiple) && (
                  <div>
                    <InstructionText>Drop {uploadInfo.length > 0 && ('more')} {this.props.multiple ? 'files' : 'a file'} here, or click to select a file</InstructionText>
                  </div>
                )}
              </DropzoneContent>
            </>
          )}
        </Dropzone>
      </Question>
    );
  }
}

export default FileUploadQuestion;
