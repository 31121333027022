
import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media.js';
import Button, { SecondaryButton } from '../../components/Button';
import TextQuestion from "../../components/TextQuestion";

class AddTA10Item extends Component {
  render() {
    return (
      <>
        <StyledTextQuestion
          question={this.props.question}
          value={this.props.value}
          valueEntered={this.props.valueEntered}
          validate={this.props.validate}
          validationError={this.props.validationError ? this.props.validationError : null}
        />
        <StyledActions>
          <Button onClick={this.props.onAdd} disabled={this.props.value === '' || this.props.validationError}>
            {this.props.addButtonText}
          </Button>
          <SecondaryButton onClick={this.props.onCancel}>Cancel</SecondaryButton>
        </StyledActions>
      </>
    )
  }
}

const StyledActions = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 0.95em;
  ${mediaQueries.md} {
    margin-top: -0.75em;
  }

  Button {
    margin-right: 0.8em;
  }
`

const StyledTextQuestion = styled(TextQuestion)`
  max-width: 520px;
`

export default AddTA10Item;
