import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

class RemoLeaseholdIntro extends Component {
  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card
          title="We need to find out more about who manages your property"
          removeTopPadding
          headingSize="1"
        >
          <p>
            Because you told us you have a management company, we need to ask you
            some questions about the rest of the property and how it is managed.
          </p>
          <p>This form should take around five minutes to complete.</p>
          <Action onClick={() => this.props.onStepComplete({})}>
            Let’s get started
          </Action>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default RemoLeaseholdIntro;
