import React from 'react';
import styled from 'styled-components/macro';

const StyledCircle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  font-size: 14px;
  font-weight: ${props => props.theme.fontWeight.bold};

  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding-top: 2px;

  border: 2px solid ${props => props.background === 'white' ? props.theme.color.white : null};
  border: 2px solid ${props => props.background === 'purple' ? props.theme.color.purple : null};

  background-color: ${props => props.background === 'purple' ? props.theme.color.purple : null};
  background-color: ${props => props.background === 'white' ? props.theme.color.white : null};

  color: ${props => props.color === 'white' ? props.theme.color.white : null};
  color: ${props => props.color === 'purple' ? props.theme.color.purple : null};
`;

function Circle(props) {
    return (
      <StyledCircle data-testid={props.dataTestId} className={props.className} background={props.background} color={props.color} title={props.title}>
        {props.children}
      </StyledCircle>
    )
}

export default Circle;
