import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Action from '../../components/Action';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

class EsignatureRequestThanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    fetch('/api/signature_request/' + this.props.flowState.formRequestId + '/complete', {
      method: 'POST',
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            error: 'Unable to process your signature',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Unable to process your signature',
          loading: false,
        });
      },
    );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn’t work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    const witnessSignature = this.props.flowState.witnessSignature
    const witnessedSignature = this.props.flowState.witnessedSignature

    let title = "That’s it, thanks!"
    if (!!witnessedSignature) {
      title = `Thanks for witnessing ${witnessedSignature.first_name} ${witnessedSignature.last_name}’s signature`
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={title} removeTopPadding>
          { !! witnessSignature && (
            <p>
              The document is ready for {witnessSignature.first_name} {witnessSignature.last_name} to complete too - please tell them to follow the instructions we’ve sent them by email.
            </p>
          )}
          <p>
            Once everyone’s signed the document, we’ll send you a copy.
          </p>
          <p>
            If we need anything else, we’ll let you know.
          </p>
          {this.props.fromDashboard && (
            <Action to="/dashboard" as={Link}>
              Take me back to my dashboard
            </Action>
          )}
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default EsignatureRequestThanks;
