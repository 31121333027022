import React, { Component } from 'react';
import Action from '../../components/Action';
import Card from '../../components/Card';
import RadioQuestion from '../../components/RadioQuestion';
import { TextareaQuestion } from '../../components/TextQuestion';
import List, { ListItem } from '../../components/List';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

const clientReferrerOptions = [
  {
    title: 'Recommendation from a friend or colleague',
    value: 'Friend or colleague',
  },
  {
    title: 'Recommendation from an estate agent',
    value: 'Estate agent',
  },
  {
    title: 'Recommendation from a mortgage broker',
    value: 'Mortgage broker',
  },
  {
    title: 'Read about Juno in a newspaper',
    value: 'Newspaper',
  },
  { title: 'Saw an online advert', value: 'Online advert' },
];

class AnythingElse extends Component {
  constructor(props) {
    super(props);

    if (this.props.anythingElse) {
      const details = this.props.anythingElse;
      this.state = {
        anythingElse: details.anythingElse,
        clientReferrer: details.clientReferrer,
        errors: {},
      };
    } else {
      this.state = {
        anythingElse: '',
        clientReferrer: '',
        errors: {},
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };

    let errors = {};
    let formIsValid = true;

    if (!fields['clientReferrer']) {
      const field = 'clientReferrer';
      formIsValid = false;
      errors[field] = true;
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      // Hide the error when you interact with the field
      errors[field] = null;
    } else {
      // If the state for this field hasn't been set, we need some input, show an error
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  anythingElseEntered = ae => {
    this.setState({ anythingElse: ae });
  };

  selectClientReferrer = cr => {
    this.setState({ clientReferrer: cr });
  };

  saveAnythingElse = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        anythingElse: {
          anythingElse: this.state.anythingElse,
          clientReferrer: this.state.clientReferrer,
        },
      });
      return;
    }
  };

  render() {
    const transactionType = this.props.transactionType;

    let tt;
    if (transactionType === 'Selling') {
      tt = 'sale';
    } else if (transactionType === 'Buying') {
      tt = 'purchase';
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Is there anything else you’d like to tell us?" removeTopPadding>
          <React.Fragment>
            <p>For example,</p>
            <List bullet>
              <ListItem>
                Would you like to complete before or after a particular date?
              </ListItem>
              <ListItem>
                Has the property undergone any major renovations?
              </ListItem>
              <ListItem>Does the property have any unusual features?</ListItem>
              <ListItem>Is it currently rented out?</ListItem>
              <ListItem>Are there any works ongoing at the property?</ListItem>
              <ListItem>
                Are there any personal circumstances, for example divorce, that
                would affect your {tt}?
              </ListItem>
              <ListItem>Do you have any other special requirements?</ListItem>
            </List>
          </React.Fragment>

          <TextareaQuestion
            question="Anything else?"
            optional={true}
            value={this.state.anythingElse}
            valueEntered={this.anythingElseEntered}
          />

          <RadioQuestion
            question="How did you hear about Juno?"
            options={clientReferrerOptions}
            inline={false}
            name="clientReferrer"
            selected={this.state.clientReferrer}
            optionSelected={this.selectClientReferrer}
            validate={this.handleFieldValidation('clientReferrer')}
            validationError={
              this.state.errors.clientReferrer
                ? 'Let us know how you found out about Juno'
                : null
            }
          />

          <Action onClick={this.saveAnythingElse}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default AnythingElse;
