import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';
import Heading from './Heading';

const JunoCard = styled.div`
  color: #0d0d0d;
  background-color: #ffffff;
  padding-top: 20px;
  ${mediaQueries.md} {
    box-shadow: 0 8px 16px 0 rgba(34, 50, 84, 0.06),
      inset 0 0 0 1px rgba(112, 123, 140, 0.15);
    border-radius: 8px;
    margin-bottom: 24px;
  }
`;

const JunoCardHeader = styled.div`
  padding: 0 ${props => props.theme.spacingScale[6]};
  ${mediaQueries.md} {
    padding: ${props => (props.dashboard ? '0px' : '32px')};
    padding-top: ${props => (props.removeTopPadding ? '0px' : '48px')};
    padding-bottom: 0;
  }
  h1,
  h2,
  h3,
  h4 {
    max-width: 608px;
  }
`;

const JunoCardContent = styled.div`
  padding: 0 ${props => props.theme.spacingScale[6]} 20px ${props => props.theme.spacingScale[6]};
  ${mediaQueries.md} {
    padding: 32px;
    padding-top: 0;
  }
  ul,
  ol,
  dl,
  p {
    max-width: 30em;
  }
`;

function Card(props) {
  return (
    <JunoCard dashboard={props.dashboard} id={props.id} className={props.className}>
      <JunoCardHeader removeTopPadding={props.removeTopPadding}>
        <Heading
          level={props.headingLevel ? props.headingLevel : '1'}
          size={props.headingSize ? props.headingSize : '2'}
        >
          {props.title}
        </Heading>
      </JunoCardHeader>
      <JunoCardContent>{props.children}</JunoCardContent>
    </JunoCard>
  );
}

export default Card;
