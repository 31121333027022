import React, {Component} from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import List, {ListItem} from '../../components/List';

class IdentityCheckIntro extends Component {
  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card
          title="We need to verify your identity"
          removeTopPadding
          headingSize="1"
        >
          <p>
            We need to carry out some identity checks. The process will take
            around five minutes.
          </p>
          <p>
            To do this, you’ll need:
          </p>
          <List bullet>
            <ListItem>
              Your identity documents (a passport or driving license)
            </ListItem>
            <ListItem>A phone or laptop with a camera</ListItem>
          </List>
          <p>
            Make sure you’re in a well-lit room. You’ll need to take photos of your documents — you may be asked for permission for the page to access your webcam or camera to take these.
          </p>
          <p>
            The service we use for our ID checks is provided by <a href="https://onfido.com/" target="_blank" rel="noreferrer">Onfido</a>. By continuing you confirm you have read, understand and accept the​ir​ <a href="https://onfido.com/terms-of-service/" target="_blank" rel="noreferrer">Terms of Service</a>, <a href="https://onfido.com/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://onfido.com/facial-scan-policy-and-release/" target="_blank" rel="noreferrer">Facial Scan Policy and Release</a>.
          </p>
          <Action onClick={() => this.props.onStepComplete({})}>
            Let’s get started
          </Action>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default IdentityCheckIntro;
