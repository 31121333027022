import React, { Component } from 'react';
import Card from '../../components/Card';
import CheckboxQuestion from '../../components/CheckboxQuestion';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { TERMS_AND_CONDITIONS_OPTIONS } from '../../constants';
import JunoTermsAndConditions from '../../components/JunoTermsAndConditions';

class RemoTermsAndConditions extends Component {
  constructor(props) {
    super(props);

    if (this.props.termsAndConditions) {
      const details = this.props.termsAndConditions;
      this.state = {
        termsAndConditions: details.termsAndConditions,
        errors: {},
      };
    } else {
      this.state = {
        termsAndConditions: null,
        errors: {},
        errorMessages: {
          termsAndConditions: 'We need you to accept our terms and conditions',
        },
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (
      fields['termsAndConditions'] === null ||
      fields['termsAndConditions'] === false
    ) {
      const field = 'termsAndConditions';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      // Hide the error when you interact with the field
      errors[field] = null;
    } else {
      // If the state for this field hasn't been set, we need some input, show an error
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleTermsChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  saveTermsAndConditions = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        termsAndConditions: {
          termsAndConditions: this.state.termsAndConditions,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="One last thing..." removeTopPadding>
          <CheckboxQuestion
            question="We need you to accept our terms and conditions, so we can start
            working for you."
            options={TERMS_AND_CONDITIONS_OPTIONS}
            name="termsAndConditions"
            title={TERMS_AND_CONDITIONS_OPTIONS.title}
            selected={this.state.termsAndConditions}
            onChange={this.handleTermsChange}
            validate={this.handleFieldValidation('termsAndConditions')}
            validationError={
              this.state.errors.termsAndConditions
                ? this.state.errors.termsAndConditions
                : null
            }
          />
          <Action onClick={this.saveTermsAndConditions}>
            {this.props.clientIsCompany ? "Sign up" : "Sign me up!"}
          </Action>
        </Card>
        <Card
          title="Our terms and conditions"
          headingLevel="2"
          removeTopPadding
        >
          <JunoTermsAndConditions />
        </Card>
      </React.Fragment>
    );
  }
}

export default RemoTermsAndConditions;
