// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-CameraPermissions-Recover-recovery{border-radius:1em;background-color:var(--osdk-color-background-info-pill);color:var(--osdk-color-content-info-pill);font-family:inherit;font-size:var(--onfido-font-size-small);font-weight:600;line-height:1.7142857143em;padding:0 1em}.onfido-sdk-ui-CameraPermissions-Recover-instructions{font-size:var(--onfido-font-size-small);text-align:left;margin:2.1428571429em 0 1.7142857143em;position:relative;padding:1.7142857143em 1.7142857143em .8571428571em}@media(--small-viewport)and (--short-viewport){.onfido-sdk-ui-CameraPermissions-Recover-instructions{font-size:var(--onfido-font-size-x-small)}}@media(max-width: 479px){.onfido-sdk-ui-CameraPermissions-Recover-instructions{margin:1.7142857143em 0 .8571428571em}}.onfido-sdk-ui-CameraPermissions-Recover-instructionsTitle{font-weight:600;margin:0 0 .5714285714em}.onfido-sdk-ui-CameraPermissions-Recover-recovery{position:absolute;top:0;left:1.4285714286em;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.onfido-sdk-ui-CameraPermissions-Recover-steps{padding:0;margin:0}.onfido-sdk-ui-CameraPermissions-Recover-step{margin:0 1.0714285714em .7142857143em}.onfido-sdk-ui-CameraPermissions-Recover-button{margin-left:auto;margin-right:auto}", ""]);
// Exports
exports.locals = {
	"modal_animation_duration": "200ms",
	"recovery": "onfido-sdk-ui-CameraPermissions-Recover-recovery",
	"instructions": "onfido-sdk-ui-CameraPermissions-Recover-instructions",
	"instructionsTitle": "onfido-sdk-ui-CameraPermissions-Recover-instructionsTitle",
	"steps": "onfido-sdk-ui-CameraPermissions-Recover-steps",
	"step": "onfido-sdk-ui-CameraPermissions-Recover-step",
	"button": "onfido-sdk-ui-CameraPermissions-Recover-button"
};
module.exports = exports;
