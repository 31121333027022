import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

function ReferralCodeBanner(props) {
  if (! props.text) {
    return null;
  }

  if (props.small === true) {
    return (
      <StyledInlineBanner data-testid="referral-code-banner">
        <StyledEmoji>🎉</StyledEmoji>
        <StyledInlineBannerText>
          {props.text}
        </StyledInlineBannerText>
      </StyledInlineBanner>
    )
  }

  if (props.withinQuote === true) {
    return (
      <StyledQuoteBanner data-testid="referral-code-banner">
        <StyledQuoteEmoji>🎉</StyledQuoteEmoji>
        <StyledQuoteBannerText>
          {props.text}
        </StyledQuoteBannerText>
      </StyledQuoteBanner>
    )
  }

  return (
    <StyledFullWidthBanner data-testid="referral-code-banner">
      <StyledFullWidthBannerContent pageWidth={props.pageWidth}>
        <StyledEmoji>🎉</StyledEmoji>
        <StyledFullWidthBannerText>
          {props.text}
        </StyledFullWidthBannerText>
      </StyledFullWidthBannerContent>
    </StyledFullWidthBanner>
  )
}

const StyledEmoji = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-shrink: 0;

  font-size: 20px;
  line-height: 1.4;

  background: white;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border: 4px solid white;
  border-radius: 50%;
`

// Full width banner

const StyledFullWidthBanner = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;

  margin-left: -50vw;
  margin-right: -50vw;

  ${mediaQueries.md} {
    margin-top: -30px;
    margin-bottom: 30px;
  }

  background: ${props => props.theme.color.green};
`

const StyledFullWidthBannerContent = styled.div`
  display: flex;
  align-items: center;

  padding-top: 10px;
  padding-bottom: 10px;

  max-width: ${props => (props.theme.pageWidth[props.pageWidth] / 16)}rem;

  margin-left: ${props => props.theme.spacingScale[6]};
  margin-right: ${props => props.theme.spacingScale[6]};

  ${mediaQueries.md} {
    margin-left: ${props => props.theme.spacingScale[12]};
    margin-right: ${props => props.theme.spacingScale[12]};
  }

  @media(min-width: ${props => (props.theme.pageWidth[props.pageWidth] + props.theme.gridSpacing.columnWidth * 2) / 16}rem) {
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledBannerText = styled.h2`
  font-size: ${props => props.theme.fontSizes.p['sm']};

  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
  }
  color: ${props => props.theme.color.white};
  padding: 0 1em;
`;

const StyledFullWidthBannerText = styled(StyledBannerText)`
  text-align: left;
`;


// Inline banner

const StyledInlineBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 38rem;

  margin-bottom: 1em;
  padding: 0 1em;

  background: ${props => props.theme.color.green};
  border-radius: 4px;
  color: ${props => props.theme.color.white};
`

const StyledInlineBannerText = styled.h2`
  font-size: ${props => props.theme.fontSizes.p['sm']};
  padding-left: 1em;
`;


// Inside quote banner

const StyledQuoteBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-top: 0.5em;
  ${mediaQueries.md} {
    padding-top: 1em;
  }
`

const StyledQuoteEmoji = styled(StyledEmoji)`
  background: ${props => props.theme.color.offWhite};
  border-color: ${props => props.theme.color.offWhite};
`;

const StyledQuoteBannerText = styled(StyledBannerText)`
  color: ${props => props.theme.color.junoText};
`;

export default ReferralCodeBanner;

ReferralCodeBanner.propTypes = {
  text: PropTypes.string,
  small: PropTypes.bool,
  withinQuote: PropTypes.bool,
  pageWidth: PropTypes.oneOf(['regular', 'wider'])
};

ReferralCodeBanner.defaultProps = {
  background: 'indigo',
  pageWidth: 'regular'
};
