import React, { Component } from 'react';
import BackButton from '../components/BackButton';

class StepFlow extends Component {
  constructor(props) {
    super(props);

    let initialIndex = 0;
    for (let i = 0; i < this.props.steps.length; i++) {
      if (
        this.props.steps[i].isFirstPage &&
        this.props.steps[i].isFirstPage(this.props.initialFlowState)
      ) {
        initialIndex = i;
        break;
      }
    }

    this.state = {
      ...this.props.initialFlowState,
      initialIndex: initialIndex,
      displayIndex: initialIndex,
      maxIndex: initialIndex,
    };
  }

  onStepComplete = stateDelta => {
    const deltaApplied = { ...this.state, ...stateDelta };
    const stepDelta = this.nextStepDelta(deltaApplied);
    this.setState({ ...stateDelta, ...stepDelta });
  };

  showFirst = partialflowState => {
    this.setState({
      ...this.props.initialFlowState,
      ...partialflowState,
      displayIndex: this.state.initialIndex,
      maxIndex: this.state.initialIndex,
    });
    return false;
  };

  showPrevious = () => {
    const stepDelta = this.prevStepDelta(this.state);
    this.setState({ ...stepDelta });
    return false;
  };

  showIndex = (index) => {
    this.setState({ displayIndex: index });
    return false;
  };

  isStepValid = (step, deltaAppliedState) => {
    if (step.valid) {
      return step.valid(deltaAppliedState ? deltaAppliedState : this.state);
    }

    return true;
  };

  nextStepDelta = deltaApplied => {
    let candidateStepIndex = deltaApplied.displayIndex + 1;
    let candidateStep = this.props.steps[candidateStepIndex];

    while (!this.isStepValid(candidateStep, deltaApplied)) {
      candidateStepIndex++;
      if (candidateStepIndex >= this.props.steps.length) {
        console.log('reached end of list, aborting');
        return;
      }
      candidateStep = this.props.steps[candidateStepIndex];
    }

    let newMax = deltaApplied.maxIndex;
    if (candidateStepIndex > deltaApplied.maxIndex) {
      newMax = candidateStepIndex;
    }

    return {
      displayIndex: candidateStepIndex,
      maxIndex: newMax,
    };
  };

  prevStepDelta = deltaApplied => {
    let candidateStepIndex = deltaApplied.displayIndex - 1;
    let candidateStep = this.props.steps[candidateStepIndex];

    while (!this.isStepValid(candidateStep, deltaApplied)) {
      candidateStepIndex--;
      if (candidateStepIndex < 0) {
        console.log('reached end of list, aborting');
        return;
      }
      candidateStep = this.props.steps[candidateStepIndex];
    }

    return {
      displayIndex: candidateStepIndex,
    };
  };

  buildNav = () => {
    if (this.props.steps[this.state.displayIndex].terminal) {
      return;
    }

    if (this.props.navMode === 'prev') {
      if (this.state.displayIndex > 0) {
        if (this.state.displayIndex > this.state.initialIndex) {
          return <BackButton onClick={this.showPrevious}>Back</BackButton>;
        }
      }
    } else if (this.props.navMode === 'breadcrumb') {
      let breadcrumbs = [];
      for (let i = 0; i <= this.state.maxIndex; i++) {
        const step = this.props.steps[i];
        if (this.isStepValid(step, this.state)) {
          breadcrumbs.push({
            name: step.name,
            index: i,
            active: i === this.state.displayIndex,
          });
        }
      }

      return (
        <div>
          <ul>
            {breadcrumbs.map(bc => {
              if (bc.active) {
                return <li key={bc.index}>{bc.name}</li>;
              } else {
                return (
                  <li key={bc.index}>
                    <a href="#" onClick={() => this.showIndex(bc.index)}>
                      {bc.name}
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      );
    }

    return;
  };

  render() {
    const activeStep = this.props.steps[this.state.displayIndex];

    const stepComponent = activeStep.component(this.state);
    const flowFunctions = {
      onStepComplete: this.onStepComplete,
      showPrevious: this.showPrevious,
      showFirst: this.showFirst,
      showIndex: this.showIndex,
    };

    const renderComponent = React.cloneElement(stepComponent, flowFunctions);
    const nav = this.buildNav();

    return (
      <>
        {nav}
        {renderComponent}
      </>
    );
  }
}

export default StepFlow;
