import React from 'react';
import { IconListItem } from './IconList';
import StatusUpdateIcon from './StatusUpdateIcon';
import StatusUpdateTitle from './StatusUpdateTitle';
import DescriptionBlocks from './DescriptionBlocks';

import styled from 'styled-components/macro';


function StatusUpdateRow({ status, title, descriptions, description_blocks }) {
  return (
    <IconListItem fullWidth={title.startsWith("Enquiries")}>
      <StatusUpdateIcon status={status} size="24" />
      <Wrapper>
        <StatusUpdateTitle type="update" status={status}>{title}</StatusUpdateTitle>
        <DescriptionBlocks
          status={status}
          descriptions={descriptions ? descriptions : null}
          description_blocks={description_blocks ? description_blocks : null } />
      </Wrapper>
    </IconListItem>
  );
};

export default StatusUpdateRow;

const Wrapper = styled.div`
  width: 100%;
  margin-left: 1rem;
  /* Prevent text breaking out of the flex container */
  a {
    word-break: break-word;
  }
`;
