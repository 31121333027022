import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import RadioConditional from '../../components/RadioConditional';
import TextQuestion from '../../components/TextQuestion';
import { isPhoneNumberValid } from "../../utils/numberUtils";
import { isEmailValid } from '../../utils/textUtils';

class PurchaseOwnersBuyingAndSelling extends Component {
  constructor(props) {
    super(props);

    const clientErrorMessages = {
      firstName: 'Enter their first name',
      lastName: 'Enter their last name',
      emailAddress: 'Enter their email address',
      phoneNumber: 'Enter their phone number',
    };

    const errorMessages = {
      numberOfOwners: 'Please choose an option',
      client2: clientErrorMessages,
      client3: clientErrorMessages,
      client4: clientErrorMessages,
    };

    if (this.props.otherOwnersPurchasing) {
      const details = this.props.otherOwnersPurchasing;
      this.state = {
        numberOfClientsSelling: this.props.otherOwnersSelling.numberOfOwners,
        sellingClients: this.props.otherOwnersSelling,
        arePurchasingClientSameAsSelling:
          details.arePurchasingClientSameAsSelling,
        areOthersPurchasing: details.areOthersPurchasing,
        numberOfClientsPurchasing: details.numberOfClientsPurchasing,
        client2: { ...details.client2 },
        client3: { ...details.client3 },
        client4: { ...details.client4 },
        errors: {
          client2: {},
          client3: {},
          client4: {},
        },
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        numberOfClientsSelling: this.props.otherOwnersSelling.numberOfOwners,
        sellingClients: this.props.otherOwnersSelling,
        client2: { firstName: '', lastName: '', emailAddress: '' },
        client3: { firstName: '', lastName: '', emailAddress: '' },
        client4: { firstName: '', lastName: '', emailAddress: '' },
        errors: {
          client2: {},
          client3: {},
          client4: {},
        },
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {
      client2: { ...this.state.errors.client2 },
      client3: { ...this.state.errors.client3 },
      client4: { ...this.state.errors.client4 },
    };

    let formIsValid = true;

    if (fields['numberOfClientsPurchasing'] >= 2) {
      if (!fields.client2['firstName']) {
        const field = 'firstName';
        formIsValid = false;
        errors.client2[field] = errorMessages.client2[field];
      }
      if (!fields.client2['lastName']) {
        const field = 'lastName';
        formIsValid = false;
        errors.client2[field] = errorMessages.client2[field];
      }
      if (!fields.client2['emailAddress']) {
        const field = 'emailAddress';
        formIsValid = false;
        errors.client2[field] = errorMessages.client2[field];
      }
      if (
        fields.client2['emailAddress'] &&
        !isEmailValid(fields.client2['emailAddress'])
      ) {
        formIsValid = false;
        errors.client2['email'] = 'Please enter a valid email address';
      }
      if (!fields.client2['phoneNumber']) {
        const field = 'phoneNumber';
        formIsValid = false;
        errors.client2[field] = errorMessages.client2[field];
      }
      if (
        fields.client2['phoneNumber'] &&
        !isPhoneNumberValid(fields.client2['phoneNumber'])
      ) {
        errors.client2['phoneNumber'] = 'Please enter a valid phone number';
        formIsValid = false;
      }
    }

    if (fields['numberOfClientsPurchasing'] >= 3) {
      if (!fields.client3['firstName']) {
        const field = 'firstName';
        formIsValid = false;
        errors.client3[field] = errorMessages.client3[field];
      }
      if (!fields.client3['lastName']) {
        const field = 'lastName';
        formIsValid = false;
        errors.client3[field] = errorMessages.client3[field];
      }
      if (!fields.client3['emailAddress']) {
        const field = 'emailAddress';
        formIsValid = false;
        errors.client3[field] = errorMessages.client3[field];
      }
      if (
        fields.client3['emailAddress'] &&
        !isEmailValid(fields.client3['emailAddress'])
      ) {
        formIsValid = false;
        errors.client3['email'] = 'Please enter a valid email address';
      }
      if (!fields.client3['phoneNumber']) {
        const field = 'phoneNumber';
        formIsValid = false;
        errors.client3[field] = errorMessages.client3[field];
      }
      if (
        fields.client3['phoneNumber'] &&
        !isPhoneNumberValid(fields.client3['phoneNumber'])
      ) {
        formIsValid = false;
        errors.client3['phoneNumber'] = 'Please enter a valid phone number';
      }
    }

    if (fields['numberOfClientsPurchasing'] === 4) {
      if (!fields.client4['firstName']) {
        const field = 'firstName';
        formIsValid = false;
        errors.client4[field] = errorMessages.client4[field];
      }
      if (!fields.client4['lastName']) {
        const field = 'lastName';
        formIsValid = false;
        errors.client4[field] = errorMessages.client4[field];
      }
      if (!fields.client4['emailAddress']) {
        const field = 'emailAddress';
        formIsValid = false;
        errors.client4[field] = errorMessages.client4[field];
      }
      if (
        fields.client4['emailAddress'] &&
        !isEmailValid(fields.client4['emailAddress'])
      ) {
        formIsValid = false;
        errors.client4['email'] = 'Please enter a valid email address';
      }
      if (!fields.client4['phoneNumber']) {
        const field = 'phoneNumber';
        formIsValid = false;
        errors.client4[field] = errorMessages.client4[field];
      }
      if (
        fields.client4['phoneNumber'] &&
        !isPhoneNumberValid(fields.client4['phoneNumber'])
      ) {
        formIsValid = false;
        errors.client4['phoneNumber'] = 'Please enter a valid phone number';
      }
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleClientFieldValidation = (clientNumber, field) => event => {
    let errors = { ...this.state.errors };
    const errorMessages = {
      ...this.state.errorMessages,
    };

    if (event.type !== 'blur') {
      errors[clientNumber][field] = null;
    } else {

      if (
        field === 'emailAddress' &&
        this.state[clientNumber]['emailAddress']
        && !isEmailValid(this.state[clientNumber][field])
      ) {
        errors[clientNumber][field] = 'Please enter a valid email address';
      }

      if (
        field === 'phoneNumber' &&
        this.state[clientNumber]['phoneNumber'] &&
        !isPhoneNumberValid(this.state[clientNumber][field])
      ) {
        errors[clientNumber][field] = 'Please enter a valid phone number';
      }

      if (!this.state[clientNumber][field]) {
        errors[clientNumber][field] = errorMessages[clientNumber][field];
      }
    }
    this.setState({ errors });
  };

  selectNumberOfOwners = noo => {
    this.setState({ numberOfOwners: noo });
  };

  selectNumberOfClientsPurchasing = noo => {
    this.setState({ numberOfClientsPurchasing: noo });
  };

  selectPurchasingClientSameAsSelling = ea => {
    this.setState({ arePurchasingClientSameAsSelling: ea });
    if (ea) {
      this.setState({
        numberOfClientsPurchasing: null,
        client2: this.props.otherOwnersSelling.client2,
        client3: this.props.otherOwnersSelling.client3,
        client4: this.props.otherOwnersSelling.client4,
      });

      if (this.state.sellingClients.numberOfClientsSelling === 4) {
        this.setState({
          numberOfClientsPurchasing: 4,
        });
      }
    } else {
      this.setState({
        numberOfClientsPurchasing: null,
        client2: { firstName: '', lastName: '', emailAddress: '', phoneNumber: ''},
        client3: { firstName: '', lastName: '', emailAddress: '', phoneNumber: ''},
        client4: { firstName: '', lastName: '', emailAddress: '', phoneNumber: ''},
      });
    }
  };

  selectOthersPurchasing = ea => {
    this.setState({ areOthersPurchasing: ea });
  };

  selectAnyoneElseInvolved = ea => {
    this.setState({ anyoneElseInvolved: ea });
    if (ea) {
      this.setState({ numberOfClientsPurchasing: 4 });
    } else {
      this.setState({
        numberOfClientsPurchasing: this.props.otherOwnersSelling
          .numberOfClientsSelling,
      });
    }
  };

  handleClient2 = field => event => {
    let client2 = { ...this.state.client2 };
    client2[field] = event;
    this.setState({ client2 });
  };

  handleClient3 = field => event => {
    let client3 = { ...this.state.client3 };
    client3[field] = event;
    this.setState({ client3 });
  };

  handleClient4 = field => event => {
    let client4 = { ...this.state.client4 };
    client4[field] = event;
    this.setState({ client4 });
  };

  saveOtherOwners = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        otherOwnersPurchasing: {
          numberOfClientsPurchasing: this.state.numberOfClientsPurchasing,
          arePurchasingClientSameAsSelling: this.state
            .arePurchasingClientSameAsSelling,
          client2: this.state.client2,
          client3: this.state.client3,
          client4: this.state.client4,
        },
      });
      return;
    }
  };

  render() {
    const cardTitle = "Now tell us about the property you're buying";

    const sellingAlone =
      this.props.otherOwnersSelling.numberOfClientsSelling === 1;

    const questions = {
      first: {
        show: !sellingAlone,
        question:
          'Will you be buying it with ' +
          this.state.sellingClients.client2.firstName +
          '?',
        options: [{ title: 'Yes', value: true }, { title: 'No', value: false }],
      },
      second: {
        show:
          (sellingAlone ||
            this.state.arePurchasingClientSameAsSelling != null) &&
          !(
            this.state.arePurchasingClientSameAsSelling &&
            this.state.sellingClients.numberOfClientsSelling === 4
          ),
        question:
          sellingAlone || !this.state.arePurchasingClientSameAsSelling
            ? 'Are you buying with anyone else?'
            : 'Is anyone else involved?',
        options:
          sellingAlone || !this.state.arePurchasingClientSameAsSelling
            ? [
                { title: 'No, just me', value: 1 },
                { title: 'Yes, with 1 other person', value: 2 },
                { title: 'Yes, with 2 other people', value: 3 },
                { title: 'Yes, with 3 other people', value: 4 },
              ]
            : [
                {
                  title: 'No, just us',
                  value: this.state.sellingClients.numberOfClientsSelling,
                },
                {
                  title: 'Yes, with 1 other person',
                  value: this.state.sellingClients.numberOfClientsSelling + 1,
                },
                {
                  title: 'Yes, with 2 other people',
                  value: this.state.sellingClients.numberOfClientsSelling + 2,
                },
                {
                  title: 'Yes, with 3 other people',
                  value: this.state.sellingClients.numberOfClientsSelling + 3,
                },
              ].slice(
                0,
                4 - (this.state.sellingClients.numberOfClientsSelling - 1),
              ),
        field: 'numberOfClientsPurchasing',
        selected: this.state.numberOfClientsPurchasing,
        selectFunc: this.selectNumberOfClientsPurchasing,
      },
    };

    const sellingClients = [
      this.state.sellingClients.client2.firstName,
      this.state.sellingClients.client3.firstName,
      this.state.sellingClients.client4.firstName,
    ];

    const primaryQuestion =
      this.state.sellingClients.numberOfClientsSelling > 2
        ? 'Are you, ' +
          sellingClients
            .slice(0, this.state.sellingClients.numberOfClientsSelling - 2)
            .join(', ') +
          ' and ' +
          sellingClients[this.state.sellingClients.numberOfClientsSelling - 2] +
          ' also buying together?'
        : 'Will you be buying it with ' +
          this.state.sellingClients.client2.firstName +
          '?';

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={cardTitle} removeTopPadding>
          {questions.first.show ? (
            <RadioQuestion
              question={primaryQuestion}
              options={questions.first.options}
              name="firstQuestion"
              inline={true}
              selected={this.state.arePurchasingClientSameAsSelling}
              optionSelected={this.selectPurchasingClientSameAsSelling}
              validate={this.handleFieldValidation(
                'arePurchasingClientSameAsSelling',
              )}
              validationError={
                this.state.errors.arePurchasingClientSameAsSelling
                  ? this.state.errors.arePurchasingClientSameAsSelling
                  : null
              }
            />
          ) : (
            <></>
          )}

          {questions.second.show ? (
            <>
              <RadioQuestion
                question={questions.second.question}
                options={questions.second.options}
                inline={false}
                name="secondQuestion"
                selected={questions.second.selected}
                optionSelected={questions.second.selectFunc}
                validate={this.handleFieldValidation(questions.second.field)}
                validationError={
                  this.state.errors[questions.second.field]
                    ? this.state.errors[questions.second.field]
                    : null
                }
              />

              {(sellingAlone && this.state.numberOfClientsPurchasing > 1) ||
              (!sellingAlone &&
                this.state.arePurchasingClientSameAsSelling &&
                this.state.numberOfClientsPurchasing >
                  this.state.sellingClients.numberOfClientsSelling) ||
              (!sellingAlone &&
                this.state.arePurchasingClientSameAsSelling === false) ? (
                <RadioConditional>
                  {this.state.numberOfClientsPurchasing >= 2 &&
                    this.state.numberOfClientsPurchasing <= 4 && (
                      <div>
                        <h3>
                          We’ll need to get in touch with them about the
                          purchase — please enter their details below.
                        </h3>
                        <h3>First person</h3>
                        <TextQuestion
                          name="client2FirstName"
                          question="What’s their first name"
                          value={this.state.client2.firstName}
                          valueEntered={this.handleClient2('firstName')}
                          validate={this.handleClientFieldValidation('client2', 'firstName')}
                          validationError={
                            this.state.errors.client2.firstName
                              ? this.state.errors.client2.firstName
                              : null
                          }
                        />
                        <TextQuestion
                          name="client2LastName"
                          question="What’s their last name"
                          value={this.state.client2.lastName}
                          valueEntered={this.handleClient2('lastName')}
                          validate={this.handleClientFieldValidation('client2', 'lastName')}
                          validationError={
                            this.state.errors.client2.lastName
                              ? this.state.errors.client2.lastName
                              : null
                          }
                        />
                        <TextQuestion
                          name="client2EmailAddress"
                          question="What’s their email address?"
                          value={this.state.client2.emailAddress}
                          valueEntered={this.handleClient2('emailAddress')}
                          validate={this.handleClientFieldValidation('client2', 'emailAddress')}
                          validationError={
                            this.state.errors.client2.emailAddress
                              ? this.state.errors.client2.emailAddress
                              : null
                          }
                        />
                        <TextQuestion
                          name="client2PhoneNumber"
                          question="What’s their phone number?"
                          value={this.state.client2.phoneNumber}
                          valueEntered={this.handleClient2('phoneNumber')}
                          validate={this.handleClientFieldValidation('client2', 'phoneNumber')}
                          validationError={
                            this.state.errors.client2.phoneNumber
                              ? this.state.errors.client2.phoneNumber
                              : null
                          }
                        />
                      </div>
                    )}

                  {this.state.numberOfClientsPurchasing >= 3 &&
                    this.state.numberOfClientsPurchasing <= 4 && (
                      <div>
                        <h3>Second person</h3>
                        <TextQuestion
                          name="client3FirstName"
                          question="What’s their first name"
                          value={this.state.client3.firstName}
                          valueEntered={this.handleClient3('firstName')}
                          validate={this.handleClientFieldValidation('client3', 'firstName')}
                          validationError={
                            this.state.errors.client3.firstName
                              ? this.state.errors.client3.firstName
                              : null
                          }
                        />
                        <TextQuestion
                          name="client3LastName"
                          question="What’s their last name"
                          value={this.state.client3.lastName}
                          valueEntered={this.handleClient3('lastName')}
                          validate={this.handleClientFieldValidation('client3', 'lastName')}
                          validationError={
                            this.state.errors.client3.lastName
                              ? this.state.errors.client3.lastName
                              : null
                          }
                        />
                        <TextQuestion
                          name="client3EmailAddress"
                          question="What’s their email address?"
                          value={this.state.client3.emailAddress}
                          valueEntered={this.handleClient3('emailAddress')}
                          validate={this.handleClientFieldValidation('client3', 'emailAddress')}
                          validationError={
                            this.state.errors.client3.emailAddress
                              ? this.state.errors.client3.emailAddress
                              : null
                          }
                        />
                        <TextQuestion
                          name="client3PhoneNumber"
                          question="What’s their phone number?"
                          value={this.state.client3.phoneNumber}
                          valueEntered={this.handleClient3('phoneNumber')}
                          validate={this.handleClientFieldValidation('client3', 'phoneNumber')}
                          validationError={
                            this.state.errors.client3.phoneNumber
                              ? this.state.errors.client3.phoneNumber
                              : null
                          }
                        />
                      </div>
                    )}

                  {this.state.numberOfClientsPurchasing === 4 && (
                    <div>
                      <h3>Third person</h3>
                      <TextQuestion
                        name="client4FirstName"
                        question="What’s their first name"
                        value={this.state.client4.firstName}
                        valueEntered={this.handleClient4('firstName')}
                        validate={this.handleClientFieldValidation('client4', 'firstName')}
                        validationError={
                          this.state.errors.client4.firstName
                            ? this.state.errors.client4.firstName
                            : null
                        }
                      />
                      <TextQuestion
                        name="client4LastName"
                        question="What’s their last name"
                        value={this.state.client4.lastName}
                        valueEntered={this.handleClient4('lastName')}
                        validate={this.handleClientFieldValidation('client4', 'lastName')}
                        validationError={
                          this.state.errors.client4.lastName
                            ? this.state.errors.client4.lastName
                            : null
                        }
                      />
                      <TextQuestion
                        name="client4EmailAddress"
                        question="What’s their email address?"
                        value={this.state.client4.emailAddress}
                        valueEntered={this.handleClient4('emailAddress')}
                        validate={this.handleClientFieldValidation('client4', 'emailAddress')}
                        validationError={
                          this.state.errors.client4.emailAddress
                            ? this.state.errors.client4.emailAddress
                            : null
                        }
                      />
                        <TextQuestion
                          name="client4PhoneNumber"
                          question="What’s their phone number?"
                          value={this.state.client4.phoneNumber}
                          valueEntered={this.handleClient4('phoneNumber')}
                          validate={this.handleClientFieldValidation('client4', 'phoneNumber')}
                          validationError={
                            this.state.errors.client4.phoneNumber
                              ? this.state.errors.client4.phoneNumber
                              : null
                          }
                        />
                    </div>
                  )}
                </RadioConditional>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <Action onClick={this.saveOtherOwners}>Continue</Action>
        </Card>

        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default PurchaseOwnersBuyingAndSelling;
