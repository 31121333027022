import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import PageLayout from '../../components/PageLayout';
import StepFlowNavigate from '../../components/StepFlowNavigate';
import { parseCampaign, parseChannel, } from '../../utils/queryStringUtils';
import { selectGAClientId } from '../../features/analytics/analyticsSlice';

import QuoteBuyingProperty from '../QuoteCommon/QuoteBuyingProperty';
import QuoteSellingProperty from '../QuoteCommon/QuoteSellingProperty';

import ReferrerAgentAndClientDetails from "./ReferrerAgentAndClientDetails";
import ReferrerQuoteTransactionType from "./ReferrerQuoteTransactionType";
import ReferrerSorryPage from './ReferrerSorryPage';
import ReferrerQuoteSentPage from './ReferrerQuoteSentPage';
import ReferrerAnythingElse from './ReferrerAnythingElse';
import ReferrerQuoteDetailsPage from "./ReferrerQuoteDetailsPage";
import ReferrerQuoteSequence from "./ReferrerQuoteSequence";
import ReferrerQuoteSubmission from "./ReferrerQuoteSubmission";
import { isSequenceBranch, isSequenceReferral } from './utils/ReferrerQuoteUtils';
import { INSURANCE_LIMIT } from '../../constants';

const transactionTypeOptions = [
  { title: 'Buying a property', value: 'Buying' },
  { title: 'Selling a property', value: 'Selling' },
  { title: 'Buying and selling at the same time', value: 'BuyingAndSelling' }
];

const ReferrerQuoteFlow = (props) => {

  let { "*": referralCodeRaw } = useParams()

  const cleanupReferralCode = (referralCodeParam) => {
    if (!referralCodeParam) {
      return referralCodeParam
    }
    // if referral code contains < (%3c) or / then we should trim it as it's likely a corrupted url
    return referralCodeParam.split(/[</]/, 2)[0];
  };
  const referralCode = cleanupReferralCode(referralCodeRaw);

  const location = useLocation()
  const navigate = useNavigate()

  // FIXME: move this into components that use it once they've been converted
  // to functional components.
  const googleAnalyticsId = useSelector(selectGAClientId);

  const [state, setState] = useState({
    loading: false,
    error: null,
    referral: null,
    referralLocked: false,
    defaultChannel: parseChannel(location, "estate_agent_referrals"),
    defaultReferrer: null,
    campaign: parseCampaign(location),
    inBranchReferral: false,
  });

  const [flowCompleted, setFlowCompleted] = useState(false);
  const [submittedFlowState, setSubmittedFlowState] = useState(null);
  const [agentName, setAgentName] = useState(null);
  const [agentEmailAddress, setAgentEmailAddress] = useState(null);

  useEffect(() => {
    if (!flowCompleted && !!referralCode) {
      setState(prevState => ({ ...prevState, loading: true, referralLocked: true }));

      fetch(`/api/referralCode/${referralCode}?audience=referrer`)
        .then(resp => resp.json())
        .then(
          referralData => {
            if (referralData.referralCodeValid) {
              const referral = {
                referralCode: referralCode,
                discountAmount: referralData.discountAmount,
                discountMessage: referralData.discountMessage,
                quoteMessage: referralData.quoteMessage,
                referralSource: referralData.referralSource,
              };

              setState(prevState => ({
                ...prevState,
                loading: false,
                referral: referral,
              }));
            } else {
              setState(prevState => ({
                ...prevState,
                loading: false,
                referral: null,
                defaultReferrer: referralCode,
                inBranchReferral: isSequenceBranch(referralCode)
              }));
            }
          },
          () => {
            setState(prevState => ({ ...prevState, loading: false }));
          },
        );
    }
  }, [referralCode, flowCompleted]);

  const shouldShowBuyingPropertyDetails = transactionType => {
    return (
      transactionType === 'Buying' || transactionType === 'BuyingAndSelling'
    );
  };

  const shouldShowSellingPropertyDetails = transactionType => {
    return (
      transactionType === 'Selling' || transactionType === 'BuyingAndSelling'
    );
  };

  const shouldShowSellingRejectionPage = (transactionDetails, referral) => {
    let details = { ...transactionDetails };

    if (details.price >= INSURANCE_LIMIT) {
      return true;
    }

    let allowRetirementPropertySales = false;
    if (!!referral && isSequenceReferral(referral.referralSource)) {
      allowRetirementPropertySales = true;
    }

    return (
      !!details.shouldReject ||
      !!details.newBuild ||
      !!details.divorceOrSeparation ||
      (
        !!details.retirementProperty && !allowRetirementPropertySales
      )
    )
  };

  const shouldShowBuyingRejectionPage = (transactionDetails, transactionType, referral) => {
    let details = { ...transactionDetails };

    let allowLeaseholdPropertyPurchases = false;
    if (!!referral && isSequenceReferral(referral.referralSource)) {
      allowLeaseholdPropertyPurchases = true;
    }

    // Allow leasehold and leasehold with... purchases for sequence referrals
    if (
      (transactionType === 'Buying' || transactionType === 'BuyingAndSelling') &&
      (
        (details.tenure === 'leasehold' || details.tenure === 'leasehold with share of freehold') &&
        !allowLeaseholdPropertyPurchases
      )
    ) {
      return true;
    }

    if (details.price >= INSURANCE_LIMIT) {
      return true;
    }

    if (details.clientIsCompany && details.buyToLet) {
      return true;
    }

    return (
      !!details.shouldReject ||
      !!details.newBuild ||
      !!details.divorceOrSeparation ||
      !!details.retirementProperty
    )
  };

  if (state.loading) {
    return null
  }

  if (flowCompleted) {
    return (
      <PageLayout>
        <ReferrerQuoteSentPage
          buyingDetails={submittedFlowState.buyingDetails}
          defaultChannel={state.defaultChannel}
          defaultReferrer={state.defaultReferrer}
          googleAnalyticsId={googleAnalyticsId}
          inBranchReferral={submittedFlowState?.inBranchReferral}
          recipient='client'
          referral={submittedFlowState?.referral}
          quoteDetails={submittedFlowState.quoteDetails}
          transactionType={submittedFlowState.transactionType}
          sellingDetails={submittedFlowState.sellingDetails}
          remortgagingDetails={submittedFlowState.remortgagingDetails}
          restartFlow={() => {
            setSubmittedFlowState(null);
            setFlowCompleted(false);
            navigate(location.pathname)
          }}
          />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <StepFlowNavigate
        initialFlowState={{
          transactionType: null,
          referral: state.referral,
          inBranchReferral: state.inBranchReferral,
          personalDetails: null,
          sellingDetails: null,
          buyingDetails: null,
          remortgagingDetails: null,
          additionalInformation: null,
          agentName: agentName || null,
          agentEmailAddress: agentEmailAddress || null,
        }}
        steps={[
          {
            component: flowState => {
              if (flowState.inBranchReferral === true) {
                return <ReferrerQuoteSequence
                  transactionType={flowState.transactionType}
                  transactionTypeOptions={transactionTypeOptions}
                  referral={flowState.referral}
                  inBranchReferral={flowState.inBranchReferral}
                  referralLocked={state.referralLocked}
                  defaultReferrer={state.defaultReferrer}
                />
              } else {
                return <ReferrerQuoteTransactionType
                  transactionType={flowState.transactionType}
                  transactionTypeOptions={transactionTypeOptions}
                  referral={flowState.referral}
                  inBranchReferral={flowState.inBranchReferral}
                />
              }
            },
            name: 'Referrer quote intro',
            slug: 'refer-a-client'
          },
          {
            component: flowState => (
              <QuoteSellingProperty
                mode="referrer"
                transactionType={flowState.transactionType}
                sellingDetails={flowState.sellingDetails}
                referral={flowState.referral}
                referralLocked={state.referralLocked}
                inBranchReferral={flowState.inBranchReferral}
              />
            ),
            name: 'Referrer Selling property details',
            valid: flowState => shouldShowSellingPropertyDetails(flowState.transactionType),
            slug: 'sale-property-details'
          },
          {
            component: flowState => (
              <ReferrerSorryPage
                transactionType={flowState.transactionType}
                transactionDetails={flowState.sellingDetails}
                rejectQuote="selling"
              />
            ),
            name: 'Selling sorry page',
            valid: flowState => shouldShowSellingRejectionPage(flowState.sellingDetails, flowState.referral),
            slug: 'sale-sorry'
          },
          {
            component: flowState => (
              <QuoteBuyingProperty
                mode="referrer"
                transactionType={flowState.transactionType}
                buyingDetails={flowState.buyingDetails}
                referral={flowState.referral}
                referralLocked={state.referralLocked}
                inBranchReferral={flowState.inBranchReferral}
              />
            ),
            name: 'Referrer Buying property details',
            valid: flowState => shouldShowBuyingPropertyDetails(flowState.transactionType),
            slug: 'purchase-details'
          },
          {
            component: flowState => (
              <ReferrerSorryPage
                transactionType={flowState.transactionType}
                transactionDetails={flowState.buyingDetails}
                rejectQuote="buying"
              />
            ),
            name: 'Buying sorry page',
            valid: flowState => shouldShowBuyingRejectionPage(flowState.buyingDetails, flowState.transactionType, flowState.referral),
            slug: 'purchase-sorry'
          },
          {
            component: flowState => (
              <ReferrerAgentAndClientDetails
                agentName={flowState.agentName}
                agentEmailAddress={flowState.agentEmailAddress}
                additionalInformation={flowState.additionalInformation}
                personalDetails={flowState.personalDetails}
              />
            ),
            name: "Referrer quote agent and client details page",
            slug: 'referrer-and-client-details'
          },
          {
            component: flowState => (
              <ReferrerAnythingElse
                transactionType={flowState.transactionType}
                additionalInformation={flowState.additionalInformation}
                showTermsLink={true}
                buttonText='Continue'
              />
            ),
            name: 'Referrer Anything else',
            slug: 'anything-else'
          },
          {
            component: flowState => (
              <ReferrerQuoteDetailsPage
                transactionType={flowState.transactionType}
                defaultChannel={state.defaultChannel}
                sellingDetails={flowState.sellingDetails}
                buyingDetails={flowState.buyingDetails}
                referral={flowState.referral}
                inBranchReferral={flowState.inBranchReferral}
                googleAnalyticsId={googleAnalyticsId}
              />
            ),
            name: "Referrer quote partial quote page",
            slug: 'quote-details'
          },
          {
            component: flowState => (
              <ReferrerQuoteSubmission
                transactionType={flowState.transactionType}
                defaultChannel={state.defaultChannel}
                defaultReferrer={state.defaultReferrer}
                campaign={state.campaign}
                agentName={flowState.agentName}
                agentEmailAddress={flowState.agentEmailAddress}
                additionalInformation={flowState.additionalInformation}
                personalDetails={flowState.personalDetails}
                sellingDetails={flowState.sellingDetails}
                buyingDetails={flowState.buyingDetails}
                recipient='client'
                referral={flowState.referral}
                inBranchReferral={flowState.inBranchReferral}
                googleAnalyticsId={googleAnalyticsId}
                onFlowComplete={(quoteDetails) => {
                  setSubmittedFlowState({
                    ...flowState,
                    ...quoteDetails
                  });
                  setAgentName(flowState.agentName);
                  setAgentEmailAddress(flowState.agentEmailAddress);
                  setFlowCompleted(true);
                }}
              />
            ),
            name: 'Referrer quote submission',
            slug: 'quote-sent'
          },
        ]}
      />
    </PageLayout>
  );
}

export default ReferrerQuoteFlow;
