import React from 'react';
import styled from "styled-components/macro";

class StyledCaseUpdateInfo extends React.Component {
  render() {
    return (
      <CaseUpdateInfo>
        {this.props.children}
      </CaseUpdateInfo>
    )
  }
}

export default StyledCaseUpdateInfo;

const CaseUpdateInfo = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: ${props => props.theme.fontSizes.p['s']};
  line-height: 1.5;
  margin-top: ${props => props.theme.spacingScale[2]};
`;
