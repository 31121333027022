import { LIFECYCLE_TAGS, LIFECYCLE_TAG_DESCRIPTIONS, MILESTONE_DESCRIPTIONS } from './constants';

export const isEmailValid = email => {
  // email validation regex from the internet - https://stackoverflow.com/a/1373724
  // eslint-disable-next-line
  const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  return re.test(String(email).toLowerCase());
};

export const describeTransactionType = transactionType => {
  const t = transactionType.toLowerCase();
  if (t === 'buying') {
    return 'purchase'
  } else if (t === 'selling') {
    return 'sale'
  } if (t === 'remortgaging') {
    return 'remortgage'
  } else {
    return t
  }
};

export const getFormattedDate = date => {
  let dateToFormat = new Date(date)

  const datePart = `${dateToFormat.toLocaleDateString("en-GB", { month: "short", day: "numeric" })}`;
  const formattedDate = `${datePart}`;

  return formattedDate
}

// Convert datetime objects to string in the format 15 February 2024
export const formatDate = (datetime) => {
  const date = new Date(datetime);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
}

// Convert  a datetime object to a string in the format 15 February 2024, 12:34
export const formatRelativeDate = (datetime) => {
  const now = new Date();
  const then = new Date(datetime);
  const days = Math.floor((now - then) /  (1000 * 60 * 60 * 24)); // number of milliseconds in a day
  if (days === 0) {
    return 'today';
  } else if (days <= 30) {
    return `${days} day${days === 1 ? '' : 's'} ago`;
  }
  return formatDate(datetime);
}

export const getMilestoneDescription = (milestone) => {
  return MILESTONE_DESCRIPTIONS[milestone] || null;
}

export const getTagGroupName = (tag) => {
  return LIFECYCLE_TAGS[tag]?.name || null;
}

export const getFormattedLifecycleTag = (activeTag, memoReceivedDate) => {

  if (!activeTag) {
    return null;
  }

  // If the active lifecycle tag is case setup and we don't have a memorandum of sale
  if (activeTag === 'case_setup' && memoReceivedDate === null) {
    return LIFECYCLE_TAGS['pre_memo'].name;
  }

  const tagObject = LIFECYCLE_TAG_DESCRIPTIONS.tags.find(t => t.tag === activeTag);
  return tagObject ? tagObject.description : null;
}

export const joinNames = (names) => {
  if (!names) {
    return null;
  }
  // lastNames is an array of strings
  // e.g. ["Smith", "Jones"]
  if (names.length === 2) {
    return names.join(' and ');
  } else if (names.length > 2) {
    return `${names.slice(0, -1).join(', ')}, and ${names[names.length - 1]}`;
  } else {
    return names[0] || '';
  }
}

const isIntDatePartValid = (datePart, maxValue) => {
  const allowedCharsRegex = /^[0-9]+$/;
  const emptyCharsRegex = /^\s*$/;

  if (!datePart || emptyCharsRegex.test(String(datePart))) {
    return false
  }
  if (datePart === "0") {
    return false
  }
  if (datePart > maxValue) {
    return false
  }
  if (!Number.isInteger(parseFloat(datePart))) {
    return false
  }
  if (!allowedCharsRegex.test(String(datePart))) {
    return false
  }

  return true
}

export const isDayValid = day => {
  return isIntDatePartValid(day, 31)
}

export const isMonthValid = month => {
  return isIntDatePartValid(month, 12)
}

export const isYearValid = year => {
  const allowedCharsRegex = /^[0-9]+$/;
  const emptyCharsRegex = /^\s*$/;

  if (!year || emptyCharsRegex.test(String(year))) {
    return false
  }
  if (year.length < 4) {
    return false
  }
  if (year < 1900) {
    return false
  }
  if (!Number.isInteger(parseFloat(year))) {
    return false
  }
  if (!allowedCharsRegex.test(String(year))) {
    return false
  }

  return true
};

const isDateValid = (day, month, year) => {
  return isDayValid(day) && isMonthValid(month) && isYearValid(year)
}

const isDateInThePast = (day, month, year) => {
  const today = new Date(new Date().toDateString());

  let monthIndex = month - 1 // month is 0-indexed
  const date = new Date(new Date(year, monthIndex, day).toDateString());

  return date < today;
}

export const isDateInThePastValid = date => {
  const year = date.year;
  const month = date.month;
  const day = date.day;

  return (
    isDateValid(day, month, year) && isDateInThePast(day, month, year)
  )
}

export const isTextValid = text => {
  const emptyCharsRegex = /^\s*$/;

  if (!text || emptyCharsRegex.test(String(text))) {
    return false
  }

  return true
};
