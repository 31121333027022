import React, { Component } from 'react';
import Card from '../../components/Card';
import TextQuestion from '../../components/TextQuestion';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';

class SellingClient1Probate extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      phoneNumber: 'Enter their phone number',
      companyName: 'Enter the company name',
      lawyerFullName: 'Enter their full name',
      emailAddress: 'Enter their email address',
    };

    if (this.props.lawFirmHandlingProbate) {
      this.state = {
        lawFirmHandlingProbate: { ...this.props.lawFirmHandlingProbate },
        errors: { ...this.props.errors },
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        lawFirmHandlingProbate: {
          phoneNumber: '',
          companyName: '',
          lawyerFullName: '',
          emailAddress: '',
        },
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state.lawFirmHandlingProbate };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (!fields['phoneNumber']) {
      const field = 'phoneNumber';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields['companyName']) {
      const field = 'companyName';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields['lawyerFullName']) {
      const field = 'lawyerFullName';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields['emailAddress']) {
      const field = 'emailAddress';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state.lawFirmHandlingProbate[field]) {
        errors[field] = errorMessages[field];
      }
    }
    this.setState({ errors });
  };

  handleInputChange = field => event => {
    let lawFirmHandlingProbate = { ...this.state.lawFirmHandlingProbate };
    lawFirmHandlingProbate[field] = event;
    this.setState({ lawFirmHandlingProbate });
  };

  saveSellingClient1Probate = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        lawFirmHandlingProbate: this.state.lawFirmHandlingProbate,
        errors: this.state.errors,
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Grant of probate" removeTopPadding>
          <p>
            You told us you’re an executor, so we’ll need to speak to the law
            firm handling the grant of probate.
          </p>

          <h3>Can you tell us...</h3>
          <TextQuestion
            question="The name of the law firm"
            value={this.state.lawFirmHandlingProbate.companyName || ''}
            valueEntered={this.handleInputChange('companyName')}
            validate={this.handleFieldValidation('companyName')}
            validationError={
              this.state.errors.companyName
                ? this.state.errors.companyName
                : null
            }
          />

          <TextQuestion
            question="The full name of the solicitor handling the case"
            value={this.state.lawFirmHandlingProbate.lawyerFullName || ''}
            valueEntered={this.handleInputChange('lawyerFullName')}
            validate={this.handleFieldValidation('lawyerFullName')}
            validationError={
              this.state.errors.lawyerFullName
                ? this.state.errors.lawyerFullName
                : null
            }
          />

          <TextQuestion
            question="Their email address"
            value={this.state.lawFirmHandlingProbate.emailAddress || ''}
            valueEntered={this.handleInputChange('emailAddress')}
            validate={this.handleFieldValidation('emailAddress')}
            validationError={
              this.state.errors.emailAddress
                ? this.state.errors.emailAddress
                : null
            }
          />

          <TextQuestion
            question="Their phone number"
            value={this.state.lawFirmHandlingProbate.phoneNumber || ''}
            valueEntered={this.handleInputChange('phoneNumber')}
            validate={this.handleFieldValidation('phoneNumber')}
            validationError={
              this.state.errors.phoneNumber
                ? this.state.errors.phoneNumber
                : null
            }
          />

          <Action onClick={this.saveSellingClient1Probate}>Continue</Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default SellingClient1Probate;
