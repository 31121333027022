import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media';

const StyledBackground = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  padding-top: 0.5em;
  padding-bottom: 2em;
  background-color: ${props => props.background === 'indigo' ? '#002457' : null};
  background-color: ${props => props.background === 'grey' ? '#e3e5e8' : null};
  background-color: ${props => props.background === 'white' ? '#ffffff' : null};

  color: ${props => props.background === 'indigo' ? '#f1f2f4' : null};
  color: ${props => props.background === 'grey' ? '#0d0d0d' : null};
`

const StyledBackgroundInner = styled.div`
  max-width: ${props => (props.theme.pageWidth[props.pageWidth] / 16)}rem;

  margin-left: ${props => props.theme.spacingScale[6]};
  margin-right: ${props => props.theme.spacingScale[6]};

  ${mediaQueries.md} {
    margin-left: ${props => props.theme.spacingScale[12]};
    margin-right: ${props => props.theme.spacingScale[12]};
  }

  @media(min-width: ${props => (props.theme.pageWidth[props.pageWidth] + props.theme.gridSpacing.columnWidth * 2) / 16}rem) {
    margin-left: auto;
    margin-right: auto;
  }

`

function FullWidthBackground(props) {
  return (
    <StyledBackground background={props.background} className={props.className}>
      <StyledBackgroundInner pageWidth={props.pageWidth}>
        {props.children}
      </StyledBackgroundInner>
    </StyledBackground>
    )
}

export default FullWidthBackground;

FullWidthBackground.propTypes = {
  background: PropTypes.oneOf(['indigo', 'grey', 'white']),
  pageWidth: PropTypes.oneOf(['narrow', 'regular', 'wide', 'wider'])
};

FullWidthBackground.defaultProps = {
  background: 'indigo',
  pageWidth: 'regular'
};
