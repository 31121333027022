import React from 'react';

import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

import { JunoDashboardCard, JunoDashboardCardHeader, JunoDashboardCardContent, StyledDashboardCardContent } from './DashboardCard'

const h1 = {
  sm: '32px',
  md: '42px',
};

const h2 = {
  sm: '18px',
  md: '20px',
};

const h3 = {
  sm: '16px',
  md: '16px',
};

const h4 = {
  sm: '16px',
  md: '18px',
};

const JunoDashboardPanel = styled(JunoDashboardCard)`
  color: white;
  background-color: #002457;
  margin-bottom: 16px;
  padding-top: 1em;
  ${mediaQueries.md} {
    padding-top: 0;
    box-shadow: 0 8px 16px 0 rgba(34, 50, 84, 0.06),
      inset 0 0 0 1px rgba(112, 123, 140, 0.15);
    border-radius: 8px;
  }
`;

const JunoDashboardPanelHeader = styled(JunoDashboardCardHeader)`
  color: #fac7c0;
  padding: 8px 16px 0 16px;
  ${mediaQueries.md} {
    padding: 32px 32px 0 32px;
  }
  h1,
  h2,
  h3,
  h4 {
    max-width: 608px;
  }
`;

const JunoPanelHeading = styled.h2`
  font-family: ${props => props.theme.junoFont};
  font-style: normal;
  line-height: 1.25em;
  margin: 0;

  &.h1 {
    font-size: ${h1['sm']};
    ${mediaQueries.md} {
      font-size: ${h1['md']};
    }
    font-weight: ${props => props.theme.fontWeight.regular};
    margin-bottom: 0;
  }

  &.h2 {
    font-size: ${h2['sm']};
    ${mediaQueries.md} {
      font-size: ${h2['md']};
    }
  }

  &.h3 {
    font-size: ${h3['sm']};
    ${mediaQueries.md} {
      font-size: ${h3['md']};
    }
  }

  &.h4 {
    font-size: ${h4['sm']};
    ${mediaQueries.md} {
      font-size: ${h4['md']};
    }
  }
`;

export const JunoDashboardPanelHeading = ({ level, size, ...props }) => {
  return (
    <JunoPanelHeading
      as={`h${level}`}
      className={size ? `h${size}` : `h${level}`}
      {...props}
    />
  );
};

function DashboardPanel(props) {
  return (
    <JunoDashboardPanel id={props.id} className={props.className}>
      {props.title && (
        <>
          <JunoDashboardPanelHeader>
            <JunoDashboardPanelHeading
              level={props.headingLevel ? props.headingLevel : '1'}
              size={props.headingSize ? props.headingSize : '1'}
            >
              {props.title}
            </JunoDashboardPanelHeading>
          </JunoDashboardPanelHeader>
          <JunoDashboardCardContent>
            {props.children}
          </JunoDashboardCardContent>
        </>
      )}

      {!props.title && (
        <StyledDashboardCardContent>
          {props.children}
        </StyledDashboardCardContent>
      )}
    </JunoDashboardPanel>
  );
}

export default DashboardPanel;
