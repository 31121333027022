import React from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

const RemoAccountNumbersIntro = (props) => {

  return (
    <>
      <ScrollToTop />
      <Card
        title={"Your mortgage account " + (props.numberOfSecuredLoans > 1 ? 'numbers' : 'number')}
        removeTopPadding
        headingSize="1"
      >
        <p>
            We’ll need your account {props.numberOfSecuredLoans > 1 ? 'numbers ' : 'number '}
            to arrange paying off the {props.numberOfSecuredLoans > 1 ? 'mortgages' : 'mortgage'}. It’ll help to find those details before you start.
        </p>
        <p>This form should take around two minutes to complete.</p>
        <Action onClick={() => props.onStepComplete()}>
          Let’s get started
        </Action>
      </Card>
      <Image src={imageOfficeWorking} fullWidth />
    </>
  );
}


export default RemoAccountNumbersIntro;
