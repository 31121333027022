import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const StyledGrid = styled.div`
  margin-left: -12px;
  margin-right: -12px;
  &:after {
    content: "";
    display: table;
    clear: both
  }
`
const StyledGridItem = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  float: left;

  ${props => props.width === 'full' ? 'width: 100%' : null};

  ${mediaQueries.md} {
    ${props => props.width === 'one-third-from-tablet' ? 'width: 33.3%' : null};
  }
`

function Grid(props) {
  return <StyledGrid>{props.children}</StyledGrid>
}

export default Grid;

export function GridItem(props) {
  return <StyledGridItem width={props.width}>
    {props.children}
    </StyledGridItem>
}

GridItem.propTypes = {
  width: PropTypes.oneOf(['full', 'one-third-from-tablet'])
};

GridItem.defaultProps = {
  width: 'one-third-from-tablet'
};
