import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';


function CaseFilterReset({ activeNavItem, activeNavItemName, searchInput, handleClearSearch, handleShowAll }) {

  const formatNavItemName = (navItem, name) => {
    if (navItem === 'all_cases') {
      return 'active cases';
    } else if (navItem === 'pre_memo') {
      return name + ' cases';
    }
    return name
  }

  return (
    <li>
      <StyledCaseFilterReset>
        {searchInput && (
          <>
            <h2>
              No search results found for <strong>{searchInput}</strong>
            </h2>
            <ResetButton onClick={handleClearSearch}>clear search</ResetButton>
          </>
        )}

        {!searchInput && activeNavItem && (
          <>
            <h2>
              No <strong>{formatNavItemName(activeNavItem, activeNavItemName)}</strong> found
            </h2>

            {activeNavItem && activeNavItem !== 'all_cases' && (
              <ResetButton onClick={handleShowAll}>Show all active cases</ResetButton>
            )}
          </>
        )}
      </StyledCaseFilterReset>
    </li>
  );
}

CaseFilterReset.propTypes = {
  selectedNavItem: PropTypes.string,
  tagGroup: PropTypes.string,
  caseType: PropTypes.string,
  searchInput: PropTypes.string,
};

export default CaseFilterReset;


const StyledCaseFilterReset = styled.div`
  width: 100%;
  max-width: 60rem;

  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;

  margin-bottom: 1.5rem;
  ${mediaQueries.lg} {
    margin-left: auto;
    margin-right: auto;
  }
  padding: 4rem;

  border-radius: 8px;
  background-image: linear-gradient(to left bottom, #dfdbff, #e7e4ff, #efedff, #f7f6ff, #ffffff);

  h2 {
    font-size: 26px;
    font-weight: 500;
    line-height: 1.1;
  }

  strong {
    color: ${props => props.theme.color.purple};
  }
`;


const ResetButton = styled.button`
  display: inline-block;
  min-height: 56px;

  background-color: ${props => props.theme.color.purple};
  color: ${props => props.theme.color.white};
  text-align: center;

  border: 0;
  border-radius: 12px;
  flex: none;

  margin-top: 1rem;
  padding: 16px 28px;

  font-size: 1rem;
  font-weight: ${props => props.theme.fontWeight.regular};
  line-height: 1.5;

  transition: background .25s, color .25s;
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.color.purple};
    background-color: ${props => props.theme.color.palePurple};
  }
`;
