import React, { Component } from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Action from '../../components/Action';
import Card from '../../components/Card';
import Image from '../../components/Image';
import imageRelaxingOnSofa from '../../images/relaxing-on-sofa.png';

class BrokerQuoteInstructLinkSent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTransactionType: this.props.transactionType,
      transactionType: this.props.transactionType,
      buyingDetails: this.props.buyingDetails,
      sellingDetails: this.props.sellingDetails,
      remortgagingDetails: this.props.remortgagingDetails,
      personalDetails: this.props.personalDetails,
      channel: this.props.channel,
      campaign: this.props.campaign,
      agentName: this.props.broker.fullName,
      agentEmailAddress: this.props.broker.emailAddress,
      additionalInformation: this.props.additionalInformation,
      quoteDetails: null,
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    fetch('/api/quote', {
      method: 'PUT',
      body: JSON.stringify({
        transactionType: this.props.transactionType,
        channel: this.props.channel,
        campaign: this.props.campaign,
        agentName: this.props.broker.fullName,
        agentEmailAddress: this.props.broker.emailAddress,
        additionalInformation: this.props.additionalInformation,
        personalDetails: this.props.personalDetails,
        sellingDetails:
          this.props.transactionType === 'Selling' ||
            this.props.transactionType === 'BuyingAndSelling'
            ? this.props.sellingDetails
            : null,
        buyingDetails:
          this.props.transactionType === 'Buying' ||
            this.props.transactionType === 'BuyingAndSelling'
            ? this.props.buyingDetails
            : null,
        remortgagingDetails:
          this.props.transactionType === 'Remortgaging'
            ? this.props.remortgagingDetails
            : null,
        referrer: null,
        referralCode: this.props.referral.referralCode,
        googleAnalyticsId: this.props.googleAnalyticsId,
        isConfirmedInstruction: true
      }),
    })
      .then(resp => resp.json())
      .then(
        quoteData => {
          this.setState({
            quoteDetails: quoteData,
            loading: false,
          });
        },
        () => {
          this.setState({
            error: 'Unable to generate your quote',
            loading: false,
          });
        },
      );
  }

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={`Thanks, we’ve sent ${this.props.personalDetails.firstName} a welcome email`} removeTopPadding>
          <p>
            We’ve sent your client an email to get started with Juno - and a copy of their quote to you.
          </p>

          <Action onClick={() => this.props.showFirst()}>
            Refer another client
          </Action>
        </Card>

        <Image src={imageRelaxingOnSofa} fullWidth />
      </React.Fragment>
    );
  }
}

export default BrokerQuoteInstructLinkSent;
