import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useClientAppFormRequest from '../../hooks/useClientAppFormRequest';

import StepFlowNavigate from '../../components/StepFlowNavigate';

import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import RemoAccountNumbersIntro from './RemoAccountNumbersIntro';
import RemoAccountNumbersForm from './RemoAccountNumbersForm';
import RemoAccountNumbersThanks from './RemoAccountNumbersThanks';
import RemoAccountNumbersSubmission from './RemoAccountNumbersSubmission';

const RemoAccountNumbersFlow = (props) => {
  const location = useLocation();
  const { requestId } = useParams();

  const [fromDashboard, setFromDashboard] = useState(false);
  const [flowCompleted, setFlowCompleted] = useState(false);
  const [initialLoanDetails, setInitialLoanDetails] = useState([]);

  const { loading, error, requestDetails, submitted } = useClientAppFormRequest(`/api/remortgageAccountNumbers/${requestId}`);

  // Set fromDashboard state on initial load
  useEffect(() => {
    const { fromDashboard } = location.state || {};
    setFromDashboard(fromDashboard);
  }, []);

  const numberOfSecuredLoans = requestDetails?.numberOfSecuredLoans;
  useEffect(() => {

  const securedLoans = [];
  for (let i = 1; i <= numberOfSecuredLoans; i++) {
    securedLoans.push(
        {
          accountName: requestDetails["securedLoanLender" + i],
          accountNumber: undefined,
          accountNumberIsValid: true
        }
      )
    }
    setInitialLoanDetails(securedLoans);
  }, [numberOfSecuredLoans]);

  if (loading) {
    return null;
  }

  if (submitted || flowCompleted) {
    return (
      <PageLayout>
        <RemoAccountNumbersThanks fromDashboard={fromDashboard} />
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <ScrollToTop />
        <Card title="Sorry, that didn't work" removeTopPadding>
          {error}
        </Card>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <StepFlowNavigate
        initialFlowState={{
          formRequestId: requestDetails.formRequestId,
          numberOfSecuredLoans: requestDetails.numberOfSecuredLoans,
          securedLoans: initialLoanDetails
        }}
        steps={[
          {
            slug: 'intro',
            component: flowState => (
              <RemoAccountNumbersIntro
                numberOfSecuredLoans={flowState.numberOfSecuredLoans} />
            ),
          },
          {
            slug: 'account-numbers',
            component: flowState => (
              <RemoAccountNumbersForm
                securedLoans={flowState.securedLoans}
                numberOfSecuredLoans={flowState.numberOfSecuredLoans}
              />
            ),
          },
          {
            slug: 'submit',
            component: flowState => (
              <RemoAccountNumbersSubmission
                remoAccountNumbersFlowState={flowState}
                onFlowComplete={() => setFlowCompleted(true)}
              />
            )
          }
        ]} />
    </PageLayout>
  );

};

export default RemoAccountNumbersFlow;
