import React, { Component } from 'react';
import Action from '../../components/Action';
import Card from '../../components/Card';
import { TextareaQuestion } from '../../components/TextQuestion';
import List, { ListItem } from '../../components/List';
import ScrollToTop from '../../components/utils/ScrollToTop';

class BrokerQuoteAnythingElse extends Component {
  constructor(props) {
    super(props);

    if (this.props.additionalInformation) {
      this.state = {
        additionalInformation: this.props.additionalInformation
      };
    } else {
      this.state = {
        additionalInformation: '',
      };
    }
  }

  additionalInformationEntered = ai => {
    this.setState({ additionalInformation: ai });
  };

  saveAnythingElse = () => {
    this.props.onStepComplete({
      additionalInformation: this.state.additionalInformation
    });
  };

  render() {
    const transactionType = this.props.transactionType;
    let tt;

    if (transactionType === 'Buying') {
      tt = 'purchase';
    }
    if (transactionType === 'BuyingAndSelling') {
      tt = 'sale and purchase';
    }
    if (transactionType === 'Remortgaging') {
      tt = 'remortgage';
    }

    let cardTitle =
      `Is there anything else you’d like to tell us about ${this.props.personalDetails.firstName}’s ${tt}?`;

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={cardTitle} removeTopPadding>
          <React.Fragment>
            <p>For example,</p>
            <List bullet>
              <ListItem>
                Is there a second client we could also get in touch with?
              </ListItem>
              {transactionType === 'Selling' && (
                <ListItem>Have they accepted an offer?</ListItem>
              )}
              {transactionType === 'Buying' && (
                <ListItem>Have they had an offer accepted?</ListItem>
              )}
              {transactionType === 'BuyingAndSelling' && (
                <ListItem>
                  Have they accepted an offer and had an offer accepted?
                </ListItem>
              )}
              <ListItem>
                Is there any reason they need to move quickly or slow things down?
              </ListItem>
            </List>
          </React.Fragment>

          <TextareaQuestion
            question="Anything else?"
            optional={true}
            value={this.state.additionalInformation}
            valueEntered={this.additionalInformationEntered}
          />


          <p>
            By clicking the button below, you agree to
            <a href="https://www.juno.legal/broker-referrals/" target="_blank" rel="noreferrer">
              {' '} our terms for referring customers to Juno
            </a>.
          </p>

          <Action onClick={this.saveAnythingElse}>
            {this.props.buttonText}
          </Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default BrokerQuoteAnythingElse;
