import React, { Component } from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon'
import { CheckCircle, DirectionsRun } from '@styled-icons/material';
import styled from 'styled-components/macro';
import List, { ListItem } from '../../components/List';
import { mediaQueries } from '../../styles/media';

const StyledTodoSummary = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  color: ${props => props.done ? props.theme.color.junoHeader : props.theme.color.junoText};
  background-color: ${props => props.done ? props.theme.color.paleGreen : props.theme.color.pink};

  padding-top: 1em;
  padding-bottom: 1em;
  ${mediaQueries.md} {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }

  margin-top: -1em;
  margin-bottom: 1em;
  ${mediaQueries.md} {
    margin-top: -2em;
  }

`;

const TodoSummaryContent = styled.div`

  margin-left: 20px;
  margin-right: 20px;

  ${mediaQueries.md} {
    margin-left: 45px;
    margin-right: 45px;
  }

  ${mediaQueries.lg} {
    width: 930px;
    margin-left: auto;
    margin-right: auto;
  }

  p,
  ul,
  ul li {
    font-size: 16px;
    line-height: 1.5;
  }

  ul:last-child {
    margin-bottom: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-size: 18px;
    ${mediaQueries.md} {
      font-size: 20px;
    }
    line-height: 1.3157894737;
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 0 0 1em 0;
  }

  a {
    font-size: 16px;
    text-decoration: none;
    color: ${props => props.theme.color.junoText};
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom-color: inherit;
    }
  }
`;

export const TodoSummaryHeading = styled.h3`
  display: flex;
  align-items: center;

  font-size: 16px;
  ${mediaQueries.md} {
    font-size: 19px;
  }
`;

export const TodoSummaryIcon = styled.div`
  ${StyledIconBase} {
    width: ${props => props.theme.iconSize['xl']};
    margin-right: 10px;
    ${mediaQueries.md} {
      margin-right: 16px;
    }
  }
`;

const triggerScroll = (id) => (e) => {
  e.preventDefault();
  const target = document.getElementById(id)
  if (!!target) {
    target.scrollIntoView({ behavior: "smooth", block: "start" })
  } else {
    console.warn("attempt to scroll to missing element", id)
  }
}

const ScrollLink = (props) => {
  return(
    <a href="" onClick={triggerScroll(props.toId)}>
      {props.children}
    </a>
  )
}

class TodoSummary extends Component {

  render() {
    const selectedCase = this.props.selectedCase;

    let unreadNotifications = [];
    if (selectedCase.notifications) {
      unreadNotifications = selectedCase.notifications.filter(n => n.status === 'unread');
    }

    const enquiryDoables = selectedCase.doables.filter(d =>
      d.doable_type === 'enquiry' && (d.status === 'available' || d.status === 'available_to_read')
    );

    const availableDoables = selectedCase.doables.filter(d => d.status === 'available');
    const numberOfFormsToFillIn = availableDoables.filter(d =>
      d.doable_type !== 'emailed_request' &&
      d.doable_type !== 'document_request' &&
      d.doable_type !== 'enquiry'
    ).length;
    const numberOfDocumentRequests = availableDoables.filter(d => d.doable_type === 'document_request').length;
    const numberOfEmailedRequests = availableDoables.filter(d => d.doable_type === 'emailed_request').length;

    const hasSummaryItems = availableDoables.length > 0 || unreadNotifications.length > 0 ||
      selectedCase.enquiries_waiting_for_client > 0 || selectedCase.enquiries_waiting_for_third_party > 0;

    if (! hasSummaryItems) {
      return (
        <StyledTodoSummary done>
          <TodoSummaryContent>
            <TodoSummaryHeading>
              <TodoSummaryIcon><CheckCircle /></TodoSummaryIcon>
              <span>We’ve got everything we need right now</span>
            </TodoSummaryHeading>
            <p>
              We’ll send you an email whenever there’s something for you to do.
            </p>
          </TodoSummaryContent>
        </StyledTodoSummary>
      )
    }

    return (
      <StyledTodoSummary>
        <TodoSummaryContent>
          <TodoSummaryHeading>
            <TodoSummaryIcon><DirectionsRun /></TodoSummaryIcon>
            <span>Help keep your case moving</span>
          </TodoSummaryHeading>

          <List bullet>

            {numberOfFormsToFillIn > 0 && (
              <ListItem>
                <ScrollLink toId="fill-in-forms">
                  {numberOfFormsToFillIn} {''} form{numberOfFormsToFillIn > 1 && 's'} to fill in
                </ScrollLink>
              </ListItem>
            )}

            {numberOfDocumentRequests > 0 && (
              <ListItem>
                <ScrollLink toId="upload-documents">
                  {numberOfDocumentRequests} {''} document{numberOfDocumentRequests > 1 && 's'} to upload
                </ScrollLink>
              </ListItem>
            )}

            {numberOfEmailedRequests > 0 && (
              <ListItem>
                <ScrollLink toId="other-things-we-need">
                  {numberOfEmailedRequests} {''} other thing{numberOfEmailedRequests > 1 && 's'} we need
                </ScrollLink>
              </ListItem>
            )}

            {unreadNotifications.length > 0 && (
              <ListItem>
                <ScrollLink toId="messages">
                  {unreadNotifications.length} {''} unread message{unreadNotifications.length > 1 && 's'}
                </ScrollLink>
              </ListItem>
            )}

            {enquiryDoables.length > 0 && (
              <ListItem>
                <ScrollLink toId="enquiries">
                  {enquiryDoables.length} {''} enquir{enquiryDoables.length > 1 ? 'ies' : 'y'} to read or respond to
                </ScrollLink>
              </ListItem>
            )}

            {selectedCase.enquiries_waiting_for_client > 0 && (
              <ListItem>
                <ScrollLink toId="client-enquiries">
                  {selectedCase.enquiries_waiting_for_client} {''} {selectedCase.enquiries_waiting_for_client > 1 ? 'questions' : 'question'} awaiting your reply
                </ScrollLink>
              </ListItem>
            )}

            {selectedCase.enquiries_waiting_for_third_party > 0 && (
              <ListItem>
                <ScrollLink toId="third-party-enquiries">
                  {selectedCase.enquiries_waiting_for_third_party} {''} {selectedCase.enquiries_waiting_for_third_party > 1 ? 'questions' : 'question'} awaiting a reply from your management company, lender or another third party
                </ScrollLink>
              </ListItem>
            )}
          </List>

          {(!! selectedCase.status_updates) && (
            <ScrollLink toId="current-status">
              See the current status of your {this.props.transactionType}
            </ScrollLink>

          )}
        </TodoSummaryContent>
      </StyledTodoSummary>
    )
  }
}

export default TodoSummary;
