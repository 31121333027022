import React, {Component} from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import * as Onfido from 'onfido-sdk-ui';
import styled from 'styled-components/macro';
import {mediaQueries} from '../../styles/media';

const OnfidoWrapper = styled.div`
  min-height: 500px;
`;

const ImageWrapper = styled.div`
  img {
    display: none;
  }
  ${mediaQueries.md} {
    img {
      display: block;
    }
  }
`;

class PerformOnfidoIdentityCheck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      identityCheckToken: null,
    };

    fetch('/api/identityCheck/getToken/' + props.identityRequestId)
      .then((resp) => resp.json())
      .then(
        (data) => {
          console.log('token was', data);
          this.setState({
            loading: false,
            identityCheckToken: data.token,
          });

          Onfido.init({
            token: data.token,
            containerId: 'onfido-mount',
            onComplete: onfidoData => {
              this.props.onStepComplete({
                onfidoData: onfidoData,
              });
            },
            onError: this.onError,
            onUserExit: this.onUserExit,
            steps: [
              {
                type: 'document',
                options: {
                  useLiveDocumentCapture: true,
                  uploadFallback: true,
                  documentTypes: {
                    passport: true,
                    driving_licence: true,
                    national_identity_card: false,
                  },
                },
              },
              {
                type: 'face',
                options: {
                  requestedVariant: 'video',
                  uploadFallback: true,
                },
              },
            ],
          });
        },
        () => {
          this.setState({
            loading: false,
            error: 'Failed to load request details',
          });
        }
      );
  }

  onError = data => {
    // no-op
  }

  onUserExit = data => {
    // no-op
  }

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <OnfidoWrapper>
          <div id="onfido-mount" />
        </OnfidoWrapper>
        <ImageWrapper>
          <Image src={imageOfficeWorking} fullWidth />
        </ImageWrapper>
      </React.Fragment>
    );
  }
}

export default PerformOnfidoIdentityCheck;
