import React from 'react';

import { useSelectedCase } from "./utils/hooks";
import DashboardCard from '../../components/DashboardCard';
import PageHeader from "../../components/PageHeader";
import ScrollToTop from '../../components/utils/ScrollToTop';
import ListDoable from './ListDoable';

const CaseArchive = () => {
  const selectedCase = useSelectedCase({caseScreen: 'archive'});

  if (! selectedCase) {
    return null;
  }

  const completeDoables = selectedCase.doables.filter(d => d.status === 'complete');
  const completeClientAppForms = completeDoables.filter(td =>
    td.doable_type !== 'information_request' &&
    td.doable_type !== 'emailed_request' &&
    td.doable_type !== 'document_request' &&
    td.doable_type !== 'enquiry'
  );
  const completeInformationRequests = completeDoables.filter(td => td.doable_type === 'information_request');
  const completeDocumentRequests = completeDoables.filter(td => td.doable_type === 'document_request');
  const completeEmailedRequests = completeDoables.filter(td => td.doable_type === 'emailed_request');

  const hasCompleteForms = (completeClientAppForms.length > 0 || completeInformationRequests.length > 0);

  return (
    <>
      <ScrollToTop />
      <PageHeader>
        Things you’ve done
      </PageHeader>
      {hasCompleteForms && (
        <DashboardCard title="Completed forms" size="1">

          {completeClientAppForms.length > 0 &&
            <ListDoable
              key="completeClientAppForms"
              items={completeClientAppForms}
              clickable={false}
            />
          }

          {completeInformationRequests.length > 0 &&
            <ListDoable
              key="completeInformationRequests"
              items={completeInformationRequests}
            />
          }
        </DashboardCard>
      )}

      {completeDocumentRequests.length > 0 && (
        <DashboardCard title="Uploaded documents" size="2">
          <ListDoable
            key="completeDocumentRequests"
            items={completeDocumentRequests}
          />
        </DashboardCard>
      )}

      {completeEmailedRequests.length > 0 && (
        <DashboardCard title="Completed other things we need" size="3">
          <ListDoable
            key="completeEmailedRequests"
            items={completeEmailedRequests}
          />
        </DashboardCard>
      )}
    </>
  );
}

export default CaseArchive
