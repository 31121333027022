import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';

const numberOfLoansOptions = [{ title: 1, value: 1 }, { title: 2, value: 2 }];

class SellingNumberOfLoans extends Component {
  constructor(props) {
    super(props);

    if (this.props.numberOfLoans) {
      this.state = {
        numberOfLoans: this.props.numberOfLoans,
        errors: { ...this.props.errors },
        errorMessages: {
          numberOfLoans: 'Please choose an option',
        },
      };
    } else {
      this.state = {
        numberOfLoans: null,
        errors: {},
        errorMessages: {
          numberOfLoans: 'Please choose an option',
        },
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};

    let formIsValid = true;

    if (!fields['numberOfLoans']) {
      const field = 'numberOfLoans';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  saveSellingNumberOfLoans = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        numberOfLoans: this.state.numberOfLoans,
        errors: this.state.errors,
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Your loan" removeTopPadding>
          <RadioQuestion
            question="How many loans are there on the property?"
            options={numberOfLoansOptions}
            inline={false}
            name="numberOfLoans"
            selected={this.state.numberOfLoans}
            optionSelected={this.handleInputChange('numberOfLoans')}
            validate={this.handleFieldValidation('numberOfLoans')}
            validationError={
              this.state.errors.numberOfLoans
                ? this.state.errors.numberOfLoans
                : null
            }
          />

          <Action onClick={this.saveSellingNumberOfLoans}>Continue</Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default SellingNumberOfLoans;
