import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Menu } from '@styled-icons/material/Menu';
import { Close } from '@styled-icons/material/Close';
import { mediaQueries } from '../styles/media.js';
import { Link } from 'react-router-dom';

import JunoLogo from './JunoLogo.js';

const Header = styled.header`
  width: 100%;

  display: flex;
  align-items: baseline;
  justify-content: space-between;

  background-color: ${props => props.theme.color.junoBrand};
  color: ${props => props.theme.color.junoHeader};

  position: fixed;
  top: 0;
  height: 4rem;
`;

const HeaderInner = styled.nav`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 0.75rem;
  height: 4rem;

  padding-left: 0.5rem;
  padding-right: 2rem;
  ${mediaQueries.lg} {
    padding-left: 1.25rem;
  }
`;

const NavMenuButton = styled.button`
  display: block;
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;

  border-color: transparent;
  background-color: transparent;
  transition-property: transform;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  cursor: pointer;
  color: white;
  &:hover {
    border-radius: 9999px;
    background-color: white;
    color: ${props => props.theme.color.junoBrand};
  }
  ${mediaQueries.lg} {
    display: none;
  }
`;

const NavIconMenu = styled(Menu)`
  color: inherit;
`

const NavIconClose = styled(Close)`
  color: inherit;
`

const UserWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-left: auto;
  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: 1.5;
  ${mediaQueries.lg} {
    margin-right: 1rem;
  }
`;

const UserName = styled.p`
  font-size: ${props => props.theme.fontSizes.p['sm']};
  display: none;
  ${mediaQueries.xl} {
    display: inline-flex;
  }
  color: white;
  margin-right: 2rem;
`;

const HeaderLink = styled(Link)`
  width: fit-content;
  margin-left: auto;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${props => props.theme.color.white};
  }

  border-bottom-style: solid;
  border-bottom-color: transparent;
  &:hover {
    border-bottom-color: #fff;
  }
`;

function DashboardHeader({ children, onToggleMenu, showMenu, showLogout, user }) {
  return (
    <Header>
      <HeaderInner>
        <NavMenuButton onClick={onToggleMenu}>
          {showMenu ? <NavIconClose /> : <NavIconMenu />}
        </NavMenuButton>

        <JunoLogo />
        {children}

        <UserWrapper>
          {user && <UserName>{user.full_name}</UserName>}
          {showLogout && <HeaderLink to="/logout">Sign out</HeaderLink>}
        </UserWrapper>
      </HeaderInner>
    </Header>
  );
}

export default DashboardHeader;

DashboardHeader.propTypes = {
  children: PropTypes.node,
  onToggleMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  showLogout: PropTypes.bool.isRequired,
  user: PropTypes.object,
};
