import styled from 'styled-components/macro';

function TabPanel(props) {
  return <StyledTabPanel show={props.show}>{props.children}</StyledTabPanel>;
}

export default TabPanel;

const StyledTabPanel = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};

  padding-top: 1rem;
  padding-bottom: 1rem;

  svg {
    height: 22px;
    width: 22px;
  }
  ul,
  p {
    margin-top: 2px;
    margin-bottom: 4px;
  }
  * {
    font-size: 0.875rem;
  }
`;

// const StyledTabText = styled.div`
//   h2 {
//     font-size: 14px;
//     ${mediaQueries.md} {
//       font-size: 16px;
//     }
//     color: #0e172a;
//   }

//   h3 {
//     font-size: 14px;
//     line-height: 1.5;

//     font-weight: ${props => props.theme.fontWeight.bold};
//     margin: 6px 0 12px 0;
//     color: #0f172a;
//   }

//   ul {
//     list-style: bullet;
//     margin: 0 0 0 1rem;
//     padding: 0;

//     font-size: 14px;
//     ${mediaQueries.md} {
//       font-size: 16px;
//     }
//     color: #455366;
//   }

//   li {
//     line-height: 1.5;
//     font-weight: ${props => props.theme.fontWeight.light};
//   }

//   p {
//     font-size: 14px;
//     ${mediaQueries.md} {
//       font-size: 16px;
//     }
//   }
// `;
