import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

function StatusUpdateTitle({ type, status, children }) {
  return (
      <Title type={type} status={status}>{children}</Title>
  );
};

export default StatusUpdateTitle;

StatusUpdateTitle.propTypes = {
  status: PropTypes.string.isRequired,
  type: PropTypes.string,
};

StatusUpdateTitle.defaultProps = {
  type: 'generic',
};

const Title = styled.h3`
  color: ${({status, theme}) => theme.statusColor[status]};
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: ${({type, theme}) => type === "generic" ? theme.spacingScale[4] : 0};
  font-weight: ${props => props.theme.fontWeight.bold};
`;
