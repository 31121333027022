import React, {Component} from 'react';
import styled from 'styled-components/macro';
import {default_rooms} from "./TA10Config";
import Card from "../../components/Card";
import Link from '../../components/Link';
import List, { ListItem } from "../../components/List";
import ScrollToTop from "../../components/utils/ScrollToTop";
import ShowAddTA10Item from "./ShowAddTA10Item";
import AddTA10Item from "./AddTA10Item";

class TA10RoomManager extends Component {

  constructor(props) {
    super(props);
    this.state = this.initStateFromProps(props);
  }

  initStateFromProps(props) {
    if (props.rooms) {
      return {
        rooms: [...props.rooms],
        sectionRooms: props.sectionRooms,
        roomToAdd: '',
        errors: {}
      }
    } else {
      return {
        rooms: [...default_rooms],
        sectionRooms: [],
        roomToAdd: '',
        errors: {}
      }
    }
  }

  addRoom = () => {
    this.setState({
      rooms: [...this.state.rooms, this.state.roomToAdd],
      roomToAdd: '',
      showAddRoom: false
    })
  }

  removeRoom = (room) => {
    this.setState({
      rooms: this.state.rooms.filter(r => r !== room),
      sectionRooms: this.state.sectionRooms.filter(sr => sr.title !== room)
    })
  }

  showAddRoom = () => {
    this.setState({ showAddRoom: true })
  }

  undoAddRoom = () => {
    this.setState({ showAddRoom: false })
  }

  updateRoomToAdd = (name) => {
    this.setState({ roomToAdd: name })
  }

  validateRoom = (event) => {
    // Bathroom isn't in the room config and can't be removed
    const rooms = [...this.state.rooms, 'Bathroom'];
    const roomToAdd = this.state.roomToAdd;

    const errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type === 'blur') {
      if (rooms.includes(roomToAdd)) {
        errors['roomToAdd'] = true
        errorMessages['roomToAdd'] = `${roomToAdd} already exists`
      }
    } else {
      errors['roomToAdd'] = false
      errorMessages['roomToAdd'] = null
    }

    this.setState({ errors, errorMessages })
  }

  saveRoomsList = (event) => {
    event.preventDefault();
    this.props.onStepComplete({rooms: this.state.rooms, sectionRooms: this.state.sectionRooms})
  }

  resetRooms = () => {
    this.setState({rooms: [...default_rooms], sectionRooms: []})
  }

  render() {

    return (
      <>
        <ScrollToTop />
        <Card title="Set up the right rooms for your property" removeTopPadding>
          <p>Add any rooms that are missing from the list, and remove any rooms that you don't have at the property.</p>
          <p>We'll ask you what's in each room as you progress through the form.</p>
          <List>
            <StyledListItem>Kitchen</StyledListItem>
            <StyledListItem>Bathroom</StyledListItem>
            {this.state.rooms
              .filter(room => room !== 'Kitchen')
              .map((room, index) => {
                return (
                  <StyledListItem key={index}>
                    {room}
                    <Link onClick={() => this.removeRoom(room)}>Remove</Link>
                  </StyledListItem>
                )
              })
            }
          </List>

          {!this.state.showAddRoom && (
            <ShowAddTA10Item
              onShowAdd={this.showAddRoom}
              addLinkText="Add a new room"
              continue={this.saveRoomsList}
            />
          )}

          {this.state.showAddRoom && (
            <AddTA10Item
              question="Add another room"
              value={this.state.roomToAdd}
              valueEntered={this.updateRoomToAdd}
              validate={this.validateRoom}
              validationError={
                this.state.errors.roomToAdd
                  ? this.state.errorMessages.roomToAdd
                  : null}
              addButtonText="Add room"
              onAdd={this.addRoom}
              onCancel={this.undoAddRoom}
            />
          )}

          <p>If you need to start over, you can <Link onClick={() => this.resetRooms()}>restore the list of rooms</Link>.</p>

        </Card>
      </>
    )
  }
}

const StyledListItem = styled(ListItem)`
  border-bottom: 1px solid ${props => props.theme.color.paleGrey};
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0;
  font-size: 16px;
`

export default TA10RoomManager;
