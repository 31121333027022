import React, { Component } from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Action from '../../components/Action';
import Card from '../../components/Card';
import CheckList, { CheckListItem } from '../../components/CheckList';
import List, { ListItem } from '../../components/List';
import Details from '../../components/Details';
import { formatMoneyWithCurrency, moneyStrToNum } from '../../utils/moneyUtils';
import Image from '../../components/Image';
import imageRelaxingOnSofa from '../../images/relaxing-on-sofa.png';
import ReferralCodeBanner from "../../components/ReferralCodeBanner";
import { ListItemSuffix, QuoteBreakdown, QuotedPrice, QuotedPriceWrapper } from "../QuoteCommon/QuoteStyles";
import { isSequenceReferral } from './utils/ReferrerQuoteUtils';

class ReferrerQuoteDetailsPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let channel = props.defaultChannel;
    if (this.props.referral && this.props.referral.referralSource) {
      channel = this.props.referral.referralSource;
    }

    this.state = {
      selectedTransactionType: this.props.transactionType,
      transactionType: this.props.transactionType,
      buyingDetails: this.props.buyingDetails,
      sellingDetails: this.props.sellingDetails,
      remortgagingDetails: this.props.remortgagingDetails,
      personalDetails: this.props.personalDetails,
      channel: channel,
      agentName: this.props.agentName,
      agentEmailAddress: this.props.agentEmailAddress,
      additionalInformation: this.props.additionalInformation,
      quoteDetails: null,
      loading: true,
      error: null,
      showReferralCodeBanner: !this.props.inBranchReferral,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    fetch('/api/partialQuote ', {
      method: 'PUT',
      body: JSON.stringify({
        transactionType: this.props.transactionType,
        channel: this.state.channel,
        agentName: this.props.agentName,
        agentEmailAddress: this.props.agentEmailAddress,
        additionalInformation: this.props.additionalInformation,
        personalDetails: this.props.personalDetails,
        sellingDetails:
          this.props.transactionType === 'Selling' ||
          this.props.transactionType === 'BuyingAndSelling'
            ? this.props.sellingDetails
            : null,
        buyingDetails:
          this.props.transactionType === 'Buying' ||
          this.props.transactionType === 'BuyingAndSelling'
            ? this.props.buyingDetails
            : null,
        remortgagingDetails:
          this.props.transactionType === 'Remortgaging'
            ? this.props.remortgagingDetails
            : null,
        referralCode: this.props.referral ? this.props.referral.referralCode : null,
        googleAnalyticsId: this.props.googleAnalyticsId,
      }),
    })
      .then(resp => resp.json())
      .then(
        quoteData => {
          if (this._isMounted) {
            this.setState({
              quoteDetails: quoteData,
              loading: false,
            });
          }
        },
        () => {
          if (this._isMounted) {
            this.setState({
              error: 'Unable to generate your quote',
              loading: false,
            });
          }
        },
      );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {

    if (!!this.state.loading) {
      return null;
    }

    let saleLegalFee = '';
    let salePropertyPrice = '';
    let purchaseLegalFee = '';
    let purchasePropertyPrice = '';
    let quotePrice = '';
    let quotePriceText = '';
    let stampDuty = '';
    let stampDutyDescription = 'Stamp Duty';
    let landRegistryFee = '';
    let companiesHouseRegistrationFee = '';
    let totalFeesAndTaxes = '';
    let sellingTenure = '';
    let buyingTenure = '';
    let remortgageLegalFee = '';
    let noSearchIndemnityFee = '';
    let showSearches = true;

    if (this.state.channel === 'towerswills') {
      showSearches = false;
    }

    const sequenceCase = isSequenceReferral(this.state.channel)

    let includeDecimal =
      landRegistryFee.includes('.') || stampDuty.includes('.');

    if (this.state.quoteDetails) {
      quotePrice = formatMoneyWithCurrency(
        moneyStrToNum(this.state.quoteDetails.totalPrice).toString(),
        includeDecimal,
      );

      saleLegalFee = formatMoneyWithCurrency(
        moneyStrToNum(this.state.quoteDetails.sellingQuotePrice).toString(),
        includeDecimal,
      );

      purchaseLegalFee = formatMoneyWithCurrency(
        moneyStrToNum(this.state.quoteDetails.buyingQuotePrice).toString(),
        includeDecimal,
      );

      remortgageLegalFee = formatMoneyWithCurrency(
        moneyStrToNum(this.state.quoteDetails.remortgageQuotePrice),
        includeDecimal,
      );

      stampDuty = formatMoneyWithCurrency(
        moneyStrToNum(this.state.quoteDetails.stampDutyFee).toString(),
        includeDecimal,
      );

      if (
        this.props.buyingDetails &&
        (!!this.props.buyingDetails.rightToBuyScheme ||
          !!this.props.buyingDetails.sharedOwnership)
      ) {
        stampDutyDescription =
          'Stamp Duty (based on the full value of the property)';
      }

      landRegistryFee = formatMoneyWithCurrency(
        moneyStrToNum(this.state.quoteDetails.landRegistryFee).toString(),
        includeDecimal,
      );

      companiesHouseRegistrationFee = formatMoneyWithCurrency(
        moneyStrToNum(this.state.quoteDetails.companiesHouseRegistrationFee),
        includeDecimal,
      );

      totalFeesAndTaxes = formatMoneyWithCurrency(
        moneyStrToNum(this.state.quoteDetails.totalFeesAndTaxes).toString(),
        includeDecimal,
      );

      noSearchIndemnityFee = formatMoneyWithCurrency(
        moneyStrToNum(this.state.quoteDetails.noSearchIndemnityFee).toString(),
        includeDecimal,
      );

      purchasePropertyPrice =
        this.state.transactionType === 'Buying' ||
        this.state.transactionType === 'BuyingAndSelling'
          ? formatMoneyWithCurrency(
          moneyStrToNum(this.props.buyingDetails.price).toString(),
          includeDecimal,
          )
          : '';

      salePropertyPrice =
        this.state.transactionType === 'Selling' ||
        this.state.transactionType === 'BuyingAndSelling'
          ? formatMoneyWithCurrency(
          moneyStrToNum(this.props.sellingDetails.price).toString(),
          includeDecimal,
          )
          : '';
    }

    if (
      this.props.transactionType === 'Selling' ||
      this.props.transactionType === 'BuyingAndSelling'
    ) {
      if (
        this.props.sellingDetails.tenure === 'leasehold with share of freehold'
      ) {
        sellingTenure = 'share of freehold';
      } else {
        sellingTenure = this.props.sellingDetails.tenure;
      }
    }

    if (
      this.props.transactionType === 'Buying' ||
      this.props.transactionType === 'BuyingAndSelling'
    ) {
      if (
        this.props.buyingDetails.tenure === 'leasehold with share of freehold'
      ) {
        buyingTenure = 'share of freehold';
      } else {
        buyingTenure = this.props.buyingDetails.tenure;
      }
    }

    let noHiddenFeesIntro = 'Our quote covers everything you need for a typical ';

    switch (this.state.transactionType) {
      case 'Buying':
        quotePriceText = `That’s our quote, including VAT, for legal work on a ${buyingTenure} purchase costing ${purchasePropertyPrice}.`;
        noHiddenFeesIntro += 'purchase, including VAT, bank transfers'
        if (showSearches) {
          noHiddenFeesIntro += ', ID checks and searches.'
        } else {
          noHiddenFeesIntro += ' and ID checks.'
        }
        break;
      case 'Selling':
        quotePriceText = `That’s our quote, including VAT, for legal work on a ${sellingTenure} sale costing ${salePropertyPrice}.`;
        noHiddenFeesIntro += 'sale, including VAT, bank transfers and ID checks.'
        break;
      case 'BuyingAndSelling':
        quotePriceText = `That’s our quote, including VAT, for legal work on a ${buyingTenure} purchase costing ${purchasePropertyPrice} and ${sellingTenure} sale costing ${salePropertyPrice}.`;

        noHiddenFeesIntro += 'sale and purchase, including VAT, bank transfers'
        if (showSearches) {
          noHiddenFeesIntro += ', ID checks and searches.'
        } else {
          noHiddenFeesIntro += ' and ID checks.'
        }
        break;
    }

    return (
      <React.Fragment>
        <ScrollToTop/>
        <Card title="Here’s your conveyancing quote" removeTopPadding>
          <QuotedPriceWrapper>
            <QuotedPrice>{quotePrice}</QuotedPrice>
            <p>{quotePriceText}</p>
            {!sequenceCase && (
            <CheckList>
              {(this.state.transactionType !== 'Remortgaging') && (
                <>
                  <CheckListItem>No completion, no legal fee</CheckListItem>
                  <CheckListItem>No hidden fees</CheckListItem>
                </>
              )}
              {(this.state.transactionType === 'Buying' ||
                this.state.transactionType === 'BuyingAndSelling') && showSearches && (
                <CheckListItem>Search guarantee</CheckListItem>
              )}

              {(this.state.transactionType === 'Remortgaging') && (
                <>
                  <CheckListItem>Perform legal work for borrower and new lender</CheckListItem>
                  <CheckListItem>Carry out online ID checks on all borrowers</CheckListItem>
                  <CheckListItem>Purchase and review title registers and other land registry documents</CheckListItem>
                  <CheckListItem>Obtain redemption statements</CheckListItem>
                  <CheckListItem>Carry out bankruptcy checks on borrowers</CheckListItem>
                  <CheckListItem>Obtain Land Registry-approved e-signatures on mortgage deeds</CheckListItem>
                  <CheckListItem>Bank transfers to redeem mortgage and credit borrower's account with surplus funds (if available)</CheckListItem>
                  <CheckListItem>Arrange for discharge of existing mortgage and registration of new charge</CheckListItem>
                </>
              )}
            </CheckList>
              )}
          </QuotedPriceWrapper>

          {!!this.props.referral && this.state.showReferralCodeBanner && (
            <ReferralCodeBanner text={this.props.referral.quoteMessage} withinQuote={true} />
          )}

          {this.state.transactionType === 'Buying' ||
          this.state.transactionType === 'BuyingAndSelling' ? (
            <QuoteBreakdown>
              <List>
                {this.state.transactionType === 'BuyingAndSelling' ? (
                  <ListItem divider>
                    Legal work on the {sellingTenure} sale
                    <ListItemSuffix>{saleLegalFee}</ListItemSuffix>
                  </ListItem>
                ) : (
                  <>
                  </>
                )}
                <ListItem divider>
                  Legal work on the {buyingTenure} purchase
                  <ListItemSuffix>{purchaseLegalFee}</ListItemSuffix>
                </ListItem>
                <ListItem divider>
                  Land Registry fee
                  <ListItemSuffix data-testid="land-registry-fee">
                    {landRegistryFee}
                  </ListItemSuffix>
                </ListItem>
                <ListItem divider>
                  {stampDutyDescription}
                  <ListItemSuffix data-testid="stamp-duty-fee">
                    {stampDuty}
                  </ListItemSuffix>
                </ListItem>
                {this.state.buyingDetails.clientIsCompany &&
                  <ListItem divider>
                    Companies house registration fee
                    <ListItemSuffix data-testid="companies-house-registration-fee">
                      {companiesHouseRegistrationFee}
                    </ListItemSuffix>
                  </ListItem>
                }
                <ListItem divider>
                  Total including government fees and taxes
                  <ListItemSuffix data-testid="total-fees-and-taxes">
                    {totalFeesAndTaxes}
                  </ListItemSuffix>
                </ListItem>
              </List>
            </QuoteBreakdown>
          ) : null}

          {this.state.transactionType === 'Remortgaging' && (
            <QuoteBreakdown>
              <List>
                <ListItem divider>
                  Our fee for legal work switching your mortgage
                  <ListItemSuffix data-testid="no-search-indemnity-fee">
                    {remortgageLegalFee}
                  </ListItemSuffix>
                </ListItem>
                <ListItem divider>
                  Land Registry fee
                  <ListItemSuffix data-testid="land-registry-fee">
                    {landRegistryFee}
                  </ListItemSuffix>
                </ListItem>
              </List>
            </QuoteBreakdown>
          )}

          <Action onClick={() => this.props.onStepComplete(
            {}
          )}>
            Send your client a quote
          </Action>
        </Card>

        {!sequenceCase && (
        <Card title="No hidden fees" removeTopPadding headingSize="3">
          <p>
            {noHiddenFeesIntro}
          </p>


          <Details summary="See what’s included">
            <p>
              Here’s what’s included:
            </p>

            <List bullet>
              <ListItem>Legal work</ListItem>

              {(this.state.transactionType === 'BuyingAndSelling' ||
                  this.state.transactionType === 'Buying') && showSearches &&
                <>
                  <ListItem>Searches: local, water and drainage, and environmental reports – £148.20, included in our
                    initial fee</ListItem>
                  <ListItem>Chancel liability insurance – £12.50, included in our initial fee</ListItem>
                </>
              }
              <ListItem>
                ID checks – £6 per person, included in our legal fee
              </ListItem>
              <ListItem>
                Bank transfers – £20 each, included in our legal fee
              </ListItem>

              {(this.state.transactionType === 'BuyingAndSelling' ||
                  this.state.transactionType === 'Buying') &&
                <>
                  <ListItem>
                    Bankruptcy searches – £2 each, included in our legal fee
                  </ListItem>
                  <ListItem>
                    Land Registry ‘official search with priority’ – £3 each, included in our legal fee
                  </ListItem>
                </>
              }
              <ListItem>
                Land Registry documents – £3 each, included in our legal fee
              </ListItem>
              <ListItem>Handling your mortgage</ListItem>

              {(this.state.transactionType === 'BuyingAndSelling' ||
                  this.state.transactionType === 'Buying') &&
                <>
                  {!this.state.buyingDetails.clientIsCompany &&
                    <ListItem>Claiming your Help to Buy or Lifetime ISA bonus</ListItem>}
                  <ListItem>Filing your Stamp Duty tax return</ListItem>
                </>
              }
              <ListItem>VAT</ListItem>
              <ListItem>Regular email updates</ListItem>
              <ListItem>Telephone support</ListItem>
            </List>

            <p>
              Just so you know, if the property has a managing agent, they’ll
              probably make you pay some fees. Talk to the managing agent for
              more information.
            </p>

          </Details>

          {(this.state.transactionType === "Buying" ||
              this.state.transactionType === "BuyingAndSelling") &&
            <>
              {!this.state.buyingDetails.clientIsCompany &&
                <p>We’ve quoted for Stamp Duty as a UK resident at the full market value of the property. For a more detailed Stamp Duty estimate, visit <a href="https://www.gov.uk/stamp-duty-land-tax" target="_blank" rel="noreferrer">www.gov.uk/stamp-duty-land-tax</a></p>}
              {this.state.buyingDetails.clientIsCompany &&
              <p>We’ve calculated the Stamp Duty due at the full corporate rate and at the full market value of the property. For a more detailed Stamp Duty estimate, visit <a href="https://www.gov.uk/stamp-duty-land-tax" target="_blank" rel="noreferrer">www.gov.uk/stamp-duty-land-tax</a></p>}
            </>
          }
        </Card>
        )}

        <Image src={imageRelaxingOnSofa} fullWidth/>
      </React.Fragment>
    );
  }
}

export default ReferrerQuoteDetailsPage;
