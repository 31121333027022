import React from 'react';
import { Link } from 'react-router-dom';
import Action from '../../components/Action';
import Card from '../../components/Card';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import List, { ListItem } from '../../components/List';
import ScrollToTop from '../../components/utils/ScrollToTop';

const SourceOfFundsThanks = ({ fromDashboard, submittedFlowState }) => {

  const flowState = submittedFlowState ?? null;

  return (
    <React.Fragment>
      <ScrollToTop />
      <Card title="That’s it, thanks!" removeTopPadding>
        <p>
          We’ll check this through soon. We’ll also need to check things like
          your bank account statements, so we can understand more about where
          your savings have come from.
        </p>

        {flowState && (
          <>
            <p>
              To help us do that, can you prepare the
              following documents:
            </p>

            <List bullet>
                {flowState.howIsBuyerPaying.mortgage === true && (
                  <React.Fragment>
                    <ListItem>
                      Six months of bank statements for every
                      account you’re using to contribute money towards your deposit.
                    </ListItem>
                  </React.Fragment>
                )}

                {flowState.howIsBuyerPaying.cashAndSavings === true && (
                  <React.Fragment>
                    <ListItem>
                      Six months of bank statements for every account you've
                      told us you're saving money in.
                    </ListItem>
                    <ListItem>
                      If you've moved money between accounts (for example, from a
                      current account to a savings account) we'll also need to see
                      six months of statements for those accounts too.
                    </ListItem>
                  </React.Fragment>
                )}

                {flowState.howIsBuyerPaying.sellingAnotherProperty === true &&
                  flowState.linkedSale.linkedSaleComplete === true && (
                    <ListItem>
                      A copy of the final statement that shows how much you were
                      sent when your sale completed.
                    </ListItem>
                  )}

                {flowState.howIsBuyerPaying.remortgagingAnotherProperty === true && (
                  <ListItem>
                    A copy of the {flowState.remortgage.remortgageComplete === true ? 'final' : 'draft'} completion statement from the law firm handling the remortgage, showing the net proceeds {flowState.remortgage.remortgageComplete === true ? 'you have received' : 'you will receive'}.
                  </ListItem>
                )}

                {flowState.howIsBuyerPaying.helpToBuyIsa === true && (
                  <ListItem>
                    Six months of bank statements for every Help to Buy ISA
                    account you're saving money in.
                  </ListItem>
                )}

                {flowState.howIsBuyerPaying.lifetimeIsa === true && (
                  <ListItem>
                    Six months of bank statements for every Lifetime ISA account
                    you're saving money in.
                  </ListItem>
                )}

                {flowState.howIsBuyerPaying.inheritance === true && (
                  <ListItem>
                    Any statements of 'letters of distribution' sent by the law
                    firm who handled the money you inherited.
                  </ListItem>
                )}

                {flowState.howIsBuyerPaying.bonusFromEmployer === true && (
                  <ListItem>
                    A payslip for the month you received the annual or one-off bonus from your employer.
                  </ListItem>
                )}
            </List>

            {flowState.howIsBuyerPaying.someoneGivingMeMoney === true && (
              <p>
                We will be contacting anyone you have told us is giving you money
                as a gift to start our identity checks.
              </p>
            )}

            {flowState.howIsBuyerPaying.someoneLendingMeMoney === true && (
              <p>
                We will also be contacting the people you told us will be giving
                you a loan to help pay for your purchase.
              </p>
            )}

            {flowState.howIsBuyerPaying.trust === true && (
              <p>
                We’ll ask you to provide the name and contact details for the trustee.
              </p>
            )}
          </>
        )}

        <p>
          We’ll send you an email soon confirming the specific documents we
          need. Thanks again!
        </p>

        {fromDashboard && (
          <Action to="/dashboard" as={Link}>
            Take me back to my dashboard
          </Action>
        )}
      </Card>
      <Image src={imageOfficeWorking} fullWidth />
    </React.Fragment>
  );
}

export default SourceOfFundsThanks;
