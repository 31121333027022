import React from 'react';
import styled, { css } from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const JunoList = styled.ul`
  display: block;
  color: ${props => props.theme.color.junoText};
  font-family: ${props => props.theme.junoFont};
  font-weight: ${props => props.theme.fontWeight.light};
  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: 1.5;
  margin-bottom: 1em;
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
    margin-bottom: 1.5em;
    line-height: 1.75;
  }
  list-style-type: none;
  padding-left: 0;

  ${props =>
    props.number &&
    css`
      padding-left: 16px;
      list-style-type: decimal;

      li {
        padding-left: 8px;
      }
    `};

  ${props =>
    props.bullet &&
    css`
      padding-left: 16px;
      list-style-type: disc;

      li {
        padding-left: 8px;
      }
    `};
`;

const JunoListItem = styled.li`
  margin-bottom: 2px;
  ${mediaQueries.md} {
    margin-bottom: 4px;
  }
  ${props =>
    props.divider &&
    css`
      font-size: 1em;
      position: relative;
      padding: 4px 0;
      border-bottom: 1px solid ${props => props.theme.color.junoBorder};
      background-clip: padding-box;
      ${mediaQueries.md} {
        border-width: 2px;
        padding-bottom: 8px;
      }
    `};
`;

function List(props) {
  return <JunoList {...props}>{props.children}</JunoList>;
}

export function ListItem(props) {
  return <JunoListItem {...props}>{props.children}</JunoListItem>;
}

export default List;
