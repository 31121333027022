// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-Button-fallbackButton{cursor:pointer;display:inline-block;margin:0;padding:0;color:inherit;font:inherit;text-decoration:underline;background:none;border:0}", ""]);
// Exports
exports.locals = {
	"modal_animation_duration": "200ms",
	"fallbackButton": "onfido-sdk-ui-Button-fallbackButton"
};
module.exports = exports;
