import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import DateOfBirthQuestion from '../../components/DateOfBirthQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { isDayValid, isMonthValid, isYearValid } from "../../utils/textUtils";

class ClientDetails extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      annualIncome: 'Enter your annual income',
      dateOfBirth: 'Enter your date of birth',
      nationality: 'Enter your nationality',
      nationalInsuranceNumber: 'Enter your National Insurance number',
      occupation: 'Enter your occupation',
      phoneNumber: 'Enter your phone number',
    };

    if (this.props.clientDetails) {
      this.state = {
        client: { ...this.props.clientDetails.client },
        errors: {},
        errorMessages: errorMessages,
        dateErrors: {},
      };
    } else {
      this.state = {
        client: {
          annualIncome: '',
          dateOfBirth: {
            day: '',
            month: '',
            year: '',
          },
          nationality: '',
          occupation: '',
          phoneNumber: '',
          nationalInsuranceNumber: '',
        },
        errors: {},
        errorMessages: errorMessages,
        dateErrors: {},
      };
    }
  }

  isBuying = transactionType => {
    return (
      transactionType === 'Buying' || transactionType === 'BuyingAndSelling'
    );
  };

  handleValidation() {
    const fields = { ...this.state.client };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (!fields['annualIncome']) {
      errors['annualIncome'] = true;
      formIsValid = false;
    }

    if (
      fields['annualIncome'] !== '' &&
      !this.isMoneyValid(fields['annualIncome'])
    ) {
      errors['annualIncome'] = true;
      errorMessages['annualIncome'] = 'Please enter a valid amount';
      formIsValid = false;
    }

    if (
      !fields['dateOfBirth'].day ||
      !fields['dateOfBirth'].month ||
      !fields['dateOfBirth'].year
    ) {
      errors['dateOfBirth'] = true;
      formIsValid = false;
    }

    if (!fields['nationality']) {
      errors['nationality'] = true;
      formIsValid = false;
    }

    if (!fields['occupation']) {
      errors['occupation'] = true;
      formIsValid = false;
    }

    if (!fields['phoneNumber']) {
      errors['phoneNumber'] = true;
      formIsValid = false;
    }

    if (this.isBuying(this.props.transactionType)) {
      if (!fields['nationalInsuranceNumber']) {
        errors['nationalInsuranceNumber'] = true;
        formIsValid = false;
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state.client[field]) {
        errors[field] = errorMessages[field];
      }
    }
    this.setState({ errors });
  };

  handleDateFieldValidation = field => datePart => value => {
    let dateErrors = { ...this.state.dateErrors };
    let errors = { ...this.state.errors };
    let dateField = dateErrors[field] || {};

    if (datePart === "day") {
      dateField["day"] = !isDayValid(value)
    }

    if (datePart === "month") {
      dateField["month"] = !isMonthValid(value)
    }

    if (datePart === "year") {
      dateField["year"] = !isYearValid(value)
    }

    dateErrors[field] = dateField

    const allValues = Object.values(dateErrors[field]);
    const dateFieldIsValid = allValues.every(item => item === false);
    errors[field] = !dateFieldIsValid;

    this.setState({ errors, dateErrors });
  };

  normaliseMoney = m => {
    const stripCharsRegex = /[£, ]/g;
    return m.replace(stripCharsRegex, '');
  };

  isMoneyValid = money => {
    const allowedCharsRegex = /^[0-9£,. ]+$/;
    if (allowedCharsRegex.test(String(money))) {
      const normalised = this.normaliseMoney(money);

      const decimalPlaceRegex = /^\d+\.?\d{0,2}$/;

      return decimalPlaceRegex.test(String(normalised));
    }
    return false;
  };

  handleMoneyValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.isMoneyValid(this.state.client[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid amount';
      }
      if (!this.state.client[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleInputChange = field => event => {
    let client = { ...this.state.client };
    client[field] = event;
    this.setState({ client });
  };

  handleDateChange = field => event => {
    let dateOfBirth = { ...this.state.client.dateOfBirth };
    dateOfBirth[field] = event.target.value;
    const updatedClient = { ...this.state.client, dateOfBirth: dateOfBirth };
    this.setState({ client: updatedClient });
  };

  saveClientDetails = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        clientDetails: {
          client: this.state.client,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Your details" removeTopPadding>
          <p>
            When we start working on your case we'll need to do some identity
            checks. These details are essential for us to move quickly – the
            wrong information could hold things up.
          </p>

          <DateOfBirthQuestion
            value={this.state.client.dateOfBirth}
            onChange={this.handleDateChange}
            validate={this.handleDateFieldValidation('dateOfBirth')}
            validationError={
              this.state.errors.dateOfBirth
                ? this.state.errorMessages.dateOfBirth
                : null
            }
          />

          <TextQuestion
            question="Mobile number"
            value={this.state.client.phoneNumber || ''}
            valueEntered={this.handleInputChange('phoneNumber')}
            validate={this.handleFieldValidation('phoneNumber')}
            validationError={
              this.state.errors.phoneNumber
                ? this.state.errorMessages.phoneNumber
                : null
            }
          />

          <TextQuestion
            question="Nationality"
            value={this.state.client.nationality || ''}
            valueEntered={this.handleInputChange('nationality')}
            validate={this.handleFieldValidation('nationality')}
            validationError={
              this.state.errors.nationality
                ? this.state.errorMessages.nationality
                : null
            }
          />

          <TextQuestion
            question="Your job"
            value={this.state.client.occupation || ''}
            valueEntered={this.handleInputChange('occupation')}
            validate={this.handleFieldValidation('occupation')}
            validationError={
              this.state.errors.occupation
                ? this.state.errorMessages.occupation
                : null
            }
          />

          <TextQuestion
            question="Annual income"
            hint="Enter the full amount before tax, including any bonuses"
            value={this.state.client.annualIncome || ''}
            valueEntered={this.handleInputChange('annualIncome')}
            validate={this.handleMoneyValidation('annualIncome')}
            validationError={
              this.state.errors.annualIncome
                ? this.state.errorMessages.annualIncome
                : null
            }
          />

          {this.isBuying(this.props.transactionType) && (
            <TextQuestion
              question="National Insurance number"
              value={this.state.client.nationalInsuranceNumber || ''}
              valueEntered={this.handleInputChange('nationalInsuranceNumber')}
              validate={this.handleFieldValidation('nationalInsuranceNumber')}
              validationError={
                this.state.errors.nationalInsuranceNumber
                  ? this.state.errorMessages.nationalInsuranceNumber
                  : null
              }
            />
          )}

          <Action onClick={this.saveClientDetails}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default ClientDetails;
