import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import PageLayout from '../../components/PageLayout';
import StepFlowNavigate from '../../components/StepFlowNavigate';

import BrokerQuoteTransactionType from "./BrokerQuoteTransactionType";
import BrokerQuoteSellingProperty from './BrokerQuoteSellingProperty';
import BrokerQuoteBuyingProperty from './BrokerQuoteBuyingProperty';
import BrokerQuoteRemortgagingProperty from "./BrokerQuoteRemortgagingProperty";
import BrokerQuoteSorryPage from "./BrokerQuoteSorryPage";
import BrokerQuoteClientDetails from "./BrokerQuoteClientDetails";
import BrokerQuoteAnythingElse from './BrokerQuoteAnythingElse';
import BrokerQuoteDetailsPage from "./BrokerQuoteDetailsPage";
import BrokerQuoteInstructLinkSent from "./BrokerQuoteInstructLinkSent";
import BrokerQuoteSent from "./BrokerQuoteSent";
import BrokerQuoteSubmission from "./BrokerQuoteSubmission";
import { BuyingMortgageLender, RemortgagingMortgageLender } from "../QuoteCommon/MortgageLender";
import QuoteBuyingPropertyMortgageAmount from "../QuoteCommon/QuoteBuyingPropertyMortgageAmount";
import { INSURANCE_LIMIT } from './../../constants';
import { selectGAClientId } from '../../features/analytics/analyticsSlice';


const transactionTypeOptions = [
  { title: 'Buying a property', value: 'Buying' },
  { title: 'Buying and selling a property', value: 'BuyingAndSelling' },
  //{ title: 'Remortgaging a buy-to-let property', value: 'Remortgaging' }
];

const BrokerQuoteFlow = (props) => {

  const location = useLocation()
  const navigate = useNavigate()

  // FIXME: move this into components that use it once they've been converted
  // to functional components.
  const googleAnalyticsId = useSelector(selectGAClientId);

  const { referralCode } = useParams()
  const [state, setState] = useState({
    loading: !!referralCode,
    error: null,
    referral: null,
    broker: null
  });
  const [flowCompleted, setFlowCompleted] = useState(false);
  const [submittedFlowState, setSubmittedFlowState] = useState(null);

  useEffect(() => {
    if (!flowCompleted && !!referralCode) {
      fetch(`/api/referralCode/${referralCode}?audience=referrer`)
        .then(resp => resp.json())
        .then(
          referralData => {
            const referral = {
              referralCode: referralCode,
              discountAmount: referralData.discountAmount,
              discountMessage: referralData.discountMessage,
              quoteMessage: referralData.quoteMessage,
            };
            const broker = {
              emailAddress: referralData.broker?.emailAddress,
              fullName: referralData.broker?.fullName,
            };
            setState({
              loading: false,
              referral: referral,
              broker: broker
            });
          },
          () => {
            setState({
              loading: false,
            });
          },
        );
    }
  }, [referralCode, flowCompleted]);

  const shouldShowBuyingPropertyDetails = transactionType => {
    return (
      transactionType === 'Buying' || transactionType === 'BuyingAndSelling'
    );
  };

  const shouldShowSellingPropertyDetails = transactionType => {
    return (
      transactionType === 'BuyingAndSelling'
    );
  };

  const shouldShowRemortgageQuoteDetailsForm = transactionType => {
    return transactionType === 'Remortgaging';
  };

  const shouldShowRejectionPage = transactionDetails => {
    let details = { ...transactionDetails };

    if (details.price >= INSURANCE_LIMIT) {
      return true;
    }

    if (details.clientIsCompany) {
      return true;
    }

    return (
      !!details.shouldReject ||
      !!details.newBuild ||
      !!details.divorceOrSeparation ||
      !!details.retirementProperty
    )
  };

  const shouldShowBuyingRejectionPage = (transactionType, transactionDetails) => {
    let details = { ...transactionDetails };

    if (
      (transactionType === 'Buying' || transactionType === 'BuyingAndSelling') &&
      (details.tenure === 'leasehold' || details.tenure === 'leasehold with share of freehold')
    ) {
      return true;
    }

    return shouldShowRejectionPage(details);
  };

  const shouldShowBuyingMortgageLender = transactionDetails => {
    return transactionDetails && !!transactionDetails.buyToLet
  };

  const shouldShowBuyingMortgageAmount = transactionDetails => {
    return transactionDetails && !!transactionDetails.buyToLet && transactionDetails.mortgageLender === 'LendInvest'
  };

  const shouldShowInstructLinkSent = flowState => {
    return flowState.progress === "sendInstructLink"
  }

  const shouldShowQuoteSentToClient = flowState => {
    return flowState.progress === "sendClientQuote"
  }

  if (state.loading) {
    return null;
  }

  if (flowCompleted) {
    return (
      <PageLayout>
        <BrokerQuoteSent
          buyingDetails={submittedFlowState.buyingDetails}
          personalDetails={submittedFlowState.personalDetails}
          quoteDetails={submittedFlowState.quoteDetails}
          referral={submittedFlowState.referral}
          remortgagingDetails={submittedFlowState.remortgagingDetails}
          sellingDetails={submittedFlowState.sellingDetails}
          transactionType={submittedFlowState.transactionType}
          restartFlow={() => {
            setSubmittedFlowState(null);
            setFlowCompleted(false);
            navigate(location.pathname)
          }}
        />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <StepFlowNavigate
        initialFlowState={{
          channel: "mortgage_broker_referrals",
          campaign: null,
          transactionType: null,
          personalDetails: null,
          referral: state.referral,
          broker: state.broker,
          sellingDetails: null,
          buyingDetails: null,
          remortgagingDetails: null,
          additionalInformation: null,
          progress: null,
        }}
        steps={[
          {
            name: 'Intro',
            slug: 'intro',
            component: flowState => (
              <BrokerQuoteTransactionType
                transactionType={flowState.transactionType}
                transactionTypeOptions={transactionTypeOptions}

                referral={flowState.referral}
              />
            ),

          },
          {
            name: 'Selling property details',
            slug: 'sale-details',
            valid: flowState => shouldShowSellingPropertyDetails(flowState.transactionType),
            component: flowState => (
              <BrokerQuoteSellingProperty
                mode="referrer"
                transactionType={flowState.transactionType}
                sellingDetails={flowState.sellingDetails}

                referral={flowState.referral}
              />
            ),
          },
          {
            name: 'Selling sorry page',
            slug: 'sale-sorry',
            valid: flowState => shouldShowRejectionPage(flowState.sellingDetails),
            component: flowState => (
              <BrokerQuoteSorryPage
                transactionType={flowState.transactionType}
                transactionDetails={flowState.sellingDetails}
                rejectQuote="selling"
              />
            ),
          },
          {
            name: 'Buying property details',
            slug: 'purchase-details',
            valid: flowState => shouldShowBuyingPropertyDetails(flowState.transactionType),
            component: flowState => (
              <BrokerQuoteBuyingProperty
                mode="referrer"
                transactionType={flowState.transactionType}
                buyingDetails={flowState.buyingDetails}

                referral={flowState.referral}
              />
            ),
          },
          {
            name: 'Buying sorry page',
            slug: 'purchase-sorry',
            valid: flowState => shouldShowBuyingRejectionPage(flowState.transactionType, flowState.buyingDetails),
            component: flowState => (
              <BrokerQuoteSorryPage
                transactionType={flowState.transactionType}
                transactionDetails={flowState.buyingDetails}
                rejectQuote="buying"
              />
            )
          },
          {
            name: 'Buying mortgage lender',
            slug: 'purchase-mortgage-lender',
            valid: flowState => shouldShowBuyingMortgageLender(
              flowState.buyingDetails
            ),
            component: flowState => (
              <BuyingMortgageLender
                mode="direct"
                buyingDetails={flowState.buyingDetails}
              />
            )
          },
          {
            name: 'Buying mortgage amount',
            slug: 'purchase-mortgage',
            valid: flowState => shouldShowBuyingMortgageAmount(
              flowState.buyingDetails
            ),
            component: flowState => (
              <QuoteBuyingPropertyMortgageAmount
                buyingDetails={flowState.buyingDetails}
              />
            )
          },
          {
            name: 'Remortgaging details',
            slug: 'remortgage-details',
            valid: flowState => shouldShowRemortgageQuoteDetailsForm(flowState.transactionType),
            component: flowState => (
              <BrokerQuoteRemortgagingProperty
                transactionType={flowState.transactionType}
                remortgagingDetails={flowState.remortgagingDetails}

                referral={flowState.referral}
              />
            ),
          },
          {
            name: 'Remortgaging sorry page',
            slug: 'remortgage-sorry',
            valid: flowState => shouldShowRejectionPage(flowState.remortgagingDetails),
            component: flowState => (
              <BrokerQuoteSorryPage
                transactionType={flowState.transactionType}
                transactionDetails={flowState.remortgagingDetails}
                rejectQuote="remortgaging"
              />
            ),
          },
          {
            name: 'Remortgaging mortgage lender',
            slug: 'remortgage-mortgage-lender',
            valid: flowState => shouldShowRemortgageQuoteDetailsForm(
              flowState.transactionType,
            ),
            component: flowState => (
              <RemortgagingMortgageLender
                remortgagingDetails={flowState.remortgagingDetails}
              />
            )
          },
          {
            name: "Broker quote - client details page",
            slug: "client-details",
            component: flowState => (
              <BrokerQuoteClientDetails
                additionalInformation={flowState.additionalInformation}
                personalDetails={flowState.personalDetails}

                referral={flowState.referral}
              />
            ),
          },
          {
            name: 'Broker quote - anything else',
            slug: 'anything-else',
            component: flowState => (
              <BrokerQuoteAnythingElse
                transactionType={flowState.transactionType}
                channel={flowState.channel}
                personalDetails={flowState.personalDetails}
                additionalInformation={flowState.additionalInformation}
                showTermsLink={true}
                buttonText={'Continue'}

                referral={flowState.referral}
              />
            ),
          },
          {
            name: "Broker quote - quote details",
            slug: "quote-details",
            component: flowState => (
              <BrokerQuoteDetailsPage
                transactionType={flowState.transactionType}
                campaign={flowState.campaign}
                channel={flowState.channel}
                googleAnalyticsId={googleAnalyticsId}

                referral={flowState.referral}
                broker={flowState.broker}

                additionalInformation={flowState.additionalInformation}
                personalDetails={flowState.personalDetails}
                sellingDetails={flowState.sellingDetails}
                buyingDetails={flowState.buyingDetails}
                remortgagingDetails={flowState.remortgagingDetails}

                recipient='broker'
              />
            )
          },
          {
            name: "Broker quote - instruct link sent",
            slug: "instruct-link-sent",
            valid: flowState => shouldShowInstructLinkSent(flowState),
            component: flowState => (
              <BrokerQuoteInstructLinkSent
                transactionType={flowState.transactionType}
                campaign={flowState.campaign}
                channel={flowState.channel}
                googleAnalyticsId={googleAnalyticsId}

                referral={flowState.referral}
                broker={flowState.broker}
                progress={flowState.progress}

                additionalInformation={flowState.additionalInformation}
                personalDetails={flowState.personalDetails}
                sellingDetails={flowState.sellingDetails}
                buyingDetails={flowState.buyingDetails}
                remortgagingDetails={flowState.remortgagingDetails}

                recipient='broker'
              />
            ),
          },
          {
            name: "Broker quote - send your client a quote",
            slug: "quote-sent",
            valid: flowState => shouldShowQuoteSentToClient(flowState),
            component: flowState => (
              <BrokerQuoteSubmission
                transactionType={flowState.transactionType}
                campaign={flowState.campaign}
                channel={flowState.channel}
                googleAnalyticsId={googleAnalyticsId}

                referral={flowState.referral}
                broker={flowState.broker}
                progress={flowState.progress}

                additionalInformation={flowState.additionalInformation}
                personalDetails={flowState.personalDetails}
                quoteDetails={flowState.quoteDetails}
                sellingDetails={flowState.sellingDetails}
                buyingDetails={flowState.buyingDetails}
                remortgagingDetails={flowState.remortgagingDetails}
                onFlowComplete={(quoteDetails) => {
                  setSubmittedFlowState({
                    ...flowState,
                    ...quoteDetails
                  });
                  setFlowCompleted(true);
                }}
              />
            ),
          }
        ]}
      />
    </PageLayout>
  );
}

export default BrokerQuoteFlow;
