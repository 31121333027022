import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const StepFlowNavigate = ({ stepParamName = 'step', steps, initialFlowState }) => {
  // Check all steps have a slug
  steps.forEach(step => {
    if (!step.slug) {
      throw new Error('Step does not have a slug');
    }
  });
  // Check all slugs are unique
  const slugs = steps.map(step => step.slug);
  const uniqueSlugs = [...new Set(slugs)];
  if (slugs.length !== uniqueSlugs.length) {
    const duplicateSlugs = slugs.filter(
      (item, index) => slugs.indexOf(item) !== index,
    );
    throw new Error(`Steps do not have unique slugs - found duplicates: ${duplicateSlugs}`);
  }

  const [flowState, setFlowState] = useState(initialFlowState);

  const isStepValid = (step, nextState) => {
    if (step && step.valid) {
      return step.valid(nextState);
    }
    return true;
  };

  const initialStepIndex = steps.findIndex(step =>
    isStepValid(step, initialFlowState),
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const stepSlug = searchParams.get(stepParamName);
  const currentStepIndex = steps.findIndex(step => step.slug === stepSlug);

  const navigateToStep = (step, options = {}) => {
    setSearchParams(params => {
      params.set(stepParamName, step.slug);
      return params
    }, options);
  };

  // Navigate to the first step on load
  useEffect(() => {
    navigateToStep(steps[initialStepIndex], { replace: true });
  }, []);

  const onStepComplete = stateDelta => {
    setFlowState(currentState => ({ ...currentState, ...stateDelta }));

    const nextFlowState = { ...flowState, ...stateDelta };
    let candidateStepIndex = currentStepIndex + 1;
    while (candidateStepIndex < steps.length) {
      let candidateStep = steps[candidateStepIndex];
      if (isStepValid(candidateStep, nextFlowState)) {
        navigateToStep(candidateStep);
        return;
      }
      candidateStepIndex++;
    }
    console.warn('onStepComplete: reached end of step list, aborting');
  };

  const showFirst = partialflowState => {
    setFlowState({
      ...initialFlowState,
      ...partialflowState,
    });
    navigateToStep(steps[initialStepIndex]);
  };

  const showPrevious = () => {
    let candidateStepIndex = currentStepIndex - 1;

    while (candidateStepIndex >= 0) {
      let candidateStep = steps[candidateStepIndex];
      if (isStepValid(candidateStep, flowState)) {
        navigateToStep(candidateStep);
        return;
      }
      candidateStepIndex--;
    }
    console.warn('showPrevious: reached start of step list, aborting');
  };

  const showIndex = index => {
    const step = steps[index];
    if (!step || !isStepValid(step, flowState)) {
      console.warn('showIndex called with invalid step index');
      return;
    }
    navigateToStep(step);
  };

  if (currentStepIndex < 0) {
    // No stepSlug yet, or an invalid stepSlug - render nothing, and let the
    // initial effect hook handle redirecting to the first step.
    return null;
  }

  const activeStep = steps[currentStepIndex];

  const stepComponent = activeStep.component(flowState);
  const renderComponent = React.cloneElement(stepComponent, {
    onStepComplete,
    showPrevious,
    showFirst,
    showIndex,
  });

  return renderComponent;
};

export default StepFlowNavigate;
