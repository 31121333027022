import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ReferralCodeBanner from '../../components/ReferralCodeBanner';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import List, { ListItem } from '../../components/List';

class BrokerQuoteTransactionType extends Component {
  constructor(props) {
    super(props);

    if (this.props.selectedTransactionType) {
      this.state = {
        selectedTransactionType: this.props.selectedTransactionType,
        sellingDetails: null,
        errors: {},
      };
    } else {
      this.state = {
        selectedTransactionType: null,
        errors: {},
      };
    }
  }

  selectTransactionType = t => {
    this.setState({ selectedTransactionType: t });
  };

  handleValidation = () => {
    const fields = { ...this.state };
    let errors = {};
    let formIsValid = true;

    if (fields['selectedTransactionType'] == null) {
      errors['selectedTransactionType'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  };

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  saveSelection = () => {
    if (this.handleValidation()) {
      this.props.onStepComplete({
        transactionType: this.state.selectedTransactionType,
        sellingDetails: this.state.sellingDetails,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />

        {!!this.props.referral &&
          <ReferralCodeBanner text={this.props.referral.discountMessage} />
        }

        <Card
          title="Simpler, clearer and faster conveyancing"
          removeTopPadding
          headingSize="1"
        >
          <p>Refer your clients to Juno and get:</p>
          <List bullet>
            <ListItem>
              A real-time dashboard, showing where every case is at
            </ListItem>
            <ListItem>
              Faster exchanges – four to six weeks quicker than the national average
            </ListItem>
            <ListItem>
              Proactive, friendly legal team, keeping your clients moving
            </ListItem>
          </List>

          <h3>Instruct your client or get a quote in seconds</h3>
          <p>
            Answer a few simple questions about your clients for a quote that covers everything – including bank transfers, ID checks and searches.
          </p>

        </Card>

        <Card title="Refer a client to Juno" removeTopPadding>
          <RadioQuestion
            question="Is your client:"
            options={this.props.transactionTypeOptions}
            name="quoteType"
            selected={this.state.selectedTransactionType}
            optionSelected={this.selectTransactionType}
            validate={this.handleFieldValidation('selectedTransactionType')}
            validationError={
              this.state.errors['selectedTransactionType']
                ? 'Please choose an option'
                : null
            }
          />

          <Action onClick={this.saveSelection}>Continue</Action>
        </Card>

        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default BrokerQuoteTransactionType;
