/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styled from 'styled-components/macro';
import List, { ListItem } from './List';
import Text from './Text';

const JunoTermsVersion = styled.p`
  margin-top: 0;
  font-size: 1rem;
  color: ${props => props.theme.color.junoTextSecondary};
`;

function JunoReferrerTermsAndConditions() {
  return (
    <React.Fragment>
      <JunoTermsVersion>Version 1.0</JunoTermsVersion>
      <Text>
        <p>These terms apply when you refer someone to Juno.</p>

        <h3>Before you start</h3>
        <p>
          So we can pay you a referral fee for transactions you refer to us:
        </p>
        <List number as="ol">
          <ListItem>
            We will confirm, separately and in writing, the referral fee amount
            per transaction agreed with you
          </ListItem>
          <ListItem>
            We will provide you with a referral link (URL) for you to refer
            transactions to us. This link will be unique to your branch
          </ListItem>
          <ListItem>
            You need to provide us with your VAT number, bank details for
            payment of your referral fee and sales commission, and email
            addresses for your accounts team and branch manager
          </ListItem>
        </List>

        <h3>Referring a transaction to us</h3>
        <p>
          When you refer a transaction to us, you must use your unique referral
          link. We’ll only pay a referral fee on transactions referred using
          your unique referral link.
        </p>
        <p>
          If the customer that you refer to us has signed up with Juno in the
          previous 12 months, no referral fee will be payable.
        </p>
        <p>
          When you refer a transaction to us, we’ll also collect the name of the
          person making the referral. We will:
        </p>
        <List bullet>
          <ListItem>
            let you know if and when the person you referred signs up as a Juno
            customer
          </ListItem>
          <ListItem>
            let you know if and when each transaction you referred to us reaches
            exchange and we have paid your referral fee, and the name of the
            person in your branch who made the referral to us
          </ListItem>
        </List>
        <p>
          If you refer a transaction to us more than once, this will count as
          one referral. If the person you referred is already signed up with
          Juno for this transaction, you won’t be eligible for a referral fee.
        </p>
        <p>
          We have the right to refuse your referrals at our sole discretion.
          It’s up to us whether we contact your referrals. We are not required
          to give you notice if we wish to stop accepting referrals from you.
        </p>
        <p>
          Occasionally we may find that we’re unable to work on one of your
          referred transactions. If this happens we will let you know, but we
          may not be able to tell you the reason (for example, if a client is
          suspected of money laundering).
        </p>

        <h3>Referral fee</h3>
        <p>
          Your referral fee is payable once we’ve exchanged contracts on the
          transaction you referred. If we don’t exchange contracts on a
          transaction, no referral fee is payable.
        </p>
        <p>
          Juno Global Services Limited will pay your referral fee on behalf of
          Juno Property Lawyers Limited. Juno Global Services Limited provides
          marketing services to Juno Property Lawyers Limited, and is part of
          the same corporate group.
        </p>
        <p>Juno Global Services Limited will make this payment to you:</p>
        <List bullet>
          <ListItem>on the day of exchange</ListItem>
          <ListItem>
            to the bank account details we have on file for you
          </ListItem>
        </List>
        <p>
          You’ll need to send Juno Global Services Limited an invoice for the
          referral fee. You can choose to send us an invoice on a case-by-case
          basis, or monthly. Please send the invoice to{' '}
          <a href="mailto:finance@evenlydistributed.xyz" target="_blank">
            finance@evenlydistributed.xyz
          </a>
          , addressed to Juno Global Services Limited, 43–51 New North Road,
          London N1 6LU.
        </p>

        <h3>Paying sales commission promptly</h3>
        <p>
          We typically pay sales commission to estate agents on the day of
          completion.
        </p>
        <p>
          For us to pay your sales commission on the day of completion we’ll
          need you to send your client’s invoice to us a few days in advance.
          We’ll send it to your client, and they’ll need to approve the payment.
        </p>
        <p>
          We will only pay your sales commission to the bank account details we
          have on file for you.
        </p>
        <p>
          If our client asks us to transfer your sales commission amount to
          them, we’re required to do so. Your client remains ultimately
          responsible for paying your commission.
        </p>

        <h3>Personal information</h3>
        <p>
          Before you refer someone to Juno, you must get their consent to share
          their personal information with us.
        </p>
        <p>You agree that the consent you get will:</p>

        <List bullet>
          <ListItem>
            allow us to contact them by phone and email in order to promote our
            services
          </ListItem>
          <ListItem>
            cover only the personal information required to complete our
            referral form
          </ListItem>
          <ListItem>
            be sufficient to comply with your obligations under the GDPR
          </ListItem>
        </List>
        <p>
          We agree that we will only use the personal information you provide to
          us in order to promote our services and not for any other purpose.
        </p>

        <h3>Confidentiality</h3>
        <p>
          You agree not to disclose these terms or the agreed referral fee
          amount with anyone (except the person buying or selling the property
          on whose behalf we are acting and who you referred to us).
        </p>
        <p>
          If you do disclose these terms or the agreed referral fee with anyone
          else then you will waive the right to receive any outstanding referral
          fees from us.
        </p>

        <h3>Reputation</h3>
        <p>
          You agree not to do or say things to any third party which might
          damage the reputation of Juno.
        </p>
        <p>
          Should any dispute arise in relation to these terms you agree to enter
          into private mediation in good faith.
        </p>

        <h3>Words we use in these terms</h3>
        <p>
          In these terms, the following words and phrases have the following
          meanings:
        </p>

        <List bullet>
          <ListItem>
            ‘estate agent’ or ‘you’ means the company you work for, and ‘your’
            means in relation to the company you work for
          </ListItem>
          <ListItem>
            ‘Juno’, ‘we’ or ‘us’ means Juno Property Lawyers Limited, and ‘our’
            means in relation to Juno Property Lawyers Limited
          </ListItem>
          <ListItem>
            ‘terms’ or ‘agreement’ means these terms and conditions
          </ListItem>
          <ListItem>‘transaction’ means a property sale or purchase</ListItem>
          <ListItem>
            ‘refer’, ‘referral’ or ‘referred transaction’ means when you refer a
            specific transaction to us
          </ListItem>
          <ListItem>
            ‘exchange’ means the point when we successfully exchange contracts
            on a referred transaction, in line with standard conveyancing
            practice
          </ListItem>
          <ListItem>
            ‘complete’ or ‘completion’ means the point when we successfully
            complete on a referred transaction, in line with standard
            conveyancing practice
          </ListItem>
          <ListItem>
            ‘GDPR’ means the General Data Protection Regulation (GDPR) as it
            applies in the UK, tailored by the Data Protection Act 2018
          </ListItem>
        </List>

        <h3>And finally…</h3>
        <p>
          We’re authorised by the Council of Licensed Conveyancers (CLC), and
          we’re required to follow the CLC Codes, as set out in the{' '}
          <a
            href="https://www.clc-uk.org/handbook/the-handbook/"
            target="_blank"
          >
            CLC Handbook
          </a>
          . The CLC Codes take precedence over these terms.
        </p>
        <p>
          If we have to make a change to these terms, we’ll tell you in writing.
        </p>
        <p>
          If in future we make any changes to the way that our company and wider
          corporate group is organised, we can transfer this agreement and your
          referrals to another company under our control. If this happens then
          these terms will continue to apply.
        </p>
        <p>
          This agreement is governed by the laws of England and Wales and is
          subject to the exclusive jurisdiction of the courts of England and
          Wales.
        </p>
      </Text>
    </React.Fragment>
  );
}

export default JunoReferrerTermsAndConditions;
