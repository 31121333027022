import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Action from '../../components/Action';

class SourceOfFundsIntro extends Component {

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="How will you be paying for your new home?" removeTopPadding>
          <p>
            We need to ask you a few questions about where the money to pay for your property has come from. All law firms are required to do this.
          </p>

          <p>
            We need to know the details of any accounts you - and anyone you are purchasing the property with - will use to contribute money towards the purchase. It’ll help to find those before you start.
          </p>

          <p>
            Once you have completed the form, our client money team will be in touch to let you know which documents they need.
          </p>
          <Action onClick={() => this.props.onStepComplete({})}>
            Let’s get started
          </Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default SourceOfFundsIntro;
