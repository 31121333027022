import styled from "styled-components/macro";
import { mediaQueries } from '../styles/media.js';

const TileGrid = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;

  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  justify-items: start;
  display: grid;

  ${mediaQueries.md} {
    margin-left: 0;
    margin-right: 0;

    grid-template-columns: 1fr 1fr;
  }
`

export default TileGrid;
