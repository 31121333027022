import styled, { css } from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const DiscountBanner = styled.div`
  display: flex;
  padding: 1em;
  font-weight: bold;
  width: auto;
  margin-bottom: 0.5em;
  ${mediaQueries.md} {
    margin-bottom: 1em;
  }

  svg {
    margin-right: 0.5em;
  }

  ${props =>
    props.valid &&
    css`
      color: ${props => props.theme.color.darkBlue};
      background: ${props => props.theme.color.veryPaleBlue};
    `};

  ${props =>
    props.invalid &&
    css`
      color: ${props => props.theme.color.darkGrey};
      background: ${props => props.theme.color.offWhite};
    `};
`;

export default DiscountBanner;
