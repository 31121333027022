import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { isMoneyValid, normaliseMoney } from '../../utils/moneyUtils';

class Trust extends Component {
  constructor(props) {
    super(props);

    if (this.props.trust) {
      const trust = { ...this.props.trust };
      this.state = {
        trustAmount: trust.trustAmount,
        errors: {},
      };
    } else {
      this.state = {
        trustAmount: '',
        errors: {},
        errorMessages: {}
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (!fields['trustAmount']) {
      errors['trustAmount'] = true;
      errorMessages['trustAmount'] =
        'Please fill this in';
      formIsValid = false;
    }

    if (
      fields['trustAmount'] !== '' &&
      !isMoneyValid(fields['trustAmount'])
    ) {
      errors['trustAmount'] = true;
      errorMessages['trustAmount'] =
        'Please enter a valid amount';
      formIsValid = false;
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handleMoneyValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isMoneyValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid amount';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  saveTrust = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        trust: {
          trustAmount: normaliseMoney(
            this.state.trustAmount,
          ),
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about the money from a Trust" removeTopPadding>
          <p>
            We need to know how much money you’re contributing towards the purchase from the Trust.
          </p>

          <TextQuestion
            question="How much are you using towards the purchase?"
            value={this.state.trustAmount}
            valueEntered={this.handleInputChange('trustAmount')}
            validate={this.handleMoneyValidation('trustAmount')}
            validationError={
              this.state.errors.trustAmount
                ? this.state.errorMessages.trustAmount
                : null
            }
          />

          <p>
            We’ll ask you to provide the name and contact details for the trustee.
          </p>

          <Action onClick={this.saveTrust}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default Trust;
