import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const JunoIconList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  max-width: 100% !important;

  display: grid;
  grid-template-columns: 1fr;
  ${mediaQueries.lg} {
    grid-template-columns: repeat(${props => props.cols}, 1fr);
  }

  color: ${props => props.theme.color.junoText};
  font-family: ${props => props.theme.junoFont};
  font-weight: ${props => props.theme.fontWeight.light};
  line-height: 1.5;

  ${mediaQueries.md} {
    margin-bottom: 1.5rem;
    line-height: 1.75;
  }

  margin-top: 0;
  margin-bottom: 1rem;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  border-bottom: 1px solid ${props => props.theme.color.junoBorder};
`;

const FullWidthItem = styled(ListItem)`
  grid-column: 1 / -1;
`;


export function IconList({ cols, children, ...rest}) {
  return <JunoIconList cols={cols} {...rest}>{children}</JunoIconList>;
}

IconList.propTypes = {
  cols: PropTypes.oneOf([1, 2]),
};

IconList.defaultProps = {
  cols: 1,
};

export function IconListItem(props) {
  if (props.fullWidth) {
    return <FullWidthItem {...props}>{props.children}</FullWidthItem>;
  }
  return <ListItem {...props}>{props.children}</ListItem>;
}
