import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';
import DashboardNav from '../pages/ClientDashboard/DashboardNav.js';

const JunoPageHeader = styled.header`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  background-color: ${props => props.theme.color.junoBrand};
  color: ${props => props.theme.color.junoHeader};

  padding-bottom: 1.5em;
  margin-bottom: 1em;
  ${mediaQueries.md} {
    margin-bottom: 2em;
    margin-top: -40px;
  }
`;

const JunoPageHeaderInner = styled.div`
  margin-left: 20px;
  margin-right: 20px;

  ${mediaQueries.md} {
    margin-left: 45px;
    margin-right: 45px;
  }

  ${mediaQueries.lg} {
    width: 930px;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    font-size: 20px;
    line-height: 1.25;
    padding-top: 0.67em;
    ${mediaQueries.md} {
      font-size: 24px;
      line-height: 1.5;
    }
    color: white;
    margin-top: 0;
    margin-bottom: 0;

    span {
      font-weight: ${props => props.theme.fontWeight.regular};
    }
  }

`;

class PageHeader extends Component {
  render() {
    return (
      <JunoPageHeader className={this.props.className}>
        <JunoPageHeaderInner>
          {this.props.hideDashboardNav ? null : < DashboardNav />}
          <h1>{this.props.children}</h1>
        </JunoPageHeaderInner>
      </JunoPageHeader>
    );
  }
}

export default PageHeader;

export function PageContent(props) {
  return (
    <JunoPageHeaderInner>
      {props.children}
    </JunoPageHeaderInner>
  );
}
