import React from 'react';
import StyledTodoListItem, {TodoText} from './StyledTodoListItem'

function ListEnquiriesWaitingForThirdParty(props) {

  if (props.numberOfEnquiriesWaitingForThirdParty === 0) {
    return null
  }

  return (
    <StyledTodoListItem clickable={false} complete={false}>
      <TodoText>
        We're {props.numberOfEnquiriesWaitingForClient > 0 && 'also'} waiting for a reply to {props.numberOfEnquiriesWaitingForThirdParty} {props.numberOfEnquiriesWaitingForThirdParty > 1 ? 'questions' : 'question'} from your management company, lender or another third party
      </TodoText>
    </StyledTodoListItem>
  )
}

export default ListEnquiriesWaitingForThirdParty;
