import React, {Component} from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import {trackRejectionPageView} from '../../utils/analyticsUtils';
import {BUYING_REJECTION_OPTIONS, INSURANCE_LIMIT, SELLING_REJECTION_OPTIONS,} from '../../constants';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import {CTAButton} from "../../components/Button";

class QuoteSorryPage extends Component {
  componentDidMount() {
    const transactionType = this.props.transactionType?.toLowerCase() || '';
    const pageName = `quote_${transactionType}_rejection`;

    trackRejectionPageView("quote", pageName, this.buildAnalyticsRejectionList());
  }

  shouldShowPriceMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.price >= INSURANCE_LIMIT;
  };

  shouldShowCannotActMessage = () => {
    const { transactionDetails } = this.props;
    return (transactionDetails?.shouldReject && !this.shouldShowPriceMessage());
  };

  shouldShowCompanyBTLMessage = () => {
    const { transactionDetails } = this.props;
    return !!transactionDetails?.clientIsCompany && !!transactionDetails?.buyToLet;
  };

  shouldShowCompanyMessage = () => {
    const { transactionDetails } = this.props;
    return !!transactionDetails?.clientIsCompany
  };

  shouldShowRightToBuyMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.rightToBuyScheme && !this.shouldShowPriceMessage();
  };

  shouldShowSharedOwnershipMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.sharedOwnership && !this.shouldShowPriceMessage();
  };

  shouldShowNewBuildMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.newBuild && !this.shouldShowPriceMessage();
  };

  shouldShowDivorceMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.divorceOrSeparation && !this.shouldShowPriceMessage();
  };

  shouldShowRetirementPropertyMessage = () => {
    const { transactionDetails } = this.props;
    return transactionDetails?.retirementProperty && !this.shouldShowPriceMessage();
  };

  shouldShowBuyingLeaseholdMessage = () => {
    const { transactionDetails, transactionType } = this.props;
    return (
      (transactionType === 'Buying' || transactionType === 'BuyingAndSelling') &&
      (transactionDetails?.tenure === 'leasehold' || transactionDetails?.tenure === 'leasehold with share of freehold') &&
      !this.shouldShowPriceMessage()
    );
  };

  buildAnalyticsRejectionList = () => {
    let reasons = []
    if (this.shouldShowPriceMessage()){ reasons.push("price_too_high")}
    if (this.shouldShowCannotActMessage()){ reasons.push("cannot_act")}
    if (this.shouldShowRightToBuyMessage()){ reasons.push("right_to_buy")}
    if (this.shouldShowSharedOwnershipMessage()){ reasons.push("shared_ownwership")}
    if (this.shouldShowNewBuildMessage()){ reasons.push("new_build")}
    if (this.shouldShowDivorceMessage()) { reasons.push("divorce_or_separation")}
    if (this.shouldShowRetirementPropertyMessage()) { reasons.push("retirement_property") }
    if (this.shouldShowBuyingLeaseholdMessage()) { reasons.push("buying_leasehold") }

    return reasons.join(", ")
  }

  displayRejectionReason = () => {
    let rejectionReason = [];

    if (this.props.rejectQuote === 'buying') {
      for (let i = 0; i < BUYING_REJECTION_OPTIONS.length; i++) {
        rejectionReason.push(BUYING_REJECTION_OPTIONS[i].value);
      }
    }

    if (this.props.rejectQuote === 'selling') {
      for (let i = 0; i < SELLING_REJECTION_OPTIONS.length; i++) {
        rejectionReason.push(SELLING_REJECTION_OPTIONS[i].value);
      }
    }

    return (rejectionReason = rejectionReason
      .join(', ')
      .replace(/, ([^,]*)$/, ' or $1'));
  };

  myHomeMoveRejectReason = () => {
    if (this.shouldShowDivorceMessage()) {
      return 'divorcing';
    }

    if (this.shouldShowNewBuildMessage()) {
      return 'buying new build';
    }

    if (this.shouldShowCannotActMessage()) {
      return this.props.rejectQuote + " " +this.displayRejectionReason();
    }

    if (this.shouldShowCompanyMessage()) {
      return 'companies';
    }

    if (this.shouldShowRightToBuyMessage()) {
      return 'using the right to buy scheme';
    }

    if (this.shouldShowSharedOwnershipMessage()) {
      return 'buying Shared Ownership properties';
    }

    if (this.shouldShowRetirementPropertyMessage()) {
      return 'buying or selling retirement properties';
    }

    if (this.shouldShowBuyingLeaseholdMessage()) {
      return 'buying leasehold properties';
    }

    return null;
  }

  myHomeMoveUrl = () => {
    if (this.props.transactionType === 'BuyingAndSelling') {
      return 'https://myhomemoveconveyancing.co.uk/instant-quote/quote-stage-one/?type=buying-and-selling&lead_source=juno';
    }

    if (this.props.transactionType === 'Buying') {
      return 'https://myhomemoveconveyancing.co.uk/instant-quote/quote-stage-one/?type=buying&lead_source=juno';
    }

    if (this.props.transactionType === 'Selling') {
      return 'https://myhomemoveconveyancing.co.uk/instant-quote/quote-stage-one/?type=selling&lead_source=juno';
    }

    return null;
  }

  displayMyHomeMoveLink = () => {

    if (this.myHomeMoveRejectReason() == null || this.props.referral != null) {
      return null;
    }
    return (
      <>
        <p>
          However, we are working in partnership with My Home Move Conveyancing, who are taking on clients who
          are {this.myHomeMoveRejectReason()} and can match you with a specialist firm that best suits your needs.
          You can get your bespoke conveyancing quote here:
        </p>
        <a href={this.myHomeMoveUrl()} target="_blank">
          <CTAButton>Get your quote here</CTAButton>
        </a>
      </>
    )
  }

  displayRejectionMessage = () => {
    if (this.shouldShowDivorceMessage()) {
      return (
        <React.Fragment>
          <p>
            We don’t currently act for separating couples selling a property together, because we often receive
            conflicting instructions which mean we have to stop acting mid-way through the sale. In our experience it’s
            better to get a divorce lawyer involved and use their in-house conveyancing specialists for the sale of
            property, who are better equipped to handle situations like this.
          </p>
          <p>
            We recognise this is a difficult time for everyone involved, and we’re sorry not to be able to help you
            resolve the situation and move on to the next phase of your life. We wish you all the best for the future.
          </p>
        </React.Fragment>
      )
    }

    if (this.shouldShowPriceMessage()) {
      return (
        <React.Fragment>
          <p>
            As a regulated conveyancing firm, we’re insured to work on
            transactions up to a value of £{INSURANCE_LIMIT / 1000000} million. We can’t take on any
            cases that have a higher value – sorry about that.
          </p>
          <p>
            We’re continuing to expand the range of services we offer, so
            please do consider us in the future.
          </p>
        </React.Fragment>
      )
    }

    if (this.shouldShowNewBuildMessage()) {
      return (
        <React.Fragment>
          <p>
            At the moment, we aren’t taking on any more clients who are buying a ‘new build’ or ‘first registration’ property.
          </p>
          <p>
            We’re always expanding the range of transactions we can help with, so please do consider us next time round.
          </p>
        </React.Fragment>
      )
    }

    if (this.shouldShowCannotActMessage()) {
      return (
        <React.Fragment>
          <p>
            At the moment, we aren’t taking on any more clients who are{' '}
            {this.props.rejectQuote} {this.displayRejectionReason()}.
          </p>
          <p>
            We’re always expanding the range of transactions we can help
            with, so please do consider us next time round.
          </p>
        </React.Fragment>
      )
    }

    if (this.shouldShowCompanyMessage()) {
      return (
        <React.Fragment>
          <p>
            At the moment, we're not representing companies who want to buy properties.
          </p>
          <p>
            We’re always expanding the range of transactions we can help
            with, so please do consider us next time round.
          </p>
        </React.Fragment>
      )
    }

    if (this.shouldShowRightToBuyMessage()) {
      return (
        <React.Fragment>
          <p>
            At the moment, we aren’t taking on any more clients who are
            using the right to buy scheme.
          </p>
          <p>
            We’re always expanding the range of transactions we can help
            with, so please do consider us next time round.
          </p>
        </React.Fragment>
      )
    }

    if (this.shouldShowSharedOwnershipMessage()) {
      return (
        <React.Fragment>
          <p>
            At the moment, we aren’t taking on any more clients who are buying a Shared Ownership property.
          </p>
          <p>
            We’re always expanding the range of transactions we can help with, so please do consider us next time round.
          </p>
        </React.Fragment>
      )
    }

    if (this.shouldShowRetirementPropertyMessage()) {
      return (
        <React.Fragment>
          <p>
            At the moment, we aren’t taking on any more clients who are buying or selling a home built specifically for people in later life. (A 'retirement home', sheltered accommodation or in an 'assisted living' development).
          </p>
        </React.Fragment>
      )
    }

    if (this.shouldShowBuyingLeaseholdMessage()) {
      return (
        <React.Fragment>
          <p>
            At the moment, we aren’t taking on any more clients who are buying leasehold, or leasehold with a share of freehold properties.
          </p>
        </React.Fragment>
      )
    }

    // by this point at lease one of the above should have returned
    return null
  }

  render() {

    return (
      <React.Fragment>
        <ScrollToTop/>
        <Card title="Sorry — we can’t take on your case" removeTopPadding>
          {this.shouldShowDivorceMessage() && (
            <p>
              We’re sorry, we’re not able to help with your planned sale.
            </p>
          )}

          {!this.shouldShowDivorceMessage() && (
            <p>
              We’re really sorry, but we can’t take on your case at the moment.
            </p>
          )}

          {this.displayRejectionMessage()}
          {this.displayMyHomeMoveLink()}

        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default QuoteSorryPage;
