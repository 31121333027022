import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import TextQuestion from '../../components/TextQuestion';
import TrustpilotBanner from '../../components/TrustpilotBanner';
import { parseTenure } from '../../utils/queryStringUtils';
import RadioConditional from '../../components/RadioConditional';
import { BUYING_REJECTION_OPTIONS, TENURE_OPTIONS, YES_NO_OPTIONS, } from '../../constants/index';
import QuoteLandingPageCopy from '../Quote/QuoteLandingPageCopy';
import ReferralCodeControl from "./ReferralCodeControl";
import ReferralCodeBanner from '../../components/ReferralCodeBanner';
import { isSequenceReferral } from "../ReferrerQuote/utils/ReferrerQuoteUtils";

class QuoteBuyingProperty extends Component {
  constructor(props) {
    super(props);

    if (this.props.buyingDetails) {
      const bd = this.props.buyingDetails;
      this.state = {
        price: this.formatMoneyWithCurrency(bd.price),
        priceValid: this.isPriceValid(bd.price),
        tenure: bd.tenure,
        firstTimeBuyer: bd.firstTimeBuyer,
        additionalProperty: bd.additionalProperty,
        buyToLet: bd.buyToLet,
        clientIsCompany: bd.clientIsCompany,
        hasMortgage: bd.hasMortgage,
        rightToBuyScheme: bd.rightToBuyScheme,
        sharedOwnership: bd.sharedOwnership,
        newBuild: bd.newBuild,
        retirementProperty: bd.retirementProperty,
        shouldReject: bd.shouldReject,
        referral: this.props.referral,
        showReferralCodeBanner: !this.props.inBranchReferral,
        errors: {},
      };
    } else {
      this.state = {
        price: '',
        priceValid: false,
        tenure: parseTenure(),
        firstTimeBuyer: null,
        additionalProperty: null,
        buyToLet: null,
        clientIsCompany: null,
        hasMortgage: null,
        rightToBuyScheme: null,
        sharedOwnership: null,
        newBuild: null,
        retirementProperty: null,
        shouldReject: null,
        referral: this.props.referral,
        showReferralCodeBanner: !this.props.inBranchReferral,
        errors: {},
      };
    }

    if (this.props.transactionType === 'BuyingAndSelling') {
      this.state.firstTimeBuyer = false;
    }

    if (this.props.discountCode) {
      this.applyDiscount();
    }
  }

  selectTenure = t => {
    this.setState({ tenure: t });
  };

  priceEntered = p => {
    this.setState({ price: p });
  };

  formatMoneyWithCurrency = p => {
    let formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    });

    let moneyWithCurrency = formatter.format(p);

    if (moneyWithCurrency.includes('.00')) {
      let i = moneyWithCurrency.indexOf('.');
      return moneyWithCurrency.substring(0, i);
    }

    return moneyWithCurrency;
  };

  normalisePrice = p => {
    const stripCharsRegex = /[£, ]/g;
    return p.replace(stripCharsRegex, '');
  };

  isPriceValid = price => {
    const allowedCharsRegex = /^[0-9£,. ]+$/;
    if (allowedCharsRegex.test(String(price))) {
      const normalised = this.normalisePrice(price);

      const decimalPlaceRegex = /^\d+\.?\d{0,2}$/;

      return decimalPlaceRegex.test(String(normalised));
    }
    return false;
  };

  selectFirstTimeBuyer = ftb => {
    if (ftb) {
      this.setState({
        firstTimeBuyer: true,
        additionalProperty: false,
      });
    } else {
      this.setState({
        firstTimeBuyer: false,
      });
    }
  };

  selectAdditionalProperty = ap => {
    if (ap) {
      this.setState({
        firstTimeBuyer: false,
        additionalProperty: true,
      });
    } else {
      this.setState({
        additionalProperty: false,
      });
    }
  };

  selectHasMortgage = m => {
    this.setState({ hasMortgage: m });
  };

  selectShouldReject = rb => {
    this.setState({ shouldReject: rb });
  };

  selectNewBuild = b => {
    this.setState({ newBuild: b });
  };

  selectSharedOwnership = b => {
    this.setState({ sharedOwnership: b });
  };

  selectRightToBuyScheme = b => {
    this.setState({ rightToBuyScheme: b });
  };

  selectBuyToLet = b => {
    this.setState({ buyToLet: b });
  };

  selectClientIsCompany = b => {
    this.setState({ clientIsCompany: b });
  };

  selectRetirementProperty = rp => {
    this.setState({ retirementProperty: rp });
  };

  onReferralCodeApplied = referral => {
    this.setState({referral: referral})
  }

  showMortgageQuestion = () => {
    return !!this.props.referral && isSequenceReferral(this.props.referral.referralSource);
  }

  handleValidation = () => {
    const fields = { ...this.state };
    let errors = {};
    let formIsValid = true;

    if (fields['price'] == null || !this.isPriceValid(fields['price'])) {
      errors['price'] = true;
      formIsValid = false;
    }

    if (fields['tenure'] == null) {
      errors['tenure'] = true;
      formIsValid = false;
    }

    // For buying, if not a first time buyer - require additional property
    if (this.props.transactionType === 'Buying') {
      if (fields['firstTimeBuyer'] == null) {
        errors['firstTimeBuyer'] = true;
        formIsValid = false;
      }

      if (fields['firstTimeBuyer'] === false) {
        if (fields['additionalProperty'] == null) {
          errors['additionalProperty'] = true;
          formIsValid = false;
        }
      }
    }

    // For buying and selling, can't be a first time buyer - require additional property
    if (this.props.transactionType === 'BuyingAndSelling') {
      if (fields['additionalProperty'] == null) {
        errors['additionalProperty'] = true;
        formIsValid = false;
      }
    }

    if (this.showMortgageQuestion() && fields['hasMortgage'] == null) {
      errors['hasMortgage'] = true;
      formIsValid = false;
    }

    if (fields['shouldReject'] == null) {
      errors['shouldReject'] = true;
      formIsValid = false;
    }

    if (fields['newBuild'] == null) {
      errors['newBuild'] = true;
      formIsValid = false;
    }

    if (fields['sharedOwnership'] == null) {
      errors['sharedOwnership'] = true;
      formIsValid = false;
    }

    if (fields['rightToBuyScheme'] == null) {
      errors['rightToBuyScheme'] = true;
      formIsValid = false;
    }

    if (fields['buyToLet'] == null) {
      errors['buyToLet'] = true;
      formIsValid = false;
    }

    if (fields['clientIsCompany'] == null) {
      errors['clientIsCompany'] = true;
      formIsValid = false;
    }

    if (fields['retirementProperty'] == null) {
      errors['retirementProperty'] = true;
      formIsValid = false;
    }

    this.setState({
      errors,
    });
    return formIsValid;
  };

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  handlePriceFieldValidation = event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors['price'] = false;
    } else {
      if (!this.state.price || !this.isPriceValid(this.state.price)) {
        errors['price'] = true;
      }
    }
    this.setState({ errors });
  };

  saveSelection = () => {
    if (this.handleValidation()) {
      this.props.onStepComplete({
        buyingDetails: {
          price: this.normalisePrice(this.state.price),
          tenure: this.state.tenure,
          firstTimeBuyer: this.state.firstTimeBuyer,
          additionalProperty: this.state.additionalProperty,
          newBuild: this.state.newBuild,
          buyToLet: this.state.buyToLet,
          clientIsCompany: this.state.clientIsCompany,
          hasMortgage: this.state.hasMortgage,
          sharedOwnership: this.state.sharedOwnership,
          retirementProperty: this.state.retirementProperty,
          rightToBuyScheme: this.state.rightToBuyScheme,
          shouldReject: this.state.shouldReject,
        },
        referral: this.state.referral,
      });
    }
  };

  render() {
    let rejectionReason = [];
    for (let i = 0; i < BUYING_REJECTION_OPTIONS.length; i++) {
      rejectionReason.push(BUYING_REJECTION_OPTIONS[i].value);
    }
    rejectionReason = rejectionReason
      .join(', ')
      .replace(/, ([^,]*)$/, ' or $1');

    let cardTitle;
    let priceHint;
    let rejectionQuestion;
    let youOrThey;
    let youreOrTheyre;
    let buttonText;

    if (this.props.mode === 'direct') {
      cardTitle =
        this.props.transactionType === 'BuyingAndSelling'
          ? "Tell us about the property you’re buying"
          : 'Get your instant quote';

      rejectionQuestion = 'Are you buying ' + rejectionReason + '?';
      priceHint =
        'If you’re not sure just put an estimate – you can change it later';
      youOrThey = 'you';
      youreOrTheyre = 'you’re'
      buttonText = 'Get your quote';

    } else {
      cardTitle =
        this.props.transactionType === 'BuyingAndSelling'
          ? 'Tell us about the property they are buying'
          : 'Tell us about their property';
      rejectionQuestion = 'Are they buying ' + rejectionReason + '?';
      youOrThey = 'they';
      youreOrTheyre = 'they’re'
      buttonText = 'Continue';
    }

    return (
      <React.Fragment>
        <ScrollToTop />

        {this.props.showLandingCopy && (
          <QuoteLandingPageCopy pageWidth={this.props.pageWidth} campaign={this.props.campaign} />
        )}

        <Card
          title={cardTitle}
          removeTopPadding={true}
        >
          <TextQuestion
            question="Purchase price"
            hint={priceHint}
            value={this.state.price}
            valueEntered={this.priceEntered}
            validate={this.handlePriceFieldValidation}
            validationError={
              this.state.errors['price'] ? 'Please enter a valid price' : null
            }
          />

          <RadioQuestion
            question="Is the property:"
            options={TENURE_OPTIONS}
            name="tenure"
            selected={this.state.tenure}
            optionSelected={this.selectTenure}
            validate={this.handleFieldValidation('tenure')}
            validationError={
              this.state.errors['tenure'] ? 'Please choose an option' : null
            }
          />

          {this.props.transactionType === 'Buying' && (
            <React.Fragment>
              <RadioQuestion
                question={'Are ' + youOrThey + ' a first-time buyer?'}
                options={YES_NO_OPTIONS}
                inline={true}
                name="firstTimeBuyer"
                selected={this.state.firstTimeBuyer}
                optionSelected={this.selectFirstTimeBuyer}
                validate={this.handleFieldValidation('firstTimeBuyer')}
                validationError={
                  this.state.errors['firstTimeBuyer']
                    ? 'Choose an option'
                    : null
                }
              />
              {this.state.firstTimeBuyer === false && (
                <RadioConditional>
                  <RadioQuestion
                    question="Will this be an additional property (a second home)?"
                    options={YES_NO_OPTIONS}
                    inline={true}
                    name="additionalProperty"
                    selected={this.state.additionalProperty}
                    optionSelected={this.selectAdditionalProperty}
                    validate={this.handleFieldValidation('additionalProperty')}
                    validationError={
                      this.state.errors['additionalProperty']
                        ? 'Please choose an option'
                        : null
                    }
                  />
                </RadioConditional>
              )}
            </React.Fragment>
          )}

          {this.props.transactionType === 'BuyingAndSelling' && (
            <RadioQuestion
              question="Will this be an additional property (a second home)?"
              options={YES_NO_OPTIONS}
              inline={true}
              name="additionalProperty"
              selected={this.state.additionalProperty}
              optionSelected={this.selectAdditionalProperty}
              validate={this.handleFieldValidation('additionalProperty')}
              validationError={
                this.state.errors['additionalProperty']
                  ? 'Please choose an option'
                  : null
              }
            />
          )}

          {this.showMortgageQuestion() &&
            <RadioQuestion
              question="Are they getting a mortgage?"
              options={YES_NO_OPTIONS}
              inline={true}
              name="hasMortgage"
              selected={this.state.hasMortgage}
              optionSelected={this.selectHasMortgage}
              validate={this.handleFieldValidation('hasMortgage')}
              validationError={
                this.state.errors['hasMortgage']
                  ? 'Please choose an option'
                  : null
              }
            />
        }

          <RadioQuestion
            question={rejectionQuestion}
            options={YES_NO_OPTIONS}
            inline={true}
            name="shouldRejectBuying"
            selected={this.state.shouldReject}
            optionSelected={this.selectShouldReject}
            validate={this.handleFieldValidation('shouldReject')}
            validationError={
              this.state.errors['shouldReject']
                ? 'Please choose an option'
                : null
            }
          />

          <RadioQuestion
            question={'Are ' + youOrThey + ' buying the property as a company?'}
            options={YES_NO_OPTIONS}
            inline={true}
            name="clientIsCompany"
            selected={this.state.clientIsCompany}
            optionSelected={this.selectClientIsCompany}
            validate={this.handleFieldValidation('clientIsCompany')}
            validationError={
              this.state.errors['clientIsCompany']
                ? 'Please choose an option'
                : null
            }
          />

          <RadioQuestion
            question={'Are ' + youOrThey + ' buying a Right to buy property?'}
            options={YES_NO_OPTIONS}
            inline={true}
            name="rightToBuy"
            selected={this.state.rightToBuyScheme}
            optionSelected={this.selectRightToBuyScheme}
            validate={this.handleFieldValidation('rightToBuyScheme')}
            validationError={
              this.state.errors['rightToBuyScheme']
                ? 'Please choose an option'
                : null
            }
          />

          <RadioQuestion
            question={
              'Are ' +
              youOrThey +
              ' buying a property to rent out? (a buy-to-let)'
            }
            options={YES_NO_OPTIONS}
            inline={true}
            name="buyToLet"
            selected={this.state.buyToLet}
            optionSelected={this.selectBuyToLet}
            validate={this.handleFieldValidation('buyToLet')}
            validationError={
              this.state.errors['buyToLet'] ? 'Please choose an option' : null
            }
          />

          <RadioQuestion
            question={
              'Are ' +
              youOrThey +
              ' buying a share of a property from a local authority or a property developer? (sometimes called shared ownership)'
            }
            options={YES_NO_OPTIONS}
            inline={true}
            name="sharedOwnership"
            selected={this.state.sharedOwnership}
            optionSelected={this.selectSharedOwnership}
            validate={this.handleFieldValidation('sharedOwnership')}
            validationError={
              this.state.errors['sharedOwnership']
                ? 'Please choose an option'
                : null
            }
          />

          <RadioQuestion
            question={
              'Are ' +
              youOrThey +
              " buying a ‘new build’ or ‘first registration’ property?"
            }
            hint="A home on a new development or a property recently converted into flats, with no previous owner"
            options={YES_NO_OPTIONS}
            inline={true}
            name="newBuild"
            selected={this.state.newBuild}
            optionSelected={this.selectNewBuild}
            validate={this.handleFieldValidation('newBuild')}
            validationError={
              this.state.errors['newBuild'] ? 'Please choose an option' : null
            }
          />

          <RadioQuestion
            question={'Is the property ' + youreOrTheyre + " buying a home built specifically for people in later life?"}
            hint="A 'retirement home', sheltered accommodation or in an 'assisted living' development"
            options={YES_NO_OPTIONS}
            inline={true}
            name="retirementProperty"
            selected={this.state.retirementProperty}
            optionSelected={this.selectRetirementProperty}
            validate={this.handleFieldValidation('retirementProperty')}
            validationError={
              this.state.errors['retirementProperty']
                ? 'Please choose an option'
                : null
            }
          />

          {!!this.props.referral && this.state.showReferralCodeBanner &&
            <ReferralCodeBanner text={this.props.referral.discountMessage} small={true} />
          }

          {!this.props.referralLocked &&
            <ReferralCodeControl
              audience={this.props.mode === "referrer" ? "referrer" : null}
              referral={this.state.referral}
              onReferralCodeApplied={this.onReferralCodeApplied}
              />
          }

          <Action onClick={this.saveSelection}>{buttonText}</Action>
        </Card>
        <TrustpilotBanner pageWidth={this.props.pageWidth} />
      </React.Fragment>
    );
  }
}

export default QuoteBuyingProperty;
