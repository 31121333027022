const junoFont = [
  "Inter, sans-serif",
];

const fontWeight = {
  light: 400,
  regular: 500,
  bold: 600,
};

const dashboardTheme = {
  bodyBackground: "#f5f5f5", // default grey body (to match page background)
  junoFont,
  fontWeight,
};

export default dashboardTheme;
