import React from 'react';

import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media';
import { CheckCircle, Markunread, RadioButtonUnchecked } from '@styled-icons/material';
import { getFormattedDate } from "../../utils/textUtils";
import DashboardCard from '../../components/DashboardCard';
import { TodoLink, TodoText, TodoDate } from './StyledTodoListItem';

const EnquiriesList = (props) => {
  const enquiryDoables = props.selectedCase.doables.filter(d => d.doable_type === 'enquiry');

  if (enquiryDoables.length === 0) {
    return null;
  }

  const availableEnquiries = enquiryDoables.filter(d =>
    d.status === "available" || d.status === "available_to_read"
  );
  const completeEnquiries = enquiryDoables.filter(d => d.status === "complete");

  const renderEnquiry = (ed) => {
    const isComplete = ed.status === "complete";
    return (
      <StyledEnquiryListItem key={ed.id}
        complete={isComplete}>
        <StyledEnquiryIcon complete={isComplete}>
          {ed.status === "available" && (
            <RadioButtonUnchecked size="24" />
          )}
          {ed.status === "available_to_read" && (
            <Markunread size="24" />
          )}
          {ed.status === "complete" && (
            <CheckCircle size="24" />
          )}
        </StyledEnquiryIcon>
        <TodoLink
          to={`/dashboard/doable/${ed.id}`}>
          <TodoText clickable={true}>
            {ed.title}
          </TodoText>
        </TodoLink>
        <TodoDate complete={isComplete}>
          {getFormattedDate(isComplete ? ed.completed_at : ed.created_at)}
        </TodoDate>
      </StyledEnquiryListItem>
    )
  };

  return (
    <DashboardCard title="Enquiries — questions about your property" size="1" id="enquiries">
      <StyledEnquiryList>
        {availableEnquiries.map(renderEnquiry)}
        {completeEnquiries.map(renderEnquiry)}
      </StyledEnquiryList>
    </DashboardCard>
  );
};

export default EnquiriesList;

const StyledEnquiryList = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding-left: 0;
`;

export const StyledEnquiryIcon = styled.div`
  flex-shrink: 0;
  text-align: left;
  margin-right: 16px;
  color: ${props => props.complete ? props.theme.color.green : props.theme.color.junoLink};
`;

export const StyledEnquiryListItem = styled.li`
  display: flex;
  padding: 10px 0;

  ${mediaQueries.md} {
    align-items: center;
  }

  font-size: 16px;
  color: ${props => props.complete ? props.theme.color.grey : props.theme.color.junoLink};
  border-bottom: 1px solid ${props => props.theme.color.paleGrey};
`;
