import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class HowIsBuyerPaying extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      cashAndSavings: 'Please choose an option',
      mortgage: 'Please choose an option',
      sellingAnotherProperty: 'Please choose an option',
      remortgagingAnotherProperty: 'Please choose an option',
      helpToBuyIsa: 'Please choose an option',
      inheritance: 'Please choose an option',
      lifetimeIsa: 'Please choose an option',
      someoneGivingMeMoney: 'Please choose an option',
      someoneLendingMeMoney: 'Please choose an option',
      bonusFromEmployer: 'Please choose an option',
      trust: 'Please choose an option',
    };

    if (this.props.howIsBuyerPaying) {
      const howIsBuyerPaying = { ...this.props.howIsBuyerPaying };
      this.state = {
        cashAndSavings: howIsBuyerPaying.cashAndSavings,
        mortgage: howIsBuyerPaying.mortgage,
        sellingAnotherProperty: howIsBuyerPaying.sellingAnotherProperty,
        remortgagingAnotherProperty: howIsBuyerPaying.remortgagingAnotherProperty,
        helpToBuyIsa: howIsBuyerPaying.helpToBuyIsa,
        lifetimeIsa: howIsBuyerPaying.lifetimeIsa,
        inheritance: howIsBuyerPaying.inheritance,
        someoneGivingMeMoney: howIsBuyerPaying.someoneGivingMeMoney,
        someoneLendingMeMoney: howIsBuyerPaying.someoneLendingMeMoney,
        bonusFromEmployer: howIsBuyerPaying.bonusFromEmployer,
        trust: howIsBuyerPaying.trust,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        cashAndSavings: null,
        mortgage: null,
        sellingAnotherProperty: null,
        remortgagingAnotherProperty: null,
        helpToBuyIsa: null,
        lifetimeIsa: null,
        inheritance: null,
        someoneGivingMeMoney: null,
        someoneLendingMeMoney: null,
        bonusFromEmployer: null,
        trust: null,
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => value => {
    this.setState({ [field]: value });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (fields['cashAndSavings'] === null) {
      errors['cashAndSavings'] = true;
      formIsValid = false;
    }

    if (fields['mortgage'] === null) {
      errors['mortgage'] = true;
      formIsValid = false;
    }

    if (fields['sellingAnotherProperty'] === null) {
      errors['sellingAnotherProperty'] = true;
      formIsValid = false;
    }

    if (fields['remortgagingAnotherProperty'] === null) {
      errors['remortgagingAnotherProperty'] = true;
      formIsValid = false;
    }

    if (fields['helpToBuyIsa'] === null) {
      errors['helpToBuyIsa'] = true;
      formIsValid = false;
    }

    if (fields['lifetimeIsa'] === null) {
      errors['lifetimeIsa'] = true;
      formIsValid = false;
    }

    if (fields['inheritance'] === null) {
      errors['inheritance'] = true;
      formIsValid = false;
    }

    if (fields['someoneGivingMeMoney'] === null) {
      errors['someoneGivingMeMoney'] = true;
      formIsValid = false;
    }

    if (fields['someoneLendingMeMoney'] === null) {
      errors['someoneLendingMeMoney'] = true;
      formIsValid = false;
    }

    if (fields['bonusFromEmployer'] === null) {
      errors['bonusFromEmployer'] = true;
      formIsValid = false;
    }

    if (fields['trust'] === null) {
      errors['trust'] = true;
      formIsValid = false;
    }

    // You can't answer 'No' to all questions
    if (
      fields['cashAndSavings'] === false &&
      fields['mortgage'] === false &&
      fields['sellingAnotherProperty'] === false &&
      fields['remortgagingAnotherProperty'] === false &&
      fields['helpToBuyIsa'] === false &&
      fields['lifetimeIsa'] === false &&
      fields['inheritance'] === false &&
      fields['someoneGivingMeMoney'] === false &&
      fields['someoneLendingMeMoney'] === false &&
      fields['bonusFromEmployer'] === false &&
      fields['trust'] === false
    ) {
      // Show an error on the first field
      errors['cashAndSavings'] = true;
      errorMessages['cashAndSavings'] =
        'Please let us know how you will pay for the property';
      formIsValid = false;
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  saveHowIsBuyerPaying = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        howIsBuyerPaying: {
          cashAndSavings: this.state.cashAndSavings,
          mortgage: this.state.mortgage,
          sellingAnotherProperty: this.state.sellingAnotherProperty,
          remortgagingAnotherProperty: this.state.remortgagingAnotherProperty,
          helpToBuyIsa: this.state.helpToBuyIsa,
          lifetimeIsa: this.state.lifetimeIsa,
          inheritance: this.state.inheritance,
          someoneGivingMeMoney: this.state.someoneGivingMeMoney,
          someoneLendingMeMoney: this.state.someoneLendingMeMoney,
          bonusFromEmployer: this.state.bonusFromEmployer,
          trust: this.state.trust,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="What will you use to pay for your property?" removeTopPadding>
          <p>
            Tell us about money you are using to pay for your new home, stamp duty, and any fees (including Juno’s legal fee).
          </p>
          <p>
            If you’re buying the property with someone else, we’ll need to know about their money too.
          </p>

          <RadioQuestion
            question="Are you using savings or investments towards your purchase?"
            hint="This is separate from any savings in Help to buy ISAs or Lifetime ISAs"
            options={YES_NO_OPTIONS}
            inline={true}
            name="cashAndSavings"
            selected={this.state.cashAndSavings}
            optionSelected={this.handleInputChange('cashAndSavings')}
            validate={this.handleFieldValidation('cashAndSavings')}
            validationError={
              this.state.errors.cashAndSavings
                ? this.state.errorMessages.cashAndSavings
                : null
            }
          />

          <RadioQuestion
            question="Are you taking out a mortgage on your new home?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="mortgage"
            selected={this.state.mortgage}
            optionSelected={this.handleInputChange('mortgage')}
            validate={this.handleFieldValidation('mortgage')}
            validationError={
              this.state.errors.mortgage
                ? this.state.errorMessages.mortgage
                : null
            }
          />

          <RadioQuestion
            question="Will you be using the money from the sale of another property?"
            hint="If the sale has already completed, please select 'savings' above"
            options={YES_NO_OPTIONS}
            inline={true}
            name="sellingAnotherProperty"
            selected={this.state.sellingAnotherProperty}
            optionSelected={this.handleInputChange('sellingAnotherProperty')}
            validate={this.handleFieldValidation('sellingAnotherProperty')}
            validationError={
              this.state.errors.sellingAnotherProperty
                ? this.state.errorMessages.sellingAnotherProperty
                : null
            }
          />

          <RadioQuestion
            question="Will you be using the money from the remortgage of another property?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="remortgagingAnotherProperty"
            selected={this.state.remortgagingAnotherProperty}
            optionSelected={this.handleInputChange('remortgagingAnotherProperty')}
            validate={this.handleFieldValidation('remortgagingAnotherProperty')}
            validationError={
              this.state.errors.remortgagingAnotherProperty
                ? this.state.errorMessages.remortgagingAnotherProperty
                : null
            }
          />

          <RadioQuestion
            question="Are you using money saved in a Help to Buy ISA for this purchase?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="helpToBuyIsa"
            selected={this.state.helpToBuyIsa}
            optionSelected={this.handleInputChange('helpToBuyIsa')}
            validate={this.handleFieldValidation('helpToBuyIsa')}
            validationError={
              this.state.errors.helpToBuyIsa
                ? this.state.errorMessages.helpToBuyIsa
                : null
            }
          />

          <RadioQuestion
            question="Are you using money saved in a Lifetime ISA to pay for this purchase?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="lifetimeIsa"
            selected={this.state.lifetimeIsa}
            optionSelected={this.handleInputChange('lifetimeIsa')}
            validate={this.handleFieldValidation('lifetimeIsa')}
            validationError={
              this.state.errors.lifetimeIsa
                ? this.state.errorMessages.lifetimeIsa
                : null
            }
          />

          <RadioQuestion
            question="Are you using an inheritance towards your purchase?"
            hint="A gift of money from someone who has died"
            options={YES_NO_OPTIONS}
            inline={true}
            name="inheritance"
            selected={this.state.inheritance}
            optionSelected={this.handleInputChange('inheritance')}
            validate={this.handleFieldValidation('inheritance')}
            validationError={
              this.state.errors.inheritance
                ? this.state.errorMessages.inheritance
                : null
            }
          />

          <RadioQuestion
            question="Is anyone giving you, or has anyone given you over the last 6 months, money you are using towards your purchase?"
            hint="A gift of money from someone who is alive"
            options={YES_NO_OPTIONS}
            inline={true}
            name="someoneGivingMeMoney"
            selected={this.state.someoneGivingMeMoney}
            optionSelected={this.handleInputChange('someoneGivingMeMoney')}
            validate={this.handleFieldValidation('someoneGivingMeMoney')}
            validationError={
              this.state.errors.someoneGivingMeMoney
                ? this.state.errorMessages.someoneGivingMeMoney
                : null
            }
          />

          <RadioQuestion
            question="Are you getting a loan (not including a mortgage) from a person or bank to help pay for your purchase?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="someoneLendingMeMoney"
            selected={this.state.someoneLendingMeMoney}
            optionSelected={this.handleInputChange('someoneLendingMeMoney')}
            validate={this.handleFieldValidation('someoneLendingMeMoney')}
            validationError={
              this.state.errors.someoneLendingMeMoney
                ? this.state.errorMessages.someoneLendingMeMoney
                : null
            }
          />

          <RadioQuestion
            question="Are you using an annual or one-off bonus from your employer?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="bonusFromEmployer"
            selected={this.state.bonusFromEmployer}
            optionSelected={this.handleInputChange('bonusFromEmployer')}
            validate={this.handleFieldValidation('bonusFromEmployer')}
            validationError={
              this.state.errors.bonusFromEmployer
                ? this.state.errorMessages.bonusFromEmployer
                : null
            }
          />

          <RadioQuestion
            question="Are you using money from a Trust?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="trust"
            selected={this.state.trust}
            optionSelected={this.handleInputChange('trust')}
            validate={this.handleFieldValidation('trust')}
            validationError={
              this.state.errors.trust
                ? this.state.errorMessages.trust
                : null
            }
          />

          <Action onClick={this.saveHowIsBuyerPaying}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default HowIsBuyerPaying;
