import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import {
  MORTGAGE_LENDER_OPTIONS,
  YES_NO_OPTIONS,
} from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { isMoneyValid, normaliseMoney } from '../../utils/moneyUtils';
import {isEmailValid} from "../../utils/textUtils";

let buyingMortgageLenders = [...MORTGAGE_LENDER_OPTIONS];
// Remove the last item 'Other' from the list
buyingMortgageLenders.pop();

class Mortgage extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      estimateMortgageAmount: 'Please fill this in',
      isBuyerUsingMortgageBroker: 'Please choose an option',
      mortgageBrokerFullName: 'Please fill this in',
      mortgageBrokerEmailAddress: 'Please fill this in',
      isMortgageLenderKnown: 'Please choose an option',
      mortgageLenderChosen: 'Please choose an option',
    };

    if (this.props.mortgage) {
      const mort = { ...this.props.mortgage };
      this.state = {
        estimateMortgageAmount: mort.estimateMortgageAmount,
        isBuyerUsingMortgageBroker: mort.isBuyerUsingMortgageBroker,
        mortgageBrokerFullName: mort.mortgageBrokerFullName,
        mortgageBrokerEmailAddress: mort.mortgageBrokerEmailAddress,
        mortgageBrokerPhoneNumber: mort.mortgageBrokerPhoneNumber,
        isMortgageLenderKnown: mort.isMortgageLenderKnown,
        mortgageLenderChosen: mort.mortgageLenderChosen,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        estimateMortgageAmount: '',
        isBuyerUsingMortgageBroker: null,
        mortgageBrokerFullName: '',
        mortgageBrokerEmailAddress: '',
        mortgageBrokerPhoneNumber: '',
        isMortgageLenderKnown: null,
        mortgageLenderChosen: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => value => {
    this.setState({ [field]: value });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (!fields['estimateMortgageAmount']) {
      errors['estimateMortgageAmount'] = true;
      formIsValid = false;
    }

    if (
      fields['estimateMortgageAmount'] !== '' &&
      !isMoneyValid(fields['estimateMortgageAmount'])
    ) {
      errors['estimateMortgageAmount'] = true;
      errorMessages['estimateMortgageAmount'] = 'Please enter a valid amount';
      formIsValid = false;
    }

    if (fields['isBuyerUsingMortgageBroker'] === null) {
      errors['isBuyerUsingMortgageBroker'] = true;
      formIsValid = false;
    }

    if (fields['isBuyerUsingMortgageBroker'] === true) {
      if (!fields['mortgageBrokerFullName']) {
        errors['mortgageBrokerFullName'] = true;
        formIsValid = false;
      }

      if (!fields['mortgageBrokerEmailAddress']) {
        errors['mortgageBrokerEmailAddress'] = true;
        formIsValid = false;
      }
      if (
        fields['mortgageBrokerEmailAddress'] !== '' &&
        !isEmailValid(fields['mortgageBrokerEmailAddress'])
      ) {
        errors['mortgageBrokerEmailAddress'] = true;
        errorMessages['mortgageBrokerEmailAddress'] =
          'Please enter a valid email address';
        formIsValid = false;
      }
    }

    if (fields['isMortgageLenderKnown'] === null) {
      errors['isMortgageLenderKnown'] = true;
      formIsValid = false;
    }

    if (fields['isMortgageLenderKnown'] === true) {
      if (!fields['mortgageLenderChosen']) {
        errors['mortgageLenderChosen'] = true;
        formIsValid = false;
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleRadioValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (this.state[field] === null) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleEmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isEmailValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid email address';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleMoneyValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isMoneyValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid amount';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  saveMortgage = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        mortgage: {
          estimateMortgageAmount: normaliseMoney(
            this.state.estimateMortgageAmount,
          ),
          isBuyerUsingMortgageBroker: this.state.isBuyerUsingMortgageBroker,
          mortgageBrokerFullName: this.state.mortgageBrokerFullName,
          mortgageBrokerEmailAddress: this.state.mortgageBrokerEmailAddress,
          mortgageBrokerPhoneNumber: this.state.mortgageBrokerPhoneNumber,
          isMortgageLenderKnown: this.state.isMortgageLenderKnown,
          mortgageLenderChosen: this.state.mortgageLenderChosen,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about your mortgage" removeTopPadding>
          <p>
            We'll look through the full offer soon, so don't worry if these
            details change
          </p>

          <TextQuestion
            question="How much will you be borrowing for your mortgage?"
            hint="If you don't know an exact figure yet, enter an estimated mortgage amount"
            value={this.state.estimateMortgageAmount}
            valueEntered={this.handleInputChange('estimateMortgageAmount')}
            validate={this.handleMoneyValidation('estimateMortgageAmount')}
            validationError={
              this.state.errors.estimateMortgageAmount
                ? this.state.errorMessages.estimateMortgageAmount
                : null
            }
          />

          <RadioQuestion
            question="Are you using a mortgage broker?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="isBuyerUsingMortgageBroker"
            selected={this.state.isBuyerUsingMortgageBroker}
            optionSelected={this.handleInputChange(
              'isBuyerUsingMortgageBroker',
            )}
            validate={this.handleRadioValidation('isBuyerUsingMortgageBroker')}
            validationError={
              this.state.errors.isBuyerUsingMortgageBroker
                ? this.state.errorMessages.isBuyerUsingMortgageBroker
                : null
            }
          />

          {this.state.isBuyerUsingMortgageBroker === true && (
            <RadioConditional>
              <TextQuestion
                question="What is your broker's name?"
                value={this.state.mortgageBrokerFullName}
                valueEntered={this.handleInputChange('mortgageBrokerFullName')}
                validate={this.handleFieldValidation('mortgageBrokerFullName')}
                validationError={
                  this.state.errors.mortgageBrokerFullName
                    ? this.state.errorMessages.mortgageBrokerFullName
                    : null
                }
              />

              <TextQuestion
                question="What is your broker's email address?"
                value={this.state.mortgageBrokerEmailAddress}
                valueEntered={this.handleInputChange(
                  'mortgageBrokerEmailAddress',
                )}
                validate={this.handleEmailAddressValidation(
                  'mortgageBrokerEmailAddress',
                )}
                validationError={
                  this.state.errors.mortgageBrokerEmailAddress
                    ? this.state.errorMessages.mortgageBrokerEmailAddress
                    : null
                }
              />

              <TextQuestion
                question="What is your broker's phone number?"
                value={this.state.mortgageBrokerPhoneNumber}
                valueEntered={this.handleInputChange(
                  'mortgageBrokerPhoneNumber',
                )}
              />
            </RadioConditional>
          )}

          <RadioQuestion
            question="Do you know who your lender will be yet?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="isMortgageLenderKnown"
            selected={this.state.isMortgageLenderKnown}
            optionSelected={this.handleInputChange('isMortgageLenderKnown')}
            validate={this.handleFieldValidation('isMortgageLenderKnown')}
            validationError={
              this.state.errors.isMortgageLenderKnown
                ? this.state.errorMessages.isMortgageLenderKnown
                : null
            }
          />

          {this.state.isMortgageLenderKnown === true && (
            <RadioConditional>
              <RadioQuestion
                question="We can only work with lenders from the following list.
                Who will your mortgage be with?"
                options={buyingMortgageLenders}
                inline={false}
                name="mortgageLenderChosen"
                selected={this.state.mortgageLenderChosen}
                optionSelected={this.handleInputChange('mortgageLenderChosen')}
                validate={this.handleFieldValidation('mortgageLenderChosen')}
                validationError={
                  this.state.errors.mortgageLenderChosen
                    ? this.state.errorMessages.mortgageLenderChosen
                    : null
                }
              />
            </RadioConditional>
          )}

          <Action onClick={this.saveMortgage}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default Mortgage;
