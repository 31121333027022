import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { SELLING_REJECTION_MAP } from '../../constants';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import { recordSignupTermination } from '../../utils/flowActivityUtils';

class SellingSorryPage extends Component {
  componentDidMount() {
    recordSignupTermination(this.props.leadId, this.displayRejectionReason());
  }

  displayRejectionReason() {
    for (let [key, value] of this.props.sellingRejectionReason) {
      if (!!value) {
        return SELLING_REJECTION_MAP.get(key);
      }
    }
  }

  render() {
    const rejectionReason = this.displayRejectionReason()  // something like "unregistered land"
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={"Sorry — we do not handle "+rejectionReason} removeTopPadding>
          <p>
            Juno does not handle the sale of {rejectionReason}.
          </p>
          <p>
            We’re focused on making regular conveyancing simpler, clearer and faster, and we’ve not yet built the systems to handle {rejectionReason}.
          </p>
          <p>
            We're sorry we cannot do your conveyancing.
          </p>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default SellingSorryPage;
