import styled from "styled-components/macro";
import { mediaQueries } from "../../styles/media";


export const QuotedPriceWrapper = styled.div`
  background: ${props => props.theme.color.green};
  padding: 1em 1.25em;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 1em;
  ${mediaQueries.md} {
    margin-top: 0;
    margin-left: -31px;
    margin-right: -31px;
    padding-bottom: 0.5em;
    padding-left: 31px;
  }
  p {
    margin-top: 0;
    color: ${props => props.theme.color.white};
  }
  a {
    color: ${props => props.theme.color.white};
    text-decoration: none;
    border-bottom: 1px solid;
  }
`;

export const QuotedPrice = styled.h2`
  color: ${props => props.theme.color.white};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin: 0.5em 0 0.25em 0;

  font-size: ${props => props.theme.fontSizes.largeNumber['sm']};
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.largeNumber['md']};
    margin-bottom: 0.4em;
  }
`;

export const ListItemSuffix = styled.span`
  display: block;
  font-weight: ${props => props.theme.fontWeight.regular};
  font-family: ${props => props.theme.junoFontMono};
  ${mediaQueries.sm} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const QuoteBreakdown = styled.div`
  overflow: hidden;
  li {
    color: ${props => props.theme.color.junoTextSecondary};
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-right: 0.75em;
    ${mediaQueries.sm} {
      margin-right: 1em;
    }
  }
`;