import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import DashboardHeader from '../../components/DashboardHeader.js';
import DashboardLayout from '../../components/DashboardLayout';
import CaseCard from '../../components/CaseCard';
import CaseFilterReset from '../../components/CaseFilterReset';
import CaseStatusUpdate from './CaseStatusUpdate';
import GenericStatusUpdate from './GenericStatusUpdate';
import Panel from '../../components/Panel';
import Search from '../../components/Search.js';
import ToggleButton from '../../components/ToggleButton';
import TabNav from './TabNav';
import TabPanel from './TabPanel';


import { LIFECYCLE_TAGS } from '../../utils/constants';
import { getTagGroupName } from '../../utils/textUtils';

import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media.js';

const filterCasesBySearchInput = (cases, searchInput) => {
  const searchParts = searchInput.toLowerCase().split(',');
  return cases.filter((c) =>
    searchParts.every(part =>
      c.case_reference?.toLowerCase().includes(part.trim()) ||
      c.case_data?.address?.line_one?.toLowerCase().includes(part.trim()) ||
      c.case_data?.address?.postcode?.toLowerCase().includes(part.trim()) ||
      c.clients?.some(client => client.last_name.toLowerCase().includes(part.trim()))
    )
  );
};

const filterCasesByNavItem = (cases, activeNavItem) => {
  if (activeNavItem === 'pre_memo') {
    return cases.filter(c => c.case_data?.active_lifecycle_tag === 'case_setup' && c.case_data?.milestones?.memorandum_of_sale_received === null);
  }

  const activeTags = LIFECYCLE_TAGS[activeNavItem]?.tags;
  if (activeTags) {
    return cases.filter(c => activeTags.includes(c.case_data?.active_lifecycle_tag) && c.case_data?.milestones?.memorandum_of_sale_received !== null);
  }

  return cases;
};

const sortCasesByPhaseAndDate = (list, dateType) => {
  const phaseOrder = [
    'wrapping_up', 'post_completion', 'approaching_completion', 'ready_to_exchange',
    'approaching_exchange', 'enquiries_ongoing', 'investigation', 'information_gathering',
    'case_setup'
  ];

  // If sorting by newest, reverse the phaseOrder
  if (dateType === 'newest_case') {
    phaseOrder.reverse();
  }

  const newList = [...list];
  return newList.sort((a, b) => {
    const phaseDiff = phaseOrder.indexOf(a.case_data.active_lifecycle_tag) - phaseOrder.indexOf(b.case_data.active_lifecycle_tag);

    if (phaseDiff !== 0) {
      return phaseDiff;
    }
    // If completion_datetime exists, sort by it, otherwise sort by created_at
    const aDate = a.case_data.completion_datetime ? new Date(a.case_data.completion_datetime) : new Date(a.created_at);
    const bDate = b.case_data.completion_datetime ? new Date(b.case_data.completion_datetime) : new Date(b.created_at);

    // If sorting by newest, reverse the date comparison
    return dateType === 'newest_case' ? bDate - aDate : aDate - bDate;
  });
};

const AgentDashboard = () => {

  const cases = useSelector(state => state.dashboardState.cases);
  const session = useSelector(state => state.dashboardState.session);

  const [showMenu, setShowMenu] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const activeNavItem = searchParams.get('view') || 'all_cases';
  const activeTab = searchParams.get('tab');
  const order = searchParams.get('order') || 'oldest_case';
  const searchInput = searchParams.get('search') || '';

  const [caseList, setCaseList] = useState(null);

  useEffect(() => {
    if (searchInput !== "") {
      setCaseList(filterCasesBySearchInput(cases, searchInput));
      return;
    }

    let newCaseList = filterCasesByNavItem(cases, activeNavItem)
    newCaseList = sortCasesByPhaseAndDate(newCaseList, order)

    setCaseList(newCaseList);
  }, [cases, searchInput, activeNavItem, order]);

  const handleOrderChange = (newOrder) => {
    setSearchParams((params) => {
      params.set('order', newOrder);
      return new URLSearchParams(params);
    });
  };

  const handleNavChange = value => {
    setSearchParams((params) => {
      params.set('view', value);
      return new URLSearchParams(params);
    });
    handleClearSearch();
    handleHideTab();
    if (showMenu !== false) {
      setShowMenu(false);
    }
  }

  const handleSearch = value => {
    setSearchParams((params) => {
      params.set('search', value);
      return new URLSearchParams(params);
    });
  };

  const handleClearSearch = () => {
    setSearchParams((params) => {
      if (params.has('search')) {
        params.delete('search');
      }
      return new URLSearchParams(params);
    });
  };

  const handleShowTab = value => {
    setSearchParams((params) => {
      if (activeTab === value) {
        if (params.has('tab')) {
          params.delete('tab');
        }
      } else {
        params.set('tab', value);
      }
      return new URLSearchParams(params);
    });
  };

  const handleHideTab = () => {
    setSearchParams((params) => {
      if (params.has('tab')) {
        params.delete('tab');
      }
      return new URLSearchParams(params);
    });
  };

  const toggleMenu = () => setShowMenu(!showMenu);

  const shouldShowActionRequired = (c) => {
    if (activeNavItem === 'completed') {
      return false
    }
    return c.status_updates?.estate_agent_dashboard_action_required && Object.keys(c.status_updates?.estate_agent_dashboard_action_required).length > 0;
  }

  const renderCaseList = () => {
    if (caseList.length === 0) {
      return (
        <>
          <CaseFilterReset
            activeNavItem={activeNavItem}
            activeNavItemName={getTagGroupName(activeNavItem)}
            searchInput={searchInput}
            handleClearSearch={handleClearSearch}
            handleShowAll={() => setSearchParams({ ...searchParams, view: 'all_cases' })}
          />
          {searchInput && (
            <Panel>
              <h2>
                If you can’t find the case you’re looking for, please get in touch.
              </h2>
              <p>
                Email us at <a href="mailto:hello@juno.legal">hello@juno.legal</a>.
              </p>
            </Panel>
          )}
        </>
      );
    }

    return caseList.map(c => (
      <ListItem key={c.id}>
        <CaseCard data={c}>
          <Tabs>
            <TabNav
              caseReference={c.case_reference}
              selectedTab={activeTab}
              onShowTab={handleShowTab}
              hasActionRequired={shouldShowActionRequired(c)}
            />
            <TabPanel show={activeTab === `${c.case_reference}_live_update`}>
              <CaseStatusUpdate
                statusUpdate={c.status_updates?.estate_agent_dashboard_overview}
              />
            </TabPanel>
            <TabPanel show={activeTab === `${c.case_reference}_action_required`}>
              <GenericStatusUpdate
                  statusUpdate={c.status_updates?.estate_agent_dashboard_action_required}
                />
            </TabPanel>
          </Tabs>
        </CaseCard>
      </ListItem>
    ));
  };

  if (!caseList) {
    return null;
  }

  return (
    <>
      <DashboardHeader
        onToggleMenu={toggleMenu}
        showMenu={showMenu}
        showLogout={true}
        user={session}>

        <SearchLayout>
          <Search
            onSearch={handleSearch}
            onClearSearch={handleClearSearch}
            value={searchInput} />

          <ToggleButton
            value={order}
            onChange={handleOrderChange} />
        </SearchLayout>

      </DashboardHeader>

      <DashboardLayout
        showMenu={showMenu}
        selectedNavItem={activeNavItem}
        showNav={handleNavChange}>

        <CaseLayout>
          <List>
            {renderCaseList()}
          </List>
        </CaseLayout>

        {!searchInput && (
          <StickyPanelLayout>
            <Panel small={true}>
              <h2>
                Tell us how we can improve your dashboard
              </h2>
              <p>
                <a href='https://forms.gle/oPU9chTHE2gF2PvY8'
                   target='_blank' rel='noreferrer'>
                  Fill in our feedback form here
                </a>
              </p>
            </Panel>
          </StickyPanelLayout>
        )}

      </DashboardLayout>
    </>
  );
}

export default AgentDashboard;

const SearchLayout = styled.div`
  display: none;
  ${mediaQueries.md} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const CaseLayout = styled.div`
  background: ${props => props.theme.color.backgroundGrey};
  border-top: 1px solid #ebebeb;

  margin-left: 1.25rem;
  margin-right: 1.25rem;

  ${mediaQueries.md} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const List = styled.ul`
  list-style: none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 1.5rem;
  ${mediaQueries.md} {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  padding: 0 0 1.5rem 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;


const StickyPanelLayout = styled.div`
  position: sticky;
  top: 100%;

  margin-left: 1.25rem;
  margin-right: 1.25rem;

  ${mediaQueries.md} {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`

const Tabs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
