import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

class RemoPropertySorry extends Component {
  rejectHasTenants = () => {
    let reason = { ...this.props.propertyInformation1 };
    if (reason.rejectHasTenants === true) {
      return true;
    }
    return false;
  };

  rejectSharedOwnership = () => {
    let reason = { ...this.props.propertyInformation1 };
    if (reason.rejectSharedOwnership === true) {
      return true;
    }
    return false;
  };

  rejectMultipleTitles = () => {
    let reason = { ...this.props.propertyInformation1 };
    if (reason.rejectMultipleTitles === true) {
      return true;
    }
    return false;
  };

  rejectManagementCompany = () => {
    let reason = { ...this.props.propertyInformation2 };
    if (
      reason.clientPaysMaintenanceFees === true &&
      reason.rejectManagementCompany === true
    ) {
      return true;
    }
    return false;
  };

  rejectNegativeEquity = () => {
    let reason = { ...this.props.propertyInformation3 };
    if (reason.rejectNegativeEquity === true) {
      return true;
    }
    return false;
  };

  rejectNotPayingOffAllCharges = () => {
    let reason = { ...this.props.propertyInformation3 };
    if (
      reason.clientHasMoreThanOneCharge === true &&
      reason.rejectNotPayingOffAllCharges === false
    ) {
      return true;
    }
    return false;
  };

  rejectNegativeEquityAndNotPayingOffAllCharges = () => {
    let reason = { ...this.props.propertyInformation3 };
    if (
      reason.rejectNegativeEquity === true &&
      (reason.clientHasMoreThanOneCharge === true &&
        reason.rejectNotPayingOffAllCharges === false)
    ) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Sorry, we can’t help you remortgage your property" removeTopPadding>
          {this.rejectHasTenants() && (
            <p>
              At the moment, we’re not taking on remortgages involving tenants.
              We plan to start offering this service soon though, so please do
              consider us next time round.
            </p>
          )}
          {this.rejectSharedOwnership() && (
            <p>
              At the moment, we’re not taking on remortgages involving Shared
              Ownership properties. We plan to start offering this service soon
              though, so please do consider us next time round.
            </p>
          )}
          {this.rejectMultipleTitles() && (
            <p>
              At the moment, we’re not taking on remortgages involving
              additional land that you want included on the mortgage. We plan
              to start offering this service soon though, so please do consider
              us next time round.
            </p>
          )}
          {this.rejectManagementCompany() && (
            <p>
              At the moment, we aren’t taking on cases that involve management
              companies. We hope to take on more of these cases soon though, so
              please do consider us next time round.
            </p>
          )}
          {this.rejectNegativeEquity() && (
            <p>
              At the moment, we aren’t taking on cases where clients are in
              negative equity. We hope to take on more of these cases soon
              though, so please do consider us next time round.
            </p>
          )}
          {this.rejectNotPayingOffAllCharges() && (
            <p>
              At the moment, we aren’t taking on cases that involve complex
              mortgage arrangements. We hope to take on more of these cases soon
              though, so please do consider us next time round.
            </p>
          )}
          {this.rejectNegativeEquityAndNotPayingOffAllCharges() && (
            <p>
              At the moment, we aren’t taking on cases where clients are in
              negative equity or cases that involve complex mortgage
              arrangements. We hope to take on more of these cases soon though,
              so please do consider us next time round.
            </p>
          )}
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default RemoPropertySorry;
