const payIdToSku = [
  ['id1', 'sku_GHmAv2gyt4mwim'], // £399
  ['id1a', 'sku_GVcF6B5FV6s7hA'], // £499
  ['id2', 'sku_GHm3drHAMJ8Dsp'], // £699
  ['id3', 'sku_GHm3D1evzByM8C'], // £749
  ['id4', 'sku_GHm35ihSAUU7Xm'], // £799
  ['id5', 'sku_GHm4RFZdV88mKg'], // £849
  ['id6', 'sku_GHm4QNmR1TGPS2'], // £899
  ['id7', 'sku_GHm49nX53AZK8E'], // £949
  ['id8', 'sku_GHm4OK63fZN1to'], // £999
  ['id9', 'sku_GHm4xmV2AMRODk'], // £1,049
  ['id10', 'sku_GHm4V21R5n3xtr'], // £1,099
  ['id11', 'sku_GHm5BHdqwoqBE8'], // £1,149
  ['id12', 'sku_GHm5ZuKqbtsvhZ'], // £1,199
  ['id13', 'sku_GHm5UuVIfXeQLg'], // £1,249
  ['id14', 'sku_GHm5wEsi9p17OV'], // £1,299
  ['id15', 'sku_GHm5uZJmc4WgDc'], // £1,349
  ['id16', 'sku_GHm5Omy0ABF7xd'], // £1,399
  ['id17', 'sku_GHm5kc89orzJ6k'], // £1,449
  ['id18', 'sku_GHm6bPMXEnOr7I'], // £1,499
  ['id19', 'sku_GHm6A7XYgllS6S'], // £1,549
  ['id20', 'sku_GHm66YLclfs36B'], // £1,599
  ['id21', 'sku_GVcG678mUHQSXV'], // £1,649
  ['id22', 'sku_GVcHVDY9ZHzLTf'], // £1,699
];

export const SKU_MAP = new Map(payIdToSku);
