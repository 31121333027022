import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { createId, createDataTestId } from '../../components/utils/helpers';
import { mediaQueries } from '../../styles/media.js';
import Link from '../../components/Link';
import Question, { Optional } from '../../components/Question';
import Fieldset from '../../components/Fieldset';
import Legend from '../../components/Legend';
import Hint from '../../components/Hint';
import Error from '../../components/Error';

class TA10RadioQuestion extends Component {

  render() {

    let itemInConfig

    // It's a custom item, if it's not in the config itemList
    if (this.props.config.sectionType === 'itemList') {
      itemInConfig = !!this.props.config.items.find(configItem => configItem.itemName === this.props.item.itemName);
    }

    // It's a custom item, if it's not in the default list of room items
    if (this.props.config.sectionType === 'roomItemList') {
      itemInConfig = !!this.props.roomItemsConfig.find(configItem => configItem.itemName === this.props.item.itemName);
    }

    return (
      <StyledTA10RadioQuestion>
        <Fieldset>
          <StyledLegend>
            <StyledQuestion>
              <span>{this.props.question}</span>
              {!itemInConfig && (
                <StyledLink onClick={() => this.props.removeItem(this.props.item)}>Remove</StyledLink>
              )}
            </StyledQuestion>
            {this.props.optional ? <Optional /> : null}
            {this.props.hint ? <Hint>{this.props.hint}</Hint> : null}
            {this.props.validationError ? (
              <Error>{this.props.validationError}</Error>
            ) : null}
          </StyledLegend>
          {this.props.options.map((option, i) => {
            const labelText = option.title || option.label;
            const componentId = createId('input', this.props.name, labelText);
            const dataTestId = createDataTestId('radio', this.props.name, labelText);

            return (
              <Radio option={option} key={i} inline={this.props.inline}>
                <input
                  type="radio"
                  name={this.props.name}
                  checked={this.props.selected === option.value}
                  onChange={event => {
                    this.props.optionSelected(option.value);
                  }}
                  onClick={this.props.validate}
                  value={option.value}
                  id={componentId}
                  data-testid={dataTestId}
                  disabled={this.props.disabled}
                />
                <label htmlFor={componentId}>{labelText}</label>
              </Radio>
            );
          })}

          <StyledSubQuestion>
            {this.props.children}
          </StyledSubQuestion>

        </Fieldset>
      </StyledTA10RadioQuestion>
    );
  }
}

const StyledTA10RadioQuestion = styled(Question)`
  margin-bottom: 2em;
`

const StyledSubQuestion = styled(Question)`
  margin-top: 1.25em;
  ${mediaQueries.md} {
    margin-top: 1.5em;
}
`

const StyledLegend = styled(Legend)`
  position: relative;
  width: 100%;

  ${mediaQueries.md} {
    width: 520px;
  }
`

const StyledQuestion = styled.div`
  display: flex;
  align-items: baseline;
  max-width: 70%;

  ${mediaQueries.md} {
    max-width: 100%;
  }

  span {
    font-weight: ${props => props.theme.fontWeight.bold};
  }
`

const StyledLink = styled(Link)`
  margin-left: 0.5em;
  &:hover {
    border-bottom: none;
  }
`

const Radio = styled.div`
  min-height: 32px;
  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: 1.5rem;

  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
    line-height: 1.75rem;
  }

  margin-bottom: 8px;
  padding-left: 2em;
  position: relative;

  label {
    display: block;
    padding: 0.25em 0.75em;
    border: 2px solid transparent;
    cursor: pointer;

    touch-action: manipulation;

    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;

      width: 2em;
      height: 2em;

      border: 2px solid ${props => props.theme.color.junoBorder};
      border-radius: 50%;
      background: transparent;
    }

    &:after {
      content: '';

      position: absolute;
      top: 0.5em;
      left: 0.5em;

      width: 0;
      height: 0;

      border: 0.5em solid ${props => props.theme.color.junoBorder};
      border-radius: 50%;
      opacity: 0;
      background: #adb6c2;

    }
  }

  input {
    position: absolute;

    z-index: 1;
    top: 0;
    left: 0;

    width: 2em;
    height: 2em;

    cursor: pointer;
    margin: 0;
    opacity: 0;

    :checked + label:after {
      opacity: 1;
    }

    :focus + label:before {
      box-shadow: 0 0 0 4px ${props => props.theme.color.junoFocus};
    }

    :disabled,
    :disabled + label {
      cursor: auto;
    }

    :disabled:checked + label {
      font-weight: ${props => props.theme.fontWeight.bold};
    }

    :disabled:checked + label::before,
    :disabled:checked + label::after {
      border-color: ${props => props.theme.color.junoText};
    }

  }
`;

export default TA10RadioQuestion;
