import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class SellingMortgagesAndLoans extends Component {
  constructor(props) {
    super(props);

    if (this.props.sellingMortgagesAndLoans) {
      const details = this.props.sellingMortgagesAndLoans;
      this.state = {
        sellerHasAMortgage: details.sellerHasAMortgage,
        sellerHasASecuredLoan: details.sellerHasASecuredLoan,
        errors: {},
      };
    } else {
      this.state = {
        sellerHasAMortgage: null,
        sellerHasASecuredLoan: null,
        errors: {},
        errorMessages: {
          sellerHasAMortgage: 'Let us know if you have a mortgage',
          sellerHasASecuredLoan: 'Let us know if you have a secured loan',
        },
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};
    let formIsValid = true;

    if (fields['sellerHasAMortgage'] === null) {
      const field = 'sellerHasAMortgage';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (fields['sellerHasASecuredLoan'] === null) {
      const field = 'sellerHasASecuredLoan';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      // Hide the error when you interact with the field
      errors[field] = null;
    } else {
      // If the state for this field hasn't been set, we need some input, show an error
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  selectSellerHasAMortgage = sm => {
    this.setState({ sellerHasAMortgage: sm });
  };

  selectSellerHasASecuredLoan = sl => {
    this.setState({ sellerHasASecuredLoan: sl });
  };

  saveSellingMortgagesAndLoans = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        sellingMortgagesAndLoans: {
          sellerHasAMortgage: this.state.sellerHasAMortgage,
          sellerHasASecuredLoan: this.state.sellerHasASecuredLoan,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Great. What about mortgages and loans?" removeTopPadding>
          <RadioQuestion
            question="Have you got a mortgage on the property?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="sellerHasAMortgage"
            selected={this.state.sellerHasAMortgage}
            optionSelected={this.selectSellerHasAMortgage}
            validate={this.handleFieldValidation('sellerHasAMortgage')}
            validationError={
              this.state.errors.sellerHasAMortgage
                ? this.state.errors.sellerHasAMortgage
                : null
            }
          />
          {this.state.sellerHasAMortgage && (
            <RadioConditional>
              <p>
                Okay, we’ll be in touch when we need more information about your
                mortgage.
              </p>
            </RadioConditional>
          )}

          <RadioQuestion
            question="Are there any loans secured on the property?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="sellerHasASecuredLoan"
            selected={this.state.sellerHasASecuredLoan}
            optionSelected={this.selectSellerHasASecuredLoan}
            validate={this.handleFieldValidation('sellerHasASecuredLoan')}
            validationError={
              this.state.errors.sellerHasASecuredLoan
                ? this.state.errors.sellerHasASecuredLoan
                : null
            }
          />
          {this.state.sellerHasASecuredLoan && (
            <RadioConditional>
              <p>
                Thanks, we’ll get in touch when we need to know more about your
                loan.
              </p>
            </RadioConditional>
          )}

          <Action onClick={this.saveSellingMortgagesAndLoans}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default SellingMortgagesAndLoans;
