import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import TextQuestion from '../../components/TextQuestion';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { MORTGAGE_LENDER_OPTIONS } from '../../constants';

class SellingMortgage1 extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      mortgageLender: 'Please choose an option or enter your lender below',
      mortgageAccountNumber: 'Enter your account number'
    };

    if (this.props.mortgage1) {
      const mortgage1 = { ...this.props.mortgage1 };
      this.state = {
        mortgageLender: mortgage1.mortgageLender,
        mortgageLenderOther: mortgage1.mortgageLenderOther,
        mortgageAccountNumber: mortgage1.mortgageAccountNumber,
        mortgageSortCode: mortgage1.mortgageSortCode,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        mortgageLender: null,
        mortgageLenderOther: '',
        mortgageAccountNumber: '',
        mortgageSortCode: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};

    let formIsValid = true;

    if (fields['mortgageLender'] === null && !fields['mortgageLenderOther']) {
      const field = 'mortgageLender';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    if (!fields['mortgageAccountNumber']) {
      const field = 'mortgageAccountNumber';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  saveSellingMortgage1 = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        mortgage1: {
          mortgageLender: this.state.mortgageLender,
          mortgageLenderOther: this.state.mortgageLenderOther,
          mortgageSortCode: this.state.mortgageSortCode,
          mortgageAccountNumber: this.state.mortgageAccountNumber
        },
      });
      return;
    }
  };

  render() {
    let cardTitle = 'Your mortgage';
    if (
      this.props.numberOfMortgages === 2 ||
      this.props.numberOfMortgages === 3
    ) {
      cardTitle = 'Your first mortgage';
    }
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={cardTitle} removeTopPadding>
          <RadioQuestion
            question="Please choose your mortgage lender from the list"
            options={MORTGAGE_LENDER_OPTIONS}
            inline={false}
            name="mortgageLender"
            selected={this.state.mortgageLender}
            optionSelected={this.handleInputChange('mortgageLender')}
            validate={this.handleFieldValidation('mortgageLender')}
            validationError={
              this.state.errors.mortgageLender
                ? this.state.errors.mortgageLender
                : null
            }
          />
          <TextQuestion
            question="If you can’t find your lender on the list, tell us their name here"
            value={this.state.mortgageLenderOther || ''}
            valueEntered={this.handleInputChange('mortgageLenderOther')}
          />

          <TextQuestion
            question="What’s your mortgage sort code?"
            optional={true}
            value={this.state.mortgageSortCode || ''}
            valueEntered={this.handleInputChange('mortgageSortCode')}
            validate={this.handleFieldValidation('mortgageSortCode')}
            validationError={
              this.state.errors.mortgageSortCode
                ? this.state.errors.mortgageSortCode
                : null
            }
          />

          <TextQuestion
            question="What’s your mortgage account number?"
            value={this.state.mortgageAccountNumber || ''}
            valueEntered={this.handleInputChange('mortgageAccountNumber')}
            validate={this.handleFieldValidation('mortgageAccountNumber')}
            validationError={
              this.state.errors.mortgageAccountNumber
                ? this.state.errors.mortgageAccountNumber
                : null
            }
          />

          <Action onClick={this.saveSellingMortgage1}>
            Submit mortgage details
          </Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default SellingMortgage1;
