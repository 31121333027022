/*
 * action types
 */

// data actions
export const DASHBOARD_LOADED = 'DASHBOARD_LOADED';
export const USER_NOT_LOGGED_IN = 'USER_NOT_LOGGED_IN';
export const SET_ACTIVE_CASE = 'SET_ACTIVE_CASE';

/*
 * action creators
 */
export function dashboardLoaded(dashboardData) {
  return {
    type: DASHBOARD_LOADED,
    dashboardData
  }
}

export function userNotLoggedIn() {
  return {
    type: USER_NOT_LOGGED_IN
  }
}

export function setActiveCase(caseRef, caseScreen) {
  return {
    type: SET_ACTIVE_CASE,
    caseRef,
    caseScreen,
  }
}
