import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const JunoQuestion = styled.div`
  margin-bottom: 1rem;
  ${mediaQueries.md} {
    margin-bottom: 1.75rem;
  }
  max-width: 38rem;
`;

const JunoOptional = styled.span`
  color: ${props => props.theme.color.junoTextSecondary};
`;

export function Optional() {
  return <JunoOptional> (optional)</JunoOptional>;
}

function Question(props) {
  return <JunoQuestion className={props.className}>{props.children}</JunoQuestion>;
}

export default Question;
