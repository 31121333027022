export const choices_TA10 = [
  { label: 'Included', value: 'Included' },
  { label: 'Excluded', value: 'Excluded' },
  { label: 'For sale', value: 'For sale' },
  { label: 'None', value: 'None' },
];

export const choices_TA10_kitchen = [
  { label: 'Fitted', value: 'Fitted' },
  { label: 'Free-standing', value: 'FreeStanding' },
]

export const default_rooms = [
  "Kitchen",
  "Hall, stairs and landing",
  "Living room",
  "Dining room",
  "Bedroom 1",
  "Bedroom 2",
  "Bedroom 3"
]

export const room_items = [
  {itemName: "Carpets", itemType: "roomItem"},
  {itemName: "Curtain rails/poles/pelmets", itemType: "roomItem"},
  {itemName: "Curtains/blinds", itemType: "roomItem"},
  {itemName: "Light fittings", itemType: "roomItem"},
  {itemName: "Fitted units", itemType: "roomItem", itemHint: "For example, fitted cupboards, shelves and wardrobes"},
]

export const TA10Config = [
  {
    sectionType: "roomItemList",
    allowsAddItem: true,
    items: room_items
  },
  {
    sectionTitle: "Basic fittings throughout the property",
    sectionNote: "Tell us which items below are included or excluded from the sale, for sale, or if there is no such item at the property.",
    sectionType: "itemList",
    allowsAddItem: true,
    items: [
      {itemName: "Boiler/immersion heater", itemType: "basic"},
      {itemName: "Radiators/wall heaters", itemType: "basic"},
      {itemName: "Night-storage heaters", itemType: "basic"},
      {itemName: "Free-standing heaters", itemType: "basic"},
      {itemName: "Gas fires (with surround)", itemType: "basic"},
      {itemName: "Electric fires (with surround)", itemType: "basic"},
      {itemName: "Light switches", itemType: "basic"},
      {itemName: "Roof insulation", itemType: "basic"},
      {itemName: "Window fittings", itemType: "basic"},
      {itemName: "Window shutters/grilles", itemType: "basic"},
      {itemName: "Internal door fittings", itemType: "basic"},
      {itemName: "External door fittings", itemType: "basic"},
      {itemName: "Doorbell/chime", itemType: "basic"},
      {itemName: "Electric sockets", itemType: "basic"},
      {itemName: "Burglar alarm", itemType: "basic"}
    ]
  },
  {
    sectionTitle: "Kitchen",
    sectionNote: "Tell us which items below are included or excluded from the sale, for sale, or if there is no such item at the property. We'll ask about common fittings, for example - carpets and curtains in the next section.",
    sectionType: "itemList",
    allowsAddItem: true,
    items: [
      {itemName: "Hob", itemType: "kitchen"},
      {itemName: "Extractor hood", itemType: "kitchen"},
      {itemName: "Oven/grill", itemType: "kitchen"},
      {itemName: "Cooker", itemType: "kitchen"},
      {itemName: "Microwave", itemType: "kitchen"},
      {itemName: "Refrigerator/fridge-freezer", itemType: "kitchen"},
      {itemName: "Freezer", itemType: "kitchen"},
      {itemName: "Dishwasher", itemType: "kitchen"},
      {itemName: "Tumble-dryer", itemType: "kitchen"},
      {itemName: "Washing machine", itemType: "kitchen"}
    ]
  },
  {
    sectionTitle: "Bathroom",
    sectionNote: "Tell us which items below are included or excluded from the sale, for sale, or if there is no such item at the property.",
    sectionType: "itemList",
    allowsAddItem: true,
    items: [
      {itemName: "Bath", itemType: "basic"},
      {itemName: "Shower fitting for bath", itemType: "basic"},
      {itemName: "Shower curtain", itemType: "basic"},
      {itemName: "Bathroom cabinet", itemType: "basic"},
      {itemName: "Taps", itemType: "basic"},
      {itemName: "Separate shower and fittings", itemType: "basic"},
      {itemName: "Towel rail", itemType: "basic"},
      {itemName: "Soap/toothbrush holders", itemType: "basic"},
      {itemName: "Toilet roll holders", itemType: "basic"},
      {itemName: "Bathroom mirror", itemType: "basic"}
    ]
  },
  {
    sectionTitle: "Carpets",
  },
  {
    sectionTitle: "Curtain rails/poles/pelmets",
  },
  {
    sectionTitle: "Curtains/blinds",
  },
  {
    sectionTitle: "Light fittings",
  },
  {
    sectionTitle: "Fitted units",
  },
  {
    sectionTitle: "Outdoor area",
    sectionNote: "Tell us which items below are included or excluded from the sale, for sale, or if there is no such item at the property.",
    sectionType: "itemList",
    allowsAddItem: true,
    items: [
      {itemName: "Garden furniture", itemType: "basic"},
      {itemName: "Garden ornaments", itemType: "basic"},
      {itemName: "Trees, plants, shrubs", itemType: "basic"},
      {itemName: "Barbeque", itemType: "basic"},
      {itemName: "Dustbins", itemType: "basic"},
      {itemName: "Garden shed", itemType: "basic"},
      {itemName: "Greenhouse", itemType: "basic"},
      {itemName: "Outdoor heater", itemType: "basic"},
      {itemName: "Outside lights", itemType: "basic"},
      {itemName: "Water butt", itemType: "basic"},
      {itemName: "Clothes line", itemType: "basic"},
      {itemName: "Rotary line", itemType: "basic"}
    ]
  },
  {
    sectionTitle: "Television and telephone",
    sectionNote: "Tell us which items below are included or excluded from the sale, for sale, or if there is no such item at the property.",
    sectionType: "itemList",
    allowsAddItem: false,
    items: [
      {itemName: "Telephone receivers", itemType: "basic"},
      {itemName: "Television aerial", itemType: "basic"},
      {itemName: "Radio aerial", itemType: "basic"},
      {itemName: "Satellite dish", itemType: "basic"}
    ]
  },
  {
    sectionTitle: "Stock of fuel",
    sectionNote: "Tell us which items below are included or excluded from the sale, for sale, or if there is no such item at the property.",
    sectionType: "itemList",
    allowsAddItem: false,
    items: [
      {itemName: "Oil", itemType: "basic"},
      {itemName: "Wood", itemType: "basic"},
      {itemName: "Liquefied Petroleum Gas (LPG)", itemType: "basic"},
    ]
  },
  {
    sectionTitle: "Other items",
    sectionNote: "Tell us if there are any other items that are for sale, or to be included or excluded from the sale.",
    sectionType: "itemList",
    allowsAddItem: true,
    items: [
    ]
  }
]
