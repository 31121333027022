import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';


const StyledReferralLink = styled.div`
  width: fit-content;

  display: flex;

  text-align: left;
  background: #fac7c0;
  border-radius: 8px;

  margin-top: 1em;
  padding: 8px 12px;

  p {
    margin: 0;
    color: #0d0d0d;
    font-size: 16px;
  }
`

function ReferralLink({ children }) {
  return (
    <StyledReferralLink>
      {children}
    </StyledReferralLink>
  );
}

ReferralLink.propTypes = {
  children: PropTypes.element.isRequired
};


export default ReferralLink;
