import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';
import { formatMoneyNum } from '../utils/moneyUtils';
import { formatDate, joinNames, getFormattedLifecycleTag } from '../utils/textUtils';


function CaseCard({ data, children }) {

  // Format names
  const surnames = data && data.clients ? data.clients.map(client => {
    return `${client.last_name}`;
  }) : [];

  const formattedLastNames = joinNames(surnames);

  // Format price
  const formattedPropertyPrice = formatMoneyNum(data.case_data?.property_price);

  // Format active lifecycle tag
  const memoReceivedDate = data.case_data?.milestones?.memorandum_of_sale_received;
  const formattedLifecycleTag = getFormattedLifecycleTag(data.case_data?.active_lifecycle_tag, memoReceivedDate)

  const address = data.case_data?.address?.line_one;

  const postcode = data.case_data?.address?.postcode;

  const completedTags = ["post_completion", "wrapping_up"];

  const formattedCompletionDate = completedTags.includes(data.case_data?.active_lifecycle_tag) && data.case_data?.milestones?.completion ? formatDate(data.case_data?.milestones?.completion) : null;

  return (
    <Card>
      <CaseSummary>
        <div>
          <CaseTitleLayout>
            <h3>{address ?? address}{address && ','} {postcode ?? postcode}</h3>
            {formattedLifecycleTag && (
              <p>{formattedLifecycleTag} {formattedCompletionDate ?? formattedCompletionDate}</p>
            )}
          </CaseTitleLayout>
          <CaseTitleLayout>
            <p>{formattedLastNames ?? formattedLastNames} {data.case_type} ({data.case_reference})</p>
            {formattedPropertyPrice && (
              <PriceText>{formattedPropertyPrice}</PriceText>
            )}
          </CaseTitleLayout>
        </div>
      </CaseSummary>

      {children}

    </Card>
  );
}

export default CaseCard;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;

  margin-left: 0px;

  color: ${props => props.theme.color.junoText};
  max-width: 60rem;

  padding: 1rem;
  ${mediaQueries.md} {
    margin-bottom: 1.5rem;
  }
  ${mediaQueries.lg} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const CaseSummary = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.color.backgroundGrey};
  h3 {
    font-size: 1rem;
    ${mediaQueries.md} {
      font-size: 1.25rem;
    }
    font-weight: ${props => props.theme.fontWeight.regular};
    margin-top: 0;
    margin-bottom: 0.25rem;
    ${mediaQueries.md} {
      margin-bottom: 10px;
      width: 75%;
    }
  }
  p {
    font-size: 0.875rem;
    font-weight: ${props => props.theme.fontWeight.regular};
    color: ${props => props.theme.color.text3};
    margin-top: 0;
    margin-bottom: 0.25rem;
    ${mediaQueries.md} {
      margin-bottom: 12px;
    }
  }
  p:first-letter {
    text-transform: uppercase;
  }
`;

const CaseTitleLayout = styled.div`
  ${mediaQueries.md} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
  svg {
    color: ${props => props.theme.color.palePurple};
  }
`;

const PriceText = styled.span`
  display: block;
  font-weight: ${props => props.theme.fontWeight.regular};
  margin-left: auto;
  margin-right: 0;
  font-size: 1rem;
  margin-bottom: 0.25rem;
`;
