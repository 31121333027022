import React, { Component } from 'react';
import MoneyQuestion from "../../components/MoneyQuestion";
import Link from '../../components/Link';
import TA10RadioQuestion from "./TA10RadioQuestion";
import RadioQuestion from '../../components/RadioQuestion';
import { TextareaQuestion } from "../../components/TextQuestion";
import { choices_TA10, choices_TA10_kitchen } from "./TA10Config";
import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media.js';


class BasicTA10Item extends Component {

  constructor(props) {
    super(props);
    this.state = this.initStateFromProps(props);
  }

  initStateFromProps(props) {
    if (props.item.comment) {
      return {
        showAddComment: true
      }
    } else {
      return {
        showAddComment: false
      }
    }
  }

  updateInclusion = inclusionValue => {
    if (inclusionValue === 'None') {
      this.props.onItemUpdate({
        ...this.props.item,
        inclusion: inclusionValue,
        price: '',
        fitted: ''
      })
    } else if (inclusionValue !== 'For sale') {
      this.props.onItemUpdate({
        ...this.props.item,
        inclusion: inclusionValue,
        price: ''
      })
    } else {
       this.props.onItemUpdate({
        ...this.props.item,
        inclusion: inclusionValue,
      })
    }
  }

  updateComment = commentValue => {
    this.props.onItemUpdate({
      ...this.props.item,
      comment: commentValue
    })
  }

  updatePrice = priceValue => {
    this.props.onItemUpdate({
      ...this.props.item,
      price: priceValue
    })
  }

  updateFitted = fittedValue => {
    this.props.onItemUpdate({
      ...this.props.item,
      fitted: fittedValue
    })
  }

  showAddComment = () => {
    this.setState({ showAddComment: true })
  }

  render() {

    const item = this.props.item

    return (
      <TA10RadioQuestion
        item={item}
        removeItem={this.props.removeItem}
        config={this.props.config}
        roomItemsConfig={this.props.roomItemsConfig}
        question={item.itemName}
        hint={item.itemHint}
        name={item.itemName}
        options={choices_TA10}
        selected={item.inclusion || null}
        optionSelected={this.updateInclusion}
        inline={true}
        validationError={this.props.errors.inclusion ? 'Please choose an option' : null}
      >

        {this.props.kitchen && item.inclusion !== 'None' &&
          <StyledRadioQuestion
            question="Is it fitted, or free-standing?"
            name={item.itemName + 'fitted'}
            options={choices_TA10_kitchen}
            selected={item.fitted || null}
            optionSelected={this.updateFitted}
            inline={false}
            validationError={this.props.errors.fitted ? 'Please choose an option' : null}
          />
        }

        {item.inclusion === 'For sale' &&
          <StyledMoneyQuestion
            question="Sale price"
            hint={item.itemName}
            name={item.itemName + 'Price'}
            value={item.price}
            valueEntered={this.updatePrice}
            validationError={this.props.errors.price ? 'Please enter a price' : null}
            optional={false}
            size="small"
          />
        }

        {!this.state.showAddComment && (
          <StyledAddComment>
            <Link onClick={this.showAddComment}>Add a comment</Link>
          </StyledAddComment>
        )}

        {this.state.showAddComment && (
          <StyledTextareaQuestion
            question="Comment"
            name={item.itemName + 'Comments'}
            value={item.comment}
            valueEntered={this.updateComment}
            optional={true}
            rows={3}
          />
        )}

      </TA10RadioQuestion>
    )
  }
}


const StyledRadioQuestion = styled(RadioQuestion)`
  margin-top: 1em;
  margin-bottom: 0.75em;
  ${mediaQueries.md} {
    margin-top: 1.5em;
    margin-bottom: 1em;
  }
`

const StyledMoneyQuestion = styled(MoneyQuestion)`
  margin-bottom: 1.5em;
  ${mediaQueries.md} {
    margin-bottom: 1.6em;
  }
`

const StyledAddComment = styled.p`
  line-height: normal;
  margin-top: 0;
`

const StyledTextareaQuestion = styled(TextareaQuestion)`
  width: 100%;
  ${mediaQueries.md} {
    width: 364px
  }
  margin-bottom: 1.4em;
  ${mediaQueries.md} {
    margin-bottom: 2em;
  }
`

export default BasicTA10Item;
