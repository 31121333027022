import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';

class SignupOtherClientsSubmission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
    };

    const flowState = this.props.signupOtherClientsFlowState;

    fetch('/api/clientSignup', {
      method: 'PUT',
      body: JSON.stringify({
        clientId: flowState.clientId,
        clientName: flowState.clientName.client,
        clientDetails: flowState.clientDetails.client,
        clientAddress: flowState.clientAddress,
      }),
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
            submitted: true,
          });
          props.onFlowComplete();
        } else {
          this.setState({
            error: 'Unable to process your signup',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Unable to process your signup',
          loading: false,
        });
      },
    );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn't work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    if (this.state.submitted) {
      return null;
    }
  }
}

export default SignupOtherClientsSubmission;
