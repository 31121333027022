import * as React from "react";
import TextQuestion from "../../components/TextQuestion";
import ScrollToTop from "../../components/utils/ScrollToTop";
import Card from "../../components/Card";
import Image from "../../components/Image";
import imageCatAndHousePlant from "../../images/cat-and-houseplant.png";
import Error from "../../components/Error";
import Action from "../../components/Action";
import {isEmailValid} from "../../utils/textUtils";

class RemoSecondManagementCompanyDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.managementCompanyDetails,
      errors: {}
    };
  }

  saveManagementCompany = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        secondManagementCompanyDetails: {
          companyName: this.state.companyName,
          emailAddress: this.state.emailAddress,
          phoneNumber: this.state.phoneNumber,
          addressLine1: this.state.addressLine1,
          addressLine2: this.state.addressLine2,
          addressLine3: this.state.addressLine3,
          addressTown: this.state.addressTown,
          addressCounty: this.state.addressCounty,
          addressPostcode: this.state.addressPostcode
        }
      })
    }
  };

  isPhoneNumberValid = phone => {
    const re = /[0-9+() -]+$/;
    return re.test(String(phone));
  };


  handleValidation() {

    const fields = {...this.state};
    let errors = {};
    let formIsValid = true;


    if (!fields.companyName) {
      errors.companyName = true;
      formIsValid = false
    }

    if (!fields.emailAddress && !fields.phoneNumber && !fields.addressLine1) {
      errors.contactDetails = true;
      formIsValid = false
    }

    if (fields.emailAddress && !isEmailValid(fields.emailAddress)) {
      errors.emailAddress = true;
      formIsValid = false
    }

    if (fields.phoneNumber && !this.isPhoneNumberValid(fields.phoneNumber)) {
      errors.phoneNumber = true;
      formIsValid = false
    }

    this.setState({errors});
    return formIsValid
  };

  handleEmailAddressValidation = event => {
    let errors = {...this.state.errors};

    if (event.type !== 'blur') {
      errors.emailAddress = false;
      errors.contactDetails = false;
    } else {
      if (this.state.emailAddress && !isEmailValid(this.state.emailAddress)) {
        errors.emailAddress = true;
      }
    }
    this.setState({errors});
  };

  handlePhoneNumberValidation = event => {
    let errors = {...this.state.errors};

    if (event.type !== 'blur') {
      errors.phoneNumber = false;
      errors.contactDetails = false;
    } else {
      if (this.state.phoneNumber && !this.isPhoneNumberValid(this.state.phoneNumber)) {
        errors.phoneNumber = true;
      }
    }
    this.setState({errors});
  };

  handleNotEmptyFieldValidation = field => event => {
    const errors = {...this.state.errors};
    errors[field] = event.type === 'blur' && !this.state[field];
    this.setState({errors});
  };

  handleInputChange = field => event => {
    this.setState({[field]: event})
  };

  render() {
    return (
      <>

        <ScrollToTop/>
        <Card
          title={'Tell us the contact details for your second management company'}
          removeTopPadding
        >
          {this.state.errors.contactDetails &&
          <Error>
            We need to know at least one of the company’s phone number, email address or postal address
          </Error>
          }

          <TextQuestion
            name={'companyName'}
            question={'Company name'}
            value={this.state.companyName || ''}
            valueEntered={this.handleInputChange('companyName')}
            validate={this.handleNotEmptyFieldValidation('companyName')}
            validationError={
              this.state.errors.companyName
                ? 'Enter the name of your management company'
                : null
            }
          />
          <TextQuestion
            name={'phoneNumber'}
            question={'Phone number'}
            value={this.state.phoneNumber || ''}
            valueEntered={this.handleInputChange('phoneNumber')}
            validate={this.handlePhoneNumberValidation}
            validationError={
              this.state.errors.phoneNumber
                ? 'Please enter a valid phone number'
                : null
            }
          />
          <TextQuestion
            name={'emailAddress'}
            question={'Email address'}
            value={this.state.emailAddress || ''}
            valueEntered={this.handleInputChange('emailAddress')}
            validate={this.handleEmailAddressValidation}
            validationError={
              this.state.errors.emailAddress
                ? 'Please enter a valid email address'
                : null
            }
          />
          <TextQuestion
            question="Address line 1"
            value={this.state.addressLine1 || ''}
            valueEntered={this.handleInputChange('addressLine1')}
          />

          <TextQuestion
            question="Address line 2"
            optional={true}
            value={this.state.addressLine2 || ''}
            valueEntered={this.handleInputChange('addressLine2')}
          />

          <TextQuestion
            question="Address line 3"
            optional={true}
            value={this.state.addressLine3 || ''}
            valueEntered={this.handleInputChange('addressLine3')}
          />

          <TextQuestion
            question="Town"
            optional={true}
            value={this.state.addressTown || ''}
            valueEntered={this.handleInputChange('addressTown')}
          />

          <TextQuestion
            question="County"
            optional={true}
            value={this.state.addressCounty || ''}
            valueEntered={this.handleInputChange('addressCounty')}
          />

          <TextQuestion
            question="Postcode"
            size="small"
            value={this.state.addressPostcode || ''}
            valueEntered={this.handleInputChange('addressPostcode')}
          />

          <Action onClick={this.saveManagementCompany}>Continue</Action>


        </Card>
        <Image
          src={imageCatAndHousePlant}
          fullWidth/>
      </>
    )
  }
}


export default RemoSecondManagementCompanyDetails
