import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';

const numberOfMortgagesOptions = [
  { title: 1, value: 1 },
  { title: 2, value: 2 },
  { title: 3, value: 3 },
];

class SellingNumberOfMortgages extends Component {
  constructor(props) {
    super(props);

    if (this.props.numberOfMortgages) {
      this.state = {
        numberOfMortgages: this.props.numberOfMortgages,
        errors: { ...this.props.errors },
        errorMessages: {
          numberOfMortgages: 'Please choose an option',
        },
      };
    } else {
      this.state = {
        numberOfMortgages: null,
        errors: {},
        errorMessages: {
          numberOfMortgages: 'Please choose an option',
        },
      };
    }
  }

  handleValidation() {
    const fields = { ...this.state };
    const errorMessages = { ...this.state.errorMessages };

    let errors = {};

    let formIsValid = true;

    if (!fields['numberOfMortgages']) {
      const field = 'numberOfMortgages';
      formIsValid = false;
      errors[field] = errorMessages[field];
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    const errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = null;
    } else {
      if (!this.state[field]) {
        errors[field] = errorMessages[field];
      }
    }

    this.setState({ errors });
  };

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  saveSellingNumberOfMortgages = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        numberOfMortgages: this.state.numberOfMortgages,
        errors: this.state.errors,
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Your mortgage" removeTopPadding>
          <RadioQuestion
            question="How many mortgages are there on the property?"
            options={numberOfMortgagesOptions}
            inline={false}
            name="numberOfMortgages"
            selected={this.state.numberOfMortgages}
            optionSelected={this.handleInputChange('numberOfMortgages')}
            validate={this.handleFieldValidation('numberOfMortgages')}
            validationError={
              this.state.errors.numberOfMortgages
                ? this.state.errors.numberOfMortgages
                : null
            }
          />

          <Action onClick={this.saveSellingNumberOfMortgages}>Continue</Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default SellingNumberOfMortgages;
