import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import {useLocation, useParams} from 'react-router-dom';
import PageLayout from '../../components/PageLayout';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';
import { SKU_MAP } from './SkuMap';

const RedirectToStripeCheckout = (props) => {

  const location = useLocation()
  const { success } = useParams();
  const queryParams = queryString.parse(location.search);
  const [error, setError] = useState(null);

  const STRIPE_PUBLIC_KEY = window.appConfig.stripe_public_key

  useEffect(() => {
    const loadStripe = (callback) => {
      var aScript = document.createElement('script');
      aScript.type = 'text/javascript';
      aScript.src = 'https://js.stripe.com/v3/';
      document.head.appendChild(aScript);
      aScript.onload = callback;
    };

    const handleStripeResponse = (res) => {
      if (res.success) {
        const stripe = window.Stripe(STRIPE_PUBLIC_KEY);
        stripe
          .redirectToCheckout({
            sessionId: res.session_id,
          })
          .then(function(result) {
            if (result.error) {
              setError(result.error.message);
            }
          });
      } else {
        setError(res.error);
      }
    };

    if (typeof queryParams.req != 'undefined') {
      const requestId = queryParams.req;
      loadStripe(() => {
        fetch('/api/clientPaymentRequest/' + requestId)
          .then((res) => res.json())
          .then(handleStripeResponse)
          .catch((res) => setError(res.error));
      });
    } else if (typeof queryParams.ref == 'undefined') {
      setError('Incorrect payment url');
    } else {
      loadStripe(() => {
        const stripe = window.Stripe(STRIPE_PUBLIC_KEY);
        var paymentId;
        var paymentSku;

        if ('product' in queryParams) {
          paymentSku = queryParams.product;
        } else {
          // Default to £250 up-front fee if no payId is set
          if (typeof queryParams.payId == 'undefined') {
            paymentSku = 'sku_FwsX1BlDTfkAnZ'; // Note: this sku is for £250 up-front fee
          } else {
            paymentId = queryParams.payId;
            paymentSku = SKU_MAP.get(paymentId);
          }
        }

        stripe
          .redirectToCheckout({
            items: [{ sku: paymentSku, quantity: 1 }],
            successUrl: 'https://client.juno.legal/payment/true',
            cancelUrl: 'https://client.juno.legal/payment/false',
            billingAddressCollection: 'required',
              clientReferenceId: queryParams.ref,
          })
          .then(function(result) {
            if (result.error) {
              setError(result.error.message);
            }
          });
      });
    }
  }, [queryParams, STRIPE_PUBLIC_KEY]);

    if (typeof success == 'undefined') {
      if (error) {
        return(
          <PageLayout>
            <Card title="That didn't work" removeTopPadding>
              <p>
                There has been an error: {error}
              </p>
            </Card>
          </PageLayout>
        )
      } else {
        return (
          <PageLayout>
            <Card title="Redirecting" removeTopPadding>
                <p>Redirecting to payment screen...</p>
            </Card>
          </PageLayout>
        )
      }
    } else {
      if (success === 'true') {
        return (
          <PageLayout>
            <ScrollToTop />
            <Card title="Thank you!" removeTopPadding>
              <p>
                You’ll get a receipt for your payment from Stripe in the next
                few minutes.
              </p>
            </Card>

            <Card title="What happens next" removeTopPadding>
              <p>
                As soon as we’ve checked the property details we’ll order your
                searches. The information in these will tell us more about the
                condition of the property. We’ll share this with you as soon as
                we can.
              </p>
              <p>
                In the meantime we’ll send you a letter with our bank details.
                You’ll need to keep this so you can send us the deposit for your
                property as we get nearer to exchange.
              </p>
              <p>
                We won’t share our bank details online or over the phone. If
                anyone gets in touch to change these please tell us straight
                away.
              </p>
            </Card>
          </PageLayout>
        );
      } else {
        return (
          <PageLayout>
          <Card title="Payment cancelled" removeTopPadding>
            <p>To try again, click the link in the email.</p>
          </Card>
          </PageLayout>
        );
      }
    }
}

export default RedirectToStripeCheckout;
