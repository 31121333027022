import React from 'react';
import PropTypes from 'prop-types';
import StyledTodoListItem, { TodoLink, TodoText, TodoDate } from './StyledTodoListItem'
import { getFormattedDate } from "../../utils/textUtils";

function ListDoable(props) {

  if (props.items.length === 0) {
    return null;
  }

  return (
    <>
      {props.items.map(doable => {
        let complete = doable.status === 'complete';

        const doableTypes = ['information_request', 'emailed_request', 'document_request'];
        const isClientAppForm = !(doableTypes.includes(doable.doable_type))

        return <StyledTodoListItem
          key={doable.id}
          complete={complete}
          clickable={props.clickable}
        >

          {isClientAppForm && props.clickable &&
          <TodoLink
            to={`/requests/${doable.doable_type}/${doable.id}`}
            state={{ fromDashboard: true }}>
              <TodoText clickable={true}>{doable.title}</TodoText>
            </TodoLink>
          }

          {!isClientAppForm && props.clickable &&
            <TodoLink
              to={`/dashboard/doable/${doable.id}`}>
              <TodoText clickable={true}>{doable.title}</TodoText>
            </TodoLink>
          }

          {!props.clickable &&
            <TodoText clickable={false}>{doable.title}</TodoText>
          }

          {doable.completed_at && (
            <TodoDate>{getFormattedDate(doable.completed_at)}</TodoDate>
          )}
        </StyledTodoListItem>
      })}
    </>
  )
}

ListDoable.propTypes = {
  items: PropTypes.array.isRequired,
  clickable: PropTypes.bool,
};

ListDoable.defaultProps = {
  clickable: true,
};

export default ListDoable;
