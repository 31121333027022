import React from 'react';

function SvgIconWrapper(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}>
      <path
        fill={props.fill}
        d={props.path}
      />
      {props.path2 && (
        <path
          fill={props.fill2 ? props.fill2 : props.fill}
          d={props.path2}
        />
      )}
    </svg>
  );
}

export default SvgIconWrapper;
