import styled from 'styled-components/macro';

const DescriptionList = styled.dl`
  margin-bottom: 1em;
`;

export const DescriptionListTerm = styled.dt`
  display: block;
  color: ${props => props.theme.color.junoTextSecondary};
`;

export const DescriptionListDetail = styled.dd`
  margin-left: 0;
  margin-bottom: 0.5em;
`;

export default DescriptionList;
