import React from 'react';
import { Link } from 'react-router-dom';

import PageLayout from '../../components/PageLayout';
import Action from '../../components/Action';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

const RemoLeaseholdThanks = ({ fromDashboard }) => {

  return (
    <PageLayout>
      <ScrollToTop />
      <Card title="That’s it, thanks!" removeTopPadding>
        <p>Thanks for telling us more about your property.</p>
        <p>
          If there’s anything else we need to check with you, we’ll send you
          an email soon.
        </p>
        {fromDashboard && (
          <Action to="/dashboard" as={Link}>
            Take me back to my dashboard
          </Action>
        )}
      </Card>
      <Image src={imageOfficeWorking} fullWidth />
    </PageLayout>
  );
}

export default RemoLeaseholdThanks;
