// data-testids use kebab-case
// data-testids are used by Cypress and React Testing Library
export const createDataTestId = (prefix, group, item) => {
  let formatGroup;
  let formatItem;
  let dataTestId;

  const formatToKebab = str =>
    str
      .replace(',', '')
      .replace(/’/g, '')
      .replace(/\//g, '')
      .replace("'", '')
      .replace('?', '')
      .replace('(', '')
      .replace(')', '')
      .replace('...', '')
      .replace(/\s/g, '-')
      .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
      .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
      .toLowerCase();

  formatGroup = formatToKebab(`${group}`);

  if (!item) {
    dataTestId = prefix + '-' + formatGroup;
  } else {
    formatItem = formatToKebab(`${item}`);
    dataTestId = prefix + '-' + formatGroup + '-' + formatItem;
  }

  return dataTestId;
};

// IDs use camelCase
// Use IDs to associate labels and inputs e.g.
// <label for={componentId}> <input id={componentId}>
export const createId = (prefix, group, item) => {
  let componentId = createDataTestId(prefix, group, item);

  const snakeToCamel = str =>
    str.replace(/([-_]\w)/g, g => g[1].replace('-', '').toUpperCase());

  componentId = snakeToCamel(componentId);
  return componentId;
};
