import React, { useState, useEffect } from 'react';
import { useParams, Link, Navigate } from "react-router-dom";
import PageLayout from '../../components/PageLayout';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';

function LoginCode() {
  const { loginId } = useParams()

  const [state, setState] = useState({
    loading: true,
    error: null,
    redirect: false,
  });

  useEffect(() => {
    const url = "/api/login/token/" + loginId;

    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'same-origin'
        });
        const data = await response.json();
        if (data.status === 'success') {
          setState(prevState => ({
            ...prevState,
            loading: false,
            redirect: true,
          }));
        } else if (data.status === 'invalid_token') {
          setState(prevState => ({
            ...prevState,
            loading: false,
            error: 'This link is invalid',
          }));

        } else if (data.status === 'already_used') {
          setState(prevState => ({
            ...prevState,
            loading: false,
            error: "It looks like you've already used this link",
          }));
        } else if (data.status === 'expired') {
          setState(prevState => ({
            ...prevState,
            loading: false,
            error: "This link has expired",
          }));
        } else if (data.status === 'failed') {
          setState(prevState => ({
            ...prevState,
            loading: false,
            error: "Failed to load login details.",
          }));
        }
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loading: false,
          error: "Failed to load login details.",
        }));
      }
    };

    fetchData();

  }, [loginId]);

  if (state.loading) {
    return null;
  }

  if (state.error) {
    return (
      <PageLayout>
        <ScrollToTop />
        <Card
          title="Sorry, that didn't work"
          removeTopPadding
          headingSize="1"
        >
          <p>{state.error}. You'll need a new link.</p>

          <p>
            Try <Link to="/login">signing in</Link> again to get one.
          </p>
        </Card>
      </PageLayout>
    );
  }

  if (state.redirect) {
    return <Navigate to="/dashboard" />;
  }
}

export default LoginCode;
