import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSelectedCaseRef } from "./utils/hooks";

import { ArrowBackIos } from '@styled-icons/material';

import {mediaQueries} from '../../styles/media.js';
import styled from "styled-components/macro";

const StyledDashboardNav = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  background-color: ${props => props.theme.color.junoBrand};
  color: ${props => props.theme.color.junoHeader};
`

export const NavList = styled.ol`
  list-style-type: none;
  padding: 0;
  display: block;

  color: #fff;
  overflow: hidden;

  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;

  ${mediaQueries.md} {
    margin-left: 45px;
    margin-right: 45px;
  }

  ${mediaQueries.lg} {
    width: 930px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const NavListItem = styled.li`
  display: flex;

  svg,
  a{
    border-bottom: 1px solid transparent;
  }

  a:hover {
    color: #fff;
    cursor: pointer;
    border-bottom: 1px solid;
  }

  a:visited {
    color: #fff;
  }
`

export const NavListIcon = styled(ArrowBackIos)`
  width: ${props => props.theme.iconSize['xs']};
`

export const DashboardNavItem = props => {
  if (props.item.linkable) {
    return (
      <NavListItem>
        <NavListIcon />
        <Link to={props.item.link}>
          {props.item.text}
        </Link>
      </NavListItem>
    )
  }
  return (
    <NavListItem>
      {props.item.text}
    </NavListItem>
  )
}

const DashboardNav = () => {
  const location = useLocation();
  const caseRef = useSelectedCaseRef();
  const lastActiveCaseScreen = useSelector(state => state.dashboardState.lastActiveCaseScreen);
  const cases = useSelector(state => state.dashboardState.cases ?? []);

  // Build the list of candidate links.
  let navList = []

  // Back to case archive if we have a case in scope and the archive screen was active.
  if (!! caseRef && lastActiveCaseScreen === "archive") {
    navList.push({
      text: "Back to things you’ve done",
      linkable: true,
      link: `/dashboard/${caseRef}/things-youve-done`,
    });
  }

  // Back to case page if we have a case in scope.
  if (!! caseRef) {
    navList.push({
      text: "Back to your dashboard",
      linkable: true,
      link: `/dashboard/${caseRef}`,
    });

  } else if (cases.length === 1) {
    // Fallback route back when there's just one case - as the case list link below won't be shown.
    navList.push({
      text: "Back to your dashboard",
      linkable: true,
      link: `/dashboard/${cases[0].case_reference}`,
    });
  }

  // Link to case list if there are multiple cases.
  if (cases.length > 1) {
    navList.push({
      text: "Back to your cases",
      linkable: true,
      link: '/dashboard',
    })
  }

  // Pick the first one that's not the current URL.
  const activeNavItem = navList.find(item => location.pathname !== item.link);

  if (! activeNavItem) {
    return null;
  }

  return (
    <StyledDashboardNav>
      <NavList>
        <DashboardNavItem item={activeNavItem} />
      </NavList>
    </StyledDashboardNav>
  )
}

export default DashboardNav
