import React, { Component } from 'react';
import styled from 'styled-components/macro';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { getFormattedDate } from "../../utils/textUtils";
import { TextareaQuestion } from "../../components/TextQuestion";
import FileUploadQuestion from '../../components/FileUploadQuestion';
import Confirmation from '../../components/Confirmation';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import Action from '../../components/Action';
import Button from '../../components/Button';
import Card from '../../components/DashboardCard';
import { AttachFile } from '@styled-icons/material';
import { StyledEnquiryDoablePageHeader, StyledEnquiryContent, StyledJunoEnquiryActivity, StyledJunoEnquiryHeading, StyledEnquiryActivity, StyledEnquiryHeading, StyledJunoEnquiry, StyledAttachmentList } from "./EnquiryDoableStyles"

class EnquiryDoable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      response: '',
      documents: [],
      errors: {},
      showResponseForm: this.props.doable.status === "available", // If it's available, it needs a reply
      showUpload: false,
      uploading: false,
      submitted: false,
      failed: false,
    }
  }

  componentDidMount() {
    if (this.props.submissionEnabled && this.props.doable.status === "available_to_read") {
      // Mark it as read.
      fetch(`/api/dashboard/doable/${this.props.doable.id} `, {
         method: 'PUT',
         credentials: 'same-origin',
         body: JSON.stringify({
           enquiry_update: {mark_as_read: true},
         }),
      })
    }
  }

  createContent(content) {
    return { __html: content };
  }

  handleResponseChange = r => {
    this.setState({ response: r });
  };

  handleUploadsChanged = uploadIds => {
    this.setState({ documents: uploadIds });
  };

  handleUploadInProgress = inProgress => {
    this.setState({ uploading: inProgress });
  };

  handleValidation() {
    const response = this.state.response;
    let formIsValid = true;
    let errors = {};

    if (!response || ! response.trim()) {
      formIsValid = false;
      errors['response'] = true;
    }

    this.setState({ errors });
    return formIsValid;
  }

  submitResponse = event => {
    event.preventDefault();

    if (! this.handleValidation()) {
      return;
    }

    const responseData = {
      enquiry_update: {
        client_reply: this.state.response,
      },
      uploaded_documents: this.state.documents.map(upload_id => (
        {identifier: "enquiry_response", upload_id: upload_id}
      )),
    };

    fetch(`/api/dashboard/doable/${this.props.doable.id} `, {
       method: 'PUT',
       credentials: 'same-origin',
       body: JSON.stringify(responseData),
    }).then(res => {
      if (! res.ok) {
        throw new Error("Non-success response");
      }
      this.setState({
        submitted: true,
      });
    }).catch(err => {
      this.setState({
        failed: true,
      });
    });
  };

  showResponseForm = () => {
    this.setState({ showResponseForm: true });
  }

  showUpload = () => {
    this.setState({ showUpload: true })
  }

  submitDisabled = () => {
    return !this.props.submissionEnabled || !this.state.response || this.state.uploading;
  }

  conversationIncludingSubmission = () => {
    let conversation = this.props.doable.configuration.conversation || [];
    if (!this.props.doable.submission || ! this.props.doable.submission.enquiry_update.client_reply) {
      // No submission present, return the conversation unchanged.
      return conversation;
    }

    const lastUpdateFromClient = conversation.slice().reverse().find(c => c.type === "update_from_client");
    if (
      !! lastUpdateFromClient &&
      new Date(lastUpdateFromClient.created_at) >= new Date(this.props.doable.submission.submitted_at)
    ) {
      // Conversation already includes the submission update.
      return conversation;
    }

    // Submission not present in the conversation, so synthesise an entry for it.
    return [
      ...conversation,
      {
        type: "update_from_client",
        created_at: this.props.doable.submission.submitted_at,
        content: this.props.doable.submission.enquiry_update.client_reply,
        attachments: this.props.doable.submission.uploaded_documents || [],
      },
    ];
  }

  render() {

    if (!this.props.doable) return null;

    if (this.state.failed) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card
            title="Sorry, that didn't work"
            removeTopPadding
            headingSize="2"
          >
            <p>Failed to submit response.</p>
            <Action small onClick={this.props.returnToDashboard}>
              Take me back to try again
            </Action>
          </Card>
        </React.Fragment>
      );
    }

    if (this.state.submitted) {
      return (
        <>
          <ScrollToTop />
          <Confirmation title="Thanks! We’ve got your reply">
            <p>
              As soon as we need anything else we’ll drop you an
              email with a link to your dashboard.
            </p>
            <Action small onClick={this.props.returnToDashboard}>
              Take me back to my dashboard
            </Action>
          </Confirmation>
          <Image src={imageOfficeWorking} fullWidth={true} />
        </>
      )
    }

    let confirmationText;
    if (this.props.doable.status === "complete") {
      if (this.props.doable.submission && this.props.doable.submission.enquiry_update.mark_as_read) {
        confirmationText = "We’ve got everything we need right now"
      } else {
        confirmationText = "Thanks for answering our question - we’ve got your reply"
      }
    }

    let addResponseText;
    if (this.props.doable.status === "complete" &&
      this.props.doable.submission && !this.props.doable.submission.enquiry_update.mark_as_read) {
      // Was submitted with a response
      addResponseText = "Send another reply to Juno";
    } else {
      // Is/was a notification update, so no response was previously submitted
      addResponseText = "Send a reply to Juno";
    }
    const showAddReplyControl = this.props.submissionEnabled && ! this.state.showResponseForm;

    return (
      <React.Fragment>
        <ScrollToTop />
        <StyledEnquiryDoablePageHeader>
          {this.props.doable.title && (this.props.doable.title)}
        </StyledEnquiryDoablePageHeader>

        {this.props.doable.status === "complete" &&
          <Confirmation
            title={confirmationText}
            submission={true} />
        }

        <StyledJunoEnquiryActivity>
          {/* This is generic text, per transaction type - about the enquiry  */}
          {/* For example, we'd like your help answering the question below */}
          {this.props.doable.description &&
            <StyledJunoEnquiryHeading dangerouslySetInnerHTML={this.createContent(this.props.doable.description)} />
          }

          {/* This is the original enquiry  */}
          {this.props.doable.configuration.question &&
            <StyledEnquiryContent dangerouslySetInnerHTML={this.createContent(this.props.doable.configuration.question)} />
          }

          {this.props.doable.configuration.attachments && (
            <StyledAttachmentList>
              {this.props.doable.configuration.attachments.map((file, index) => {
                return (
                  <li key={`enquiry_attachment_${index}`}>
                    <AttachFile size="18" /><a href={file.link} target="_blank">{file.display_name}</a>
                  </li>
                )
              })}
            </StyledAttachmentList>
          )}
        </StyledJunoEnquiryActivity>

        {/* Provide all the context of the conversation */}

        {this.conversationIncludingSubmission().map((update, index, conversation) => {

          const isLastItem = !!(conversation.length - 1 === index)

          if (update.type === "update_to_client") {
            return (
              <StyledJunoEnquiryActivity key={`update_to_client_${index}`} data-testid={`update_to_client_${index}`}>
                <StyledJunoEnquiryHeading>
                  Juno {update.needs_reply ? 'asked' : 'said'}: <span>{getFormattedDate(update.created_at)}</span></StyledJunoEnquiryHeading>
                <StyledEnquiryContent>{update.content}</StyledEnquiryContent>

                {update.attachments && (
                  <StyledAttachmentList>
                    {update.attachments.map((file, index) => {
                      return (
                        <li key={`update_to_client_file_${index}`}>
                          <AttachFile size="18" /><a href={file.link} target="_blank">{file.display_name}</a>
                        </li>
                      )
                    })}
                  </StyledAttachmentList>
                )}

                {isLastItem && showAddReplyControl && (
                  <StyledEnquiryContent>
                    <StyledAddItem onClick={this.showResponseForm}>
                      <StyledText>{addResponseText}</StyledText>
                    </StyledAddItem>
                  </StyledEnquiryContent>
                )}

              </StyledJunoEnquiryActivity>
            )
          }

          if (update.type === "update_from_client") {
            return (
              <StyledEnquiryActivity key={`update_from_client_${index}`}>
                <StyledEnquiryHeading>
                  You replied:
                  {update.created_at &&
                    <span>{getFormattedDate(update.created_at)}</span>
                  }
                </StyledEnquiryHeading>
                <StyledEnquiryContent>{update.content}</StyledEnquiryContent>

                {update.attachments && (
                  <StyledAttachmentList>
                    {update.attachments.map((file, index) => {
                      return (
                        <li key={`update_from_client_file_${index}`}>
                          {file.link && (<>
                            <AttachFile size="18" /><a href={file.link} target="_blank">{file.filename}</a>
                          </>) || (<>
                            <AttachFile size="18" />{file.filename}
                          </>)}
                        </li>
                      )
                    })}
                  </StyledAttachmentList>
                )}

                {isLastItem && showAddReplyControl && (
                  <StyledEnquiryContent>
                    <StyledAddItem onClick={this.showResponseForm}>
                      <StyledText>{addResponseText}</StyledText>
                    </StyledAddItem>
                  </StyledEnquiryContent>
                )}

              </StyledEnquiryActivity>
            )
          }

        })}

        {this.state.showResponseForm && (
          <StyledEnquiryActivity>
            <StyledEnquiryHeading>Your reply</StyledEnquiryHeading>
            <StyledEnquiryContent>

              <TextareaQuestion
                question="Enter your response below"
                value={this.state.response}
                valueEntered={this.handleResponseChange}
                validationError={this.state.errors.response ? 'Please enter a response' : null}
                disabled={!this.props.submissionEnabled}
              />

              {this.props.submissionEnabled && ! this.state.showUpload && (
                <StyledAddItem
                  onClick={this.showUpload}>
                  <StyledIcon><AttachFile size="24" /></StyledIcon>
                  <StyledText>Attach a document</StyledText>
                </StyledAddItem>
              )}

              {this.state.showUpload && (
                <FileUploadQuestion
                  question='Attach documents to your reply'
                  multiple={true}
                  onUploadsChanged={this.handleUploadsChanged}
                  onUploadInProgress={this.handleUploadInProgress}
                />
              )}

              <StyledActions>
                <Button onClick={this.submitResponse} disabled={this.submitDisabled()}>
                  Save your reply
                </Button>
              </StyledActions>

            </StyledEnquiryContent>
          </StyledEnquiryActivity>
        )}
      </React.Fragment>
    )

  }
}

export default EnquiryDoable


const StyledActions = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;

  Button {
    margin-right: 0.8em;
    font-size: 16px;
  }
`

export const StyledAddItem = styled.div`
  margin-top: -0.5em;
  color: ${props => props.theme.color.junoLink};
  width: fit-content;
  display: flex;
  align-items: center;

  &:hover {
    border-bottom: 0;
  }
`

export const StyledIcon = styled.div`
  svg {
    fill: ${props => props.theme.color.junoLink}
  }

  &:hover {
    svg {
      fill: ${props => props.theme.color.junoLinkHover}
    }
  }
`

export const StyledText = styled.div`
  margin-left: 4px;
  font-size: 16px;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom-color: ${props => props.theme.color.junoLinkHover}
  }
`
