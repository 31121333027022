import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import TextQuestion from '../../components/TextQuestion';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import { TENURE_OPTIONS, YES_NO_OPTIONS, } from '../../constants/index';
import ReferralCodeBanner from '../../components/ReferralCodeBanner';

class BrokerQuoteRemortgagingProperty extends Component {
  constructor(props) {
    super(props);

    if (this.props.remortgagingDetails) {
      const rd = this.props.remortgagingDetails;

      this.state = {
        mortgageAmount: this.formatMoneyWithCurrency(rd.mortgageAmount),
        tenure: rd.tenure,
        clientIsCompany: rd.clientIsCompany,
        isBuyToLet: rd.isBuyToLet,
        errors: {},
      };

    } else {
      this.state = {
        mortgageAmount: '',
        tenure: null,
        clientIsCompany: null,
        isBuyToLet: null,
        errors: {},
      };
    }
  }

  selectTenure = t => {
    this.setState({ tenure: t });
  };

  mortgageAmountEntered = p => {
    this.setState({ mortgageAmount: p });
  };

  selectClientIsCompany = c => {
    this.setState({ clientIsCompany: c });
  };

  selectIsBuyToLet = btl => {
    this.setState({ isBuyToLet: btl });
  };

  formatMoneyWithCurrency = p => {
    let formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    });

    let moneyWithCurrency = formatter.format(p);

    if (moneyWithCurrency.includes('.00')) {
      let i = moneyWithCurrency.indexOf('.');
      return moneyWithCurrency.substring(0, i);
    }

    return moneyWithCurrency;
  };

  normalisePrice = p => {
    const stripCharsRegex = /[£, ]/g;
    return p.replace(stripCharsRegex, '');
  };

  isPriceValid = price => {
    const allowedCharsRegex = /^[0-9£,. ]+$/;
    if (allowedCharsRegex.test(String(price))) {
      const normalised = this.normalisePrice(price);

      const decimalPlaceRegex = /^\d+\.?\d{0,2}$/;

      return decimalPlaceRegex.test(String(normalised));
    }
    return false;
  };

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  handlePriceFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field] || !this.isPriceValid(this.state[field])) {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  handleValidation = () => {
    const fields = { ...this.state };
    let errors = {};
    let formIsValid = true;

    if (fields['mortgageAmount'] == null || !this.isPriceValid(fields['mortgageAmount'])) {
      errors['mortgageAmount'] = true;
      formIsValid = false;
    }

    if (fields['tenure'] == null) {
      errors['tenure'] = true;
      formIsValid = false;
    }

    if (fields['clientIsCompany'] == null) {
      errors['clientIsCompany'] = true;
      formIsValid = false;
    }

    if (fields['isBuyToLet'] == null) {
      errors['isBuyToLet'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  };

  saveSelection = () => {
    if (this.handleValidation()) {
      this.props.onStepComplete({
        remortgagingDetails: {
          tenure: this.state.tenure,
          price: this.normalisePrice(this.state.mortgageAmount), // price is used in the generic reject quote check
          // This has been replaced with mortgageAmount in the quote details page
          // propertyValue: this.normalisePrice(this.state.propertyValue),
          mortgageAmount: this.normalisePrice(this.state.mortgageAmount), // but we actually price based on the mortgage
          clientIsCompany: this.state.clientIsCompany,
          isBuyToLet: this.state.isBuyToLet,
        }
      });
    }
  };

  render() {
    return (
      <>
        <ScrollToTop />
        <Card title="Tell us about your client’s remortgage" removeTopPadding>

          <TextQuestion
            question="How much are they borrowing?"
            hint="If you’re not sure just put an estimate – you can change it later"
            value={this.state.mortgageAmount}
            valueEntered={this.mortgageAmountEntered}
            validate={this.handlePriceFieldValidation('mortgageAmount')}
            validationError={
              this.state.errors.mortgageAmount ? 'Please enter a valid price' : null
            }
          />

          <RadioQuestion
            question="Is the property:"
            options={TENURE_OPTIONS}
            name="tenure"
            selected={this.state.tenure}
            optionSelected={this.selectTenure}
            validate={this.handleFieldValidation('tenure')}
            validationError={
              this.state.errors.tenure ? 'Choose an option' : null
            }
          />

          <RadioQuestion
            question='Do they own the property as a limited company?'
            options={YES_NO_OPTIONS}
            inline={true}
            name="clientIsCompany"
            selected={this.state.clientIsCompany}
            optionSelected={this.selectClientIsCompany}
            validate={this.handleFieldValidation('clientIsCompany')}
            validationError={
              this.state.errors['clientIsCompany']
                ? 'Please choose an option'
                : null
            }
          />

          <RadioQuestion
            question='Is this a buy-to-let remortgage?'
            options={YES_NO_OPTIONS}
            inline={true}
            name=""
            selected={this.state.isBuyToLet}
            optionSelected={this.selectIsBuyToLet}
            validate={this.handleFieldValidation('isBuyToLet')}
            validationError={
              this.state.errors['isBuyToLet']
                ? 'Please choose an option'
                : null
            }
          />

          {!!this.props.referral &&
            <ReferralCodeBanner text={this.props.referral.discountMessage} small={true} />
          }

          <Action onClick={this.saveSelection}>Continue</Action>
        </Card>

        <Image src={imageCatAndHousePlant} />
      </>
    );
  }
}

export default BrokerQuoteRemortgagingProperty;
