import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import List, { ListItem } from '../../components/List';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class Inheritance extends Component {

  continue = () => {
    this.props.onStepComplete()
  }

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Inheritance - a gift of money from someone who has died" removeTopPadding>
          <p>
            We need you to email the law firm that handled the probate (cc'ing in hello@juno.legal) – and ask them to confirm the inheritance and how much you received as a result.
          </p>
          <p>
            If the estate administration was not handled by a law firm, we need you to email us:
          </p>
          <List bullet>
            <ListItem>
              A copy of the Will showing your inheritance
            </ListItem>
            <ListItem>
              A copy of the estate accounts (this can just be a simple spreadsheet) showing the amount paid to you
            </ListItem>
          </List>
          <p>
            We’ll send a reminder of what we need to your dashboard.
          </p>
          <Action onClick={this.continue}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default Inheritance;
