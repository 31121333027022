
export function enableFrontChatForClient(client) {

  if (!!client.front_chat_user_hash && !window.frontChatEnabled) {

    window.FrontChat('init', {
      email: client.email_address,
      name: client.first_name + ' ' + client.last_name,
      userHash: client.front_chat_user_hash,
      chatId: window.appConfig.front_chat_id,
      useDefaultLauncher: true,
    });

    window.frontChatEnabled = true;
  }
}

export function hideFrontChatWidget() {

  if (!!window.frontChatEnabled && !!window.FrontChat) {
    window.FrontChat('shutdown');
    window.frontChatEnabled = false;
  }
}