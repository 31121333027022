import React, { Component } from 'react';
import Action from '../../components/Action';
import Card from '../../components/Card';
import Image from '../../components/Image';
import List, { ListItem } from "../../components/List";
import imageOfficeWorking from '../../images/office-working.png';
import ScrollToTop from '../../components/utils/ScrollToTop';

class TA10Intro extends Component {

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about fixtures, fittings and contents" removeTopPadding>
          <p>
            Use this form to let your buyer know about items you’ll:
          </p>
          <List bullet>
            <ListItem>
              include in the sale price
            </ListItem>
            <ListItem>
              offer to sell to your buyer for an additional fee
            </ListItem>
            <ListItem>
              not include in the sale because they aren’t contained in your property
            </ListItem>
          </List>
          <p>
            Once you’ve answered all the questions, we’ll put them on a form for you to approve.
          </p>
          <p>
            When you’ve approved the form, we’ll share it with your buyer’s lawyer.
          </p>

          <Action onClick={() => this.props.onStepComplete({})}>
            Let’s get started
          </Action>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default TA10Intro;
