import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { mediaQueries } from '../styles/media.js';
import Header from './Header';
import Footer from './Footer';

const MainContent = styled.main`
  display: block;

  ${mediaQueries.md} {
    padding-bottom: ${props => props.theme.junoImageSpacing['md']};
    padding-top: ${props => props.pageWidth === 'narrow' ? '40px' : '30px'};
  }

  background-color: ${props => props.pageWidth === 'narrow' ? 'transparent' : props.theme.color.offWhite};
  ${props => props.background === 'white' ? 'background-color: white;' : null}
`;

const MainContentInner = styled.div`
  position: relative;
  background: white;
  ${props => props.pageWidth === 'narrow' ? 'background: transparent;' : null}

  max-width: ${props => (props.theme.pageWidth[props.pageWidth]/16)}rem;

  ${mediaQueries.md} {
    background: transparent;
    margin-left: ${props => props.pageWidth === 'narrow' ? 'auto' : props.theme.spacingScale[12]};
    margin-right: ${props => props.pageWidth === 'narrow' ? 'auto' : props.theme.spacingScale[12]};
  }

  @media(min-width: ${props => (props.theme.pageWidth[props.pageWidth] + props.theme.gridSpacing.columnWidth * 2) / 16}rem) {
    margin-left: auto;
    margin-right: auto;
  }
`;

class PageLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Header pageWidth={this.props.pageWidth} showLogout={this.props.showLogout} />
        <MainContent pageWidth={this.props.pageWidth} background={this.props.background}>
          <MainContentInner pageWidth={this.props.pageWidth}>
            {this.props.children}
          </MainContentInner>
        </MainContent>
        <Footer pageWidth={this.props.pageWidth} reversed />
      </>
    )
  }
}

export default PageLayout;

PageLayout.propTypes = {
  pageWidth: PropTypes.oneOf(['narrow', 'regular', 'wide', 'wider']),
  showLogout: PropTypes.bool
};

PageLayout.defaultProps = {
  pageWidth: 'regular',
  showLogout: false
};
