import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';

class SellingMortgagesAndLoansSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };

    const flowState = this.props.sellingMortgagesAndLoansFlowState;

    fetch('/api/sellingMortgagesAndLoans', {
      method: 'PUT',
      body: JSON.stringify({
        formRequestId: flowState.formRequestId,

        numberOfMortgages: flowState.numberOfMortgages,
        sellingMortgage1: flowState.mortgage1,
        sellingMortgage2: flowState.mortgage2,
        sellingMortgage3: flowState.mortgage3,

        numberOfLoans: flowState.numberOfLoans,
        sellingLoan1: flowState.loan1,
        sellingLoan2: flowState.loan2,
      }),
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
            submitted: true,
          });
          props.onFlowComplete();
        } else {
          this.setState({
            error: 'Unable to process your details',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Unable to process your details',
          loading: false,
        });
      },
    );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn't work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    if (this.state.submitted) {
      return null;
    }
  }
}

export default SellingMortgagesAndLoansSubmission;
