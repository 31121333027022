import React from 'react';
import styled, { css } from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const JunoDetails = styled.details`
  display: block;
  clear: both;
  margin-bottom: 1rem;
  ${mediaQueries.md} {
    margin-bottom: 1.75rem;
  }
  max-width: 38rem;
`;

const JunoDetailsSummary = styled.summary`
  display: list-item;
  width: fit-content;

  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: normal;
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
  }
  color: ${props => props.theme.color.junoLink};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.color.junoLinkHover};
  }

  &:focus {
    outline: 3px solid ${props => props.theme.color.junoFocus};
  }

  ${props =>
    props.boldSummary &&
    css`
      font-weight: ${props => props.theme.fontWeight.bold};
    `}
`;

const JunoDetailsText = styled.div`
  margin-top: 0.5em;
`;

function Details(props) {
  return (
    <JunoDetails className={props.className}>
      <JunoDetailsSummary boldSummary={props.boldSummary}>
        {props.summary}
      </JunoDetailsSummary>
      <JunoDetailsText>{props.children}</JunoDetailsText>
    </JunoDetails>
  );
}

export default Details;
