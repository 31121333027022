import React from 'react';
import PageLayout from '../../components/PageLayout';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';

const LinkExpired = () => {
  return (
    <PageLayout>
      <ScrollToTop />
      <Card title="Link expired" removeTopPadding headingSize="1">
        <p>
          The link you clicked has expired. To view your dashboard you will need
          to get a new link.
        </p>

        <p>
          Try <Link to="/login">signing in</Link> again
        </p>
      </Card>
    </PageLayout>
  );
};

export default LinkExpired;
