import React, { Component } from 'react';
import Action from '../../components/Action';
import Card from '../../components/DashboardCard';
import Confirmation from '../../components/Confirmation';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import PageHeader from '../../components/PageHeader';
import QuestionDescription from '../../components/QuestionDescription';
import ReviewTable from '../../components/ReviewTable';
import ReviewText from '../../components/ReviewText';
import RenderQuestion, { isFieldQuestionVisible } from "./utils/RenderQuestion";
import ReviewQuestion from "./utils/ReviewQuestion";
import ScrollToTop from "../../components/utils/ScrollToTop";
import {
  isDateInThePastValid,
  isDayValid,
  isEmailValid,
  isMonthValid,
  isTextValid,
  isYearValid
} from "../../utils/textUtils";
import { formatPenceNumberString, isMoneyValid, normaliseMoney } from "../../utils/moneyUtils";
import { isPhoneNumberValid } from "../../utils/numberUtils";


const field_type_validators = {
  "integer": value => {
    return !(Number.isInteger(parseFloat(value)) || value === "");
  },
  "year": value => {
    return !(isYearValid(value));
  },
  "text": value => {
    return !(isTextValid(value));
  },
  "text_area": value => {
    return !(isTextValid(value));
  },
  "postcode": value => {
    return !(isTextValid(value));
  },
  "money": value => {
    return !(isMoneyValid(value) || value === "");
  },
  "email_address": value => {
    return !(isEmailValid(value) || value === "");
  },
  "date_in_the_past": value => {
    return !(isDateInThePastValid(value) || value === "");
  },
  "phone_number": value => {
    return !(isPhoneNumberValid(value) || value === "");
  },
  "mobile_number": value => {
    return !(isPhoneNumberValid(value) || value === "");
  }
};
class DynamicFormDoable extends Component {
  constructor(props) {
    super(props);

    let responses = {};
    this.props.doable.configuration.fields.map(field => {
      let fieldId = field.field_identifier;
      responses[fieldId] = field.value ? this.parseExistingValue(field.field_type, field.value) : null;

      if (field.field_type === 'checkbox') {
        responses[fieldId] = {};
        const initialValues = field.values ? field.values : [];
        if (field.choices !== null) {
          field.choices.map(choice =>
            Object.assign(responses[fieldId], { [choice.value]: initialValues.includes(choice.value) }),
          );
        }
      }
    });

    this.state = {
      responses: responses,
      responsesRequired: [],
      errors: {},
      dateErrors: {},
      reviewing: false,
      submitted: false,
      submissionError: null,
    };
  }

  parseExistingValue = (fieldType, value) => {
    switch (fieldType) {
      case 'boolean':
        return value === 'true';
      case 'date':
      case 'must_happen_by_date':
      case 'date_in_the_past':
        // parse into a Date to ensure the result is properly padded etc.
        const date = new Date(value)
        if (isNaN(date.getTime())) {
          return null
        }
        return {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear()
        }

      case 'money':
        return formatPenceNumberString(value)
      default:
        return value
    }
  }

  handleInputChange = field => event => {
    let responses = { ...this.state.responses };
    responses[field] = event;
    this.setState({ responses });
  };

  handleCheckboxChange = field => event => {
    let responses = { ...this.state.responses };

    if (this.getFieldTypeForField(field) === "confirmation") {
      responses[field] = event.target.checked ? true : null;
    }

    if (this.getFieldTypeForField(field) === "checkbox") {
      const choices = this.getChoicesForField(field);
      const hasNoneOfTheAbove = choices.some(choice => choice.is_none_of_the_above === true);

      if (hasNoneOfTheAbove) {
        const selectedCheckboxConfig = choices.filter(choice => choice.value === event.target.value)[0]
        const noneOfTheAboveCheckboxConfig = choices.filter(choice => choice.is_none_of_the_above === true)[0]

        if (selectedCheckboxConfig.is_none_of_the_above === true) {
          // Uncheck all other options
          Object.keys(responses[field]).forEach(key => {
            responses[field][key] = false;
          });
        } else {
          // Ensure 'None of the above' is not checked
          responses[field][noneOfTheAboveCheckboxConfig.value] = false;
        }
      }

      // Check selected checkbox
      responses[field][event.target.value] = event.target.checked;
    }

    this.setState({ responses });
  };

  handleDateChange = field => datePart => event => {
    let value = event.target.value;

    let responses = { ...this.state.responses };

    let date = responses[field] || {};
    date[datePart] = value;

    responses[field] = date;

    this.setState({ responses });
  };

  handleDateFieldValidation = field => datePart => value => {

    const responses = { ...this.state.responses };
    const requiredFieldConfig = this.props.doable.configuration.fields.filter((fieldConfig) => {
      return (fieldConfig.required && isFieldQuestionVisible(this.props.doable.configuration, fieldConfig, responses))
    })
    const responsesRequired = requiredFieldConfig.map(f => f.field_identifier);
    const questionIsRequired = responsesRequired.includes(field);

    if (questionIsRequired) {
      let dateErrors = { ...this.state.dateErrors };
      let errors = { ...this.state.errors };
      let dateField = dateErrors[field] || {};

      if (datePart === "day") {
        dateField["day"] = !isDayValid(value)
      }

      if (datePart === "month") {
        dateField["month"] = !isMonthValid(value)
      }

      if (datePart === "year") {
        dateField["year"] = !isYearValid(value)
      }

      dateErrors[field] = dateField

      const allValues = Object.values(dateErrors[field]);
      const dateFieldIsValid = allValues.every(item => item === false);

      errors[field] = !dateFieldIsValid;

      // Additional fieldType validation
      let fieldType = this.getFieldTypeForField(field);
      if (responses[field] !== null) {
        if (fieldType in field_type_validators) {
          errors[field] = field_type_validators[fieldType](responses[field])
        }
      }

      this.setState({ errors, dateErrors });
    }
  };

  handleFieldValidation = field => fieldType => event => {
    const value = event.target.value;
    const errors = { ...this.state.errors };

    if ( event.type === 'click') {
      // clear error when we start interacting - on click for checkbox / radio controls
      errors[field] = false;
      this.setState({ errors });
      return;
    } else if (event.type !== 'blur') {
      // blur event on text input will cause revalidation
      // other events don't cause error clear or re-validate so return
      return;
    }

    const responses = { ...this.state.responses };
    const requiredFieldConfig = this.props.doable.configuration.fields.filter((fieldConfig) => {
      return (fieldConfig.required && isFieldQuestionVisible(this.props.doable.configuration, fieldConfig, responses))
    })
    const requiredFields = requiredFieldConfig.map(f => f.field_identifier);

    if (responses[field] === '' || !responses[field]) {
      // field is empty - check if it's required
      errors[field] = requiredFields.includes(field);
    } else {
      // field is populated - validate it with the appropriate validator
      if (fieldType in field_type_validators) {
        errors[field] = field_type_validators[fieldType](value)
      }
    }

    this.setState({ errors });
  };

  handleValidation() {
    const responses = { ...this.state.responses };
    const requiredFieldConfig = this.props.doable.configuration.fields.filter((fieldConfig) => {
      return (fieldConfig.required && isFieldQuestionVisible(this.props.doable.configuration, fieldConfig, responses))
    })
    const responsesRequired = requiredFieldConfig.map(f => f.field_identifier);

    const visibleFields = this.props.doable.configuration.fields.filter((fieldConfig) => {
      return (isFieldQuestionVisible(this.props.doable.configuration, fieldConfig, responses))
    }).map(f => f.field_identifier);

    const errors = { ...this.state.errors };
    let formIsValid = true;

    const hasErrorOnVisibleField = visibleFields.some(field => errors[field] === true);

    if (hasErrorOnVisibleField) {
      formIsValid = false
    }

    Object.entries(responses).forEach(([question, answer]) => {
      let questionIsRequired = responsesRequired.includes(question);

      let fieldType = this.getFieldTypeForField(question);

      if (questionIsRequired) {
        if (answer === null || answer === '') {
          formIsValid = false;
          errors[question] = true;
        } else if (answer instanceof Object) {
          if (fieldType === "checkbox" || fieldType === "confirmation") {
            const allValues = Object.values(answer);
            const showError = allValues.every(item => item === false);
            if (showError) {
              formIsValid = false;
              errors[question] = true;
            }
          } else {
            const allValues = Object.values(answer);
            const showError = allValues.every(item => item === null);
            if (showError) {
              formIsValid = false;
              errors[question] = true;
            }
          }
        }
      } else {
        errors[question] = false;
      }
    });

    this.setState({ errors });

    return formIsValid
  }

  getFieldTypeForField = (field) => {
    return this.props.doable.configuration.fields.filter(f => f.field_identifier === field)[0].field_type
  };

  getChoicesForField = (field) => {
    return this.props.doable.configuration.fields.filter(f => f.field_identifier === field)[0].choices
  };

  reviewResponses = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.setState({
        reviewing: true,
      });
    }
  };

  normaliseFormSubmissionValue = (value, fieldType) => {
    switch (fieldType) {
      case 'checkbox':
        return Object.keys(value).filter(key => value[key])
      case 'date':
      case 'must_happen_by_date':
      case 'date_in_the_past':
        if (value !== null) {
          // parse into a Date to ensure the result is properly padded etc.
          const date = new Date(
            // Ensure it's interpreted as UTC because toISOString converts to
            // UTC and we don't want any rounding issues to shift the day by one.
            Date.UTC(parseInt(value.year), parseInt(value.month)-1, parseInt(value.day))
          );
          return date.toISOString().split('T')[0] // We only want the date portion
        }
      case 'money':
        if (value !== null) {
          return normaliseMoney(value)
        }
      case 'confirmation':
        return value !== null ? value.toString() : null;
      default:
        return value !== null ? value.toString() : null;
    }
  }

  formSubmission = () => {

    const fieldsSubmission = [];  // array of
    const fieldsToUnset = [];  // array of
    this.props.doable.configuration.fields.forEach(field => {

      const value = this.state.responses[field.field_identifier];
      const fieldType = field.field_type;
      const fieldVisible = isFieldQuestionVisible(this.props.doable.configuration, field, this.state.responses);

      if (fieldVisible) {
        const normalisedValue = this.normaliseFormSubmissionValue(value, fieldType)
        if (normalisedValue !== null) {
          if (fieldType === 'checkbox') {
            fieldsSubmission.push({
              fieldName: field.field_identifier,
              fieldValues: normalisedValue
            })
          } else {
            fieldsSubmission.push({
              fieldName: field.field_identifier,
              fieldValue: normalisedValue
            })
          }
        }
      } else {
        if (!!field.value || !!field.values) {
          fieldsToUnset.push(field.field_identifier)
        }
      }
    })

    const submissionBody = {
      fields: fieldsSubmission
    }

    if (fieldsToUnset.length > 0) {
      submissionBody.fields_to_unset = fieldsToUnset
    }

    fetch(`/api/dashboard/doable/${this.props.doable.id}`, {
      method: 'PUT',
      credentials: 'same-origin',
      body: JSON.stringify(submissionBody)
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            reviewing: false,
            submitted: true,
          });
        } else {
          this.setState({
            reviewing: false,
            submitted: false,
            submissionError: 'Unable to submit form',
          });
        }
      },
      () => {
        this.setState({
          reviewing: false,
          submitted: false,
          submissionError: 'Unable to submit form',
        });
      },
  )};

  saveResponses = () => {
    if (this.handleValidation()) {
      this.formSubmission()
    }
  };

  submitResponses = () => {
    this.formSubmission()
  }

  showPrevious = () => {
    this.setState({
      reviewing: false,
    });
  }

  createContent(content) {
    return { __html: content };
  }

  render() {

    if (!this.props.doable) return null;

    const requestTitle = this.props.doable.title;
    const requestDescription = this.props.doable.description;
    const checkBeforeSubmit = this.props.doable.configuration.check_before_submit;


    if (!!this.state.submissionError) {
      return (
        <>
          {window.scrollTo(0, 0)}
          <Card title="Sorry, that didn’t work" removeTopPadding>
            <p>
              We’re having trouble submitting your answers at the moment.
            </p>
            <p>
              Please send us a message via web chat, and let us know you were filling in the form:
            </p>
            <p>
              <em>{requestTitle}</em>
            </p>
          </Card>
        </>
      );
    }

    if (this.state.submitted) {
      return (
        <>
          {window.scrollTo(0, 0)}
          <Confirmation title="Thanks! We’ve got your responses">
            <p>
              As soon as we need anything else we’ll drop you an
              email with a link to your dashboard.
            </p>
            <Action small onClick={this.props.returnToDashboard}>
              Take me back to my dashboard
            </Action>
          </Confirmation>
          <Image src={imageOfficeWorking} fullWidth={true} />
        </>
      )
    }

    if (this.state.reviewing) {
      return (
        <>
          {window.scrollTo(0, 0)}
          <PageHeader>
            Review your answers
          </PageHeader>
          <Card title={requestTitle} removeTopPadding>
            {requestDescription && (
              <QuestionDescription dangerouslySetInnerHTML={this.createContent(requestDescription)} />
            )}
            <ReviewText>
              We’ve listed your answers below — if anything doesn’t look right, you can change your answers before you submit.
            </ReviewText>
            <ReviewTable>
              <thead>
                <tr>
                  <th>We asked</th>
                  <th>Your answer</th>
                </tr>
              </thead>
              <tbody>
                {this.props.doable.configuration.fields.map((fieldConfig) => {
                  const name = fieldConfig.field_identifier;
                  return <ReviewQuestion
                    doableConfig={this.props.doable.configuration}
                    fieldConfig={fieldConfig}
                    responses={this.state.responses}
                    key={name}
                    responseToQuestion={this.state.responses[name]}
                    showPrevious={this.showPrevious}
                  />;
                })}
              </tbody>
            </ReviewTable>
            <Action small onClick={this.submitResponses}>Submit your answers</Action>
          </Card>
        </>
      )
    }

    return (
      <>
        <ScrollToTop />
        <PageHeader>
          Answer questions
        </PageHeader>
        <Card title={requestTitle} removeTopPadding>
          {requestDescription && (
            <QuestionDescription dangerouslySetInnerHTML={this.createContent(requestDescription)} />
          )}
          <React.Fragment>
            {this.props.doable.configuration.fields.map((fieldConfig) => {
              const name = fieldConfig.field_identifier;
              return <RenderQuestion
                doableConfig={this.props.doable.configuration}
                fieldConfig={fieldConfig}
                responses={this.state.responses}
                key={name}
                inline={true}
                selected={this.state.responses[name]}
                value={this.state.responses[name] || ''}
                onChange={this.handleCheckboxChange(name)}
                onDateChange={this.handleDateChange(name)}
                optionSelected={this.handleInputChange(name)}
                validate={this.handleFieldValidation(name)(fieldConfig.field_type)}
                validateDate={this.handleDateFieldValidation(name)}
                validationError={
                  this.state.errors[name] ? 'Please choose an option' : null
                } />;
            })}

            {checkBeforeSubmit
              ? <Action small onClick={this.reviewResponses}>Continue</Action>
              : <Action small onClick={this.saveResponses}>Save your answers</Action>
            }
          </React.Fragment>
        </Card>
      </>
    )
  }
}

export default DynamicFormDoable
