import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout';
import ScrollToTop from "../../components/utils/ScrollToTop";
import Card from "../../components/Card";
import TextQuestion from "../../components/TextQuestion";
import Action from "../../components/Action";
import Image from "../../components/Image";
import imageOfficeWorking from "../../images/office-working.png";

const RemoAccountNumbersForm = (props) => {

  const [state, setState] = useState({
    error: null,
    securedLoans: props.securedLoans || []
  });

  const handleAccountNumberInput = accountIndex => event => {
    const securedLoans = [...state.securedLoans];
    securedLoans[accountIndex]['accountNumber'] = event;
    setState(prevState => ({ ...prevState, securedLoans }));
  };

  const validateAccountNumber = accountIndex => event => {
    const securedLoans = [...state.securedLoans];
    let accountNumberIsValid = true;
    if (event.type === 'blur') {
      accountNumberIsValid = !!state.securedLoans[accountIndex].accountNumber;
    }
    securedLoans[accountIndex].accountNumberIsValid = accountNumberIsValid;
    setState(prevState => ({ ...prevState, securedLoans }));
  };

  const validate = () => {
    let isValid = true;
    const securedLoans = [...state.securedLoans];
    for (const loan of securedLoans) {
      loan.accountNumberIsValid = !!loan.accountNumber;
      if (!loan.accountNumberIsValid) {
        isValid = false
      }
    }
    setState(prevState => ({ ...prevState, securedLoans }));
    return isValid
  }

  const submit = () => {
    if (validate()) {
      props.onStepComplete({
        securedLoans: state.securedLoans
      });
    }
  }

  const securedLoans = state.securedLoans;
  const numberOfSecuredLoans = securedLoans.length;
  const accountNumberInputs = securedLoans.map(
    (x, i) =>
      <TextQuestion
      key={i}
      question={`Enter the account number for your mortgage with ${x.accountName}`}
      value={x.accountNumber || ''}
      valueEntered={handleAccountNumberInput(i)}
      validate={validateAccountNumber(i)}
      validationError={x.accountNumberIsValid ? null : 'Please enter your account number' }
    />
  );

  return (
    <>
      <ScrollToTop/>
      <Card title={"Your mortgage account " + (numberOfSecuredLoans > 1 ? 'numbers' : 'number')} removeTopPadding>
        {accountNumberInputs}
        <Action onClick={submit}>
          Submit
        </Action>
      </Card>
      <Image src={imageOfficeWorking} fullWidth/>
    </>
  )
}

export default RemoAccountNumbersForm
