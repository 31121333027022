import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media';

function ReviewText(props) {
  return <JunoReviewText className={props.className}>{props.children}</JunoReviewText>;
}

const JunoReviewText = styled.div`
  color: ${props => props.theme.color.green};
  display: inline-block;
  font-weight: bold;
  max-width: 38rem;

  font-size: ${props => props.theme.fontSizes.h4['sm']};
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.h4['md']};
  }

  margin-bottom: 1rem;
  ${mediaQueries.md} {
    margin-bottom: 1.75rem;
  }
`;

export default ReviewText;
