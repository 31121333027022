import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';

class RemoLeaseholdSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };

    const flowState = this.props.remortgageLeaseholdInformationFlowState;

    fetch('/api/remortgageLeaseholdInformation', {
      method: 'PUT',
      body: JSON.stringify({
        formRequestId: flowState.formRequestId,
        transactionType: flowState.transactionType,
        hasChallengedCharges:
          flowState.leaseholdInformation.hasChallengedCharges,
        challengedChargesInfo:
          flowState.leaseholdInformation.challengedChargesInfo,
        hasReceivedNotices: flowState.leaseholdInformation.hasReceivedNotices,
        receivedNoticesInfo:
          flowState.leaseholdInformation.receivedNoticesInfo,
        hasReceivedComplaints:
          flowState.leaseholdInformation.hasReceivedComplaints,
        receivedComplaintsInfo:
          flowState.leaseholdInformation.receivedComplaintsInfo,
        firstManagementCompanyDetails: flowState.firstManagementCompanyDetails,
        secondManagementCompanyDetails: flowState.secondManagementCompanyDetails,
      }),
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
            submitted: true,
          });
          props.onFlowComplete();
        } else {
          this.setState({
            error: 'Unable to process your details',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Unable to process your details',
          loading: false,
        });
      },
    );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn’t work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    if (this.state.submitted) {
      return null;
    }
  }
}

export default RemoLeaseholdSubmission;
