import React from 'react';
import Card from '../../components/DashboardCard';
import {IconList} from "../../components/IconList";
import LastWorkedOn from './LastWorkedOn';
import StatusUpdateRow from "../../components/StatusUpdateRow";
import StyledCaseUpdateInfo from './StyledCaseUpdateInfo';

function StatusUpdate(props) {
  const statusUpdate = props.selectedCase.status_updates?.client_dashboard_overview;

  if (! statusUpdate) {
    return (
      <Card title="Current status"
        headingLevel="3"
        headingSize="2">
        We haven’t created a status update yet - soon you’ll be able to view the progress of your case here.
      </Card>
    )
  }

  const statusUpdateRows = statusUpdate.sections.map((section, index) => {
    return (
      <StatusUpdateRow
        key={index}
        title={section.title}
        descriptions={section.descriptions}
        description_blocks={section.description_blocks}
        status={section.status}
      />
    );
  });

  return (
    <>
      <Card title={props.title}
        headingLevel="3"
        headingSize="2"
        id="current-status">
        <IconList cols={1}>{statusUpdateRows}</IconList>
        <StyledCaseUpdateInfo>
          Status updated: {new Date(statusUpdate.last_updated).toLocaleString('en-GB')}
        </StyledCaseUpdateInfo>
        <LastWorkedOn selectedCase={props.selectedCase} />
      </Card>
    </>
  )
}

export default StatusUpdate;
