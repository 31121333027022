import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import { TextareaQuestion } from '../../components/TextQuestion';
import { YES_NO_OPTIONS } from '../../constants';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class RemoLeaseholdInformation extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      hasChallengedCharges: 'Please choose an option',
      challengedChargesInfo: 'Please fill this in',
      hasReceivedNotices: 'Please choose an option',
      receivedNoticesInfo: 'Please fill this in',
      hasReceivedComplaints: 'Please choose an option',
      receivedComplaintsInfo: 'Please fill this in',
    };

    if (this.props.leaseholdInformation) {
      const details = this.props.leaseholdInformation;
      this.state = {
        hasChallengedCharges: details.hasChallengedCharges,
        challengedChargesInfo: details.challengedChargesInfo,
        hasReceivedNotices: details.hasReceivedNotices,
        receivedNoticesInfo: details.receivedNoticesInfo,
        hasReceivedComplaints: details.hasReceivedComplaints,
        receivedComplaintsInfo: details.receivedComplaintsInfo,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        hasChallengedCharges: null,
        challengedChargesInfo: '',
        hasReceivedNotices: null,
        receivedNoticesInfo: '',
        hasReceivedComplaints: null,
        receivedComplaintsInfo: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let formIsValid = true;

    if (fields['hasChallengedCharges'] === null) {
      errors['hasChallengedCharges'] = true;
      formIsValid = false;
    }

    if (fields['hasChallengedCharges'] === true) {
      if (!fields['challengedChargesInfo']) {
        errors['challengedChargesInfo'] = true;
        formIsValid = false;
      }
    }

    if (fields['hasReceivedNotices'] === null) {
      errors['hasReceivedNotices'] = true;
      formIsValid = false;
    }

    if (fields['hasReceivedNotices'] === true) {
      if (!fields['receivedNoticesInfo']) {
        errors['receivedNoticesInfo'] = true;
        formIsValid = false;
      }
    }

    if (fields['hasReceivedComplaints'] === null) {
      errors['hasReceivedComplaints'] = true;
      formIsValid = false;
    }

    if (fields['hasReceivedComplaints'] === true) {
      if (!fields['receivedComplaintsInfo']) {
        errors['receivedComplaintsInfo'] = true;
        formIsValid = false;
      }
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  saveRemoLeaseholdInformation = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        leaseholdInformation: {
          hasChallengedCharges: this.state.hasChallengedCharges,
          challengedChargesInfo: this.state.challengedChargesInfo,
          hasReceivedNotices: this.state.hasReceivedNotices,
          receivedNoticesInfo: this.state.receivedNoticesInfo,
          hasReceivedComplaints: this.state.hasReceivedComplaints,
          receivedComplaintsInfo: this.state.receivedComplaintsInfo,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about how the property is managed" removeTopPadding>

          <RadioQuestion
            question="Have you challenged the maintenance charge or any expense in the last three years?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="hasChallengedCharges"
            selected={this.state.hasChallengedCharges}
            optionSelected={this.handleInputChange('hasChallengedCharges')}
            validate={this.handleFieldValidation('hasChallengedCharges')}
            validationError={
              this.state.errors['hasChallengedCharges']
                ? this.state.errorMessages['hasChallengedCharges']
                : null
            }
          />

          {this.state.hasChallengedCharges === true && (
            <RadioConditional data-testid="challenged-charges-info">
              <TextareaQuestion
                question="Please give details"
                value={this.state.challengedChargesInfo}
                valueEntered={this.handleInputChange('challengedChargesInfo')}
                validate={this.handleFieldValidation('challengedChargesInfo')}
                validationError={
                  this.state.errors['challengedChargesInfo']
                    ? this.state.errorMessages['challengedChargesInfo']
                    : null
                }
              />
            </RadioConditional>
          )}

          <RadioQuestion
            question="Have you received any Notices from a Landlord or Management company about the property, its use, condition, repair or maintenance?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="hasReceivedNotices"
            selected={this.state.hasReceivedNotices}
            optionSelected={this.handleInputChange('hasReceivedNotices')}
            validate={this.handleFieldValidation('hasReceivedNotices')}
            validationError={
              this.state.errors['hasReceivedNotices']
                ? this.state.errorMessages['hasReceivedNotices']
                : null
            }
          />

          {this.state.hasReceivedNotices === true && (
            <RadioConditional data-testid="received-notices-info">
              <TextareaQuestion
                question="Please give details"
                value={this.state.receivedNoticesInfo}
                valueEntered={this.handleInputChange('receivedNoticesInfo')}
                validate={this.handleFieldValidation('receivedNoticesInfo')}
                validationError={
                  this.state.errors['receivedNoticesInfo']
                    ? this.state.errorMessages['receivedNoticesInfo']
                    : null
                }
              />
            </RadioConditional>
          )}

          <RadioQuestion
            question="Have you received any complaints from a Landlord or Management Company about anything you have or have not done at the property?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="hasReceivedComplaints"
            selected={this.state.hasReceivedComplaints}
            optionSelected={this.handleInputChange('hasReceivedComplaints')}
            validate={this.handleFieldValidation('hasReceivedComplaints')}
            validationError={
              this.state.errors['hasReceivedComplaints']
                ? this.state.errorMessages['hasReceivedComplaints']
                : null
            }
          />

          {this.state.hasReceivedComplaints === true && (
            <RadioConditional data-testid="received-complaints-info">
              <TextareaQuestion
                question="Please give details"
                value={this.state.receivedComplaintsInfo}
                valueEntered={this.handleInputChange('receivedComplaintsInfo')}
                validate={this.handleFieldValidation('receivedComplaintsInfo')}
                validationError={
                  this.state.errors['receivedComplaintsInfo']
                    ? this.state.errorMessages['receivedComplaintsInfo']
                    : null
                }
              />
            </RadioConditional>
          )}

          <Action onClick={this.saveRemoLeaseholdInformation}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default RemoLeaseholdInformation;
