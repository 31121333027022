const breakpoints = {
  mobile: 320,
  tablet: 670,
  desktop: 980,
  wide: 1300,
  sm: 420,
  md: 768,
  lg: 1024,
  xl: 1280,
};

export const mediaQueries = {
  sm: `@media (min-width: ${breakpoints.sm / 16}rem)`,
  md: `@media (min-width: ${breakpoints.md / 16}rem)`,
  lg: `@media (min-width: ${breakpoints.lg / 16}rem)`,
  xl: `@media (min-width: ${breakpoints.xl / 16}rem)`,
};


export const mediaQueriesUntil = {
  sm: `@media (max-width: ${breakpoints.sm / 16}rem)`,
  md: `@media (max-width: ${breakpoints.md / 16}rem)`,
  lg: `@media (max-width: ${breakpoints.lg / 16}rem)`,
  xl: `@media (max-width: ${breakpoints.xl / 16}rem)`,
};
