import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { SAVINGS_ACCOUNTS_NUMBER_USED } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class Savings extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      numberOfSavingsAccounts: 'Please choose an option'
    };

    if (this.props.savings) {
      const savings = { ...this.props.savings };
      this.state = {
        numberOfSavingsAccounts: savings.numberOfSavingsAccounts,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        numberOfSavingsAccounts: null,
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => value => {
    this.setState({ [field]: value });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let formIsValid = true;

    if (fields['numberOfSavingsAccounts'] === null) {
      errors['numberOfSavingsAccounts'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleRadioValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (this.state[field] === null) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  saveSavings = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        savings: {
          numberOfSavingsAccounts: this.state.numberOfSavingsAccounts,
          inheritance: this.state.inheritance,
        },
      });
      return;
    }
  };

  render() {

    const { helpToBuyIsa, lifetimeIsa } = this.props.howIsBuyerPaying || {};
    const hasIsa = helpToBuyIsa || lifetimeIsa;
    const hasBothIsas = helpToBuyIsa && lifetimeIsa;

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about your savings or investments" removeTopPadding>
          <p>
            If you’re keeping money somewhere else, or using another kind of
            funding to pay for your purchase, tell us about it.
          </p>

          {hasIsa && (
            <p>
              We’ll ask about your {helpToBuyIsa && 'Help to Buy ISA'} {hasBothIsas && '/'} {lifetimeIsa && 'Lifetime ISA'} separately - there’s no need to include {hasBothIsas ? 'them' : 'it'} here.
            </p>
          )}

          <RadioQuestion
            question="How many accounts are you keeping your savings in?"
            hint="If you’re buying the property with someone else, include their accounts too"
            options={SAVINGS_ACCOUNTS_NUMBER_USED}
            name="numberOfSavingsAccounts"
            selected={this.state.numberOfSavingsAccounts}
            optionSelected={this.handleInputChange('numberOfSavingsAccounts')}
            validate={this.handleRadioValidation('numberOfSavingsAccounts')}
            validationError={
              this.state.errors.numberOfSavingsAccounts
                ? this.state.errorMessages.numberOfSavingsAccounts
                : null
            }
          />

          <Action onClick={this.saveSavings}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default Savings;
