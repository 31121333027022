import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';

import BasicTA10Item from "./BasicTA10Item";
import ShowAddTA10Item from "./ShowAddTA10Item";
import AddTA10Item from "./AddTA10Item";


class TA10SectionRooms extends Component {

  constructor(props) {
    super(props);
    this.state = this.initStateFromProps(props);
  }

  initStateFromProps(props) {
    if (props.sectionRooms && props.sectionRooms[props.roomIndex] !== undefined) {
      const sectionRooms = [...props.sectionRooms];

      return {
        title: sectionRooms[props.roomIndex].title,
        items: sectionRooms[props.roomIndex].items,
        errors: sectionRooms[props.roomIndex].items.map(_ => { {} }),
        addItemErrors: {}
      }
    }
    else {
      const config = this.props.config
      let items

      items = config.items.map(item => {
        return {
          itemName: item.itemName,
          itemType: item.itemType,
          itemHint: item.itemHint
        }
      })

      return {
        title: props.sectionTitle,
        items: items,
        itemToAdd: '',
        errors: items.map(_ => { {} }),
        addItemErrors: {}
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState(this.initStateFromProps(this.props));
    }
  }

  validate = () => {
    let validationPassed = true

    const errors = this.state.items.map((item) => {
      const itemErrors = {}
      if (!item.inclusion) {
        itemErrors.inclusion = true
        validationPassed = false
      }
      if (item.itemType === 'kitchen' && item.inclusion !== 'None' && !item.fitted) {
        itemErrors.fitted = true
        validationPassed = false
      }
      if (item.inclusion === 'For sale' && !item.price) {
        itemErrors.price = true
        validationPassed = false
      }
      return itemErrors
    })

    this.setState({ errors: errors })

    return validationPassed
  }

  itemUpdated = (index, item) => {

    const items = [...this.state.items]
    const errors = [...this.state.errors]
    items[index] = item

    const itemErrors = {}

    if (!item.inclusion) {
      itemErrors.inclusion = true
    }

    if (item.itemType === 'kitchen' && item.inclusion !== 'None' && !item.fitted) {
      itemErrors.fitted = true
    }

    if (item.inclusion === 'For sale' && !item.price) {
      itemErrors.price = true
    }

    errors[index] = itemErrors
    this.setState({ items: items, errors: errors })

  }

  updateItemToAdd = (name) => {
    this.setState({ itemToAdd: name })
  }

  addItem = () => {

    const newItem = {
      itemName: this.state.itemToAdd,
      itemType: this.props.kitchen ? 'kitchen' : 'other'
    }

    this.setState({
      items: [...this.state.items, newItem],
      itemToAdd: '',
      showAddItem: false
    })
  }

  removeItem = (name) => {
    this.setState({ items: this.state.items.filter(i => i !== name) })
  }

  showAddItem = () => {
    this.setState({ showAddItem: true })
  }

  undoAddItem = () => {
    this.setState({ showAddItem: false })
  }

  validateItem = (event) => {
    const items = [...this.state.items];
    const itemToAdd = this.state.itemToAdd;
    const addItemErrors = { ...this.state.addItemErrors };
    const errorMessages = { ...this.state.errorMessages };
    const itemAlreadyExists = this.state.items.some(i => i['itemName'] === itemToAdd)

    if (event.type === 'blur') {
      if (itemAlreadyExists) {
        addItemErrors['itemToAdd'] = true
        errorMessages['itemToAdd'] = `${itemToAdd} already exists`
      }
    } else {
      addItemErrors['itemToAdd'] = false
      errorMessages['itemToAdd'] = null
    }

    this.setState({ addItemErrors, errorMessages })
  }

  saveTA10Section = event => {
    event.preventDefault();

    if (this.validate()) {

      if (this.props.sectionRooms) {
        const sectionRooms = [...this.props.sectionRooms];
        sectionRooms[this.props.roomIndex] = {
          title: this.state.title,
          items: this.state.items
        }
        this.props.onStepComplete({
          sectionRooms
        });

      } else {
        const sectionRooms = [];
        sectionRooms[this.props.roomIndex] = {
          title: this.state.title,
          items: this.state.items
        }
        this.props.onStepComplete({
          sectionRooms
        });
      }

    }
  }

  render() {

    const config = this.props.config
    const roomItemsConfig = this.props.roomItemsConfig

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={config.sectionTitle || this.props.sectionTitle} removeTopPadding>

          {config.sectionNote && <p>{config.sectionNote}</p>}

          {this.state.items.map((item, index) => {
            return (
              <BasicTA10Item
                config={config}
                roomItemsConfig={roomItemsConfig}
                key={index}
                item={item}
                hint={item.itemHint ? item.itemHint : null}
                removeItem={(item) => this.removeItem(item)}
                onItemUpdate={(item) => this.itemUpdated(index, item)}
                errors={this.state.errors[index] ? this.state.errors[index] : {}}
              />
            )
          })}

          {config.allowsAddItem && !this.state.showAddItem && (
            <ShowAddTA10Item
              onShowAdd={this.showAddItem}
              addLinkText="Add a new item"
              continue={this.saveTA10Section}
            />
          )}

          {config.allowsAddItem && this.state.showAddItem && (
            <AddTA10Item
              question="Add another item"
              value={this.state.itemToAdd}
              valueEntered={this.updateItemToAdd}
              validate={this.validateItem}
              validationError={
                this.state.addItemErrors['itemToAdd']
                  ? this.state.errorMessages['itemToAdd']
                  : null}
              addButtonText="Add item"
              onAdd={this.addItem}
              onCancel={this.undoAddItem}
            />
          )}

        </Card>
      </React.Fragment>
    )
  }
}

export default TA10SectionRooms;
