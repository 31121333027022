import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { createDataTestId, createId } from './utils/helpers';
import { mediaQueries } from '../styles/media.js';
import Question, { Optional } from './Question';
import Fieldset from './Fieldset';
import Legend from './Legend';
import Hint from './Hint';
import Error from './Error';

const Checkbox = styled.div`
  position: relative;
  margin-bottom: 8px;
  padding-left: 2em;
  min-height: 32px;

  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: normal;
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
  }

  input {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 0;
    opacity: 0;
  }

  label {
    display: block;
    padding: 0.25em 0.75em;
    border: 2px solid transparent;
    cursor: pointer;
    touch-action: manipulation;
  }

  label::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2em;
    height: 2em;
    border: 2px solid #a7b1be;
    background: transparent;
  }

  label::after {
    content: '';
    width: 1em;
    height: 0.55em;
    position: absolute;
    top: 0.6em;
    left: 0.5em;
    transform: rotate(-45deg);
    border: solid;
    border-width: 0 0 5px 5px;
    border-color: #a7b1be;
    border-top-color: transparent;
    opacity: 0;
    background: transparent;
  }
  input:focus + label::before {
    box-shadow: 0 0 0 3px #f6ce55;
  }

  input:checked + label::after {
    opacity: 1;
  }

  input:disabled,
  input:disabled + label {
    cursor: default;
  }

  input:disabled:checked,
  input:disabled:checked + label {
    font-weight: bold;
  }

  input:disabled:checked + label::before,
  input:disabled:checked + label::after {
    border-color: ${props => props.theme.color.junoText};
  }

`;

class DynamicCheckboxQuestion extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Question>
        <Fieldset>
          <Legend>
            {this.props.question} {this.props.optional ? <Optional /> : null}
            {this.props.hint ? <Hint>{this.props.hint}</Hint> : null}
            {this.props.validationError ? (
              <Error>{this.props.validationError}</Error>
            ) : null}
          </Legend>

          {this.props.options.map((option, i) => {
            const labelText = option.label;
            const componentId = createId(this.props.fieldId, `${labelText}`);
            const dataTestId = createDataTestId('checkbox', `${labelText}`);
            const checkboxName = labelText.toLowerCase().replace(/ /g, '_');

            return (
              <Checkbox option={option} key={i} {...this.props}>
                <input
                  type="checkbox"
                  name={checkboxName}
                  checked={
                    (this.props.selected && this.props.selected === true) ||
                    (this.props.selected && this.props.selected[option.value] === true) ||
                    false
                  }
                  onChange={this.props.onChange}
                  value={option.value}
                  title={option.label}
                  onClick={this.props.validate}
                  id={componentId}
                  data-testid={dataTestId}
                  disabled={this.props.disabled}
                />
                <label htmlFor={componentId}>{labelText}</label>
              </Checkbox>
            );
          })}
        </Fieldset>
      </Question>
    );
  }
}

export default DynamicCheckboxQuestion;
