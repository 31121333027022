import React from 'react';
import styled from 'styled-components/macro';

const JunoLink = styled.a`
  font-size: inherit;
  color: ${props => props.theme.color.junoLink};
  cursor: pointer;
  margin-bottom: 0.25em;
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${props => props.theme.color.junoLinkHover};
    border-bottom-color: ${props => props.theme.color.junoLinkHover};
  }

  &:focus {
    outline: 3px solid ${props => props.theme.color.junoFocus};
  }
`;

function Link(props) {
  return (
    <JunoLink {...props}>
      {props.children}
    </JunoLink>
  );
}

export default Link;
