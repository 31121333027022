import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media';
import PageHeader from '../../components/PageHeader';

export const StyledEnquiryDoablePageHeader = styled(PageHeader)`
  margin-bottom: 16px;
`

export const StyledJunoEnquiry = styled.div`
  position: relative;
  color: #0d0d0d;
  background-color: #ffffff;
  border: 1px solid ${props => props.theme.color.indigo};
  border-radius: 16px;
  padding: 0;

  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 16px;

  ${mediaQueries.md} {
    margin-left: 0px;
    margin-right: 64px;
  }
`;

export const StyledEnquiryContent = styled.div`
  padding: 16px;
  font-size: 14px;
  ${mediaQueries.md} {
    font-size: 16px;
  }
  white-space: pre-line;


  label,
  label > div {
    font-size: 14px;
    ${mediaQueries.md} {
      font-size: 16px;
    }
  }

  p,
  ul,
  ol {
    font-size: 14px;
    ${mediaQueries.md} {
      font-size: 16px;
    }
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0
  }

  textarea {
    border-width: 1px;
    border-radius: 16px;
    font-size: 14px;
    ${mediaQueries.md} {
      font-size: 16px;
    }
    padding: 12px;
  }

  button {
    font-size: 16px;
  }
`

export const StyledJunoEnquiryActivity = styled.div`
  position: relative;
  color: #0d0d0d;
  background-color: #ffffff;
  border: 1px solid ${props => props.theme.color.indigo};
  border-radius: 16px;
  padding: 0;

  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 16px;

  ${mediaQueries.md} {
    margin-left: 0px;
    margin-right: 64px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 16px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right-color: ${props => props.theme.color.indigo};
    border-left: 0;
    border-top: 0;
    margin-top: -4px;
    margin-left: -9px;
  }
`;

export const StyledEnquiryActivity = styled.div`
  position: relative;
  color: ${props => props.theme.color.junoText};
  background-color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.lightGrey};
  border-radius: 16px;
  padding: 0;

  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 16px;

  ${mediaQueries.md} {
    margin-left: 64px;
    margin-right: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 24px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-left-color: ${props => props.theme.color.lightGrey};
    border-right: 0;
    border-bottom: 0;
    margin-top: -4px;
    margin-right: -9px;
  }
`;

export const StyledJunoEnquiryHeading = styled.div`
  display: flex;
  justify-content: space-between;

  font-weight: ${props => props.theme.fontWeight.bold};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color:  ${props => props.theme.color.white};
  padding: 6px 16px;
  background: ${props => props.theme.color.indigo};
  border-bottom: 1px solid  ${props => props.theme.color.indigo};
  font-size: 14px;
`

export const StyledEnquiryHeading = styled.div`
  display: flex;
  justify-content: space-between;

  font-weight: ${props => props.theme.fontWeight.bold};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color:  ${props => props.theme.color.white};
  padding: 6px 16px;
  background: ${props => props.theme.color.lightGrey};
  border-bottom: 1px solid  ${props => props.theme.color.lightGrey};
  font-size: 14px;
`

export const StyledAttachmentList = styled.ul`
  margin: 0;
  padding: 0 16px 8px 16px;

  li {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 4px;
    line-height: normal;
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.color.grey};
  }

  a {
    color: ${props => props.theme.color.grey};
    border-bottom: 1px solid transparent;

    &:hover {
      color: ${props => props.theme.color.junoLinkHover};
      border-bottom-color:  ${props => props.theme.color.junoLinkHover};
    }
  }

  svg {
    transform: rotate(45deg);
    margin-left: -4px;
    font-size: 18px;
    margin-right: 4px;
    color: ${props => props.theme.color.lightGrey};
  }
`
