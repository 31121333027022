import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';
import RadioQuestion from '../../components/RadioQuestion';
import { YES_NO_OPTIONS } from '../../constants';
import { isMoneyValid, normaliseMoney } from '../../utils/moneyUtils';
import { getOrdinalString } from '../../utils/numberUtils';
import {isEmailValid} from "../../utils/textUtils";

class LendingMoneyForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.initStateFromProps(props);
  }

  initStateFromProps(props) {
    const errorMessages = {
      lendingMoneyFullName: 'Please fill this in',
      lendingMoneyAmount: 'Please fill this in',
      lendingMoneySecured: 'Please choose an option',
      lendingMoneyEmailAddress: 'Please fill this in',
      lendingMoneyPhoneNumber: 'Please fill this in',
      lendingMoneyAddressAndPostcode: 'Please fill this in',
    };

    if (this.props.lendingMoney) {
      const lend = { ...props.lendingMoney };
      return {
        lendingMoneyFullName: lend.lendingMoneyFullName,
        lendingMoneyAmount: lend.lendingMoneyAmount,
        lendingMoneySecured: lend.lendingMoneySecured,
        lendingMoneyEmailAddress: lend.lendingMoneyEmailAddress,
        lendingMoneyPhoneNumber: lend.lendingMoneyPhoneNumber,
        lendingMoneyAddressAndPostcode: lend.lendingMoneyAddressAndPostcode,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      return {
        lendingMoneyFullName: '',
        lendingMoneyAmount: '',
        lendingMoneySecured: null,
        lendingMoneyEmailAddress: '',
        lendingMoneyPhoneNumber: '',
        lendingMoneyAddressAndPostcode: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.lendingMoney !== prevProps.lendingMoney ||
      this.props.lenderNumber !== prevProps.lenderNumber
    ) {
      this.setState(this.initStateFromProps(this.props));
    }
  }

  handleInputChange = field => value => {
    this.setState({ [field]: value });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (!fields['lendingMoneyFullName']) {
      errors['lendingMoneyFullName'] = true;
      formIsValid = false;
    }

    if (!fields['lendingMoneyAmount']) {
      errors['lendingMoneyAmount'] = true;
      formIsValid = false;
    }

    if (
      fields['lendingMoneyAmount'] !== '' &&
      !isMoneyValid(fields['lendingMoneyAmount'])
    ) {
      errors['lendingMoneyAmount'] = true;
      errorMessages['lendingMoneyAmount'] = 'Please enter a valid amount';
      formIsValid = false;
    }

    if (fields['lendingMoneySecured'] === null) {
      errors['lendingMoneySecured'] = true;
      formIsValid = false;
    }

    if (!fields['lendingMoneyEmailAddress']) {
      errors['lendingMoneyEmailAddress'] = true;
      formIsValid = false;
    }

    if (
      fields['lendingMoneyEmailAddress'] !== '' &&
      !isEmailValid(fields['lendingMoneyEmailAddress'])
    ) {
      errors['lendingMoneyEmailAddress'] = true;
      errorMessages['lendingMoneyEmailAddress'] =
        'Please enter a valid email address';
      formIsValid = false;
    }

    if (!fields['lendingMoneyPhoneNumber']) {
      errors['lendingMoneyPhoneNumber'] = true;
      formIsValid = false;
    }

    if (
      fields['lendingMoneyPhoneNumber'] !== '' &&
      !this.isPhoneNumberValid(fields['lendingMoneyPhoneNumber'])
    ) {
      errors['lendingMoneyPhoneNumber'] = true;
      errorMessages['lendingMoneyPhoneNumber'] =
        'Please enter a valid phone number';
      formIsValid = false;
    }

    if (!fields['lendingMoneyAddressAndPostcode']) {
      errors['lendingMoneyAddressAndPostcode'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleRadioValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (this.state[field] === null) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  isPhoneNumberValid = phone => {
    const re = /[0-9+() -]+$/;
    return re.test(String(phone));
  };

  handlePhoneNumberValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.isPhoneNumberValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid phone number';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleEmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isEmailValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid email address';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  handleMoneyValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isMoneyValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid amount';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  saveLendingMoney = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      const outLending = {};
      outLending['lendingMoney' + this.props.lenderNumber] = {
        lendingMoneyFullName: this.state.lendingMoneyFullName,
        lendingMoneyAmount: normaliseMoney(this.state.lendingMoneyAmount),
        lendingMoneySecured: this.state.lendingMoneySecured,
        lendingMoneyEmailAddress: this.state.lendingMoneyEmailAddress,
        lendingMoneyPhoneNumber: this.state.lendingMoneyPhoneNumber,
        lendingMoneyAddressAndPostcode: this.state
          .lendingMoneyAddressAndPostcode,
      };

      this.props.onStepComplete({
        ...outLending,
      });
      return;
    }
  };

  render() {
    const ordinalString = getOrdinalString(this.props.lenderNumber);

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card
          title={
            'Tell us about the ' + ordinalString + ' person lending you money'
          }
          removeTopPadding
        >
          <p>
            We’ll need to contact and verify the identity of anyone lending you money - to complete our anti-money laundering checks.
          </p>
          <TextQuestion
            question={
              "What's the name of the " +
              ordinalString +
              ' person lending you money?'
            }
            value={this.state.lendingMoneyFullName}
            valueEntered={this.handleInputChange('lendingMoneyFullName')}
            validate={this.handleFieldValidation('lendingMoneyFullName')}
            validationError={
              this.state.errors.lendingMoneyFullName
                ? this.state.errorMessages.lendingMoneyFullName
                : null
            }
          />

          <TextQuestion
            question="How much will they be lending you?"
            value={this.state.lendingMoneyAmount}
            valueEntered={this.handleInputChange('lendingMoneyAmount')}
            validate={this.handleMoneyValidation('lendingMoneyAmount')}
            validationError={
              this.state.errors.lendingMoneyAmount
                ? this.state.errorMessages.lendingMoneyAmount
                : null
            }
          />

          <RadioQuestion
            question="Will this loan be secured against your property?"
            options={YES_NO_OPTIONS}
            inline={true}
            name="lendingMoneySecured"
            selected={this.state.lendingMoneySecured}
            optionSelected={this.handleInputChange('lendingMoneySecured')}
            validate={this.handleRadioValidation('lendingMoneySecured')}
            validationError={
              this.state.errors.lendingMoneySecured
                ? this.state.errorMessages.lendingMoneySecured
                : null
            }
          />

          <TextQuestion
            question="What's their email address?"
            value={this.state.lendingMoneyEmailAddress}
            valueEntered={this.handleInputChange('lendingMoneyEmailAddress')}
            validate={this.handleEmailAddressValidation(
              'lendingMoneyEmailAddress',
            )}
            validationError={
              this.state.errors.lendingMoneyEmailAddress
                ? this.state.errorMessages.lendingMoneyEmailAddress
                : null
            }
          />

          <TextQuestion
            question="What's their phone number?"
            value={this.state.lendingMoneyPhoneNumber}
            valueEntered={this.handleInputChange('lendingMoneyPhoneNumber')}
            validate={this.handlePhoneNumberValidation(
              'lendingMoneyPhoneNumber',
            )}
            validationError={
              this.state.errors.lendingMoneyPhoneNumber
                ? this.state.errorMessages.lendingMoneyPhoneNumber
                : null
            }
          />

          <TextQuestion
            question="What's their address?"
            value={this.state.lendingMoneyAddressAndPostcode}
            valueEntered={this.handleInputChange(
              'lendingMoneyAddressAndPostcode',
            )}
            validate={this.handleFieldValidation(
              'lendingMoneyAddressAndPostcode',
            )}
            validationError={
              this.state.errors.lendingMoneyAddressAndPostcode
                ? this.state.errorMessages.lendingMoneyAddressAndPostcode
                : null
            }
          />

          <Action onClick={this.saveLendingMoney}>Continue</Action>
        </Card>

        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default LendingMoneyForm;
