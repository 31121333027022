import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

const NUMBER_OF_GIFTS_OPTIONS = [
  { title: '1', value: 1 },
  { title: '2', value: 2 },
  { title: '3', value: 3 },
  { title: '4', value: 4 },
];

class GiftingMoney extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      numberOfSource: 'Please choose an option',
    };

    if (this.props.giftingMoney) {
      const gifts = { ...this.props.giftingMoney };
      this.state = {
        numberOfSource: gifts.numberOfSource,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        numberOfSource: null,
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let formIsValid = true;

    if (fields['numberOfSource'] === null) {
      errors['numberOfSource'] = true;
      formIsValid = false;
    }

    this.setState({ errors });

    return formIsValid;
  }

  handleRadioValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (this.state[field] === null) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  saveGiftingMoney = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        giftingMoney: {
          numberOfSource: this.state.numberOfSource,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about any gifts of money from someone who is alive" removeTopPadding>
          <p>
            A gift of money is when someone gives you money that you don’t have to pay back. This could be a gift from a family member or friend.
          </p>
          <p>
            We need to know how many gifts you are receiving and how much money you are receiving in total towards your purchase.
          </p>
          <p>
            If you’re buying with another person, and both receiving a gift from the same person, this counts as one gift. If the gift is from a joint account - this also counts as one gift.
          </p>

          <RadioQuestion
            question="How many gifts are you receiving?"
            options={NUMBER_OF_GIFTS_OPTIONS}
            inline={false}
            name="numberOfSource"
            selected={this.state.numberOfSource}
            optionSelected={this.handleInputChange('numberOfSource')}
            validate={this.handleRadioValidation('numberOfSource')}
            validationError={
              this.state.errors.numberOfSource
                ? this.state.errorMessages.numberOfSource
                : null
            }
          />

          <Action onClick={this.saveGiftingMoney}>Continue</Action>
        </Card>

        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default GiftingMoney;
