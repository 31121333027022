import React, { Component } from "react";
import DiscountPanel from "../../components/DiscountPanel";
import { Error, CheckCircle } from '@styled-icons/material';
import Details from "../../components/Details";
import { TextQuestionWithButton } from "../../components/TextQuestion";


class ReferralCodeControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referralCode: this.props.referral ? this.props.referral.referralCode : '',
      referralCodeError: null,
      referral: this.props.referral,
      referralCodeValid: !!this.props.referral,
    };
  }

  applyReferralCode = () => {
    this.setState({
      applyEnabled: false,
      referralCodeError: null,
      referral: null,
      referralCodeValid: false,
    });

    let url = `/api/referralCode/${this.state.referralCode}`;
    if (!!this.props.audience) {
      url += `?audience=${this.props.audience}`;
    }

    fetch(url)
      .then(resp => resp.json())
      .then(
        referralData => {
          const referral = {
            referralCode: this.state.referralCode,
            discountAmount: referralData.discountAmount,
            discountMessage: referralData.discountMessage,
            quoteMessage: referralData.quoteMessage,
            referralSource: referralData.referralSource,
          };

          this.setState({
            referralCodeError: referralData.referralCodeError,
            referral: referral,
            referralCodeValid: referralData.referralCodeValid,
          });
          this.props.onReferralCodeApplied(
            referralData.referralCodeValid ? referral : null
          )
        },
        () => {
          this.setState({
            referralCodeError: 'unable to retrieve referral code',
            referral: null,
            referralCodeValid: false,
          });
        },
      );
  }

  referralCodeEntered = code => {
    this.setState({
      referralCode: code,
      applyEnabled: true,
    });
  };

  render() {
    let discountStatus;
    if (this.state.referralCodeError) {
      discountStatus = (
        <DiscountPanel invalid>
          <Error size={24} />
          {this.state.referralCodeError}
        </DiscountPanel>
      );
    } else if (this.state.referral) {
      discountStatus = (
        <DiscountPanel valid data-testid="discount-panel-valid">
          <CheckCircle size={24} />{this.state.referral.discountMessage || 'Referral code applied successfully'}
        </DiscountPanel>
      );
    }

    return (
      <Details summary="Add a referral code">
        {discountStatus}
        <TextQuestionWithButton
          question="Referral code"
          value={this.state.referralCode}
          valueEntered={this.referralCodeEntered}
          buttonText="Apply code"
          buttonAction={this.applyReferralCode}
        />
      </Details>
    );
  }
}

export default ReferralCodeControl
