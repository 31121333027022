import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import JunoLogo from './JunoLogo';
import { mediaQueries } from '../styles/media.js';

const JunoFooter = styled.footer`
  font-family: ${props => props.theme.junoFont};

  background-color: ${props =>
    props.reversed
      ? props.theme.color.junoFooterReversed
      : props.theme.color.junoFooter};

  /* theme footer links */
  a {
    text-decoration: none;
  }

  a:link,
  a:visited {
    color: ${props =>
    props.reversed
      ? props.theme.color.junoLinkReversedActive
      : props.theme.color.junoFooterText};
  }

  a:hover,
  a:active {
    color: ${props =>
    props.reversed
      ? props.theme.color.junoLinkReversedHover
      : props.theme.color.junoLinkHover};
  }

  /* theme Juno text */
  color: ${props =>
    props.reversed
      ? props.theme.color.junoTextReversed
      : props.theme.color.junoFooterText};

  /* theme address */
  address {
    color: ${props =>
    props.reversed
      ? props.theme.color.junoTextReversed
      : props.theme.color.junoFooterText};
  }

  /* theme Smallprint */
  p {
    color: ${props =>
    props.reversed
      ? props.theme.color.junoTextReversedSecondary
      : props.theme.color.junoTextSecondary};
  }
`;

const JunoFooterInner = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;

  max-width: ${props => (props.theme.pageWidth[props.pageWidth]/16)}rem;

  margin-left: ${props => props.theme.spacingScale[6]};
  margin-right: ${props => props.theme.spacingScale[6]};

  ${mediaQueries.md} {
    margin-left: ${props => props.pageWidth === 'narrow' ? 'auto' : props.theme.spacingScale[12]};
    margin-right: ${props => props.pageWidth === 'narrow' ? 'auto' : props.theme.spacingScale[12]};
  }

   @media(min-width: ${props => (props.theme.pageWidth[props.pageWidth] + props.theme.gridSpacing.columnWidth * 2) / 16}rem) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const JunoFooterText = styled.p`
  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: 1.5rem;

  font-weight: ${props => props.theme.fontWeight.light};
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit;

  margin-top: 0;
  margin-bottom: 0.75rem;

  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
    line-height: 1.75rem;

    margin-bottom: 1rem;
  }
`;

const JunoFooterAddress = styled(JunoFooterText)`
  color: inherit;
  font-style: normal;

  margin-top: 1rem;

  ${mediaQueries.md} {
    margin-top: 1.75rem;
  }
`;

const JunoFooterSection = styled.section`
  margin-bottom: 3em;
`;

const Smallprint = styled.div`
  max-width: 38rem;

  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
  }
`;


function Footer(props) {
  return (
    <JunoFooter {...props}>
      <JunoFooterInner pageWidth={props.pageWidth}>
        <JunoLogo fill={props.reversed ? '#f1f2f4' : '#002457'} />

        <JunoFooterSection>
          <JunoFooterAddress as="address">
            43-51 New North Road
            <br />
            London N1 6LU
          </JunoFooterAddress>
          <JunoFooterText>
            <a href="tel:020 3856 3339">020 3856 3339</a>
          </JunoFooterText>
          <JunoFooterText>
            <a href="mailto:hello@juno.legal">hello@juno.legal</a>
          </JunoFooterText>
          <JunoFooterText>
            We are open Monday to Friday, 9am to 5pm
          </JunoFooterText>
        </JunoFooterSection>

        <JunoFooterSection>
          <Smallprint>
            <p>
              Juno is the trading name of Juno Property Lawyers Limited, a company registered in England and Wales. Company number 1022 7405 &middot; &nbsp;VAT number 243 966 087
            </p>
            <p>
              Juno is regulated by the CLC &middot; &nbsp;Licence number 3338
            </p>
            <p>
              &copy; Juno Property Lawyers Limited
            </p>
          </Smallprint>
        </JunoFooterSection>
      </JunoFooterInner>
    </JunoFooter>
  );
}

export default Footer;

Footer.propTypes = {
  pageWidth: PropTypes.string.isRequired,
  reversed: PropTypes.bool.isRequired
};
