import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import { YES_NO_OPTIONS } from '../../constants';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class BuyingMortgage extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      buyerHasMortgage: 'Choose an option',
    };

    if (this.props.buyingMortgage) {
      const mort = this.props.buyingMortgage;
      this.state = {
        buyerHasMortgage: mort.buyerHasMortgage,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        buyerHasMortgage: null,
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let formIsValid = true;

    if (fields['buyerHasMortgage'] === null) {
      errors['buyerHasMortgage'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  saveBuyingMortgage = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        buyingMortgage: {
          buyerHasMortgage: this.state.buyerHasMortgage,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us if you’re getting a mortgage" removeTopPadding>
          <RadioQuestion
            question={this.props.clientIsCompany? "Will your company be taking out a mortgage on the property?" : "Are you taking out a mortgage on your new home?"}
            options={YES_NO_OPTIONS}
            inline={true}
            name="buyerHasMortgage"
            selected={this.state.buyerHasMortgage}
            optionSelected={this.handleInputChange('buyerHasMortgage')}
            validate={this.handleFieldValidation('buyerHasMortgage')}
            validationError={
              this.state.errors.buyerHasMortgage
                ? this.state.errorMessages.buyerHasMortgage
                : null
            }
          />

          <Action onClick={this.saveBuyingMortgage}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default BuyingMortgage;
