import React, { Component } from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';

class SignupSubmission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
    };

    const flowState = this.props.signupFlowState;

    let numberOfOwners = flowState.otherOwners
      ? flowState.otherOwners.numberOfOwners
      : 0;
    let otherOwners = [];
    if (numberOfOwners === 4) {
      otherOwners = [
        flowState.otherOwners.client2,
        flowState.otherOwners.client3,
        flowState.otherOwners.client4,
      ];
    } else if (numberOfOwners === 3) {
      otherOwners = [
        flowState.otherOwners.client2,
        flowState.otherOwners.client3,
      ];
    } else if (numberOfOwners === 2) {
      otherOwners = [flowState.otherOwners.client2];
    } else {
      otherOwners = [];
    }

    let numberOfClientsPurchasing = flowState.otherOwnersPurchasing
      ? flowState.otherOwnersPurchasing.numberOfClientsPurchasing
      : 0;
    let otherOwnersPurchasing = [];
    if (numberOfClientsPurchasing === 4) {
      otherOwnersPurchasing = [
        flowState.otherOwnersPurchasing.client2,
        flowState.otherOwnersPurchasing.client3,
        flowState.otherOwnersPurchasing.client4,
      ];
    } else if (numberOfClientsPurchasing === 3) {
      otherOwnersPurchasing = [
        flowState.otherOwnersPurchasing.client2,
        flowState.otherOwnersPurchasing.client3,
      ];
    } else if (numberOfClientsPurchasing === 2) {
      otherOwnersPurchasing = [flowState.otherOwnersPurchasing.client2];
    } else {
      otherOwnersPurchasing = [];
    }

    let numberOfClientsSelling = flowState.otherOwnersSelling
      ? flowState.otherOwnersSelling.numberOfClientsSelling
      : 0;
    let otherOwnersSelling = [];
    if (numberOfClientsSelling === 4) {
      otherOwnersSelling = [
        flowState.otherOwnersSelling.client2,
        flowState.otherOwnersSelling.client3,
        flowState.otherOwnersSelling.client4,
      ];
    } else if (numberOfClientsSelling === 3) {
      otherOwnersSelling = [
        flowState.otherOwnersSelling.client2,
        flowState.otherOwnersSelling.client3,
      ];
    } else if (numberOfClientsSelling === 2) {
      otherOwnersSelling = [flowState.otherOwnersSelling.client2];
    } else {
      otherOwnersSelling = [];
    }

    fetch('/api/signup', {
      method: 'PUT',
      body: JSON.stringify({
        leadId: flowState.leadId,
        transactionType: flowState.transactionType,

        sellingPropertyOfferAccepted: flowState.sellingPropertyOfferAccepted,
        sellingAddressDetails: flowState.sellingAddressDetails,
        sellingEstateAgent: flowState.sellingEstateAgent,
        sellingMortgagesAndLoans: flowState.sellingMortgagesAndLoans,

        buyingPropertyOfferAccepted: flowState.buyingPropertyOfferAccepted,
        buyingAddressDetails: flowState.buyingAddressDetails,
        buyingEstateAgent: flowState.buyingEstateAgent,
        buyingMortgageLender: flowState.buyingMortgageLender,
        buyingMortgageBroker: flowState.mortgageBroker,

        primaryClient: {
          ...flowState.yourName.client1,
          ...flowState.contactDetails,
        },
        otherOwners: otherOwners,
        otherOwnersPurchasing: otherOwnersPurchasing,
        otherOwnersSelling: otherOwnersSelling,
        anythingElse: flowState.anythingElse,
      }),
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
            submitted: true,
          });
          props.onFlowComplete();
        } else {
          this.setState({
            error: 'Unable to process your signup',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Unable to process your signup',
          loading: false,
        });
      },
    );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <div>
          <ScrollToTop />
          {this.state.error}
        </div>
      );
    }

    if (this.state.submitted) {
      return null;
    }
  }
}

export default SignupSubmission;
