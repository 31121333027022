import React from 'react';
import styled from 'styled-components/macro';
import { createId, createDataTestId } from './utils/helpers';
import Question, { Optional } from './Question';
import Label from './Label';
import Hint from './Hint';
import Input from './Input';
import Textarea from './Textarea';
import Error from './Error';
import { SecondaryButton } from './Button';

function TextQuestion(props) {
  const componentName = props.name ? props.name : null
  const componentId = createId('input', props.question, componentName);
  const dataTestId = createDataTestId('input', props.question, componentName);

  return (
    <Question>
      <Label htmlFor={componentId}>
        {props.question} {props.optional ? <Optional /> : null}
        {props.hint ? <Hint>{props.hint}</Hint> : null}
        {props.validationError ? <Error>{props.validationError}</Error> : null}
      </Label>
      <Input
        {...props}
        type={props.type ? props.type : 'text'}
        onChange={event => props.valueEntered(event.target.value)}
        onFocus={props.validate}
        onBlur={props.validate}
        id={componentId}
        data-testid={dataTestId}
        value={props.value}
        error={props.validationError ? props.validationError : null}
        disabled={props.disabled}
        size={props.size}
      />
    </Question>
  );
}

export function TextareaQuestion(props) {
  const componentName = props.name ? props.name : null
  const componentId = createId('textarea', props.question, componentName);
  const dataTestId = createDataTestId('textarea', props.question, componentName);

  return (
    <Question className={props.className}>
      <Label htmlFor={componentId}>
        {props.question} {props.optional ? <Optional /> : null}
        {props.validationError ? <Error>{props.validationError}</Error> : null}
        {props.hint ? <Hint>{props.hint}</Hint> : null}
      </Label>
      <Textarea {...props}
        onChange={event => props.valueEntered(event.target.value)}
        onFocus={props.validate}
        onBlur={props.validate}
        id={componentId}
        data-testid={dataTestId}
        value={props.value}
        error={props.validationError ? props.validationError : null}
        disabled={props.disabled}
        rows={props.rows || 5}
      />
    </Question>
  );
}

const QuestionButton = styled(SecondaryButton)`
  margin-top: 0.5em;
`;

export function TextQuestionWithButton(props) {
  const componentName = props.name ? props.name : null
  const componentId = createId('input', props.question, componentName);
  const dataTestId = createDataTestId('input', props.question, componentName);

  return (
    <Question>
      <Label htmlFor={componentId}>
        {props.question}
        {props.validationError ? <Error>{props.validationError}</Error> : null}
        {props.hint ? <Hint>{props.hint}</Hint> : null}
      </Label>
      <Input
        id={componentId}
        data-testid={dataTestId}
        type={props.type ? props.type : 'text'}
        onChange={event => props.valueEntered(event.target.value)}
        onBlur={event => {
          if (props.validate) {
            props.validate(event.target.value);
          }
        }}
        size={props.size}
        value={props.value}
        error={!!props.validationError}
      />
      <QuestionButton
        small
        disabled={props.buttonDisabled}
        onClick={props.buttonAction}
      >
        {props.buttonText}
      </QuestionButton>
    </Question>
  );
}

export default TextQuestion;
