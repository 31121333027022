import React, { Component } from 'react';
import Action from '../../components/Action';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';

import { BUYING_PROOF_OF_FUNDS_OPTIONS } from '../../constants';
import List, { ListItem } from '../../components/List';

class CheckHowIsBuyerPaying extends Component {

  formatSourceOfFunds(howIsBuyerPaying) {
    let payingName = Object.keys(howIsBuyerPaying)
      .filter(key => howIsBuyerPaying[key] === true)

    const fundingSources = payingName.map((name) => {
      const option = BUYING_PROOF_OF_FUNDS_OPTIONS.find(option => option.name === name);
      return option.title
    })

    return fundingSources;
  }

  continue = () => {
    this.props.onStepComplete()
  }

  render() {

    const howIsBuyerPaying = this.formatSourceOfFunds(this.props.howIsBuyerPaying);

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Let’s quickly check your funds" removeTopPadding>

          <p id="funds-list">You’re using these funds to pay for your property:</p>
          <List bullet aria-labelledby="funds-list">
            {howIsBuyerPaying.map((fundingSource, idx) => {
              return (
                <ListItem key={idx}>{fundingSource}</ListItem>
              )
            })}
            {this.props.onlyMortgage && (<ListItem>You’re only using a mortgage</ListItem>)}
            {this.props.noSavings && (<ListItem>You’re not using savings or investments</ListItem>)}
            {this.props.noMortgage && (<ListItem>You’re not taking out a mortgage on your new home</ListItem>)}
          </List>

          {(this.props.onlyMortgage || this.props.noSavings) && (
            <>
              <p>
                People often use savings or investments as well as a mortgage - to pay for a deposit.
              </p>
              <p>
                If you’d like to add a savings account{!this.props.onlyMortgage && ' or mortgage'}, choose “I’d like to add more funds” below.
              </p>
            </>
          )}

          {!this.props.noSavings && this.props.noMortgage && (
            <>
              <p>
                People often use a mortgage to pay for their property. If you’d like to add a mortgage, choose “I’d like to add more funds” below.
              </p>
            </>
          )}

          <Action onClick={this.continue}>
            I’m only using the funds above - continue
          </Action>

          <Action onClick={this.props.showPrevious} secondaryButton={true}>
            I’d like to add more funds
          </Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default CheckHowIsBuyerPaying;
