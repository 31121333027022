import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import { isEmailValid } from '../../utils/textUtils';

class ReferrerAgentAndClientDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agentName: this.props.agentName ? this.props.agentName : '',
      agentEmailAddress: this.props.agentEmailAddress ? this.props.agentEmailAddress : '',
      clientFirstName: this.props.personalDetails ? this.props.personalDetails.firstName : '',
      clientLastName: this.props.personalDetails ? this.props.personalDetails.lastName : '',
      clientEmailAddress: this.props.personalDetails ? this.props.personalDetails.emailAddress : '',
      clientPhoneNumber: this.props.personalDetails ? this.props.personalDetails.phoneNumber : '',
      errors: {},
    };
  }

  agentNameEntered = rn => {
    this.setState({ agentName: rn });
  };

  agentEmailAddressEntered = rn => {
    this.setState({ agentEmailAddress: rn });
  };

  clientFirstNameEntered = s => {
    this.setState({ clientFirstName: s });
  };

  clientLastNameEntered = s => {
    this.setState({ clientLastName: s });
  };

  clientEmailAddressEntered = s => {
    this.setState({ clientEmailAddress: s });
  };

  clientPhoneEntered = s => {
    this.setState({ clientPhoneNumber: s });
  };

  handleValidation = () => {
    const fields = { ...this.state };
    let errors = {};
    let formIsValid = true;

    if (fields['agentName'] === '') {
      errors['agentName'] = true;
      formIsValid = false;
    }

    if (fields['agentEmailAddress'] === '' ||
      !isEmailValid(fields['agentEmailAddress'])
    ) {
      errors['agentEmailAddress'] = 'Please enter a valid email address';
      formIsValid = false;
    }

    if (fields['clientFirstName'] === '') {
      errors['clientFirstName'] = true;
      formIsValid = false;
    }

    if (fields['clientLastName'] === '') {
      errors['clientLastName'] = true;
      formIsValid = false;
    }

    if (
      fields['clientEmailAddress'] === '' ||
      !isEmailValid(fields['clientEmailAddress'])
    ) {
      errors['clientEmailAddress'] = 'Please enter a valid email address';
      formIsValid = false;
    }

    if (
      fields['clientEmailAddress'] !== '' &&
      fields['agentEmailAddress'] !== '' &&
      String(fields['clientEmailAddress']).toLowerCase() === String(fields['agentEmailAddress']).toLowerCase()
    ) {
      errors['clientEmailAddress'] = "client's email address must be different to your email address";
      errors['agentEmailAddress'] = "your email address must be different to the client's email address";
      formIsValid = false;
    }

    if (
      fields['clientPhoneNumber'] === '' ||
      !this.isPhoneNumberValid(fields['clientPhoneNumber'])
    ) {
      errors['clientPhoneNumber'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  };

  isPhoneNumberValid = phone => {
    const re = /[0-9+() -]+$/;
    return re.test(String(phone));
  };

  handleEmailAddressValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (
        !this.state[field] ||
        !isEmailValid(this.state[field])
      ) {
        errors[field] = 'Please enter a valid email address';
      }
    }
    this.setState({ errors });
  };

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  handlePhoneNumberValidation = event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors.clientPhoneNumber = false;
    } else {
      if (
        !this.state.clientPhoneNumber ||
        !this.isPhoneNumberValid(this.state.clientPhoneNumber)
      ) {
        errors.clientPhoneNumber = true;
      }
    }
    this.setState({ errors });
  };

  saveDetails = () => {
    if (this.handleValidation()) {
      this.props.onStepComplete({
        agentName: this.state.agentName,
        agentEmailAddress: this.state.agentEmailAddress,
        personalDetails: {
          firstName: this.state.clientFirstName,
          lastName: this.state.clientLastName,
          emailAddress: this.state.clientEmailAddress,
          phoneNumber: this.state.clientPhoneNumber,
        },
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about yourself" removeTopPadding>
          <p>
            Make sure you get the credit for this referral – enter your details
            below.
          </p>
          <TextQuestion
            question="First, tell us your name"
            value={this.state.agentName}
            valueEntered={this.agentNameEntered}
            validate={this.handleFieldValidation('agentName')}
            validationError={
              this.state.errors['agentName'] ? 'Please enter your name' : null
            }
          />
          <TextQuestion
            question="And your email address"
            value={this.state.agentEmailAddress}
            valueEntered={this.agentEmailAddressEntered}
            validate={this.handleEmailAddressValidation('agentEmailAddress')}
            validationError={
              this.state.errors.agentEmailAddress
                ? this.state.errors.agentEmailAddress
                : null
            }
          />
        </Card>

        <Card title="Tell us your client’s details" removeTopPadding>
          <TextQuestion
            question="First name"
            value={this.state.clientFirstName}
            valueEntered={this.clientFirstNameEntered}
            validate={this.handleFieldValidation('clientFirstName')}
            validationError={
              this.state.errors['clientFirstName']
                ? 'Please enter their first name'
                : null
            }
          />

          <TextQuestion
            question="Last name"
            value={this.state.clientLastName}
            valueEntered={this.clientLastNameEntered}
            validate={this.handleFieldValidation('clientLastName')}
            validationError={
              this.state.errors.clientLastName ? 'Please enter their last name' : null
            }
          />

          <TextQuestion
            question="Email address"
            value={this.state.clientEmailAddress}
            valueEntered={this.clientEmailAddressEntered}
            validate={this.handleEmailAddressValidation('clientEmailAddress')}
            validationError={
              this.state.errors.clientEmailAddress
                ? this.state.errors.clientEmailAddress
                : null
            }
          />

          <TextQuestion
            question="Phone number"
            value={this.state.clientPhoneNumber}
            valueEntered={this.clientPhoneEntered}
            validate={this.handlePhoneNumberValidation}
            validationError={
              this.state.errors.clientPhoneNumber
                ? 'Please enter a valid phone number'
                : null
            }
          />

          <p>
            By pressing continue, you confirm that you have your client’s
            permission to share their details with Juno.
          </p>

          <Action onClick={this.saveDetails}>
            Continue
          </Action>
        </Card>

        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default ReferrerAgentAndClientDetails;
