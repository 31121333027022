import React from 'react';
import styled, { css } from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

const JunoTextarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 0.25em 0.75em 0.25em 0.25em;

  border: 2px solid ${props => props.theme.color.junoBorder};
  border-radius: 0;
  color: ${props => props.theme.color.junoText};
  resize: vertical;

  font-size: ${props => props.theme.fontSizes.p['sm']};
  line-height: normal;
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
  }

  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  /* stylelint-enable property-no-vendor-prefix */

  &:focus {
    outline: 3px solid ${props => props.theme.color.junoFocus};
  }

  ${props =>
    props.error &&
    css`
      border-width: 4px;
      border-color: ${props => props.theme.color.junoError};
    `};

  ${props =>
    props.disabled &&
    css`
      font-weight: bold;
      background: none;
      resize: none;
    `};
`;

function Textarea(props) {
  return <JunoTextarea {...props} />;
}

export default Textarea;
