import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';
import TextQuestion from '../../components/TextQuestion';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class QuoteBuyingPropertyMortgageAmount extends Component {
  constructor(props) {
    super(props);

    if (this.props.buyingDetails) {
      const d = this.props.buyingDetails;
      this.state = {
        mortgageAmount: d.mortgageAmount ? this.formatMoneyWithCurrency(d.mortgageAmount) : '',
        errors: {},
      };
    } else {
      this.state = {
        mortgageAmount: '',
        errors: {},
      };
    }
  }

  mortgageAmountEntered = p => {
    this.setState({ mortgageAmount: p });
  };

  formatMoneyWithCurrency = p => {
    let formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    });

    let moneyWithCurrency = formatter.format(p);

    if (moneyWithCurrency.includes('.00')) {
      let i = moneyWithCurrency.indexOf('.');
      return moneyWithCurrency.substring(0, i);
    }

    return moneyWithCurrency;
  };

  normalisePrice = p => {
    const stripCharsRegex = /[£, ]/g;
    return p.replace(stripCharsRegex, '');
  };

  isPriceValid = price => {
    const allowedCharsRegex = /^[0-9£,. ]+$/;
    if (allowedCharsRegex.test(String(price))) {
      const normalised = this.normalisePrice(price);

      const decimalPlaceRegex = /^\d+\.?\d{0,2}$/;

      return decimalPlaceRegex.test(String(normalised));
    }
    return false;
  };

  handleValidation = () => {
    const fields = { ...this.state };
    let errors = {};
    let formIsValid = true;

    if (fields['mortgageAmount'] == null || !this.isPriceValid(fields['mortgageAmount'])) {
      errors['mortgageAmount'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  };

  handlePriceFieldValidation = field => event => {
    let errors = { ...this.state.errors };
    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field] || !this.isPriceValid(this.state[field])) {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  saveSelection = () => {
    if (this.handleValidation()) {
      let buyingDetails = { ...this.props.buyingDetails }
      buyingDetails['mortgageAmount'] = this.state.mortgageAmount
      this.props.onStepComplete({
        buyingDetails: buyingDetails,
      });
    }
  };

  render() {
    let cardTitle;
    let priceHint;

    if (this.props.mode === 'direct') {
      cardTitle = 'Tell us about your mortgage';
      priceHint = 'If you’re not sure just put an estimate – you can change it later';
    } else {
      cardTitle = 'Tell us about their mortgage';
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card
          title={cardTitle}
          removeTopPadding
        >

          <TextQuestion
            question={this.props.mode === 'direct' ? 'Value of your new mortgage' : 'How much are they borrowing?'}
            hint={priceHint}
            value={this.state.mortgageAmount}
            valueEntered={this.mortgageAmountEntered}
            validate={this.handlePriceFieldValidation('mortgageAmount')}
            validationError={
              this.state.errors.mortgageAmount ? 'Please enter a valid price' : null
            }
          />

          <Action onClick={this.saveSelection}>Continue</Action>
        </Card>

        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default QuoteBuyingPropertyMortgageAmount;
