import React from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '../styles/media.js';

export const JunoLegend = styled.legend`
  line-height: normal;

  font-size: ${props => props.theme.fontSizes.p['sm']};
  padding-bottom: 0.5em;

  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.p['md']};
  }
`;

function Legend(props) {
  return <JunoLegend {...props}>{props.children}</JunoLegend>;
}

export default Legend;
