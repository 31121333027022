import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSelectedCaseRef } from "./utils/hooks";
import Action from '../../components/Action';
import Card from '../../components/DashboardCard';
import DynamicFormDoable from "./DynamicFormDoable";
import DocumentUploadDoable from "./DocumentUploadDoable";
import DynamicFormSubmission from "./DynamicFormSubmission";
import DocumentUploadSubmission from "./DocumentUploadSubmission";
import EnquiryDoable from "./EnquiryDoable";
import EmailedDoable from "./EmailedDoable";

const CaseDoable = () => {
  const sessionType = useSelector(state => state.dashboardState.session.session_type);

  const caseRef = useSelectedCaseRef();
  const { doableId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [doable, setDoable] = useState(null);

  useEffect(() => {
    fetch('/api/dashboard/doable/' + doableId, {
      method: 'GET',
      credentials: 'same-origin',
    }).then(
      resp => {
        if (! resp.ok) {
          throw new Error("non-success");
        }
        return resp.json();
      }
    ).then(data => {
      setDoable(data);
      setLoading(false);
    }).catch(err => {
      setErrored(true);
      setLoading(false);
    });
  }, [doableId]);

  // TODO: extract this into something shared with DashboardNav
  const returnToDashboard = () => {
    if (!! caseRef) {
      navigate(`/dashboard/${caseRef}`);
    } else {
      navigate('/dashboard');
    }
  }

  if (loading) {
    return null;
  }

  if (errored) {
    return (
      <React.Fragment>
        <Card
          title="Sorry, that didn't work"
          removeTopPadding
          headingSize="2"
        >
          <p>Failed to load request details.</p>
          <Action small onClick={returnToDashboard}>
            Take me back to try again
          </Action>
        </Card>
      </React.Fragment>
    );
  }

  let DoableComponent;
  switch (doable.doable_type) {
    case 'information_request':
      if (doable.status === 'complete') {
        DoableComponent = DynamicFormSubmission;
      } else {
        DoableComponent = DynamicFormDoable;
      }
      break;
    case 'document_request':
      if (doable.status === 'complete') {
        DoableComponent = DocumentUploadSubmission;
      } else {
        DoableComponent = DocumentUploadDoable;
      }
      break;
    case 'enquiry':
      DoableComponent = EnquiryDoable;
      break;
    default:
      // "emailed_request" or any other unexpected doable type
      DoableComponent = EmailedDoable;
  }

  const submissionEnabled = sessionType === "client";

  return <DoableComponent
    doable={doable}
    submissionEnabled={submissionEnabled}
    returnToDashboard={returnToDashboard}
  />
};

export default CaseDoable
