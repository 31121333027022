import React, {Component} from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';
import List, {ListItem} from '../../components/List';

class IdentityCheckPassFirstTime extends Component {
  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card
          title="Pass first time"
          removeTopPadding
          headingSize="1"
        >
          <p>
            Here's a few tips to help you pass your ID check first time:
          </p>
          <List>
            <ListItem>✅ Take your photo in a bright, evenly-lit room.</ListItem>
            <ListItem>✅ Avoid reflections and glare.</ListItem>
            <ListItem>❌ Do not take your photo in a dark room.</ListItem>
            <ListItem>❌ Don’t get too close – make sure it’s in focus.</ListItem>
          </List>

          <Action onClick={() => this.props.onStepComplete({})}>
            Continue
          </Action>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default IdentityCheckPassFirstTime;
