const color = {
  black: '#000000',
  offBlack: '#0D0D0D',
  darkGrey: '#2B323B',
  grey: '#627084',
  lightGrey: '#A7B1BE',
  paleGrey: '#E3E5E8',
  backgroundGrey: '#F5F5F5',
  offWhite: '#F1F2F4',
  white: '#FFFFFF',
  indigo: '#002457',
  darkBlue: '#003C8F',
  blue: '#0046A8',
  brightBlue: '#0060E6',
  lightBlue: '#4D97FF',
  paleBlue: '#B0C7E8',
  veryPaleBlue: '#CCE1FF',
  green: '#1E9952',
  brightGreen: '#00CC88',
  paleGreen: '#7bb79d',
  red: '#CC4428',
  yellow: '#F6CE55',
  paleYellow: '#FDF4D8',
  pink: '#F2A68C',
  palePink: '#FCEDE8',
  purple: '#483EA6',
  palePurple: '#D5D1FF',
  junoBrand: '#002457',
  junoPrimary: '#003C8F',
  junoAccent: '#0060E6',
  junoPage: '#F1F2F4',
  junoCard: '#FFFFFF',
  junoHeader: '#002457',
  junoHeaderInternal: '#0060E6',
  junoFooter: '#FFFFFF',
  junoFooterText: '#002457',
  junoFooterReversed: '#002457',
  junoText: '#0D0D0D',
  junoTextSecondary: '#627084',
  junoTextReversed: '#F1F2F4',
  junoTextReversedSecondary: '#A7B1BE',
  junoTextHighlight: '#FCEDE8',
  junoLink: '#0060E6',
  junoLinkActive: '#4D97FF',
  junoLinkHover: '#4D97FF',
  junoLinkVisited: '#0060E6',
  junoLinkReversedActive: '#FFFFFF',
  junoLinkReversedHover: '#FFFFFF',
  junoLinkReversedVisited: '#F1F2F4',
  junoBorder: '#A7B1BE',
  junoBorderReversed: '#FFFFFF',
  junoButton: '#003C8F',
  junoButtonText: '#F1F2F4',
  junoButtonShadow: '#002457',
  junoButtonHover: '#0060E6',
  junoSecondaryButton: '#f7f7f7',
  junoSecondaryButtonText: '#003C8F',
  junoSecondaryButtonShadow: '#003C8F',
  junoSecondaryButtonHover: '#d3d8de',
  junoCTAButton: '#00CC88',
  junoCTAButtonShadow: '#00CC88',
  junoCTAButtonHover: '#28d391',
  junoSuccess: '#1E9952',
  junoError: '#CC4428',
  junoFocus: '#F6CE55',
  text1: '#0f172a',
  text2: '#263142',
  text3: '#455366',
  text4: '#64748b',
};

const statusColor = {
  complete: '#1e9952',
  started: '#000000',
  not_started: '#627084', // matches the status we send from the staff app
}

// Font stack for the Juno logo
const junoLogoFont = [
  "Overpass, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
];

// Override this font stack with Inter for the agent dashboard
// Later we'll use Inter everywhere and remove this
const junoFont = [
  "Overpass, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
];

const junoFontMono = ["'Overpass Mono', monospace"];

const junoFontSecondary = `Roboto, Helvetica, Arial, sans-serif`;

const largeNumber = {
  sm: '36px',
  md: '42px',
  lg: '48px',
  xl: '60px',
};

const h1 = {
  sm: '28px',
  md: '30px',
  lg: '36px',
  xl: '36px',
};

const h2 = {
  sm: '24px',
  md: '26px',
  lg: '32px',
  xl: '32px',
};

const h3 = {
  sm: '20px',
  md: '22px',
  lg: '24px',
  xl: '24px',
};

const h4 = {
  sm: '16px',
  md: '18px',
  lg: '20px',
  xl: '22px',
};

const p = {
  sm: '16px',
  md: '18px',
  lg: '20px',
  xl: '20px',
};

const iconSize = {
  xs: '14px',
  sm: '20px',
  md: '24px',
  lg: '27px',
  xl: '36px',
}

const fontWeight = {
  light: 300,
  regular: 400,
  bold: 700,
};

const junoImageSpacing = {
  sm: '20px',
  md: '80px',
  lg: '100px',
  xl: '120px',
};

const spacingScale = {
  1: '0.25rem', // 4px
  2: '0.5rem', // 8px
  3: '.75rem', // 12px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '1.75rem', //28px
  8: '2rem', // 32px
  12: '3rem', // 48px
  16: '4rem', // 64px
  24: '6rem', // 96px
  32: '8rem', // 128px
  48: '12rem', // 192px
};

const gridSpacing = {
  columnWidth: 48,
  gutterWidth: 24,
}

const setPageWidth = (columns) => {
  return ((gridSpacing.columnWidth * columns) + gridSpacing.gutterWidth * (columns - 1))
}

const pageWidth = {
  narrow: setPageWidth(9),    // 624px - static site 'regular'
  regular: setPageWidth(12),  // 840px - static site 'wide'
  wide: 930,                  // 930px - client dashboard page width
  wider: setPageWidth(14),    // 984px - static site 'wider' (homepage width)
}

const bodyBackground = color.junoBrand;  // default dark blue body (to match footer)

const theme = {
  bodyBackground,
  junoLogoFont,
  junoFont,
  junoFontSecondary,
  junoFontMono,
  fontWeight,
  spacingScale,
  gridSpacing,
  pageWidth,
  color,
  statusColor,
  iconSize,
  fontSizes: {
    h1,
    h2,
    h3,
    h4,
    p,
    largeNumber,
  },
  junoImageSpacing,
};

export default theme;
