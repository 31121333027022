import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioConditional from '../../components/RadioConditional';
import RadioQuestion from '../../components/RadioQuestion';
import { YES_NO_OPTIONS } from '../../constants';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

class RemoPropertyInformation3 extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      rejectNegativeEquity: 'Please choose an option',
      clientIsPayingOffSomeOfLoan: 'Please choose an option',
      clientHasMoreThanOneCharge: 'Please choose an option',
      rejectNotPayingOffAllCharges: 'Please choose an option',
    };

    if (this.props.propertyInformation3) {
      const details = this.props.propertyInformation3;
      this.state = {
        rejectNegativeEquity: details.rejectNegativeEquity,
        clientIsPayingOffSomeOfLoan: details.clientIsPayingOffSomeOfLoan,
        clientHasMoreThanOneCharge: details.clientHasMoreThanOneCharge,
        rejectNotPayingOffAllCharges: details.rejectNotPayingOffAllCharges,
        errors: { ...details.errors },
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        rejectNegativeEquity: null,
        clientIsPayingOffSomeOfLoan: null,
        clientHasMoreThanOneCharge: null,
        rejectNotPayingOffAllCharges: null,
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let formIsValid = true;

    if (fields['rejectNegativeEquity'] === null) {
      errors['rejectNegativeEquity'] = true;
      formIsValid = false;
    }

    if (fields['clientIsPayingOffSomeOfLoan'] === null) {
      errors['clientIsPayingOffSomeOfLoan'] = true;
      formIsValid = false;
    }

    if (fields['clientHasMoreThanOneCharge'] === null) {
      errors['clientHasMoreThanOneCharge'] = true;
      formIsValid = false;
    }

    if (fields['clientHasMoreThanOneCharge'] === true) {
      if (fields['rejectNotPayingOffAllCharges'] === null) {
        errors['rejectNotPayingOffAllCharges'] = true;
        formIsValid = false;
      }
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  selectClientHasMoreThanOneCharge = event => {
    this.setState(
      {
        clientHasMoreThanOneCharge: event,
      },
      () => {
        this.resetRejectNotPayingOffAllCharges();
      },
    );
  };

  resetRejectNotPayingOffAllCharges = () => {
    if (this.state.clientHasMoreThanOneCharge === false) {
      this.setState({ rejectNotPayingOffAllCharges: null });
    }
  };

  savePropertyInformation3 = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.props.onStepComplete({
        propertyInformation3: {
          rejectNegativeEquity: this.state.rejectNegativeEquity,
          clientIsPayingOffSomeOfLoan: this.state.clientIsPayingOffSomeOfLoan,
          clientHasMoreThanOneCharge: this.state.clientHasMoreThanOneCharge,
          rejectNotPayingOffAllCharges: this.state.rejectNotPayingOffAllCharges,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={this.props.clientIsCompany ? "Tell us about the remortgage" : "Tell us about your remortgage"} removeTopPadding>
          <RadioQuestion
            question={this.props.clientIsCompany? "Is your company borrowing less than it currently owes?" : "Are you borrowing less than you currently owe?"}
            hint={this.props.clientIsCompany? "Is the company in ‘negative equity’?" : "Are you in ‘negative equity’?"}
            options={YES_NO_OPTIONS}
            inline={true}
            name="rejectNegativeEquity"
            selected={this.state.rejectNegativeEquity}
            optionSelected={this.handleInputChange('rejectNegativeEquity')}
            validate={this.handleFieldValidation('rejectNegativeEquity')}
            validationError={
              this.state.errors['rejectNegativeEquity']
                ? this.state.errorMessages['rejectNegativeEquity']
                : null
            }
          />

          <RadioQuestion
            question={this.props.clientIsCompany? "Does your company plan to make any extra payments on your existing mortgage?" : "Do you plan to make any extra payments on your existing mortgage?"}
            options={YES_NO_OPTIONS}
            inline={true}
            name="clientIsPayingOffSomeOfLoan"
            selected={this.state.clientIsPayingOffSomeOfLoan}
            optionSelected={this.handleInputChange(
              'clientIsPayingOffSomeOfLoan',
            )}
            validate={this.handleFieldValidation('clientIsPayingOffSomeOfLoan')}
            validationError={
              this.state.errors['clientIsPayingOffSomeOfLoan']
                ? this.state.errorMessages['clientIsPayingOffSomeOfLoan']
                : null
            }
          />

          <RadioQuestion
            question={this.props.clientIsCompany? "Is there more than one mortgage (or loan) on the property?" : "Do you have more than one mortgage (or loan) on the property?"}
            options={YES_NO_OPTIONS}
            inline={true}
            name="clientHasMoreThanOneCharge"
            selected={this.state.clientHasMoreThanOneCharge}
            optionSelected={this.selectClientHasMoreThanOneCharge}
            validate={this.handleFieldValidation('clientHasMoreThanOneCharge')}
            validationError={
              this.state.errors['clientHasMoreThanOneCharge']
                ? this.state.errorMessages['clientHasMoreThanOneCharge']
                : null
            }
          />

          {this.state.clientHasMoreThanOneCharge === true && (
            <RadioConditional>
              <RadioQuestion
                question="Are you planning to pay off all the existing mortgages and loans secured on the property by doing this remortgage?"
                options={YES_NO_OPTIONS}
                inline={true}
                name="rejectNotPayingOffAllCharges"
                selected={this.state.rejectNotPayingOffAllCharges}
                optionSelected={this.handleInputChange(
                  'rejectNotPayingOffAllCharges',
                )}
                validate={this.handleFieldValidation(
                  'rejectNotPayingOffAllCharges',
                )}
                validationError={
                  this.state.errors['rejectNotPayingOffAllCharges']
                    ? this.state.errorMessages['rejectNotPayingOffAllCharges']
                    : null
                }
              />
            </RadioConditional>
          )}

          <Action onClick={this.savePropertyInformation3}>Continue</Action>
        </Card>
        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default RemoPropertyInformation3;
