import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';
import { mediaQueriesUntil } from '../../styles/media.js';

import PageHeader from "../../components/PageHeader";
import DashboardCard from '../../components/DashboardCard';
import ReferralPanel from '../../components/ReferralPanel';
import BigNumberedList from '../../components/BigNumberedList';
import ScrollToTop from '../../components/utils/ScrollToTop';


class ClientReferral extends React.Component {

  render() {

    return (
      <>
        <ScrollToTop />
        <ReferralPageHeader>
          {this.props.client.first_name}, let your friends know about Juno
        </ReferralPageHeader>

        <ReferralPanel
          showReferral={true}
          showFindOutMore={false}
          referralCode={this.props.client.referral_code.code} />

        <DashboardCard title="How it works" headingSize="1">

          <BigNumberedList>
            <li>
              Invite your friends, using your link
            </li>
            <li>
              When they sign up they’ll get a £75 discount on their legal fees.
            </li>
            <li>
              When they complete their move we’ll send you a £75 gift voucher – choose from dozens of retailers, including Amazon, John Lewis, Tesco and more
            </li>
          </BigNumberedList>

          <p>
            <em>There’s no limit on the number of people who can use your personal referral code.<br />
            <a href="https://www.juno.legal/client-referrals/" target="_blank" rel="noopener noreferrer">Terms apply.</a></em>
          </p>

        </DashboardCard>
      </>
    );
  }
}

export default ClientReferral

ClientReferral.propTypes = {
  client: PropTypes.object.isRequired
};

const ReferralPageHeader = styled(PageHeader)`
  ${mediaQueriesUntil.md} {
    margin-bottom: 0;
    padding-bottom: 0.5em;
  }
`;
