import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useClientAppSignupRequest from '../../hooks/useClientAppSignupRequest';
import PageLayout from '../../components/PageLayout';
import StepFlowNavigate from '../../components/StepFlowNavigate';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';

import RemoYourName from './RemoYourName';
import RemoContactDetails from './RemoContactDetails';
import RemoCompletionDate from './RemoCompletionDate';
import RemoMortgageBroker from './RemoMortgageBroker';
import RemoPropertyAddress from './RemoPropertyAddress';
import RemoPropertyInformation1 from './RemoPropertyInformation1';
import RemoPropertyInformation2 from './RemoPropertyInformation2';
import RemoPropertyInformation3 from './RemoPropertyInformation3';
import RemoPropertySorry from './RemoPropertySorry';
import RemoOtherOwners from './RemoOtherOwners';
import RemoOtherOccupiers from './RemoOtherOccupiers';
import RemoAnythingElse from './RemoAnythingElse';
import RemoTermsAndConditions from './RemoTermsAndConditions';
import RemoSubmission from './RemoSubmission';
import RemoThanks from './RemoThanks';


const RemoSignupFlow = (props) => {

  const { signupId } = useParams();
  const [flowCompleted, setFlowCompleted] = useState(false);

  const { loading, error, signupRequest, submitted } = useClientAppSignupRequest(`/api/signup/${signupId}`);

  const shouldShowMortgageBroker = flowState => {
    if (flowState.haveMortgageBrokerDetails) {
      return false
    } else {
      return true
    }
  }

  const shouldShowPropertyAddress = flowState => {
    return flowState.contactDetails.propertyAddressIsClientAddress === false;
  };

  const shouldRejectSharedOwnershipProperties = flowState => {
    return flowState.propertyInformation1.rejectSharedOwnership === true;
  };

  const shouldRejectMultipleTitles = flowState => {
    return flowState.propertyInformation1.rejectMultipleTitles === true;
  };

  const shouldRejectPropertiesWithNegativeEquity = flowState => {
    return (
      flowState.propertyInformation3.rejectNegativeEquity === true ||
      (
        flowState.propertyInformation3.clientHasMoreThanOneCharge === true &&
        flowState.propertyInformation3.rejectNotPayingOffAllCharges === false
      )
    );
  };

  if (!!loading) {
    return null;
  }

  if (submitted || flowCompleted) {
    return <RemoThanks />;
  }

  if (error) {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Sorry, that didn't work" removeTopPadding>
          {error}
        </Card>
      </React.Fragment>
    );
  }

  return (
    <PageLayout>
      <StepFlowNavigate
        initialFlowState={{
          leadId: signupId,
          transactionType: signupRequest.transactionType,
          leadSource: signupRequest.leadSource,
          clientIsCompany: signupRequest.clientIsCompany,
          yourName: {
            client1: {
              companyName: '',
              firstName: signupRequest.firstName,
              lastName: signupRequest.lastName,
              emailAddress: signupRequest.email,
              hasPreviousName: null,
              previousName: '',
              firstNameIsGivenName: null,
              givenName: '',
            },
          },
          contactDetails: {
            emailAddress: signupRequest.email,
            phoneNumber: signupRequest.phoneNumber,
            propertyAddressIsClientAddress: null,
            clientAddress: {
              addressLine1: '',
              addressLine2: '',
              addressLine3: '',
              addressTown: '',
              addressCounty: '',
              addressPostcode: '',
            },
          },
          haveMortgageBrokerDetails: signupRequest.haveMortgageBrokerDetails,
          isBuyToLet: signupRequest.isBuyToLet,
        }}
        steps={[
          {
            slug: 'your-name',
            component: flowState => (
              <RemoYourName
                transactionType={flowState.transactionType}
                clientIsCompany={flowState.clientIsCompany}
                yourName={flowState.yourName}
              />
            ),
          },
          {
            slug: 'contact-details',
            component: flowState => (
              <RemoContactDetails
                transactionType={flowState.transactionType}
                clientIsCompany={flowState.clientIsCompany}
                leadSource={flowState.leadSource}
                contactDetails={flowState.contactDetails}
              />
            ),
          },
          {
            slug: 'property-address',
            valid: flowState => shouldShowPropertyAddress(flowState),
            component: flowState => (
              <RemoPropertyAddress
                propertyAddress={flowState.propertyAddress}
                clientIsCompany={flowState.clientIsCompany}
                leadSource={flowState.leadSource}
              />
            ),
          },
          {
            slug: 'mortgage-broker',
            valid: flowState => shouldShowMortgageBroker(flowState),
            component: flowState => (
              <RemoMortgageBroker mortgageBroker={flowState.mortgageBroker} />
            ),
          },
          {
            slug: 'property-information-1',
            component: flowState => (
              <RemoPropertyInformation1
                propertyInformation1={flowState.propertyInformation1}
                clientIsCompany={flowState.clientIsCompany}
                leadSource={flowState.leadSource}
                isBuyToLet={flowState.isBuyToLet}
              />
            ),
          },
          {
            slug: 'sorry-shared-ownership',
            valid: flowState =>
              shouldRejectSharedOwnershipProperties(flowState),
            component: flowState => (
              <RemoPropertySorry
                propertyInformation1={flowState.propertyInformation1}
              />
            ),
          },
          {
            slug: 'sorry-multiple-titles',
            valid: flowState =>
              shouldRejectMultipleTitles(flowState),
            component: flowState => (
              <RemoPropertySorry
                propertyInformation1={flowState.propertyInformation1}
              />
            ),
          },
          {
            slug: 'property-information-2',
            component: flowState => (
              <RemoPropertyInformation2
                propertyInformation2={flowState.propertyInformation2}
                clientIsCompany={flowState.clientIsCompany}
                leadSource={flowState.leadSource}
              />
            ),
          },
          {
            slug: 'property-information-3',
            component: flowState => (
              <RemoPropertyInformation3
                propertyInformation3={flowState.propertyInformation3}
                clientIsCompany={flowState.clientIsCompany}
              />
            ),
          },
          {
            slug: 'sorry-negative-equity',
            valid: flowState =>
              shouldRejectPropertiesWithNegativeEquity(flowState),
            component: flowState => (
              <RemoPropertySorry
                propertyInformation3={flowState.propertyInformation3}
              />
            ),
          },
          {
            slug: 'other-owners',
            component: flowState => (
              <RemoOtherOwners
                transactionType={flowState.transactionType}
                clientIsCompany={flowState.clientIsCompany}
                otherOwners={flowState.otherOwners}
              />
            ),
          },
          {
            slug: 'other-occupiers',
            component: flowState => (
              <RemoOtherOccupiers otherOccupiers={flowState.otherOccupiers}
                                  clientIsCompany={flowState.clientIsCompany} />
            ),
          },
          {
            slug: 'completion-date',
            component: flowState => (
              <RemoCompletionDate completionDate={flowState.completionDate}
                                  clientIsCompany={flowState.clientIsCompany} />
            ),
          },
          {
            slug: 'anything-else',
            component: flowState => (
              <RemoAnythingElse anythingElse={flowState.anythingElse} leadSource={flowState.leadSource} />
            ),
          },
          {
            slug: 'terms-and-conditions',
            component: flowState => (
              <RemoTermsAndConditions
                termsAndConditions={flowState.termsAndConditions}
                clientIsCompany={flowState.clientIsCompany}
              />
            ),
          },
          {
            slug: 'thanks',
            component: flowState => (
              <RemoSubmission
                remoSignupFlowState={flowState}
                leadSource={flowState.leadSource}
                onFlowComplete={() => setFlowCompleted(true)} />
            ),
            terminal: true,
          },
        ]}
      />
    </PageLayout>
  );
}

export default RemoSignupFlow;
