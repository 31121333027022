import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import TextQuestion from '../../components/TextQuestion';
import Action from '../../components/Action';
import { isMoneyValid, normaliseMoney } from '../../utils/moneyUtils';
import { getOrdinalString } from '../../utils/numberUtils';

const accountTypeConfiguration = new Map([
  [
    'savings',
    {
      outputStateObjectRoot: 'savingsAccount',
      pageViewRoot: 'savings_account_',
      titleAccountDescription: 'account you have saved or invested money in',
      accountDescription: 'savings',
    },
  ],
  [
    'helpToBuyIsa',
    {
      outputStateObjectRoot: 'helpToBuyIsaAccount',
      pageViewRoot: 'help_to_buy_isa_account_',
      titleAccountDescription: 'Help to Buy ISA account',
      accountDescription: 'Help To Buy ISA',
    },
  ],
  [
    'lifetimeIsa',
    {
      outputStateObjectRoot: 'lifetimeIsaAccount',
      pageViewRoot: 'lifetime_isa_account_',
      titleAccountDescription: 'Lifetime ISA account',
      accountDescription: 'Lifetime ISA',
    },
  ],
]);

class AccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.initStateFromProps(props);
  }

  initStateFromProps(props) {
    const errorMessages = {
      accountOwner: 'Please fill this in',
      accountBank: 'Please fill this in',
      accountAmount: 'Please fill this in',
      accountExplanation: 'Please fill this in',
      accountIdentifier: 'Please fill this in',
    };

    if (props.savingsAccount) {
      const savingsAccount = { ...props.savingsAccount };
      return {
        accountOwner: savingsAccount.accountOwner,
        accountBank: savingsAccount.accountBank,
        accountAmount: savingsAccount.accountAmount,
        accountIdentifier: savingsAccount.accountIdentifier,
        accountExplanation: savingsAccount.accountExplanation,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      return {
        accountOwner: '',
        accountBank: '',
        accountAmount: '',
        accountIdentifier: '',
        accountExplanation: '',
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.savingsAccount !== prevProps.savingsAccount ||
      this.props.accountNumber !== prevProps.accountNumber ||
      this.props.accountType !== prevProps.accountType ||
      this.props.accountIdentifier !== prevProps.accountIdentifier ||
      this.props.accountExplanation !== prevProps.accountExplanation
    ) {
      this.setState(this.initStateFromProps(this.props));
    }
  }

  accountTypeConfig = configKey => {
    const conf = accountTypeConfiguration.get(this.props.accountType);

    if (conf) {
      return conf[configKey];
    }
    console.warn('unsupported account type', this.props.accountType);
  };

  handleInputChange = field => value => {
    this.setState({ [field]: value });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };
    let formIsValid = true;

    if (!fields['accountOwner']) {
      errors['accountOwner'] = true;
      formIsValid = false;
    }

    if (!fields['accountBank']) {
      errors['accountBank'] = true;
      formIsValid = false;
    }

    if (!fields['accountAmount']) {
      errors['accountAmount'] = true;
      formIsValid = false;
    }

    if (!fields['accountExplanation']) {
      errors['accountExplanation'] = true;
      formIsValid = false;
    }

    if (!fields['accountIdentifier'] && (this.accountTypeConfig('outputStateObjectRoot') === "savingsAccount")){
      errors['accountIdentifier'] = true;
      formIsValid = false;
    }

    if (
      fields['accountAmount'] !== '' &&
      !isMoneyValid(fields['accountAmount'])
    ) {
      errors['accountAmount'] = true;
      errorMessages['accountAmount'] = 'Please enter a valid amount';
      formIsValid = false;
    }

    this.setState({ errors });
    this.setState({ errorMessages });
    return formIsValid;
  }

  handleFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!this.state[field]) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  handleMoneyValidation = field => event => {
    let errors = { ...this.state.errors };
    let errorMessages = { ...this.state.errorMessages };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (!isMoneyValid(this.state[field])) {
        errors[field] = true;
        errorMessages[field] = 'Please enter a valid amount';
      }
      if (!this.state[field]) {
        errors[field] = true;
        errorMessages[field] = 'Please fill this in';
      }
    }

    this.setState({ errors });
    this.setState({ errorMessages });
  };

  saveAccount = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      const outAccount = {};
      outAccount[
        this.accountTypeConfig('outputStateObjectRoot') +
          this.props.accountNumber
      ] = {
        accountOwner: this.state.accountOwner,
        accountBank: this.state.accountBank,
        accountAmount: normaliseMoney(this.state.accountAmount),
        accountIdentifier: this.state.accountIdentifier,
        accountExplanation: this.state.accountExplanation,
      };

      this.props.onStepComplete({
        ...outAccount,
      });
      return;
    }
  };

  render() {
    const ordinalString = getOrdinalString(this.props.accountNumber);

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card
          title={
            'Tell us about the ' +
            ordinalString +
            ' ' +
            this.accountTypeConfig('titleAccountDescription')
          }
          removeTopPadding
        >
          <TextQuestion
            question={
              'Whose name is the ' +
              ordinalString +
              ' ' +
              this.accountTypeConfig('accountDescription') +
              ' account under?'
            }
            value={this.state.accountOwner}
            valueEntered={this.handleInputChange('accountOwner')}
            validate={this.handleFieldValidation('accountOwner')}
            validationError={
              this.state.errors.accountOwner
                ? this.state.errorMessages.accountOwner
                : null
            }
          />

          <TextQuestion
            question={
              'What bank is the ' +
              ordinalString +
              ' ' +
              this.accountTypeConfig('accountDescription') +
              ' account with?'
            }
            value={this.state.accountBank}
            valueEntered={this.handleInputChange('accountBank')}
            validate={this.handleFieldValidation('accountBank')}
            validationError={
              this.state.errors.accountBank
                ? this.state.errorMessages.accountBank
                : null
            }
          />

          <TextQuestion
            question="How much is saved towards your purchase in this account?"
            value={this.state.accountAmount}
            valueEntered={this.handleInputChange('accountAmount')}
            validate={this.handleMoneyValidation('accountAmount')}
            validationError={
              this.state.errors.accountAmount
                ? this.state.errorMessages.accountAmount
                : null
            }
          />

          <TextQuestion
            question="Please give a brief explanation of how you built up the total amount shown in your account"
            hint="We'll ask to see the last 6 months of bank statements for this account"
            value={this.state.accountExplanation}
            valueEntered={this.handleInputChange('accountExplanation')}
            validate={this.handleFieldValidation('accountExplanation')}
            validationError={
              this.state.errors.accountExplanation
                ? this.state.errorMessages.accountExplanation
                : null
            }
          />

          <TextQuestion
            question="What are the last four digits of the account number?"
            hint={this.accountTypeConfig('outputStateObjectRoot') === "savingsAccount" && ("If there isn't an account number, please enter '0000'") }
            size="small"
            value={this.state.accountIdentifier}
            valueEntered={this.handleInputChange('accountIdentifier')}
            validate={this.handleFieldValidation('accountIdentifier')}
            validationError={
              this.state.errors.accountIdentifier
                ? this.state.errorMessages.accountIdentifier
                : null
            }
          />

          <Action onClick={this.saveAccount}>Continue</Action>
        </Card>
      </React.Fragment>
    );
  }
}

export default AccountForm;
