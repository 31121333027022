import React, { Component } from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';

class BrokerQuoteSubmission extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };

    fetch('/api/quote', {
      method: 'PUT',
      body: JSON.stringify({
        transactionType: this.props.transactionType,
        channel: this.props.channel,
        campaign: this.props.campaign,
        agentName: this.props.broker.fullName,
        agentEmailAddress: this.props.broker.emailAddress,
        additionalInformation: this.props.additionalInformation,
        personalDetails: this.props.personalDetails,
        sellingDetails:
          this.props.transactionType === 'Selling' ||
            this.props.transactionType === 'BuyingAndSelling'
            ? this.props.sellingDetails
            : null,
        buyingDetails:
          this.props.transactionType === 'Buying' ||
            this.props.transactionType === 'BuyingAndSelling'
            ? this.props.buyingDetails
            : null,
        remortgagingDetails:
          this.props.transactionType === 'Remortgaging'
            ? this.props.remortgagingDetails
            : null,
        referrer: null,
        referralCode: this.props.referral.referralCode,
        googleAnalyticsId: this.props.googleAnalyticsId
      }),
    })
      .then(resp => resp.json())
      .then(
        quoteData => {
          this.setState({
            quoteDetails: quoteData,
            loading: false,
            submitted: true,
          });
          props.onFlowComplete({
            quoteDetails: quoteData,
          });
        },
        () => {
          this.setState({
            error: 'Unable to generate your quote',
            loading: false,
          });
        },
      );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn't work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    if (this.state.submitted) {
      return null;
    }
  }
}

export default BrokerQuoteSubmission;
