import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Action from '../../components/Action';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

class IdentityCheckThanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };

    const flowState = this.props.identityCheckFlowState;

    fetch('/api/identityCheck', {
      method: 'PUT',
      body: JSON.stringify({
        formRequestId: flowState.formRequestId,
        onfido_submission: flowState.onfidoData,
      }),
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            error: 'Unable to process your details',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Unable to process your details',
          loading: false,
        });
      },
    );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn’t work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="That’s it, thanks!" removeTopPadding>
          <p>Thanks for telling us about yourself.</p>
          <p>
            If there’s anything else we need to check with you, we’ll send you
            an email soon.
          </p>
          {this.props.fromDashboard && (
            <Action to="/dashboard" as={Link}>
              Take me back to my dashboard
            </Action>
          )}
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default IdentityCheckThanks;
