import React, { Component } from 'react';
import TextQuestion from '../../components/TextQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';
import Action from '../../components/Action';
import List, { ListItem } from '../../components/List';
import TrustpilotBanner from '../../components/TrustpilotBanner';
import {isEmailValid} from "../../utils/textUtils";


class SaveQuoteForm extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor(props) {
    super(props);

    if (this.props.personalDetails) {
      const details = this.props.personalDetails;
      this.state = {
        firstName: details.firstName,
        lastName: details.lastName,
        emailAddress: details.emailAddress,
        phoneNumber: details.phoneNumber,
        errors: {},
        quoteDetails: null,
        loading: true,
        error: null,
      };
    } else {
      this.state = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        errors: {},
        quoteDetails: null,
        loading: true,
        error: null,
      };
    }

    let channel = this.props.defaultChannel;
    if (this.props.referral && this.props.referral.referralSource) {
      channel = this.props.referral.referralSource;
    }

    fetch('/api/partialQuote', {
      method: 'PUT',
      body: JSON.stringify({
        channel: channel,
        campaign: this.props.campaign,
        sellingDetails:
          this.props.transactionType === 'Selling' ||
            this.props.transactionType === 'BuyingAndSelling'
            ? this.props.sellingDetails
            : null,
        buyingDetails:
          this.props.transactionType === 'Buying' ||
            this.props.transactionType === 'BuyingAndSelling'
            ? this.props.buyingDetails
            : null,
        remortgagingDetails:
          this.props.transactionType === 'Remortgaging'
            ? this.props.remortgagingDetails
            : null,
        referralCode: this.props.referral ? this.props.referral.referralCode : null,
        googleAnalyticsId: this.props.googleAnalyticsId,
      }),
    })
      .then(resp => resp.json())
      .then(
        quoteData => {
          if (this._isMounted) {
            this.setState({
              quoteDetails: quoteData,
              loading: false,
            });
          }
        },
        () => {
          if (this._isMounted) {
            this.setState({
              error: 'Unable to generate your quote',
              loading: false,
            });
          }
        },
      );
  }

  firstNameEntered = s => {
    this.setState({ firstName: s });
  };

  lastNameEntered = s => {
    this.setState({ lastName: s });
  };

  emailEntered = s => {
    this.setState({ emailAddress: s });
  };

  phoneEntered = s => {
    this.setState({ phoneNumber: s });
  };

  isPhoneNumberValid = phone => {
    const re = /[0-9+() -]+$/;
    return re.test(String(phone));
  };

  handleEmailAddressValidation = event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors.emailAddress = false;
    } else {
      if (
        !this.state.emailAddress ||
        !isEmailValid(this.state.emailAddress)
      ) {
        errors.emailAddress = true;
      }
    }
    this.setState({ errors });
  };

  handleNameFieldValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (this.state[field] === '') {
        errors[field] = true;
      }
    }
    this.setState({ errors });
  };

  handlePhoneNumberValidation = event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors.phoneNumber = false;
    } else {
      if (
        !this.state.phoneNumber || !this.isPhoneNumberValid(this.state.phoneNumber)
      ) {
        errors.phoneNumber = true;
      }
    }
    this.setState({ errors });
  };

  handleValidation = () => {
    const fields = { ...this.state };
    let errors = {};
    let formIsValid = true;

    if (fields['firstName'] === '') {
      errors['firstName'] = true;
      formIsValid = false;
    }

    if (fields['lastName'] === '') {
      errors['lastName'] = true;
      formIsValid = false;
    }

    if (
      fields['phoneNumber'] === '' || !this.isPhoneNumberValid(fields['phoneNumber'])
    ) {
      errors['phoneNumber'] = true;
      formIsValid = false;
    }

    if (
      fields['emailAddress'] === '' ||
      !isEmailValid(fields['emailAddress'])
    ) {
      errors['emailAddress'] = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  };

  continueImmediately = () => {
    if (this.handleValidation()) {
      this.props.onStepComplete({
        progress: 'immediately',
        personalDetails: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          emailAddress: this.state.emailAddress,
          phoneNumber: this.state.phoneNumber,
        },
      });
    }
  };

  render() {

    if (!!this.state.loading) {
      return null;
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="You’re almost there..." removeTopPadding>

          <>
            <p>Add your details below so you can get:</p>
            <List bullet>
              <ListItem>
                A full copy of your quote
              </ListItem>
              <ListItem>
                Your price locked in for 30 days
              </ListItem>
              <ListItem>
                A free consultation with one of our customer support specialists
              </ListItem>
            </List>
          </>

          <TextQuestion
            question="First name"
            value={this.state.firstName}
            valueEntered={this.firstNameEntered}
            validate={this.handleNameFieldValidation('firstName')}
            validationError={
              this.state.errors['firstName']
                ? 'Please enter your first name'
                : null
            }
          />

          <TextQuestion
            question="Last name"
            value={this.state.lastName}
            valueEntered={this.lastNameEntered}
            validate={this.handleNameFieldValidation('lastName')}
            validationError={
              this.state.errors.lastName ? 'Please enter your last name' : null
            }
          />

          <TextQuestion
            question="Email address"
            value={this.state.emailAddress}
            valueEntered={this.emailEntered}
            validate={this.handleEmailAddressValidation}
            validationError={
              this.state.errors.emailAddress
                ? 'Please enter a valid email address'
                : null
            }
          />

          <TextQuestion
            question="Phone number"
            hint="Enter your number for a free consultation with our specialists"
            value={this.state.phoneNumber}
            valueEntered={this.phoneEntered}
            validate={this.handlePhoneNumberValidation}
            validationError={
              this.state.errors.phoneNumber
                ? 'Please enter a valid phone number'
                : null
            }
          />

          <Action onClick={this.continueImmediately}>Continue</Action>
        </Card>
        <TrustpilotBanner pageWidth={this.props.pageWidth} />
      </React.Fragment>
    );
  }
}

export default SaveQuoteForm;
