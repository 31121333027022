import React from 'react';
import styled from 'styled-components/macro';
import List, { ListItem } from '../components/List';
import Text from '../components/Text';
import Table from '../components/Table';
import Heading from '../components/Heading';
import { Link } from 'react-router-dom';
import DescriptionList, { DescriptionListDetail, DescriptionListTerm, } from '../components/DescriptionList';

const JunoTermsVersion = styled.p`
  margin-top: 0;
  font-size: 1rem;
  color: ${props => props.theme.color.junoTextSecondary};
`;

function JunoTermsAndConditions() {
  return (
    <React.Fragment>
      <Text>
        <p>
          When you agree to these terms, you’re appointing us as your lawyers in order to buy, sell or remortgage a property, or some combination of the above.
        </p>

        <Heading level="3" id="who-we-are">Who we are</Heading>
        <p>
          We’re Juno Property Lawyers Limited, a law firm offering conveyancing
          services in England and Wales. We’re looking forward to working with you.
        </p>
        <p>
          We’re a team of 30+ conveyancers with more than 200 years of experience between us.
          We’re a mix of qualified Solicitors, Licenced Conveyancers, Chartered Legal
          Executives and non-qualified paralegals, all working together to make your move happen.
        </p>

        <Heading level="3" id="words-we-use-in-these-terms">Words we use in these terms</Heading>
        <p>
          In these terms, the following words and phrases have the following
          meanings:
        </p>
        <List bullet>
          <ListItem>
            ‘Client’ or ‘you’ means the person agreeing
            to these terms (if you’re accepting these terms on behalf of a
            company, ‘client’ or ‘you’ means the company
            in question).
          </ListItem>
          <ListItem>
            ‘Juno’, ‘we’ or ‘us’ means Juno
            Property Lawyers Limited.
          </ListItem>
          <ListItem>
            ‘Terms’ means these terms and conditions.
          </ListItem>
          <ListItem>
            ‘Sale’ or ‘selling’ means a sale transaction.
          </ListItem>
          <ListItem>
            ‘Purchase’ or ‘buying’ means a purchase transaction.
          </ListItem>
          <ListItem>
            ‘Remortgage’ or ‘remortgaging’ means a remortgage transaction.
          </ListItem>
          <ListItem>
            ‘Complete’ or ‘completion’ means the point when we successfully complete your sale, purchase or remortgage in line with standard conveyancing practice.
          </ListItem>
          <ListItem>
            ‘Fall through’ or ‘fail’ means a sale to a given buyer or purchase from a given seller that did not exchange, or a remortgage of a given property with a given lender that did not complete.
          </ListItem>
        </List>

        <Heading level="3" id="who-can-agree-to-these-terms">
          Who can agree to these terms
        </Heading>

        <p>
          You’re agreeing to these terms on your own behalf.
        </p>
        <p>
          You can’t agree to these terms on anyone else’s behalf, unless you’re
          someone’s personal representative, you’re an executor, or you’ve been
          granted Power of Attorney. If this is the case we will ask for proof
          that you have authority to act on behalf of that person.
        </p>
        <p>
          If more than one person or company is appointing us, you agree to
          these terms both individually and as a group (‘jointly and
          severally’).
        </p>

        <Heading level="3" id="our-service">
          Our service
        </Heading>
        <p>
          We’ll act as your lawyers so you can buy, sell, or remortgage a property.
        </p>
        <p>
          Our role is to:
        </p>
        <List bullet>
          <ListItem>
            investigate who owns the property.
          </ListItem>
          <ListItem>
            agree contracts on a sale or purchase.
          </ListItem>
          <ListItem>
            obtain information and identify any potential issues.
          </ListItem>
          <ListItem>
            make sure your lender is happy to lend on the property (if you’re buying or remortgaging a property).
          </ListItem>
          <ListItem>
            transfer money.
          </ListItem>
          <ListItem>
            register your ownership (if you’re buying a property).
          </ListItem>
          <ListItem>
            register your new mortgage with the Land Registry (if you’re buying or remortgaging a property).
          </ListItem>
          <ListItem>
            make sure any old mortgages and loans are paid off (if you’re selling or remortgaging a property, as long as we’ve received the money).
          </ListItem>
          <ListItem>
            make any Stamp Duty payment on your behalf (as long as we’ve received
            the money).
          </ListItem>
        </List>
        <p>
          Your role is to:
        </p>
        <List bullet>
          <ListItem>
            make the final decision to buy, sell or remortgage the property.
          </ListItem>
          <ListItem>
            provide complete and truthful information about everything we ask for.
          </ListItem>
          <ListItem>
            tell us anything else you think is relevant.
          </ListItem>
        </List>
        <p>
          We’ll provide you with secure access to your online Juno dashboard. This enables you to see the progress of your transaction(s), send us documents, answer questions and get in touch via webchat. 
          </p>
          <p>
          We will take what you tell us using the dashboard as a formal instruction to do, or not do, something. This includes giving us the final go-ahead to exchange contracts on your behalf. 
          </p>
          <p>
          If you’re buying or selling with someone else, one of you can give us the go-ahead to exchange contracts on behalf of all of you.
          </p>
          <p>
          When you explicitly give the go-ahead in this way, we will take it as confirmation from all of you.
          </p>
          <p>
          We’ve tried to make our service accessible to everyone. If you need
          anything, please let us know and we’ll be happy to make reasonable
          adjustments.
        </p>
        <p>
          You can see more about the key stages and timescales at:
        </p>
        <List bullet>
          <ListItem>
            <a href="https://www.juno.legal/how-it-works/buying" target="_blank" rel="noreferrer">www.juno.legal/how-it-works/buying</a>
          </ListItem>
          <ListItem>
            <a href="https://www.juno.legal/how-it-works/selling" target="_blank" rel="noreferrer">www.juno.legal/how-it-works/selling</a>
          </ListItem>
          <ListItem>
            <a href="https://www.juno.legal/how-it-works/remortgaging" target="_blank" rel="noreferrer">www.juno.legal/how-it-works/remortgaging</a>
          </ListItem>
        </List>
        <Heading level="4" id="searches">
          Searches
        </Heading>
        <p>
          Our search pack consists of the following:
        </p>
        <List bullet>
          <ListItem>
            Local authority search
          </ListItem>
          <ListItem>
            Water and drainage search
          </ListItem>
          <ListItem>
            Environmental search
          </ListItem>
          <ListItem>
            Chancel liability policy
          </ListItem>
        </List>
        <p>
          Other searches are not included. For example, we do not order a search covering planning applications in the local area.
        </p>
        <Heading level="4" id="whats-not-included">
          What’s not included
        </Heading>
        <p>
          Our role is limited to the standard legal work involved in the purchase, sale or remortgage of residential property.
        </p>
        <p>
          Our quote does not include anything that is not part of the legal work on a standard conveyancing transaction, for example:
        </p>
        <List bullet>
          <ListItem>
            Management company fees.
          </ListItem>
          <ListItem>
            Indemnity policies (other than the chancel liability indemnity policy, which is included on every property purchase).
          </ListItem>
          <ListItem>
            Extra searches, such as an additional flood or mining search.
          </ListItem>
          <ListItem>
            Tenant advice, such as how to give notice.
          </ListItem>
          <ListItem>
            Tax advice.
          </ListItem>
          <ListItem>
            Legal disputes, such as taking a management company to court, or any post-completion problems.
          </ListItem>
        </List>

        <p>
          When two or more people are buying a property, the Land Registry transfer deed allows you to own equal shares or define a percentage split. We do not recommend any alternative arrangement or provide advice about alternatives (a ‘deed of trust’).
        </p>
        <p>
          Where you’re purchasing a leasehold property and we’ve advised you that there is an escalating ground rent, we strongly advise you to get independent valuation advice from a qualified surveyor to make sure that the escalating ground rent will not affect the value or saleability of the property.
        </p>
        <p>
          If you need a lease extension after you complete your purchase, we won’t be able to do that work for you, but we can suggest other firms you could use.
        </p>
        <p>
          No other people or organisations may rely upon the advice we give to you.
        </p>

        <Heading level="3" id="your-quote">
          Your quote
        </Heading>
        <p>
          We charge fixed fees for our services.
        </p>
        <p>
          At the start, we’ll give you a quote, and your quote will say what service you asked for. For example, ‘buy a freehold property’. If we find out that you’ve asked for a different service to the one you require, we’ll tell you and adjust our fee accordingly.
        </p>
        <Heading level="4" id="situations-where-your-quote-may-change">
          Situations where your quote can change
        </Heading>
        <p>
          We provide a quote based on the information you provide us.
        </p>
        <p>
          We’ll need to change your quote if:
        </p>
        <List bullet>
          <ListItem>
            The property is leasehold or leasehold with share of freehold, but our quote is for a freehold property.
          </ListItem>
          <ListItem>
            Your quote is for a freehold property, but you’re also buying something as a leasehold at the same time (eg a garage).
          </ListItem>
          <ListItem>
            We’re working for you as a company, but our quote is for working for you as an individual.
          </ListItem>
          <ListItem>
            The property is a buy-to-let, but our quote is for a standard residential purchase.
          </ListItem>
          <ListItem>
            The purchase involves a completely new lease, or we need to make changes to the property’s lease or transfer deeds (via a ‘deed of variation’):
            <List bullet>
              <ListItem>
                This is pretty unusual.
              </ListItem>
              <ListItem>
                We will not be able to include this in our quote at the start because we only find out by doing a full review of the property documents, but we will let you know as soon as we can.
              </ListItem>
              <ListItem>
                Our fees are £600 inc VAT for a deed of variation and £750 inc VAT for a completely new lease.
              </ListItem>
              <ListItem>
                On a purchase, the Land Registry will charge a <Link to="https://www.gov.uk/guidance/hm-land-registry-registration-services-fees#scale-2-fees" target="_blank">
                  {' '}
                  ‘scale 2’ fee
                </Link>, typically £30–£65.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            You’re taking a secured loan from relative or friend:
            <List bullet>
              <ListItem>
                We’ll review their loan agreement and advise you on it, and we’ll register the loan as a charge after completion.
              </ListItem>
              <ListItem>
                Our fee for this work is £1,000 inc VAT.
              </ListItem>
              <ListItem>
                Your relative or friend will need to get another law firm to draw up the loan agreement.
              </ListItem>
              <ListItem>
                If you’re also getting a regular mortgage, watch out. It’s very unlikely that a mortgage lender will agree to a second secured loan.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            You’re selling a retirement home – these come with extra legal agreements we need to review and act upon.
            <List bullet>
              <ListItem>
                Our fee for this work is £600 inc VAT.
              </ListItem>
              <ListItem>
                The retirement community management will typically also charge fees.
              </ListItem>
            </List>
          </ListItem>
        </List>
        <Heading level="4" id="our-quotes-are-valid-for-30-days">
          Our quotes are valid for 30 days
        </Heading>
        <p>
          If your quote is more than 30 days old at the point you tell us about your offer, we may need to update your quote based on our current pricing.
        </p>


        <Heading level="3" id="our-fees-on-your-sale-and-our-second-chance-guarantee-for-sellers">
          Our fees on your sale – and our second chance guarantee for sellers
        </Heading>
        <p>
          This section applies to all sellers <strong>except</strong> if you’ve been referred to Juno by one of the following estate agents: Brown & Merry, Roger Platt, Barnfields, Hurfords, Kevin Henry, Pattison Lane, Sharman Quinney and William H Brown.
        </p>
        <p>
          Our second chance guarantee means that if your first sale doesn’t complete then you won’t need to pay us.
        </p>
        <p>
          Here’s how it works:
        </p>
        <List bullet>
          <ListItem>
            You don’t have to pay us anything up front on your first sale.
          </ListItem>
          <ListItem>
            On completion, we’ll transfer our full fee from our client account to our company account – we’ll provide you with an invoice as a record.
          </ListItem>
          <ListItem>
            If your first sale falls through – for any reason at all – you’re covered by our second chance guarantee. You won’t get charged for your first sale.
          </ListItem>
          <ListItem>
            You’ll pay £399 up front at the start of your second (or subsequent) sale. We’ll deduct the remainder of our quoted fee from the sale proceeds on completion.
          </ListItem>
          <ListItem>
            On your second or subsequent sale, we’ll transfer money to our company account at the following milestones:

            <Table>
              <thead>
                <tr>
                  <th>Milestone reached</th>
                  <th>Amount deducted from client account, inc VAT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-title="Milestone reached: ">Contract pack sent</td>
                  <td data-title="Amount deducted from client account, inc VAT: ">£99</td>
                </tr>
                <tr>
                  <td data-title="Milestone reached: ">Work done on enquiries</td>
                  <td data-title="Amount deducted from client account, inc VAT: ">£300</td>
                </tr>
                <tr>
                  <td data-title="Milestone reached: ">Exchange and completion</td>
                  <td data-title="Amount deducted from client account, inc VAT: ">Remainder of quoted fee</td>
                </tr>
              </tbody>
            </Table>
          </ListItem>
          <ListItem>
            When we transfer money to our company account, we’ll provide you with an invoice as a record of the payment. These transfers are non-refundable.
          </ListItem>
        </List>
        <p>
          If you’re buying and selling, then to use your second chance guarantee, your second purchase cannot be a new build, shared ownership, involve a mortgage lender we can’t work with, or involve any other <a href="#services-we-do-not-offer">service we do not offer</a>. If you choose to proceed with a second purchase we cannot handle, we will not be able to handle your sale either (unless you do not need both transactions to complete on the sale day), and we’ll give you a refund for any amount not yet billed, and keep any amounts already invoiced.
        </p>
        <p>
          Normally we’ll deduct our fee from the sale proceeds on completion, but if this isn’t enough, you’ll need to cover any shortfall before completion.
        </p>
        <p>
          Work on your sale is bespoke to you, your property, and your sale. You agree to waive your right to a cooling-off period so we can begin work right away.
        </p>
        <p>
          In the unlikely event that your sale exchanges but does not complete, you will still need to pay the remainder of our quoted fee.
        </p>

        <Heading level="3" id="our-fees-on-your-sale-for-clients-referred-by-sequence-home-conveyancing">
          Our fees on your sale – for clients referred by Home Conveyancing
        </Heading>
        <p>
          This section applies to all sellers <strong>referred to Juno by Home Conveyancing.</strong> The following estate agents refer via Home Conveyancing:
        </p>
        <List bullet>
          <ListItem>
            Barnfields
          </ListItem>
          <ListItem>
            Brown & Merry
          </ListItem>
          <ListItem>
            Hurfords
          </ListItem>
          <ListItem>
            Kevin Henry
          </ListItem>
          <ListItem>
            Pattison Lane
          </ListItem>
          <ListItem>
            Roger Platt
          </ListItem>
          <ListItem>
            Sharman Quinney
          </ListItem>
          <ListItem>
            William H Brown
          </ListItem>
        </List>
        <p>
          If you were referred by Home Conveyancing:
        </p>
        <List bullet>
          <ListItem>
            You don’t have to pay us anything up front on your sale.
          </ListItem>
          <ListItem>
            On completion, we’ll transfer our full fee from our client account to our company account – we’ll provide you with an invoice as a record.
          </ListItem>
          <ListItem>
            If your sale falls through, we will not charge our legal fee. We’ll give you a new quote when you’re ready to start your next sale – the pricing from your previous sale does not automatically apply.
          </ListItem>
        </List>
        <p>
          Normally we’ll deduct our fee from the sale proceeds on completion, but if this isn’t enough, you’ll need to cover any shortfall before completion.
        </p>

        <Heading level="3" id="our-fees-on-your-purchase-and-our-second-chance-guarantee-for-buyers">
          Our fees on your purchase – and our second chance guarantee for buyers
        </Heading>
        <p>
          This section applies to all buyers <strong>except</strong> if you’ve been referred to Juno by one of the following estate agents: Brown & Merry, Roger Platt, Barnfields, Hurfords, Kevin Henry, Pattison Lane, Sharman Quinney and William H Brown.
        </p>
        <p>
          With our second chance guarantee, if your first purchase falls through you can have another try within 12 months without paying anything more.
        </p>
        <Heading level="4">
          How it works
        </Heading>
        <List bullet>
          <ListItem>
            At the start of your first purchase, you’ll pay your full fee up front and the money will arrive in our client account.
          </ListItem>
          <ListItem>
            We’ll transfer money from our client account to our company account at the following milestones:

            <Table>
              <thead>
                <tr>
                  <th>Milestone reached</th>
                  <th>Amount deducted from client account, inc VAT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-title="Milestone reached: ">1. Searches ordered</td>
                  <td data-title="Amount deducted from client account, inc VAT: ">£399</td>
                </tr>
                <tr>
                  <td data-title="Milestone reached: ">2. Title and local search reviewed, initial enquiries raised</td>
                  <td data-title="Amount deducted from client account, inc VAT: ">75% of our remaining fee</td>
                </tr>
                <tr>
                  <td data-title="Milestone reached: ">3. Purchase completed</td>
                  <td data-title="Amount deducted from client account, inc VAT: ">100% of our remaining fee</td>
                </tr>
              </tbody>
            </Table>
          </ListItem>
          <ListItem>
            When we transfer money to our company account, we’ll provide you with an invoice as a record of the payment. These transfers are non-refundable.
          </ListItem>
          <ListItem>
            If your first purchase falls through – for any reason at all – your second purchase will be covered by our second chance guarantee, as long as you start it within 12 months of the date your first purchase fell through.
          </ListItem>
          <ListItem>
            With our second chance guarantee, we’ll get you the searches and start work on the new property at no extra charge. Any amounts already transferred to our company account on your first purchase will not be transferred again on your second purchase, so you won’t be out of pocket.
          </ListItem>
          <ListItem>
            If your second purchase falls through (or if your first purchase falls through and you don’t proceed with a second purchase within 12 months) Juno will keep any amounts already transferred to our company account and refund you any amount still in our client account.
          </ListItem>
          <ListItem>
            If your first purchase falls through and you request a refund for the amount in our client account, then your second purchase will not be covered by our second chance guarantee: you’ll pay your full fee up front into our client account, and we’ll transfer it into our company account according to the milestones above.
          </ListItem>
          <ListItem>
            At the start of your third or subsequent purchase (or second purchase if you do not start it within 12 months) you’ll pay your full fee up front into our client account, and we’ll transfer it into our company account according to the milestones above. And if your third or subsequent purchase falls through, Juno will keep any amounts already transferred to our company account, and we’ll refund you any amount still in our client account.
          </ListItem>
          <ListItem>
            If you want us to work on purchasing two different properties at the same time, you will not be covered by our second chance guarantee. You’ll pay the full fee up front for both properties into our client account, and we’ll transfer the money into our company account for both transactions according to the milestones above.
          </ListItem>
        </List>
        <p>
          To use your second chance guarantee, your second purchase cannot be a new build, shared ownership, involve a mortgage lender we can’t work with, or involve any other <a href="#services-we-do-not-offer">service we do not offer</a>. If you choose to proceed with a second purchase we cannot handle, we’ll give you a refund for any amount not yet billed and keep any amounts already invoiced, and we will not be able to handle your sale either (unless you do not need both transactions to complete on the sale day). Work on your purchase is bespoke to you, your chosen property, and your purchase. You agree to waive your right to a cooling-off period so we can begin work right away.
        </p>
        <p>
          In the unlikely event that your purchase exchanges but does not complete, you will still need to pay the remainder of our quoted fee.
        </p>

        <Heading level="4">
          Mortgage offer protection
        </Heading>
        <p>
          If your quote includes ‘mortgage offer protection’, we’ll give you a full refund on the legal fees for your purchase if your lender doesn’t approve your mortgage offer.
        </p>
        <p>
          To get this refund, you’ll need to provide evidence showing that your mortgage application has been rejected (such as a letter from a lender) and your quote must include a reference to ‘mortgage offer protection’.
        </p>
        <p>
          If your first purchase falls through because of your mortgage offer, you cannot get a refund and also use our second chance guarantee – you must choose which one you want.
        </p>

        <Heading level="3" id="our-fees-on-your-purchase-for-clients-referred-by-sequence-home-conveyancing">
          Our fees on your purchase – for clients referred by Home Conveyancing
        </Heading>
        <p>
          This section applies to all sellers <strong>referred to Juno by Home Conveyancing.</strong> The following estate agents refer via Home Conveyancing:
        </p>
        <List bullet>
          <ListItem>
            Barnfields
          </ListItem>
          <ListItem>
            Brown & Merry
          </ListItem>
          <ListItem>
            Hurfords
          </ListItem>
          <ListItem>
            Kevin Henry
          </ListItem>
          <ListItem>
            Pattison Lane
          </ListItem>
          <ListItem>
            Roger Platt
          </ListItem>
          <ListItem>
            Sharman Quinney
          </ListItem>
          <ListItem>
            William H Brown
          </ListItem>
        </List>
        <p>
          If you were referred by Home Conveyancing:
        </p>
        <List bullet>
          <ListItem>
            You’ve already entered into a contract with your estate agent to provide you with searches. If you’ve not paid them yet, you’ll need to pay us £399 inc VAT and we’ll transfer this across. You’ll be covered by the Home Conveyancing search pledge – your estate agent should already have provided you with their terms.
          </ListItem>
          <ListItem>
            You don’t have to pay us anything up front on your purchase (except for the £399 to your estate agent, as above).
          </ListItem>
          <ListItem>
            On completion, we’ll transfer our full fee from our client account to our company account – we’ll provide you with an invoice as a record.
          </ListItem>
          <ListItem>
            If your purchase falls through, we will not charge our legal fee. We’ll give you a new quote when you’re ready to start your next purchase – the pricing from your previous purchase does not automatically apply.
          </ListItem>
        </List>

        <Heading level="3" id="our-fees-on-your-remortgage">
          Our fees on your remortgage
        </Heading>
        <p>
          You don’t have to pay us anything until we receive your mortgage offer – we’ll get started on your ID checks and case setup right away.
        </p>
        <p>
          Once we receive your mortgage offer, we’ll ask you to pay part of our fee up front and the remainder on completion. Here’s how it works:
        </p>
        <List bullet>
          <ListItem>
            At the start of your first remortgage, we’ll ask you to pay £399 up front, and this will arrive in our client account.
          </ListItem>
          <ListItem>
            We’ll transfer money to our company account at the following milestones:
            <Table>
              <thead>
                <tr>
                  <th align="left">Milestone reached</th>
                  <th align="right">Amount deducted from client account, inc VAT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-title="Milestone reached: ">1. Mortgage offer and upfront payment received</td>
                  <td data-title="Amount deducted from client account, inc VAT: ">£399</td>
                </tr>
                <tr>
                  <td data-title="Milestone reached: ">2. Remortgage completed</td>
                  <td data-title="Amount deducted from client account, inc VAT: ">Remainder of our quoted fee</td>
                </tr>
              </tbody>
            </Table>
          </ListItem>
          <ListItem>
            When we transfer money to our company account, we’ll provide you with an invoice as a record of the payment.
          </ListItem>
        </List>
        <p>
          Work on your remortgage is bespoke to you, your property, and your remortgage. You agree to waive your right to a cooling-off period so we can begin work right away.
        </p>
        <p>
          Our fees on your second and subsequent remortgage attempt will be the same as for your first remortgage as long as there’s no change in tenure, the property price changes by no more than 10%, and your original quote is less than 30 days old.
        </p>

        <Heading level="3" id="if-tax-rates-or-government-fees-change">
          If tax rates or government fees change
        </Heading>
        <p>
          If the VAT rate changes, your quote will not change.
        </p>
        <p>
          If the Stamp Duty rates change, your Stamp Duty amount will change.
          The Stamp Duty amount on your quote is an estimate based on the Stamp
          Duty rates at the time and other information you have given us.
        </p>
        <p>
          If the Land Registry fee increases after you’ve sent us the money and
          before we register your new mortgage, we’ll ask you to send us the
          additional amount. If the fee decreases, we’ll refund you the
          difference once your registration is complete.
        </p>

        <Heading level="3" id="third-party-costs">
          Third party costs
        </Heading>
        <p>
          Our quote includes several third party costs as standard:
        </p>
        <List bullet>
          <ListItem>
            ID checks
          </ListItem>
          <ListItem>
            Bank transfer fees
          </ListItem>
          <ListItem>
            Land Registry documents
          </ListItem>
          <ListItem>
            Searches: local, water and drainage, and environmental reports, if you’re buying a property
          </ListItem>
          <ListItem>
            Chancel liability insurance, if you’re buying a property
          </ListItem>
          <ListItem>
            Bankruptcy searches and the Land Registry ‘official search with priority’, if you’re buying or remortgaging
          </ListItem>
        </List>
        <p>
          Sometimes we’ll have to pay for something on your behalf.
          These costs are also known as ‘disbursements’.
        </p>
        <p>
          For example, if you’re selling a property where you’ve built an
          extension and you’ve lost the Planning Permission, we can get a copy
          from the local council, and we’ll pass the cost on to you.
        </p>
        <p>
          We don’t add a handling fee or mark these payments up in any way.
        </p>
        <p>
          If you cancel your contract with us, if it becomes apparent that a
          transaction has failed, or if we find out we can’t work for you:
        </p>
        <List bullet>
          <ListItem>
            We usually won’t be able to refund any money you’ve paid us for
            third-party costs, because we usually spend the money soon after you
            pay it to us.
          </ListItem>
          <ListItem>
            If you’ve paid us money for third party costs and we haven’t yet
            spent it, we’ll refund it to you.
          </ListItem>
          <ListItem>
            If we’ve spent money on your behalf and you haven’t yet paid it to
            us, you’ll have to send it to us immediately.
          </ListItem>
        </List>

        <Heading level="3" id="if-we-cant-work-for-you">
          If we can’t work for you
        </Heading>
        <p>
          If we find out that we can’t work for you, we’ll let you know as soon
          as possible.
        </p>
        <p>
          Reasons we might not be able to work for you include:
        </p>
        <List bullet>
          <ListItem>
            The work you’re asking us to do is something we do not offer – there’s a list in the next section.
          </ListItem>
          <ListItem>
            The work you’re asking us to do includes things that are not part of the standard legal work involved in the purchase, sale or remortgage of residential property.
          </ListItem>
          <ListItem>
            We’re already working for the people on the other side of your
            transaction and they’ve not given permission for us to work for both
            sides (for example, you’re buying a property, and we’re already
            working for the sellers, either on this transaction, or another
            one). This is known as a ‘conflict of interest’.
          </ListItem>
          <ListItem>
            We find out that someone is pressuring someone else to take part in
            the transaction. This is known as ‘undue influence’ or
            ‘under duress’.
          </ListItem>
          <ListItem>
            In cases where the property is owned jointly, we are given conflicting instructions by each owner or are asked by one owner to withhold information from the other owner.
          </ListItem>
          <ListItem>
            We’re not on your mortgage lender’s panel – take a look at our <Link to="https://www.juno.legal/find-out-more/lenders/" target="_blank">
              {' '}
              full panel list
            </Link>.
          </ListItem>
          <ListItem>
            You do not pass our ID checks.
          </ListItem>
          <ListItem>
            We cannot verify that your money has come from legitimate sources, and you do not have any alternative sources for the money.
          </ListItem>
          <ListItem>
            You can’t understand English. As a law firm, we’re required to make
            sure that you can understand the information we’re giving you and
            the choices we’re presenting you with. If you can’t understand
            English, you’ll need to find a lawyer that can communicate with you
            in a language you understand.
          </ListItem>
          <ListItem>
            We find out that the other party (the person buying your property or
            selling one to you) has decided to represent themselves. In these
            cases, we won’t be able to offer you satisfactory levels of
            protection against things like identity fraud or money laundering.
          </ListItem>
          <ListItem>
            The property is not in England and Wales.
          </ListItem>
          <ListItem>
            We’re already at full capacity.
          </ListItem>
        </List>
        <p>
          We reserve the right to refuse to work for you (at our sole and
          absolute discretion).
        </p>

        <Heading level="3" id="services-we-do-not-offer">
          Services we do not offer
        </Heading>

        <p>
          Our role is limited to the standard legal work involved in the purchase, sale or remortgage of residential property.
        </p>
        <p>
          If you’re buying and selling, or buying and remortgaging, we will only handle it if we’re doing both parts. The one exception is if you do not need both transactions to complete on the same day. If we find that you are using Juno for one transaction and another firm for the other (for example, we act on your purchase, but not your sale), we reserve the right to stop working for you.
        </p>
        <p>
          We do not handle:
        </p>
        <List bullet>
          <ListItem>
            <strong>Agricultural property</strong>
          </ListItem>
          <ListItem>
            <strong>Armed Forces Help to Buy loans</strong>
          </ListItem>
          <ListItem>
            <strong>Auctions</strong> – including modern auctions
          </ListItem>
          <ListItem>
            <strong>Bridging loans</strong >
          </ListItem>
          <ListItem>
            Properties in the <strong>Channel Islands</strong >
          </ListItem>
          <ListItem>
            <strong>Commercial property</strong>
          </ListItem>
          <ListItem>
            Transactions involving <strong>companies based outside the UK</strong>
          </ListItem>
          <ListItem>
            Purchases where a <strong>company is buying</strong>
          </ListItem>
          <ListItem>
            <strong>Sales where a company is selling</strong>
          </ListItem>
          <ListItem>
            <strong>Contract races</strong> – where a seller has accepted more than one offer
          </ListItem>
          <ListItem>
            <strong>Deeds of gift</strong> – giving a property to someone with no money changing hands
          </ListItem>
          <ListItem>
            Drafting a <strong>Deed of Trust</strong> on a purchase – an agreement as to how to split proceeds when a property is sold
          </ListItem>
          <ListItem>
            <strong>Discounted price</strong> – buying or selling not at market price
          </ListItem>
          <ListItem>
            <strong>Divorce</strong>
          </ListItem>
          <ListItem>
            <strong>Equity release mortgages</strong> on a purchase, or as the new mortgage on a remortgage
          </ListItem>
          <ListItem>
            <strong>Exclusivity agreement</strong> – prevents the seller from selling to anyone else for a fixed amount of time – requires drafting and negotiating an entirely separate contract
          </ListItem>
          <ListItem>
            <strong>Buying with a Help to Buy loan</strong> – selling with one is fine
          </ListItem>
          <ListItem>
            <strong>Holding deposit</strong> – as in, a deposit in addition to the mortgage deposit and exchange deposit – requires drafting and negotiating an entirely separate contract
          </ListItem>
          <ListItem>
            Buying a property to use as a <strong>holiday let</strong> – lenders and councils often have special restrictions
          </ListItem>
          <ListItem>
            Buying on a <strong>holiday park</strong>
          </ListItem>
          <ListItem>
            <strong>House in Multiple Occupation (HMO)</strong> – a house split into several individual bedsits or flats) unless you’ve been referred to Juno directly by a specialist lender
          </ListItem>
          <ListItem>
            <strong>Islamic or Sharia finance</strong>
          </ListItem>
          <ListItem>
            <strong>Land only</strong>
          </ListItem>
          <ListItem>
            <strong>Lease extensions</strong>, except:
            <List bullet>
              <ListItem>
                On a sale where you’ve already agreed terms with the freeholder, and it’s not a statutory lease extension.
              </ListItem>
              <ListItem>
                On a purchase where the seller has already agreed the new lease or deed of variation with the freeholder, and it’s not a statutory lease extension.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Lifetime mortgages</strong> on a purchase, or as the new mortgage on a remortgage
          </ListItem>
          <ListItem>
            <strong>Linked transactions where a different law firm is handling the other half</strong> – for example, we're handling the sale and another firm are handling the purchase, and both must complete on the same day
          </ListItem>
          <ListItem>
            <strong>Loans from a non-FCA-regulated lender</strong> – including when someone is gifting but wants a charge over the property
          </ListItem>
          <ListItem>
            <strong>Lockout agreement</strong> – prevents the seller from selling to anyone else for a fixed amount of time – requires drafting and negotiating an entirely separate contract
          </ListItem>
          <ListItem>
            <strong>Matrimonial home rights notices</strong>
          </ListItem>
          <ListItem>
            <strong>Mobile homes</strong>
          </ListItem>
          <ListItem>
            <strong>Taking out a mortgage where we’re not on the panel</strong> – take a look at our <Link to="https://www.juno.legal/find-out-more/lenders/" target="_blank">
              {' '}
              full panel list
            </Link>.
          </ListItem>
          <ListItem>
            <strong>New builds</strong>
          </ListItem>
          <ListItem>
            <strong>New leases</strong>
          </ListItem>
          <ListItem>
            <strong>Non-refundable deposits</strong> – as in, a deposit in addition to the mortgage deposit and exchange deposit – requires drafting and negotiating an entirely separate contract
          </ListItem>
          <ListItem>
            Properties in <strong>Northern Ireland</strong>
          </ListItem>
          <ListItem>
            <strong>Option agreements</strong> – an agreement giving a buyer the option to buy a property in the future – requires drafting and negotiating an entirely separate contract
          </ListItem>
          <ListItem>
            <strong>Park homes</strong>
          </ListItem>
          <ListItem>
            Buying or selling just <strong>part of a property</strong> – buying two linked properties is fine, such as a house with a separate garage
          </ListItem>
          <ListItem>
            <strong>Part-exchange</strong>
          </ListItem>
          <ListItem>
            Buying on behalf of someone using a <strong>power of attorney</strong>, including Lasting Powers of Attorney
          </ListItem>
          <ListItem>
            <strong>Separate lender representation</strong> – acting only for you and not for your mortgage lender as well
          </ListItem>
          <ListItem>
            <strong>Separation</strong>
          </ListItem>
          <ListItem>
            <strong>Reclaiming overpaid Stamp Duty</strong>
          </ListItem>
          <ListItem>
            <strong>Remortgaging</strong>
          </ListItem>
          <ListItem>
            Advising on <strong>rental problems</strong> – for example, apportionment of rent, deposits, issues with a landlord
          </ListItem>
          <ListItem>
            Buying a <strong>repossession</strong>
          </ListItem>
          <ListItem>
            Buying or remortgaging a <strong>retirement property</strong> – as these involve special fees and agreements
          </ListItem>
          <ListItem>
            <strong>Right to Buy</strong> – buying a property direct from the council for a discount – as this involves a special process with no contract
          </ListItem>
          <ListItem>
            Properties in <strong>Scotland</strong>
          </ListItem>
          <ListItem>
            Buying with <strong>no searches</strong>
          </ListItem>
          <ListItem>
            <strong>Shared ownership</strong> – buying a share of a property from a council or a property developer
          </ListItem>
          <ListItem>
            <strong>Splitting one property into two</strong>
          </ListItem>
          <ListItem>
            <strong>Selling a ‘split title’</strong> – for example, a house where the garage is on a separate title
          </ListItem>
          <ListItem>
            <strong>Staircasing</strong> – buying a larger share of a property from a council or a property developer, or selling the whole property when you own a share only
          </ListItem>
          <ListItem>
            <strong>Static caravans</strong>
          </ListItem>
          <ListItem>
            <strong>Student accommodation</strong>
          </ListItem>
          <ListItem>
            <strong>Tenants</strong> – unless you’re selling to the tenant, or remortgaging with tenants in place
          </ListItem>
          <ListItem>
            <strong>Transfer of equity</strong> – adding or removing someone from the ownership of the property
          </ListItem>
          <ListItem>
            Acting for a <strong>trust</strong>
          </ListItem>
          <ListItem>
            <strong>Unregistered land</strong> including where the registration is pending
          </ListItem>
        </List>
        <p>
          If we discover after starting work that any of the above apply to your transaction:
        </p>
        <List>
          <ListItem>
            We may need to ask you to use another law firm.
          </ListItem>
          <ListItem>
            If you have given us inaccurate information (for example, you’ve said that the property does not have tenants, but it does) we may not be able to refund your fees.
          </ListItem>
          <ListItem>
            If we can continue acting for you (which will be at our sole discretion) we’ll let you know what additional fee will be payable for the extra work required.
          </ListItem>
        </List>

        <Heading level="3" id="changing-your-mortgage-offer-after-exchange">
          Changing your mortgage offer after exchange
        </Heading>
        <p>
          You might want to get a new mortgage offer after we’ve exchanged contracts, for example, to get a better interest rate.
        </p>
        <p>
          We can do this as long as your lender stays the same (for example, your initial mortgage offer was with Barclays, and your new offer is still with Barclays). We must agree in writing before going ahead, since it’s possible to get into serious trouble if this goes wrong.
        </p>
        <p>
          We cannot accept a new offer from a different lender (for example, your initial mortgage offer was with Barclays but your new offer is with NatWest), because the conveyancing work is lender-specific.
        </p>

        <Heading level="3" id="referral-fees">
          Referral fees
        </Heading>
        <p>
          We may pay referral fees to your estate agent or mortgage broker.
        </p>
        <p>
          If we do this the average fee we pay is £180 + VAT. You have the freedom to choose your conveyancer, so if you don’t want to use Juno you’re free to use another provider.
        </p>

        <Heading level="3" id="checking-your-identity">
          Checking your identity
        </Heading>
        <p>
          We have to verify the identity of individuals and companies who are our clients. To prove the identity of individuals we can accept a valid:
        </p>
        <List bullet>
          <ListItem>
            Passport
          </ListItem>
          <ListItem>
            Photocard driving licence
          </ListItem>
          <ListItem>
            For people outside the UK, a national ID card
          </ListItem>
        </List>
        <p>
          If a company is buying, selling or remortgaging a property, we’ll also need to check the identity of:
        </p>
        <List bullet>
          <ListItem>
            directors of the company
          </ListItem>
          <ListItem>
            any person who has a 25% or greater beneficial ownership of the company (directly or indirectly)
          </ListItem>
          <ListItem>
            the directors of any company that has a 25% or greater beneficial ownership of the company
          </ListItem>
        </List>
        <p>
          We’ll do our best to help you provide the information we need, but if you do not pass our ID checks, we will not be able to work for you.
        </p>

        <Heading level="3" id="making-sure-your-money-has-come-from-legitimate-sources">
          Making sure your money comes from legitimate sources
        </Heading>
        <p>
          Every law firm is required to make sure that any money used toward a property transaction has come from legitimate sources.
        </p>
        <p>
          We’ll start by asking for six months’ bank statements. We may have to ask for more documents. The exact type and amount of documentation we need for these checks is up to us.
        </p>
        <p>
          If someone else is giving or lending you money, they’ll also need to pass our identity checks and show that the money they’re providing has come from legitimate sources.
        </p>
        <p>
        We cannot accept money from cryptocurrency, China, Hong Kong, Turkey, the United Arab Emirates (inc Abu Dhabi and Dubai) or any other countries or sources we cannot fully investigate. This applies even if the money is now in a UK bank account. If you’d like to check whether we can accept your money, please just ask.
        </p>
        <p>
          If you have money from a source we cannot accept, you have two options:
        </p>
        <List bullet>
          <ListItem>You can find a different UK-based source for the money – such as another savings account, or a relative who can give you an unconditional gift.</ListItem>
          <ListItem>You can use another law firm which is set up to verify money from this source, such as a bilingual law firm, or a firm with a cryptocurrency specialisation, and we would refund any fees not yet transferred to our company account.</ListItem>
        </List>
        <p>
          We’ll do our best to help you provide the information we need, but if we cannot verify that your money is from legitimate sources, we will not be able to work for you.
        </p>

        <Heading level="3" id="payments">
          Payments
        </Heading>
        <p>We accept payments via:</p>
        <List bullet>
          <ListItem>bank transfer from a UK-based bank account</ListItem>
          <ListItem>credit or debit card (via our website, for upfront payments only)</ListItem>
        </List>
        <p>We do not accept:</p>
        <List bullet>
          <ListItem>cash</ListItem>
          <ListItem>cheques</ListItem>
          <ListItem>bank transfers from accounts held outside the UK</ListItem>
          <ListItem>card payments over the phone</ListItem>
          <ListItem>magic beans</ListItem>
        </List>

        <Heading level="3" id="staying-safe">
          Staying safe
        </Heading>
        <p>
          <strong>Read this bit extra carefully!</strong> Property transactions are a big target for fraudsters.
        </p>
        <p>
          Once you sign up, we’ll send the following by post to your home
          address:
        </p>
        <List bullet>
          <ListItem>Our bank details</ListItem>
          <ListItem>A form for you to tell us your bank details</ListItem>
        </List>
        <p>Stay safe by remembering these simple rules:</p>
        <List bullet>
          <ListItem>
            Our bank details <strong>will not change</strong> for the whole of
            your transaction.
          </ListItem>
          <ListItem>
            We’ll <strong>never</strong> call or email you to tell you our bank
            details have changed.
          </ListItem>
          <ListItem>
            If someone tells you our bank details have changed, <strong>they’re a fraudster. You should let us know right away by phone on 020 3856 3339</strong>. Please
            don’t email us about suspected fraud.
          </ListItem>
        </List>
        <p>
          If we need to transfer money to you, we’ll only send that money to a
          UK bank account in your name. If you need to send the money to someone
          else, we’ll send it to you, and then you can send it to the other
          person.
        </p>

        <Heading level="3" id="how-we-store-your-money">
          How we store your money
        </Heading>
        <p>
          We keep all our clients’ money in client account facilities provided by UK banks authorised by the Bank of England. We pay our business expenses from completely separate bank accounts.
        </p>
        <p>
          We do not calculate or pay interest to you on money we hold on your behalf.
        </p>
        <p>
          By instructing Juno you give us permission to share information with the Financial Services Compensation Scheme (FSCS) if necessary.
        </p>

        <Heading level="3" id="we-donate-small-leftover-amounts-to-charity">
          We donate small leftover amounts to charity
        </Heading>
        <p>
          Sometimes there’s a small amount of money left over once your transaction is finished.
        </p>
        <p>
          If the amount is £30 or less we donate it to the charity <a href="https://www.crisis.org.uk" target="_blank" rel="noreferrer">Crisis</a>.
          They use your donations to fight homelessness.
        </p>
        <p>
          If the amount is more than £30, we’ll ask for your bank details so we
          can transfer it back to you. If we’ve tried to return your money to you for over a year and not been successful, we will donate it to Crisis.
        </p>
        <p>
          If you want us to send the money back to you even if it’s less than
          £30, please email us before your completion date.
        </p>
        <Heading level="3" id="your-personal-information">
          Your personal information
        </Heading>
        <p>
          By accepting these terms and you agree that the following people may see your personal information and detailed case information:
        </p>
        <List bullet>
          <ListItem>Our employees and contractors</ListItem>
          <ListItem>
            Your mortgage lender, if you’re buying with a mortgage
          </ListItem>
          <ListItem>Your mortgage broker, if you’re using one</ListItem>
          <ListItem>
            The freeholder, landlord, management companies and/or managing agents for the property you’re transacting
          </ListItem>
          <ListItem>Our suppliers of data processing services</ListItem>
          <ListItem>Other companies in the Juno group</ListItem>
          <ListItem>Our regulators (including the CLC and the FCA)</ListItem>
          <ListItem>Any official body by virtue of their legal powers</ListItem>
        </List>
        <p>
          By accepting these terms you agree that we can share non-personal information about the progress of the transaction with your:
        </p>
        <List bullet>
          <ListItem>Estate agents</ListItem>
          <ListItem>Mortgage lender</ListItem>
          <ListItem>Mortgage broker</ListItem>
        </List>
        <p>
          If you use a referral code or referral link to get a quote from us, then we’ll tell the person or organisation associated with that code or link that you got a quote. We’ll also tell them when you sign up and when your case completes, but we won’t include any personal details in these notifications.
        </p>
        <p>
          We’re often asked to recommend and introduce third party suppliers of home-moving services like surveying, removals, home insurance and life insurance. Juno Property Lawyers Limited (JPL) does not make introductions to third party suppliers. Any recommendations and introductions during the course of your transaction with us are made by a company called Juno Global Services Limited (JGS). JGS may make these recommendations or introductions via your dashboard or by email.
        </p>
        <p>
          We will share your personal information and case details with JGS to facilitate these introductions. JGS is owned and controlled by the same shareholders as Juno Property Lawyers Limited and is part of the Juno group. We won’t sell your information to anyone, and we’ll only share your information with the people and companies listed above.
        </p>
        <p>
          We’re registered with the Information Commissioner’s Office (ICO) as a
          data controller. Our ICO number is ZA245867.
        </p>
        <p>
          We’ll record all your calls and emails with us and store them for as long as we’re required to store your file (see{' '}
          <a href="#file-storage">File storage</a> below).
        </p>

        <Heading level="3" id="mortgage-lenders-we-work-with">
          Mortgage lenders we work with
        </Heading>
        <p>
          If you’re remortgaging or buying residential property with a mortgage, we can only work for you if you’re using one of the lenders listed at <a href="https://www.juno.legal/find-out-more/lenders/" target="_blank" rel="noreferrer">www.juno.legal/find-out-more/lenders</a>.
        </p>
        <p>
          If you’re taking out a mortgage with a different lender you’ll have to
          use another firm for your move. If you’ve already started a
          transaction with us, we’ll send them all reasonable paperwork.
        </p>

        <Heading level="3" id="copyright">
          Copyright
        </Heading>
        <p>
          We retain the copyright for our online service and all materials we
          create.
        </p>

        <Heading level="3" id="complying-with-the-law">
          Complying with the law
        </Heading>
        <p>We need to tell the National Crime Agency (NCA) if we suspect:</p>
        <List bullet>
          <ListItem>
            You’re attempting to launder money or help someone launder money.
          </ListItem>
          <ListItem>
            Your money is the proceeds of crime, including tax evasion.
          </ListItem>
        </List>
        <p>
          You agree that we can do this regardless of our duty of
          confidentiality to you.
        </p>
        <p>
          If you ask us about anything relating to crime prevention, we don’t
          have to give an answer. For example, if you ask us whether we have
          reported you for money laundering, we can’t tell you either way.
        </p>
        <p>
          If the police ask us to stop acting for you, we’ll do so, and we won’t
          be able to tell you the reason.
        </p>

        <Heading level="3" id="our-liability">
          Our liability
        </Heading>
        <p>
          If we make a mistake that ends up causing a problem or costing you
          money, you can make a claim against our professional indemnity
          insurance.
        </p>
        <p>You agree that:</p>
        <List bullet>
          <ListItem>
            Any claim you want to make will be against Juno, and not against any
            individuals who work for us.
          </ListItem>
          <ListItem>
            The limit of our insurance is £5 million per claim, and this is the
            largest claim you can make.
          </ListItem>
          <ListItem>
            If we can’t deliver our services to you through no fault of our own
            and because of circumstances outside of our control, you won’t have
            any claim against us for any actual or consequential loss. For
            example, if there are delays in the banking system.
          </ListItem>
          <ListItem>
            If you’ve provided all the information we asked for, we’ve received
            your completion money, and yet we fail to send the money to a
            seller’s lawyer and this causes a delay to the completion of your
            transaction, then you won’t have to pay our legal fee, but you won’t
            have any other claim against us.
          </ListItem>
          <ListItem>
            If you make a valid claim against us for a loss arising out of work
            for which we’re legally responsible and we’re unable to meet our
            liability in full, you may be entitled to claim from the Council for Licensed Conveyancers
            compensation fund. Contact the CLC if you need more information.
          </ListItem>
        </List>

        <Heading level="4" id="transactions-which-do-not-complete-or-miss-a-deadline">
          Transactions which do not complete, or miss a deadline
        </Heading>

        <p>
          We always try our best to bring your transaction to completion, and if you’ve asked us to hit a deadline we will try our best to meet it. But we do not guarantee that your transaction will complete, or that it will complete by a given deadline. For example, your buyer or seller may decide not to proceed or not to meet your deadline, and we cannot force them to do so.
        </p>
        <p>
          We are not liable for any actual or consequential loss if your transaction does not complete, or does not complete by a specified date.
        </p>
        <p>For example:</p>
        <List bullet>
          <ListItem>
            If your sale did not proceed, we would not be liable for your mortgage repayments, council tax or utility bills.
          </ListItem>
          <ListItem>
            We would not be liable if your sale did not complete by a deadline and you were not able to reclaim higher rate Stamp Duty paid on a previous purchase.
          </ListItem>
          <ListItem>
            If you have to leave your current accommodation – for example, if your landlord has given you notice – we would not be liable for any losses or costs you incur if your purchase did not complete in time for you to move straight into the new property.
          </ListItem>
        </List>
        <p>
          If someone who works for Juno says that we will complete a transaction or that we will meet a deadline, this is not a guarantee that we will be able to do so, and these terms will continue to apply.
        </p>

        <Heading level="4" id="limit-of-our-liability">
          Limit of our liability
        </Heading>
        <p>
          We can only limit our liability to the extent that the law allows. In
          particular we can’t limit our liability for death or personal injury
          caused by our negligence.
        </p>

        <Heading level="3" id="online-reviews">
          Online reviews
        </Heading>
        <p>
          If you leave us an online review, you agree that we may use relevant confidential information to respond to that review.
        </p>

        <Heading level="3" id="file-storage">
          File storage
        </Heading>
        <p>
          We keep your information for several years after we complete your
          transaction:
        </p>
        <List bullet>
          <ListItem>
            If you sell a property, we’ll keep your file for 6 years.
          </ListItem>
          <ListItem>
            If you buy or remortgage a property, we’ll keep your file for 15
            years.
          </ListItem>
        </List>
        <p>When a transaction is complete and you have paid our fees, we’ll provide you with:</p>
        <List bullet>
          <ListItem>
            Electronic copies of any documents we obtained for you
          </ListItem>
          <ListItem>
            A record of all significant correspondence sent and received as part
            of the transaction
          </ListItem>
        </List>
        <p>
          We will provide copies of any documents on request, as long as you have paid our fees.
        </p>
        <p>
          We don’t keep physical copies of documents once a transaction is
          completed and registered.
        </p>

        <Heading level="3" id="contacting-you-in-future">
          Contacting you in future
        </Heading>
        <p>
          You agree that we can use your personal information and information about your transactions to let you know about relevant services after your transaction has completed. For example this might be to offer you a good deal on your remortgage 18 months into a two year fixed term mortgage.
        </p>

        <Heading level="3" id="if-youre-not-happy-our-complaints-policy">
          If you’re not happy – our complaints policy
        </Heading>
        <p>
          If you’re not happy, start by telling the person handling your case. We’ll do our best to turn things around for you.
        </p>
        <p>
          Please do tell us if you’re not happy – we want to give you a good experience, but we can’t fix things if you wait until after completion.
        </p>
        <p>
          If you need to make a complaint, you can do so by email or by phone. To complain by email, write to{' '}
          <a href="mailto:hello@juno.legal">hello@juno.legal</a> and let us know what has gone wrong. If you make a complaint by phone, we’ll summarise our understanding of your complaint to you in writing. We’ll try to
          provide a full response within one week. If more investigation is
          required, we’ll take a maximum of four weeks to respond.
        </p>
        <p>
          If you’re still unhappy or if you don’t feel comfortable discussing
          things with the person handling your case, please email our Head of
          Legal Practice, Charles Jacobs:{' '}
          <a href="mailto:charlie.jacobs@juno.legal">charlie.jacobs@juno.legal</a>
        </p>
        <p>
          If you’re not satisfied with our response, you can complain about us
          to the{' '}<a href="https://www.legalombudsman.org.uk/" target="_blank" rel="noreferrer">Legal Ombudsman
          </a> 8 weeks after your complaint. You have to complain to the Legal Ombudsman within one year of the act or omission you’re complaining about, or within one year from when you should have realised there was cause for complaint. The Legal Ombudsman is an independent and impartial complaints
          organisation established by the Legal Services Act 2007.
        </p>
        <p>
          You can complain to the legal Ombudsman by contacting them at the details below:
        </p>
        <List bullet>
          <ListItem>
            Website: <a href="www.legalombudsman.org.uk" target="_blank" rel="noreferrer">www.legalombudsman.org.uk</a>
          </ListItem>
          <ListItem>
            Tel: 0300 555 0333
          </ListItem>
          <ListItem>
            Email: enquiries@legalombudsman.org.uk
          </ListItem>
          <ListItem>
            Address: Legal Ombudsman, PO Box 6806, Wolverhampton, WV1 9WJ
          </ListItem>
        </List>
        <p>
          We don’t agree to use alternative complaint organisations, for
          example, Ombudsman Services, ProMediate, Small Claims Mediation, the
          European Online Dispute Resolution platform.
        </p>

        <Heading level="3" id="dont-be-dishonest-rude-or-threatening">
          Don’t be dishonest, rude or threatening
        </Heading>
        <p>
          It’s very important that you can trust us, and we can trust you.
        </p>
        <p>
          If we discover that you have been dishonest with us, we believe that you have been excessively rude to our staff, or that you have made any form of threat against the company or our staff, then:
        </p>
        <List bullet>
          <ListItem>
            we will refuse to continue to act for you
          </ListItem>
          <ListItem>
            you’ll have to find new lawyers
          </ListItem>
          <ListItem>
            you won’t be entitled to a refund of any legal fees you’ve already paid us
          </ListItem>
        </List>

        <Heading level="3" id="our-authorisation">Our authorisation</Heading>
        <p>
          <a href="https://www.clc-uk.org.uk" target="_blank" rel="noreferrer">
            The Council of Licensed Conveyancers (CLC)
          </a>{' '}
          have authorised Juno to provide conveyancing services in England and
          Wales. Our licence number is 3338.
        </p>
        <p>
          We are not authorised by the Financial Conduct Authority but we are
          included in the register maintained by the Financial Conduct Authority so
          that we can carry on Insurance Distribution Activities, which is broadly
          advising on, selling and administration of insurance contracts. This part
          of our business is regulated by the Council for Licensed Conveyancers, and
          arrangements for complaint or redress if something goes wrong are subject
          to the jurisdiction of the Legal Ombudsman
          (<a href="www.legalombudsman.org.uk" target="_blank" rel="noreferrer">www.legalombudsman.org.uk</a>).
          The register can be accessed via the Financial Conduct Authority website at <a href="https://register.fca.org.uk" target="_blank" rel="noreferrer">register.fca.org.uk</a>.
        </p>

        <Heading level="3" id="and-finally">
          And finally…
        </Heading>
        <p>
          If we have to make a change to these terms, we’ll tell you in writing.
        </p>
        <p>
          If there’s a discrepancy between these terms and any other description of our service, these terms will apply.
        </p>
        <p>
          If we make any changes in future to the way that our company and wider
          corporate group is organised, we can transfer your cases and contract
          to another company under our control. If this happens then the same
          people will continue to work on your case, and these terms will
          continue to apply.
        </p>
        <p>
          This agreement is governed by the laws of England and Wales and is
          subject to the exclusive jurisdiction of the courts of England and
          Wales.
        </p>

        <Heading level="3" id="about-juno">
          About Juno
        </Heading>
        <DescriptionList>
          <DescriptionListTerm>Company name</DescriptionListTerm>
          <DescriptionListDetail>Juno Property Lawyers Limited</DescriptionListDetail>
          <DescriptionListTerm>Registered office</DescriptionListTerm>
          <DescriptionListDetail>
            43–51 New North Road, London N1 6LU
          </DescriptionListDetail>
          <DescriptionListTerm>Company number</DescriptionListTerm>
          <DescriptionListDetail>10227405</DescriptionListDetail>
          <DescriptionListTerm>
            <abbr title="Value added tax">VAT</abbr> number
          </DescriptionListTerm>
          <DescriptionListDetail>243966087</DescriptionListDetail>
          <DescriptionListTerm>
            Council of Licensed Conveyancers (CLC) number
          </DescriptionListTerm>
          <DescriptionListDetail>3338</DescriptionListDetail>
          <DescriptionListTerm>
            Solicitors Regulatory Authority (SRA) number
          </DescriptionListTerm>
          <DescriptionListDetail>644540</DescriptionListDetail>
          <DescriptionListTerm>
            Information Commissioner’s Office (ICO) number{' '}
          </DescriptionListTerm>
          <DescriptionListDetail>ZA245867</DescriptionListDetail>
          <DescriptionListTerm>
            Chief Executive Officer / Head of Finance and Administration
          </DescriptionListTerm>
          <DescriptionListDetail>Etienne Pollard</DescriptionListDetail>
          <DescriptionListTerm>Head of Legal Practice</DescriptionListTerm>
          <DescriptionListDetail>
            Charles Jacobs
          </DescriptionListDetail>
        </DescriptionList>
        <JunoTermsVersion>Version 1.72</JunoTermsVersion>
      </Text>
    </React.Fragment>
  );
}

export default JunoTermsAndConditions;
