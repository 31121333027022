import React from 'react';

import JunoReferrerTermsAndConditions from '../../components/JunoReferrerTermsAndConditions';
import Card from '../../components/Card';
import PageLayout from '../../components/PageLayout';

const ReferrerTerms = () => (
  <PageLayout>
  <Card
    title="Our terms for referring customers to Juno"
    headingLevel="2"
    removeTopPadding
  >
    <JunoReferrerTermsAndConditions />
  </Card>
  </PageLayout>
);

export default ReferrerTerms;
