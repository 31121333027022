import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import RadioQuestion from '../../components/RadioQuestion';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageCatAndHousePlant from '../../images/cat-and-houseplant.png';

export const NUMBER_OF_LOANS_OPTIONS = [
  { title: '1', value: 1 },
  { title: '2', value: 2 },
];

class LendingMoney extends Component {
  constructor(props) {
    super(props);

    const errorMessages = {
      numberOfSource: 'Please choose an option',
    };

    if (this.props.lendingMoney) {
      const loans = { ...this.props.lendingMoney };
      this.state = {
        numberOfSource: loans.numberOfSource,
        errors: {},
        errorMessages: errorMessages,
      };
    } else {
      this.state = {
        numberOfSource: null,
        errors: {},
        errorMessages: errorMessages,
      };
    }
  }

  handleInputChange = field => event => {
    this.setState({ [field]: event });
  };

  handleValidation() {
    const fields = { ...this.state };
    let errors = { ...this.state.errors };
    let formIsValid = true;

    if (fields['numberOfSource'] === null) {
      errors['numberOfSource'] = true;
      formIsValid = false;
    }

    this.setState({ errors });

    return formIsValid;
  }

  handleRadioValidation = field => event => {
    let errors = { ...this.state.errors };

    if (event.type !== 'blur') {
      errors[field] = false;
    } else {
      if (this.state[field] === null) {
        errors[field] = true;
      }
    }

    this.setState({ errors });
  };

  saveLendingMoney = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.onStepComplete({
        lendingMoney: {
          numberOfSource: this.state.numberOfSource,
        },
      });
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title="Tell us about the loans you are taking out" removeTopPadding>
          <p>
            First, we need to know how many loans from a person or bank (not including a mortgage), you are taking out to pay for your property.
          </p>

          <RadioQuestion
            question="How many loans are you taking out?"
            options={NUMBER_OF_LOANS_OPTIONS}
            inline={false}
            name="numberOfSource"
            selected={this.state.numberOfSource}
            optionSelected={this.handleInputChange('numberOfSource')}
            validate={this.handleRadioValidation('numberOfSource')}
            validationError={
              this.state.errors.numberOfSource
                ? this.state.errorMessages.numberOfSource
                : null
            }
          />

          <Action onClick={this.saveLendingMoney}>Continue</Action>
        </Card>

        <Image src={imageCatAndHousePlant} />
      </React.Fragment>
    );
  }
}

export default LendingMoney;
