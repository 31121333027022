import React from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Card from '../../components/Card';
import CheckList, { CheckListItem } from '../../components/CheckList';
import Action from '../../components/Action';
import List, { ListItem } from '../../components/List';
import ReferralCodeBanner from '../../components/ReferralCodeBanner';
import styled from 'styled-components/macro';
import { mediaQueries } from '../../styles/media';
import { formatMoneyWithCurrency, moneyStrToNum } from '../../utils/moneyUtils';
import Image from '../../components/Image';
import imageRelaxingOnSofa from '../../images/relaxing-on-sofa.png';

const QuotedPriceWrapper = styled.div`
  background: ${props => props.theme.color.green};
  padding: 1em 1.25em;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 1em;
  ${mediaQueries.md} {
    margin-top: 0;
    margin-left: -31px;
    margin-right: -31px;
    padding-bottom: 0.5em;
    padding-left: 31px;
  }
  p {
    margin-top: 0;
    color: ${props => props.theme.color.white};
  }
  a {
    color: ${props => props.theme.color.white};
    text-decoration: none;
    border-bottom: 1px solid;
  }
`;

const QuotedPrice = styled.h2`
  color: ${props => props.theme.color.white};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin: 0.5em 0 0.25em 0;

  font-size: ${props => props.theme.fontSizes.largeNumber['sm']};
  ${mediaQueries.md} {
    font-size: ${props => props.theme.fontSizes.largeNumber['md']};
    margin-bottom: 0.4em;
  }
`;

const ListItemSuffix = styled.span`
  display: block;
  font-weight: ${props => props.theme.fontWeight.regular};
  font-family: ${props => props.theme.junoFontMono};
  ${mediaQueries.sm} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const QuoteBreakdown = styled.div`
  overflow: hidden;
  li {
    color: ${props => props.theme.color.junoTextSecondary};
  }
`;

const BrokerQuoteSent = (props) => {

    const {
      buyingDetails,
      personalDetails,
      quoteDetails,
      referral,
      remortgagingDetails,
      sellingDetails,
      transactionType,
    } = props;

    let saleLegalFee = '';
    let salePropertyPrice = '';

    let purchaseLegalFee = '';
    let purchasePropertyPrice = '';

    let quotePrice = '';

    let stampDuty = '';
    let stampDutyDescription = 'Stamp Duty';

    let landRegistryFee = '';
    let totalFeesAndTaxes = '';

    let remortgageLegalFee = '';
    let remortgageAmount = '';
    let remortgageTitleInsured = '';
    let remortgageBuyToLet = '';

    const formatQuotedAmount = (value, showDecimal) => {
      if (!value) return '';
      return formatMoneyWithCurrency(
        moneyStrToNum(value).toString(),
        showDecimal,
      );
    };

    let includeDecimal = landRegistryFee.includes('.') || stampDuty.includes('.');

    if (quoteDetails) {
      quotePrice = formatQuotedAmount(quoteDetails.totalPrice, includeDecimal);
      saleLegalFee = formatQuotedAmount(quoteDetails.sellingQuotePrice, includeDecimal);
      purchaseLegalFee = formatQuotedAmount(quoteDetails.buyingQuotePrice, includeDecimal);
      remortgageLegalFee = formatQuotedAmount(quoteDetails.remortgageQuotePrice, includeDecimal);
      stampDuty = formatQuotedAmount(quoteDetails.stampDutyFee, includeDecimal);
      landRegistryFee = formatQuotedAmount(quoteDetails.landRegistryFee, includeDecimal);
      totalFeesAndTaxes = formatQuotedAmount(quoteDetails.totalFeesAndTaxes, includeDecimal);

      if (buyingDetails && (!!buyingDetails.rightToBuyScheme || !!buyingDetails.sharedOwnership)) {
        stampDutyDescription = 'Stamp Duty (based on the full value of the property)';
      }

      purchasePropertyPrice = (transactionType === 'Buying' || transactionType === 'BuyingAndSelling')
        ? formatQuotedAmount(buyingDetails?.price, includeDecimal)
        : '';

      salePropertyPrice = (transactionType === 'Selling' || transactionType === 'BuyingAndSelling')
        ? formatQuotedAmount(sellingDetails?.price, includeDecimal)
        : '';

      remortgageAmount = (transactionType === 'Remortgaging')
        ? formatQuotedAmount(remortgagingDetails?.mortgageAmount, includeDecimal)
        : '';
    }

    function getTenure(details) {
      if (details?.tenure === 'leasehold with share of freehold') {
        return 'share of freehold';
      }
      return details.tenure;
    }

    let sellingTenure = '';
    let buyingTenure = '';
    let remortgageTenure = '';

    if (transactionType === 'Selling' || transactionType === 'BuyingAndSelling') {
      sellingTenure = getTenure(sellingDetails);
    }
    if (transactionType === 'Buying' || transactionType === 'BuyingAndSelling') {
      buyingTenure = getTenure(buyingDetails);
    }
    if (transactionType === 'Remortgaging') {
      remortgageTenure = getTenure(remortgagingDetails);

      if (!!remortgagingDetails && quoteDetails.remoUsingTitleInsurance) {
        remortgageTitleInsured = 'title-insured';
      }

      if (remortgagingDetails?.isBuyToLet === true) {
        remortgageBuyToLet = 'buy-to-let';
      }
    }

    const transactionTypes = {
      'Buying': {
        quotePriceText: `That’s our quote, including VAT, for legal work on a ${buyingTenure} purchase costing ${purchasePropertyPrice}.`
      },
      'BuyingAndSelling': {
        quotePriceText: `That’s our quote, including VAT, for legal work on a ${buyingTenure} purchase costing ${purchasePropertyPrice} and ${sellingTenure} sale costing ${salePropertyPrice}.`
      },
      'Remortgaging': {
        quotePriceText: `That’s our quote, including VAT, for legal work switching to a ${remortgageAmount} ${remortgageTitleInsured} ${remortgageBuyToLet} mortgage on a ${remortgageTenure} property.`
      }
    };

    const { quotePriceText } = transactionTypes[transactionType] || {};

    return (
      <React.Fragment>
        <ScrollToTop />
        <Card title={`Thanks, we’ve sent ${personalDetails.firstName} the following quote`} removeTopPadding>
          <QuotedPriceWrapper>
            <QuotedPrice>{quotePrice}</QuotedPrice>
            <p>{quotePriceText}</p>

            <CheckList>
              <CheckListItem>Simpler, clearer, faster</CheckListItem>
              {(transactionType === 'BuyingAndSelling') && (
                <CheckListItem>No sale, no fee</CheckListItem>
              )}
              {(transactionType === 'BuyingAndSelling' ||
                transactionType === 'Buying') && (
                  <CheckListItem>Second chance guarantee</CheckListItem>
                )}
              {(transactionType === 'Remortgaging') && (
                <CheckListItem>No completion, no fee</CheckListItem>
              )}
              <CheckListItem>No hidden fees</CheckListItem>
            </CheckList>

            <p>
              Our quote includes searches, ID checks and common disbursements.{' '}
              <a href="https://www.juno.legal/find-out-more/whats-included-in-our-fixed-fee/" target="_blank" rel="noreferrer">See what’s included in our fixed fee</a>.
            </p>
          </QuotedPriceWrapper>

          {referral && (
            <ReferralCodeBanner text={referral.quoteMessage} withinQuote={true} />
          )}

          {(transactionType === 'Buying' || transactionType === 'BuyingAndSelling') && (
              <QuoteBreakdown>
                <List>

                  {transactionType === 'BuyingAndSelling' && (
                    <>
                      <ListItem divider>
                        Legal work on the {sellingTenure} sale
                        <ListItemSuffix>{saleLegalFee}</ListItemSuffix>
                      </ListItem>

                      <ListItem divider>
                        Legal work on the {buyingTenure} purchase
                        <ListItemSuffix>{purchaseLegalFee}</ListItemSuffix>
                      </ListItem>
                    </>
                  )}

                  {transactionType === 'Buying' && (
                    <ListItem divider>
                      Legal fee on the {buyingTenure} purchase
                      <ListItemSuffix data-testid="purchase-legal-fee">
                        {purchaseLegalFee}
                      </ListItemSuffix>
                    </ListItem>
                  )}

                  <ListItem divider>
                    Land Registry fee
                    <ListItemSuffix data-testid="land-registry-fee">
                      {landRegistryFee}
                    </ListItemSuffix>
                  </ListItem>

                  <ListItem divider>
                    {stampDutyDescription}
                    <ListItemSuffix data-testid="stamp-duty-fee">
                      {stampDuty}
                    </ListItemSuffix>
                  </ListItem>

                  <ListItem divider>
                    Total including government fees and taxes
                    <ListItemSuffix data-testid="total-fees-and-taxes">
                      {totalFeesAndTaxes}
                    </ListItemSuffix>
                  </ListItem>
                </List>
              </QuoteBreakdown>
            )}

          {transactionType === 'Remortgaging' && (
            <QuoteBreakdown>
              <List>

                <ListItem divider>
                  Legal fee for switching the mortgage
                  <ListItemSuffix data-testid="no-search-indemnity-fee">
                    {remortgageLegalFee}
                  </ListItemSuffix>
                </ListItem>

                <ListItem divider>
                  Land Registry fee
                  <ListItemSuffix data-testid="land-registry-fee">
                    {landRegistryFee}
                  </ListItemSuffix>
                </ListItem>
              </List>
            </QuoteBreakdown>
          )}

          <Action onClick={() => props.restartFlow()}>
            Refer another client
          </Action>
        </Card>

        <Image src={imageRelaxingOnSofa} fullWidth />
      </React.Fragment>
    );
}

export default BrokerQuoteSent;
