import React from 'react';
import styled from 'styled-components/macro';

const JunoHint = styled.span`
  display: block;
  color: ${props => props.theme.color.junoTextSecondary};
  font-family: ${props => props.theme.junoFont};
  font-weight: ${props => props.theme.fontWeight.light};
`;

function Hint(props) {
  return <JunoHint>{props.children}</JunoHint>;
}

export default Hint;
