import React from 'react';

import PageLayout from '../../components/PageLayout';
import JunoTermsAndConditions from '../../components/JunoTermsAndConditions';
import Card from '../../components/Card';

const TermsAndConditionsPage = () => (
  <PageLayout>
  <Card title="Our terms and conditions" headingLevel="2" removeTopPadding>
    <JunoTermsAndConditions />
  </Card>
  </PageLayout>
);

export default TermsAndConditionsPage;
