import React, { Component } from 'react';
import ScrollToTop from '../../components/utils/ScrollToTop';

class RemoSubmission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
    };

    const flowState = this.props.remoSignupFlowState;

    let numberOfOwners = flowState.otherOwners
      ? flowState.otherOwners.numberOfOwners
      : 0;
    let otherOwners = [];
    if (numberOfOwners === 4) {
      otherOwners = [
        flowState.otherOwners.client2,
        flowState.otherOwners.client3,
        flowState.otherOwners.client4,
      ];
    } else if (numberOfOwners === 3) {
      otherOwners = [
        flowState.otherOwners.client2,
        flowState.otherOwners.client3,
      ];
    } else if (numberOfOwners === 2) {
      otherOwners = [flowState.otherOwners.client2];
    } else {
      otherOwners = [];
    }

    let numberOfOccupiers = flowState.otherOccupiers
      ? flowState.otherOccupiers.numberOfOccupiers
      : 0;
    let otherOccupiers = [];
    if (numberOfOccupiers === 3) {
      otherOccupiers = [
        flowState.otherOccupiers.occupier1,
        flowState.otherOccupiers.occupier2,
        flowState.otherOccupiers.occupier3,
      ];
    } else if (numberOfOccupiers === 2) {
      otherOwners = [
        flowState.otherOccupiers.occupier2,
        flowState.otherOccupiers.occupier1,
      ];
    } else if (numberOfOccupiers === 1) {
      otherOccupiers = [flowState.otherOccupiers.occupier1];
    } else {
      otherOccupiers = [];
    }

    let areThereAnyAdultOccupiers = numberOfOccupiers > 0;

    const body = {
      leadId: flowState.leadId,
      transactionType: flowState.transactionType,
      remortgagePropertyAddress: flowState.propertyAddress,
      primaryClient: {
        ...flowState.yourName.client1,
        ...flowState.contactDetails,
      },
      remortgagingAddressDetails: {
        isBuyToLet: flowState.propertyInformation1.isBuyToLet,
        isTransferOfEquity: flowState.propertyInformation1.isTransferOfEquity,
        hasClientHadDisputesWithNeighbours:
          flowState.propertyInformation1.hasHadDisputesWithNeighbours,
        informationAboutDisputesWithNeighbours:
          flowState.propertyInformation1.disputesWithNeighboursInfo,
        paymentDemandedForLocalChurchRepairs:
          flowState.propertyInformation1.hasHadPaymentDemandsForChurchRepairs,
        informationAboutChurchRepairs:
          flowState.propertyInformation1.churchRepairsInfo,
        hasClientReceivedNotices:
          flowState.propertyInformation2.hasClientReceivedNotices,
        informationAboutNotices: flowState.propertyInformation2.noticesInfo,
        isPropertyConnectedToStandardUtilities:
          flowState.propertyInformation2.isPropertyConnectedToUtilities,
        informationAboutConnectionToStandardUtilities:
          flowState.propertyInformation2.connectionToUtilitiesInfo,
        numberOfManagementCompanies: flowState.propertyInformation2.numberOfManagementCompanies,
        whoInsuresProperty: flowState.propertyInformation2.whoInsuresProperty,
        doesTheClientPayAnyMaintenanceFees:
          flowState.propertyInformation2.clientPaysMaintenanceFees,
        informationAboutMaintenanceFees:
          flowState.propertyInformation2.maintenanceFeesInfo,
        haveThereBeenAnyRecentBuildingWorks:
          flowState.propertyInformation2.hasBuildingWorks,
        informationAboutBuildingWorks:
          flowState.propertyInformation2.buildingWorksInfo,
        isTheClientPayingOffSomeOfTheirExistingLoan:
          flowState.propertyInformation3.clientIsPayingOffSomeOfLoan,
        propertyIsTenanted: flowState.propertyInformation1.rejectHasTenants,
      },
      remortgageMortgageLender: flowState.mortgageLender,
      remortgageMortgageBroker: flowState.mortgageBroker,
      otherOwners: otherOwners,
      areThereAnyAdultOccupiers: areThereAnyAdultOccupiers,
      otherOccupiers: otherOccupiers,
      completionDetails: flowState.completionDate,
      anythingElse: flowState.anythingElse,
    };

    fetch('/api/signup', {
      method: 'PUT',
      body: JSON.stringify(body),
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
            submitted: true,
          });
          props.onFlowComplete();
        } else {
          this.setState({
            error: 'Unable to process your signup',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Unable to process your signup',
          loading: false,
        });
      },
    );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <div>
          <ScrollToTop />
          {this.state.error}
        </div>
      );
    }

    if (this.state.submitted) {
      return null
    }
  }
}

export default RemoSubmission;
