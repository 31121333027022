import React, { Component } from 'react';
import Card from '../../components/Card';
import Action from '../../components/Action';
import ScrollToTop from '../../components/utils/ScrollToTop';
import Image from '../../components/Image';
import imageOfficeWorking from '../../images/office-working.png';

class SellingMortgagesAndLoansIntro extends Component {
  render() {
    const sellingMortgagesAndLoans = { ...this.props.sellingMortgagesAndLoans };
    let introText = '';

    if (sellingMortgagesAndLoans.sellerHasAMortgage === true) {
      introText = 'mortgages';
    }

    if (sellingMortgagesAndLoans.sellerHasASecuredLoan === true) {
      introText = 'loans';
    }

    if (
      sellingMortgagesAndLoans.sellerHasAMortgage === true &&
      sellingMortgagesAndLoans.sellerHasASecuredLoan === true
    ) {
      introText = 'mortgages and loans';
    }
    return (
      <React.Fragment>
        <ScrollToTop />
        <Card
          title={'Tell us about your ' + introText}
          removeTopPadding
          headingSize="1"
        >
          <p>
            When you signed up to sell your home, you told us there were{' '}
            {introText} on the property. We need a bit more information about
            these, so we can make sure we can pay these off when you sell your
            property.
          </p>
          <p>
            It should take just a minute to fill this form out. You’ll need
            details about your {introText} to hand to complete it.
          </p>
          <Action onClick={() => this.props.onStepComplete({})}>
            Let’s get started
          </Action>
        </Card>
        <Image src={imageOfficeWorking} fullWidth />
      </React.Fragment>
    );
  }
}

export default SellingMortgagesAndLoansIntro;
