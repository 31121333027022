import React, { Component } from 'react';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';

class SharedOwnershipPurchaseSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };

    const flowState = this.props.sharedOwnershipPurchaseFlowState;

    fetch('/api/sharedOwnershipPurchase', {
      method: 'PUT',
      body: JSON.stringify({
        formRequestId: flowState.formRequestId,
        shareDetails: flowState.shareDetails,
        housingAssociation: flowState.housingAssociation,
      }),
    }).then(
      resp => {
        if (!!resp.ok) {
          this.setState({
            loading: false,
            submitted: true,
          });
          props.onFlowComplete();
        } else {
          this.setState({
            error: 'Unable to process your purchase details',
            loading: false,
          });
        }
      },
      () => {
        this.setState({
          error: 'Unable to process your purchase details',
          loading: false,
        });
      },
    );
  }

  render() {
    if (!!this.state.loading) {
      return null;
    }

    if (this.state.error) {
      return (
        <React.Fragment>
          <ScrollToTop />
          <Card title="Sorry, that didn’t work" removeTopPadding>
            {this.state.error}
          </Card>
        </React.Fragment>
      );
    }

    if (this.state.submitted) {
      return null;
    }
  }
}

export default SharedOwnershipPurchaseSubmission;
