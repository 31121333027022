import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useClientAppFormRequest from '../../hooks/useClientAppFormRequest';
import PageLayout from '../../components/PageLayout';
import Card from '../../components/Card';
import ScrollToTop from '../../components/utils/ScrollToTop';
import StepFlowNavigate from '../../components/StepFlowNavigate';
import SourceOfFundsIntro from './SourceOfFundsIntro';
import HowIsBuyerPaying from './HowIsBuyerPaying';
import CheckHowIsBuyerPaying from './CheckHowIsBuyerPaying';
import BonusFromEmployer from './BonusFromEmployer';
import Trust from './Trust';
import Savings from './Savings';
import Mortgage from './Mortgage';
import LinkedSale from './LinkedSale';
import Remortgage from './Remortgage';
import HelpToBuyIsa from './HelpToBuyIsa';
import LifetimeIsa from './LifetimeIsa';
import GiftingMoney from './GiftingMoney';
import LendingMoney from './LendingMoney';
import Inheritance from './Inheritance';
import AccountForm from './AccountForm';
import GiftingMoneyForm from './GiftingMoneyForm';
import LendingMoneyForm from './LendingMoneyForm';
import SourceOfFundsCheckYourAnswers from './SourceOfFundsCheckYourAnswers';
import SourceOfFundsThanks from './SourceOfFundsThanks';
import SourceOfFundsSubmitted from './SourceOfFundsSubmitted';


const SourceOfFundsFlow = (props) => {
  const location = useLocation()
  const { requestId } = useParams()

  const [fromDashboard, setFromDashboard] = useState(false);
  const [flowCompleted, setFlowCompleted] = useState(false);
  const [submittedFlowState, setSubmittedFlowState] = useState(null);
  const { loading, error, requestDetails, submitted } = useClientAppFormRequest(`/api/sourceOfFunds/${requestId}`);

   // Set fromDashboard state on initial load
  useEffect(() => {
    const { fromDashboard } = location.state || {};
    setFromDashboard(fromDashboard);
  }, []);

  const shouldShowCashOrSavings = flowState => {
    return flowState.howIsBuyerPaying.cashAndSavings === true;
  };

  const shouldShowCheckHowIsBuyerPaying = flowState => {
    const hasOnlyMortgage = buyerHasOnlyMortgage(flowState);
    const hasNoMortgage = sourceNotSelected(flowState, 'mortgage');
    const hasNoSavings = sourceNotSelected(flowState, 'cashAndSavings');

    return (
      !!hasNoMortgage || !!hasOnlyMortgage || !!hasNoSavings
    )
  }

  const buyerHasOnlyMortgage = (flowState) => {
    const howIsBuyerPayingKeys = Object.keys(flowState.howIsBuyerPaying).filter(key => flowState.howIsBuyerPaying[key] === true)
    if (howIsBuyerPayingKeys.length === 1 && howIsBuyerPayingKeys[0] === 'mortgage') {
      return true
    }
    return false
  }

  const sourceNotSelected = (flowState, source) => {
    for (const [key, value] of Object.entries(flowState.howIsBuyerPaying)) {
      if (key === source) {
        if (value === false) {
          return true
        } else {
          return false
        }
      }
    }
  }

  const shouldShowSavingsAccount = (flowState, accountNumber) => {
    if (flowState.howIsBuyerPaying.cashAndSavings !== true) {
      return false;
    }

    switch (accountNumber) {
      case 1:
        return flowState.savings.numberOfSavingsAccounts <= 12;
      default:
        return flowState.savings.numberOfSavingsAccounts > accountNumber - 1;
    }
  };

  const shouldShowMortgage = flowState => {
    return (
      flowState.howIsBuyerPaying.mortgage === true
    );
  };

  const shouldShowSellingAnotherProperty = flowState => {
    return flowState.howIsBuyerPaying.sellingAnotherProperty === true;
  };

  const shouldShowRemortgagingAnotherProperty = flowState => {
    return flowState.howIsBuyerPaying.remortgagingAnotherProperty === true;
  };

  const shouldShowHelpToBuyIsa = (flowState, accountNumber) => {
    if (flowState.howIsBuyerPaying.helpToBuyIsa !== true) {
      return false;
    }

    switch (accountNumber) {
      case 1:
        return flowState.helpToBuyIsa.numberOfSource <= 4;
      case 2:
        return flowState.helpToBuyIsa.numberOfSource > 1;
      case 3:
        return flowState.helpToBuyIsa.numberOfSource > 2;
      case 4:
        return flowState.helpToBuyIsa.numberOfSource > 3;
      default:
        return true; // helpToBuyIsa == true, no account number
    }
  };

  const shouldShowLifetimeIsa = (flowState, accountNumber) => {
    if (flowState.howIsBuyerPaying.lifetimeIsa !== true) {
      return false;
    }

    switch (accountNumber) {
      case 1:
        return flowState.lifetimeIsa.numberOfSource <= 4;
      case 2:
        return flowState.lifetimeIsa.numberOfSource > 1;
      case 3:
        return flowState.lifetimeIsa.numberOfSource > 2;
      case 4:
        return flowState.lifetimeIsa.numberOfSource > 3;
      default:
        return true; // lifetimeIsa == true, no account number
    }
  };

  const shouldShowInheritance = flowState => {
    return flowState.howIsBuyerPaying.inheritance === true;
  };

  const shouldShowGiftingMoney = (flowState, accountNumber) => {
    if (flowState.howIsBuyerPaying.someoneGivingMeMoney !== true) {
      return false;
    }

    switch (accountNumber) {
      case 1:
        return flowState.giftingMoney.numberOfSource <= 4;
      case 2:
        return flowState.giftingMoney.numberOfSource > 1;
      case 3:
        return flowState.giftingMoney.numberOfSource > 2;
      case 4:
        return flowState.giftingMoney.numberOfSource > 3;
      default:
        return true; // someoneGivingMeMoney == true, no account number
    }
  };

  const shouldShowLendingMoney = (flowState, accountNumber) => {
    if (flowState.howIsBuyerPaying.someoneLendingMeMoney !== true) {
      return false;
    }

    switch (accountNumber) {
      case 1:
        return flowState.lendingMoney.numberOfSource <= 2;
      case 2:
        return flowState.lendingMoney.numberOfSource > 1;
      default:
        return true; // someoneLendingMeMoney == true, no account number
    }
  };

  const shouldShowBonusFromEmployer = flowState => {
    return flowState.howIsBuyerPaying.bonusFromEmployer === true;
  };

  const shouldShowTrust = flowState => {
    return flowState.howIsBuyerPaying.trust === true;
  };


  if (!!loading) {
    return null;
  }

  if (submitted || flowCompleted) {
    return (
      <PageLayout>
        <SourceOfFundsThanks fromDashboard={fromDashboard} submittedFlowState={submittedFlowState} />
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <ScrollToTop />
        <Card title="Sorry, that didn't work" removeTopPadding>
          {error}
        </Card>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
    <StepFlowNavigate
      initialFlowState={{
        formRequestId: requestDetails.formRequestId,
        leadSource: requestDetails.leadSource,
        fromDashboard: fromDashboard
      }}
      steps={[
        {
          name: 'Intro',
          slug: 'intro',
          component: flowState => <SourceOfFundsIntro />,
        },
        {
          name: 'How are you paying',
          slug: 'how-are-you-paying',
          component: flowState => (
            <HowIsBuyerPaying howIsBuyerPaying={flowState.howIsBuyerPaying} />
          ),
        },
        {
          name: 'Warn if no mortgage, no savings, or only mortgage',
          slug: 'check-how-are-you-paying',
          valid: flowState => shouldShowCheckHowIsBuyerPaying(flowState),
          component: flowState => (
            <CheckHowIsBuyerPaying
              howIsBuyerPaying={flowState.howIsBuyerPaying}
              noMortgage={sourceNotSelected(flowState, 'mortgage')}
              onlyMortgage={buyerHasOnlyMortgage(flowState)}
              noSavings={sourceNotSelected(flowState, 'cashAndSavings')}
            />
          ),
        },
        {
          name: 'Tell us about your savings or investments',
          slug: 'savings-or-investments',
          valid: flowState => shouldShowCashOrSavings(flowState),
          component: flowState => <Savings savings={flowState.savings} howIsBuyerPaying={flowState.howIsBuyerPaying} />,
        },
        {
          name: 'Savings Account 1',
          slug: 'savings-account-1',
          valid: flowState => shouldShowSavingsAccount(flowState, 1),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount1}
              accountNumber={1}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Savings Account 2',
          slug: 'savings-account-2',
          valid: flowState => shouldShowSavingsAccount(flowState, 2),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount2}
              accountNumber={2}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Savings Account 3',
          slug: 'savings-account-3',
          valid: flowState => shouldShowSavingsAccount(flowState, 3),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount3}
              accountNumber={3}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Savings Account 4',
          slug: 'savings-account-4',
          valid: flowState => shouldShowSavingsAccount(flowState, 4),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount4}
              accountNumber={4}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Savings Account 5',
          slug: 'savings-account-5',
          valid: flowState => shouldShowSavingsAccount(flowState, 5),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount5}
              accountNumber={5}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Savings Account 6',
          slug: 'savings-account-6',
          valid: flowState => shouldShowSavingsAccount(flowState, 6),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount6}
              accountNumber={6}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Savings Account 7',
          slug: 'savings-account-7',
          valid: flowState => shouldShowSavingsAccount(flowState, 7),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount7}
              accountNumber={7}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Savings Account 8',
          slug: 'savings-account-8',
          valid: flowState => shouldShowSavingsAccount(flowState, 8),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount8}
              accountNumber={8}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Savings Account 9',
          slug: 'savings-account-9',
          valid: flowState => shouldShowSavingsAccount(flowState, 9),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount9}
              accountNumber={9}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Savings Account 10',
          slug: 'savings-account-10',
          valid: flowState => shouldShowSavingsAccount(flowState, 10),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount10}
              accountNumber={10}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Savings Account 11',
          slug: 'savings-account-11',
          valid: flowState => shouldShowSavingsAccount(flowState, 11),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount11}
              accountNumber={11}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Savings Account 12',
          slug: 'savings-account-12',
          valid: flowState => shouldShowSavingsAccount(flowState, 12),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.savingsAccount12}
              accountNumber={12}
              accountType="savings"
            />
          ),
        },
        {
          name: 'Tell us about your mortgage',
          slug: 'mortgage',
          valid: flowState => shouldShowMortgage(flowState),
          component: flowState => <Mortgage mortgage={flowState.mortgage} />,
        },
        {
          name: 'Tell us about your sale',
          slug: 'selling-another-property',
          valid: flowState =>
            shouldShowSellingAnotherProperty(flowState),
          component: flowState => (
            <LinkedSale linkedSale={flowState.linkedSale} />
          ),
        },
        {
          name: 'Tell us about your remortgage',
          slug: 'remortgaging-another-property',
          valid: flowState =>
            shouldShowRemortgagingAnotherProperty(flowState),
          component: flowState => (
            <Remortgage remortgage={flowState.remortgage} />
          ),
        },
        {
          name: 'Tell us about your Help to Buy ISAs',
          slug: 'help-to-buy-isa',
          valid: flowState => shouldShowHelpToBuyIsa(flowState),
          component: flowState => (
            <HelpToBuyIsa helpToBuyIsa={flowState.helpToBuyIsa} />
          ),
        },
        {
          name: 'Help to Buy Isa Account 1',
          slug: 'help-to-buy-isa-account-1',
          valid: flowState => shouldShowHelpToBuyIsa(flowState, 1),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.helpToBuyIsaAccount1}
              accountNumber={1}
              accountType="helpToBuyIsa"
            />
          ),
        },
        {
          name: 'Help to Buy Isa Account 2',
          slug: 'help-to-buy-isa-account-2',
          valid: flowState => shouldShowHelpToBuyIsa(flowState, 2),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.helpToBuyIsaAccount2}
              accountNumber={2}
              accountType="helpToBuyIsa"
            />
          ),
        },
        {
          name: 'Help to Buy Isa Account 3',
          slug: 'help-to-buy-isa-account-3',
          valid: flowState => shouldShowHelpToBuyIsa(flowState, 3),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.helpToBuyIsaAccount3}
              accountNumber={3}
              accountType="helpToBuyIsa"
            />
          ),
        },
        {
          name: 'Help to Buy Isa Account 4',
          slug: 'help-to-buy-isa-account-4',
          valid: flowState => shouldShowHelpToBuyIsa(flowState, 4),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.helpToBuyIsaAccount4}
              accountNumber={4}
              accountType="helpToBuyIsa"
            />
          ),
        },
        {
          name: 'Tell us about your Lifetime ISAs',
          slug: 'lifetime-isa',
          valid: flowState => shouldShowLifetimeIsa(flowState),
          component: flowState => (
            <LifetimeIsa lifetimeIsa={flowState.lifetimeIsa} />
          ),
        },
        {
          name: 'Lifetime Isa Account 1',
          slug: 'lifetime-isa-account-1',
          valid: flowState => shouldShowLifetimeIsa(flowState, 1),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.lifetimeIsaAccount1}
              accountNumber={1}
              accountType="lifetimeIsa"
            />
          ),
        },
        {
          name: 'Lifetime Isa Account 2',
          slug: 'lifetime-isa-account-2',
          valid: flowState => shouldShowLifetimeIsa(flowState, 2),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.lifetimeIsaAccount2}
              accountNumber={2}
              accountType="lifetimeIsa"
            />
          ),
        },
        {
          name: 'Lifetime Isa Account 3',
          slug: 'lifetime-isa-account-3',
          valid: flowState => shouldShowLifetimeIsa(flowState, 3),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.lifetimeIsaAccount3}
              accountNumber={3}
              accountType="lifetimeIsa"
            />
          ),
        },
        {
          name: 'Lifetime Isa Account 4',
          slug: 'lifetime-isa-account-4',
          valid: flowState => shouldShowLifetimeIsa(flowState, 4),
          component: flowState => (
            <AccountForm
              savingsAccount={flowState.lifetimeIsaAccount4}
              accountNumber={4}
              accountType="lifetimeIsa"
            />
          ),
        },
        {
          name: 'Inheritance',
          slug: 'inheritance',
          valid: flowState => shouldShowInheritance(flowState),
          component: flowState => (
            <Inheritance />
          ),
        },
        {
          name: 'Tell us about any gifts of money from someone who is alive',
          slug: 'gifting-money',
          valid: flowState => shouldShowGiftingMoney(flowState),
          component: flowState => (
            <GiftingMoney giftingMoney={flowState.giftingMoney} />
          ),
        },
        {
          name: 'Gifting Money 1',
          slug: 'gifting-money-1',
          valid: flowState => shouldShowGiftingMoney(flowState, 1),
          component: flowState => (
            <GiftingMoneyForm
              giftingMoney={flowState.giftingMoney1}
              giftNumber={1}
            />
          ),
        },
        {
          name: 'Gifting Money 2',
          slug: 'gifting-money-2',
          valid: flowState => shouldShowGiftingMoney(flowState, 2),
          component: flowState => (
            <GiftingMoneyForm
              giftingMoney={flowState.giftingMoney2}
              giftNumber={2}
            />
          ),
        },
        {
          name: 'Gifting Money 3',
          slug: 'gifting-money-3',
          valid: flowState => shouldShowGiftingMoney(flowState, 3),
          component: flowState => (
            <GiftingMoneyForm
              giftingMoney={flowState.giftingMoney3}
              giftNumber={3}
            />
          ),
        },
        {
          name: 'Gifting Money 4',
          slug: 'gifting-money-4',
          valid: flowState => shouldShowGiftingMoney(flowState, 4),
          component: flowState => (
            <GiftingMoneyForm
              giftingMoney={flowState.giftingMoney4}
              giftNumber={4}
            />
          ),
        },
        {
          name: 'Tell us about the loans you are taking out',
          slug: 'lending-money',
          valid: flowState => shouldShowLendingMoney(flowState),
          component: flowState => (
            <LendingMoney lendingMoney={flowState.lendingMoney} />
          ),
        },
        {
          name: 'Lending Money 1',
          slug: 'lending-money-1',
          valid: flowState => shouldShowLendingMoney(flowState, 1),
          component: flowState => (
            <LendingMoneyForm
              lendingMoney={flowState.lendingMoney1}
              lenderNumber={1}
            />
          ),
        },
        {
          name: 'Lending Money 2',
          slug: 'lending-money-2',
          valid: flowState => shouldShowLendingMoney(flowState, 2),
          component: flowState => (
            <LendingMoneyForm
              lendingMoney={flowState.lendingMoney2}
              lenderNumber={2}
            />
          ),
        },
        {
          name: 'Bonus from employer',
          slug: 'bonus-from-employer',
          valid: flowState => shouldShowBonusFromEmployer(flowState),
          component: flowState => (
            <BonusFromEmployer
              bonusFromEmployer={flowState.bonusFromEmployer}
            />
          ),
        },
        {
          name: 'Money from a Trust',
          slug: 'trust',
          valid: flowState => shouldShowTrust(flowState),
          component: flowState => (
            <Trust
              trust={flowState.trust}
            />
          ),
        },
        {
          name: 'Check your answers',
          slug: 'check-your-answers',
          component: flowState => (
            <SourceOfFundsCheckYourAnswers
              sourceOfFundsFlowState={flowState} />
          )
        },
        {
          name: 'Submission',
          slug: 'submit',
          component: flowState => (
            <SourceOfFundsSubmitted
              sourceOfFundsFlowState={flowState}
              onFlowComplete={() => {
                setSubmittedFlowState({...flowState});
                setFlowCompleted(true);
              }}
              />
          )
        },
      ]}
    />
    </PageLayout>
  );
}

export default SourceOfFundsFlow;
