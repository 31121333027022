import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { setActiveCase } from "../../../actions/dashboardActions";

export const useSelectedCaseRef = ({caseScreen} = {}) => {
  const { caseRef } = useParams();

  const lastActiveCaseRef = useSelector(state => state.dashboardState.lastActiveCaseRef);
  const lastActiveCaseScreen = useSelector(state => state.dashboardState.lastActiveCaseScreen);
  const dispatch = useDispatch();

  // Store the active case and screen in Redux so we can link back to the case
  // page from URLs that don't include the :caseRef param.
  useEffect(() => {
    // Do nothing if we don't have a reference in the URL and a screen specified.
    if (! caseRef || ! caseScreen) {
      return;
    }

    if (caseRef !== lastActiveCaseRef || caseScreen !== lastActiveCaseScreen) {
      dispatch(setActiveCase(caseRef, caseScreen));
    }
  }, [dispatch, caseRef, caseScreen, lastActiveCaseRef, lastActiveCaseScreen]);

  if (!! caseRef) {
    return caseRef;
  }
  return lastActiveCaseRef;
}

export const useSelectedCase = ({caseScreen} = {}) => {
  const caseRef = useSelectedCaseRef({caseScreen: caseScreen});

  const selectedCase = useSelector(state => {
    if (! state.dashboardState.cases) {
      return null;
    }
    return state.dashboardState.cases.find(c => c.case_reference === caseRef)
  });

  return selectedCase;
}
